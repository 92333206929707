<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!orderLineItemInput">Add Order Line Item</h2>
    <h2 *ngIf="orderLineItemInput">Update Order Line Item</h2>

    <form id="formId" [formGroup]="orderLineItemForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" >
        <ejs-dropdowntree id='order_item_codeee' formControlName="order_item_code" floatLabelType="Auto"
                          [fields]='orderItemFields' cssClass="e-filled" sortOrder="Ascending"
                          placeholder='Order Item *'
                          (blur)="orderLineItemForm.controls.order_item_code.markAsTouched()">
        </ejs-dropdowntree>
        <div *ngIf="orderLineItemForm.controls.order_item_code.invalid">
          <div *ngIf="orderLineItemForm.controls.order_item_code.errors.required && orderLineItemForm.controls.order_item_code.touched "
               class="e-error">
            Please select an order item
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description *" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto"
                     (blur)="orderLineItemForm.controls.description.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="orderLineItemForm.controls.description.errors">
          <div *ngIf="orderLineItemForm.controls.description.errors.required && orderLineItemForm.controls.description.touched "
               class="e-error">
            Description is required.
          </div>
          <div *ngIf="orderLineItemForm.controls.description.errors.maxlength && orderLineItemForm.controls.description.touched"
               class="e-error">
            Description cannot be more than 1000 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-numerictextbox id="amount" placeholder="Amount" cssClass="e-filled" min="0"
                     formControlName="amount" floatLabelType="Auto" [showSpinButton]=false
                     (blur)="orderLineItemForm.controls.amount.markAsTouched()" maxlength="15"
        ></ejs-numerictextbox>
        <div *ngIf="orderLineItemForm.controls.amount.errors">
          <div *ngIf="orderLineItemForm.controls.amount.errors.maxlength && orderLineItemForm.controls.amount.touched"
               class="e-error">
            Amount cannot be more than 15 digits
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-numerictextbox id="quantity" placeholder="Quantity" cssClass="e-filled" [showSpinButton]=false
                     formControlName="quantity" floatLabelType="Auto"  format="n0" decimals="0" maxlength="9" min="0"
                     (blur)="orderLineItemForm.controls.quantity.markAsTouched()"
        ></ejs-numerictextbox>
        <div *ngIf="orderLineItemForm.controls.quantity.errors">
          <div *ngIf="orderLineItemForm.controls.quantity.errors.maxlength && orderLineItemForm.controls.quantity.touched"
               class="e-error">
            Quantity cannot be more than 9 digits
          </div>
        </div>
      </div>

      <div *ngIf="orderLineItemForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clear(); "
                data-ripple="true">Clear
        </button>
        <button *ngIf="!orderLineItemInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddOrderLineItem(orderLineItemForm)" [disabled]="formSent" data-ripple="true">Add Order Line Item
        </button>
        <button *ngIf="orderLineItemInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onUpdateOrderLineItem(orderLineItemForm)" [disabled]="formSent" data-ripple="true">Update Order Line Item
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
