import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSlice'
})
export class TextSlicePipe implements PipeTransform {

  transform(value: any, length?: number): any {
    let transformVal;
    if(value && value !== undefined) {
      if(value.length < length + 2) {
        transformVal = value;
      } else {
        transformVal = value.slice(0, length + 2) + '...';
      }
    }
    return transformVal;
  }

}
