import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {MultiSelectComponent, DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import * as _ from 'lodash';
import {Observable, BehaviorSubject} from 'rxjs';
import {AppState} from 'src/app/core';
import {CommonService} from 'src/app/core/services/common.service';
import {ArticleSetSuccessNull, UpdateArticleLTDSettings} from 'src/app/core/store/actions/article.actions';
import {CompanySetStatusNull, CompanyUpdateLTDSettings} from 'src/app/core/store/actions/company.actions';
import {websiteValidator} from '../../../../../app-validators';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ArticleService} from '../../../../../core/store/services/article.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-article-ltd-settings-form',
  templateUrl: './article-ltd-settings.component.html',
  styleUrls: ['./article-ltd-settings.component.scss']
})
export class ArticleLtdSettingsComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('companyFormAddress') companyFormAddress: MultiSelectComponent;
  @ViewChild('visibility') visibilityDropdown: DropDownListComponent;

  @Input() selectedArticleCode;
  @Input() articleList;
  @Input() type = '';
  @Input() countryList = [];
  @Input() isArticleType: boolean;
  @Output() valueChanged = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  public visibilityFields: object = {value: 'visibility_code', text: 'visibility_name'};
  public articleFields: object = {value: 'article_code', text: 'article_name'};
  public countryFields: object = {value: 'country_code', text: 'country_name'};


  public formSent: boolean;
  referenceID = '';
  error$: Observable<any>;
  success$: Observable<boolean>;
  defaultTabDefaultValue = 'auto_select';
  preLoadedFiles: any;

  showUploadingBtn = false;

  showUploader$ = new BehaviorSubject(false);

  displayPasswordInput = false;

  public selectedItem: string = 'public';

  public visibilityList: any[] = [
    {visibility_code: 'private', visibility_name: 'Private'},
    {visibility_code: 'password_protected', visibility_name: 'Password Protected'},
    {visibility_code: 'public', visibility_name: 'Public'},
  ];

  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(false);
  articleLTDForm: FormGroup;
  // brandForm: FormGroup;
  _deliverableSubscription: any;

  public _articleLTDSettingsInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set articleLTDSettingsInput(mode: any | null) {
    this._articleLTDSettingsInput$.next(mode);
  }

  get articleLTDSettings(): any | null {
    return this._articleLTDSettingsInput$.getValue();
  }

  constructor(private store: Store<AppState>, private commonService: CommonService, private articleService: ArticleService) {
  }

  setDebounce = debounce((e) => {
    this.onFilter(e);
  }, 700);

  ngOnInit(): void {
    this.generateForm();

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.store.dispatch(new ArticleSetSuccessNull());
        this.closeSidebar();
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
    if (this.type === 'images') {
      this.showUploader$.next(true);
      this.articleLTDForm.clearValidators();
    } else {
      this.articleLTDForm.controls.visibility.setValidators(Validators.required);
      this.articleLTDForm.controls.password_protected_password.setValidators(Validators.maxLength(255));

    }
  }

  closeSidebar(): void {
    this.formSent = false;
    this.showUploader$.next(false);
    if (this.sidebarInstance.isOpen) {
      this.clearForm(this.articleLTDForm);
    }
    this.sidebarInstance.hide();

    this.valueChanged.emit('success');
    this.preLoadedFiles = [];

  }

  generateForm() {
    this.articleLTDForm = new FormGroup({
      is_featured_homepage: new FormControl(false),
      subArticle: new FormControl(''),
      visibility: new FormControl(''),
      password_protected_password: new FormControl(''),
      country: new FormControl(''),
      youtube_vimeo_link: new FormControl('', Validators.compose([websiteValidator]))
    });


    // this.checkPremiumCompany();
  }

  ngOnDestroy(): void {
    this.showUploader$.complete();
    this.referenceID = null;
    // this._deliverableSubscription.complete();
  }

  onUpdateArticleLTD(form: FormGroup) {
    if (this.type === 'images') {
      this.showUploader$.next(true);
      this.articleLTDForm.clearValidators();
      this.articleLTDForm.updateValueAndValidity();
      this.articleLTDForm.controls.visibility.updateValueAndValidity();

    } else {
      this.articleLTDForm.controls.visibility.setValidators(Validators.required);
      this.articleLTDForm.controls.password_protected_password.setValidators(Validators.maxLength(255));
      this.articleLTDForm.updateValueAndValidity();
    }

    


    if (!form.invalid) {
      const formValues = form.getRawValue();

      this.store.dispatch(new UpdateArticleLTDSettings({
        articleCode: this.selectedArticleCode,
        ltdSettings: {
          is_feature_homepage_yn: formValues.is_featured_homepage === false || formValues.is_featured_homepage === null ? 0 : 1,
          sub_article_code: formValues.subArticle,
          visibility: formValues.visibility,
          password_protected_password: formValues.password_protected_password,
          featured_image_reference_id: this.referenceID,
          youtube_vimeo_link: formValues.youtube_vimeo_link,
          country_code: formValues.country
        }
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.isArticleType) {
      
    }
    if (this._articleLTDSettingsInput$.getValue()) {

      if (this.articleLTDForm) {
        this.articleLTDForm.setValue({
          visibility: this._articleLTDSettingsInput$.getValue().visibility ? this._articleLTDSettingsInput$.getValue().visibility : null,
          password_protected_password: this._articleLTDSettingsInput$.getValue().password_protected_password ? this._articleLTDSettingsInput$.getValue().password_protected_password : null,
          is_featured_homepage: this._articleLTDSettingsInput$.getValue().is_feature_homepage_yn === 1,
          subArticle: this._articleLTDSettingsInput$.getValue().sub_article ? this._articleLTDSettingsInput$.getValue().sub_article.article_code : null,
          country: this._articleLTDSettingsInput$.getValue().country ? this._articleLTDSettingsInput$.getValue().country.country_code : null,
          youtube_vimeo_link: this._articleLTDSettingsInput$.getValue().youtube_vimeo_link ? this._articleLTDSettingsInput$.getValue().youtube_vimeo_link : null
        });


      }

      
      if (this._articleLTDSettingsInput$.getValue().featured_image_reference_id) {
        this.referenceID = this._articleLTDSettingsInput$.getValue().featured_image_reference_id;
        this.preLoadedFiles = [{
          filename: this._articleLTDSettingsInput$.getValue().featured_image_filename,
          url: this._articleLTDSettingsInput$.getValue().featured_image_reference_id
        }];
      }

    }
  }

  updateReferenceID(args) {
    
    if (args.reference_id && args.isUploading == false) {
      this.referenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.cleared === true) {
      // this.setCleared$.next(null);
      this.referenceID = null;
      this.formSent = false;
    } else if (args.unsupportedFileType === true) {
      this.setCleared$.next(null);
      // this.formSent = true;
    } else if (args.unsupportedFileType === false) {
      this.formSent = false;
    } else if (args.reference_id === '' && args.isUploading === false) {
      this.referenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.isUploading === true) {
      this.formSent = true;
      this.showUploadingBtn = true;
      
    }
  }

  clearForm(form?) {

    if (this.type === 'brand') {
      this.articleLTDForm.controls.brands.setValue('');
    } else if (this.type === 'ltd-settings') {
      this.articleLTDForm.patchValue({
        visibility: null,
        password_protected_password: null,
        is_featured_homepage: 0,
        subArticle: null,
        country: null,
        youtube_vimeo_link: null
      });
    } else if (this.type === 'images') {
      this.setCleared$.next(true);
    }
  }


  changePasswordValidation(form: FormGroup, args) {
    if (form && args && args.itemData) {
      form.controls.visibility.setValue(args.itemData.visibility_code);
      if (form.controls.visibility.value === 'password_protected') {
        form.controls.password_protected_password.setValidators([Validators.required, Validators.maxLength(255)]);
        form.updateValueAndValidity();
        this.displayPasswordInput = true;
      } else {
        this.displayPasswordInput = false;
        form.controls.password_protected_password.clearValidators();
        form.updateValueAndValidity();

      }
    }
  }

  setDefaultValue() {
    if (!this._articleLTDSettingsInput$.getValue() && this.visibilityDropdown) {
      this.visibilityDropdown.value = this.selectedItem;
    }
  }

  public onFiltering = (e) => {
    e.preventDefaultAction = true;
    this.setDebounce(e);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();


    query = (e.text !== '') ? query.where('article_name', 'contains', e.text, true) : query;
    const filterParams = {search: e.text, page_size: 100, page_no: 1};
    this.articleService.getSearchResults(filterParams).subscribe((data) => {
      if (data && data.data) {
        e.updateData(data.data.result, query);

      }
    });
  }


  clearAll(){
    this.clearModal.hide()
  this.clearForm(this.articleLTDForm)
  if (this.type === 'images') {
    this.setCleared$.next(true);
  }
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
