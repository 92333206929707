import { user } from "../../models/admin/user.model";
import { UserActionTypes, UserActions } from "../actions/adminUser.actions";
import * as _ from 'lodash';
import { formatDataSet } from 'src/app/views/common/base.component';
import cloneDeep from 'lodash.clonedeep';
export interface UserState {


    user: {
    result: user[];
    total_count?: number,
    page_no?: number,
    page_size?: number
    }
    loading: boolean;
    loaded: boolean;
    error?: Error;
    success?: boolean;

}
export const initialState: UserState = {
    user:{ result: [],
        total_count: 0,
        page_no: 0,
        page_size: 0},
    loading: false,
    loaded: false,
};
export const ReturnLoading = (State: UserState) => {
    return {
        ...State,
        loading: true,
        loaded: false,
        error: undefined,
        success: undefined,
    };
};
export function userReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {

        case UserActionTypes.LOAD_USER: {
            return ReturnLoading(state);
        }

        case UserActionTypes.LOAD_USER_SUCCESS: {
            const result: any = formatDataSet(action.payload.columns, { ...action.payload });
            return {
                ...state,
                user: {
                    result: result.data,
                    page_no: action.payload.page_no,
                    page_size: action.payload.page_size, total_count: action.payload.total_count
                },
                loading: false,
                error: undefined,
                loaded: true
            };
        }

        case UserActionTypes.LOAD_USER_FAILURE: {
            return { ...state, loading: false, error: action.payload, loaded: false };
        }

        case UserActionTypes.ADD_USER: {
            return ReturnLoading(state);
        }

        case UserActionTypes.ADD_USER_SUCCESS: {
            let array = [action.payload.user,...state.user.result];
            // array.push(action.payload.user)
            // array = _.orderBy(array, 'rank_order', 'asc');
            
            const userResult = { ...state.user };
            userResult.result=array
            return {
                ...state,
                user: userResult,
                loading: false,
                error: undefined,
                loaded: true,
                success: true
            };
        }

        case UserActionTypes.ADD_USER_FAILURE: {
            return { ...state, loading: false, error: action.payload, loaded: false };
        }

        case UserActionTypes.UPDATE_USER: {
            return ReturnLoading(state);
        }

        case UserActionTypes.UPDATE_USER_SUCCESS: {
        
            
            
            const userIndex = state.user.result.findIndex(x => x.user_code === action.payload.user.user_code);
            const user = state.user[userIndex];
            const updateduser = {  ...action.payload.user, };
            const updatedusers = [...state.user.result];

            const index = updatedusers.findIndex(x => x.user_code === action.payload.user.user_code);
            updatedusers[index] = updateduser;


            const userResult = { ...state.user };
            userResult.result=updatedusers
            
            // deliverableItemDateResult.result = _.orderBy(updatedProjectSocialDates, 'rank_order', 'asc');
            return {
                ...state, user: userResult, error: undefined, loading: false, loaded: true,
                success: true
            };
        }

        case UserActionTypes.UPDATE_USER_FAILURE: {
            return { ...state, error: action.payload, loading: false, loaded: false };
        }

        case UserActionTypes.DELETE_USER:
            return ReturnLoading(state);
      
          case UserActionTypes.DELETE_USER_SUCCESS: {
            const userResult = { ...state.user };
            var result=state.user.result.filter(item => item.user_code !== action.payload.user_code)
            userResult.result=result
            return {
              ...state, user: userResult, loading: false, error: undefined, loaded: true
            };
          }
      
          case UserActionTypes.DELETE_USER_FAILURE:
            return {...state, error: action.payload, loading: false, loaded: false};

            case UserActionTypes.SET_STATUS_NULL: {
                return { ...state, error: undefined, success: undefined };
              }

              case UserActionTypes.BLOCK_USER:
                return ReturnLoading(state);
          
              case UserActionTypes.BLOCK_USER_SUCCESS: {
                const userIndex = state.user.result.findIndex(x => x.user_code === action.payload.user.user_code);
            const user = state.user[userIndex];
            const updateduser = {  ...action.payload.user, };
            const updatedusers = [...state.user.result];

            const index = updatedusers.findIndex(x => x.user_code === action.payload.user.user_code);
            updatedusers[index] = updateduser;


            const userResult = { ...state.user };
            userResult.result=updatedusers
            
                return {
                  ...state, user: userResult, loading: false, error: undefined, loaded: true,success:true
                };
              }
          
              case UserActionTypes.BLOCK_USER_FAILURE:
                return {...state, error: action.payload, loading: false, loaded: false};
    
                
    

        default:
            return state;
    }
}