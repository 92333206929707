import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SourceType} from '../../../../../core/models/source-types.model';
import {SourceTypeAdd, SourceTypeUpdate} from '../../../../../core/store/actions/source-type.actions';
import {trimValidator} from '../../../../../app-validators';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-project-source-type-form',
  templateUrl: './source-types.component.html',
  styleUrls: ['./source-types.component.scss']
})
export class SourceTypesComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @Input() parentSourceTypes: any;

  @Output() valueChange = new EventEmitter();
  sourceTypeForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  sourceTypeFields: any;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public _sourceTypeInput$: BehaviorSubject<SourceType | null> = new BehaviorSubject<SourceType | null>(null);

  @Input() set sourceTypeInput(mode: SourceType | null) {
    this._sourceTypeInput$.next(mode);
  }

  get sourceTypeInput(): SourceType | null {
    return this._sourceTypeInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.sourceTypeForm) {
      this.generateSourceTypeForm();
    }

    this.error$ = this.store.pipe(select(store => store.activityType.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        // this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.activityType.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateSourceTypeForm() {
    this.sourceTypeForm = this.formBuilder.group({
      project_source_type_name: new FormControl('', [Validators.required,  Validators.compose([trimValidator])]),
      source_type_parent_code: new FormControl(''),
      description: new FormControl('', [Validators.maxLength(200)])
    });
  }

  onAddUpdateSourceType(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new SourceTypeAdd({source_type_name: form.value.project_source_type_name,
          parent_source_type_code: form.value.source_type_parent_code ? form.value.source_type_parent_code[0] : null,
          description: form.value.description}));
      } else {
        this.store.dispatch(new SourceTypeUpdate({project_source_type_name: form.value.project_source_type_name,
          project_source_type_code: this._sourceTypeInput$.getValue().project_source_type_code,
          parent_source_type_code: form.value.source_type_parent_code ? form.value.source_type_parent_code[0] : null,
          description: form.value.description}));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.sourceTypeForm.reset();
    this.sidebarInstance.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.sourceTypeForm) {
      this.generateSourceTypeForm();
    }

    if (this._sourceTypeInput$.getValue()) {
      this.sourceTypeForm.setValue({
        project_source_type_name: this._sourceTypeInput$.getValue().project_source_type_name,
        description: this._sourceTypeInput$.getValue().description ?  this._sourceTypeInput$.getValue().description : null,
        source_type_parent_code: this._sourceTypeInput$.getValue().parent ? [this._sourceTypeInput$.getValue().parent] : '',
      });
    }

    if (changes.parentSourceTypes) {
      this.sourceTypeFields = {
        dataSource: this.parentSourceTypes,
        value: 'project_source_type_code',
        text: 'project_source_type_name',
        child: 'children'
      };
    }
  }
  clearAll(){
    this.clearModal.hide()
    this.sourceTypeForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
