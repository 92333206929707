import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ResponseData} from '../../ResponseData.interface';
import {v4 as uuid} from 'uuid';
import {environment} from '../../../../environments/environment.prod';
import {catchError, map, publishReplay, refCount, shareReplay} from 'rxjs/operators';
const SECURITY_ROLE_URL = environment.API_URL + 'security-role';
const COMPANY_TYPE_URL = environment.API_URL + 'company-type';
const COMPANY_ROLE_URL = environment.API_URL + 'company-role';
const PROJECT_TYPE_URL = environment.API_URL + 'project-type';
const PROJECT_STATUS_URL = environment.API_URL + 'project-status';
const SOURCE_TYPE_URL = environment.API_URL + 'project-source-type';
const SOCIAL_TYPE_URL = environment.API_URL+'social-date';
const DELIVERABLE_ITEM_URL = environment.API_URL + 'deliverable-item';
const ORDER_ITEM_URL = environment.API_URL + 'order-item';
const DELIVERABLE_STATUS_URL = environment.API_URL + 'deliverable-status';
const COST_CENTER_URL = environment.API_URL + 'cost-center';
const SUBSCRIPTION_STAGE_URL = environment.API_URL + 'subscription-stage';
const CURRENCY_URL = environment.API_URL + 'currency';
const PROJECT_SOURCE_STATUS_URL = environment.API_URL + 'project-source-status';
const ARTICLE_CATEGORY_URL = environment.API_URL + 'article-category';
const EMAIL_TEMPLATE_URL = environment.API_URL + 'email-template';
const EMAIL_LOG_URL = environment.API_URL + 'email-log';
const DELIVERABLE_ITEM_DATE_URL = environment.API_URL + 'deliverable-item-date';
const ARTICLE_STAGE_URL = environment.API_URL + 'article-stage';
const ARTICLE_DATE_URL = environment.API_URL + 'article-date';
const USER_URL = environment.API_URL + 'user';




@Injectable()
export class AdminService {
  id: string;

  constructor(private http: HttpClient) {
  }

  // Source Types
  getSourceTypes(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(SOURCE_TYPE_URL, {params: parameters});
  }

  addSourceType(sourceTypeName: string, parentSourceTypeCode: string, description: string) {
    const request: any = {
      project_source_type_name: sourceTypeName,
      parent_source_type_code: parentSourceTypeCode,
      description,
      batch_id: uuid()
    };
    return this.http.post<ResponseData>(SOURCE_TYPE_URL, request);
  }

  deleteSourceType(sourceTypeCode: string) {
    return this.http.delete<ResponseData>(SOURCE_TYPE_URL + '/' + sourceTypeCode);
  }

  updateSourceType(sourceTypeCode: string, source_type_name: string, parentSourceTypeCode: string, description: string) {
    const request: any = {
      project_source_type_name: source_type_name,
      parent_source_type_code: parentSourceTypeCode,
      description,
      batch_id: uuid()
    };
    return this.http.put<ResponseData>(SOURCE_TYPE_URL + '/' + sourceTypeCode,
      request);
  }

  // Company Types
  getCompanyTypes(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_TYPE_URL, {params: parameters}).pipe(publishReplay(), refCount(), catchError(error => of(error))
    ) as Observable<any>;
  }

  addCompanyType(company_type_name: string, company_type_parent_code: string) {
    let params = {company_type_name, company_type_parent_code, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.post<ResponseData>(COMPANY_TYPE_URL, params);
  }

  deleteCompanyType(companyTypeCode: string) {
    return this.http.delete<ResponseData>(COMPANY_TYPE_URL + '/' + companyTypeCode);
  }

  updateCompanyType(companyTypeCode: string, company_type_name: string, company_type_parent_code: string) {
    const params = {company_type_name, company_type_parent_code, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(COMPANY_TYPE_URL + '/' + companyTypeCode, params);
  }

  // Company Roles
  getCompanyRoles(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_ROLE_URL, {params: parameters});
  };

  addCompanyRole(company_role_name: string, company_role_parent_code: string) {
    let params = {company_role_name, company_role_parent_code, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.post<ResponseData>(COMPANY_ROLE_URL, params);
  }

  deleteCompanyRole(companyRoleCode: string) {
    return this.http.delete<ResponseData>(COMPANY_ROLE_URL + '/' + companyRoleCode);
  }

  updateCompanyRole(companyRoleCode: string, company_role_name: string, company_role_parent_code: string) {
    const params = {company_role_name, company_role_parent_code, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(COMPANY_ROLE_URL + '/' + companyRoleCode, params);
  }

  // Project Types
  getProjectTypes(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_TYPE_URL, {params: parameters});
  };

  addProjectType(project_type_name: string, project_type_parent_code: string) {
    let params = {project_type_name, project_type_parent_code, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.post<ResponseData>(PROJECT_TYPE_URL, params);
  }

  deleteProjectType(projectTypeCode: string) {
    return this.http.delete<ResponseData>(PROJECT_TYPE_URL + '/' + projectTypeCode);
  }

  updateProjectType(projectTypeCode: string, project_type_name: string, project_type_parent_code: string) {
    const params = {project_type_name, project_type_parent_code, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(PROJECT_TYPE_URL + '/' + projectTypeCode, params);
  }

  addProjectStatus(status_name: string, parent_status_code: string, description: string, is_notes_mandatory_yn: number) {
    let params = {status_name, parent_status_code, description, batch_id: uuid(), is_notes_mandatory_yn};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.post<ResponseData>(PROJECT_STATUS_URL, params);
  }

  // Project Status
  getProjectStatus(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_STATUS_URL, {params: parameters});
  };

  deleteProjectStatus(projectStatusCode: string) {
    return this.http.delete<ResponseData>(PROJECT_STATUS_URL + '/' + projectStatusCode);
  }

  updateProjectStatus(projectStatusCode: string, status_name: string, parent_status_code: string, description: string, is_notes_mandatory_yn: number) {
    const params = {status_name, parent_status_code, description, batch_id: uuid(), is_notes_mandatory_yn};
    // Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(PROJECT_STATUS_URL + '/' + projectStatusCode, params);
  }

  // Deliverable item
  getDeliverableItem(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(DELIVERABLE_ITEM_URL, {params: parameters});
  }

  addDeliverableItem(deliverable_item_name: string, description: string, parent_deliverable_item_code: string, deliverable_item_date_code: [], type: string) {
    const params = {deliverable_item_name, description, parent_deliverable_item_code, deliverable_item_date_code, batch_id: uuid(), type};
    return this.http.post<ResponseData>(DELIVERABLE_ITEM_URL, params);
  }

  deleteDeliverableItem(deliverable_item_code: string) {
    return this.http.delete<ResponseData>(DELIVERABLE_ITEM_URL + '/' + deliverable_item_code);
  }

  updateDeliverableItem(deliverable_item_code: string, deliverable_item_name: string, description: string, parent_deliverable_item_code: string,
                        deliverable_item_date_code: [], type: string) {
    const params = {deliverable_item_name, batch_id: uuid(), description, parent_deliverable_item_code, deliverable_item_date_code, type};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(DELIVERABLE_ITEM_URL + '/' + deliverable_item_code, params);
  }

  getDeliverableDates(deliverable_item_code: string, type: string) {
    return this.http.get<ResponseData>(DELIVERABLE_ITEM_URL + '/get-deliverable-dates', {params: {deliverable_item_code, type}});
  }

  // Line item
  getLineItem(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(ORDER_ITEM_URL, {params: parameters});
  }

  addLineItem(order_item_name: string, order_item_parent_code: string, description: string) {
    let params = {order_item_name, order_item_parent_code, description, batch_id: uuid()};
    return this.http.post<ResponseData>(ORDER_ITEM_URL, params);
  }

  deleteLineItem(order_item_code: string) {
    return this.http.delete<ResponseData>(ORDER_ITEM_URL + '/' + order_item_code);
  }

  updateLineItem(order_item_code: string, order_item_name: string, order_item_parent_code: string, description: string) {
    const params = {order_item_name, order_item_parent_code, description, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(ORDER_ITEM_URL + '/' + order_item_code, params);
  }

  // Deliverable status
  getDeliverableStatus(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(DELIVERABLE_STATUS_URL, {params: parameters});
  }

  addDeliverableStatus(deliverable_status_name: string, deliverable_status_parent_code: string, description: string) {
    let params = {deliverable_status_name, deliverable_status_parent_code, description, batch_id: uuid()};
    return this.http.post<ResponseData>(DELIVERABLE_STATUS_URL, params);
  }

  deleteDeliverableStatus(deliverable_status_code: string) {
    return this.http.delete<ResponseData>(DELIVERABLE_STATUS_URL + '/' + deliverable_status_code);
  }

  updateDeliverableStatus(deliverable_status_code: string, deliverable_status_name: string, deliverable_status_parent_code: string, description: string) {
    const params = {deliverable_status_name, deliverable_status_parent_code, description, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(DELIVERABLE_STATUS_URL + '/' + deliverable_status_code, params);
  }

  // Cost Center
  getCostCenter(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COST_CENTER_URL, {params: parameters});
  }

  addCostCenter(cost_center_name: string) {
    let params = {cost_center_name, batch_id: uuid()};
    return this.http.post<ResponseData>(COST_CENTER_URL, params);
  }

  deleteCostCenter(cost_center_code: string) {
    return this.http.delete<ResponseData>(COST_CENTER_URL + '/' + cost_center_code);
  }

  updateCostCenter(cost_center_code: string, cost_center_name: string) {
    const params = {cost_center_name, batch_id: uuid()};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(COST_CENTER_URL + '/' + cost_center_code, params);
  }

  // Subscription Stage
  getSubscriptionStage(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(SUBSCRIPTION_STAGE_URL, {params: parameters});
  }

  // Currency
  getCurrency(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(CURRENCY_URL, {params: parameters});
  }

  getProjectSourceStatus(): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_SOURCE_STATUS_URL);
  }

  getArticleCategory(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(ARTICLE_CATEGORY_URL);
  }

  // Email Template
  getEmailTemplate(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(EMAIL_TEMPLATE_URL, {params: parameters});
  }

  addEmailTemplate(email_template_name: string, email_template_subject: string, email_template_content: string,
                   is_html_yn: number, parent_email_template_code: string, email_category_code: string) {
    const params = {email_template_name, email_template_subject, email_template_content,
      is_html_yn, parent_email_template_code, email_category_code, batch_id: uuid()};
    return this.http.post<ResponseData>(EMAIL_TEMPLATE_URL, params);
  }

  deleteEmailTemplate(email_template_code: string) {
    return this.http.delete<ResponseData>(EMAIL_TEMPLATE_URL + '/' + email_template_code);
  }

  updateEmailTemplate(email_template_code: string, email_template_name: string, email_template_subject: string, email_template_content: string,
                      is_html_yn: number, parent_email_template_code: string, email_category_code: string) {
    const params = {email_template_name, email_template_subject, email_template_content,
      is_html_yn, parent_email_template_code, email_category_code};
    Object.keys(params).forEach((key) => (params[key] == null) && delete params[key]);
    return this.http.put<ResponseData>(EMAIL_TEMPLATE_URL + '/' + email_template_code, params);
  }

  // Email Log
  getEmailLog(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(EMAIL_LOG_URL, {params: parameters});
  }

  getFilteredEmailLog(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(EMAIL_LOG_URL + '/filter', {params: parameters});
  }

//  Deliverable Item Date
  addDeliverableItemDate(deliverable_item_date_name: string, filter_color: string, short_code: string, filter_text_color: string, rank_order: number) {
    const params = {deliverable_item_date_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order};
    return this.http.post<ResponseData>(DELIVERABLE_ITEM_DATE_URL, params);
  }

  updateDeliverableItemDate(deliverable_item_date_code, deliverable_item_date_name: string, filter_color: string, short_code: string,
                            filter_text_color: string, rank_order: number) {
    const params = {deliverable_item_date_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order};
    return this.http.put<ResponseData>(DELIVERABLE_ITEM_DATE_URL + '/' + deliverable_item_date_code, params);
  }

  // article stage
  addArticleStage(article_stage_name: string, filter_color: string, short_code: string, filter_text_color: string, rank_order: number, description: string,
                  article_stage_parent_code) {
    const params = {article_stage_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order, description, article_stage_parent_code};
    return this.http.post<ResponseData>(ARTICLE_STAGE_URL, params);
  }

  updateArticleStage(article_stage_code, article_stage_name: string, filter_color: string, short_code: string,
                            filter_text_color: string, rank_order: number, description: string, article_stage_parent_code: string) {
    const params = {article_stage_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order, description, article_stage_parent_code};
    return this.http.put<ResponseData>(ARTICLE_STAGE_URL + '/' + article_stage_code, params);
  }

  addArticleDate(article_date_name: string, filter_color: string, short_code: string, filter_text_color: string, rank_order: number) {
    const params = {article_date_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order};
    return this.http.post<ResponseData>(ARTICLE_DATE_URL, params);
  }

  updateArticleDate(article_date_code, article_date_name: string, filter_color: string, short_code: string,
                    filter_text_color: string, rank_order: number) {
    const params = {article_date_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order};
    return this.http.put<ResponseData>(ARTICLE_DATE_URL + '/' + article_date_code, params);
  }
getUsers(page_size?: number, page_no?: number, sort_by?:any, sort_order?:any){
  const request: any = {page_size, page_no,sort_by,sort_order};
  return this.http.get<ResponseData>(USER_URL+'/list', {params: request});
}
addUsers(first_name:string, last_name:string,email:string,security_role_code:any){
  var param={}
  if(last_name !=''){
  param={first_name, last_name,email,security_role_code,batch_id: uuid()}}

 else{
  param={first_name,email,security_role_code,batch_id: uuid()}
 }
  return this.http.post<ResponseData>(USER_URL,param);
  }
updateUsers(user_code:string,first_name:string, last_name:string,security_role_code:any){
  var param={}
  if(last_name !=''){
    param={first_name, last_name,security_role_code,batch_id: uuid()}}
    else{
  param={first_name,security_role_code,batch_id: uuid()}}
  return this.http.put<ResponseData>(USER_URL+'/'+user_code,param);
}
blockUsers(user_code:string,is_blocked_yn:number){
  var param={is_blocked_yn}
  
  return this.http.post<ResponseData>(USER_URL+'/block/'+user_code,param);
}

deleteUsers(user_code:any){
  return this.http.delete<ResponseData>(USER_URL+'/'+user_code);
}
getUserDetails(userCode: any) {
  return this.http.get<ResponseData>(USER_URL + '/' + userCode);
}
getSecurityRole() {
  return this.http.get<ResponseData>(SECURITY_ROLE_URL);
}

updateEmailAccount(data) {
  return this.http.post<ResponseData>(`${environment.API_URL}auth/email-account`, data);
}

sendTeatEmail(data) {
  return this.http.post<ResponseData>(`${environment.API_URL}send-test-email`, data);
}

// getSingleUser(user_code:any){
//   return this.http.get<ResponseData>(USER_URL+'/'+user_code);
// }

DeleteAppPassword(usercode){
  return this.http.delete<ResponseData>(`${environment.API_URL}auth/email-account/`+usercode);
}
}
