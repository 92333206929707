export const environment = {
  production: true,
  limitedUsers: ["shahlin"],
  SENTRY_DSN: '',
  API_URL: 'https://canvas-dev-b-api.lovethat.design/api/canvas/v1.0/',
  RELEASE_VERSION: '0.3.22.02',
  CHECK_SYNC_TIMEOUT: 10000,
  usersAllowToSync: ['shoaib', 'sharika', 'stephen', 'hasna'],
  LTD_API_URL: 'https://service.qa2.lovethatdesign.com/canvas/',
  COMMUNITY_API_KEY:'osmt4mDlzZtw2dJiGf',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCz7vAOjFWhrPvFOZ_Xe80D6NQC2a7yh3Y',
  GOOGLE_MAPS_API_KEY_LTD: 'AIzaSyCz7vAOjFWhrPvFOZ_Xe80D6NQC2a7yh3Y'
};
