import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import {Observable, throwError} from 'rxjs';

import { ResponseData } from '../../ResponseData.interface';
import {environment} from '../../../../environments/environment.prod';
import {AppState} from '../../index';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';

const PROJECT_URL = environment.API_URL + 'project';
const PROJECT_ACTIVITY_URL = environment.API_URL + 'project-activity';
const PROJECT_ACTIVITY_LIST_URL = environment.API_URL + 'project-activity/list-activity';
const PROJECT_COMPANY_ROLE_URL =  environment.API_URL + 'project-company-role';
const PROJECT_SOCIAL_URL =  environment.API_URL + 'project-social-dates';

@Injectable()
export class ProjectService {
  id: string;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  getProjects(parameters): Observable<ResponseData> {
    if (_.get(parameters, 'user_code')) {
      return this.http.get<ResponseData>(PROJECT_URL + '/get-assigned', {params: parameters});
    } else {
      return this.http.get<ResponseData>(PROJECT_URL, {params: parameters});
    }
  }

  addProject(project_name: string, project_type: [], address: string, city_name: string,
    country_id: string, project_description: string, tags: string, drive_link: string, status_code: string, display_name: string,project_source: any): Observable<ResponseData> {

    this.id = uuid();
    return this.http.post<ResponseData>(PROJECT_URL, {
      project_name, project_type_code: project_type, address, city_name, country_code: country_id,
      project_description, project_tag: tags, drive_link, batch_id: this.id, status_code, display_name, project_source: {...project_source, batch_id: this.id}
    });
  }

  updateProject(code: string, project: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...project, batch_id: this.id};
    return this.http.put<ResponseData>(PROJECT_URL + '/' + code, request);
  }

  deleteProjects(projectCode: string) {
    return this.http.delete<ResponseData>(PROJECT_URL + '/' + projectCode);
  }

  getProject(projectCode: string) {
    return this.http.get<ResponseData>(PROJECT_URL + '/' + projectCode);
  }

  getProjectSources(projectCode: string) {
    return this.http.get<ResponseData>(PROJECT_URL + '/' + projectCode);
  }

  getFilteredResults(parameters) {
    return this.http.get<ResponseData>(PROJECT_URL + '/filter', {params: parameters});
  }

  // getProjectActivityFilteredResults(parameters) {
  //   return this.http.get<ResponseData>(PROaJECT_ACTIVITY_URL + '/filter', {params: parameters});
  // }

  addUpdateProjectCompanyRole(project_code: string, company_role_code: string, company_code: string, contact_code: string, company_contact_code: string, isUpdate: boolean,
                        project_role_code: string) {

    let companyContact = {};
    if (contact_code) {
      if (company_code) {
        companyContact = {contact_code, company_code};
      } else {
        companyContact = {contact_code};
      }
    } else {
      companyContact = {company_code};
    }

    this.id = uuid();
    let request = {
      project_code, company_role_code, batch_id: this.id, company_contact_code
    };

    request = {...request, ...companyContact};

    if (isUpdate) {
      delete request.project_code;
      return this.http.put<ResponseData>(PROJECT_COMPANY_ROLE_URL + '/' + project_role_code, request);
    } else {
      return this.http.post<ResponseData>(PROJECT_COMPANY_ROLE_URL, request);
    }
  }

  loadProjectRoles(projectCode: string) {
    return this.http.get<ResponseData>(PROJECT_COMPANY_ROLE_URL + '/' + projectCode);
  }
  loadProjectSocials(projectCode: string){
    return this.http.get<ResponseData>(PROJECT_SOCIAL_URL + '/' + projectCode);
  }
  addProjectSocial(project_code:string,project_social_date:any,social_date_code:any,comment:any,link:any):Observable<ResponseData>{
    
    return this.http.post<ResponseData>(PROJECT_SOCIAL_URL + '/',{project_code,project_social_date,social_date_code,comment,link,batch_id:uuid()} );
  }
  updateProjectSocial(project_social_code: string,project_social_date: string,comment:any,link:any):Observable<ResponseData>{
    
    return this.http.put<ResponseData>(PROJECT_SOCIAL_URL + '/'+ project_social_code,{project_social_date,comment,link} );
  }
  deleteProjectSocials(projectSocialCode: string) {
    return this.http.delete<ResponseData>(PROJECT_SOCIAL_URL + '/' + projectSocialCode);
  }
  deleteProjectRole(projectRoleCode: string) {
    return this.http.delete<ResponseData>(PROJECT_COMPANY_ROLE_URL + '/' + projectRoleCode);
  }

  getProjectHistory(projectCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_URL + '/' + projectCode + '/transaction-history');
  }

  getProjectActivityHistory(projectCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_ACTIVITY_URL + '/' + projectCode + '/transaction-history');
  }

  getSearchResults(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_URL + '/search', {params: parameters});
  }

  updateBulkProjects(parameters): Observable<ResponseData> {
    parameters = {...parameters, batch_id: uuid()};
    return this.http.put<ResponseData>(PROJECT_URL + '/bulk-update', parameters);
  }

}
