import { Action } from '@ngrx/store';
import { Project } from '../../models/project.model';
import { Activity } from '../../models/activity.model';
import { ProjectSource } from '../../models/project-source.model';
import {ProjectRole} from '../../models/project-role.model';
import {TransactionHistory} from '../../models/transaction-history.model';
import {CompanyActionTypes} from './company.actions';
import {Company} from '../../models/company.model';
import {ProjectActionTypes} from './project.actions';

export enum BrandActionTypes {

  LOAD = '[BRAND] Load Action',
  LOAD_SUCCESS = '[BRAND] Load Success Action',
  LOAD_FAILURE = '[BRAND] Load Failure Action',

  SEARCH_BRAND = '[BRAND] Search Brand',
  SEARCH_BRAND_SUCCESS = '[BRAND] Search Brand Success',
  SEARCH_BRAND_FAILURE = '[BRAND] Search Brand Failure',

  SET_SUCCESS_NULL = '[BRAND] Set Success Null',

}

export class BrandLoad implements Action {
  readonly type = BrandActionTypes.LOAD;
  constructor(public payload?: {page_size?: number, page_no?: number }) { }
}

export class BrandLoadSuccess implements Action {
  readonly type = BrandActionTypes.LOAD_SUCCESS;
  constructor(public payload: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class BrandLoadFailure implements Action {
  readonly type = BrandActionTypes.LOAD_FAILURE;
  constructor(public payload: Error) { }
}

export class BrandSearch implements Action {
  readonly type = BrandActionTypes.SEARCH_BRAND;

  constructor(public payload: { search?: string, page_no?: number, page_size?: number }) {
  }
}

export class BrandSearchSuccess implements Action {
  readonly type = BrandActionTypes.SEARCH_BRAND_SUCCESS;

  constructor(public payload: { result: [], total_count: number, page_no: number, page_size: number }) {
    
  }
}

export class BrandSearchFailure implements Action {
  readonly type = BrandActionTypes.SEARCH_BRAND_FAILURE;

  constructor(public payload: Error) {
  }
}

export type BrandActions = BrandLoad | BrandLoadSuccess | BrandLoadFailure |
BrandSearch | BrandSearchSuccess | BrandSearchFailure;
