import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {ResponseData} from '../ResponseData.interface';

const IMAGE_URL = environment.API_URL + 'upload';
const GET_IMAGE_URL = environment.API_URL + 'get-image';

@Injectable()
export class ImageService {


  constructor(private http: HttpClient) {
  }

  uploadImage(parameters) {
   
    return this.http.post<ResponseData>(IMAGE_URL, parameters);
  }

  getImage(referenceID) {
    return this.http.get(GET_IMAGE_URL + '/' + referenceID, {responseType: 'blob'});
  }
}
