import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../ResponseData.interface';
import {environment} from '../../../../environments/environment.prod';

const LOGIN_URL = environment.API_URL + 'auth/login';
const LOGIN_TOKEN_URL = environment.API_URL + 'auth/token-login';
const CHANGE_PASSWORD_URL = environment.API_URL + 'auth/change-password';
const CHANGE_PROFILE_URL = environment.API_URL + 'auth/profile';


@Injectable()
export class AuthService {
  id: string;
  

  constructor(private http: HttpClient, private router: Router) { }

  // Authentication/Authorization
  login(user_name: string, password: string): Observable<ResponseData> {
    this.id = uuid();
    return this.http.post<ResponseData>(LOGIN_URL, { user_name, password, batch_id: this.id });
  }

  loginByToken() {
    return this.http.get<ResponseData>(LOGIN_TOKEN_URL);
  }

  loadUser(): void {
    this.router.navigateByUrl('');
  }

  logout(): void {
    this.router.navigate(['auth/login']);
  }

  changePassword(user_name: string, old_password: string, new_password: string): Observable<ResponseData> {
    this.id = uuid();
    return this.http.put<ResponseData>(CHANGE_PASSWORD_URL, { user_name, old_password, new_password, batch_id: this.id });
  }

  changeProfile(first_name: string, last_name: string,email:string): Observable<ResponseData> {
    this.id = uuid();
    return this.http.put<ResponseData>(CHANGE_PROFILE_URL, { first_name,last_name,email,batch_id: this.id });
  }

  // Forgot Password

  // Register
}
