<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2>Update Collection for Article</h2>

    <form id="formId" [formGroup]="articleCollectionForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="articleCollectionForm.controls.collection_code.value == ''">
        <ejs-multiselect #contact id='collection_code' formControlName="collection_code" [dataSource]='collectionList'
                          [fields]='collectionFields' [showClearButton]=true (filtering)='onFiltering($event,"contact")'
                          placeholder='Collection' [allowFiltering]='true' mode='CheckBox' showClearButton="true"
                          cssClass="e-filled" floatLabelType="Auto"
                          (blur)="articleCollectionForm.controls.collection_code.markAsTouched()"></ejs-multiselect>
        <div *ngIf="articleCollectionForm.controls.collection_code.errors">
          <div
            *ngIf="articleCollectionForm.controls.collection_code.errors.required && articleCollectionForm.controls.collection_code.touched"
            class="e-error">
            Collection is required.
          </div>
        </div>
      </div>


      <div *ngIf="articleCollectionForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button"
                data-ripple="true" (click)="clear()">Clear
        </button>
        <button  id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddArticleCollectionLink(articleCollectionForm)" [disabled]="formSent" data-ripple="true"> Update
          Collection for Article
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
