import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
@Injectable()
export class ActionsService {

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  private Isdisabled = new Subject<any>();
  currentStatus = this.Isdisabled.asObservable();
  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
getIsDisabled(status:string){
  this.Isdisabled.next(status)
}
}
