import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import * as _ from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { websiteValidator } from 'src/app/app-validators';
import { AppState } from 'src/app/core';
import { Tags } from 'src/app/core/models/tags.model';
import { ArticleSetSuccessNull, UpdateArticleGeneral } from 'src/app/core/store/actions/article.actions';
import { CompanyUpdateGeneral } from 'src/app/core/store/actions/company.actions';

@Component({
  selector: 'app-article-general-form',
  templateUrl: './article-general.component.html',
  styleUrls: ['./article-general.component.scss']
})
export class ArticleGeneralComponent implements OnInit {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('internalTag') public internalTag: MultiSelectComponent;
  @ViewChild('externalTag') public externalTag: MultiSelectComponent;

  @Input() allTags: Observable<Array<Tags>>;
  @Input() articleCode: string;
  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  public formSent: boolean;

  successCompany$: Observable<boolean>;
  articleGeneralForm: FormGroup;
  socialMediaCounter = [1];
  chosenSocialMediaPlatforms = [];


  tagFields = {text: 'tag', value: 'tag'};

  public tagModelData = [];
  modelData = [];
  selectedTag = [];
  tagSelected = '';


  public _articleGeneralInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set articleGeneralInput(mode: any | null) {
    this._articleGeneralInput$.next(mode);
  }

  get articleGeneral(): any | null {
    return this._articleGeneralInput$.getValue();
  }

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.generateForm();

    this.successCompany$ = this.store.pipe(select(store => store.articles.success));
    this.successCompany$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ArticleSetSuccessNull());
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
    this.valueChange.emit('success');
    this.clearForm();
  }

  clearForm() {
    this.chosenSocialMediaPlatforms = [];
    this.articleGeneralForm.reset();
  }

  generateForm() {
    this.articleGeneralForm = new FormGroup({
      internal_tags: new FormControl(''),
      external_tags: new FormControl(''),
    });
  }


  onUpdateArticleGeneral(form: FormGroup) {
    if (!form.invalid) {
      const formValues = form.getRawValue();

      this.store.dispatch(new UpdateArticleGeneral({
        articleCode: this.articleCode,
        articleGeneral: {
          internal_tag: formValues.internal_tags,
          external_tag: formValues.external_tags

        }
      }));
    }
  }





  ngOnChanges(changes: SimpleChanges) {
    if (changes.allTags) {

    }

    if (this._articleGeneralInput$.getValue()) {

      const publicTags = [];
      const privateTags = [];

      if (this._articleGeneralInput$.getValue().public_tag) {
        for (const tag of this._articleGeneralInput$.getValue().public_tag) {
          publicTags.push(tag.tag);
        }
      }

      if (this._articleGeneralInput$.getValue().private_tag) {
        for (const tag of this._articleGeneralInput$.getValue().private_tag) {
          privateTags.push(tag.tag);
        }
      }

      this.articleGeneralForm.patchValue({
        internal_tags: privateTags ? privateTags : null,
        external_tags: publicTags ? publicTags : null,
      });

    }
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if (!this.modelData.includes(this.tagSelected)) {
      this.externalTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.tags.setValue(_.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }
  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
