import {CompanyRoleActions, CompanyRoleActionsType} from '../actions/company-role.actions';

export interface CompanyRoleState {
  companyRole?: {
    result?: any,
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
}

export const initialState: CompanyRoleState = {
  companyRole: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  error: undefined,
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: CompanyRoleState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: undefined
  };
};

export function companyRoleReducer(state: CompanyRoleState = initialState, action: CompanyRoleActions): CompanyRoleState {
  switch (action.type) {

    case CompanyRoleActionsType.LOAD_COMPANY_ROLE: {
      return ReturnLoading(state);
    }

    case CompanyRoleActionsType.LOAD_COMPANY_ROLE_SUCCESS: {
      let companyRoleList = [...state.companyRole.result, ...action.payload.result];

      companyRoleList = companyRoleList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.company_role_code === thing.company_role_code && t.company_role_name === thing.company_role_name
        ))
      );

      state = {
        ...state, companyRole: {
          result: companyRoleList, page_no: action.payload.page_no, page_size: action.payload.page_size,
          total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true,
      };

      return state;
    }

    case CompanyRoleActionsType.LOAD_COMPANY_ROLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyRoleActionsType.ADD_COMPANY_ROLE: {
      return ReturnLoading(state);
    }

    case CompanyRoleActionsType.ADD_COMPANY_ROLE_SUCCESS: {
      let newCompanyRole;
      if (action.payload.parentCode) {
        const parentCompanyRole = {...find([...state.companyRole.result], action.payload.parentCode)};
        const childrenArray = [...parentCompanyRole.children];
        childrenArray.push(action.payload.companyRole);
        parentCompanyRole.children = childrenArray;
        const findParent = findAndReplace([...state.companyRole.result], action.payload.parentCode, parentCompanyRole);
        newCompanyRole = findParent;
      } else {
        newCompanyRole = [action.payload.companyRole, ...state.companyRole.result];
      }

      return {
        ...state, loading: false, companyRole: {
          result: newCompanyRole
        }, error: undefined, loaded: true,
        success: true
      };
    }

    case CompanyRoleActionsType.ADD_COMPANY_ROLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case CompanyRoleActionsType.UPDATE_COMPANY_ROLE: {
      return ReturnLoading(state);
    }

    case CompanyRoleActionsType.UPDATE_COMPANY_ROLE_SUCCESS: {
      const companyRoleList = findAndDelete([...state.companyRole.result], action.payload.companyRole.company_role_code);
      let updateCompanyRole;
      if (action.payload.parent_type_code) {
        const parentCompanyRole = {...find(companyRoleList, action.payload.parent_type_code)};
        const childrenArray = [...parentCompanyRole.children];
        childrenArray.push(action.payload.companyRole);
        parentCompanyRole.children = childrenArray;
        const findParent = findAndReplace(companyRoleList, action.payload.parent_type_code, parentCompanyRole);
        updateCompanyRole = findParent;
      } else {
        updateCompanyRole = [action.payload.companyRole, ...companyRoleList];
      }

      return {
        ...state, companyRole: {result: updateCompanyRole}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CompanyRoleActionsType.UPDATE_COMPANY_ROLE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyRoleActionsType.DELETE_COMPANY_ROLE:
      return ReturnLoading(state);

    case CompanyRoleActionsType.DELETE_COMPANY_ROLE_SUCCESS: {
      const companyRoleList = findAndDelete([...state.companyRole.result], action.payload.companyRoleCode);
      return {
        ...state,
        companyRole: {result: companyRoleList},
        loading: false,
        error: undefined,
        loaded: true,
        success:true
      };
    }

    case CompanyRoleActionsType.DELETE_COMPANY_ROLE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyRoleActionsType.RESET_ERROR:
      return ReturnLoading(state);

    default:
      return state;
  }
}

function find(array, id) {
  let result;
  array.some(o => result = o.company_role_code === id ? o : find(o.children || [], id));
  return result;
}

function findAndReplace(companyRoleList, findValue, replaceValue) {
  for (const companyRole in companyRoleList) {
    if (companyRoleList[companyRole].company_role_code === findValue) {
      companyRoleList[companyRole] = replaceValue;
      return companyRoleList;
    }

    if (companyRoleList[companyRole].children) {
      const children = findAndReplace([...companyRoleList[companyRole].children], findValue, replaceValue);
      const companyRoleListChildCopy: any = {...companyRoleList[companyRole]};
      companyRoleListChildCopy.children = children;
      companyRoleList[companyRole] = companyRoleListChildCopy;
    }
  }
  return companyRoleList;
}

function findAndDelete(companyRoleList, findValue) {
  for (const companyRole in companyRoleList) {
    if (companyRoleList[companyRole].company_role_code === findValue) {
      delete companyRoleList[companyRole];
      const companyRoleArray = companyRoleList.filter((el) => {
        return el != null;
      });
      return companyRoleArray;
    }

    if (companyRoleList[companyRole].children) {
      const children = findAndDelete([...companyRoleList[companyRole].children], findValue);
      const companyTypeListChildCopy: any = {...companyRoleList[companyRole]};
      companyTypeListChildCopy.children = children;
      companyRoleList[companyRole] = companyTypeListChildCopy;
    }
  }
  return companyRoleList;
}
