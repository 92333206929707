import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {NumericTextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeliverableItemDate} from '../../../../../core/models/admin/deliverable-item-date.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {
  AddArticleDate,
  AddDeliverableItemDate,
  MetaDataResetError, UpdateArticleDate,
  UpdateDeliverableItemDate
} from '../../../../../core/store/actions/common.actions';
import {ArticleDate} from '../../../../../core/models/admin/article-date.model';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-article-date-form',
  templateUrl: './article-date.component.html',
  styleUrls: ['./article-date.component.scss']
})
export class ArticleDateComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @ViewChild('rankOrder') rankOrder: NumericTextBoxComponent;

  @Input() maxValue: number;
  @Output() valueChange = new EventEmitter();


  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  articleDateForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  public minValue: number = 1;

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _articleDateInput$: BehaviorSubject<ArticleDate | null> = new BehaviorSubject<ArticleDate | null>(null);

  @Input() set articleDateInput(mode: ArticleDate | null) {
    this._articleDateInput$.next(mode);
  }

  get articleDateInput(): ArticleDate | null {
    return this._articleDateInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.articleDateForm) {
      this.generateArticleDateForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateArticleDateForm() {
    this.articleDateForm = this.formBuilder.group({
      article_date_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      filter_color: new FormControl('', [Validators.required]),
      short_code: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      filter_text_color: new FormControl('', [Validators.required]),
      rank_order: new FormControl('', [Validators.required]),
    });
  }

  onAddUpdateArticleDate(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddArticleDate({article_date_name: form.value.article_date_name,
          filter_color: form.value.filter_color, short_code: form.value.short_code, filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      } else {
        this.store.dispatch(new UpdateArticleDate({article_date_name: form.value.article_date_name,
          filter_color: form.value.filter_color, short_code: form.value.short_code,
          article_date_code: this._articleDateInput$.getValue().article_date_code,
          filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.articleDateForm.reset();
    this.sidebarInstance.hide();

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.articleDateForm) {
      this.generateArticleDateForm();
    }

    if (this._articleDateInput$.getValue()) {
      this.articleDateForm.setValue({
        article_date_name: this._articleDateInput$.getValue().article_date_name,
        filter_color: this._articleDateInput$.getValue().filter_color,
        short_code: this._articleDateInput$.getValue().short_code,
        filter_text_color: this._articleDateInput$.getValue().filter_text_color,
        rank_order: this._articleDateInput$.getValue().rank_order,
      });
    }
  }
  clearAll(){
    this.clearModal.hide()
  this.articleDateForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }

}
