import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import {
  Breadcrumb,
  SubheaderService,
} from "src/app/core/_base/layout/services/subheader.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { AppService } from "../../../core/services/app.service";
import { BaseComponent } from "../../common/base.component";

@Component({
  selector: " app-subheader",
  templateUrl: "./subheader.component.html",
  styleUrls: ["./subheader.component.scss"],
})
export class SubheaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  fixed = true;
  clear = false;
  width = "fluid";
  subheaderClasses = "";
  subheaderContainerClasses = "";
  title = "";
  page = "";
  breadcrumbs: Breadcrumb[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    public subheaderService: SubheaderService,
    private router: Router,
    protected appService: AppService,
  ) {
    super(appService);
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.subheaderService.title$.subscribe((bt) => {
        // breadcrumbs title sometimes can be undefined
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.page = bt.page;
          });
        }
      }),
    );

    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
        });
      }),
    );
  }

  navigateToPage(url: string) {
    this.router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  isCompanyContactProjectLink(): boolean {
    return (
      this.router.url.indexOf("/company/details") > -1 ||
      this.router.url.indexOf("/project/details") > -1 ||
      this.router.url.indexOf("/contact/details") > -1 ||
      this.router.url.indexOf("/orders/details") > -1 ||
      this.router.url.indexOf("/contact") > -1 ||
      this.router.url.indexOf("/article_news/details") > -1 ||
      this.router.url.indexOf("/events/details") > -1 ||
      this.router.url.indexOf("/community-product/details") > -1
    );
  }

  isEmailTemaplate() {
    return this.router.url.indexOf("/email-templates/details") > -1;
  }

  checkEditPermission() {
    if (this.router.url.indexOf("/company/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.COMPANY);
    } else if (this.router.url.indexOf("/project/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.PROJECT);
    } else if (
      this.router.url.indexOf("/contact/details/") > -1 ||
      this.router.url.indexOf("/contact") > -1
    ) {
      return this.canUpdate(this.FEATURE_INSTANCE.CONTACT);
    } else if (this.router.url.indexOf("/orders/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.ORDER);
    } else if (this.router.url.indexOf("/article_news/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.EVENTS);
    } else if (this.router.url.indexOf("/events/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.ARTICLE);
    } else if (this.router.url.indexOf("/community-product/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.COMMUNITY);
    }
  }

  checkDeletePermission() {
    if (this.router.url.indexOf("/company/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.COMPANY);
    } else if (this.router.url.indexOf("/project/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.PROJECT);
    } else if (this.router.url.indexOf("/contact/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.CONTACT);
    } else if (this.router.url.indexOf("/orders/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.ORDER);
    } else if (this.router.url.indexOf("/article_news/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.EVENTS);
    } else if (this.router.url.indexOf("/events/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.ARTICLE);
    }
  }
}
