// Actions

// Models
import * as moment from 'moment';
import {CollectionActions, CollectionActionTypes} from '../actions/collection.action';

export interface CollectionState {
  collections: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
  db?: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
}

export const initialState: CollectionState = {
    collections: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: CollectionState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: false,
  };
};

export function collectionReducer(state: CollectionState = initialState, action: CollectionActions): CollectionState {
  switch (action.type) {

    case CollectionActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case CollectionActionTypes.LOAD_SUCCESS: {
      return {
        ...state, collections: {
          result: action.payload.result,
          total_count:  action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, db: {
          result:  action.payload.result,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };
    }

    case CollectionActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CollectionActionTypes.SEARCH_COLLECTION: {
      return ReturnLoading(state);
    }

    case CollectionActionTypes.SEARCH_COLLECTION_SUCCESS: {

      return {
        ...state, collections: {
          result: action.payload.result, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case CollectionActionTypes.SEARCH_COLLECTION_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    default:
      return state;
  }

}
