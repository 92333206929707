import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActionsService } from "../../../../core/services/actions.service";
import { Router } from "@angular/router";
import { BaseComponent } from "../../../common/base.component";
import { AppService } from "../../../../core/services/app.service";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/core/services/common.service";

@Component({
  selector: "app-action-dropdown-menu",
  templateUrl: "./action-dropdown-menu.component.html",
})
export class ActionDropdownMenuComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  message: string;
  status: Subscription;
  stat: any;

  constructor(
    private actionsService: ActionsService,
    private router: Router,
    protected appService: AppService,
    private commonService: CommonService,
  ) {
    super(appService);
  }

  ngOnInit(): void {
    this.actionsService.currentMessage.subscribe(
      (message) => (this.message = message),
    );
    if (this.router.url.indexOf("/email-templates/details") > -1) {
      this.status = this.actionsService.currentStatus.subscribe((status) => {
        this.stat = status;
      });
    }
  }

  edit() {
    this.actionsService.changeMessage("edit");
  }

  merge() {
    this.actionsService.changeMessage("merge");
  }

  delete() {
    this.actionsService.changeMessage("delete");
  }

  disable() {
    this.actionsService.changeMessage(this.stat);
  }

  preview() {
    this.actionsService.changeMessage("preview");
  }

  checkEditPermission() {
    if (this.router.url.indexOf("/company/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.COMPANY);
    } else if (this.router.url.indexOf("/project/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.PROJECT);
    } else if (
      this.router.url.indexOf("/contact/details/") > -1 ||
      this.router.url.indexOf("/contact") > -1
    ) {
      return this.canUpdate(this.FEATURE_INSTANCE.CONTACT);
    } else if (this.router.url.indexOf("/orders/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.ORDER);
    } else if (this.router.url.indexOf("/article_news/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.ARTICLE);
    } else if (this.router.url.indexOf("/events/details/") > -1) {
      return this.canUpdate(this.FEATURE_INSTANCE.EVENTS);
    } else if (this.router.url.indexOf("/community-product/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.COMMUNITY);
    }
  }

  checkDeletePermission() {
    if (this.router.url.indexOf("/company/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.COMPANY);
    } else if (this.router.url.indexOf("/project/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.PROJECT);
    } else if (this.router.url.indexOf("/contact/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.CONTACT);
    } else if (this.router.url.indexOf("/orders/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.ORDER);
    } else if (this.router.url.indexOf("/article_news/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.ARTICLE);
    } else if (this.router.url.indexOf("/events/details/") > -1) {
      return this.canDelete(this.FEATURE_INSTANCE.EVENTS);
    }
  }

  isVisible() {
    return (
      this.router.url.indexOf("/contact") > -1 &&
      this.router.url.indexOf("/contact/details") === -1
    );
  }

  isVisibleContact() {
    return (
      this.router.url.indexOf("/contact/details") > -1 ||
      this.router.url.indexOf("/orders/details/") > -1 ||
      this.router.url.indexOf("/company/details/") > -1 ||
      this.router.url.indexOf("/project/details/") > -1 ||
      this.router.url.indexOf("/article_news/details/") > -1 ||
      this.router.url.indexOf("/events/details/") > -1 ||
      this.router.url.indexOf("/community-product/details") > -1
    );
  }

  isDisable() {
    return this.router.url.indexOf("/email-templates/details") > -1;
  }
  isEmailTemplates() {
    return this.router.url.indexOf("/email-templates/details") > -1;
  }
  ngOnDestroy(): void {
    if (this.router.url.indexOf("/email-templates/details") > -1) {
      this.status.unsubscribe();
    }
  }
}
