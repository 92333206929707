import { Action } from "@ngrx/store";
import { Event } from "../../models/event.model";
import { CompanyActionTypes } from "./company.actions";

export enum EventsActionTypes {
  FILTER = "[EVENTS] Load Filter Events List",
  FILTER_SUCCESS = "[EVENTS] Load Filter Events List Success Action",
  FILTER_FAILURE = "[EVENTS] Load Filter Events List Failure Action",

  ADD = "[EVENTS] Add Event",
  ADD_SUCCESS = "[EVENTS] Add Event Success Action",
  ADD_FAILURE = "[EVENTS] Add Event Failure Action",

  DELETE = "[EVENTS] Delete Event",
  DELETE_SUCCESS = "[EVENTS] Delete Event Success Action",
  DELETE_FAILURE = "[EVENTS] Delete Event Failure Action",

  DELETE_ATTENDEE = "[EVENTS] Delete Attendee",
  DELETE_ATTENDEE_SUCCESS = "[EVENTS] Delete Attendee Success",
  DELETE_ATTENDEE_FAILURE = "[EVENTS] Delete Attendee Failure",

  EVENT_UPDATE = "[EVENTS] Update Event",
  EVENT_UPDATE_SUCCESS = "[EVENTS] Update Event Success Action",
  EVENT_UPDATE_FAILURE = "[EVENTS] Update Event Failure Action",

  SET_SUCCESS_NULL = "[EVENTS] Set Success Null",
  SET_ERROR_NULL = "[EVENTS] Set Error Null",

  GET_DETAILS = "[EVENTS] Get Event Details",
  GET_DETAILS_SUCCESS = "[EVENTS] Get Event Details Success",
  GET_DETAILS_FAILURE = "[EVENTS] Get Event Details Failure",

  UPDATE_ATTENDEE = "[EVENTS] Update Attendee",
  UPDATE_ATTENDEE_SUCCESS = "[EVENTS] Update Attendee Success",
  UPDATE_ATTENDEE_FAILURE = "[EVENTS] Update Attendee Failure",
}

export class EventsFilter implements Action {
  readonly type = EventsActionTypes.FILTER;
  constructor(
    public payload: {
      global?: string; // Search query
      page_size?: number;
      page_no?: number;
      created_at_start?: any;
      created_at_end?: any;
      verified_by?: string;
      routerParams?: object;
    },
  ) {}
}

export class EventsFilterSuccess implements Action {
  readonly type = EventsActionTypes.FILTER_SUCCESS;
  constructor(
    public payload: {
      data?: [];
      total_count: number;
      page_size: number;
      page_no: number;
    },
  ) {}
}

export class EventsFilterFailure implements Action {
  readonly type = EventsActionTypes.FILTER_FAILURE;
  constructor(public payload: Error) {}
}

export class EventAdd implements Action {
  readonly type = EventsActionTypes.ADD;
  constructor(
    public payload: {
      event_name: string;
      slug: string;
      city_name: string;
      country_code: string;
      address: string;
      start_date: string;
      end_date: string;
      description: string;
    },
  ) {}
}
export class EventAddSuccess implements Action {
  readonly type = EventsActionTypes.ADD_SUCCESS;
  constructor(public payload: { event: Event }) {}
}
export class EventAddFailure implements Action {
  readonly type = EventsActionTypes.ADD_FAILURE;
  constructor(public payload: Error) {}
}

export class EventsSetStatusNull implements Action {
  readonly type = EventsActionTypes.SET_SUCCESS_NULL;
  constructor() {}
}

export class EventsSetErrorNull implements Action {
  readonly type = EventsActionTypes.SET_ERROR_NULL;
  constructor() {}
}

export class EventGetDetails implements Action {
  readonly type = EventsActionTypes.GET_DETAILS;
  constructor(public payload: { event_code: string }) {}
}

export class EventGetDetailsSuccess implements Action {
  readonly type = EventsActionTypes.GET_DETAILS_SUCCESS;
  constructor(public payload: { event: Event }) {}
}

export class EventGetDetailsFailure implements Action {
  readonly type = EventsActionTypes.GET_DETAILS_FAILURE;
  constructor(public payload: Error) {}
}

export class EventUpdate implements Action {
  readonly type = EventsActionTypes.EVENT_UPDATE;
  constructor(
    public payload: {
      event_code: string;
      event_name: string;
      slug?: string;
      city_name: string;
      country_code: string;
      address: string;
      start_date: string;
      end_date: string;
      description: string;
    },
  ) {}
}

export class EventUpdateSuccess implements Action {
  readonly type = EventsActionTypes.EVENT_UPDATE_SUCCESS;
  constructor(public payload: { event: Event }) {}
}

export class EventUpdateFailure implements Action {
  readonly type = EventsActionTypes.EVENT_UPDATE_FAILURE;
  constructor(public payload: Error) {}
}

export class EventUpdateAttendee implements Action {
  readonly type = EventsActionTypes.UPDATE_ATTENDEE;
  constructor(
    public payload: {
      event_code: string;
      company_contact_code: {
        code?: string;
        contact_code?: string;
        has_attended?: boolean | number;
        has_attended_yn?: boolean | number;
        comment: string;
      }[];
    },
  ) {}
}

export class EventUpdateAttendeeSuccess implements Action {
  readonly type = EventsActionTypes.UPDATE_ATTENDEE_SUCCESS;
  constructor(public payload: { event: Event }) {}
}

export class EventUpdateAttendeeFailure implements Action {
  readonly type = EventsActionTypes.UPDATE_ATTENDEE_FAILURE;
  constructor(public payload: Error) {}
}
export class EventDelete implements Action {
  readonly type = EventsActionTypes.DELETE;
  constructor(public payload: { event_code: string }) {}
}

export class EventDeleteSuccess implements Action {
  readonly type = EventsActionTypes.DELETE_SUCCESS;
  constructor(public payload: { event_code: string }) {}
}

export class EventDeleteFailure implements Action {
  readonly type = EventsActionTypes.DELETE_FAILURE;
  constructor(public payload: Error) {}
}

export class EventDeleteAttendee implements Action {
  readonly type = EventsActionTypes.DELETE_ATTENDEE;
  constructor(
    public payload: { event_code: string; company_contact_code: string[] },
  ) {}
}

export class EventDeleteAttendeeSuccess implements Action {
  readonly type = EventsActionTypes.DELETE_ATTENDEE_SUCCESS;
  constructor(public payload: { event: Event; payload: any }) {}
}

export class EventDeleteAttendeeFailure implements Action {
  readonly type = EventsActionTypes.DELETE_ATTENDEE_FAILURE;
  constructor(public payload: Error) {}
}

export type EventsActions =
  | EventsFilter
  | EventsFilterSuccess
  | EventsFilterFailure
  | EventAdd
  | EventAddSuccess
  | EventAddFailure
  | EventsSetStatusNull
  | EventsSetErrorNull
  | EventGetDetails
  | EventGetDetailsSuccess
  | EventGetDetailsFailure
  | EventUpdate
  | EventUpdateSuccess
  | EventUpdateFailure
  | EventUpdateAttendee
  | EventUpdateAttendeeSuccess
  | EventUpdateAttendeeFailure
  | EventDelete
  | EventDeleteSuccess
  | EventDeleteFailure
  | EventDeleteAttendee
  | EventDeleteAttendeeSuccess
  | EventDeleteAttendeeFailure;
