import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import {Observable, BehaviorSubject} from 'rxjs';
import {websiteValidator} from 'src/app/app-validators';
import {AppState} from 'src/app/core';
import {Tags} from 'src/app/core/models/tags.model';
import {ArticleSetSuccessNull, UpdateArticleLinks} from 'src/app/core/store/actions/article.actions';
import {CompanySetStatusNull, CompanyUpdateGeneral} from 'src/app/core/store/actions/company.actions';

@Component({
  selector: 'app-article-link-form',
  templateUrl: './article-link.component.html',
  styleUrls: ['./article-link.component.scss']
})
export class ArticleLinkComponent implements OnInit {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @Input() articleCode: string;
  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  public formSent: boolean;

  successCompany$: Observable<boolean>;
  articleLinkForm: FormGroup;
  socialMediaCounter = [1];


  public _articleLinkInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set articleLinkInput(mode: any | null) {
    this._articleLinkInput$.next(mode);
  }

  get articleLink(): any | null {
    return this._articleLinkInput$.getValue();
  }

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.generateForm();

    this.successCompany$ = this.store.pipe(select(store => store.articles.success));
    this.successCompany$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ArticleSetSuccessNull());
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
    this.valueChange.emit('success');
    this.clearForm();
  }

  clearForm() {
    this.links.clear();
    this.articleLinkForm.clearValidators();
    this.articleLinkForm.updateValueAndValidity();
    this.articleLinkForm.reset();
  }

  generateForm() {
    this.articleLinkForm = new FormGroup({
      links: new FormArray([])
    });
  }

  addSMLink() {
    this.socialMediaCounter.push(1);
    this.links.push(this.newLink());
  }


  newLink(): FormGroup {
    return this.fb.group({
      link: new FormControl('', Validators.compose([websiteValidator])),
    });
  }

  get links(): FormArray {
    return this.articleLinkForm.get('links') as FormArray;
  }

  removeSMLink() {
    this.links.removeAt(this.links.length - 1);
  }

  onUpdateArticleLinks(form: FormGroup) {
    if (!form.invalid) {
      const formValues = form.getRawValue();

      let result = [];
      if (formValues.links && formValues.links.length > 0) {
        result = formValues.links.map(a => {
          if (a.link !== '') {
            return a.link;
          }
        });
      }

      result = result.filter(el => {
        return el != null;
      });

      this.store.dispatch(new UpdateArticleLinks({
        articleCode: this.articleCode,
        articleLinks: {links: result},

      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this._articleLinkInput$.getValue()) {
      if (this._articleLinkInput$.getValue() && this._articleLinkInput$.getValue().length > 0) {
        for (const [index, socialMediaLink] of this._articleLinkInput$.getValue().entries()) {
          // if (index > 0) {
          this.addSMLink();
          // }

          this.links.at(index).setValue({
            link: socialMediaLink.link,
          });

        }


      }
    }
  }
  clearAll(){
    this.clearModal.hide()
    this.clearForm()
    var c=document.getElementById('removeButton')
    c.click()
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }

}
