// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.  'priyanka@lovethatdesign.com'

export const environment = {
  production: false,
  // API_URL: 'http://127.0.0.1:8000/api/canvas/v1.0/',
  // LTD_API_URL: 'http://localhost:8001/canvas/',
  LTD_API_URL: "https://qa2.lovethatdesign.com/service/canvas/",
  API_URL: "https://canvas-dev-b-api.lovethat.design/api/canvas/v1.0/",
  limitedUsers: ["shahlin"],
  usersAllowToSync: ["shoaib", "sharika", "stephen", "hasna"],
  SENTRY_DSN: "",
  COMMUNITY_API_KEY: "osmt4mDlzZtw2dJiGf",
  GOOGLE_MAPS_API_KEY: "AIzaSyCz7vAOjFWhrPvFOZ_Xe80D6NQC2a7yh3Y",
  ALLOWED_EMAIL_SENDER_ACCOUNTS: ["sharika@28lightbulbs.com"],
  CHECK_SYNC_TIMEOUT: 5000,
  splashImageMaxLimit: 15,
  newDomainUrl: "https://canvas-dev-b-v2.lovethat.design",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
