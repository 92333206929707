import { Action } from '@ngrx/store';

export enum CollectionActionTypes {

  LOAD = '[COLLECTION] Load Action',
  LOAD_SUCCESS = '[COLLECTION] Load Success Action',
  LOAD_FAILURE = '[COLLECTION] Load Failure Action',

  SEARCH_COLLECTION = '[COLLECTION] Search Collection',
  SEARCH_COLLECTION_SUCCESS = '[COLLECTION] Search Collection Success',
  SEARCH_COLLECTION_FAILURE = '[COLLECTION] Search Collection Failure',

  SET_SUCCESS_NULL = '[COLLECTION] Set Success Null',

}

export class CollectionLoad implements Action {
  readonly type = CollectionActionTypes.LOAD;
  constructor(public payload?: {page_size?: number, page_no?: number }) { }
}

export class CollectionLoadSuccess implements Action {
  readonly type = CollectionActionTypes.LOAD_SUCCESS;
  constructor(public payload: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class CollectionLoadFailure implements Action {
  readonly type = CollectionActionTypes.LOAD_FAILURE;
  constructor(public payload: Error) { }
}

export class CollectionSearch implements Action {
  readonly type = CollectionActionTypes.SEARCH_COLLECTION;

  constructor(public payload: { search?: string, page_no?: number, page_size?: number }) {
  }
}

export class CollectionSearchSuccess implements Action {
  readonly type = CollectionActionTypes.SEARCH_COLLECTION_SUCCESS;

  constructor(public payload: { result: [], total_count: number, page_no: number, page_size: number }) {
  }
}

export class CollectionSearchFailure implements Action {
  readonly type = CollectionActionTypes.SEARCH_COLLECTION_FAILURE;

  constructor(public payload: Error) {
  }
}

export type CollectionActions = CollectionLoad | CollectionLoadSuccess | CollectionLoadFailure |
CollectionSearch | CollectionSearchSuccess | CollectionSearchFailure;
