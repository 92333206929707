import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import {websiteValidator} from '../../../../../app-validators';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {ContactSetStatusNull, ContactUpdateGeneral} from '../../../../../core/store/actions/contact.actions';
import {CompanySetStatusNull, CompanyUpdateGeneral} from '../../../../../core/store/actions/company.actions';
import * as _ from 'lodash';
import {MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-company-general-form',
  templateUrl: './company-general.component.html',
  styleUrls: ['./company-general.component.scss']
})
export class CompanyGeneralComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('internalTag') public internalTag: MultiSelectComponent;
  @ViewChild('externalTag') public externalTag: MultiSelectComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @Input() allTags: Observable<Array<Tags>>;
  @Input() companyCode: string;
  @Input() socialMediaPlatform: any[];
  @Output() valueChange = new EventEmitter();
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  public formSent: boolean;

  successCompany$: Observable<boolean>;
  companyGeneralForm: FormGroup;
  socialMediaCounter = [1];
  chosenSocialMediaPlatforms = [];


  tagFields = {text: 'tag', value: 'tag'};
  socialMediaFields = {text: 'social_media_platform_name', value: 'social_media_platform_code'};

  public tagModelData = [];
  modelData = [];
  selectedTag = [];
  tagSelected = '';


  public _companyGeneralInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set companyGeneralInput(mode: any | null) {
    this._companyGeneralInput$.next(mode);
  }

  get companyGeneral(): any | null {
    return this._companyGeneralInput$.getValue();
  }

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.generateForm();

    this.successCompany$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.successCompany$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new CompanySetStatusNull());
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
    this.valueChange.emit('success');
    this.clearForm();
  }

  clearForm() {
    this.chosenSocialMediaPlatforms = [];
    this.companyGeneralForm.reset();
    this.social_media.clear();
    this.companyGeneralForm.clearValidators();
    this.companyGeneralForm.updateValueAndValidity();
  }

  generateForm() {
    this.companyGeneralForm = new FormGroup({
      internal_tags: new FormControl(''),
      external_tags: new FormControl(''),
      description: new FormControl(''),
      social_media: new FormArray([])
    });
  }

  addSMLink() {
    this.socialMediaCounter.push(1);
    this.social_media.push(this.newLink());
  }


  newLink(): FormGroup {
    return this.fb.group({
      social_media_link: new FormControl(''),
      social_media_platform_code: '',
    });
  }

  get social_media(): FormArray {
    return this.companyGeneralForm.get('social_media') as FormArray;
  }

  removeSMLink() {
    this.social_media.removeAt(this.social_media.length - 1);
    this.chosenSocialMediaPlatforms.pop();
  }

  onUpdateCompanyGeneral(form: FormGroup) {
    if (!form.invalid) {
      const formValues = form.getRawValue();

      this.store.dispatch(new CompanyUpdateGeneral({
        company_code: this.companyCode,
        description: formValues.description,
        socialMedia: formValues.social_media.filter((item) => item.social_media_platform_code !== ''),
        internal_tag: formValues.internal_tags,
        external_tag: formValues.external_tags
      }));
    }
  }

  setSocialMediaPlatforms(index, type, args?) {
    if (type === 'link') {

      if (this.social_media.controls[index].get('social_media_platform_code').value === null
        || this.social_media.controls[index].get('social_media_platform_code').value === '') {
        this.social_media.controls[index].get('social_media_platform_code').setValidators([Validators.required]);
        this.social_media.controls[index].get('social_media_platform_code').markAsTouched();
      } else {
        this.social_media.controls[index].get('social_media_platform_code').clearValidators();
      }
    } else {
      if (this.social_media.controls[index].get('social_media_link').value === null
        || this.social_media.controls[index].get('social_media_link').value === '') {
        // this.socialMediaPlatform = this.socialMediaPlatform.filter((item) => item.social_media_platform_code !== this.social_media.controls[index].get('social_media_platform_code').value);
        if (args.value !== null) {
          this.social_media.controls[index].get('social_media_platform_code').setValue(args.value);
          this.chosenSocialMediaPlatforms[index] = this.social_media.controls[index].get('social_media_platform_code').value;
        }
        if (this.social_media.controls[index].get('social_media_platform_code').value.indexOf('social-id') > -1) {
          this.social_media.controls[index].get('social_media_link').clearValidators();

          this.social_media.controls[index].get('social_media_link').setValidators([Validators.required]);
          // this.social_media.controls[index].get('social_media_id').markAsTouched();

        } else {
          this.social_media.controls[index].get('social_media_link').clearValidators();
          this.social_media.controls[index].get('social_media_link').setValidators([Validators.required, websiteValidator]);
        }
        this.social_media.controls[index].get('social_media_link').markAsTouched();
      } else {
        this.social_media.controls[index].get('social_media_link').clearValidators();
        this.social_media.controls[index].get('social_media_link').updateValueAndValidity();

        // this.social_media.controls[index].get('social_media_link').setValidators([Validators.compose([websiteValidator])]);

      }
    }

    this.social_media.controls[index].get('social_media_platform_code').updateValueAndValidity();
    this.social_media.controls[index].get('social_media_link').updateValueAndValidity();
  }

  fetchSocialMediaPlatformDataset(args) {
    return _.cloneDeep(this.socialMediaPlatform.filter((item) => !this.chosenSocialMediaPlatforms.includes(item.social_media_platform_code) || item.social_media_platform_code === args));
  }

  isSocialID(index) {
    return this.social_media.controls[index].get('social_media_platform_code').value.indexOf('social-id') > -1
  }

  getPlaceholder(index) {
    if (this.social_media.controls[index].get('social_media_platform_code').value.indexOf('social-id') > -1) {
      return 'ID';
    } else {
      return 'Link';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allTags) {

    }

    if (this._companyGeneralInput$.getValue()) {

      const publicTags = [];
      const privateTags = [];

      if (this._companyGeneralInput$.getValue().public_tag) {
        for (const tag of this._companyGeneralInput$.getValue().public_tag) {
          publicTags.push(tag.tag);
        }
      }

      if (this._companyGeneralInput$.getValue().private_tag) {
        for (const tag of this._companyGeneralInput$.getValue().private_tag) {
          privateTags.push(tag.tag);
        }
      }

      this.companyGeneralForm.patchValue({
        internal_tags: privateTags ? privateTags : null,
        external_tags: publicTags ? publicTags : null,
        description: this._companyGeneralInput$.getValue().description ? this._companyGeneralInput$.getValue().description : null
      });

      if (this._companyGeneralInput$.getValue().social_media && this._companyGeneralInput$.getValue().social_media.length > 0) {
        for (const [index, socialMediaLink] of this._companyGeneralInput$.getValue().social_media.entries()) {
          // if (index > 0) {
          this.addSMLink();
          // }

          this.social_media.at(index).setValue({
            social_media_link: socialMediaLink.link,
            social_media_platform_code: socialMediaLink.social_media_platform.social_media_platform_code,
          });

          if (socialMediaLink.social_media_platform.social_media_platform_code.indexOf('social-id') > -1) {
          } else {
            this.social_media.at(index).get('social_media_link').setValidators(Validators.compose([websiteValidator]));
          }

        }


      }
    }
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if (!this.modelData.includes(this.tagSelected)) {
      this.externalTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.tags.setValue(_.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
