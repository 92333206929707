<ejs-sidebar id="events-sidebar-menu" type="Over" style="visibility: hidden"
  #sidebarInstance
  [width]="width"
  [position]="'Right'"
  [enableGestures]="false"
  [showBackdrop]="true"
>
  <div id="element" #tabObj>
      <div class="offcanvas-close mt-n1 pr-5" style="float: right; padding: 48px">
        <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close" (click)="closeSidebar()">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <div class="p-15">
        <h2 *ngIf="!eventsInput">Add Event</h2>
        <h2 *ngIf="eventsInput">Update Event</h2>

        <form id="addUpdateEventsForm" [formGroup]="eventsForm" #formDir="ngForm" style="margin-top: 16px">
          <!-- Event Name -->
          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="eventName" cssClass="e-filled" placeholder="Event Name *" formControlName="event_name" floatLabelType="Auto" required
              (blur)="eventsForm.controls.event_name.markAsTouched(); checkDuplicates(eventsForm.value.event_name); getEventsSlug(eventsForm.value.event_name, eventsForm)">
            </ejs-textbox>
            <span class="text-warning"> {{ duplicateMsg$ | async }}</span>
            <div *ngIf="eventsForm.controls.event_name.errors">
              <div *ngIf="eventsForm.controls.event_name.errors.required && eventsForm.controls.event_name.touched" class="e-error">
                Event Name is required.
              </div>
              <div *ngIf="eventsForm.controls.event_name.errors.invalidEventName" class="e-error">
                Please enter a valid event name
              </div>
              <div *ngIf="eventsForm.controls.event_name.errors.minlength && eventsForm.controls.event_name.touched" class="e-error">
                Event name cannot be less than 3 characters
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="slug" placeholder="Slug *" cssClass="e-filled" formControlName="slug" floatLabelType="Auto"
              (blur)="eventsForm.controls.slug.markAsTouched()">
            </ejs-textbox>
            <div *ngIf="eventsForm.controls.slug.errors">
              <div *ngIf="eventsForm.controls.slug.errors.maxlength && eventsForm.controls.slug.touched" class="e-error">
                Slug cannot be more than 200 characters
              </div>
              <div *ngIf="eventsForm.controls.slug.errors.required && eventsForm.controls.slug.touched" class="e-error">
                Slug is required.
              </div>
              <div *ngIf="eventsForm.controls.slug.errors.pattern" class="e-error">
                Please remove any special characters except hyphen
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-datepicker id="event_start_date" cssClass="e-filled" placeholder="Start Date *" formControlName="start_date" floatLabelType="Auto" required
                            [allowEdit]="allowEdit" format="dd-MMM-yyyy"
                            (blur)="eventsForm.controls.start_date.markAsTouched()">
            </ejs-datepicker>
            <div *ngIf="eventsForm.controls.start_date.errors">
              <div *ngIf="eventsForm.controls.start_date.errors.required && eventsForm.controls.start_date.touched" class="e-error">
                Start Date is required
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-datepicker id="event_end_date" cssClass="e-filled" placeholder="End Date *" formControlName="end_date" floatLabelType="Auto" required
                            [allowEdit]="allowEdit" format="dd-MMM-yyyy"
                            (blur)="eventsForm.controls.end_date.markAsTouched()">
            </ejs-datepicker>
            <div *ngIf="eventsForm.controls.end_date.errors">
              <div *ngIf="eventsForm.controls.end_date.errors.required && eventsForm.controls.end_date.touched" class="e-error">
                End Date is required
              </div>
            </div>
            <div *ngIf="eventsForm.errors">
              <div *ngIf="eventsForm.errors.fromToDate" class="e-error">
                End date cannot be less than start date
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="city" placeholder="City *" cssClass="e-filled" formControlName="city_name" floatLabelType="Auto"
              (blur)="eventsForm.controls.city_name.markAsTouched()">
            </ejs-textbox>
            <div *ngIf="eventsForm.controls.city_name.errors">
              <div *ngIf="eventsForm.controls.city_name.errors.required && eventsForm.controls.city_name.touched" class="e-error">
                Please enter a city
              </div>
              <div *ngIf="eventsForm.controls.city_name.errors.invalidText" class="e-error">
                Please enter a valid city
              </div>
              <div *ngIf="eventsForm.controls.city_name.errors.maxlength && eventsForm.controls.city_name.touched" class="e-error">
                City cannot be more than 100 characters
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-dropdownlist #countryDropdown id="country" formControlName="country" placeholder="Country *" sortOrder="Ascending" floatLabelType="Auto" cssClass="e-filled"
                              [allowFiltering]="true"
                              [fields]="countryFields"
                              [dataSource]="countries"
                              (filtering)="onFiltering($event)"
                              (blur)="eventsForm.controls.country.markAsTouched()">
            </ejs-dropdownlist>
            <div *ngIf="eventsForm.controls.country.invalid">
              <div *ngIf="eventsForm.controls.country.errors.required && eventsForm.controls.country.touched" class="e-error">
                Please select a country
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="eventAddress" cssClass="e-filled" placeholder="Address" formControlName="address" floatLabelType="Auto"
                         [multiline]="true" (blur)="eventsForm.controls.address.markAsTouched()"></ejs-textbox>
            <div *ngIf="eventsForm.controls.address.errors">
              <div *ngIf="eventsForm.controls.address.errors.maxlength && eventsForm.controls.address.touched" class="e-error">
                Address cannot be more than 200 characters
              </div>
            </div>
          </div>
          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="eventDescription" cssClass="e-filled" placeholder="Description" formControlName="description" floatLabelType="Auto"
                         [multiline]="true" (blur)="eventsForm.controls.description.markAsTouched()"></ejs-textbox>
            <div *ngIf="eventsForm.controls.description.errors">
              <div *ngIf="eventsForm.controls.description.errors.maxlength && eventsForm.controls.description.touched" class="e-error">
                Description cannot be more than 1000 characters
              </div>
            </div>
          </div>

          <div *ngIf="eventsForm.invalid" class="e-error">
            Please fill all fields
          </div>
          <div *ngIf="errorMsg.length > 0" class="e-error">
            <div *ngFor="let message of errorMsg">{{message}}</div>
          </div>

          <div class="my-10 pb-20">
            <button id="resetbtn" class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5" type="button" data-ripple="true"
                    (click)="clear()">Clear</button>
            <button id="addSubmit" class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit" data-ripple="true"
                    *ngIf="!eventsInput" [disabled]="formSent || eventsForm.invalid"
                    (click)="addEvent(eventsForm)">Add Event</button>
            <button id="updateSubmit" class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit" data-ripple="true"
                    *ngIf="eventsInput" [disabled]="formSent || eventsForm.invalid"
                    (click)="updateEvent(eventsForm)">Update Event</button>
          </div>

        </form>
      </div>
  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]="dltButtons" header="Are you sure?" animationSettings="none" showCloseIcon="true" width="25%" [visible]="false">
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
