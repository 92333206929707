import { Component, OnInit } from '@angular/core';
import { OffcanvasOptions } from 'src/app/core/_base/layout';
import {Observable} from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import {select, Store} from '@ngrx/store';
import { AppState } from 'src/app/core';
import { Logout } from 'src/app/core/store/auth/auth.actions';
import {LayoutService} from '../../../../core/_base/layout/services/layout.service';
import {DynamicHeaderMenuService} from '../../../../core/_base/layout/services/dynamic-header-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from '../../../../core/store/services/admin.service';

@Component({
  selector: 'app-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  extrasUserOffcanvasDirection = 'offcanvas-right';
  user$: Observable<User>;
  success$: Observable<any>;
  extrasUserLayout: 'offcanvas' | 'dropdown';
  show = false;
  message = '';
  userCode;
  userDetails: any;
  allowedEmailAccountRoles = ['admin-email', 'intern-email','super-admin'];
  isLinkEmailAccountVisible = false;

  constructor(
    private store: Store<AppState>,
    private layout: LayoutService,
    private menu: DynamicHeaderMenuService,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(res => {
      if (Object.keys(res).length === 0) {
        this.show = false;
      }
    });
    this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp(
      'extras.user.offcanvas.direction'
    )}`;
    this.user$ = this.store.pipe(select(store => store.auth.user));
    this.user$.subscribe((data) => {
      if (data) {
        
        this.userCode = data.user_code;
        this.getUserDetails(this.userCode);
      }
    });
    
    this.route.queryParams.subscribe(res => {
      // tslint:disable-next-line:triple-equals
      if (Object.keys(res).length != 0) {
        Object.keys(res).forEach(key => {
          // tslint:disable-next-line:triple-equals
          if (key === 'isSuccess' && res[key] == 'true') {
             const x = document.getElementById('snackbar');
             x.className = 'show';
             setTimeout(() => {
               x.className = x.className.replace('show', '');
             }, 3000);
             this.router.navigate([]);
          }
        
        });
      }
      else{
        
        this.getUserDetails(this.userCode)
      }
    });

    this.getUserDetails(this.userCode)
    
  }
  getUserDetails(code) {
    this.adminService.getUserDetails(code).subscribe(res => {
      try  {
        if (res && res.data) {
          sessionStorage.setItem("userDetails",JSON.stringify(res.data));
          this.userDetails = res.data;
          if (this.userDetails.security_role && this.allowedEmailAccountRoles.includes(this.userDetails.security_role.security_role_code)) {
            this.isLinkEmailAccountVisible = true;
          }
        }
      } catch (e) {}
    });
  }
  logout() {
    this.store.dispatch(new Logout());
    // localStorage.clear();
    this.menu.clearMenu();
    localStorage.removeItem('token');
    localStorage.removeItem('userCode');
    localStorage.removeItem('layoutConfig');
    localStorage.removeItem('LAYOUT_CONFIG_LOCAL_STORAGE_KEY');
    localStorage.removeItem('metadata');
    localStorage.removeItem('selectDetails');
    localStorage.removeItem('filter');
  }
  editProfile() {
    this.show = true;
    this.message = 'Profile updated successfully';

    this.router.navigate([], { queryParams: {change: 'user-name'}});
  }
  changePassword() {
    this.show = true;
    this.message = 'Password changed successfully';
    this.router.navigate([], { queryParams: {change: 'password'}});
  }
  linkEmailAccount() {
    
    this.show = true;
    // this.message = 'Email linked successfully';
    this.router.navigate([], { queryParams: {change: 'link-email-account'}});
  }

  
}
