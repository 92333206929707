import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Observable} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../../core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {
  CompanyFilter,
  CompanyOrderDeliverablesFilter, CompanyOrderLineItemsFilter,
  GetCompanyOrderLineItem
} from '../../../../../../../core/store/actions/company.actions';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-order-line-item-filter',
  templateUrl: './order-line-item-filter.component.html',
  styleUrls: ['./order-line-item-filter.component.scss']
})
export class OrderLineItemFilterComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @Input() users: object;
  @Input() companyList: any;
  @Input() lineItem: [];
  @Input() companyOrderCode: string;


  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<boolean>;

  formSent: boolean;
  isFilterReset = false ;
  companyFields: object = {value: 'company_code', text: 'company_name'};
  userFields: object = {value: 'user_code', text: 'first_name'};
  lineItemFields: object;
  public treeSettings: object = {autoCheck: true};


  allowEdit = false;


  filterForm: FormGroup;

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private route: ActivatedRoute ) {
  }

  ngOnInit(): void {
    this.generateFilterForm();

    this.success$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.error$ = this.store.pipe(select(store => store.companies.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        // this.store.dispatch(new ProjectSetStatusNull());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }


  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      created_at: new FormControl(null),
      updated_at: new FormControl(null),
      order_item_code: new FormControl([]),
      amount_start: new FormControl(null),
      amount_end: new FormControl(null),
      quantity_start: new FormControl(null),
      quantity_end: new FormControl(null),
      company_code: new FormControl(null),
      updated_by: new FormControl(null),
      created_by: new FormControl(null),
    });

    this.route.queryParams.subscribe(res => {
      // console.log(res);
      // let filterVal = {...this.filterForm.value};
      // filterVal = {...filterVal, ...res};
      // // console.log(filterVal);
      // for (const fKey in filterVal) {
      //   if (this.filterFormArray.includes(fKey)) {
      //     // console.log(filterVal[fKey]);
      //     if (filterVal[fKey]) {
      //       filterVal[fKey] = filterVal[fKey].split(',');
      //     }
      //   }
      // }

      // this.filterForm.setValue({...filterVal});
      // this.filterForm.controls.project_type_code.setValue(res.project_type_code.split(','));
      // console.log(this.filterForm.value);
      // console.log(filterVal);
      // const queryCopy = {...res};
      // delete queryCopy.project_type_code;
      // this.filterForm.patchValue(queryCopy);
      // console.log(this.filterForm.value);
      // console.log(queryCopy);
      // const resCopy = {...res};
      // const types = resCopy.project_type_code.toString().split(',');
      // console.log(types);
      // this.filterForm.setValue(res);
      // this.filterForm.setValue({company_type_code: types});
      // this.filterForm.controls.project_type_code.value = types;
      // this.filterForm.patchValue({project_type_code: types});
      // console.log(this.filterForm.value);
    });
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {...this.filterForm.value};
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);

    if (filterValues.created_at) {
      filterValues.created_at_end = moment(filterValues.created_at[1]).format('YYYY-MM-DD');
      filterValues.created_at_start = moment(filterValues.created_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.created_at;

    if (filterValues.updated_at) {
      filterValues.updated_at_end = moment(filterValues.updated_at[1]).format('YYYY-MM-DD');
      filterValues.updated_at_start = moment(filterValues.updated_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }

    this.router.navigate([], {queryParams: filterValues});
    filterValues = {...filterValues, page_size: 100, page_no: 1, company_order_code: this.companyOrderCode};
    this.store.dispatch(new CompanyOrderLineItemsFilter(filterValues));
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], {queryParams: {}});
    this.filterForm.reset();
    this.filterForm.controls.order_item_code.setValue([]);

    this.store.dispatch(new GetCompanyOrderLineItem({company_order_code: this.companyOrderCode, page_size: 100, page_no: 1}))
  }

  openClick(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  isCompanyLink(): boolean {
    return (this.router.url.indexOf('/company/') > -1);
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      const filterParams = {company_name: e.text, page_size: 100, page_no: 1};
      this.store.dispatch(new CompanyFilter(filterParams));
      this.store.select(store => store.companies.companies).subscribe((data) => {
        if (data) {
          this.companyList = data.result;
          e.updateData(this.companyList, query);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.lineItem) {
      this.lineItemFields = {value: 'order_item_code', text: 'order_item_name', dataSource: this.lineItem, child: 'children'};
    }
  }

  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
