import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {ContactSetStatusNull, ContactUpdate, ContactUpdateImages} from '../../../../../core/store/actions/contact.actions';
import {BehaviorSubject, Observable} from 'rxjs';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';


@Component({
  selector: 'app-contact-ltd-settings-form',
  templateUrl: './contact-ltd-settings.component.html',
  styleUrls: ['./contact-ltd-settings.component.scss']
})
export class ContactLtdSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('visibility') visibilityDropdown: DropDownListComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @Input() contactCode = '';
  @Input() featuredImageFiles: any;
  @Input() type: string;
  @Output() valueChanged = new EventEmitter();

  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  public formSent: boolean;
  showUploadingBtn = false;
  displayPasswordInput = false;


  referenceID = '';
  successContact$: Observable<boolean>;
  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(false);
  preLoadedFiles: any;
  showUploader$ = new BehaviorSubject(false);

  public visibilityFields: object = {value: 'visibility_code', text: 'visibility_name'};
  public selectedItem:string = "public";
  contactLTDSettingsForm: FormGroup;
  formSentSettings = false;


  public visibilityList: any[] = [
    {visibility_code: 'private', visibility_name: 'Private'},
    {visibility_code: 'password_protected', visibility_name: 'Password Protected'},
    {visibility_code: 'public', visibility_name: 'Public'},
  ];


  public _contactLTDSettings$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set contactLTDSettingsInput(mode: any | null) {
    this._contactLTDSettings$.next(mode);
  }

  get contactLTDSettings(): any | null {
    return this._contactLTDSettings$.getValue();
  }

  constructor(private store: Store<AppState>, private formBuilder: FormBuilder) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.type === 'ltd-settings') {
      this.generateForm();
    }

    if (this._contactLTDSettings$.getValue()) {

      if (this.contactLTDSettingsForm) {
        this.contactLTDSettingsForm.setValue({
          visibility: this._contactLTDSettings$.getValue().visibility ? this._contactLTDSettings$.getValue().visibility : null,
          password_protected_password: this._contactLTDSettings$.getValue().password_protected_password ? this._contactLTDSettings$.getValue().password_protected_password : null
        });
      }


      if (this._contactLTDSettings$.getValue()
      && this._contactLTDSettings$.getValue().featured_image_reference_id) {
      this.preLoadedFiles = [{
        filename: this._contactLTDSettings$.getValue().contact_featured_image_filename,
        url: this._contactLTDSettings$.getValue().featured_image_reference_id
      }];

    }

      // this.preLoadedFiles = this.featuredImageFiles;
    }
  }

  clearForm() {
    this.contactLTDSettingsForm.reset();
  }

  onUpdate() {
   
    if (!this.contactLTDSettingsForm.invalid) {

      this.formSentSettings = true;
      const formVals = this.contactLTDSettingsForm.getRawValue();
      const updateParams = {
        is_published_yn: this._contactLTDSettings$.getValue().is_published_yn,
        company_code:  this._contactLTDSettings$.getValue().company ? this._contactLTDSettings$.getValue().company.company_code : null,
        tel_no: this._contactLTDSettings$.getValue().tel_no,
        email: this._contactLTDSettings$.getValue().email,
        designation: this._contactLTDSettings$.getValue().designation,
        start_date: this._contactLTDSettings$.getValue().start_date ? moment(this._contactLTDSettings$.getValue().start_date).format('YYYY-MM-DD') : null,
        end_date: this._contactLTDSettings$.getValue().end_date ? moment(this._contactLTDSettings$.getValue().end_date).format('YYYY-MM-DD') : null,
        lead_source: this._contactLTDSettings$.getValue().lead_source ? this._contactLTDSettings$.getValue().lead_source : null,
        website: this._contactLTDSettings$.getValue().website ? this._contactLTDSettings$.getValue().website : null,
        slug: this._contactLTDSettings$.getValue().slug ? this._contactLTDSettings$.getValue().slug : null,
        excerpt: this._contactLTDSettings$.getValue().excerpt ? this._contactLTDSettings$.getValue().excerpt : null,
        password_protected_password: formVals.password_protected_password ? formVals.password_protected_password : null,
        description: this._contactLTDSettings$.getValue().description ? this._contactLTDSettings$.getValue().description : null,
        visibility: formVals.visibility
      };
      this.store.dispatch(new ContactUpdate({
        contact_code: this._contactLTDSettings$.getValue().contact_code,
        currentScreen: 'contact',
        contact: updateParams
      }));

    }
  }

  ngOnInit(): void {
    this.successContact$ = this.store.pipe(select(store => store.contacts.success.isLoaded));
    this.successContact$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ContactSetStatusNull());
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();

    this.setCleared$.next(null);
    this.referenceID = null;
    this.formSent = false;
    this.showUploader$.next(true);
    this.formSentSettings = false;
  }

  generateForm() {
    this.contactLTDSettingsForm = this.formBuilder.group({
      visibility: new FormControl(null, Validators.required),
      password_protected_password: new FormControl(null, Validators.maxLength(255)),
    });
  }

  closeSidebar(): void {
    this.setCleared$.next(true);
    this.sidebarInstance.hide();
    this.formSent = false;
    this.formSentSettings = false;


    this.showUploader$.next(false);
    this.valueChanged.emit('success');
    this.preLoadedFiles = [];
  }

  updateReferenceID(args) {
    if (args.reference_id && args.isUploading == false) {
      this.referenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.cleared === true) {
      // this.setCleared$.next(null);
      this.referenceID = null;
      this.formSent = false;
      
    } else if (args.unsupportedFileType === true) {
      this.setCleared$.next(null);
      // this.formSent = true;
    } else if (args.unsupportedFileType === false) {
      this.formSent = false;
    } else if (args.reference_id === '' && args.isUploading === false) {
      this.referenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.isUploading === true) {
      this.formSent = true;
      this.showUploadingBtn = true;
     
    }
  }

  onUploadImage() {
    this.store.dispatch(new ContactUpdateImages({contact_code: this.contactCode, reference_id: this.referenceID}));
  }

  setClearImages() {
    this.setCleared$.next(true);
  }

  ngOnDestroy(): void {
    this.showUploader$.complete();
  }


  changePasswordValidation(form: FormGroup, args) {
    if (form && args && args.itemData) {
      form.controls.visibility.setValue(args.itemData.visibility_code);
      if (form.controls.visibility.value === 'password_protected') {
        form.controls.password_protected_password.setValidators([Validators.required, Validators.maxLength(255)]);
        form.updateValueAndValidity();
        this.displayPasswordInput = true;
      } else {
        this.displayPasswordInput = false;
        form.controls.password_protected_password.clearValidators();
        form.controls.password_protected_password.updateValueAndValidity();
        form.updateValueAndValidity();

      }
    }
  }

  setDefaultValue () {
    if (!this._contactLTDSettings$.getValue() && this.visibilityDropdown) {
      this.visibilityDropdown.value = this.selectedItem;
    }
  }
  clearAll(){
    this.clearModal.hide()
  
  if(this.type=='images'){
    this.setCleared$.next(true);  
  }
  this.clearForm()
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }

}
