import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from '../../../../core/services/common.service';
import { find } from '../../../common/base.component';
import { LoadUsers } from '../../../../core/store/actions/common.actions';
import { ContactService } from '../../../../core/store/services/contact.service';
import { CompanyService } from '../../../../core/store/services/company.service';
import { v4 as uuid } from 'uuid';
import { ProjectUpdate } from '../../../../core/store/actions/project.actions';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/app/core/store/services/admin.service';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-send-email-form',
  templateUrl: './send-email-form.component.html',
  styleUrls: ['./send-email-form.component.scss']
})
export class SendEmailFormComponent implements OnInit, OnChanges, OnDestroy {


  _environment = environment
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @ViewChild('EmailSender') public EmailSender: TextBoxComponent
 
  @Input() selectedRecord: any;
  @Input() selectedCode: any;
  @Output() valueChange = new EventEmitter();
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
singleuser=''
  formSent: boolean;
  sendEmailForm: FormGroup;
  error = ''
  sendEmailList = [];
  sendEmailField = { text: 'email', value: 'user_code' };
  senderEmailField = { text: 'name', value: 'company_contact_code' };
  receiverEmailList = [];
  email = ''
  appPassword=false
  isVerified=false
  userCode
  selectedLastEmailedOn = '';
  emailTemplateFields: any;
  categoryEmailTemplate: any;
  selectedEmailTemplate: any = null;
  receiverInfo: any;

  _emailSent: any;
  _projectUpdate: any;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute,
    private commonService: CommonService, private contactService: ContactService, private companyService: CompanyService,private adminService: AdminService) {
  }

  ngOnInit(): void {
    document.getElementById('Emailerror').innerHTML=''
    this.generateEmailForm();

    this.store.select(store => store.auth.user).subscribe((user) => {
      if (user) {
        this.userCode=user.user_code
        this.email = user.email
        // this.sendEmailList.push(user.email);
        // this.sendEmailForm.controls.sender_email.setValue(user.email)
        
      }
    });
    this.getUserDetails()
    // this.sendEmailList=this._environment.ALLOWED_EMAIL_SENDER_ACCOUNTS
    // this.sendEmailForm.controls.sender_email.setValue(this.sendEmailList[0]);
    this.store.select(store => store.metadata.users).subscribe((data) => {
    //   if (data) {

    //     var list = this._environment.ALLOWED_EMAIL_SENDER_ACCOUNTS


    //       this.sendEmailList.push(data.find(user => user.email === list[0]))
    //       //  this.sendEmailList.push(data.find(user => user.email === 'priyanka@lovethatdesign.com'));
    //       this.sendEmailForm.controls.sender_email.setValue(this.sendEmailList[0].user_code);

    //   }
     });

    this.store.dispatch(new LoadUsers());

    this.commonService.getCategories().subscribe(data => {
      if (data && data.data) {
        this.categoryEmailTemplate = this.transformData(data.data);
        // this.categoryEmailTemplate = data.data;
        this.emailTemplateFields = { dataSource: this.categoryEmailTemplate, text: 'name', value: 'id', child: 'children' };
      }
    });

  }

  closeSidebar(): void {
    this.clearForm();
    this.sidebarInstance.hide();
    this.valueChange.emit({ message: 'success' });

    if (this._emailSent) {
      this._emailSent.complete();
    }
  }

  openSidebar(): void {
    this.getUserDetails()
    
    document.getElementById('Emailerror').innerHTML=''
    if(this.appPassword==true && this.isVerified==true){
     this.sendEmailForm.controls.sender_email.setValue(this.email)
     document.getElementById('appPasswordError').innerHTML=''}
     else if(this.appPassword==true && this.isVerified==false){
      document.getElementById('appPasswordError').innerHTML='Please verify your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
     }
     else if(this.appPassword==false && this.isVerified==false){
      document.getElementById('appPasswordError').innerHTML='Please set your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
     }
    this.sidebarInstance.show();
  }

  clearForm() {
    this.sendEmailForm.reset();
    // this.sendEmailForm.controls.receiver_email.setValue('');
    this.receiverInfo = null;
    this.getUserDetails()
    
    document.getElementById('Emailerror').innerHTML=''
    if(this.appPassword==true && this.isVerified==true){
      this.sendEmailForm.controls.sender_email.setValue(this.email)
      document.getElementById('appPasswordError').innerHTML=''}
      else if(this.appPassword==true && this.isVerified==false){
       document.getElementById('appPasswordError').innerHTML='Please verify your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
      }
      else if(this.appPassword==false && this.isVerified==false){
       document.getElementById('appPasswordError').innerHTML='Please set your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
      }
  }

  generateEmailForm() {
    this.sendEmailForm = this.formBuilder.group({
      email_subject: new FormControl(null, Validators.required),
      email_content: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[^{}]+')])),
      receiver_email: new FormControl('', Validators.required),
      sender_email: new FormControl('', Validators.required),
      bcc_email: new FormControl(null),
      cc_email: new FormControl(null),
      email_template: new FormControl('', Validators.required)
    });
  }

  transformData(apiData) {
    const categories = [...apiData];
    const result = [];
    let tempResult = [];
    let categoryResult = [];
    let obj1 = {
      name: null,
      children: null,
      email_template_subject: null,
      email_template_content: null,
      id: null
    };

    for (const category of categories) {
      tempResult = [];
      categoryResult = [];
      if (category.email_category) {
        for (const categoryElement of category.email_category) {
          tempResult = [];

          if (categoryElement.email_template && categoryElement.email_template.length > 0) {
            for (const email of categoryElement.email_template) {
              obj1 = {
                children: null,
                name: email.email_template_name,
                email_template_subject: email.email_template_subject,
                email_template_content: email.email_template_content,
                id: email.email_template_code
              };

              if (email.children && email.children.length > 0) {
                obj1.children = [this.transformData(email.children)];
              }

              // tempResult.push({children: obj1, name: category.email_category.email_category_name, id: category.email_category.email_category_code});
              tempResult.push(obj1);

            }
            categoryResult.push({
              children: tempResult,
              name: categoryElement.email_category_name,
              id: categoryElement.email_category_code
            });


          } else if (!categoryElement.email_template && categoryElement.children) {
            const obj = {
              children: null,
              name: categoryElement.email_template_name,
              email_template_subject: categoryElement.email_template_subject,
              email_template_content: categoryElement.email_template_content,
              id: categoryElement.email_template_code
            };

            if (categoryElement.children) {
              obj.children = this.transformData(categoryElement.children);
            }
            return obj;
          }
        }
      }
      result.push({ children: categoryResult, name: category.application_category_name, id: category.application_category_code });
    }
    return result;
  }

  onSelectEmail(args) {
    if (args.value.length > 0) {
      this.selectedEmailTemplate = find(this.categoryEmailTemplate, args.value[0], 'id');
      this.formatEmail();
    }
  }

  onSelectReceiverEmail(args) {
    if (args.value !== null) {
      this.sendEmailForm.controls.receiver_email.setValue(args.value);
      this.receiverInfo = args.itemData;
      if (args && args.itemData && args.itemData.email_log && args.itemData.email_log.length > 0) {
        this.selectedLastEmailedOn = args.itemData.email_log ? 'Last Emailed on: ' + moment(args.itemData.email_log[0].updated_at).fromNow() : '';
      } else {
        this.selectedLastEmailedOn = '';
      }
      this.formatEmail();
    }
  }

  onSelectSenderEmail(args) {
   
    // if(this.appPassword==true){
    //   if (args.value !== null) {
    //     this.sendEmailForm.controls.sender_email.setValue(args.value);
    //     document.getElementById('appPasswordError').innerHTML=''
    //     this.formatEmail();
    //   }
    // }
    // else{
    //   document.getElementById('appPasswordError').innerHTML='Please set your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
    // }


    if(this.appPassword==true && this.isVerified==true){
      if (args.value !== null) {
        this.sendEmailForm.controls.sender_email.setValue(args.value);
        document.getElementById('appPasswordError').innerHTML=''
        this.formatEmail();
      }}
      else if(this.appPassword==true && this.isVerified==false){
       document.getElementById('appPasswordError').innerHTML='Please verify your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
      }
      else if(this.appPassword==false && this.isVerified==false){
       document.getElementById('appPasswordError').innerHTML='Please set your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
      }
   
  }

  formatEmail() {
    if (this.selectedEmailTemplate !== null && this.sendEmailForm.controls.receiver_email.value !== '' &&
      this.sendEmailForm.controls.sender_email.value !== '') {
      let companyCode = this.sendEmailForm.controls.receiver_email.value === 'company' ? this.receiverInfo.company_code : null;
      let type = '';
      if (this.router.url.indexOf('project/details') > -1) {
        type = 'project';
      } else {
        type = 'company';
      }

      this.commonService.formatEmail(this.sendEmailForm.controls.receiver_email.value === 'company' ? null : this.sendEmailForm.controls.receiver_email.value,
        this.userCode, this.selectedEmailTemplate.id, companyCode, this.selectedCode, type).subscribe((data) => {
          if (data) {
            document.getElementById('Emailerror').innerHTML=''
            this.error=''
            this.sendEmailForm.controls.email_subject.setValue(data.data.email_subject);
            this.sendEmailForm.controls.email_content.setValue(data.data.email_content);
          }
        }, err => {
          document.getElementById('Emailerror').innerHTML=err.error.error
          this.error= err.error.error
        });
    }
  }

  sendEmail(form: FormGroup) {

    if (!form.invalid) {
      document.getElementById('Emailerror').innerHTML=''
      this.error = ''
      const formattedEmail = this.sendEmailForm.controls.email_content.value.replaceAll('\n', ' <br> ');
      const companyContactCode = this.sendEmailForm.controls.receiver_email.value !== 'company' ? this.sendEmailForm.controls.receiver_email.value : null;
      let companyCode = this.sendEmailForm.controls.receiver_email.value === 'company' ? this.receiverInfo.company_code : null;

      let senderName = '';
      this.store.select(store => store.auth.user).subscribe((data) => {
        senderName = data.first_name + ' ' + data.last_name;
      });

      const receiver = this.receiverEmailList.find(item => item.company_contact_code === this.sendEmailForm.controls.receiver_email.value);

      let activity: any = {};
      let type = '';
      if (this.router.url.indexOf('project/details') > -1) {
        activity = { ...activity, entity: 'project', project_code: this.selectedCode };
        type = 'project';
      } else {
        activity = { ...activity, entity: 'company', company_code: this.selectedCode };
        type = 'company';
        companyCode = this.selectedCode;
      }

      const name = receiver.name.split(' - ')[0];
      activity = {
        ...activity,
        activity_title: 'Email ' + this.selectedEmailTemplate.name + ' sent to ' + name,
        activity_type_code: 'email-sent',
        activity_note: 'Email ' + this.selectedEmailTemplate.name + ' sent to ' + name,
        user_code: null,
        activity_participant_code: null, meeting_start_time: null, meeting_end_time: null,
        meeting_date: null, activity_tag: null, batch_id: uuid()
      };

      const subscribeVar = this.commonService.sendEmail(companyContactCode, this.userCode,
        this.sendEmailForm.controls.email_subject.value, formattedEmail, this.sendEmailForm.controls.email_template.value,
        companyCode, this.selectedCode, activity, type);
      this.formSent = true;

      this._emailSent = subscribeVar.subscribe((data) => {
        if (data) {

          if (data.data === 'Email sent') {

            if (type === 'project') {
              let newStatusName = '';
              this.store.select(store => store.projectStatus.projectStatus).subscribe((data) => {
                if (data.result) {
                  const statuses: any = [...data.result];
                  newStatusName = find(statuses, '11-investigation', 'status_code').status_name;
                }
              });
              //  Check and update project status if status === 01-lead
              const projectSubscribeVar = this.store.select(store => store.projects.details);

              this._projectUpdate = projectSubscribeVar.subscribe((data) => {
                if (data && data.status.status_code === '01-lead') {
                  this.store.dispatch(new ProjectUpdate({
                    project_code: this.selectedCode, project: {
                      status_code: '11-investigation',
                      featured_date: data.featured_date,
                      proposed_post_date: data.proposed_post_date,
                      project_tag: data.project_tag,
                      display_name: data.display_name,
                      user_code: data.assigned_to.user_code,
                      project_activity: [{
                        project_code: this.selectedCode,
                        user_code: null,
                        activity_participant_code: null, meeting_start_time: null, meeting_end_time: null,
                        meeting_date: null, activity_tag: null, entity: 'project', batch_id: uuid(),
                        activity_title: 'Status Changed from ' + data.status.status_name + ' to ' + newStatusName,
                        activity_note: 'Email sent',
                        activity_type_code: 'status-changed',
                      }],
                      drive_link: data.drive_link,
                      ltd_project_link: data.ltd_project_link,
                      project_description: data.project_description,
                    }
                  }));

                }
              });

              this._projectUpdate.complete();
            }
            this.formSent = false;
            document.getElementById('Emailerror').innerHTML=''
            this.error=''
            this.closeSidebar();
          }
        }

      }, err => {
        
                this.error=err.error.error
                document.getElementById('Emailerror').innerHTML=err.error.error
          
               
              
          
              });


    }
  }

  openCompanyContactPage() {
    if (this.sendEmailForm.controls.receiver_email.value === 'company') {
      this.openInternalLink('/company/details/' + this.receiverInfo.company_code);
    } else {
      this.openInternalLink('/contact/details/' + this.receiverInfo.contact.contact_code);
    }
  }

  openInternalLink(urlToOpen: string) {
    const baseURL = window.location.href.split('/')[2];
    urlToOpen = baseURL + urlToOpen;
    let url = '';
    if (!/^http[s]?:\/\//.test(urlToOpen)) {
      url += 'http://';
    }
    url += urlToOpen;
    window.open(url, '_blank');
  }

  refreshEmailList(refresh = false) {
    this.receiverEmailList = [];
    let totalEmailCount = 0;

    const selectedRecordCopy = { ...this.selectedRecord };

    selectedRecordCopy.contact = Array.isArray(selectedRecordCopy.contact) ? selectedRecordCopy.contact[0] : selectedRecordCopy.contact;
    selectedRecordCopy.company = Array.isArray(selectedRecordCopy.company) ? selectedRecordCopy.company[0] : selectedRecordCopy.company;

    if (selectedRecordCopy && selectedRecordCopy.company) {

      // if (refresh) {
      this.companyService.getCompany(selectedRecordCopy.company.company_code).subscribe((data) => {
        if (data && data.data) {
          const company = data.data;
          console.log(data.data)
          if (company.email) {
            const lastEmailed = company.email_log ? ' - ' + company.email_log_count + ' emails sent' : '';
console.log(lastEmailed)
            const newCompany = {
              name: company.company_name + ' (' +
                company.email + ')' + lastEmailed,
              company_contact_code: 'company',
              company_code: selectedRecordCopy.company.company_code,
              email_log: undefined
            };

            if (company.email_log) {
              newCompany.email_log = company.email_log;
            } else {
              delete newCompany.email_log;
            }
            this.receiverEmailList.push(newCompany);
          }
        }
      });
      // } else {
      //   if (selectedRecordCopy.company.email) {
      //     const lastEmailed = selectedRecordCopy.company.email_log ? ' - ' + selectedRecordCopy.company.email_log_count + ' emails sent' : '';
      //     const newCompany = {
      //       name: selectedRecordCopy.company.company_name + ' (' +
      //         selectedRecordCopy.company.email + ')' + lastEmailed,
      //       company_contact_code: 'company',
      //       company_code: selectedRecordCopy.company.company_code,
      //       email_log: undefined
      //     };
      //
      //     if (selectedRecordCopy.company.email_log) {
      //       newCompany.email_log = selectedRecordCopy.company.email_log;
      //     } else {
      //       delete newCompany.email_log;
      //     }
      //
      //     this.receiverEmailList.push(newCompany);
      //   }
      // }

      totalEmailCount = selectedRecordCopy.company.email_log_count;
      this.contactService.getAllCompanyContacts({ company_code: selectedRecordCopy.company.company_code }).subscribe((data) => {
        if (data.data && data.data.company_contact) {
          totalEmailCount = data.data.email_log_count;

          for (const contact of data.data.company_contact) {
            let lastEmailed = '';
            let freshEmailLogs = [];
            if (contact.email_log && contact.email_log.length > 0) {
              contact.email_log.map((item) => {
                if (item.sent_to_email === contact.email) {
                  freshEmailLogs.push(item);
                }
              });
              lastEmailed = ' - ' + freshEmailLogs.length + '/' + totalEmailCount + ' emails sent';
            }

            // let companyContact = {...contact};
            contact.name = contact.contact.first_name + ' ' + contact.contact.last_name + ' (' + contact.email + ')' + lastEmailed;
            contact.email_log = _.cloneDeep(freshEmailLogs);
            this.receiverEmailList.push(contact);
          }
          // this.receiverEmailList = data.data.company_contact;
        }


      });
    } else if (selectedRecordCopy && !selectedRecordCopy.company && selectedRecordCopy.company_contact) {

      this.contactService.getAllAssociatedCompanies({ contact_code: selectedRecordCopy.contact.contact_code }).pipe(take(1)).subscribe((data) => {
        if (data && data.data) {

          const contact = _.cloneDeep(data.data.result[0]);
          const contactDetails = _.cloneDeep(contact);
          delete contactDetails.associated_companies;
          for (const associated_company of contact.associated_companies) {
            if (associated_company.company_contact_code === selectedRecordCopy.company_contact.company_contact_code) {
              let lastEmailed = '';
              let freshEmailLogs = [];
              if (associated_company.email_log && associated_company.email_log.length > 0) {
                associated_company.email_log.map((item) => {
                  if (item.sent_to_email === associated_company.email) {
                    freshEmailLogs.push(item);
                  }
                });
                lastEmailed = ' - ' + freshEmailLogs.length + ' emails sent';
              }

              this.receiverEmailList.push({
                name: contact.first_name + ' ' +
                  contact.last_name + ' (' + associated_company.email + ')' + lastEmailed,
                company_contact_code: associated_company.company_contact_code,
                contact: { contact_code: selectedRecordCopy.contact.contact_code }, email_log: freshEmailLogs
              });
            }
          }
          // const lastEmailed = contact.email_log ? ' - ' + contact.email_log_count + ' emails sent' : '';

        }
      });



    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRecord) {
      this.refreshEmailList();
    }
  }

  ngOnDestroy(): void {

  }

  openEmailLog() {
    const receiver = this.receiverEmailList.find(item => item.company_contact_code === this.sendEmailForm.value.receiver_email);
    receiver.company_contact_code === 'company' ? this.openInternalLink('/email-log?company_code=' + receiver.company_code)
      : this.openInternalLink('/email-log?contact_code=' + receiver.contact.contact_code);
  }
getUserDetails(){
  
  this.adminService.getUserDetails(this.userCode).subscribe(user => {
    try  {
      if (user && user.data) {
        this.singleuser=user.data.email
       if(user.data.app_password==1 && user.data.is_verified_yn==1){
        this.appPassword=true
        this.isVerified=true

        this.sendEmailForm.controls.sender_email.setValue(user.data.email)
        document.getElementById('appPasswordError').innerHTML=''
        this.EmailSender.focusIn()
       }
       else if(user.data.app_password==1 && user.data.is_verified_yn!=1){
        this.appPassword=true
        this.isVerified=false
        document.getElementById('appPasswordError').innerHTML='Please verify your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
        this.EmailSender.focusIn()
       }
       
       else if(user.data.app_password!=1 && user.data.is_verified_yn!=1){
         this.appPassword=false
         this.isVerified=false
         document.getElementById('appPasswordError').innerHTML='Please set your App Password from Profile-> Linked Email Account to be able to send Project Emails.'
         this.EmailSender.focusIn()
       }
      this.EmailSender.focusOut()
      }
    } catch (e) {}
  });
}
clearAll(){
  this.clearModal.hide()
this.clearForm()

}
hideClearAllModal(){
  this.clearModal.hide()
}
clear(){
  this.clearModal.show()
}
}
