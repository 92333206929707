import {Action} from '@ngrx/store';
import {Contact} from '../../models/contact.model';
import {Activity} from '../../models/activity.model';
import {TransactionHistory} from '../../models/transaction-history.model';
import {CompanyActionTypes} from './company.actions';
import {Project} from '../../models/project.model';
import {Company} from '../../models/company.model';
import {ProjectActionTypes} from './project.actions';

export enum ContactActionTypes {
  LOAD = '[CONTACT] Load Action',
  LOAD_SUCCESS = '[CONTACT] Load Success Action',
  LOAD_FAILURE = '[CONTACT] Load Failure Action',

  ADD = '[CONTACT] Add Action',
  ADD_SUCCESS = '[CONTACT] Add Success Action',
  ADD_FAILURE = '[CONTACT] Add Failure Action',

  UPDATE = '[CONTACT] Update Action',
  UPDATE_SUCCESS = '[CONTACT] Update Success Action',
  UPDATE_FAILURE = '[CONTACT] Update Failure Action',

  DELETE = '[CONTACT] Delete Action',
  DELETE_SUCCESS = '[CONTACT] Delete Success Action',
  DELETE_FAILURE = '[CONTACT] Delete Failure Action',

  FILTER = '[CONTACT] Filter Action',
  FILTER_SUCCESS = '[CONTACT] Filter Success Action',
  FILTER_FAILURE = '[CONTACT] Filter Failure Action',
  FILTER_RESET = '[CONTACT] Filter Reset Action',

  GET_SINGLE_CONTACT = '[CONTACT] Get Single Contact Action',
  GET_SINGLE_CONTACT_SUCCESS = '[CONTACT] Get Single Contact Success Action',
  GET_SINGLE_CONTACT_FAILURE = '[CONTACT] Get Single Contact Failure Action',

  GET_CONTACT_ACTIVITY = '[CONTACT] Get Contact Activity Action',
  GET_CONTACT_ACTIVITY_SUCCESS = '[CONTACT] Get Contact Activity Success Action',
  GET_CONTACT_ACTIVITY_FAILURE = '[CONTACT] Get Contact Activity Failure Action',

  ADD_CONTACT_ACTIVITY = '[CONTACT] Add Contact Activity Action',
  ADD_CONTACT_ACTIVITY_SUCCESS = '[CONTACT] Add Contact Activity Success Action',
  ADD_CONTACT_ACTIVITY_FAILURE = '[CONTACT] Add Contact Activity Failure Action',

  UPDATE_CONTACT_ACTIVITY = '[CONTACT] Update Contact Activity Action',
  UPDATE_CONTACT_ACTIVITY_SUCCESS = '[CONTACT] Update Contact Activity Success Action',
  UPDATE_CONTACT_ACTIVITY_FAILURE = '[CONTACT] Update Contact Activity Failure Action',

  DELETE_CONTACT_ACTIVITY = '[CONTACT] Delete Contact Activity Action',
  DELETE_CONTACT_ACTIVITY_SUCCESS = '[CONTACT] Delete Contact Activity Success Action',
  DELETE_CONTACT_ACTIVITY_FAILURE = '[CONTACT] Delete Contact Activity Failure Action',

  ACTIVITY_FILTER = '[CONTACT] Activity Filter Action',
  ACTIVITY_FILTER_SUCCESS = '[CONTACT] Activity Filter Success Action',
  ACTIVITY_FILTER_FAILURE = '[CONTACT] Activity Filter Failure Action',

  GET_CONTACT_HISTORY = '[CONTACT] Get Contact Transaction History Action',
  GET_CONTACT_HISTORY_SUCCESS = '[CONTACT] Get Contact Transaction History Success Action',
  GET_CONTACT_HISTORY_FAILURE = '[CONTACT] Get Contact Transaction History Failure Action',

  GET_PROJECTS = '[CONTACT] Get Projects for Contact Action',
  GET_PROJECTS_SUCCESS = '[CONTACT] Get Projects for Contact Success Action',
  GET_PROJECTS_FAILURE = '[CONTACT] Get Projects for Contact Failure Action',

  PROJECT_FILTER = '[CONTACT] Project Filter for Contact Action',
  PROJECT_FILTER_SUCCESS = '[CONTACT] Project Filter for Contact Success Action',
  PROJECT_FILTER_FAILURE = '[CONTACT] Project Filter for Contact Failure Action',

  SEARCH = '[CONTACT] Search Action',
  SEARCH_SUCCESS = '[CONTACT] Search Success Action',
  SEARCH_FAILURE = '[CONTACT] Search Failure Action',

  RESET = '[CONTACT] Reset Action',
  SET_SUCCESS_NULL = '[CONTACT] Set status Null',
  SET_CONTACTS_NULL = '[CONTACT] Set contacts null',

  GET_CONTACT_ACTIVITY_HISTORY_SUCCESS = '[CONTACT] Get Contact Activity History Success Action',
  GET_CONTACT_ACTIVITY_HISTORY_FAILURE = '[CONTACT] Get Contact Activity History Failure Action',

  SEARCH_CONTACT = '[CONTACT] Search Contact Action',
  SEARCH_CONTACT_SUCCESS = '[CONTACT] Search Contact Success Action',
  SEARCH_CONTACT_FAILURE = '[CONTACT] Search Contact Failure Action',

  GET_ALL_COMPANY_CONTACTS = '[CONTACT] Get All Company Contacts Action',
  GET_ALL_COMPANY_CONTACTS_SUCCESS = '[CONTACT] All Company Contacts Success Action',
  GET_ALL_COMPANY_CONTACTS_FAILURE = '[CONTACT] All Company Contacts Failure Action',

  GET_ALL_ASSOCIATED_COMPANIES = '[CONTACT] Get All Associated Companies Action',
  GET_ALL_ASSOCIATED_COMPANIES_SUCCESS = '[CONTACT] All Associated Companies Success Action',
  GET_ALL_ASSOCIATED_COMPANIES_FAILURE = '[CONTACT] All Associated Companies Failure Action',

  ADD_ASSOCIATED_COMPANY = '[CONTACT] Add Associated Company Action',
  ADD_ASSOCIATED_COMPANY_SUCCESS = '[CONTACT] Add Associated Company Success Action',
  ADD_ASSOCIATED_COMPANY_FAILURE = '[CONTACT] Add Associated Company Failure Action',

  UPDATE_ASSOCIATED_COMPANY = '[CONTACT] Update Associated Company Action',
  UPDATE_ASSOCIATED_COMPANY_SUCCESS = '[CONTACT] Update Associated Company Success Action',
  UPDATE_ASSOCIATED_COMPANY_FAILURE = '[CONTACT] Update Associated Company Failure Action',

  UPDATE_ASSOCIATED_COMPANY_RANK = '[CONTACT] Update Associated Company rank Action',
  UPDATE_ASSOCIATED_COMPANY_RANK_SUCCESS = '[CONTACT] Update Associated Company rank Success Action',
  UPDATE_ASSOCIATED_COMPANY_RANK_FAILURE = '[CONTACT] Update Associated Company rank Failure Action',


  DELETE_ASSOCIATED_COMPANY = '[CONTACT] Delete Associated Company Action',
  DELETE_ASSOCIATED_COMPANY_SUCCESS = '[CONTACT] Delete Associated Company Success Action',
  DELETE_ASSOCIATED_COMPANY_FAILURE = '[CONTACT] Delete Associated Company Failure Action',

  DELETE_COMPANY_CONTACT_LINK = '[CONTACT] Delete Company Contact Link Action',
  DELETE_COMPANY_CONTACT_LINK_SUCCESS = '[CONTACT] Delete Company Contact Link Success Action',
  DELETE_COMPANY_CONTACT_LINK_FAILURE = '[CONTACT] Delete Company Contact Link Failure Action',

  PROJECT_BULK_EDIT = '[CONTACT] Project Bulk Edit Action',
  PROJECT_BULK_EDIT_SUCCESS = '[CONTACT] Project Bulk Edit Success Action',
  PROJECT_BULK_EDIT_FAILURE = '[CONTACT] Project Bulk Edit Failure Action',

  STATUS_CHANGED = '[CONTACT] Status Changed Action',
  STATUS_CHANGED_CLEARED = '[CONTACT] Status Changed Cleared Action',

  LOAD_CONTACT_GENERAL = '[CONTACT] Load Contact General Action',
  LOAD_CONTACT_GENERAL_SUCCESS = '[CONTACT] Load Contact General Success Action',
  LOAD_CONTACT_GENERAL_FAILURE = '[CONTACT] Load Contact General Failure Action',

  UPDATE_CONTACT_GENERAL = '[CONTACT] Update Contact General Action',
  UPDATE_CONTACT_GENERAL_SUCCESS = '[CONTACT] Update Contact General Success Action',
  UPDATE_CONTACT_GENERAL_FAILURE = '[CONTACT] Update Contact General Failure Action',

  UPDATE_CONTACT_IMAGES = '[CONTACT] Update Contact Images Action',
  UPDATE_CONTACT_IMAGES_SUCCESS = '[CONTACT] Update Contact Images Success Action',
  UPDATE_CONTACT_IMAGES_FAILURE = '[CONTACT] Update Contact Images Failure Action',

  MERGE_CONTACT = '[CONTACT] Merge Contact Action',
  MERGE_CONTACT_SUCCESS = '[CONTACT] Merge Contact Success Action',
  MERGE_CONTACT_FAILURE = '[CONTACT] Merge Contact Failure Action',

  UPDATE_SYNC_STATUS = '[CONTACT] UPDATE SYNC STATUS',


}

export class ContactLoad implements Action {
  readonly type = ContactActionTypes.LOAD;

  constructor(public payload?: { page_size?: number, page_no?: number, sort_by?: string, sort_order?: string }) {
  }
}

export class ContactLoadSuccess implements Action {
  readonly type = ContactActionTypes.LOAD_SUCCESS;

  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class ContactLoadFailure implements Action {
  readonly type = ContactActionTypes.LOAD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactAdd implements Action {
  readonly type = ContactActionTypes.ADD;

  constructor(public payload: {
    first_name: string, last_name: string, email: string, company_code: string, designation: string,
    leadSource: string, tel_no: string, currentScreen: string, contact_tag: string, start_date: string, end_date: string, excerpt: string,
    website: string, slug: string, visibility: string, password_protected_password: string
  }) {
  }
}

export class ContactAddSuccess implements Action {
  readonly type = ContactActionTypes.ADD_SUCCESS;

  constructor(public payload: { contact: Contact, currentScreen: string }) {
  }
}

export class ContactAddFailure implements Action {
  readonly type = ContactActionTypes.ADD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactUpdate implements Action {
  readonly type = ContactActionTypes.UPDATE;

  constructor(public payload: { contact_code: string, contact: {}, currentScreen: string }) {
  }
}

export class ContactUpdateSuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { contact_code: string, contact: Contact, currentScreen: string }) {
  }
}

export class ContactUpdateFailure implements Action {
  readonly type = ContactActionTypes.UPDATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactDelete implements Action {
  readonly type = ContactActionTypes.DELETE;

  constructor(public payload: { contactCode: string, contact: Contact, currentScreen: string }) {
  }
}

export class ContactDeleteSuccess implements Action {
  readonly type = ContactActionTypes.DELETE_SUCCESS;

  constructor(public payload: { contactCode: string, contact: Contact, currentScreen: string }) {
  }
}

export class ContactDeleteFailure implements Action {
  readonly type = ContactActionTypes.DELETE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactFilter implements Action {
  readonly type = ContactActionTypes.FILTER;

  constructor(public payload: {
    contact_name?: string, company_name?: string, industry_type?: [], country?: [], tags?: string[],
    page_no?: number, page_size?: number, company_code?: string
  }) {
  }
}

export class ContactFilterSuccess implements Action {
  readonly type = ContactActionTypes.FILTER_SUCCESS;

  constructor(public payload: { data?: [], columns: [], total_count: number, page_size: number, page_no: number }) {
  }
}

export class ContactFilterFailure implements Action {
  readonly type = ContactActionTypes.FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactFilterReset implements Action {
  readonly type = ContactActionTypes.FILTER_RESET;
}

export class ContactGetSingleContact implements Action {
  readonly type = ContactActionTypes.GET_SINGLE_CONTACT;

  constructor(public payload: { contactCode: string }) {
  }
}

export class ContactGetSingleContactSuccess implements Action {
  readonly type = ContactActionTypes.GET_SINGLE_CONTACT_SUCCESS;

  constructor(public payload: { contactCode: string, contact: Contact }) {
  }
}

export class ContactGetSingleContactFailure implements Action {
  readonly type = ContactActionTypes.GET_SINGLE_CONTACT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetContactActivity implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_ACTIVITY;

  constructor(public payload: { contactCode: string, entity: string }) {
  }
}

export class GetContactActivitySuccess implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_ACTIVITY_SUCCESS;

  constructor(public payload: { activity: [] }) {
  }
}

export class GetContactActivityFailure implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddContactActivity implements Action {
  readonly type = ContactActionTypes.ADD_CONTACT_ACTIVITY;

  constructor(public payload: {
    contact_code: string, activity_type: string, activityTitle: string, activityNote: string,
    userParticipants: string[], contactParticipants: string[], meeting_start_time: string, meeting_end_time: string,
    meeting_date: string, activityTags: string[], entity: string
  }) {
  }
}

export class AddContactActivitySuccess implements Action {
  readonly type = ContactActionTypes.ADD_CONTACT_ACTIVITY_SUCCESS;

  constructor(public payload: { activity: Activity }) {
  }
}

export class AddContactActivityFailure implements Action {
  readonly type = ContactActionTypes.ADD_CONTACT_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteContactActivity implements Action {
  readonly type = ContactActionTypes.DELETE_CONTACT_ACTIVITY;

  constructor(public payload: { activity_code: string, entity: string }) {
  }
}

export class DeleteContactActivitySuccess implements Action {
  readonly type = ContactActionTypes.DELETE_CONTACT_ACTIVITY_SUCCESS;

  constructor(public payload: { activity_code: string }) {
  }
}

export class DeleteContactActivityFailure implements Action {
  readonly type = ContactActionTypes.DELETE_CONTACT_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateContactActivity implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_ACTIVITY;

  constructor(public payload: { activity_code: string, contactActivity: {}, entity: string }) {
  }
}

export class UpdateContactActivitySuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_ACTIVITY_SUCCESS;

  constructor(public payload: { activity_code: string, activity: Activity }) {
  }
}

export class UpdateContactActivityFailure implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactActivityFilter implements Action {
  readonly type = ContactActionTypes.ACTIVITY_FILTER;

  constructor(public payload: {
    filterValues: {
      activity_title: string, activity_type: [], participants: [], tags: string[],
      meeting_date: string
    }, entity: string, code: string
  }) {
  }
}

export class ContactActivityFilterSuccess implements Action {
  readonly type = ContactActionTypes.ACTIVITY_FILTER_SUCCESS;

  constructor(public payload: Array<Activity>) {
  }
}

export class ContactActivityFilterFailure implements Action {
  readonly type = ContactActionTypes.ACTIVITY_FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetContactHistory implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_HISTORY;

  constructor(public payload: { contactCode: string }) {
  }
}

export class GetContactHistorySuccess implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_HISTORY_SUCCESS;

  constructor(public payload: { contactCode: string, history: TransactionHistory }) {
  }
}

export class GetContactHistoryFailure implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_HISTORY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactSetStatusNull implements Action {
  readonly type = ContactActionTypes.SET_SUCCESS_NULL;

  constructor() {
  }
}

export class ContactSetContactsNull implements Action {
  readonly type = ContactActionTypes.SET_CONTACTS_NULL;

  constructor() {
  }
}

export class ContactGetProjects implements Action {
  readonly type = ContactActionTypes.GET_PROJECTS;

  constructor(public payload: { contact_code?: string, project_contact_code_source?: string, contact_code_role?: string }) {
  }
}

export class ContactGetProjectsSuccess implements Action {
  readonly type = ContactActionTypes.GET_PROJECTS_SUCCESS;

  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class ContactGetProjectsFailure implements Action {
  readonly type = ContactActionTypes.GET_PROJECTS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactProjectFilter implements Action {
  readonly type = ContactActionTypes.PROJECT_FILTER;

  constructor(public payload: {
    contact_code: string, project_name?: string, reference_id?: string, project_type?: [], country?: [],
    tags?: string[], assigned_to?: string, proposed_post_date?: string, status?: string[], page_no?: number, page_size?: number,
    sort_by?: string, sort_order?: string
  }) {
  }
}

export class ContactProjectFilterSuccess implements Action {
  readonly type = ContactActionTypes.PROJECT_FILTER_SUCCESS;

  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class ContactProjectFilterFailure implements Action {
  readonly type = ContactActionTypes.PROJECT_FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactSearch implements Action {
  readonly type = ContactActionTypes.SEARCH;

  constructor(public payload: { search?: string, page_no?: number, page_size?: number }) {
  }
}

export class ContactSearchSuccess implements Action {
  readonly type = ContactActionTypes.SEARCH_SUCCESS;

  constructor(public payload: { data?: [], columns: [], total_count: number, page_size: number, page_no: number }) {
  }
}

export class ContactSearchFailure implements Action {
  readonly type = ContactActionTypes.SEARCH_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactReset implements Action {
  readonly type = ContactActionTypes.RESET;
}

// export class GetContactActivityHistory implements Action {
//   readonly type = ContactActionTypes.GET_CONTACT_ACTIVITY_HISTORY;
//   constructor(public payload: { contactCode: string, history: TransactionHistory }) { }
// }

export class GetContactActivityHistorySuccess implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_ACTIVITY_HISTORY_SUCCESS;

  constructor(public payload: { contactCode: string, history: TransactionHistory }) {
  }
}

export class GetContactActivityHistoryFailure implements Action {
  readonly type = ContactActionTypes.GET_CONTACT_ACTIVITY_HISTORY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetSearchedContact implements Action {
  readonly type = ContactActionTypes.SEARCH_CONTACT;

  constructor(public payload: { name?: string }) {
  }
}

export class GetSearchedContactSuccess implements Action {
  readonly type = ContactActionTypes.SEARCH_CONTACT_SUCCESS;

  constructor(public payload: { total_count: number, result: Array<Contact>, page_no?: number, page_size?: number }) {
  }
}

export class GetSearchedContactFailure implements Action {
  readonly type = ContactActionTypes.SEARCH_CONTACT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetAllCompanyContacts implements Action {
  readonly type = ContactActionTypes.GET_ALL_COMPANY_CONTACTS;

  constructor(public payload: { company_code: string }) {
  }
}

export class GetAllCompanyContactsSuccess implements Action {
  readonly type = ContactActionTypes.GET_ALL_COMPANY_CONTACTS_SUCCESS;

  constructor(public payload: { company: Company }) {
  }
}

export class GetAllCompanyContactsFailure implements Action {
  readonly type = ContactActionTypes.GET_ALL_COMPANY_CONTACTS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetAllAssociatedCompanies implements Action {
  readonly type = ContactActionTypes.GET_ALL_ASSOCIATED_COMPANIES;

  constructor(public payload: { contact_code: string, page_size?: number, page_no?: number }) {
  }
}

export class GetAllAssociatedCompaniesSuccess implements Action {
  readonly type = ContactActionTypes.GET_ALL_ASSOCIATED_COMPANIES_SUCCESS;

  constructor(public payload: {
    result: Array<{
      associated_companies: Array<{
        company_contact_code: string, company: Company,
        email: string, is_primary_yn: number,
        start_date: string, end_date: string, tel_no?: string, designation?: string,rank_order:number
      }>
    }>, page_size?: number, page_no?: number, total_count?: number
  }) {
  }
}

export class GetAllAssociatedCompaniesFailure implements Action {
  readonly type = ContactActionTypes.GET_ALL_ASSOCIATED_COMPANIES_FAILURE;

  constructor(public payload: Error) {
  }
}

 export class UpdateAssociatedCompaniesRank implements Action {
  readonly type = ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_RANK;

   constructor(public payload: {ranks:any}) {
  }
 }

 export class UpdateAssociatedCompaniesRankSuccess implements Action {
   readonly type = ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_RANK_SUCCESS;

   
  constructor(public payload: {
    data:Array<{
      company_contact_code:any,
      rank_order: number
    }>
    
  }) {
  }
}

export class UpdateAssociatedCompaniesRankFailure implements Action {
  readonly type = ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_RANK_FAILURE;

  constructor(public payload: Error) {
  }
 }

export class AddAssociateCompany implements Action {
  readonly type = ContactActionTypes.ADD_ASSOCIATED_COMPANY;

  constructor(public payload: {
    contact_code: string, company_code: string, email: string, is_primary_yn: number,
    start_date: string, end_date: string, tel_no?: string, designation?: string, is_display_yn: number
  }) {
  }
}

export class AddAssociateCompanySuccess implements Action {
  readonly type = ContactActionTypes.ADD_ASSOCIATED_COMPANY_SUCCESS;

  constructor(public payload: {
    company: {
      company: {
        company_code: string;
        company_name: string;
        address: string;
        city_name: string;
        country?: {
          country_name: string,
          country_code: string
        }
      }, tel_no: string,
      designation: string, email: string, is_primary_yn: number, start_date: string, end_date: string, is_display_yn: string,rank_order:number
    }
  }) {
  }
}

export class AddAssociateCompanyFailure implements Action {
  readonly type = ContactActionTypes.ADD_ASSOCIATED_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateAssociateCompany implements Action {
  readonly type = ContactActionTypes.UPDATE_ASSOCIATED_COMPANY;

  constructor(public payload: {
    company_contact_code: string, company_code?: string, email?: string, tel_no?: string,
    designation?: string, is_primary_yn?: number, start_date?: string, end_date?: string, page: string, is_display_yn?: number
  }) {
  }
}

export class UpdateAssociateCompanySuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_SUCCESS;

  constructor(public payload: {
    company: {
      company_contact_code: string, company?: { company_code: string, company_name: string }, tel_no: string,
      designation: string,rank_order:number, email: string, is_primary_yn: number, start_date: string, end_date: string, contact_code?: string, is_display_yn?: number
    }, page: string,
    oldCompanyContactCode: string
  }) {
  }
}

export class UpdateAssociateCompanyFailure implements Action {
  readonly type = ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}


export class DeleteAssociateCompany implements Action {
  readonly type = ContactActionTypes.DELETE_ASSOCIATED_COMPANY;

  constructor(public payload: { company_contact_code: string }) {
  }
}

export class DeleteAssociateCompanySuccess implements Action {
  readonly type = ContactActionTypes.DELETE_ASSOCIATED_COMPANY_SUCCESS;

  constructor(public payload: { company_contact_code: string, displayCompanyCompanyContactCode?: string; }) {
  }
}

export class DeleteAssociateCompanyFailure implements Action {
  readonly type = ContactActionTypes.DELETE_ASSOCIATED_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteCompanyContactLink implements Action {
  readonly type = ContactActionTypes.DELETE_COMPANY_CONTACT_LINK;

  constructor(public payload: { company_contact_code: string }) {
  }
}

export class DeleteCompanyContactLinkSuccess implements Action {
  readonly type = ContactActionTypes.DELETE_COMPANY_CONTACT_LINK_SUCCESS;

  constructor(public payload: { company_contact_code: string }) {
  }
}

export class DeleteCompanyContactLinkFailure implements Action {
  readonly type = ContactActionTypes.DELETE_COMPANY_CONTACT_LINK_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactProjectBulkEdit implements Action {
  readonly type = ContactActionTypes.PROJECT_BULK_EDIT;

  constructor(public payload: { request: any }) {
  }
}

export class ContactProjectBulkEditSuccess implements Action {
  readonly type = ContactActionTypes.PROJECT_BULK_EDIT_SUCCESS;

  constructor(public payload: Array<Project>) {
  }
}

export class ContactProjectBulkEditFailure implements Action {
  readonly type = ContactActionTypes.PROJECT_BULK_EDIT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactStatusChanged implements Action {
  readonly type = ContactActionTypes.STATUS_CHANGED;

  constructor(public payload: any) {
  }
}

export class ContactStatusChangedCleared implements Action {
  readonly type = ContactActionTypes.STATUS_CHANGED_CLEARED;
}

export class ContactLoadGeneral implements Action {
  readonly type = ContactActionTypes.LOAD_CONTACT_GENERAL;

  constructor(public payload?: { contact_code?: string }) {
  }
}

export class ContactLoadGeneralSuccess implements Action {
  readonly type = ContactActionTypes.LOAD_CONTACT_GENERAL_SUCCESS;

  constructor(public payload: { contact: any }) {
  }
}

export class ContactLoadGeneralFailure implements Action {
  readonly type = ContactActionTypes.LOAD_CONTACT_GENERAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactUpdateGeneral implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_GENERAL;

  constructor(public payload?: { contact_code: string, description?: string, socialMedia: any[], external_tag: any[], internal_tag: any[] }) {
  }
}

export class ContactUpdateGeneralSuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_GENERAL_SUCCESS;

  constructor(public payload: { contact: any }) {
  }
}

export class ContactUpdateGeneralFailure implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_GENERAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactUpdateImages implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_IMAGES;

  constructor(public payload?: { contact_code: string, reference_id?: string }) {
  }
}

export class ContactUpdateImagesSuccess implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_IMAGES_SUCCESS;

  constructor(public payload: { contact: any }) {
  }
}

export class ContactUpdateImagesFailure implements Action {
  readonly type = ContactActionTypes.UPDATE_CONTACT_IMAGES_FAILURE;

  constructor(public payload: Error) {
  }
}

export class MergeContact implements Action {
  readonly type = ContactActionTypes.MERGE_CONTACT;

  constructor(public payload?: { contact: any }) {
  }
}

export class MergeContactSuccess implements Action {
  readonly type = ContactActionTypes.MERGE_CONTACT_SUCCESS;

  constructor(public payload: { mergedContact: any, destinationContactCode: string, sourceContactCode: string }) {
  }
}

export class MergeContactFailure implements Action {
  readonly type = ContactActionTypes.MERGE_CONTACT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ContactUpdateSyncStatus implements Action {
  readonly type = ContactActionTypes.UPDATE_SYNC_STATUS;
  constructor(public payload: { contact_last_sync: any }) {
  }
}


export type ContactActions =
  ContactAdd | ContactAddSuccess | ContactAddFailure |
  ContactLoad | ContactLoadSuccess | ContactLoadFailure |
  ContactUpdate | ContactUpdateSuccess | ContactUpdateFailure |
  ContactDelete | ContactDeleteSuccess | ContactDeleteFailure |
  ContactFilter | ContactFilterSuccess | ContactFilterFailure |
  ContactGetSingleContact
  | ContactGetSingleContactSuccess
  |
  ContactGetSingleContactFailure
  | GetContactActivity
  | GetContactActivitySuccess
  | GetContactActivityFailure
  | AddContactActivity
  |
  AddContactActivitySuccess
  | AddContactActivityFailure
  | UpdateContactActivity
  | UpdateContactActivitySuccess
  |
  UpdateContactActivityFailure
  | DeleteContactActivity
  | DeleteContactActivitySuccess
  | DeleteContactActivityFailure
  |
  ContactSetStatusNull
  | ContactActivityFilter
  | ContactActivityFilterSuccess
  | ContactActivityFilterFailure
  | ContactFilterReset
  |
  GetContactHistory
  | GetContactHistorySuccess
  | GetContactHistoryFailure
  | ContactSetContactsNull
  | ContactGetProjects
  |
  ContactGetProjectsSuccess
  | ContactGetProjectsFailure
  | ContactProjectFilter
  | ContactProjectFilterSuccess
  | ContactProjectFilterFailure
  |
  ContactSearch
  | ContactSearchSuccess
  | ContactSearchFailure
  | ContactReset
  | GetContactActivityHistorySuccess
  |
  GetContactActivityHistoryFailure
  | GetSearchedContact
  | GetSearchedContactSuccess
  | GetSearchedContactFailure
  | GetAllCompanyContacts
  |
  GetAllCompanyContactsSuccess
  | GetAllCompanyContactsFailure
  | GetAllAssociatedCompanies
  | GetAllAssociatedCompaniesSuccess
  |
  GetAllAssociatedCompaniesFailure
  |UpdateAssociatedCompaniesRank
  |UpdateAssociatedCompaniesRankSuccess
  |UpdateAssociatedCompaniesRankFailure
  |
  AddAssociateCompany
  | AddAssociateCompanySuccess
  | AddAssociateCompanyFailure
  | UpdateAssociateCompany
  |
  UpdateAssociateCompanySuccess
  | UpdateAssociateCompanyFailure
  | DeleteAssociateCompany
  | DeleteAssociateCompanySuccess
  |
  DeleteAssociateCompanyFailure
  | DeleteCompanyContactLink
  | DeleteCompanyContactLinkSuccess
  | DeleteCompanyContactLinkFailure
  |
  ContactProjectBulkEdit
  | ContactProjectBulkEditSuccess
  | ContactProjectBulkEditFailure
  | ContactStatusChanged
  |
  ContactStatusChangedCleared |
  ContactLoadGeneral | ContactLoadGeneralSuccess | ContactLoadGeneralFailure |
  ContactUpdateGeneral | ContactUpdateGeneralSuccess | ContactUpdateGeneralFailure |
  ContactUpdateImages | ContactUpdateImagesSuccess | ContactUpdateImagesFailure |
  MergeContact | MergeContactSuccess | MergeContactFailure |
  ContactUpdateSyncStatus;
