import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Observable, of } from 'rxjs';

import { ResponseData } from '../../ResponseData.interface';
import { environment } from '../../../../environments/environment.prod';

const COMPANY_URL = environment.API_URL + 'company';
const COMPANY_ACTIVITY_URL = environment.API_URL + 'company-activity';
const PROJECT_URL = environment.API_URL + 'project';
const ORDER_LINE_ITEM_URL = environment.API_URL + 'order-line-item';
const DELIVERABLE_URL = environment.API_URL + 'deliverable';
const DELIVERABLE_NOTE_URL = environment.API_URL + 'deliverable-note';
const COMPANY_ORDER_URL = environment.API_URL + 'company-order';
const COMPANY_GENERAL_URL = environment.API_URL + 'company-general';
const COMPANY_LTD_URL = environment.API_URL + 'company-ltd';
const USER_SETTINGS = environment.API_URL + 'user-settings';



// @ts-ignore
@Injectable()
export class CompanyService {
  id: string;

  constructor(private http: HttpClient) {
  }

  getCompanies(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL, { params: parameters });
  }

  getCompany(code: string): Observable<ResponseData> {

    return this.http.get<ResponseData>(COMPANY_URL + '/' + code);
  }

  addCompany(company_name: string, address: string, city_name: string, country: string, website: string, tel_no: string,
    industryType: [], accountOwner: string,account_manager:string, company_tag: string, excerpt: string, display_name: string, email: string,
    slug: string, member_since: string, p_o_box: string, company_logo_reference_id,
    primary_community_type_code: string,account_owner_activity_note?:string,
    account_manager_activity_note?:string,): Observable<ResponseData> {
    this.id = uuid();

    return this.http.post<ResponseData>(COMPANY_URL, {
      company_name, address, city_name, website, tel_no, country_code: country, community_type_code: industryType,
      account_owner: accountOwner, company_tag, batch_id: this.id, excerpt, display_name, email, slug, member_since, p_o_box, company_logo_reference_id,
      primary_community_type_code,account_manager,account_owner_activity_note,account_manager_activity_note

    });
  }

  updateCompany(code: string, company: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = { ...company, batch_id: this.id };

    return this.http.put<ResponseData>(COMPANY_URL + '/' + code, request);
  }

  deleteCompany(code: string): Observable<ResponseData> {
    return this.http.delete<ResponseData>(COMPANY_URL + '/' + code);
  }

  getFilteredResults(parameters) {
    return this.http.get<ResponseData>(COMPANY_URL + '/filter', { params: parameters });
  }

  getCompanyActivity(companyCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_ACTIVITY_URL + '/' + companyCode);
  }

  addActivity(code: string, activityType: string, activityTitle: string, activityNote: string,
    meeting_date: string, meeting_start_time: string, meeting_end_time: string, user_code: string[],
    contact_code: string[], tags: string[]):
    Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      activity_type_code: activityType, activity_note: activityNote,
      activity_title: activityTitle, meeting_date, meeting_start_time, meeting_end_time, user_code, contact_code,
      activity_tag: tags, batch_id: this.id, company_code: code
    };

    return this.http.post<ResponseData>(COMPANY_ACTIVITY_URL, request);
  }

  deleteActivity(code: string) {
    return this.http.delete<ResponseData>(COMPANY_ACTIVITY_URL + '/' + code);
  }

  updateActivity(code: string, activity: any): Observable<ResponseData> {
    this.id = uuid();

    return this.http.put<ResponseData>(COMPANY_ACTIVITY_URL + '/' + code, {
      activity_title: activity.activityTitle, activity_note: activity.activityNote, activity_type: activity.activity_type,
      project_activity_tag: activity.activityTags, batch_id: this.id,
      user_code: activity.userParticipants, project_contact_code: activity.contactParticipants, meeting_date: activity.meeting_date,
      meeting_start_time: activity.meeting_start_time, meeting_end_time: activity.meeting_end_time
    });
  }

  getCompanyHistory(companyCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/' + companyCode + '/transaction-history');
  }

  getCompanyActivityHistory(companyCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_ACTIVITY_URL + '/' + companyCode + '/transaction-history');
  }

  getProjects(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(PROJECT_URL + '/filter', { params: parameters });
  }

  getSearchResults(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/search', { params: parameters });
  }

  getSearchedCompany(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/search-side-bar', { params: parameters });
  }

  getAllOrders(page_no?: number, page_size?: number,global?:any): Observable<ResponseData> {
    if(global){ const parameters = { page_no: page_no.toString(), page_size: page_size.toString(),global };
    return this.http.get<ResponseData>(COMPANY_URL + '/all-orders', { params: parameters });}
    else{
    const parameters = { page_no: page_no.toString(), page_size: page_size.toString() };
    return this.http.get<ResponseData>(COMPANY_URL + '/all-orders', { params: parameters });}
  }

  getOrders(companyCode: string, page_no?: number, page_size?: number): Observable<ResponseData> {
    const parameters = { page_no: page_no.toString(), page_size: page_size.toString() };
    return this.http.get<ResponseData>(COMPANY_URL + '/' + companyCode + '/order', { params: parameters });
  }

  deleteOrder(companyCode: string, companyOrderCode: string): Observable<ResponseData> {
    return this.http.delete<ResponseData>(COMPANY_URL + '/' + companyCode + '/order/' + companyOrderCode);
  }

  addOrder(company_order_code: string, company_code: string, order_name: string, notes: string, amount: string,
    start_date: string, end_date: string, start_free_period_date: string, end_free_period_date: string,
    currency_code: string, order_owner_code: string, order_status_code: string,deal_type:string): Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      order_name, notes, amount, start_date, end_date, start_free_period_date, end_free_period_date, currency_code, order_owner_code,
      batch_id: this.id, order_status_code,deal_type
    };

    return this.http.post<ResponseData>(COMPANY_URL + '/' + company_code + '/order', request);
  }

  updateOrder(company_order_code: string, company_code: string, order_name: string, notes: string, amount: string,
    start_date: string, end_date: string, start_free_period_date: string, end_free_period_date: string, contact_code: string,
    order_role_code: string, drive_link: string, company_order_drive_link_code: string, company_order_contact_role_code: string,
    isAdd: boolean = true, currency_code: string, order_owner_code: string, order_status_code: string,deal_type:string): Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      order_name,
      notes,
      amount,
      start_date,
      end_date,
      start_free_period_date,
      end_free_period_date,
      contact_code,
      order_role_code,
      drive_link,
      batch_id: this.id,
      company_order_drive_link_code,
      company_order_contact_role_code,
      currency_code,
      order_owner_code,
      order_status_code,
      deal_type
    };
    Object.keys(request).forEach((key) => (request[key] == null) && delete request[key]);

    request = { ...request, company_order_code };
    return this.http.put<ResponseData>(COMPANY_URL + '/' + company_code + '/order/' + company_order_code, request);
  }

  getSingleCompanyOrder(company_order_code: string, company_code: string) {
    return this.http.get<ResponseData>(COMPANY_URL + '/' + company_code + '/order/' + company_order_code);
  }

  getCompanyOrderLineItem(company_order_code: string, page_size: number, page_no: number) {
    const parameters = { company_order_code, page_no: page_no.toString(), page_size: page_size.toString() };
    return this.http.get<ResponseData>(ORDER_LINE_ITEM_URL, { params: parameters });
  }

  addCompanyOrderLineItem(company_order_code: string, order_item_code: string, description: string, amount: string, quantity: string) {
    this.id = uuid();

    let request: any = {
      company_order_code, order_item_code, amount, quantity, description, batch_id: this.id,
    };
    return this.http.post<ResponseData>(ORDER_LINE_ITEM_URL, request);
  }

  updateCompanyOrderLineItem(order_line_item_code: string, order_item_code: string, description: string, amount: string, quantity: string): Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      order_item_code, amount, quantity, description, batch_id: this.id,
    };

    return this.http.put<ResponseData>(ORDER_LINE_ITEM_URL + '/' + order_line_item_code, request);
  }

  deleteCompanyOrderLineItem(order_line_item_code: string): Observable<ResponseData> {
    return this.http.delete<ResponseData>(ORDER_LINE_ITEM_URL + '/' + order_line_item_code);
  }

  getCompanyOrderDeliverable(company_order_code: string, page_size: number, page_no: number,global?:any) {
    const parameters = { company_order_code, page_no: page_no.toString(), page_size: page_size.toString(), global };
    Object.keys(parameters).forEach((key) => ((parameters[key] == null) || (parameters[key] === '')) && delete parameters[key]);
    parameters['column_list'] = true;
    return this.http.get<ResponseData>(DELIVERABLE_URL, { params: parameters });
  }

  addCompanyOrderDeliverable(company_order_code: string, deliverable_item_code: string, cost_center_code: string,
    deliverable_status_code: string, description: string, start_date: string, end_date: string,
    deliverable_owner_code: string, order_line_item_code: string, is_external_provider_yn: number,
    dates: any, expiry_date: string, article_code: any) {
    this.id = uuid();

    let request: any = {
      company_order_code, deliverable_item_code, cost_center_code, expiry_date,
      deliverable_status_code, description, start_date, end_date, is_external_provider_yn,
      deliverable_owner_code, batch_id: this.id, order_line_item_code, dates, article_code
    };
    return this.http.post<ResponseData>(DELIVERABLE_URL, request);
  }

  updateCompanyOrderDeliverable(deliverable_code: string, deliverable_item_code: string, cost_center_code: string,
    deliverable_status_code: string, description: string, start_date: string, end_date: string,
    deliverable_owner_code: string, order_line_item_code: string,
    is_external_provider_yn: number, dates: any, expiry_date: string, article_code: any,
    deliverable_note?: any,): Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      deliverable_item_code, cost_center_code,
      deliverable_status_code, description, start_date, end_date, is_external_provider_yn, expiry_date, article_code,
      deliverable_owner_code, batch_id: this.id, order_line_item_code, deliverable_note, dates
    };

    return this.http.put<ResponseData>(DELIVERABLE_URL + '/' + deliverable_code, request);
  }

  updateUserSettings(setting_value: string, settingKeyCode: string): Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      setting_value, batch_id: this.id
    };

    return this.http.put<ResponseData>(USER_SETTINGS + '/' + settingKeyCode, request);
  }

  deleteCompanyOrderDeliverable(deliverable_code: string): Observable<ResponseData> {
    return this.http.delete<ResponseData>(DELIVERABLE_URL + '/' + deliverable_code);
  }

  getDeliverableNotes(deliverable_code: string, page_size: number, page_no: number) {
    const parameters = { deliverable_code, page_no: page_no.toString(), page_size: page_size.toString() };
    return this.http.get<ResponseData>(DELIVERABLE_NOTE_URL, { params: parameters });
  }

  addDeliverableNotes(deliverable_code: string, deliverable_note: string) {
    this.id = uuid();

    let request: any = {
      deliverable_note, deliverable_code, batch_id: this.id
    };
    return this.http.post<ResponseData>(DELIVERABLE_NOTE_URL, request);
  }

  updateDeliverableNotes(deliverable_note_code: string, note: string) {
    this.id = uuid();

    let request: any = {
      note, batch_id: this.id
    };
    return this.http.put<ResponseData>(DELIVERABLE_NOTE_URL + '/' + deliverable_note_code, request);
  }

  deleteDeliverableNotes(deliverable_note_code: string) {
    return this.http.delete<ResponseData>(DELIVERABLE_NOTE_URL + '/' + deliverable_note_code);
  }

  getCompanyOrderHistory(companyOrderCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/' + 'company/order/' + companyOrderCode + '/transaction-history');
  }

  getAllFilteredOrders(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_ORDER_URL + '/filter', { params: parameters });
  }

  getAllFilteredOrderDeliverables(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(DELIVERABLE_URL + '/filter', { params: parameters });
  }

  getAllFilteredOrderLineItems(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(ORDER_LINE_ITEM_URL + '/filter', { params: parameters });
  }

  checkDuplicates(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/check-duplicates', { params: parameters });
  }

  getCompanySlug(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_URL + '/generate-company-slug', { params: parameters });
  }

  getDeliverablesByMonth(params?) {
    if (params) {
      return this.http.get<ResponseData>(DELIVERABLE_URL + '/get-deliverable-by-month', { params });
    } else {
      return this.http.get<ResponseData>(DELIVERABLE_URL + '/get-deliverable-by-month');
    }
  }

  loadCompanyGeneral(company_code): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_GENERAL_URL, { params: { company_code } });
  }

  updateCompanyGeneral(code: string, description: string, social_media: any, external_tag, internal_tag): Observable<ResponseData> {
    this.id = uuid();
    const request = { description, social_media, external_tag, internal_tag, batch_id: this.id };
    return this.http.put<ResponseData>(COMPANY_GENERAL_URL + '/' + code, request);
  }

  loadCompanyLTDSettings(company_code): Observable<ResponseData> {
    return this.http.get<ResponseData>(COMPANY_LTD_URL, { params: { company_code } });
  }

  updateCompanyLTDSettings(request, companyCode): Observable<ResponseData> {
    this.id = uuid();
    request = { ...request, batch_id: this.id };
    return this.http.put<ResponseData>(COMPANY_LTD_URL + '/' + companyCode, request);
  }

}
