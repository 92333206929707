// tslint:disable:variable-name
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../../core";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";
import { CheckBoxComponent } from "@syncfusion/ej2-angular-buttons";
import {
  EventsSetStatusNull,
  EventUpdateAttendee,
} from "../../../../../core/store/actions/events.actions";

@Component({
  selector: "app-events-attendees-update-form",
  templateUrl: "./events-attendees-update.component.html",
  styleUrls: ["./events-attendees-update.component.scss"],
})
export class EventsAttendeesUpdateComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("sidebarInstance") public sidebarInstance: SidebarComponent;
  @ViewChild("clearModal") public clearModal: DialogComponent;
  @ViewChild("checkbox") public checkbox: CheckBoxComponent;
  // Listeners
  @Input() selectedEventCode: string;
  @Input() selectedContactList: any;
  @Output() valueChange = new EventEmitter();
  // style props
  public width = "auto";
  public position = "Right";
  // Field Props
  public contactFields: object = { value: "value", text: "name" };
  eventAttendeeForm: FormGroup;
  // Form Props
  public formSent: boolean;
  error$: Observable<any>;
  success$: Observable<any>;
  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedContactList) {
      if (this.selectedContactList && this.selectedContactList.length === 1) {
        setTimeout(() => {
          try {
            console.log("sss", this.selectedContactList);
            this.eventAttendeeForm.patchValue({
              comment: this.selectedContactList[0].comment,
              has_attended: this.selectedContactList[0].has_attended_yn === 1,
            });
            this.cd.detectChanges();
          } catch (e) {}
        }, 1000);
      }
    }
  }

  ngOnInit(): void {
    this.generateArticleContactForm();
    this.error$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.error) {
          return;
        }
        return store.events.error;
      }),
    );
    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
    this.success$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.success) {
          return;
        }
        return store.events.success.isForm;
      }),
    );
    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new EventsSetStatusNull());
      }
    });
  }

  generateArticleContactForm() {
    this.eventAttendeeForm = this.formBuilder.group({
      comment: new FormControl("", [Validators.maxLength(1000)]),
      has_attended: new FormControl(false),
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
    this.selectedContactList = [];
    if (this.eventAttendeeForm) {
      this.eventAttendeeForm.reset();
    }
    this.valueChange.emit("close");
  }
  clearAll() {
    this.formSent = false;
    this.clearModal.hide();
    if (this.eventAttendeeForm) {
      this.eventAttendeeForm.reset();
    }
  }

  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }
  // Submit form
  updateEventAttendees(form: FormGroup) {
    if (!form.invalid) {
      const postData = {
        event_code: this.selectedEventCode,
        company_contact_code: [],
      };
      try {
        if (this.selectedContactList && this.selectedContactList.length > 0) {
          postData.company_contact_code = this.selectedContactList.map((el) => {
            return {
              code: el.company_contact_code,
              comment: form.value.comment,
              has_attended_yn: form.value.has_attended ? 1 : 0,
            };
          });
        }
      } catch {}
      console.log(postData);
      this.store.dispatch(new EventUpdateAttendee(postData));
      this.formSent = true;
    }
  }
  ngOnDestroy() {}
}
