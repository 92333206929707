<!--begin::Topbar-->
<ng-container *ngIf="extraSearchDisplay">

  <div class="quick-search quick-search-inline ml-20 w-300px mt-3 mr-2" id="kt_quick_search_inline" *ngIf="!isVisibleSearchBox()">
    <!--begin::Form-->
    <form (submit)="search()" class="quick-search-form">
      <div class="input-group rounded bg-light">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="svg-icon svg-icon-lg">
              <!--begin::Svg Icon | path:/metronic/theme/html/demo5/dist/assets/media/svg/icons/General/Search.svg-->
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000" fill-rule="nonzero"></path>
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </span>
        </div>
        <input type="text" [(ngModel)]="searchParams" name="search" class="form-control h-45px" placeholder="Search...">
        <div class="input-group-append"><span class="input-group-text"><i class="quick-search-close ki ki-close icon-sm text-muted"></i></span>
        </div>
      </div>
    </form>
    <!--end::Form-->
    <!--begin::Search Toggle-->
    <div id="kt_quick_search_toggle" data-toggle="dropdown" data-offset="0px,1px"></div>
    <!--end::Search Toggle-->
    <!--begin::Dropdown-->
    <div class="dropdown-menu dropdown-menu-left dropdown-menu-lg dropdown-menu-anim-up">
      <div class="quick-search-wrapper scroll ps" data-scroll="true" data-height="350" data-mobile-height="200"
           style="height: 350px; overflow: hidden;">
        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
          <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; right: 0px;">
          <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
        </div>
      </div>
    </div>
    <!--end::Dropdown-->
  </div>
  <!--  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">-->
  <!--    &lt;!&ndash;begin::Search&ndash;&gt;-->
  <!--    <div class="topbar-item">-->
  <!--      <div-->
  <!--        class="btn btn-icon btn-clean btn-lg mr-1"-->
  <!--        id="kt_quick_search_toggle"-->
  <!--      >-->
  <!--        <span-->
  <!--          [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"-->
  <!--          cacheSVG="true"-->
  <!--          class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--        ></span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Search&ndash;&gt;-->
  <!--  </ng-container>-->

  <!--  <ng-container-->
  <!--    *ngIf="extrasSearchLayout === 'dropdown'"-->
  <!--    id="kt_quick_search_toggle"-->
  <!--  >-->
  <!--    <div-->
  <!--      class="dropdown"-->
  <!--      id="kt_quick_search_toggle"-->
  <!--      autoClose="outside"-->
  <!--      ngbDropdown-->
  <!--    >-->
  <!--      &lt;!&ndash;begin::Toggle&ndash;&gt;-->
  <!--      <div class="topbar-item" ngbDropdownToggle>-->
  <!--        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">-->
  <!--          <span-->
  <!--            [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"-->
  <!--            cacheSVG="true"-->
  <!--            class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--          ></span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Toggle&ndash;&gt;-->

  <!--      &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
  <!--      <div-->
  <!--        class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"-->
  <!--        ngbDropdownMenu-->
  <!--      >-->
  <!--&lt;!&ndash;        <app-search-dropdown-inner></app-search-dropdown-inner>&ndash;&gt;-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Dropdown&ndash;&gt;-->
  <!--    </div>-->
  <!--  </ng-container>-->
  <!--</ng-container>-->

  <!--<ng-container *ngIf="extrasNotificationsDisplay">-->
  <!--  <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">-->
  <!--    &lt;!&ndash;begin::Notifications&ndash;&gt;-->
  <!--    <div class="topbar-item">-->
  <!--      <div-->
  <!--        class="btn btn-icon btn-icon-mobile btn-clean btn-lg mr-1 pulse pulse-primary"-->
  <!--        id="kt_quick_notifications_toggle"-->
  <!--      >-->
  <!--        <span-->
  <!--          [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'"-->
  <!--          cacheSVG="true"-->
  <!--          class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--        ></span>-->
  <!--        <span class="pulse-ring"></span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Notifications&ndash;&gt;-->
  <!--  </ng-container>-->

  <!--  <ng-container *ngIf="extrasNotificationsLayout === 'dropdown'">-->
  <!--    &lt;!&ndash;begin::Notifications&ndash;&gt;-->
  <!--    <div class="dropdown" ngbDropdown placement="bottom">-->
  <!--      &lt;!&ndash;begin::Toggle&ndash;&gt;-->
  <!--      <div-->
  <!--        class="topbar-item"-->
  <!--        data-toggle="dropdown"-->
  <!--        data-offset="10px,0px"-->
  <!--        ngbDropdownToggle-->
  <!--      >-->
  <!--        <div-->
  <!--          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"-->
  <!--        >-->
  <!--          <span-->
  <!--            [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'"-->
  <!--            cacheSVG="true"-->
  <!--            class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--          ></span>-->
  <!--          <span class="pulse-ring"></span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Toggle&ndash;&gt;-->

  <!--      &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
  <!--      <div-->
  <!--        ngbDropdownMenu-->
  <!--        class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg"-->
  <!--      >-->
  <!--        <form>-->
  <!--          <app-notifications-dropdown-inner></app-notifications-dropdown-inner>-->
  <!--        </form>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Dropdown&ndash;&gt;-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Notifications&ndash;&gt;-->
  <!--  </ng-container>-->
  <!--</ng-container>-->

  <!--<ng-container *ngIf="extrasQuickActionsDisplay">-->
  <!--  <ng-container *ngIf="extrasQuickActionsLayout === 'offcanvas'">-->
  <!--    &lt;!&ndash;begin::Quick Actions&ndash;&gt;-->
  <!--    <div class="topbar-item">-->
  <!--      <div-->
  <!--        class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"-->
  <!--        id="kt_quick_actions_toggle"-->
  <!--      >-->
  <!--        <span-->
  <!--          [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'"-->
  <!--          cacheSVG="true"-->
  <!--          class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--        ></span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Quick Actions&ndash;&gt;-->
  <!--  </ng-container>-->
  <!--  <ng-container *ngIf="extrasQuickActionsLayout === 'dropdown'">-->
  <!--    &lt;!&ndash;begin::Quick Actions&ndash;&gt;-->
  <!--    <div class="dropdown" ngbDropdown placement="bottom">-->
  <!--      &lt;!&ndash;begin::Toggle&ndash;&gt;-->
  <!--      <div-->
  <!--        class="topbar-item"-->
  <!--        data-toggle="dropdown"-->
  <!--        data-offset="10px,0px"-->
  <!--        ngbDropdownToggle-->
  <!--      >-->
  <!--        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">-->
  <!--          <span-->
  <!--            [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'"-->
  <!--            cacheSVG="true"-->
  <!--            class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--          ></span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Toggle&ndash;&gt;-->
  <!--      &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
  <!--      <div-->
  <!--        class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg"-->
  <!--        ngbDropdownMenu-->
  <!--      >-->
  <!--        <app-quick-actions-dropdown-inner></app-quick-actions-dropdown-inner>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Dropdown&ndash;&gt;-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Quick Actions&ndash;&gt;-->
  <!--  </ng-container>-->
  <!--</ng-container>-->

  <!--<ng-container *ngIf="extrasCartDisplay">-->
  <!--  <ng-container *ngIf="extrasCartLayout === 'offcanvas'">-->
  <!--    &lt;!&ndash;begin::Cart&ndash;&gt;-->
  <!--    <div class="topbar-item">-->
  <!--      <div-->
  <!--        class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"-->
  <!--        id="kt_quick_cart_toggle"-->
  <!--      >-->
  <!--        <span-->
  <!--          [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'"-->
  <!--          cacheSVG="true"-->
  <!--          class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--        ></span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Cart&ndash;&gt;-->
  <!--  </ng-container>-->
  <!--  <ng-container *ngIf="extrasCartLayout === 'dropdown'">-->
  <!--    &lt;!&ndash;begin::Cart&ndash;&gt;-->
  <!--    <div class="dropdown" ngbDropdown placement="bottom">-->
  <!--      &lt;!&ndash;begin::Toggle&ndash;&gt;-->
  <!--      <div-->
  <!--        class="topbar-item"-->
  <!--        data-toggle="dropdown"-->
  <!--        data-offset="10px,0px"-->
  <!--        ngbDropdownToggle-->
  <!--      >-->
  <!--        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">-->
  <!--          <span-->
  <!--            [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'"-->
  <!--            cacheSVG="true"-->
  <!--            class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--          ></span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Toggle&ndash;&gt;-->
  <!--      &lt;!&ndash;begin::Dropdown&ndash;&gt;-->
  <!--      <div-->
  <!--        ngbDropdownMenu-->
  <!--        class="dropdown-menu p-0 m-0 dropdown-menu-xl dropdown-menu-anim-up"-->
  <!--      >-->
  <!--        <form>-->
  <!--          <app-cart-dropdown-inner></app-cart-dropdown-inner>-->
  <!--        </form>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;end::Dropdown&ndash;&gt;-->
  <!--    </div>-->
  <!--    &lt;!&ndash;end::Cart&ndash;&gt;-->
  <!--  </ng-container>-->
  <!--</ng-container>-->

  <!--<ng-container *ngIf="extrasQuickPanelDisplay">-->
  <!--  &lt;!&ndash;begin::Quick panel&ndash;&gt;-->
  <!--  <div class="topbar-item">-->
  <!--    <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">-->
  <!--      <span-->
  <!--        [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'"-->
  <!--        cacheSVG="true"-->
  <!--        class="svg-icon svg-icon-xl svg-icon-primary"-->
  <!--      ></span>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  &lt;!&ndash;end::Quick panel&ndash;&gt;-->
  <!--</ng-container>-->

  <!--<ng-container *ngIf="extrasLanguagesDisplay">-->
  <!--  <app-language-selector style="margin-top: 10px;"></app-language-selector>-->
  <!--</ng-container>-->

  <ng-container *ngIf="extrasUserDisplay">
    <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
      <ng-container *ngIf="user$ | async as _user">
        <!--begin::User-->
        <div class="topbar-item">
          <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle" (click)="click()">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.first_name }} {{_user.last_name}}</span>
            <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold" id="avatar">{{avatars}}</span>
          </span>
          </div>
        </div>
        <!--end::User-->
      </ng-container>
    </ng-container>
    <!--  <app-user-profile></app-user-profile>-->

    <ng-container *ngIf="extrasUserLayout === 'dropdown'">
      <!--begin::User-->
      <ng-container *ngIf="user$ | async as _user">
        <div class="dropdown" ngbDropdown placement="bottom-right">
          <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
            <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
              <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.first_name }}</span>
              <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{_user.first_name | firstLetter}}</span>
            </span>
            </div>
          </div>
          <!--end::Toggle-->
          <!--begin::Dropdown-->
          <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
            <!--          <app-user-dropdown-inner></app-user-dropdown-inner>-->
            <!--          <app-user-profile></app-user-profile>-->
          </div>
        </div>
        <!--end::Dropdown-->
      </ng-container>
      <!--end::User-->
    </ng-container>
  </ng-container>

  <!--end::Topbar-->
