import { Action } from '@ngrx/store';
import { user } from '../../models/admin/user.model';
export enum UserActionTypes {
    LOAD_USER = '[USER] Load user',
    LOAD_USER_SUCCESS = '[USER] Load user Success Action',
    LOAD_USER_FAILURE = '[USER] Load user Failure Action',

    ADD_USER = '[USER] Add user Action',
    ADD_USER_SUCCESS = '[USER] Add user Success Action',
    ADD_USER_FAILURE = '[USER] Add user Failure Action',

    UPDATE_USER = '[USER] Update user Action',
    UPDATE_USER_SUCCESS = '[USER] Update user Success Action',
    UPDATE_USER_FAILURE = '[USER] Update user Failure Action',

    DELETE_USER = '[USER] Delete User Action',
    DELETE_USER_SUCCESS = '[USER] Delete User Success Action',
    DELETE_USER_FAILURE = '[USER] Delete User Failure Action',

    BLOCK_USER = '[USER] Block User Action',
    BLOCK_USER_SUCCESS = '[USER] Block User Success Action',
    BLOCK_USER_FAILURE = '[USER] Block User Failure Action',

    SET_STATUS_NULL = '[USER] Set status null action'
}

export class LoadUser implements Action {

    readonly type = UserActionTypes.LOAD_USER;
    constructor(public payload?: { page_size?: number, page_no?: number, sort_by?:any, sort_order?:any
}) {
    }
}

export class LoadUserSuccess implements Action {
    readonly type = UserActionTypes.LOAD_USER_SUCCESS;

    constructor(public payload: { result: Array<user>,columns: [], total_count?: number,
        page_no?: number,
        page_size?: number }) {
    }
}

export class LoadUserFailure implements Action {
    readonly type = UserActionTypes.LOAD_USER_FAILURE;

    constructor(public payload: Error) {
    }
}

export class AddUser implements Action {
    readonly type = UserActionTypes.ADD_USER;

    constructor(public payload: { first_name: string, last_name?: string, email: string, security_role_code: string }) {
    }
}

export class AddUserSuccess implements Action {
    readonly type = UserActionTypes.ADD_USER_SUCCESS;

    constructor(public payload: { user: user }) {
    }
}

export class AddUserFailure implements Action {
    readonly type = UserActionTypes.ADD_USER_FAILURE;

    constructor(public payload: Error) {
    }
}

export class UpdateUser implements Action {
    readonly type = UserActionTypes.UPDATE_USER;
    
    constructor(public payload: {
        user_code: string,
        first_name: string, last_name: string, security_role_code: string
    }) {
    }
}

export class UpdateUserSuccess implements Action {
    readonly type = UserActionTypes.UPDATE_USER_SUCCESS;
    
    constructor(public payload: { user: user }) {
    }
}

export class UpdateUserFailure implements Action {
    readonly type = UserActionTypes.UPDATE_USER_FAILURE;

    constructor(public payload: Error) {
    }
}


export class DeleteUser implements Action {
    readonly type = UserActionTypes.DELETE_USER;
    constructor(public payload: { user_code: string}) { }
  }
  
  export class DeleteUserSuccess implements Action {
    readonly type = UserActionTypes.DELETE_USER_SUCCESS;
    constructor(public payload: {user_code: string}) { }
  }
  
  export class DeleteUserFailure implements Action {
    readonly type = UserActionTypes.DELETE_USER_FAILURE;
    constructor(public payload: Error) { }
  }

  export class BlockUser implements Action {
    readonly type = UserActionTypes.BLOCK_USER;
    constructor(public payload: { user_code: string,is_blocked_yn:number}) { }
  }
  
  export class BlockUserSuccess implements Action {
    readonly type = UserActionTypes.BLOCK_USER_SUCCESS;
    constructor(public payload: {user: user}) { }
  }
  
  export class BlockUserFailure implements Action {
    readonly type = UserActionTypes.BLOCK_USER_FAILURE;
    constructor(public payload: Error) { }
  }
  
  export class AuthSuccessNull implements Action {
    readonly type = UserActionTypes.SET_STATUS_NULL;
  }
export type UserActions =
    LoadUser
    | LoadUserSuccess
    | LoadUserFailure
    |
    AddUser
    | AddUserSuccess
    | AddUserFailure
    |
    UpdateUser
    | UpdateUserSuccess
    | UpdateUserFailure
    |
    DeleteUser
    | DeleteUserSuccess
    | DeleteUserFailure
    |
    BlockUser
    | BlockUserSuccess
    | BlockUserFailure
    |AuthSuccessNull;