import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {ProjectStatus} from '../../../../../core/models/project-status.model';
import {trimValidator} from '../../../../../app-validators';
import {ProjectStatusAdd, ProjectStatusResetError, ProjectStatusUpdate} from '../../../../../core/store/actions/project-status.actions';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-project-status-form',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @Input() parentProjectStatus: any;

  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  projectStatusForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  public projectStatusFields: object = {value: 'project_type_code', text: 'project_type_name'};

  public _projectStatusInput$: BehaviorSubject<ProjectStatus | null> = new BehaviorSubject<ProjectStatus | null>(null);

  @Input() set projectStatusInput(mode: ProjectStatus | null) {
    this._projectStatusInput$.next(mode);
  }

  get projectStatusInput(): ProjectStatus | null {
    return this._projectStatusInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.projectStatusForm) {
      this.generateProjectStatusForm();
    }

    this.error$ = this.store.pipe(select(store => store.projectStatus.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.projectStatus.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateProjectStatusForm() {
    this.projectStatusForm = this.formBuilder.group({
      project_status_parent_code: new FormControl(''),
      project_status_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      description: new FormControl('', [ Validators.maxLength(200)]),
      is_notes_mandatory_yn: new FormControl(null),
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.clearForm();
    this.sidebarInstance.hide();
  }

  clearForm() {
    this.projectStatusForm.reset();
    this.projectStatusForm.controls.project_status_parent_code.setValue('');
    this.store.dispatch(new ProjectStatusResetError());
  }

  onAddUpdateProjectStatus(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!this._projectStatusInput$.getValue()) {
        this.store.dispatch(new ProjectStatusAdd({
          status_name: form.value.project_status_name,
          parent_type_code: form.value.project_status_parent_code[0],
          description: form.value.description,
          is_notes_mandatory_yn: form.value.is_notes_mandatory_yn === null || form.value.is_notes_mandatory_yn === false ? 0 : 1,

        }));
      } else {
        this.store.dispatch(new ProjectStatusUpdate({
          projectStatusName: form.value.project_status_name,
          status_code: this._projectStatusInput$.getValue().status_code,
          parent_type_code: form.value.project_status_parent_code ? form.value.project_status_parent_code[0] : null,
          description: form.value.description,
          is_notes_mandatory_yn: form.value.is_notes_mandatory_yn === null || form.value.is_notes_mandatory_yn === false ? 0 : 1,
        }));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._projectStatusInput$.getValue()) {
      this.projectStatusForm.setValue({
        project_status_name: this._projectStatusInput$.getValue().status_name,
        project_status_parent_code: this._projectStatusInput$.getValue().parent ? [this._projectStatusInput$.getValue().parent] : '',
        description: this._projectStatusInput$.getValue().description ? this._projectStatusInput$.getValue().description : null,
        is_notes_mandatory_yn: this._projectStatusInput$.getValue().is_notes_mandatory_yn === 1,
      });
    }

    if (changes.parentProjectStatus) {
      this.projectStatusFields = {
        dataSource: this.parentProjectStatus,
        value: 'status_code',
        text: 'status_name',
        child: 'children'
      };
    }

  }
  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
