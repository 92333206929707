<ejs-sidebar
  id="sidebar-menu"
  #sidebarInstance
  type="Over"
  [width]="width"
  [position]="position"
  style="visibility: hidden"
  [enableGestures]="false"
  [showBackdrop]="true"
>
  <ejs-tab id="element" #tabObj>
    <e-tabitems>
      <e-tabitem [header]="headerText[0]"
        ><ng-template #content>
          <div
            class="offcanvas-close mt-n1 pr-5"
            style="float: right; padding: 48px"
          >
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
              (click)="closeSidebar()"
            >
              <i class="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <div class="p-15">
            <h2 *ngIf="!companyInput">Add Company</h2>
            <h2 *ngIf="companyInput">Update Company</h2>

            <form
              id="formId"
              [formGroup]="companyForm"
              #formDir="ngForm"
              class=""
              novalidate=""
              style="margin-top: 16px"
            >
              <!--      <div class="form-group sf-outline-input mb-10">-->
              <!--        <label>Company Logo</label>-->
              <!--        <img class="mb-4" [src]="imgFile" *ngIf="imgFile" style="height: 300px; width:500px" alt="">-->

              <!--        <button type="button" class="btn btn-secondary mt-1" onclick="document.getElementById('avatarToUpload').click()">-->
              <!--          Choose file-->
              <!--        </button>-->
              <!--        <input id="avatarToUpload" #fileInput type="file" style="display:none;" (change)="onProfileImageUpload($event)">-->

              <!--      </div>-->
              <div class="mb-5">
                <app-uploader
                  *ngIf="sidebarInstance.isOpen"
                  (valueChange)="updateReferenceID($event)"
                  [preLoadedFiles]="preLoadedFiles"
                  [setCleared]="setCleared$ | async"
                ></app-uploader>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="companyName"
                  placeholder="Company Name *"
                  cssClass="e-filled"
                  formControlName="companyName"
                  floatLabelType="Auto"
                  (blur)="
                    companyForm.controls.companyName.markAsTouched();
                    checkDuplicates(companyForm.value.companyName);
                    getCompanySlug(companyForm.value.companyName, companyForm)
                  "
                ></ejs-textbox>
                <span class="text-warning"> {{ duplicateMsg$ | async }}</span>
                <div *ngIf="companyForm.controls.companyName.errors">
                  <div
                    *ngIf="
                      companyForm.controls.companyName.errors.required &&
                      companyForm.controls.companyName.touched
                    "
                    class="e-error"
                  >
                    Company Name is required.
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.companyName.errors.invalidCompanyName
                    "
                    class="e-error"
                  >
                    Please enter a valid company name
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.companyName.errors.minlength &&
                      companyForm.controls.companyName.touched
                    "
                    class="e-error"
                  >
                    Company name cannot be less than 3 characters
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="display_name"
                  placeholder="Display Name"
                  cssClass="e-filled"
                  formControlName="display_name"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.display_name.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.display_name.errors">
                  <div
                    *ngIf="
                      companyForm.controls.display_name.errors.maxlength &&
                      companyForm.controls.display_name.touched
                    "
                    class="e-error"
                  >
                    Display name cannot be more than 100 characters
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-datepicker
                  id="member_since"
                  placeholder="Member Since"
                  cssClass="e-filled"
                  formControlName="member_since"
                  floatLabelType="Auto"
                  [allowEdit]="allowEdit"
                  (blur)="companyForm.controls.member_since.markAsTouched()"
                ></ejs-datepicker>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="slug"
                  placeholder="Slug *"
                  cssClass="e-filled"
                  formControlName="slug"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.slug.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.slug.errors">
                  <div
                    *ngIf="
                      companyForm.controls.slug.errors.maxlength &&
                      companyForm.controls.slug.touched
                    "
                    class="e-error"
                  >
                    Slug cannot be more than 200 characters
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.slug.errors.required &&
                      companyForm.controls.slug.touched
                    "
                    class="e-error"
                  >
                    Slug is required.
                  </div>
                  <div
                    *ngIf="companyForm.controls.slug.errors.pattern"
                    class="e-error"
                  >
                    Please remove any special characters except hypen
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="description"
                  [multiline]="true"
                  placeholder="Excerpt"
                  cssClass="e-filled"
                  formControlName="excerpt"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.excerpt.markAsTouched()"
                ></ejs-textbox>
              </div>

              <div
                class="form-group sf-outline-input mb-10"
                *ngIf="isSuperAdmin"
              >
                <ejs-dropdownlist
                  id="accountOwner"
                  #owner
                  formControlName="accountOwner"
                  [dataSource]="Ownerusers"
                  floatLabelType="Auto"
                  [fields]="userFields"
                  cssClass="e-filled"
                  placeholder="Account Owner"
                  [allowFiltering]="true"
                  (blur)="companyForm.controls.accountOwner.markAsTouched()"
                  (change)="accountOwnerChanged($event)"
                  (created)="accountOwnerCreated()"
                >
                  <!-- <ng-template #itemTemplate="" let-data style="display: inline-block">
                    <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                      <span>{{data.first_name}}</span>
                    </div>
                    <div style="display: inline-block" *ngIf="data.is_disable_yn ==1">
                      <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                    </div>

                  </ng-template> -->
                </ejs-dropdownlist>
                <div *ngIf="companyForm.controls.accountOwner.invalid">
                  <div
                    *ngIf="
                      companyForm.controls.accountOwner.errors.required &&
                      companyForm.controls.accountOwner.touched
                    "
                    class="e-error"
                  >
                    Please select an owner
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-dropdownlist
                  #manager
                  formControlName="account_manager"
                  [dataSource]="Mnagerusers"
                  floatLabelType="Auto"
                  [fields]="userField"
                  cssClass="e-filled"
                  placeholder="Sales"
                  [allowFiltering]="true"
                  (blur)="companyForm.controls.account_manager.markAsTouched()"
                  (change)="accountMnagerChanged($event)"
                  [showClearButton]="true"
                  (created)="dropdowncreated()"
                >
                  <!-- <ng-template #itemTemplate="" let-data style="display: inline-block">
                    <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                      <span>{{data.first_name}}</span>
                    </div>
                    <div style="display: inline-block" *ngIf="data.is_disable_yn ==1">
                      <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                    </div>
                  </ng-template> -->
                </ejs-dropdownlist>

                <div *ngIf="companyForm.controls.account_manager.invalid">
                  <div
                    *ngIf="
                      companyForm.controls.account_manager.errors.required &&
                      companyForm.controls.account_manager.touched
                    "
                    class="e-error"
                  >
                    Please select an owner
                  </div>
                </div>
              </div>

              <div
                class="form-group sf-outline-input mb-10"
                [class.hide-label]="
                  companyForm.controls.industryType.value == ''
                "
              >
                <!-- <ejs-dropdowntree formControlName="industryType" multiselect id='industryType' allowMultiSelection='true'
                                  sortOrder="Ascending"
                                  [fields]='industryFields' placeholder='Company Type *' floatLabelType="Auto"
                                  cssClass="e-filled"
                                  (blur)="companyForm.controls.industryType.markAsTouched()" [showCheckBox]="true"
                                  (change)="setCompanyType($event)"
                                  [showClearButton]=false>
                </ejs-dropdowntree> -->
                <ejs-multiselect
                  id="industryTypeo"
                  formControlName="industryType"
                  #industryType
                  [dataSource]="communityTypes"
                  placeholder="Community Type"
                  [fields]="communityTypeField"
                  floatLabelType="Auto"
                  cssClass="e-filled"
                  [showDropDownIcon]="true"
                  showSelectAll="true"
                  sortOrder="Ascending"
                  [showCheckBox]="true"
                  (select)="onSelect($event)"
                  (change)="setCompanyType($event, companyForm)"
                  [allowFiltering]="false"
                  (blur)="
                    companyForm.controls.primary_community_type.markAsTouched()
                  "
                  [showClearButton]="true"
                  (open)="onOpen($event)"
                  filterBarPlaceholder="Search"
                ></ejs-multiselect>
                <!-- <div *ngIf="companyForm.controls.industryType.invalid">
                  <div *ngIf="companyForm.controls.industryType.errors.required && companyForm.controls.industryType.touched "
                       class="e-error">
                    Please select a company type
                  </div>
                </div> -->
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-dropdownlist
                  #primaryCommunityDropdown
                  id="industryTypePrimary"
                  formControlName="primary_community_type"
                  [dataSource]="selectedCommunityTypes"
                  floatLabelType="Auto"
                  [fields]="communityTypeField"
                  cssClass="e-filled"
                  sortOrder="Ascending"
                  placeholder="Primary Community Type"
                  [allowFiltering]="true"
                  (blur)="
                    companyForm.controls.primary_community_type.markAsTouched()
                  "
                  (created)="createdPrimaryDropdown()"
                  [value]="selectedPrimaryCommunityType"
                >
                </ejs-dropdownlist>
                <div *ngIf="companyForm.controls.primary_community_type.errors">
                  <div
                    *ngIf="
                      companyForm.controls.primary_community_type.errors
                        .required &&
                      companyForm.controls.primary_community_type.touched
                    "
                    class="e-error"
                  >
                    Please select a primary community type
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  #email
                  id="email"
                  placeholder="Email"
                  cssClass="e-filled"
                  formControlName="email"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.email.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.email.errors">
                  <div
                    *ngIf="companyForm.controls.email.errors.invalidEmail"
                    class="e-error"
                  >
                    Please enter a valid email
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="telephone"
                  placeholder="Telephone"
                  cssClass="e-filled"
                  formControlName="telephone"
                  floatLabelType="Auto"
                  appOnlynumber
                  (blur)="companyForm.controls.telephone.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.telephone.errors">
                  <div
                    *ngIf="
                      companyForm.controls.telephone.touched &&
                      companyForm.controls.telephone.errors
                    "
                    class="e-error"
                  >
                    Please enter a valid telephone number
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="website"
                  placeholder="Website"
                  cssClass="e-filled"
                  formControlName="website"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.website.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.website.errors">
                  <div
                    *ngIf="
                      companyForm.controls.website.errors.invalidurl &&
                      companyForm.controls.website.touched
                    "
                    class="e-error"
                  >
                    Please enter a valid url.
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.website.errors.maxlength &&
                      companyForm.controls.website.touched
                    "
                    class="e-error"
                  >
                    Website cannot be more than 200 characters
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="address"
                  [multiline]="true"
                  placeholder="Address"
                  cssClass="e-filled"
                  formControlName="address"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.address.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.address.errors">
                  <div
                    *ngIf="
                      companyForm.controls.address.errors.maxlength &&
                      companyForm.controls.address.touched
                    "
                    class="e-error"
                  >
                    Address cannot be more than 200 characters
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="city"
                  placeholder="City *"
                  cssClass="e-filled"
                  formControlName="city"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.city.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.city.errors">
                  <div
                    *ngIf="
                      companyForm.controls.city.errors.required &&
                      companyForm.controls.city.touched
                    "
                    class="e-error"
                  >
                    Please enter a city
                  </div>
                  <div
                    *ngIf="companyForm.controls.city.errors.invalidText"
                    class="e-error"
                  >
                    Please enter a valid city
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.city.errors.maxlength &&
                      companyForm.controls.city.touched
                    "
                    class="e-error"
                  >
                    City cannot be more than 100 characters
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-dropdownlist
                  id="country"
                  formControlName="country"
                  [dataSource]="countries"
                  [fields]="countryFields"
                  placeholder="Country *"
                  [allowFiltering]="true"
                  (filtering)="onFiltering($event)"
                  sortOrder="Ascending"
                  cssClass="e-filled"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.country.markAsTouched()"
                >
                </ejs-dropdownlist>
                <div *ngIf="companyForm.controls.country.invalid">
                  <div
                    *ngIf="
                      companyForm.controls.country.errors.required &&
                      companyForm.controls.country.touched
                    "
                    class="e-error"
                  >
                    Please select a country
                  </div>
                </div>
              </div>

              <div class="form-group sf-outline-input mb-10">
                <ejs-textbox
                  id="p_o_box"
                  placeholder="P.O. Box"
                  cssClass="e-filled"
                  formControlName="p_o_box"
                  floatLabelType="Auto"
                  (blur)="companyForm.controls.p_o_box.markAsTouched()"
                ></ejs-textbox>
                <div *ngIf="companyForm.controls.p_o_box.errors">
                  <div
                    *ngIf="
                      companyForm.controls.p_o_box.errors.required &&
                      companyForm.controls.p_o_box.touched
                    "
                    class="e-error"
                  >
                    Please enter a P.O.Box
                  </div>
                </div>
              </div>
              <!--
              <div class="form-group sf-outline-input mb-10" >
                <div>
                  <ejs-dropdownlist id='visibilityDropdown' #visibility formControlName="visibility" floatLabelType="Auto" cssClass="e-filled" [value]="selectedItem"
                                    [dataSource]='visibilityList' [fields]='visibilityFields' placeholder='Visibility *' (created)="setDefaultValue()"
                                    (change)="changePasswordValidation(companyForm, $event)">
                  </ejs-dropdownlist>
                  <div *ngIf="companyForm.controls.visibility.errors">
                    <div *ngIf="companyForm.controls.visibility.errors.required && companyForm.controls.visibility.touched" class="e-error">
                      Visibility is required.
                    </div>
                  </div>
                </div>

                <div *ngIf="displayPasswordInput" class="mt-4">
                  <ejs-textbox  placeholder="Password *" cssClass="e-filled"
                              formControlName="password_protected_password" floatLabelType="Auto"
                              (blur)="companyForm.controls.password_protected_password.markAsTouched()">
                  </ejs-textbox>
                  <div *ngIf="companyForm.controls.password_protected_password.errors">
                    <div *ngIf="companyForm.controls.password_protected_password.errors.required && companyForm.controls.password_protected_password.touched" class="e-error">
                      Password is required.
                    </div>
                    <div *ngIf="companyForm.controls.password_protected_password.errors.maxlength && companyForm.controls.password_protected_password.touched"
                       class="e-error">
                    Password cannot be more than 255 characters
                  </div>
                  </div>
                </div>

              </div> -->

              <!--      <div class="form-group sf-outline-input mb-10">-->
              <!--        <ejs-multiselect id='tags' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"-->
              <!--                         [fields]="tagFields" formControlName='tags'-->
              <!--                         [allowCustomValue]=true mode='Box' placeholder='Tags'-->
              <!--                         (actionComplete)="actionComplete($event)"-->
              <!--                         (ngModelChange)="syncModel($event)"-->
              <!--                         (change)="onChange($event)"-->
              <!--                         (blur)="onBlur($event, companyForm)"-->
              <!--                         name="tags"-->
              <!--                         (removed)="selectedTag = []"-->
              <!--                         ([ngModel])="modelData"-->
              <!--                         (select)="select($event)"-->
              <!--                         ngModel></ejs-multiselect>-->
              <!--      </div>-->

              <!--      <div class="d-flex">-->
              <!--        <button class="btn btn-primary text-uppercase font-weight-bolder btn-sm float-right px-8" id="addNewCompany" >-->
              <!--          In Active-->
              <!--        </button>-->

              <!--        <button class="ml-2 btn btn-primary text-uppercase font-weight-bolder btn-sm float-right px-8" id="addNewCompany" >-->
              <!--          Publish-->
              <!--        </button>-->
              <!--      </div>-->

              <div *ngIf="companyForm.invalid" class="e-error">
                Please fill all fields
              </div>
              <div *ngIf="error$ | async as error" class="e-error">
                {{ error.error.message }}
              </div>

              <div class="my-10 pb-20">
                <button
                  id="resetbtn"
                  class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                  type="button"
                  (click)="clear()"
                  data-ripple="true"
                >
                  Clear
                </button>
                <button
                  *ngIf="
                    !companyInput &&
                    !showUploadingBtn &&
                    !ownerChanged &&
                    !managerChanged
                  "
                  id="addSubmit"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddCompany(companyForm)"
                  [disabled]="formSent"
                  data-ripple="true"
                >
                  Add Company
                </button>
                <button
                  *ngIf="
                    companyInput &&
                    !showUploadingBtn &&
                    !ownerChanged &&
                    !managerChanged
                  "
                  id="updateSubmit"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onUpdateCompany(companyForm)"
                  [disabled]="formSent"
                  data-ripple="true"
                >
                  Update Company
                </button>
                <button
                  *ngIf="!showUploadingBtn && (ownerChanged || managerChanged)"
                  id="addSubmit"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="navigate(companyForm)"
                  [disabled]="formSent"
                  data-ripple="true"
                >
                  Next
                </button>

                <button
                  *ngIf="companyInput && showUploadingBtn"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  [disabled]="true"
                  data-ripple="true"
                >
                  Uploading Images...
                </button>
              </div>
            </form>
          </div>
        </ng-template>
      </e-tabitem>
      <e-tabitem [header]="headerText[1]">
        <ng-template #content>
          <div
            class="offcanvas-close mt-n1 pr-5"
            style="float: right; padding: 48px"
          >
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
              (click)="closeSidebar()"
            >
              <i class="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <div class="p-15">
            <h2>Notes</h2>

            <form
              id="formId"
              [formGroup]="companyForm"
              #formDir="ngForm"
              class=""
              novalidate=""
              style="margin-top: 16px"
            >
              <!-- <div class="form-group sf-outline-input mb-10" *ngIf="ownerChanged==true">
              <ejs-textbox id="notes" [multiline]="true" placeholder="Account Owner Notes" cssClass="e-filled" formControlName="Account_owner_activity_note"
                floatLabelType="Auto" (blur)="companyForm.controls.Account_owner_activity_note.markAsTouched()"></ejs-textbox>
              <div *ngIf="companyForm.controls.Account_owner_activity_note.errors">
                <div *ngIf="companyForm.controls.Account_owner_activity_note.errors.maxlength && companyForm.controls.Account_owner_activity_note.touched"
                  class="e-error">
                  Notes cannot be more than 1000 characters
                </div>
              </div>
            </div> -->

              <div
                class="form-group sf-outline-input mb-10"
                *ngIf="ownerChanged == true"
              >
                <ejs-textbox
                  id="note"
                  [multiline]="true"
                  placeholder="Account Owner Notes*"
                  cssClass="e-filled"
                  formControlName="account_owner_activity_note"
                  floatLabelType="Auto"
                  (blur)="
                    companyForm.controls.account_owner_activity_note.markAsTouched()
                  "
                  (input)="inputs($event)"
                ></ejs-textbox>
                <div
                  *ngIf="
                    companyForm.controls.account_owner_activity_note.errors
                  "
                >
                  <div
                    *ngIf="
                      companyForm.controls.account_owner_activity_note.errors
                        .maxlength &&
                      companyForm.controls.account_owner_activity_note.touched
                    "
                    class="e-error"
                  >
                    Notes cannot be more than 1000 characters
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.account_owner_activity_note.errors
                        .required &&
                      companyForm.controls.account_owner_activity_note.touched
                    "
                    class="e-error"
                  >
                    Please add note.
                  </div>
                </div>

                <div
                  *ngIf="
                    notFormValid == false &&
                    (this.ownerValue == null ||
                      this.ownerValue == '' ||
                      this.ownerValue == undefined)
                  "
                  class="e-error"
                >
                  Please fill account owner notes.
                </div>
              </div>

              <div
                class="form-group sf-outline-input mb-10"
                *ngIf="managerChanged == true"
              >
                <ejs-textbox
                  id="notes"
                  [multiline]="true"
                  placeholder="Sales Notes*"
                  cssClass="e-filled"
                  formControlName="account_manager_activity_note"
                  floatLabelType="Auto"
                  (blur)="
                    companyForm.controls.account_manager_activity_note.markAsTouched()
                  "
                  (input)="input($event)"
                ></ejs-textbox>
                <div
                  *ngIf="
                    companyForm.controls.account_manager_activity_note.errors
                  "
                >
                  <div
                    *ngIf="
                      companyForm.controls.account_manager_activity_note.errors
                        .maxlength &&
                      companyForm.controls.account_manager_activity_note.touched
                    "
                    class="e-error"
                  >
                    Notes cannot be more than 1000 characters
                  </div>
                  <div
                    *ngIf="
                      companyForm.controls.account_manager_activity_note.errors
                        .required &&
                      companyForm.controls.account_manager_activity_note.touched
                    "
                    class="e-error"
                  >
                    Please add note.
                  </div>
                </div>
                <div
                  *ngIf="
                    notFormValid == false &&
                    (this.managerValue == null ||
                      this.managerValue == '' ||
                      this.managerValue == undefined)
                  "
                  class="e-error"
                >
                  Please fill sales notes.
                </div>
              </div>
              <div *ngIf="notFormValid == false" class="e-error">
                Please fill all fields
              </div>
              <div *ngIf="companyForm.invalid" class="e-error">
                Please fill all fields
              </div>
              <div *ngIf="error$ | async as error" class="e-error">
                {{ error.error.message }}
              </div>

              <div class="my-10 pb-20">
                <!-- <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clear()" data-ripple="true">Clear
              </button> -->
                <button
                  *ngIf="!companyInput && !showUploadingBtn"
                  id="addSubmit"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onAddCompany(companyForm)"
                  [disabled]="formSent"
                  data-ripple="true"
                >
                  Add Company
                </button>
                <button
                  *ngIf="companyInput && !showUploadingBtn"
                  id="updateSubmit"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  (click)="onUpdateCompany(companyForm)"
                  [disabled]="formSent"
                  data-ripple="true"
                >
                  Update Company
                </button>
                <button
                  *ngIf="companyInput && showUploadingBtn"
                  class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                  type="submit"
                  [disabled]="true"
                  data-ripple="true"
                >
                  Uploading Images...
                </button>
              </div>
            </form>
          </div>
        </ng-template>
      </e-tabitem>
    </e-tabitems>
  </ejs-tab>
</ejs-sidebar>
<ejs-dialog
  #clearModal
  [buttons]="dltButtons"
  header="Are you sure?"
  animationSettings="none"
  showCloseIcon="true"
  width="25%"
  [visible]="false"
>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
