import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {BehaviorSubject, Observable} from 'rxjs';
import {Form, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Order} from '../../../../../core/models/order.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {
  CompanyAddOrder, CompanyOrder, CompanySetErrorNull,
  CompanySetStatusNull,
  CompanyUpdateOrder
} from '../../../../../core/store/actions/company.actions';
import * as moment from 'moment';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {ContactFilter} from '../../../../../core/store/actions/contact.actions';
import {ActivatedRoute, Router} from '@angular/router';
import {fromToDate, trimValidator, websiteValidator} from '../../../../../app-validators';
import {ContactService} from '../../../../../core/store/services/contact.service';
import * as _ from 'lodash';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { OrderFilterComponent } from './order-filter/order-filter.component';


@Component({
  selector: 'app-order-form',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @ViewChild('contactDropdown') contactDropdown: DropDownListComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @ViewChild('orderFilter') public orderFilterForm: OrderFilterComponent;
  @Input() selectedCompanyCode: string;
  @Input() selectedCompanyOrderContactCode: string;
  @Input() contactList: any = [];
  @Input() orderRoleList: [];
  @Input() users: any;
  // tslint:disable-next-line:variable-name
  @Input() order_code: string;
  @Input() currency: [];
  @Input() notes: any;
  @Input() freePeriodStart: any;
  @Input() freePeriodEnd: any;
  @Input() orderStatus: any;
  @Input() dealType: any;
  @Input() isFilterExist: any;
  @Output() valueChange = new EventEmitter();
  companyOrderForm: FormGroup;
  contactRoleForm: FormGroup;
  companyOrderDriveLinkForm: FormGroup;
  isContactRoleForm = false;
  isOrderForm = false;
  isDuplicateOrderForm = false;
  isDriveLinkForm = false;
  isContactRoleUpdate = false;
  isDriveLinkUpdate = false;
  isSubmitted = false;
  public width = 'auto';
  public position = 'Right';
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<string>;
  public formSent: boolean;
  selectedDriveLinkCode: string;
  allowEdit = false;
  orderRoleFields = {value: 'order_role_code', text: 'order_role_name'};
  currencyFields = {value: 'currency_code', text: 'currency_name'};
  userFields: object = {value: 'user_code', text: 'first_name'};
  orderStatusFields: object = {value: 'order_status_code', text: 'order_status_name'};
  public contactFields: object = {value: 'value', text: 'name'};
  dealTypeFields = {text: 'common_name', value: 'common_code'};
  selectedContact: any;
  // tslint:disable-next-line:variable-name
  public _companyOrderInput$: BehaviorSubject<Order | null> = new BehaviorSubject<Order | null>(null);
  private selectedName: string;

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  @Input() set companyOrderInput(mode: Order | null) {
    this._companyOrderInput$.next(mode);
  }

  get companyOrderInput(): Order | null {
    return this._companyOrderInput$.getValue();
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService
  ) {}


  ngOnChanges(changes: SimpleChanges) {

    if (this._companyOrderInput$.getValue()) {

      this.companyOrderForm.setValue({
        order_name: this._companyOrderInput$.getValue().order_name ? this._companyOrderInput$.getValue().order_name : null,
        amount: this._companyOrderInput$.getValue().amount ? this._companyOrderInput$.getValue().amount : null,
        notes: this._companyOrderInput$.getValue().notes ? this._companyOrderInput$.getValue().notes : null,
        start_date: this._companyOrderInput$.getValue().start_date ?
          moment(this._companyOrderInput$.getValue().start_date, 'YYYY-MM-DD HH:mm').toDate() : null,
        end_date: this._companyOrderInput$.getValue().end_date ?
          moment(this._companyOrderInput$.getValue().end_date, 'YYYY-MM-DD HH:mm').toDate() : null,
        start_free_period_date: this._companyOrderInput$.getValue().start_free_period_date ?
          moment(this._companyOrderInput$.getValue().start_free_period_date, 'YYYY-MM-DD HH:mm').toDate() : null,
        end_free_period_date: this._companyOrderInput$.getValue().end_free_period_date ?
          moment(this._companyOrderInput$.getValue().end_free_period_date, 'YYYY-MM-DD HH:mm').toDate() : null,
        currency_code: this._companyOrderInput$.getValue().currency ? this._companyOrderInput$.getValue().currency.currency_code : null,
        order_owner_code: this._companyOrderInput$.getValue().order_owner ?
          this._companyOrderInput$.getValue().order_owner.user_code : null,
        order_status_code: this._companyOrderInput$.getValue().order_status ?
          this._companyOrderInput$.getValue().order_status.order_status_code : null,
        deal_type: this._companyOrderInput$.getValue().deal_type ? this._companyOrderInput$.getValue().deal_type.common_code : null
      });
    }
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe((data: any) => {
      this.selectedCompanyCode = data.params.company_code;
    });
    if (!this.companyOrderForm) {
      this.generateCompanyOrderForm();
    }

    if (!this.contactRoleForm) {
      this.generateContactRoleForm();
    }

    if (!this.companyOrderDriveLinkForm) {
      this.generateCompanyDriveLinkForm();
    }

    this.error$ = this.store.pipe(select(store => store.companies.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;

      }
    });

    this.success$ = this.store.pipe(select(store => store.companies.success.isForm));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new CompanySetStatusNull());
      }
    });
  }

  generateCompanyOrderForm() {
    this.companyOrderForm = this.formBuilder.group({
      order_name: new FormControl('', [Validators.required, Validators.maxLength(500), Validators.compose([trimValidator])]),
      amount: new FormControl('', [Validators.required, Validators.maxLength(15), Validators.compose([trimValidator])]),
      notes: new FormControl('', [Validators.maxLength(1000)]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
      end_free_period_date: new FormControl(''),
      start_free_period_date: new FormControl(''),
      currency_code: new FormControl('', [Validators.required]),
      order_owner_code: new FormControl('', [Validators.required]),
      order_status_code: new FormControl('', Validators.required),
      deal_type: new FormControl('', [Validators.required])
    }, {
      validators: [fromToDate('start_date', 'end_date'),
        fromToDate('start_free_period_date', 'end_free_period_date', 'freePeriodDateError')]
    });
  }

  generateContactRoleForm() {
    this.contactRoleForm = this.formBuilder.group({
      contact_code: new FormControl('', [Validators.required]),
      order_role_code: new FormControl('', [Validators.required]),
    });
  }

  generateCompanyDriveLinkForm() {
    this.companyOrderDriveLinkForm = this.formBuilder.group({
      drive_link: new FormControl('', [Validators.required, Validators.compose([websiteValidator])]),
    });
  }

  onAddUpdateCompanyOrder(form: FormGroup, isAdd = true) {
    this.isSubmitted = true;
    if (!form.invalid) {
      this.formSent = true;
      // tslint:disable-next-line:variable-name one-variable-per-declaration
      let end_free_period_date;
      // tslint:disable-next-line:variable-name one-variable-per-declaration
      let start_free_period_date;
      this.isSubmitted = false;
      if (form.value.start_free_period_date && form.value.end_free_period_date) {
        end_free_period_date = moment(form.value.end_free_period_date).format('YYYY-MM-DD');
        start_free_period_date = moment(form.value.start_free_period_date).format('YYYY-MM-DD');
      }
      // tslint:disable-next-line:variable-name
      const start_date = moment(form.value.start_date).format('YYYY-MM-DD');
      // tslint:disable-next-line:variable-name
      const end_date = moment(form.value.end_date).format('YYYY-MM-DD');

      let request: any = {
        order_name: this.companyOrderForm.value.order_name.trim(),
        amount: this.companyOrderForm.value.amount,
        notes: this.companyOrderForm.value.notes,
        start_date,
        end_date,
        end_free_period_date,
        start_free_period_date,
        company_code: this.selectedCompanyCode,
        currency_code: this.companyOrderForm.value.currency_code,
        order_owner_code: this.companyOrderForm.value.order_owner_code,
        order_status_code: this.companyOrderForm.value.order_status_code,
        deal_type: this.companyOrderForm.value.deal_type
      };

      if (this.isCompanyLink()) {
        request = {...request, page: 'company'};
      } else {
        request = {...request, page: 'order'};
      }

      if (isAdd) {
        if (this.isFilterExist) {
          this.router.navigate([], { queryParams: {} });
          this.store.dispatch(new CompanyAddOrder(request));
          this.store.dispatch(new CompanyOrder({
            company_code: this.selectedCompanyCode,
            page_size: 100,
            page_no: 1
          }));
        } else {
          this.store.dispatch(new CompanyAddOrder(request));
        }
      } else {
        request = {...request, company_order_code: this._companyOrderInput$.getValue().company_order_code};
        this.store.dispatch(new CompanyUpdateOrder(request));
      }
    }
  }

  isCompanyLink(): boolean {
    return this.router.url.indexOf('/company') > -1;
  }


  openSidebar(args): void {
    if (args.action === 'addRole') {
      this.isContactRoleForm = true;
    } else if (args.action === 'orderForm') {
      this.isOrderForm = true;
    } else if (args.action === 'duplicateOrderForm') {
      this.isDuplicateOrderForm = true;
    } else if (args.action === 'updateRole') {
      if (!this.contactRoleForm) {
        this.generateContactRoleForm();
      }
      this.isContactRoleForm = true;
      this.isContactRoleUpdate = true;
      // tslint:disable-next-line:variable-name
      const order_roles = [];
      this.selectedContact = args.role;
      this.searchAndSetContact();
      for (const type of args.role.contact_role) {
        order_roles.push(type.order_role_code);
      }
      this.contactRoleForm.setValue({
        contact_code: args.role.contact_code,
        order_role_code: order_roles
      });
    } else if (args.action === 'addLink') {
      this.isDriveLinkForm = true;
    } else if (args.action === 'updateLink') {
      this.isDriveLinkForm = true;
      this.isDriveLinkUpdate = true;
      this.selectedDriveLinkCode = args.drive.company_order_drive_link_code;

      this.companyOrderDriveLinkForm.setValue({
        drive_link: args.drive.drive_link,
      });
    }
    this.isSubmitted = false;
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    if (this.isOrderForm || this.isDuplicateOrderForm) {
      this.companyOrderForm.reset();
    } else if (this.isContactRoleForm) {
      this.contactRoleForm.reset();
    }
    this.valueChange.emit({action: 'success'});
    this.sidebarInstance.hide();
    this.isSubmitted = false;
    this.isOrderForm = false;
    this.isDuplicateOrderForm = false;
    this.isContactRoleForm = false;
    this.isDriveLinkForm = false;
    this.isContactRoleUpdate = false;
    this.isDriveLinkUpdate = false;
    this.store.dispatch(new CompanySetErrorNull());

  }

  addContactRole(form: FormGroup, isAdd = true) {
    if (!form.invalid) {
      let request: any = {
        company_order_code: this.order_code,
        contact_code: form.value.contact_code,
        order_role_code: form.value.order_role_code,
        company_code: this.selectedCompanyCode,
        page: 'company',
        notes: this.notes,
        start_free_period_date: this.freePeriodStart,
        end_free_period_date: this.freePeriodEnd
      };

      if (this.isCompanyLink()) {
        request = {...request, page: 'company'};
      } else {
        request = {...request, page: 'order'};
      }

      if (!isAdd) {
        request = {...request, company_order_contact_role_code: this.selectedContact.company_order_contact_role_code};
      }
      this.store.dispatch(new CompanyUpdateOrder(request));
    }
  }

  addDriveLink(form: FormGroup) {
    if (!form.invalid) {
      let request = {
        company_order_code: this.order_code,
        drive_link: form.value.drive_link,
        company_code: this.selectedCompanyCode,
        page: 'company',
        notes: this.notes,
        start_free_period_date: this.freePeriodStart,
        end_free_period_date: this.freePeriodEnd
      };
      if (this.isCompanyLink()) {
        request = {...request, page: 'company'};
      } else {
        request = {...request, page: 'order'};
      }
      this.store.dispatch(new CompanyUpdateOrder(request));
    }
  }

  updateDriveLink(form: FormGroup) {
    if (!form.invalid) {
      let request = {
        company_order_code: this.order_code,
        drive_link: form.value.drive_link,
        company_order_drive_link_code: this.selectedDriveLinkCode,
        company_code: this.selectedCompanyCode,
        page: 'company',
        notes: this.notes,
        start_free_period_date: this.freePeriodStart,
        end_free_period_date: this.freePeriodEnd
      };

      if (this.isCompanyLink()) {
        request = {...request, page: 'company'};
      } else {
        request = {...request, page: 'order'};
      }
      this.store.dispatch(new CompanyUpdateOrder(request));
    }
  }

  searchAndSetContact() {
    this.selectedName = this.selectedContact.first_name ? this.selectedContact.first_name : null;
    if (this.selectedName) {
      const isContactExistsInList = this.contactList.filter((el: any) => el.name === this.selectedName).length > 0;
      if (!isContactExistsInList) {
        const contactList: any = [];
        let company;
        company = '';

        const result = {
          value: this.selectedContact.contact_code,
          name: (this.selectedContact.first_name + ' ' + this.selectedContact.last_name + company),
        };
        contactList.push(result);
        // this.contactList.forEach(el => contactList.push(el));

        this.contactList = [...this.contactList, ...contactList];
        // this.contactDropdown.dataSource = contactList;
        this.contactRoleForm.controls.contact_code.setValue(this.selectedContact.contact_code);
      }
    }
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'contact') {

      query = (e.text !== '') ? query.where('value', 'contains', e.text, true) : query;
      const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
      // this.store.dispatch(new ContactFilter(contactfilterParams));

      this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {

              if (contact.associated_companies) {
                for (const associatedCompany of contact.associated_companies) {
                  let company = '';
                  // tslint:disable-next-line:variable-name
                  let company_code = '';
                  const company_contact_code = associatedCompany.company_contact_code;

                  if (associatedCompany.company) {
                    company = ' - ' + associatedCompany.company.company_name;
                    company_code = associatedCompany.company.company_code;
                  } else {
                    company = ' (' + associatedCompany.email + ')';
                    company_code = '';
                  }

                  const result = {
                    value: contact.contact_code,
                    name: (contact.first_name + ' ' + contact.last_name + company),
                    company_code, contact_code: contact.contact_code
                  };
                  companyContactList.push(result);
                }
              }
            }
            // this.contactList = companyContactList;
            e.updateData(companyContactList, query);
          }
        }
      });
    }
  }

  onSelectFreePeriodDate(args, form: FormGroup) {
    if (args.value !== '' && args.value !== null) {
      form.controls.end_free_period_date.setValidators([Validators.required]);
      form.controls.end_free_period_date.markAsTouched();
      form.controls.end_free_period_date.updateValueAndValidity();
    } else if (args.value === '' || args.value === null) {
      form.controls.end_free_period_date.setValue(null);
      form.controls.end_free_period_date.clearValidators();
      form.controls.end_free_period_date.updateValueAndValidity();
    }
  }

  clearAll() {
    this.clearModal.hide();
    if (this.companyOrderForm) {
    this.companyOrderForm.reset();
    }
    if (this.contactRoleForm) {
      this.contactRoleForm.reset();
    }
    if (this.companyOrderDriveLinkForm) {
      this.companyOrderDriveLinkForm.reset();
    }
  }
  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }
}
