import {Action} from '@ngrx/store';
import {Project} from '../../models/project.model';
import {Activity} from '../../models/activity.model';
import {ProjectSource} from '../../models/project-source.model';
import {ProjectRole} from '../../models/project-role.model';
import {TransactionHistory} from '../../models/transaction-history.model';
import {CompanyActionTypes} from './company.actions';
import {Company} from '../../models/company.model';
import {ProjectActionTypes} from './project.actions';
import {Article} from '../../models/article.model';

export enum ArticleActionTypes {

  LOAD = '[ARTICLE] Load Action',
  LOAD_SUCCESS = '[ARTICLE] Load Success Action',
  LOAD_FAILURE = '[ARTICLE] Load Failure Action',

  ADD = '[ARTICLE] Add Article Action',
  ADD_SUCCESS = '[ARTICLE] Add Article Success Action',
  ADD_FAILURE = '[ARTICLE] Add Article Failure Action',

  UPDATE = '[ARTICLE] Update Article Action',
  UPDATE_SUCCESS = '[ARTICLE] Update Article Success Action',
  UPDATE_FAILURE = '[ARTICLE] Update Article Failure Action',

  DELETE = '[ARTICLE] Delete Article Action',
  DELETE_SUCCESS = '[ARTICLE] Delete Article Success Action',
  DELETE_FAILURE = '[ARTICLE] Delete Article Failure Action',

  GET_ONE_ARTICLE = '[ARTICLE] Get One Article Action',
  GET_ONE_ARTICLE_SUCCESS = '[ARTICLE] Get One Article Success Action',
  GET_ONE_ARTICLE_FAILURE = '[ARTICLE] Get One Article Failure Action',

  GET_ARTICLE_CONTACTS = '[ARTICLE] Get Article Contacts Action',
  GET_ARTICLE_CONTACTS_SUCCESS = '[ARTICLE] Get Article Contacts Success Action',
  GET_ARTICLE_CONTACTS_FAILURE = '[ARTICLE] Get Article Contacts Failure Action',

  ADD_ARTICLE_CONTACT = '[ARTICLE] Add Article Contacts Action',
  ADD_ARTICLE_CONTACT_SUCCESS = '[ARTICLE] Add Article Contact Success Action',
  ADD_ARTICLE_CONTACT_FAILURE = '[ARTICLE] Add Article Contact Failure Action',

  UPDATE_ARTICLE_CONTACT = '[ARTICLE] Update Article Contacts Action',
  UPDATE_ARTICLE_CONTACT_SUCCESS = '[ARTICLE] Update Article Contact Success Action',
  UPDATE_ARTICLE_CONTACT_FAILURE = '[ARTICLE] Update Article Contact Failure Action',

  DELETE_ARTICLE_CONTACT = '[ARTICLE] Delete Article Contacts Action',
  DELETE_ARTICLE_CONTACT_SUCCESS = '[ARTICLE] Delete Article Contact Success Action',
  DELETE_ARTICLE_CONTACT_FAILURE = '[ARTICLE] Delete Article Contact Failure Action',

  GET_ARTICLE_COMPANY = '[ARTICLE] Get Article Company Action',
  GET_ARTICLE_COMPANY_SUCCESS = '[ARTICLE] Get Article Company Success Action',
  GET_ARTICLE_COMPANY_FAILURE = '[ARTICLE] Get Article Company Failure Action',

  ADD_ARTICLE_COMPANY = '[ARTICLE] Add Article Company Action',
  ADD_ARTICLE_COMPANY_SUCCESS = '[ARTICLE] Add Article Company Success Action',
  ADD_ARTICLE_COMPANY_FAILURE = '[ARTICLE] Add Article Company Failure Action',

  UPDATE_ARTICLE_COMPANY = '[ARTICLE] Update Article Company Action',
  UPDATE_ARTICLE_COMPANY_SUCCESS = '[ARTICLE] Update Article Company Success Action',
  UPDATE_ARTICLE_COMPANY_FAILURE = '[ARTICLE] Update Article Company Failure Action',

  DELETE_ARTICLE_COMPANY = '[ARTICLE] Delete Article Company Action',
  DELETE_ARTICLE_COMPANY_SUCCESS = '[ARTICLE] Delete Article Company Success Action',
  DELETE_ARTICLE_COMPANY_FAILURE = '[ARTICLE] Delete Article Company Failure Action',

  GET_ARTICLE_PROJECT = '[ARTICLE] Get Article Project Action',
  GET_ARTICLE_PROJECT_SUCCESS = '[ARTICLE] Get Article Project Success Action',
  GET_ARTICLE_PROJECT_FAILURE = '[ARTICLE] Get Article Project Failure Action',

  ADD_ARTICLE_PROJECT = '[ARTICLE] Add Article Project Action',
  ADD_ARTICLE_PROJECT_SUCCESS = '[ARTICLE] Add Article Project Success Action',
  ADD_ARTICLE_PROJECT_FAILURE = '[ARTICLE] Add Article Project Failure Action',

  UPDATE_ARTICLE_PROJECT = '[ARTICLE] Update Article Project Action',
  UPDATE_ARTICLE_PROJECT_SUCCESS = '[ARTICLE] Update Article Project Success Action',
  UPDATE_ARTICLE_PROJECT_FAILURE = '[ARTICLE] Update Article Project Failure Action',

  DELETE_ARTICLE_PROJECT = '[ARTICLE] Delete Article Project Action',
  DELETE_ARTICLE_PROJECT_SUCCESS = '[ARTICLE] Delete Article Project Success Action',
  DELETE_ARTICLE_PROJECT_FAILURE = '[ARTICLE] Delete Article Project Failure Action',

  // Get Article Brand
  GET_ARTICLE_BRAND = '[ARTICLE] Get Article Brand Action',
  GET_ARTICLE_BRAND_SUCCESS = '[ARTICLE] Get Article Brand Success Action',
  GET_ARTICLE_BRAND_FAILURE = '[ARTICLE] Get Article Brand Failure Action',

  ADD_ARTICLE_BRAND = '[ARTICLE] Add Article Brand Action',
  ADD_ARTICLE_BRAND_SUCCESS = '[ARTICLE] Add Article Brand Success Action',
  ADD_ARTICLE_BRAND_FAILURE = '[ARTICLE] Add Article Brand Failure Action',

  UPDATE_ARTICLE_BRAND = '[ARTICLE] Update Article Brand Action',
  UPDATE_ARTICLE_BRAND_SUCCESS = '[ARTICLE] Update Article Brand Success Action',
  UPDATE_ARTICLE_BRAND_FAILURE = '[ARTICLE] Update Article Brand Failure Action',

  DELETE_ARTICLE_BRAND = '[ARTICLE] Delete Article Brand Action',
  DELETE_ARTICLE_BRAND_SUCCESS = '[ARTICLE] Delete Article Brand Success Action',
  DELETE_ARTICLE_BRAND_FAILURE = '[ARTICLE] Delete Article Brand Failure Action',

  // Get Article Product
  GET_ARTICLE_PRODUCT = '[ARTICLE] Get Article Product Action',
  GET_ARTICLE_PRODUCT_SUCCESS = '[ARTICLE] Get Article Product Success Action',
  GET_ARTICLE_PRODUCT_FAILURE = '[ARTICLE] Get Article Product Failure Action',

  ADD_ARTICLE_PRODUCT = '[ARTICLE] Add Article Product Action',
  ADD_ARTICLE_PRODUCT_SUCCESS = '[ARTICLE] Add Article Product Success Action',
  ADD_ARTICLE_PRODUCT_FAILURE = '[ARTICLE] Add Article Product Failure Action',

  UPDATE_ARTICLE_PRODUCT = '[ARTICLE] Update Article Product Action',
  UPDATE_ARTICLE_PRODUCT_SUCCESS = '[ARTICLE] Update Article Product Success Action',
  UPDATE_ARTICLE_PRODUCT_FAILURE = '[ARTICLE] Update Article Product Failure Action',

  DELETE_ARTICLE_PRODUCT = '[ARTICLE] Delete Article Product Action',
  DELETE_ARTICLE_PRODUCT_SUCCESS = '[ARTICLE] Delete Article Product Success Action',
  DELETE_ARTICLE_PRODUCT_FAILURE = '[ARTICLE] Delete Article Product Failure Action',

  // Get Article Collections
  GET_ARTICLE_COLLECTION = '[ARTICLE] Get Article Collection Action',
  GET_ARTICLE_COLLECTION_SUCCESS = '[ARTICLE] Get Article Collection Success Action',
  GET_ARTICLE_COLLECTION_FAILURE = '[ARTICLE] Get Article Collection Failure Action',

  ADD_ARTICLE_COLLECTION = '[ARTICLE] Add Article Collection Action',
  ADD_ARTICLE_COLLECTION_SUCCESS = '[ARTICLE] Add Article Collection Success Action',
  ADD_ARTICLE_COLLECTION_FAILURE = '[ARTICLE] Add Article Collection Failure Action',

  UPDATE_ARTICLE_COLLECTION = '[ARTICLE] Update Article Collection Action',
  UPDATE_ARTICLE_COLLECTION_SUCCESS = '[ARTICLE] Update Article Collection Success Action',
  UPDATE_ARTICLE_COLLECTION_FAILURE = '[ARTICLE] Update Article Collection Failure Action',

  DELETE_ARTICLE_COLLECTION = '[ARTICLE] Delete Article Collection Action',
  DELETE_ARTICLE_COLLECTION_SUCCESS = '[ARTICLE] Delete Article Collection Success Action',
  DELETE_ARTICLE_COLLECTION_FAILURE = '[ARTICLE] Delete Article Collection Failure Action',

  // Get Article Edition
  GET_ARTICLE_EDITION = '[ARTICLE] Get Article Edition Action',
  GET_ARTICLE_EDITION_SUCCESS = '[ARTICLE] Get Article Edition Success Action',
  GET_ARTICLE_EDITION_FAILURE = '[ARTICLE] Get Article Edition Failure Action',

  ADD_ARTICLE_EDITION = '[ARTICLE] Add Article Edition Action',
  ADD_ARTICLE_EDITION_SUCCESS = '[ARTICLE] Add Article Edition Success Action',
  ADD_ARTICLE_EDITION_FAILURE = '[ARTICLE] Add Article Edition Failure Action',

  UPDATE_ARTICLE_EDITION = '[ARTICLE] Update Article Edition Action',
  UPDATE_ARTICLE_EDITION_SUCCESS = '[ARTICLE] Update Article Edition Success Action',
  UPDATE_ARTICLE_EDITION_FAILURE = '[ARTICLE] Update Article Edition Failure Action',

  DELETE_ARTICLE_EDITION = '[ARTICLE] Delete Article Edition Action',
  DELETE_ARTICLE_EDITION_SUCCESS = '[ARTICLE] Delete Article Edition Success Action',
  DELETE_ARTICLE_EDITION_FAILURE = '[ARTICLE] Delete Article Edition Failure Action',

  // Get Article Edition
  GET_ARTICLE_TRANSACTION_HISTORY = '[ARTICLE] Get Article Transaction History Action',
  GET_ARTICLE_TRANSACTION_HISTORY_SUCCESS = '[ARTICLE] Get Article Transaction History Success Action',
  GET_ARTICLE_TRANSACTION_HISTORY_FAILURE = '[ARTICLE] Get Article Transaction History Failure Action',

  LOAD_ARTICLE_GENERAL = '[ARTICLE] Load Article General Action',
  LOAD_ARTICLE_GENERAL_SUCCESS = '[ARTICLE] Load Article General Success Action',
  LOAD_ARTICLE_GENERAL_FAILURE = '[ARTICLE] Load Article General Failure Action',

  UPDATE_ARTICLE_GENERAL = '[ARTICLE] Update Article General Action',
  UPDATE_ARTICLE_GENERAL_SUCCESS = '[ARTICLE] Update Article General Success Action',
  UPDATE_ARTICLE_GENERAL_FAILURE = '[ARTICLE] Update Article General Failure Action',

  LOAD_ARTICLE_LTD_SETTINGS = '[ARTICLE] Load Article LTD Settings Action',
  LOAD_ARTICLE_LTD_SETTINGS_SUCCESS = '[ARTICLE] Load Article LTD Settings Success Action',
  LOAD_ARTICLE_LTD_SETTINGS_FAILURE = '[ARTICLE] Load Article LTD Settings Failure Action',

  UPDATE_ARTICLE_LTD_SETTINGS = '[ARTICLE] Update Article LTD Settings Action',
  UPDATE_ARTICLE_LTD_SETTINGS_SUCCESS = '[ARTICLE] Update Article LTD Settings Success Action',
  UPDATE_ARTICLE_LTD_SETTINGS_FAILURE = '[ARTICLE] Update Article LTD Settings Failure Action',

  // Links
  LOAD_ARTICLE_LINKS = '[ARTICLE] Load Article Links Action',
  LOAD_ARTICLE_LINKS_SUCCESS = '[ARTICLE] Load Article Links Success Action',
  LOAD_ARTICLE_LINKS_FAILURE = '[ARTICLE] Load Article Links Failure Action',

  UPDATE_ARTICLE_LINKS = '[ARTICLE] Update Article Links Action',
  UPDATE_ARTICLE_LINKS_SUCCESS = '[ARTICLE] Update Article Links Success Action',
  UPDATE_ARTICLE_LINKS_FAILURE = '[ARTICLE] Update Article Links Failure Action',

  FILTER = '[ARTICLE] Filter Action',
  FILTER_SUCCESS = '[ARTICLE] Filter Success Action',
  FILTER_FAILURE = '[ARTICLE] Filter Failure Action',

  SEARCH_ARTICLE = '[ARTICLE] Search Action',
  SEARCH_ARTICLE_SUCCESS = '[ARTICLE] Search Success Action',
  SEARCH_ARTICLE_FAILURE = '[ARTICLE] Search Failure Action',

  FILTER_RESET = '[ARTICLE] FILTER RESET',
  SET_SUCCESS_NULL = '[ARTICLE] Set Success Null',
  UPDATE_SYNC_STATUS = '[ARTICLE] UPDATE SYNC STATUS',


}

export class ArticleLoad implements Action {
  readonly type = ArticleActionTypes.LOAD;

  constructor(public payload?: { page_size?: number, page_no?: number }) {
  }
}

export class ArticleLoadSuccess implements Action {
  readonly type = ArticleActionTypes.LOAD_SUCCESS;

  constructor(public payload: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class ArticleLoadFailure implements Action {
  readonly type = ArticleActionTypes.LOAD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class LoadOneArticle implements Action {
  readonly type = ArticleActionTypes.GET_ONE_ARTICLE;

  constructor(public payload?: { article_code: string }) {
  }
}

export class LoadOneArticleSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ONE_ARTICLE_SUCCESS;

  constructor(public payload: { article: Article }) {
  }
}

export class LoadOneArticleFailure implements Action {
  readonly type = ArticleActionTypes.GET_ONE_ARTICLE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticle implements Action {
  readonly type = ArticleActionTypes.ADD;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_SUCCESS;

  constructor(public payload: { article: Article }) {
  }
}

export class AddArticleFailure implements Action {
  readonly type = ArticleActionTypes.ADD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ArticleUpdate implements Action {
  readonly type = ArticleActionTypes.UPDATE;

  constructor(public payload: { article_code: string, article: {} }) {
  }
}

export class ArticleUpdateSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { article_code: string, article: Article }) {
  }
}

export class ArticleUpdateFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ArticleDelete implements Action {
  readonly type = ArticleActionTypes.DELETE;

  constructor(public payload: { article_code: string }) {
  }
}

export class ArticleDeleteSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_SUCCESS;

  constructor(public payload: { article_code: string }) {
  }
}

export class ArticleDeleteFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ArticleFilter implements Action {
  readonly type = ArticleActionTypes.FILTER;

  constructor(public payload: {
    article_name?: string, reference_id?: string, article_category_code?: [], country?: [], tags?: string[],article_company_code?:string,
    assigned_to?: string, proposed_post_date?: string, status?: string[], page_no?: number, page_size?: number, sort_by?: string,
    sort_order?: string, company_code?: string, article_contact_code?: string, company_code_source?: string, contact_code_article?: string
  }) {
  }
}

export class ArticleFilterSuccess implements Action {
  readonly type = ArticleActionTypes.FILTER_SUCCESS;

  constructor(public payload: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class ArticleFilterFailure implements Action {
  readonly type = ArticleActionTypes.FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ArticleFilterReset implements Action {
  readonly type = ArticleActionTypes.FILTER_RESET;
}

export class ArticleSetSuccessNull implements Action {
  readonly type = ArticleActionTypes.SET_SUCCESS_NULL;
}

export class GetArticleContacts implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_CONTACTS;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleContactsSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_CONTACTS_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleContactsFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_CONTACTS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleContact implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_CONTACT;

  constructor(public payload: { article_code: string, company_contact_code }) {
  }
}

export class AddArticleContactSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_CONTACT_SUCCESS;

  constructor(public payload: { contact: any }) {
  }
}

export class AddArticleContactFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_CONTACT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleContact implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_CONTACT;

  constructor(public payload: { article_code: string, company_contact_code }) {
  }
}

export class UpdateArticleContactSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_CONTACT_SUCCESS;

  constructor(public payload: { contact: any }) {
  }
}

export class UpdateArticleContactFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_CONTACT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleContact implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_CONTACT;

  constructor(public payload: { article_contact_link_code: string }) {
  }
}

export class DeleteArticleContactSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_CONTACT_SUCCESS;

  constructor(public payload: { article_contact_link_code: any }) {
  }
}

export class DeleteArticleContactFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_CONTACT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleCompanies implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_COMPANY;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleCompaniesSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_COMPANY_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleCompaniesFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleCompany implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_COMPANY;

  constructor(public payload: { article_code: string, company_code }) {
  }
}

export class AddArticleCompanySuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_COMPANY_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleCompanyFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleCompany implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_COMPANY;

  constructor(public payload: { article_code: string, company_code }) {
  }
}

export class UpdateArticleCompanySuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_COMPANY_SUCCESS;

  constructor(public payload: { company: any }) {
  }
}

export class UpdateArticleCompanyFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleCompany implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_COMPANY;

  constructor(public payload: { article_company_link_code: string }) {
  }
}

export class DeleteArticleCompanySuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_COMPANY_SUCCESS;

  constructor(public payload: { article_company_link_code: string }) {
  }
}

export class DeleteArticleCompanyFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleProjects implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_PROJECT;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleProjectsSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_PROJECT_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleProjectsFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_PROJECT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleProject implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_PROJECT;

  constructor(public payload: { article_code: string, project_code: string }) {
  }
}

export class AddArticleProjectSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_PROJECT_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleProjectFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_PROJECT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleProject implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_PROJECT;

  constructor(public payload: { article_code: string, project_code }) {
  }
}

export class UpdateArticleProjectSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_PROJECT_SUCCESS;

  constructor(public payload: { project: any }) {
  }
}

export class UpdateArticleProjectFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_PROJECT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleProject implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_PROJECT;

  constructor(public payload: { article_project_link_code: string }) {
  }
}

export class DeleteArticleProjectSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_PROJECT_SUCCESS;

  constructor(public payload: { article_project_link_code: string }) {
  }
}

export class DeleteArticleProjectFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_PROJECT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleBrand implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_BRAND;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleBrandSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_BRAND_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleBrandFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_BRAND_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleBrand implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_BRAND;

  constructor(public payload: { article_code: string, brand_code }) {
  }
}

export class AddArticleBrandSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_BRAND_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleBrandFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_BRAND_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleBrand implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_BRAND;

  constructor(public payload: { article_code: string, brand_code }) {
  }
}

export class UpdateArticleBrandSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_BRAND_SUCCESS;

  constructor(public payload: { brand: any }) {
  }
}

export class UpdateArticleBrandFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_BRAND_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleBrand implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_BRAND;

  constructor(public payload: { article_brand_link_code: string }) {
  }
}

export class DeleteArticleBrandSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_BRAND_SUCCESS;

  constructor(public payload: { article_brand_link_code: string }) {
  }
}

export class DeleteArticleBrandFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_BRAND_FAILURE;

  constructor(public payload: Error) {
  }
}

// Product
export class GetArticleProduct implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_PRODUCT;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleProductSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_PRODUCT_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleProductFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_PRODUCT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleProduct implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_PRODUCT;

  constructor(public payload: { article_code: string, product_code }) {
  }
}

export class AddArticleProductSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_PRODUCT_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleProductFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_PRODUCT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleProduct implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_PRODUCT;

  constructor(public payload: { article_code: string, product_code }) {
  }
}

export class UpdateArticleProductSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_PRODUCT_SUCCESS;

  constructor(public payload: { product: any }) {
  }
}

export class UpdateArticleProductFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_PRODUCT_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleProduct implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_PRODUCT;

  constructor(public payload: { article_product_link_code: string }) {
  }
}

export class DeleteArticleProductSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_PRODUCT_SUCCESS;

  constructor(public payload: { article_product_link_code: string }) {
  }
}

export class DeleteArticleProductFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_PRODUCT_FAILURE;

  constructor(public payload: Error) {
  }
}

// Collection
export class GetArticleCollection implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_COLLECTION;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleCollectionSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_COLLECTION_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleCollectionFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_COLLECTION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleCollection implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_COLLECTION;

  constructor(public payload: { article_code: string, collection_code: string }) {
  }
}

export class AddArticleCollectionSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_COLLECTION_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleCollectionFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_COLLECTION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleCollection implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_COLLECTION;

  constructor(public payload: { article_code: string, collection_code }) {
  }
}

export class UpdateArticleCollectionSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_COLLECTION_SUCCESS;

  constructor(public payload: { collection: any }) {
  }
}

export class UpdateArticleCollectionFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_COLLECTION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleCollection implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_COLLECTION;

  constructor(public payload: { article_collection_link_code: string }) {
  }
}

export class DeleteArticleCollectionSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_COLLECTION_SUCCESS;

  constructor(public payload: { article_collection_link_code: string }) {
  }
}

export class DeleteArticleCollectionFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_COLLECTION_FAILURE;

  constructor(public payload: Error) {
  }
}

// Edition
export class GetArticleEdition implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_EDITION;

  constructor(public payload: { article_code: string }) {
  }
}

export class GetArticleEditionSuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_EDITION_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class GetArticleEditionFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_EDITION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddArticleEdition implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_EDITION;

  constructor(public payload: { article_code: string, edition_code }) {
  }
}

export class AddArticleEditionSuccess implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_EDITION_SUCCESS;

  constructor(public payload: { article: any }) {
  }
}

export class AddArticleEditionFailure implements Action {
  readonly type = ArticleActionTypes.ADD_ARTICLE_EDITION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleEdition implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_EDITION;

  constructor(public payload: { article_code: string, edition_code }) {
  }
}

export class UpdateArticleEditionSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_EDITION_SUCCESS;

  constructor(public payload: { edition: any }) {
  }
}

export class UpdateArticleEditionFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_EDITION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteArticleEdition implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_EDITION;

  constructor(public payload: { article_edition_link_code: string }) {
  }
}

export class DeleteArticleEditionSuccess implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_EDITION_SUCCESS;

  constructor(public payload: { article_edition_link_code: string }) {
  }
}

export class DeleteArticleEditionFailure implements Action {
  readonly type = ArticleActionTypes.DELETE_ARTICLE_EDITION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleHistory implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_TRANSACTION_HISTORY;

  constructor(public payload: { articleCode: string }) {
  }
}

export class GetArticleHistorySuccess implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_TRANSACTION_HISTORY_SUCCESS;

  constructor(public payload: { articleCode: string, history: TransactionHistory }) {
  }
}

export class GetArticleHistoryFailure implements Action {
  readonly type = ArticleActionTypes.GET_ARTICLE_TRANSACTION_HISTORY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleGeneral implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_GENERAL;

  constructor(public payload: { articleCode: string }) {
  }
}

export class GetArticleGeneralSuccess implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_GENERAL_SUCCESS;

  constructor(public payload: { articleCode: string, articleGeneral: any }) {
  }
}

export class GetArticleGeneralFailure implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_GENERAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleGeneral implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_GENERAL;

  constructor(public payload: { articleCode: string, articleGeneral: any }) {
  }
}

export class UpdateArticleGeneralSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_GENERAL_SUCCESS;

  constructor(public payload: { articleCode: string, articleGeneral: any }) {
  }
}

export class UpdateArticleGeneralFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_GENERAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleLTDSettings implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_LTD_SETTINGS;

  constructor(public payload: { articleCode: string }) {
  }
}

export class GetArticleLTDSettingsSuccess implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_LTD_SETTINGS_SUCCESS;

  constructor(public payload: { articleCode: string, articleLTDSettings: any }) {
  }
}

export class GetArticleLTDSettingsFailure implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_LTD_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleLTDSettings implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_LTD_SETTINGS;

  constructor(public payload: { articleCode: string, ltdSettings: any }) {
  }
}

export class UpdateArticleLTDSettingsSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_LTD_SETTINGS_SUCCESS;

  constructor(public payload: { articleCode: string, articleLTDSettings: any }) {
  }
}

export class UpdateArticleLTDSettingsFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_LTD_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetArticleLinks implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_LINKS;

  constructor(public payload: { articleCode: string }) {
  }
}

export class GetArticleLinksSuccess implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_LINKS_SUCCESS;

  constructor(public payload: { articleCode: string, articleLinks: any }) {
  }
}

export class GetArticleLinksFailure implements Action {
  readonly type = ArticleActionTypes.LOAD_ARTICLE_LINKS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateArticleLinks implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_LINKS;

  constructor(public payload: { articleCode: string, articleLinks: any }) {
  }
}

export class UpdateArticleLinksSuccess implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_LINKS_SUCCESS;

  constructor(public payload: { articleCode: string, articleLinks: any }) {
  }
}

export class UpdateArticleLinksFailure implements Action {
  readonly type = ArticleActionTypes.UPDATE_ARTICLE_LINKS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ArticleSearch implements Action {
  readonly type = ArticleActionTypes.SEARCH_ARTICLE;

  constructor(public payload: { search?: string, page_no?: number, page_size?: number }) {
  }
}

export class ArticleSearchSuccess implements Action {
  readonly type = ArticleActionTypes.SEARCH_ARTICLE_SUCCESS;

  constructor(public payload: { result: [], total_count: number, page_no: number, page_size: number }) {
  }
}

export class ArticleSearchFailure implements Action {
  readonly type = ArticleActionTypes.SEARCH_ARTICLE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class ArticleUpdateSyncStatus implements Action {
  readonly type = ArticleActionTypes.UPDATE_SYNC_STATUS;

  constructor(public payload: { article_last_sync: any }) {
  }
}


export type ArticleActions = ArticleLoad | ArticleLoadSuccess | ArticleLoadFailure | ArticleFilter | ArticleFilterSuccess |
  ArticleFilterFailure | ArticleFilterReset | ArticleSetSuccessNull |
  LoadOneArticle | LoadOneArticleSuccess | LoadOneArticleFailure |
  AddArticle | AddArticleSuccess | AddArticleFailure |
  GetArticleContacts | GetArticleContactsSuccess | GetArticleContactsFailure |
  AddArticleContact | AddArticleContactSuccess | AddArticleContactFailure |
  UpdateArticleContact | UpdateArticleContactSuccess | UpdateArticleContactFailure |
  DeleteArticleContact | DeleteArticleContactSuccess | DeleteArticleContactFailure |
  GetArticleCompanies | GetArticleCompaniesSuccess | GetArticleCompaniesFailure |
  AddArticleCompany | AddArticleCompanySuccess | AddArticleCompanyFailure |
  UpdateArticleCompany | UpdateArticleCompanySuccess | UpdateArticleCompanyFailure |
  DeleteArticleCompany | DeleteArticleCompanySuccess | DeleteArticleCompanyFailure |
  GetArticleProjects | GetArticleProjectsSuccess | GetArticleProjectsFailure |
  AddArticleProject | AddArticleProjectSuccess | AddArticleProjectFailure |
  UpdateArticleProject | UpdateArticleProjectSuccess | UpdateArticleProjectFailure |
  DeleteArticleProject | DeleteArticleProjectSuccess | DeleteArticleProjectFailure |
  GetArticleBrand | GetArticleBrandSuccess | GetArticleBrandFailure |
  AddArticleBrand | AddArticleBrandSuccess | AddArticleBrandFailure |
  UpdateArticleBrand | UpdateArticleBrandSuccess | UpdateArticleBrandFailure |
  DeleteArticleBrand | DeleteArticleBrandSuccess | DeleteArticleBrandFailure |
  GetArticleProduct | GetArticleProductSuccess | GetArticleProductFailure |
  AddArticleProduct | AddArticleProductSuccess | AddArticleProductFailure |
  UpdateArticleProduct | UpdateArticleProductSuccess | UpdateArticleProductFailure |
  DeleteArticleProduct | DeleteArticleProductSuccess | DeleteArticleProductFailure |
  GetArticleCollection | GetArticleCollectionSuccess | GetArticleCollectionFailure |
  AddArticleCollection | AddArticleCollectionSuccess | AddArticleCollectionFailure |
  UpdateArticleCollection | UpdateArticleCollectionSuccess | UpdateArticleCollectionFailure |
  DeleteArticleCollection | DeleteArticleCollectionSuccess | DeleteArticleCollectionFailure |
  GetArticleEdition | GetArticleEditionSuccess | GetArticleEditionFailure |
  AddArticleEdition | AddArticleEditionSuccess | AddArticleEditionFailure |
  UpdateArticleEdition | UpdateArticleEditionSuccess | UpdateArticleEditionFailure |
  DeleteArticleEdition | DeleteArticleEditionSuccess | DeleteArticleEditionFailure |
  GetArticleHistory | GetArticleHistorySuccess | GetArticleHistoryFailure |
  ArticleUpdate | ArticleUpdateSuccess | ArticleUpdateFailure |
  ArticleDelete | ArticleDeleteSuccess | ArticleDeleteFailure |
  GetArticleGeneral | GetArticleGeneralSuccess | GetArticleGeneralFailure |
  GetArticleLTDSettings | GetArticleLTDSettingsSuccess | GetArticleLTDSettingsFailure |
  UpdateArticleGeneral | UpdateArticleGeneralSuccess | UpdateArticleGeneralFailure |
  UpdateArticleLTDSettings | UpdateArticleLTDSettingsSuccess | UpdateArticleLTDSettingsFailure |
  GetArticleLinks | GetArticleLinksSuccess | GetArticleLinksFailure |
  UpdateArticleLinks | UpdateArticleLinksSuccess | UpdateArticleLinksFailure |
  ArticleSearch | ArticleSearchSuccess | ArticleSearchFailure | ArticleUpdateSyncStatus;

