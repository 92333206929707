<ejs-sidebar
  id="events-sidebar-menu"
  type="Over"
  style="visibility: hidden"
  #sidebarInstance
  [width]="width"
  [position]="'Right'"
  [enableGestures]="false"
  [showBackdrop]="true"
>
  <div id="element" #tabObj>
    <div class="offcanvas-close mt-n1 pr-5" style="float: right; padding: 48px">
      <button
        class="btn btn-xs btn-icon btn-light btn-hover-primary"
        id="kt_quick_panel_close"
        (click)="closeSidebar()"
      >
        <i class="ki ki-close icon-xs text-muted"></i>
      </button>
    </div>
    <div class="p-15">
      <h2>Filter Event</h2>

      <form
        id="addUpdateEventsForm"
        [formGroup]="eventsForm"
        #formDir="ngForm"
        style="margin-top: 16px"
      >
        <!-- Event Name -->
        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox
            id="eventName"
            cssClass="e-filled"
            placeholder="Event Name"
            formControlName="event_name"
            floatLabelType="Auto"
            (blur)="eventsForm.controls.event_name.markAsTouched()"
          >
          </ejs-textbox>
          <span class="text-warning"> {{ duplicateMsg$ | async }}</span>
          <div *ngIf="eventsForm.controls.event_name.errors">
            <div
              *ngIf="eventsForm.controls.event_name.errors.invalidEventName"
              class="e-error"
            >
              Please enter a valid event name
            </div>
            <div
              *ngIf="
                eventsForm.controls.event_name.errors.minlength &&
                eventsForm.controls.event_name.touched
              "
              class="e-error"
            >
              Event name cannot be less than 3 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-datepicker
            id="event_start_date"
            cssClass="e-filled"
            placeholder="Start Date"
            formControlName="start_date"
            floatLabelType="Auto"
            [allowEdit]="allowEdit"
            format="dd-MMM-yyyy"
            (blur)="eventsForm.controls.start_date.markAsTouched()"
          >
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-datepicker
            id="event_end_date"
            cssClass="e-filled"
            placeholder="End Date"
            formControlName="end_date"
            floatLabelType="Auto"
            [allowEdit]="allowEdit"
            format="dd-MMM-yyyy"
            (blur)="eventsForm.controls.end_date.markAsTouched()"
          >
          </ejs-datepicker>
          <div *ngIf="eventsForm.errors">
            <div *ngIf="eventsForm.errors.fromToDate" class="e-error">
              End date cannot be less than start date
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox
            id="city"
            placeholder="City"
            cssClass="e-filled"
            formControlName="city_name"
            floatLabelType="Auto"
            (blur)="eventsForm.controls.city_name.markAsTouched()"
          >
          </ejs-textbox>
          <div *ngIf="eventsForm.controls.city_name.errors">
            <div
              *ngIf="eventsForm.controls.city_name.errors.invalidText"
              class="e-error"
            >
              Please enter a valid city
            </div>
            <div
              *ngIf="
                eventsForm.controls.city_name.errors.maxlength &&
                eventsForm.controls.city_name.touched
              "
              class="e-error"
            >
              City cannot be more than 100 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input custom-ms mb-10">
          <ejs-multiselect
            id="created_by"
            formControlName="created_by"
            #checkbox
            [dataSource]="users"
            placeholder="Created By"
            [fields]="userFields"
            floatLabelType="Auto"
            cssClass="e-filled"
            mode="CheckBox"
            [showDropDownIcon]="true"
            showSelectAll="true"
            filterBarPlaceholder="Search"
          >
            <ng-template
              #itemTemplate=""
              let-data
              style="display: inline-block"
            >
              <div
                style="display: inline-block"
                *ngIf="data.is_disable_yn != 1"
              >
                <span>{{ data.first_name }}</span>
              </div>
              <div
                style="display: inline-block"
                *ngIf="data.is_disable_yn == 1"
              >
                <span [ngStyle]="{ color: '#d3d3d3' }">{{
                  data.first_name
                }}</span>
              </div>
            </ng-template>
          </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input custom-ms mb-10">
          <ejs-multiselect
            #checkbox
            id="updated_by"
            formControlName="updated_by"
            placeholder="Updated By"
            floatLabelType="Auto"
            cssClass="e-filled"
            showSelectAll="true"
            filterBarPlaceholder="Search"
            mode="CheckBox"
            [dataSource]="users"
            [fields]="userFields"
            [showDropDownIcon]="true"
          >
            <ng-template
              #itemTemplate=""
              let-data
              style="display: inline-block"
            >
              <div
                style="display: inline-block"
                *ngIf="data.is_disable_yn != 1"
              >
                <span>{{ data.first_name }}</span>
              </div>
              <div
                style="display: inline-block"
                *ngIf="data.is_disable_yn == 1"
              >
                <span [ngStyle]="{ color: '#d3d3d3' }">{{
                  data.first_name
                }}</span>
              </div>
            </ng-template>
          </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input custom-ms mb-10">
          <ejs-multiselect
            #countryDropdown
            id="country"
            formControlName="country"
            placeholder="Country"
            floatLabelType="Auto"
            sortOrder="Ascending"
            selectAllText="Select All"
            cssClass="e-filled"
            showSelectAll="true"
            filterBarPlaceholder="Search"
            mode="CheckBox"
            [dataSource]="countries"
            [fields]="countryFields"
            [showDropDownIcon]="true"
            (blur)="eventsForm.controls.country.markAsTouched()"
          >
          </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox
            id="eventAddress"
            cssClass="e-filled"
            placeholder="Address"
            formControlName="address"
            floatLabelType="Auto"
            [multiline]="true"
            (blur)="eventsForm.controls.address.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="eventsForm.controls.address.errors">
            <div
              *ngIf="
                eventsForm.controls.address.errors.maxlength &&
                eventsForm.controls.address.touched
              "
              class="e-error"
            >
              Address cannot be more than 200 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox
            id="country"
            placeholder="Company Name"
            cssClass="e-filled"
            formControlName="company_name"
            floatLabelType="Auto"
            (blur)="eventsForm.controls.company_name.markAsTouched()"
          >
          </ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox
            id="contact_name"
            placeholder="Contact Name"
            cssClass="e-filled"
            formControlName="contact_name"
            floatLabelType="Auto"
            (blur)="eventsForm.controls.contact_name.markAsTouched()"
          >
          </ejs-textbox>
        </div>

        <div class="form-group sf-outline-input custom-ms mb-10">
          <ejs-combobox
            #hasAttended
            id="has_attended_yn"
            formControlName="has_attended_yn"
            placeholder="Attendance"
            floatLabelType="Auto"
            cssClass="e-filled"
            [dataSource]="has_attended_options"
            [fields]="has_attended_fields"
            [showDropDownIcon]="true"
            (focus)="hasAttended.showPopup()"
            (change)="onChangeHasAttended($event)"
            (blur)="eventsForm.controls.has_attended_yn.markAsTouched()"
          >
          </ejs-combobox>
        </div>

        <div *ngIf="errorMsg.length > 0" class="e-error">
          <div *ngFor="let message of errorMsg">{{ message }}</div>
        </div>

        <div class="my-10 pb-20">
          <button
            id="resetbtn"
            class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
            type="button"
            data-ripple="true"
            (click)="clear()"
          >
            Clear
          </button>
          <button
            id="addSubmit"
            class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
            type="submit"
            data-ripple="true"
            [disabled]="formSent || eventsForm.invalid"
            (click)="filterEvents(eventsForm)"
          >
            Filter
          </button>
        </div>
      </form>
    </div>
  </div>
</ejs-sidebar>

<ejs-dialog
  #clearModal
  [buttons]="dltButtons"
  header="Are you sure?"
  animationSettings="none"
  showCloseIcon="true"
  width="25%"
  [visible]="false"
>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
