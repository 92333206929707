<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Article Filter</h2>
    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="article_name" placeholder="Article Name" cssClass="e-filled"
                     formControlName="article_name" floatLabelType="Auto"
                     (blur)="filterForm.controls.article_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="reference_id" floatLabelType="Auto"
                     (blur)="filterForm.controls.reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.article_category_code.value == ''">
        <ejs-multiselect id='article_category_code' formControlName="article_category_code" #checkbox
                         [dataSource]='articleCategory' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Article Category' [fields]='articleCategoryFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="filterForm.controls.company_type_code.value == ''">
        <ejs-dropdowntree formControlName="company_type_code" id='company_type_code' allowMultiSelection='true' [treeSettings]='treeSettings'
                          [fields]='companyTypeField' placeholder='Company Type' floatLabelType="Auto" sortOrder="Ascending"
                          cssClass="e-filled" (blur)="filterForm.controls.company_type_code.markAsTouched()"
                          [showCheckBox]='true'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='company' formControlName="company_code" #checkboxCompany [dataSource]='companyList'
                         placeholder='Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'company')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="!isContactLink()">
        <ejs-multiselect id='contact' formControlName="contact_code" #checkboxContact [dataSource]='contactList'
                         placeholder='Contact' [fields]='contactFields' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'contact')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="published_from" (change)="setDuration($event)"
                          placeholder='Published From' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="published_till"
                          placeholder='Published Till' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="filterForm.value.published_from">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="created_from" (change)="setDuration($event)"
                          placeholder='Created From' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="created_till"
                          placeholder='Created Till' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="filterForm.value.created_from">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="updated_from" (change)="setDuration($event)"
                          placeholder='Updated From' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="updated_till"
                          placeholder='Updated Till' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="filterForm.value.updated_from">
          </ejs-datepicker>
        </div>
      </div>



<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-multiselect id='assigned_to_user' formControlName="assigned_to_user" #checkbox [dataSource]='users'-->
<!--                         placeholder='Assigned To' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"-->
<!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'-->
<!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
<!--      </div>-->


      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clearForm()"
                data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="button"
                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
        </button>
      </div>

    </form>

  </div>
</ejs-sidebar>
