import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core';
import { AddArticleEdition, UpdateArticleEdition } from 'src/app/core/store/actions/article.actions';
import { ContactService } from 'src/app/core/store/services/contact.service';

@Component({
  selector: 'app-article-edition-form',
  templateUrl: './article-edition.component.html',
  styleUrls: ['./article-edition.component.scss']
})
export class ArticleEditionComponent implements OnInit, OnChanges {

  @Input() editionList: any = [];
  @Input() articleCode: string;
  @Input() selectedEditionList = [];
  @Output() valueChange = new EventEmitter();
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<any>;

  public editionFields: object = {value: 'edition_code', text: 'edition_name'};


  articleEditionForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private contactService: ContactService) { }

  ngOnInit(): void {
    this.generateArticleEditionForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.valueChange.emit("success");
        this.closeSidebar();
      }
    });

  }

  generateArticleEditionForm() {
    this.articleEditionForm = this.formBuilder.group({
      edition_code: new FormControl('')
    });
  }

  onAddArticleEditionLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleEdition({article_code: this.articleCode, edition_code: form.value.edition_code}));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.articleEditionForm.reset();
    this.sidebarInstance.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedEditionList) {
      this.searchAndSetCompany(this.selectedEditionList);

    }
  }

  searchAndSetCompany(editions) {
    let selectedEditionList = [];

    if (editions && editions.length > 0) {
      for(const edition of editions) {
        let selectedEditionName = edition.edition_name;
        if (selectedEditionName) {
          let isEditionExistsInList = this.editionList.filter(el => el.edition_name === selectedEditionName).length > 0;
          if (!isEditionExistsInList) {
            let editionList = [];
            editionList.push(edition);
            this.editionList.forEach(el => editionList.push(el));
            // this.dropdownObj.dataSource = editionList;
            this.editionList = [...this.editionList, ...editionList];

          }

          selectedEditionList.push(edition.edition_code);
        }

      }
    }

    if (this.articleEditionForm) {
      this.articleEditionForm.controls.edition_code.setValue(selectedEditionList);
    }

  }
  clearAll(){
    this.clearModal.hide()
  this.articleEditionForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
