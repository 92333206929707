import { Action } from '@ngrx/store';
import { projectSocialType } from '../../models/admin/projectSocialType.model'; 
export enum projectSocialTypeActionTypes {
LOAD_PROJECT_SOCIAL_TYPE = '[PROJECT SOCIAL TYPE] Load Project Social Type Action',
LOAD_PROJECT_SOCIAL_TYPE_SUCCESS = '[PROJECT SOCIAL TYPE] Load Project Social Type Success Action',
LOAD_PROJECT_SOCIAL_TYPE_FAILURE = '[PROJECT SOCIAL TYPE] Load Project Social Type Failure Action',

  ADD_PROJECT_SOCIAL_TYPE = '[PROJECT SOCIAL TYPE] Add Project Social Type Action',
  ADD_PROJECT_SOCIAL_TYPE_SUCCESS = '[PROJECT SOCIAL TYPE] Add Project Social Type Success Action',
  ADD_PROJECT_SOCIAL_TYPE_FAILURE = '[PROJECT SOCIAL TYPE] Add Project Social Type Failure Action',

  UPDATE_PROJECT_SOCIAL_TYPE = '[PROJECT SOCIAL TYPE] Update Project Social Type Action',
  UPDATE_PROJECT_SOCIAL_TYPE_SUCCESS = '[PROJECT SOCIAL TYPE] Update Project Social Type Success Action',
  UPDATE_PROJECT_SOCIAL_TYPE_FAILURE = '[PROJECT SOCIAL TYPE] Update Project Social Type Failure Action',}


  export class LoadProjectSocialType implements Action {
   
    readonly type = projectSocialTypeActionTypes.LOAD_PROJECT_SOCIAL_TYPE;
  
  }
  
  export class LoadProjectSocialTypeSuccess implements Action {
    readonly type = projectSocialTypeActionTypes.LOAD_PROJECT_SOCIAL_TYPE_SUCCESS;
  
    constructor(public payload: { result: Array<projectSocialType> }) {
    }
  }
  
  export class LoadProjectSocialTypeFailure implements Action {
    readonly type = projectSocialTypeActionTypes.LOAD_PROJECT_SOCIAL_TYPE_FAILURE;
  
    constructor(public payload: Error) {
    }
  }
  
  export class AddProjectSocialType implements Action {
    readonly type = projectSocialTypeActionTypes.ADD_PROJECT_SOCIAL_TYPE;
  
    constructor(public payload: { social_date_name: string, filter_color: string, short_code: string, filter_text_color: string, rank_order: number, description?: string}) {
    }
  }
  
  export class AddProjectSocialTypeSuccess implements Action {
    readonly type = projectSocialTypeActionTypes.ADD_PROJECT_SOCIAL_TYPE_SUCCESS;
  
    constructor(public payload: { projectSocialsTypes: projectSocialType}) {
    }
  }
  
  export class AddProjectSocialTypeFailure implements Action {
    readonly type = projectSocialTypeActionTypes.ADD_PROJECT_SOCIAL_TYPE_FAILURE;
  
    constructor(public payload: Error) {
    }
  }
  
  export class UpdateProjectSocialType implements Action {
    readonly type = projectSocialTypeActionTypes.UPDATE_PROJECT_SOCIAL_TYPE;
                               // project_social_date_code: string,
    constructor(public payload: {
      social_date_code: string,
      social_date_name: string,
      filter_color: string, short_code: string, filter_text_color: string, rank_order: number, description:string
    }) {
    }
  }
  
  export class UpdateProjectSocialTypeSuccess implements Action {
    readonly type = projectSocialTypeActionTypes.UPDATE_PROJECT_SOCIAL_TYPE_SUCCESS;
    // , project_social_type_name: string 
    constructor(public payload: { projectSocialType: projectSocialType}) {
    }
  }
  
  export class UpdateProjectSocialTypeFailure implements Action {
    readonly type = projectSocialTypeActionTypes.UPDATE_PROJECT_SOCIAL_TYPE_FAILURE;
  
    constructor(public payload: Error) {
    }
  }

  export type  projectSocialsTypeActions=
  LoadProjectSocialType
  | LoadProjectSocialTypeSuccess
  | LoadProjectSocialTypeFailure
  |
  AddProjectSocialType
  | AddProjectSocialTypeSuccess
  | AddProjectSocialTypeFailure
  |
  UpdateProjectSocialType
  |UpdateProjectSocialTypeSuccess
  |UpdateProjectSocialTypeFailure;