<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">

    <h2>Article - Links</h2>

    <form id="formId" [formGroup]="articleLinkForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">
      

      <div class="form-group sf-outline-input mb-10">
        <div class="d-flex mb-4">
          <h4>Links</h4>
          <div class="float-right mr-0 ml-auto">
            <button (click)="addSMLink()"
                    class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3 ml-3 add-btn"
            >+
            </button>
            <button (click)="removeSMLink()"
                    class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3 ml-3 add-btn"
             id="removeButton">-
            </button>
          </div>


        </div>

        <div formArrayName="links">
          <div *ngFor="let socialMediaC of links.controls; let i=index" >
            <div [formGroupName]="i" class="d-flex mt-5">
              <ejs-textbox class="ml-1 w-100" id="socialMedia" placeholder="Link" cssClass="e-filled"
                           formControlName="link" floatLabelType="Auto" [multiline]="true" rows="1"
              ></ejs-textbox>

            </div>
            <div *ngIf="links.controls[i].get('link').errors" >
              <div
                *ngIf="links.controls[i].get('link').errors.invalidurl && links.controls[i].get('link').touched"
                class="e-error">
                Please enter a valid link
              </div>
            </div>



          </div>

        </div>


      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button"
                (click)="clear();"
                data-ripple="true">Clear
        </button>

        <button
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUpdateArticleLinks(articleLinkForm)" [disabled]="formSent" data-ripple="true">Update Links
        </button>
      </div>
    </form>

  </div>

</ejs-sidebar>
<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
