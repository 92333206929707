import { CommunityActions, CommunityActionTypes } from '../actions/community.actions';
import { formatDataSet } from '../../../views/common/base.component';
// Models
import { Community } from '../../models/community.model';
import { communityHistory } from '../../models/communityHistory.model';
import StartEndDate from 'src/assets/plugins/formvalidation/src/js/plugins/StartEndDate';
import { State } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';


export interface CommunityState {
  community: {
    result: Community[],
    total_count?: number,
    page_no?: number,
    page_size?: number,
  
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: {
    isLoaded?: boolean,
  };
  db?: {
    result: Community[],
    total_count?: number,
    page_no?: number,
    page_size?: number,
    
  };
  details?: Community;
  communityHistory?: communityHistory;

}

export const initialState: CommunityState = {
  community: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0,
  
  },
  loading: false,
  loaded: false,
  success: {
    isLoaded: false,

  },
};

export const ReturnLoading = (State: CommunityState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: {
      isLoaded: false,
    },
  };
};
export function communityReducer(state: CommunityState = initialState, action: CommunityActions): CommunityState {
  switch (action.type) {

    case CommunityActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case CommunityActionTypes.FILTER_SUCCESS: {
      // const result: any = formatDataSet(action.payload.columns, {...action.payload});
      const result: any = { ...action.payload }
     

      state = {
        ...state, community: {
          result: result.data, page_no: result.meta.page_number,
          page_size: result.meta.page_size, total_count: result.meta.page_total_count,
        }, loading: false, error: undefined, loaded: true, db:
        {
          result: result.data,
          total_count: result.page_total_count,
          page_no: result.meta.page_number,
          page_size: result.meta.page_size,
        }
      };

      return state;
    }

    case CommunityActionTypes.FILTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }
    case CommunityActionTypes.GET_SINGLE_COMPANY: {
      
      return {
        ...state,
        details: null,
        loading: true,
        loaded: false,
        error: undefined,
        success: {
          isLoaded: false,
          
        },
      };
    }

    case CommunityActionTypes.GET_SINGLE_COMPANY_SUCCESS: {
   
    
      return {
        ...state, details: action.payload.communitySingle, error: undefined, loading: false,
        loaded: true,
      };
    }

    case CommunityActionTypes.GET_SINGLE_COMPANY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }


    case CommunityActionTypes.GET_COMMUNITY_HISTORY: {
      return {
        ...state,
        communityHistory: null,
        loading: true,
        loaded: false,
        error: undefined,
        success: {
          isLoaded: false,
        }
      };
    }

    case CommunityActionTypes.GET_COMMUNITY_HISTORY_SUCCESS: {

      var history = { ...state.details }
      history.communityHistory = { ...action.payload.communityHistory }

      return {
        ...state, details: history, error: undefined, loading: false,
        success: { isLoaded: true }, loaded: true
      };
    }

    case CommunityActionTypes.GET_COMMUNITY_HISTORY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommunityActionTypes.UPDATE_STATUS: {
      return ReturnLoading(state);
    }

    case CommunityActionTypes.UPDATE_STATUS_SUCCESS: {
      const updatedContact = {

        ...action.payload
      };

      var code = updatedContact.company_approval_code
      var company = { ...state.details }
      var companies = cloneDeep(company)



      if (companies.approval_code == code) {
        companies.request_status.canvas_status = updatedContact.request_status
        companies.verified_by= updatedContact.verified_by
      }

      return {
        ...state, details: companies, error: undefined, loading: false, loaded: true,
        success: { isLoaded: true }
      };
    }

    case CommunityActionTypes.UPDATE_STATUS_FAILURE:{
      return { ...state, error: action.payload, loading: false, loaded: false };
    }
  

  //   case CommunityActionTypes.FILTER: {
  //     return ReturnLoading(state);
  //   }

  //   case CommunityActionTypes.FILTER_SUCCESS: {
      
  //       let result;
  //       if (action.payload.data.length === 0) {
  //         result = {data: []};
  //       } else {
  //         result = {...action.payload};
  //       }
  // console.log("result",result)
  // console.log("result data",result.meta.total_count)
  //       return {
  //         ...state, community: {
  //           result: result.data, total_count: result.meta.total_count,
  //           page_no: result.meta.page_number, page_size: result.page_size
  //         }
  //         , loading: false, error: undefined, loaded: true, success: {
  //           isLoaded: true, 
  //         }
  //       };
  //     }
  
  //     case CommunityActionTypes.FILTER_FAILURE: {
  //       return {...state, loading: false, error: action.payload, loaded: false};
  //     }
    }
   

 
}