import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Observable} from 'rxjs';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {AppState} from 'src/app/core';
import {AddArticleBrand, AddArticleCompany, UpdateArticleBrand} from 'src/app/core/store/actions/article.actions';
import {Query} from '@syncfusion/ej2-data';
import {BrandService} from 'src/app/core/store/services/brand.service';
import * as _ from 'lodash';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';


@Component({
  selector: 'app-article-brand-form',
  templateUrl: './article-brand.component.html',
  styleUrls: ['./article-brand.component.scss']
})
export class ArticleBrandComponent implements OnInit {

  @Input() brandList: any = [];
  @Input() articleCode: string;
  @Input() selectedBrandList = [];
  @Output() valueChange = new EventEmitter();
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  public brandFields: object = {value: 'brand_code', text: 'brand_name'};


  articleBrandForm: FormGroup;
  success$: Observable<any>;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private brandService: BrandService) {
  }

  ngOnInit(): void {
    this.generateArticleBrandForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.valueChange.emit('success');
        this.closeSidebar();
      }
    });

  }

  generateArticleBrandForm() {
    this.articleBrandForm = this.formBuilder.group({
      brand_code: new FormControl('')
    });
  }

  onAddArticleBrandLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleBrand({article_code: this.articleCode, brand_code: form.value.brand_code}));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
    this.articleBrandForm.reset();
  }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    const brandList = [];

    query = (e.text !== '') ? query.where('brand_name', 'contains', e.text, true) : query;
    const filterParams = {search: e.text, page_size: 40, page_no: 1};

    this.brandService.getSearchResults(filterParams).subscribe((data) => {
      if (data && data.data) {
        if (data.data.result) {
          for (const brand of data.data.result) {
            const checkPub = brand.publish_status !== 'publish' ? ' [NP]' : ' [P]';
            brandList.push({...brand, brand_name: brand.brand_name + checkPub});
          }
          // this.brandList = _.clone(brandList);
          e.updateData(_.clone(brandList), query);
        }
      }
    });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedBrandList) {
      this.searchAndSetCompany(this.selectedBrandList);

    }
  }


  searchAndSetCompany(brands) {
    let selectedBrandList = [];
    let brandList = [];

    if (brands && brands.length > 0) {
      for (const brand of brands) {
        let selectedBrandName = brand.brand_name;
        if (selectedBrandName) {
          let isBrandExistsInList = this.brandList.filter(el => el.brand_name === selectedBrandName).length > 0;
          if (!isBrandExistsInList) {
            const checkPub = brand.publish_status !== 'publish' ? ' [NP]' : ' [P]';

            brandList.push({...brand, brand_name: brand.brand_name + checkPub});


          }

          selectedBrandList.push(brand.brand_code);
        }

      }

      this.brandList.forEach(el => brandList.push(el));
      // this.dropdownObj.dataSource = brandList;
      this.brandList = [...this.brandList, ...brandList];
    }

    if (this.articleBrandForm) {
      this.articleBrandForm.controls.brand_code.setValue(selectedBrandList);
    }

  }
  clearAll(){
    this.clearModal.hide()
    this.articleBrandForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
