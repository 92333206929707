// Angular
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
} from "../../../core/_base/layout";
// Auth
// import { AuthNoticeService } from '../../../core/auth';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  headerLogo: string;

  /**
   * Component constructor
   *
   * @param el
   * @param render
   * @param layoutConfigService: LayoutConfigService
   * @param translationService: TranslationService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.headerLogo = this.layoutConfigService.getLogo();

    this.splashScreenService.hide();

    this.renderLoginImage();
  }
  getRandomIntInclusive = (min: number = 1, max: number) => {
    min = Math.ceil(min); // Ensure minimum is a whole number
    max = Math.floor(max); // Ensure maximum is a whole number
    return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
  };

  renderLoginImage = () => {
    const imageSelector = <HTMLElement>(
      document.querySelector(".action-page-image-bg")
    );
    const imageSelectorMobile = <HTMLElement>(
      document.querySelector(".action-page-image-mobile")
    );
    const rid = this.getRandomIntInclusive(1, environment.splashImageMaxLimit);
    const levelZeroSrc = "/assets/media/login.jpg";
    const levelOneSrc = `/assets/images/login/${window.location.hostname.split(".")[0]}.jpg`;
    const levelTwoSrc = `/assets/images/splash/${rid}.jpg`;
    if (imageSelector !== undefined) {
      imageSelector.style.background = `url(${levelTwoSrc}), url(${levelOneSrc}), url(${levelZeroSrc})`;
    }
    if (imageSelectorMobile !== undefined) {
      imageSelectorMobile.style.background = `url(${levelTwoSrc}), url(${levelOneSrc}), url(${levelZeroSrc})`;
    }
  };

  /**
   * Load CSS for this specific page only, and destroy when navigate away
   * @param styleUrl
   */
  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement("link");
      styleElement.href = styleUrl;
      styleElement.type = "text/css";
      styleElement.rel = "stylesheet";
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }
}
