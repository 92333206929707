import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core';
import { AddArticleProject, UpdateArticleProject } from 'src/app/core/store/actions/article.actions';
import { CompanyService } from 'src/app/core/store/services/company.service';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import * as _ from 'lodash';
import {formatDataSet} from '../../../../common/base.component';
import {ProjectService} from '../../../../../core/store/services/project.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-article-project-form',
  templateUrl: './article-project.component.html',
  styleUrls: ['./article-project.component.scss']
})
export class ArticleProjectComponent implements OnInit, OnChanges {

  @Input() projectList: any = [];
  @Input() articleCode: string;
  @Input() selectedProjectList = [];
  @Output() valueChange = new EventEmitter();
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;


  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  public projectFields: object = {value: 'project_code', text: 'project_name'};

  limitedProjectList: any = [];

  articleProjectForm: FormGroup;
  success$: Observable<any>;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private projectService: ProjectService) { }


  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  ngOnInit(): void {
    this.generateArticleProjectForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.valueChange.emit("success");
        this.closeSidebar();
      }
    });

  }

  generateArticleProjectForm() {
    this.articleProjectForm = this.formBuilder.group({
      project_code: new FormControl('')
    });
  }

  onAddArticleProjectLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleProject({article_code: this.articleCode, project_code: form.value.project_code}));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.articleProjectForm.reset();
    this.sidebarInstance.hide();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.projectList) {
      this.limitedProjectList = this.projectList.slice(0,100);
    }

    if (changes.selectedProjectList) {
      if (this.selectedProjectList && this.selectedProjectList.length > 0) {
        const projectCodeList = this.selectedProjectList.map(function (el) { return el.project_code; });
        this.articleProjectForm.controls.project_code.setValue(projectCodeList);
      }

    }
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    const projectList = [];

    // if ((this.projectCompanyRoleForm && this.projectCompanyRoleForm.controls.company_code.value !== null &&
    //   this.projectCompanyRoleForm.controls.company_code.value !== '') || this.projectSourceForm) {
    query = (e.text !== '') ? query.where('project_name', 'contains', e.text, true) : query;
    const filterParams = {search: e.text, page_size: 40, page_no: 1};
    this.projectService.getSearchResults(filterParams).subscribe((data) => {
      if (data.data) {
        const result = formatDataSet(data.data.columns, {...data.data});
        for (const project of result.data) {
          const checkPub = project.status_code !== '21-published' ? ' [NP]' : ' [P]';
          projectList.push({...project, project_name: project.project_name + checkPub});
        }

        e.updateData(projectList, query);
      }
    });

    // }

  }
  clearAll(){
    this.clearModal.hide()
    this.articleProjectForm.reset();
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
