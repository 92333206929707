import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactService } from '../../../../../core/store/services/contact.service';
import { CompanyService } from '../../../../../core/store/services/company.service';
import { debounce, EmitType } from '@syncfusion/ej2-base';
import { LoadEmailLog, MetaDataResetError } from '../../../../../core/store/actions/common.actions';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import * as moment from 'moment';
import { ContactLoad } from 'src/app/core/store/actions/contact.actions';
import { ArticleService } from '../../../../../core/store/services/article.service';

@Component({
  selector: 'app-sync-job-filter',
  templateUrl: './sync-job-filter.component.html',
  styleUrls: ['./sync-job-filter.component.scss']
})
export class SyncJobFilterComponent implements OnInit {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @Input() companyList: any;
  @Input() contactList: any;
  @Input() articleList: any;
  @Input() blockTypes: any;

  filterForm: FormGroup;
  public width = 'auto';
  public position = 'Right';
  formSent: boolean;
  isFilterReset = false;

  public treeSettings: object = { autoCheck: true };
  status = [{ status: 'Pending' }, { status: 'Success' }, { status: 'Failed' }];

  contactFields = { text: 'name', value: 'value' };
  companyFields = { text: 'company_name', value: 'company_code' };
  articleFields = { text: 'article_name', value: 'article_code' };

  public statusFields: object = { dataSource: this.status, value: 'status', text: 'status' };
  public blockTypeFields: object = { dataSource: this.blockTypes, value: 'block_type_name', text: 'block_type_name' };

  error$: Observable<any>;
  success$: Observable<boolean>;

  allowEdit = false;
  queryParams={}
  value:any
  start=null
  end=null

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router,
    private contactService: ContactService, private companyService: CompanyService, private articleService: ArticleService, private route: ActivatedRoute) { }


  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  ngOnInit(): void {
    this.generateFilterForm();
    this.loadContactData();

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.error$ = this.store.pipe(select(store => store.metadata.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        this.store.dispatch(new MetaDataResetError());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  openSidebar(): void {
    
    this.sidebarInstance.show();
    this.route.queryParams.subscribe(res => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
        this.populateSidebar(this.queryParams);
      }
      else {
        this.clearForm()
      }

    });
  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      dispatched_at: new FormControl(null),
      company_code: new FormControl(null),
      contact_code: new FormControl(null),
      status: new FormControl(''),
      block_type_name: new FormControl(null),
      article_code: new FormControl(null)
    });
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], { queryParams: {} });
    this.filterForm.reset();
    this.store.dispatch(new LoadEmailLog({ page_size: 100, page_no: 1 }));
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
      const filterParams = { company_name: e.text, page_size: 30, page_no: 1 };
      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data && data.data) {
          e.updateData(data.data.result, query);
          sessionStorage.removeItem("searchedCompanyList");
          sessionStorage.setItem("searchedCompanyList", JSON.stringify(data.data.result));
        }
      });
    } else if (entity === 'contact') {
      query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
      const contactfilterParams = { name: e.text, page_size: 30, page_no: 1 };
      this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {
              let company = '';

              company += ' (' + contact.reference_id + ')';
              const result = {
                value: contact.contact_code,
                name: (contact.first_name + ' ' + contact.last_name + company)
              };
              companyContactList.push(result);
            }
            sessionStorage.removeItem("searchedContactList");
            sessionStorage.setItem("searchedContactList", JSON.stringify(companyContactList));
            // this.contactList = companyContactList;
            e.updateData(companyContactList, query);
          }
        }
      });
    } else if (entity === 'article') {

      query = (e.text !== '') ? query.where('article_name', 'contains', e.text, true) : query;
      const filterParams = { search: e.text, page_size: 30, page_no: 1 };
      this.articleService.getSearchResults(filterParams).subscribe((data) => {
        if (data && data.data) {
          sessionStorage.removeItem("searchedarticleList");
          sessionStorage.setItem("searchedarticleList", JSON.stringify(data.data.result));
          e.updateData(data.data.result, query);

        }
      });
    }
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = { ...this.filterForm.value };
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);

    if (filterValues.dispatched_at) {
      filterValues.dispatched_at_end = moment(filterValues.dispatched_at[1]).format('YYYY-MM-DD');
      filterValues.dispatched_at_start = moment(filterValues.dispatched_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.dispatched_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        if (filterValues[filterValuesKey].length > 0) {
          filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
        } else {
          delete filterValues[filterValuesKey];
        }
      }
    }

    this.router.navigate([], { queryParams: filterValues });
  }

  loadContactData() {
    this.store.dispatch(new ContactLoad({ page_no: 1, page_size: 100, sort_by: 'contact_name' }));
    this.store.select(store => store.contacts.contacts).subscribe((data: any) => {
      if (data) {
        // this.projectSourceArray = [];
        for (const contact of data.result) {

          let company = '';
          if (contact.company) {
            company = ' - ' + contact.company.company_name;
          } else {
            company = '';
          }
          const result = {
            value: contact.contact_code,
            name: (contact.first_name + ' ' + contact.last_name + company)
          };
          this.contactList.push(result);
        }
      }
    });
  }
  populateSidebar(filters) {

    // if(filters==undefined&&!this.isFilterReset){
    //   console.log("reached")
    //   this.filterForm.controls.deliverable_status_code.setValue(val) 
    //   console.log(this.filterForm.value)
    // }
    this.start=null
  this.end=null
this.value=''
    for (const key in filters) {


      if (filters[key]) {
        
        if (key != 'dispatched_at_end'&&key!='dispatched_at_start') {
          this.filterForm.controls[key].setValue((filters[key].split(',')));

        }
        if(key=='dispatched_at_start'){
          this.start=filters[key]
          }
        if (key == 'dispatched_at_end'){
          this.end=filters[key]
        }
        if(this.start!=null&&this.end!=null){
          this.value=[new Date(this.start), new Date(this.end)] as any;
        }

      }
    }
  }

}
