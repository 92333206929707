// tslint:disable:variable-name
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../../core";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";
import { debounce, EmitType } from "@syncfusion/ej2-base";
import {
  FilteringEventArgs,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import * as _ from "lodash";
import { ContactService } from "../../../../../core/store/services/contact.service";
import { CheckBoxComponent } from "@syncfusion/ej2-angular-buttons";
import {
  EventsSetStatusNull,
  EventUpdateAttendee,
} from "../../../../../core/store/actions/events.actions";

@Component({
  selector: "app-events-attendees-form",
  templateUrl: "./events-attendees-form.component.html",
  styleUrls: ["./events-attendees-form.component.scss"],
})
export class EventsAttendeesFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("sidebarInstance") public sidebarInstance: SidebarComponent;
  @ViewChild("clearModal") public clearModal: DialogComponent;
  @ViewChild("checkbox") public checkbox: CheckBoxComponent;
  @ViewChild("contact") public contact: MultiSelectComponent;
  // Listeners
  @Input() contactList: any = [];
  @Input() selectedEventCode: string;
  @Input() selectedContactList: any;
  @Output() valueChange = new EventEmitter();
  // style props
  public width = "auto";
  public position = "Right";
  // Field Props
  public contactFields: object = { value: "value", text: "name" };
  eventAttendeeForm: FormGroup;
  // Form Props
  public formSent: boolean;
  error$: Observable<any>;
  success$: Observable<any>;
  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];
  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);
  isAddOrUpdate: "Add" | "Update" = "Add";
  timeoutIDs = [];

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private contactService: ContactService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedContactList || changes.contactList) {
      // if (changes.selectedContactList || changes.contactList) {
      //   if (changes.selectedContactList) {
      //     this.selectedContactList = changes.selectedContactList.currentValue;
      //   }
      //   if (changes.contactList) {
      //     this.contactList = changes.contactList.currentValue;
      //   }
      if (this.contact) {
        this.contact.refresh();
      }
      if (this.selectedContactList) {
        if (
          this.selectedContactList.selectedAttendees &&
          this.selectedContactList.selectedAttendees.length > 0
        ) {
          this.searchAndSetContact(this.selectedContactList.selectedAttendees);
          this.isAddOrUpdate = "Update";
        } else {
          this.isAddOrUpdate = "Add";
        }
        if (this.selectedContactList.comment) {
          this.eventAttendeeForm.controls.comment.setValue(
            this.selectedContactList.comment,
          );
        }
        if (this.selectedContactList.has_attended) {
          this.eventAttendeeForm.controls.has_attended.setValue(
            this.selectedContactList.has_attended,
          );
          this.checkbox.checked = this.selectedContactList.has_attended;
        }
        this.setCheckbox();
        console.log(
          "this.contactList",
          this.contactList,
          this.selectedContactList,
          this.checkbox,
        );
      }
    }
  }

  ngOnInit(): void {
    this.generateArticleContactForm();
    this.error$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.error) {
          return;
        }
        return store.events.error;
      }),
    );
    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
    this.success$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.success) {
          return;
        }
        return store.events.success.isForm;
      }),
    );
    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new EventsSetStatusNull());
      }
    });
  }

  generateArticleContactForm() {
    this.eventAttendeeForm = this.formBuilder.group({
      contact_code: new FormControl("", Validators.required),
      comment: new FormControl("", [Validators.maxLength(1000)]),
      has_attended: new FormControl(false),
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
    this.timeoutIDs.push(
      setTimeout(() => {
        this.cd.detectChanges();
        this.contact.refresh();
      }, 1000),
    );
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
    this.timeoutIDs.push(
      setTimeout(() => {
        if (this.eventAttendeeForm) {
          this.eventAttendeeForm.reset();
        }
      }, 1000),
    );
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    query =
      e.text !== "" ? query.where("name", "contains", e.text, true) : query;
    const filterParams = { name: e.text, page_size: 40, page_no: 1 };

    this.contactService
      .getSearchedContactLimited(filterParams)
      .subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {
              if (contact) {
                // for (const associatedCompany of contact.associated_companies) {
                let company_name = "";
                // tslint:disable-next-line:variable-name
                let company_code = "";
                // tslint:disable-next-line:variable-name
                const company_contact_code = _.clone(
                  contact.company_contact_code,
                );

                // if (associatedCompany.company) {
                //   company = " - " + associatedCompany.company.company_name;
                //   company_code = associatedCompany.company.company_code;
                // } else {
                //   company = " (" + associatedCompany.email + ")";
                //   company_code = "";
                // }

                if (contact.company_name) {
                  company_name = " - " + contact.company_name;
                }
                if (contact.is_primary_yn === 0) {
                  company_name += " - ( 🚫 Inactive )";
                }

                const result = {
                  value: contact.company_contact_code,
                  name:
                    contact.first_name + " " + contact.last_name + company_name,
                  company_code,
                  contact_code: contact.contact_code,
                };
                companyContactList.push(result);
                // }
              }
            }
            e.updateData(_.clone(companyContactList), query);
          }
        }
      });
    // this.contactService.getSearchedContact(filterParams).subscribe((data) => {
    //   if (data && data.data) {
    //     const companyContactList = [];
    //     if (data.data.result) {
    //       for (const contact of data.data.result) {
    //         if (contact.associated_companies) {
    //           for (const associatedCompany of contact.associated_companies) {
    //             let company = "";
    //             // tslint:disable-next-line:variable-name
    //             let company_code = "";
    //             // tslint:disable-next-line:variable-name
    //             const company_contact_code = _.clone(
    //               associatedCompany.company_contact_code,
    //             );
    //
    //             if (associatedCompany.company) {
    //               company = " - " + associatedCompany.company.company_name;
    //               company_code = associatedCompany.company.company_code;
    //             } else {
    //               company = " (" + associatedCompany.email + ")";
    //               company_code = "";
    //             }
    //
    //             if (associatedCompany.is_primary_yn === 0) {
    //               company += " - ( 🚫 Inactive )";
    //             }
    //
    //             const result = {
    //               value: company_contact_code,
    //               name: contact.first_name + " " + contact.last_name + company,
    //               company_code,
    //               contact_code: contact.contact_code,
    //             };
    //             companyContactList.push(result);
    //           }
    //         }
    //       }
    //       e.updateData(_.clone(companyContactList), query);
    //     }
    //   }
    // });
  };

  searchAndSetContact(contacts) {
    console.log("contacts", contacts);
    const selectedContactList = [];
    const contactList = [];
    if (
      this.contactList &&
      this.contactList.length > 0 &&
      contacts &&
      contacts.length > 0
    ) {
      for (const contact of contacts) {
        const selectedContactCode = contact.company_contact_code;
        if (selectedContactCode) {
          const isContactExistsInList =
            this.contactList.filter((el) => el.value === selectedContactCode)
              .length > 0;
          if (!isContactExistsInList) {
            console.log("------->", contact);
            contactList.push({
              company_code:
                contact.company && contact.company.company_code
                  ? contact.company.company_code
                  : "",
              contact_code:
                contact.contact && contact.contact.contact_code
                  ? contact.contact.contact_code
                  : "",
              name:
                (contact.contact && contact.contact.name
                  ? contact.contact.name
                  : "") +
                " - " +
                (contact.company && contact.company.company_name
                  ? contact.company.company_name
                  : ""),
              value: contact.company_contact_code,
            });
          }
          // console.log(
          //   "isContactExistsInList",
          //   isContactExistsInList,
          //   contact,
          //   this.contactList,
          //   contactList,
          // );
          selectedContactList.push({
            value: contact.company_contact_code,
            name:
              contact.contact.first_name +
              " " +
              contact.contact.last_name +
              " - " +
              (contact.company && contact.company.company_name
                ? contact.company.company_name
                : ""),
            company_code:
              contact.company && contact.company.company_code
                ? contact.company.company_code
                : "",
            contact_code: contact.contact.contact_code,
          });
        }
      }
      this.contactList.forEach((el) => contactList.push(el));
      this.contactList = [...this.contactList, ...contactList];
      this.contactList = _.uniqBy(this.contactList, "value");
      console.log(
        "selectedContactList",
        contactList,
        selectedContactList,
        this.contactList,
      );
      this.contact.refresh();
    }
    if (
      this.eventAttendeeForm &&
      selectedContactList &&
      selectedContactList.length > 0
    ) {
      const data = selectedContactList.map((el) => el.value).filter((el) => el);
      console.log("data", data);
      this.eventAttendeeForm.controls.contact_code.setValue(data);
      this.setCheckbox();
    }
  }

  clearAll() {
    this.formSent = false;
    this.clearModal.hide();
    this.timeoutIDs.push(
      setTimeout(() => {
        if (this.eventAttendeeForm) {
          this.eventAttendeeForm.reset();
          this.eventAttendeeForm.setErrors({ invalid: true });
        }
      }, 1000),
    );
  }

  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }
  // Submit form
  updateEventAttendees(form: FormGroup) {
    if (!form.invalid) {
      const postData = {
        event_code: this.selectedEventCode,
        company_contact_code: [],
      };
      try {
        if (form.value.contact_code && form.value.contact_code.length > 0) {
          postData.company_contact_code = form.value.contact_code.map((el) => {
            return {
              code: el,
              comment: form.value.comment,
              has_attended_yn: form.value.has_attended ? 1 : 0,
            };
          });
        }
      } catch {}
      console.log(postData);
      this.store.dispatch(new EventUpdateAttendee(postData));
      this.formSent = true;
    }
  }
  setCheckbox() {
    this.timeoutIDs.push(
      setTimeout(() => {
        this.eventAttendeeForm.controls.has_attended.setValue(
          this.selectedContactList.has_attended,
        );
        this.checkbox.checked = this.selectedContactList.has_attended;
        this.contact.refresh();
        console.log(this.checkbox, this.selectedContactList.has_attended);
        this.cd.detectChanges();
      }, 1000),
    );
  }
  ngOnDestroy() {
    if (this.timeoutIDs && this.timeoutIDs.length > 0) {
      this.timeoutIDs.forEach((el) => clearTimeout(el));
    }
  }
}
