<!--begin::Subheader-->
<div class="subheader subheader-solid py-2 py-lg-4" [ngClass]="subheaderClasses" id="kt_subheader">
	<div [ngClass]="subheaderContainerClasses" class=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline mr-5">
        <ng-container *ngIf="title">
          <!--begin::Page Title-->
          <div *ngIf="title !='Deliverables'">
            <h5 class="text-dark font-weight-bold my-2 mr-5" (click)="navigateToPage(page)" style="cursor: pointer">{{title}}</h5>
          </div>
          <div *ngIf="title =='Deliverables'">
            <h5 class="text-dark font-weight-bold my-2 mr-5" style="cursor: pointer">{{title}}</h5>
          </div>
        </ng-container>
        <!--end::Page Title-->

        <!--begin::Breadcrumb-->
        <ng-container *ngIf="breadcrumbs.length > 0">
          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
            <ng-container *ngFor="let item of breadcrumbs">
              <li class="breadcrumb-item">
                <a [routerLink]="item.page" [queryParams]="item.queryParams" class="text-muted">{{item.title}}</a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

    <div class="d-flex align-items-center">
       <!--      <ng-container *ngIf="displayDaterangepicker">-->
     <!--        &lt;!&ndash;begin::Daterange&ndash;&gt;-->
        <!--        <a class="btn btn-light btn-sm font-weight-bold mr-3" id="kt_dashboard_daterangepicker" data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">-->
             <!--          <span class="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>-->
          <!--          <span class="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">{{todayDate | date}}</span>-->
           <!--        </a>-->
            <!--        &lt;!&ndash;end::Daterange&ndash;&gt;-->
          <!--      </ng-container>-->

      <!--begin::Dropdown-->
      <div *ngIf="isCompanyContactProjectLink() && (checkDeletePermission() || checkEditPermission())"
        class="dropdown dropdown-inline" data-toggle="tooltip"
        title="Quick actions" placement="bottom-right" ngbDropdown
      >
        <a class="btn btn-icon cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <span id="actions">Actions</span>
        </a>
        <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0" ngbDropdownMenu>
          <app-action-dropdown-menu></app-action-dropdown-menu>
        </div>
      </div>

      <div *ngIf="isEmailTemaplate()" class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" placement="bottom-right" ngbDropdown>
        <a class="btn btn-icon cursor-pointer" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <span id="actions">Actions</span>
        </a>
        <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0" ngbDropdownMenu>
          <app-action-dropdown-menu></app-action-dropdown-menu>
        </div>
      </div>
    </div>
	</div>
</div>
<!--end::Subheader-->
