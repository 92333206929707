<ejs-sidebar [width]='width' [position]='position' id="changePassword" [enableGestures]="false" [showBackdrop]=true
  class="z-index">

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
      (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class='p-15'>

    <h2 *ngIf="isChangePassword==true">Change Password</h2>
    <h2 *ngIf="isChangeUsername==true">Edit Profile</h2>
    <h2 *ngIf="isLinkEmailAccount && isLinkEmailAccountVisible">Linked Email Account</h2>
<div *ngIf="isLinkEmailAccount && isLinkEmailAccountVisible">
  <a href="https://support.google.com/accounts/answer/185833?hl=en"
  target="_blank" style="text-decoration: underline; margin-top: 20px;color: blue;">How to create your own App Password</a></div>
    <div *ngIf="isChangePassword==true">
      <form id="form-project" [formGroup]="changePasswordForm" novalidate="" #form>
        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="current_password" placeholder="Current Password *" cssClass="e-filled"
            formControlName="current_password" floatLabelType="Auto" type="password"></ejs-textbox>
          <div *ngIf="changePasswordForm.controls.current_password.errors">
            <div class="e-error"
              *ngIf="changePasswordForm.controls.current_password.errors.required && changePasswordForm.controls.current_password.touched">
              Current Password is required.
            </div>
          </div>
        </div>
        <div formGroupName="new_passwords">

          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="new_password" placeholder="New Password *" cssClass="e-filled"
              formControlName="new_password" floatLabelType="Auto" type="password"></ejs-textbox>
            <div *ngIf="changePasswordForm.get('new_passwords.new_password').errors">
              <div class="e-error"
                *ngIf="changePasswordForm.get('new_passwords.new_password').errors.required && changePasswordForm.get('new_passwords.new_password').touched">
                New Password is required.
              </div>
              <div *ngIf="changePasswordForm.get('new_passwords.new_password').errors.minlength" class="e-error">
                Password cannot be less than 8 characters
              </div>
            </div>
          </div>
          <div class="form-group sf-outline-input mb-10">
            <ejs-textbox id="confirm_new_password" placeholder="Confirm new Password *" cssClass="e-filled"
              formControlName="confirm_new_password" floatLabelType="Auto" type="password"
              (blur)="changePasswordForm.get('new_passwords.confirm_new_password').markAsTouched()"></ejs-textbox>
            <div *ngIf="changePasswordForm.get('new_passwords').errors">
              <div *ngIf=" changePasswordForm.controls.new_passwords.errors" class="e-error">Passwords do not match
              </div>
            </div>
          </div>
        </div>
        <div class="e-error" *ngIf="error$ | async as error">{{error.error}}</div>
        <div *ngIf="isfilled==false" style="color: red;"> Please fill all fields</div>
        <div class="my-10 pb-20">
          <button id="resetbtn"
            class="btn btn-outline-secondary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block mb-5"
            type="button" (click)="clear()" data-ripple="true">Clear
          </button>
          <button id="changePasswordButton"
            class="btn btn-primary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block" type="submit"
            (click)="changePassword()" [disabled]="formSent" data-ripple="true">Change Password
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="isChangeUsername==true">
      <form id="form-project" [formGroup]="changeUserNameForm" novalidate="">
        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="firstname" placeholder="First Name *" cssClass="e-filled" formControlName="firstname"
            floatLabelType="Auto" type="text"></ejs-textbox>
          <div *ngIf="isSubmitted&&changeUserNameForm.controls.firstname.errors">
            <div *ngIf="changeUserNameForm.controls.firstname.errors.required" class="e-error">First name is required.
            </div>
            <div *ngIf="changeUserNameForm.controls.firstname.errors.maxlength" class="e-error">First name cannot be
              more than 50 characters</div>
            <div *ngIf="changeUserNameForm.controls.firstname.errors.pattern" class="e-error">numbers are not allowed
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="lastname" placeholder="Last Name " cssClass="e-filled" formControlName="lastname"
            floatLabelType="Auto" type="text"> </ejs-textbox>
          <div *ngIf="isSubmitted&&changeUserNameForm.controls.lastname.errors">
            <div *ngIf="changeUserNameForm.controls.lastname.errors.required" class="e-error">New username is required.
            </div>
            <div *ngIf="changeUserNameForm.controls.lastname.errors.maxlength" class="e-error">Username cannot be more
              than 50 characters</div>
            <div *ngIf="changeUserNameForm.controls.lastname.errors.pattern" class="e-error">numbers are not allowed
            </div>
          </div>
        </div>

        <div *ngIf="HasNotAllowedCharecters" class="e-error">Special Characters and Numbers are not allowed in name
          fields</div>
        <div *ngIf="success$| async as success">{{success}}</div>
        <div class="e-error" *ngIf="error$ | async as error">{{error.error}}</div>

        <div class="my-10 pb-20">
          <button id="resetbtn"
            class="btn btn-outline-secondary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block mb-5"
            type="reset" (click)="clearForm()">Cancel</button>
          <button id="changeProfileButton"
            class="btn btn-primary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block" type="submit"
            (click)="changeUsername()">Update Changes</button>
        </div>
      </form>
    </div>

    <div *ngIf="isLinkEmailAccount && isLinkEmailAccountVisible">
      <div id="link-email-account" >
        <div class="form-group sf-outline-input mb-10" style="margin-top: 45px;">
          <ejs-textbox id="userEmail" placeholder="Email*" cssClass="e-filled" floatLabelType="Auto" type="text"
            disabled="true" [ngModel]="userEmail"></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox #linkedPassword id="linkedPassword" placeholder="App Password (Not your Gmail password)*" cssClass="e-filled" floatLabelType="Auto"
            type="password" required="true" [(ngModel)]="linkEmailPassword" (focus)="linkEmailPasswordPlaceholder = ''"
            (blur)="linkEmailPasswordPlaceholder = !linkEmailPassword ? '****' : ''"
            (input)="valueChange()"> </ejs-textbox>
        </div>
        
        <div *ngIf="HasNotAllowedCharecters" class="e-error">Special Characters and Numbers are not allowed in name
          fields</div>
        <div *ngIf="success$| async as success">{{success}}</div>
        <div class="e-error" *ngIf="error$ | async as error">{{error.error}}</div>
        <div class="e-error" id="Emailerror">{{errorMasForUpdate}}</div>
        <div class="e-error" id="mailerror"></div>
        <!-- <div *ngIf="!linkEmailPassword&&isApppasswordBeforeSet==true" class="e-error">Saving null value will unlink current App Paasword.</div> -->
        <div *ngIf="isAppPasswordSet==true && isVerified==true &&isLinkedEmailError==false" class="text-success">App Password has been Verified.</div>
        <div *ngIf="isAppPasswordSet==true && isVerified==false && isLinkedEmailError==false" class="e-error">Your App Password is not verified, Please verify your App Password.</div>
        <div *ngIf="isAppPasswordSet==false && isVerified==false && isUpdateButtonClicked==false&&isLinkedEmailError==false&&isApppasswordBeforeSet==false" style="color: blue;"><span style="position: absolute;"><mat-icon>info</mat-icon></span><span style="padding-left: 22px;">App password is not set. Please set and Verify your App password.</span></div>
        <p id="spinnerdiv" *ngIf="isUpdateButtonClicked==true" style="text-align: center; color: #e3165b; margin-bottom: 20px;">Please wait, Verifying your App Password</p>
        <div id="container" style="position: absolute;

        left: 50%;" class="spinnerClass">
       </div>
       <div *ngIf="isUpdateButtonClicked==true" style="margin-bottom: 20px;"></div>
        <div class="my-10 pb-20">
          <button id="testEmailBtn"
            class="btn btn-outline-secondary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block"
            type="button" (click)="sendTestEmail()"[hidden]="(linkEmailPassword&&linkEmailPassword!='********')|| linkEmailPassword==''||isLinkedEmailError==true">Send Test Email</button>
            <!--  -->
          <button id="updateLinkedPassword" [disabled]="(!linkEmailPassword&&isApppasswordBeforeSet==false)||linkEmailPassword=='********'"
            class="btn btn-primary text-uppercase font-weight-bolder px-15 py-5 float-right btn-block disable" type="button"
            (click)="updateLinkedEmail()"  >Verify & Update  App Password</button>
        </div>
      </div>
    </div>


  </div>

</ejs-sidebar>
<div id="showMessage">{{message}}</div>
<ejs-dialog #deleteModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will unlink current App Password.</p>
  </ng-template>
</ejs-dialog>

<ejs-dialog #clearModal [buttons]='clrButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>