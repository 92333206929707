import {Action} from '@ngrx/store';
import {User} from '../../models/user.model';

export enum AuthActionTypes {
  LOGIN = '[Login] Action',
  LOGOUT = '[Logout] Action',
  REGISTER = '[Register] Action',
  USER_REQUESTED = '[Request User] Action',
  USER_LOADED = '[Load User] Auth API',

  LOGIN_BY_TOKEN = '[Login] Login By Token Action',
  LOGIN_BY_TOKEN_SUCCESS = '[Login] Login By Token Success Action',
  LOGIN_BY_TOKEN_FAILURE = '[Login] Login By Token Failure Action',

  CHANGE_PASSWORD = '[Login] Change Password Action',
  CHANGE_PASSWORD_SUCCESS = '[Login] Change Password Success Action',
  CHANGE_PASSWORD_FAILURE = '[Login] Change Password Failure Action',

  CHANGE_PROFILE = '[Login] Change Profile Action',
  CHANGE_PROFILE_SUCCESS = '[Login] Change Profile Success Action',
  CHANGE_PROFILE_FAILURE = '[Login] Change Profile Failure Action',

  SET_SUCCESS_NULL = '[LOGIN] Set success null action',

  SET_STATUS_NULL = '[LOGIN] Set status null action'

}

export class Login implements Action {
  readonly type = AuthActionTypes.LOGIN;

  constructor(public payload: { username: string, password: string }) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class Register implements Action {
  readonly type = AuthActionTypes.REGISTER;

  constructor(public payload: { authToken: string }) { }
}


export class UserRequested implements Action {
  readonly type = AuthActionTypes.USER_REQUESTED;
}

export class UserLoaded implements Action {
  readonly type = AuthActionTypes.USER_LOADED;

  constructor(public payload: { user: User }) { }
}

export class LoginByToken implements Action {
  readonly type = AuthActionTypes.LOGIN_BY_TOKEN;
}

export class LoginByTokenSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_BY_TOKEN_SUCCESS;
}

export class LoginByTokenFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_BY_TOKEN_FAILURE;

  constructor(public payload: Error) { }
}

export class ChangePassword implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD;

  constructor(public payload: { user_name: string, current_password: string, new_password: string }) {
  }
}

export class ChangePasswordSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_SUCCESS;
  constructor(public payload: string) { }
}

export class ChangePasswordFailure implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_FAILURE;
  constructor(public payload: Error) { }
}



export class ChangeProfile implements Action {
  readonly type = AuthActionTypes.CHANGE_PROFILE;

  constructor(public payload: { first_name: string,last_name: string,email:string }) {
  }
}

export class ChangeProfileSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_PROFILE_SUCCESS;
  constructor(public payload:{first_name: string,last_name: string,}) { }
}

export class ChangeProfileFailure implements Action {
  readonly type = AuthActionTypes.CHANGE_PROFILE_FAILURE;
  constructor(public payload: Error) { }
}

export class AuthSetStatusNull implements Action {
  readonly type = AuthActionTypes.SET_SUCCESS_NULL;
}

export class AuthSuccessNull implements Action {
  readonly type = AuthActionTypes.SET_STATUS_NULL;
}

export type AuthActions = Login | Logout | Register | UserRequested | UserLoaded | LoginByToken | LoginByTokenSuccess |
  LoginByTokenFailure | ChangePassword | ChangePasswordSuccess | ChangePasswordFailure |ChangeProfile | ChangeProfileSuccess | ChangeProfileFailure | AuthSetStatusNull | AuthSuccessNull;
