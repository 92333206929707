import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/core';
import {AddArticleProduct, UpdateArticleProduct} from 'src/app/core/store/actions/article.actions';
import {Query} from '@syncfusion/ej2-data';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {ProductService} from 'src/app/core/store/services/product.service';
import * as _ from 'lodash';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';


@Component({
  selector: 'app-article-product-form',
  templateUrl: './article-product.component.html',
  styleUrls: ['./article-product.component.scss']
})
export class ArticleProductComponent implements OnInit, OnChanges {

  @Input() productList: any = [];
  @Input() articleCode: string;
  @Input() selectedProductList = [];
  @Output() valueChange = new EventEmitter();
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<any>;

  public productFields: object = {value: 'product_code', text: 'product_name'};


  articleProductForm: FormGroup;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private productService: ProductService) {
  }

  ngOnInit(): void {
    this.generateArticleProductForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.valueChange.emit('success');
        this.closeSidebar();
      }
    });

  }

  generateArticleProductForm() {
    this.articleProductForm = this.formBuilder.group({
      product_code: new FormControl('')
    });
  }

  onAddArticleProductLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleProduct({article_code: this.articleCode, product_code: form.value.product_code}));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.articleProductForm.reset();
    this.sidebarInstance.hide();
  }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    const productList = [];


    // if ((this.projectCompanyRoleForm && this.projectCompanyRoleForm.controls.company_code.value !== null &&
    //   this.projectCompanyRoleForm.controls.company_code.value !== '') || this.projectSourceForm) {
    query = (e.text !== '') ? query.where('product_name', 'contains', e.text, true) : query;
    const contactfilterParams = {search: e.text, page_size: 40, page_no: 1};
    this.productService.getSearchResults(contactfilterParams).subscribe((data) => {
      if (data && data.data) {
        if (data.data.result) {
          for (const product of data.data.result) {
            const checkPub = product.publish_status !== 'publish' ? ' [NP]' : ' [P]';
            productList.push({...product, product_name: product.product_name + checkPub});
          }
          // this.productList = _.clone(productList);
          e.updateData(_.clone(productList), query);
        }
      }
    });

    // }

  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProductList) {
      this.searchAndSetProduct(this.selectedProductList);

    }
  }

  searchAndSetProduct(products) {
    let selectedProductList = [];
    let productList = [];

    if (products && products.length > 0) {
      for (const product of products) {
        let selectedProductName = product.product_name;
        if (selectedProductName) {
          let isProductExistsInList = this.productList.filter(el => el.product_name === selectedProductName).length > 0;
          if (!isProductExistsInList) {
            const checkPub = product.publish_status !== 'publish' ? ' [NP]' : ' [P]';

            productList.push({...product, product_name:  product.product_name + checkPub});


          }

          selectedProductList.push(product.product_code);
        }

      }

      this.productList.forEach(el => productList.push(el));
      this.productList = [...this.productList, ...productList];
    }

    if (this.articleProductForm) {
      this.articleProductForm.controls.product_code.setValue(selectedProductList);

    }

  }
  clearAll(){
    this.clearModal.hide()
  this.articleProductForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
