import {BrowserModule, Title} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule, SwUpdate} from '@angular/service-worker';
import * as Sentry from '@sentry/angular';

// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';

import {
  LayoutRefService,
  LayoutConfigService,
  SplashScreenService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService
} from './core/_base/layout';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { PartialsModule } from './views/partials/partials.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './views/pages/authentication/auth.module';
import { reducers } from './core';
import { ThemeModule } from './views/theme/theme.module';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthGuardService } from './core/_base/crud/utils/AuthGuard.service';
import { SubheaderService } from './core/_base/layout/services/subheader.service';
import { PageConfigService } from './core/_base/layout/services/page-config.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppService } from './core/services/app.service';
import {SyncfusionModule} from './syncfusion.module';
import {metaReducers} from './core/store/reducers/meta-reducer.reducer';
import {ActivityService} from './core/store/services/activity.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ActionsService} from './core/services/actions.service';
import {RequestCache} from './core/_base/crud/utils/request-cache.service';
import {CachingInterceptor} from './core/_base/crud/utils/caching.interceptor';
import { NotifierService } from './views/partials/layout/notification/notfier.service';
import {NotificationType} from './views/partials/layout/notification/notification-type';
import * as _ from 'lodash';
import {NotificationComponent} from './views/partials/layout/notification/notification.component';
import {NotificationService} from './core/services/notification.service';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {NotifierModule} from 'angular-notifier';
import {ProgressBarModule} from '@syncfusion/ej2-angular-progressbar';
import { GridModule, EditService, ToolbarService, SortService, PageService } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { SelectionService, RowDDService } from '@syncfusion/ej2-angular-grids';
export const appServiceFactory = (startupService: AppService) => {
  return () => startupService.init();
};

Sentry.init({
  dsn: environment.SENTRY_DSN
});


@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
  ],
  imports: [
    TextBoxModule,
    MultiSelectModule,
    AutoCompleteModule,
    BrowserModule,
        GridModule,
      
       
        FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PartialsModule,
    ThemeModule,
    SyncfusionModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    InlineSVGModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    AuthModule.forRoot(),
    FontAwesomeModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NotifierModule,
    ProgressBarModule,
  ],
  providers: [
    LayoutRefService,
    LayoutConfigService,
    MenuAsideService,
    MenuConfigService,
    SplashScreenService,
    AuthGuardService,
    SubheaderService,
    PageConfigService,
    AppService,
    ActivityService,
    MenuHorizontalService,
    Title,
    NotificationService,
    NotifierService,
    PageService, SelectionService, RowDDService,
    EditService, ToolbarService, SortService, PageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appServiceFactory,
      deps: [AppService],
      multi: true
    },
    

    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    ActionsService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
  
})
export class AppModule {
  constructor(swUpdate: SwUpdate, _notifier: NotifierService) {
    if (swUpdate.isEnabled) {
      swUpdate.checkForUpdate();

      swUpdate.available.subscribe((isUpdate) => {
        if (_.get(isUpdate, 'type') === 'UPDATE_AVAILABLE') {
          
          newVersion(_notifier);
        }
      });

    }
  }
}

function newVersion(_notifier) {
  _notifier.notify({ title: 'A New Version is Available', subtitle: 'Update to continue responding' }, NotificationType.Reload);
}

