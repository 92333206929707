import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NumericTextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { BehaviorSubject, Observable } from 'rxjs';
import { trimValidator } from 'src/app/app-validators';
import { AppState } from 'src/app/core';
import { ArticleStage } from 'src/app/core/models/admin/article-stages.model';
import { AddArticleStage, MetaDataResetError, UpdateArticleStage } from 'src/app/core/store/actions/common.actions';

@Component({
  selector: 'app-article-stages-form',
  templateUrl: './article-stages.component.html',
  styleUrls: ['./article-stages.component.scss']
})
export class ArticleStagesComponent implements OnInit {

  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent; 
  @ViewChild('rankOrder') rankOrder: NumericTextBoxComponent;

  @Input() maxValue: number;
  @Input() articleStageList: any[];
  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];

  articleStagesForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  public minValue: number = 1;
  articleStagesFields: any;
  
  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _articleStagesInput$: BehaviorSubject<ArticleStage | null> = new BehaviorSubject<ArticleStage | null>(null);

  @Input() set articleStageInput(mode: ArticleStage | null) {
    this._articleStagesInput$.next(mode);
  }

  get articleStageInput(): ArticleStage | null {
    return this._articleStagesInput$.getValue();
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.articleStagesForm) {
      this.generateArticleStageForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateArticleStageForm() {
    this.articleStagesForm = this.formBuilder.group({
      article_stage_name: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.compose([trimValidator])]),
      filter_color: new FormControl('', [Validators.required]),
      short_code: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      filter_text_color: new FormControl('', [Validators.required]),
      rank_order: new FormControl('', [Validators.required]),
      article_stage_parent_code: new FormControl(''),
      description: new FormControl('', Validators.maxLength(200))
    });
  }

  onAddUpdateArticleStage(form: FormGroup, isUpdate = false) {
     if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddArticleStage({article_stage_name: form.value.article_stage_name, article_stage_parent_code: form.value.article_stage_parent_code, description: form.value.description,
        filter_color: form.value.filter_color, short_code: form.value.short_code, filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      } else {
        this.store.dispatch(new UpdateArticleStage({article_stage_name: form.value.article_stage_name, article_stage_parent_code: form.value.article_stage_parent_code[0], description: form.value.description,
          filter_color: form.value.filter_color, short_code: form.value.short_code,
          article_stage_code: this._articleStagesInput$.getValue().article_stage_code,
          filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      }
    }
  }

  openSideBar(): void {
    
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.articleStagesForm.reset();
    this.sidebarInstance.hide();
    this.articleStagesForm.controls.article_stage_parent_code.setValue('');


    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.articleStagesForm) {
      this.generateArticleStageForm();
    }

    if (this._articleStagesInput$.getValue()) {
      this.articleStagesForm.setValue({
        article_stage_name: this._articleStagesInput$.getValue().article_stage_name,
        filter_color: this._articleStagesInput$.getValue().filter_color,
        short_code: this._articleStagesInput$.getValue().short_code,
        filter_text_color: this._articleStagesInput$.getValue().filter_text_color,
        rank_order: this._articleStagesInput$.getValue().rank_order,
        description: this._articleStagesInput$.getValue().description,
        article_stage_parent_code: this._articleStagesInput$.getValue().parent ? [this._articleStagesInput$.getValue().parent] : '',
      });
    }

    if (changes.articleStageList) {
      this.articleStagesFields = {
        dataSource: this.articleStageList,
        value: 'article_stage_code',
        text: 'article_stage_name',
        child: 'children'
      };
    }
  }

  flattenData (data) {
    let result = [];
    
    while (data) {
      result.push({ name: data.name, config: data.config });
      data = data.prev;
    }
    
    return result;
  }
  clearAll(){
    this.clearModal.hide()
    this.articleStagesForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
