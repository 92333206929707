import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/core';
import { AddArticleCompany, UpdateArticleCompany } from 'src/app/core/store/actions/article.actions';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import { CompanyService } from 'src/app/core/store/services/company.service';
import { Query } from '@syncfusion/ej2-data';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';



@Component({
  selector: 'app-article-company-form',
  templateUrl: './article-company.component.html',
  styleUrls: ['./article-company.component.scss']
})
export class ArticleCompanyComponent implements OnInit, OnChanges {

  @Input() companyList: any = [];
  @Input() articleCode: string;
  @Input() selectedCompanyList = [];
  @Output() valueChange = new EventEmitter();
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  public companyFields: object = {value: 'company_code', text: 'company_name'};


  articleCompanyForm: FormGroup;
  success$: Observable<any>;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private companyService: CompanyService) { }

  ngOnInit(): void {
    this.generateArticleCompanyForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.valueChange.emit("succes");
      }
    });

  }

  generateArticleCompanyForm() {
    this.articleCompanyForm = this.formBuilder.group({
      company_code: new FormControl('')
    });
  }

  onAddArticleContactLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleCompany({article_code: this.articleCode, company_code: form.value.company_code}));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
    this.articleCompanyForm.reset();
  }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();

      query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
      const filterParams = {company_name: e.text, page_size: 40, page_no: 1};

      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data.data) {
          const companyList = [];
          for (const company of data.data.result) {
            const checkPub = company.is_published_yn === 0 ? ' [NP]' : ' [P]';
            companyList.push({company_name: company.company_name + ' (' + company.reference_id + ')' + checkPub, company_code: company.company_code});
          }
          e.updateData(_.clone(companyList), query);
        }
      });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCompanyList) {
      this.searchAndSetCompany(this.selectedCompanyList);

    }
  }

  searchAndSetCompany(companies) {
    let selectedCompanyList = [];
    let companyList = [];

    if (companies && companies.length > 0) {
      for(const company of companies) {
        const selectedCompanyCode = company.company_code;
        const checkPub = company.is_published_yn === 0 ? ' [NP]' : ' [P]';
        // company.company_name = company.company_name + ' (' + company.reference_id + ')' + checkPub;
        if (selectedCompanyCode) {
          let isCompanyExistsInList = this.companyList.filter(el => el.company_code === selectedCompanyCode).length > 0;
          if (!isCompanyExistsInList) {
            companyList.push({...company, company_name: company.company_name + ' (' + company.reference_id + ')' + checkPub});


          }

          selectedCompanyList.push(company.company_code);
        }

      }

      this.companyList.forEach(el => companyList.push(el));
      // this.dropdownObj.dataSource = companyList;
      this.companyList = [...this.companyList, ...companyList];
      this.companyList = _.uniqBy(this.companyList, 'company_code');
    }

    if (this.articleCompanyForm) {
      this.articleCompanyForm.controls.company_code.setValue(selectedCompanyList);

    }

  }
  clearAll(){
    this.clearModal.hide()
    this.articleCompanyForm.reset();
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }

}
