import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { FormatDatePipe } from './DateFormat.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconGeneratePipe } from './icon-generate.pipe';
import {ReversePipe} from './Reverse.pipe';
import {FirstLetterPipe} from './FirstLetter.pipe';
import {ShortUrlPipe} from './short-url.pipe';
import {ArrayStringPipe} from '../../../../views/pipes/pipes/array-to-string.pipe';
import { ReplaceLineBreaks } from './ReplaceLineBreaks.pipe';
import { TextSlicePipe } from './text-slice.pipe';


@NgModule({
  declarations: [FormatDatePipe, IconGeneratePipe, ReversePipe, FirstLetterPipe, ShortUrlPipe, ArrayStringPipe, ReplaceLineBreaks, TextSlicePipe],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    FormatDatePipe,
    IconGeneratePipe,
    ReversePipe,
    FirstLetterPipe,
    ShortUrlPipe,
    ArrayStringPipe,
    ReplaceLineBreaks,
    TextSlicePipe,

  ]
})

export class MainPipe { }
