import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { matchValidator } from '../../../../app-validators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core';
import { ChangePassword, AuthSuccessNull, ChangeProfile } from '../../../../core/store/auth/auth.actions';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../../../core/store/services/admin.service';
import { v4 as uuid } from 'uuid';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-angular-popups';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('form') public forms: ElementRef;
  @ViewChild('linkedPassword') public linkedPassword: TextBoxComponent;
  @ViewChild('deleteModal') public deleteModal: DialogComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.deleteAppPassword.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideDeleteModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];

    public clrButtons: ButtonPropsModel[] = [
      { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
      { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];

  @Input() isLinkEmailAccountVisible = false;
  @Input() userDetails: any;
  changePasswordForm: FormGroup;
  changeUserNameForm: FormGroup;
  formSent: boolean;
  userEmail: string;
  isChangePassword = false;
  isChangeUsername = false;
  isLinkEmailAccount = false;
  linkEmailPasswordPlaceholder = '*****';
  linkEmailPassword = '';
  isPasswordValueChanged=false
  HasValues = false;
  isfilled = true;
  firstname: any;
  lastname: any;
  userCode: any;
  message = ''
  errorMasForUpdate=''
  public width = 'auto';
  public position = 'Right';
  isNewRequest = true;
  clicked = true;
  HasNotAllowedCharecters = false;
  isLinkedEmailError = false
  success = false;
  error = false;
  userDetail: any
  success$: Observable<any>;
  error$: Observable<any>;
  isSubmitted = false;
  isAppPasswordSet = false
  isApppasswordBeforeSet = false
  isVerified = false
  userData: any
  isTestmail=false
  isUpdateButtonClicked = false
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
   
    this.store.select(store => store.auth.user).subscribe((data) => {
      if (data) {
        this.userEmail = data.email;
        this.firstname = data.first_name;
        this.lastname = data.last_name;
        this.userCode = data.user_code
      }
    });

    this.adminService.getUserDetails(this.userCode).subscribe(res => {
      try {
        if (res && res.data) {
          sessionStorage.setItem("userDetails", JSON.stringify(res.data));
          this.userDetails = res.data;
          this.userData = res.data
          
          sessionStorage.setItem("userDetails", JSON.stringify(this.userData));
          
         
        }
      } catch (e) { }
    });

    this.route.queryParams.subscribe(res => {
      Object.keys(res).forEach(key => {
        if (key === 'change' && res[key] === 'user-name') {
          this.isChangePassword = false;
          this.isLinkEmailAccount = false;
          this.isChangeUsername = true;
          this.generateUserNameChangeForm();
          this.changeUserNameForm.controls.firstname.setValue(this.firstname);
          this.changeUserNameForm.controls.lastname.setValue(this.lastname);
        } else if (key === 'change' && res[key] === 'password') {
          this.isChangeUsername = false;
          this.isLinkEmailAccount = false;
          this.isChangePassword = true;
          this.generateChangePasswordForm();
        } else if (key === 'change' && res[key] === 'link-email-account') {
          this.isChangeUsername = false;
          this.isChangePassword = false;
          this.isLinkEmailAccount = true;
          this.linkEmailPassword = '';
          this.linkEmailPasswordPlaceholder = '*****';
          this.getLinkEmail();
          this.errorMasForUpdate=''
          // document.getElementById("Emailerror").innerHTML = ''
          document.getElementById("mailerror").innerHTML = ''
        }
      });
    });
    this.success$ = this.store.select((store => store.auth.success));
    this.success$.subscribe((data) => {
      if (this.isChangePassword) {
        this.changePasswordForm.reset();
      }
      if (data !== undefined) {

        this.success = true;
        this.error = false;
        this.closeSidebar();
        const btn = document.getElementById('kt_quick_panel_close');
        btn.click();
      }
    });
    this.error$ = this.store.select((store => store.auth.error));
    this.error$.subscribe((error) => {
      if (error) {
        this.success = false;
        this.error = true;
      }
    });




    this.userDetail = JSON.parse(sessionStorage.userDetails);
    console.log(this.userDetail)
    if (this.userDetail.app_password == 1 && this.userDetail.is_verified_yn == 1) {
      this.isAppPasswordSet = true
      this.linkEmailPassword = '********'
      this.isApppasswordBeforeSet = true
      this.isVerified = true

    }
    else if (this.userDetail.app_password == 1 && this.userDetail.is_verified_yn != 1) {
      this.isAppPasswordSet = true
      this.linkEmailPassword = '*******'
      this.isApppasswordBeforeSet = true
      this.isVerified = false

    }
    else if (this.userDetail.app_password != 1 && this.userDetail.is_verified_yn != 1) {
      this.isAppPasswordSet = false
      this.linkEmailPassword = ''
      this.isApppasswordBeforeSet = false
      this.isVerified = false

    }

   

  }

  generateUserNameChangeForm() {
    this.changeUserNameForm = this.formBuilder.group({
      firstname: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      lastname: new FormControl(null, Validators.maxLength(20)),
    });
  }

  generateChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      current_password: new FormControl(null, Validators.required),
      new_passwords: this.formBuilder.group({
        new_password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
        confirm_new_password: new FormControl(null, [Validators.required]),
      }, { validators: matchValidator })
    });
  }

  getLinkEmail() { }
  updateLinkedEmail() {
    this.errorMasForUpdate=''
    // document.getElementById("Emailerror").innerHTML = ''
    document.getElementById("mailerror").innerHTML = ''

    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById('container') as any
    });

    if (this.linkEmailPassword != '') {
      this.isAppPasswordSet = false
      this.isApppasswordBeforeSet = false
      this.isUpdateButtonClicked = true
      showSpinner((document as any).getElementById('container'));
      this.message = 'Email Linked Successfully'

      this.isLinkedEmailError = false
      const postParams = {
        batch_id: uuid(),
        email: this.userEmail,
        app_password: this.linkEmailPassword,
      };
      this.adminService.updateEmailAccount(postParams).subscribe(res => {
        if (res && res.data) {
          this.isUpdateButtonClicked = false
          this.isLinkedEmailError = false
          this.errorMasForUpdate=''
          this.linkedPassword.focusIn()
          hideSpinner((document as any).getElementById('container'));
          this.linkEmailPassword = '********'
          this.isAppPasswordSet = true
          this.isApppasswordBeforeSet = true
          this.isVerified = true
          this.linkedPassword.focusOut()
          if (res.data == 'Email account linked successfully.') {
            // this.success = true;
            // this.error = false;

            const x = document.getElementById('showMessage');
            x.className = 'show';
            setTimeout(() => {
              x.className = x.className.replace('show', '');
            }, 3000);
            this.adminService.getUserDetails(this.userCode).subscribe(res => {
              try {
                if (res && res.data) {
                  sessionStorage.setItem("userDetails", JSON.stringify(res.data));
                  this.userDetails = res.data;
                  this.userData = res.data
                  sessionStorage.setItem("userDetails", JSON.stringify(this.userData));
                  // setTimeout(() => {
                  //   this.updateUserdata()
                  // }, 3002);
                }
              } catch (e) { }
            });
            this.errorMasForUpdate=''
            // document.getElementById("Emailerror").innerHTML = ''
            document.getElementById("mailerror").innerHTML = ''
          }
        }
      }, err => {
       
        this.isUpdateButtonClicked = false
        this.linkedPassword.focusIn()
        this.isLinkedEmailError = true
        var msg=err.error.error
        this.errorMasForUpdate=msg
        hideSpinner((document as any).getElementById('container'));
        this.linkedPassword.focusOut()
        // document.getElementById("Emailerror").innerHTML = err.error.error
        if (this.userDetail.app_password == 1 && this.userDetail.is_verified_yn == 1) {
        this.isAppPasswordSet = true
      
         this.isApppasswordBeforeSet = true}
         else if (this.userDetail.app_password == 1 && this.userDetail.is_verified_yn != 1) {
          this.isAppPasswordSet = true
        
           this.isApppasswordBeforeSet = true}
           else if(this.userDetail.app_password == 1 && this.userDetail.is_verified_yn != 1){
            this.isAppPasswordSet = false
        
            this.isApppasswordBeforeSet = false
           }
           this.isVerified =false
        
        this.adminService.getUserDetails(this.userCode).subscribe(res => {
          try {
            if (res && res.data) {
              
              sessionStorage.setItem("userDetails", JSON.stringify(res.data));
              this.userDetails = res.data;
              this.userData = res.data
              sessionStorage.setItem("userDetails", JSON.stringify(this.userData));
              // setTimeout(() => {
              //   this.updateUserdata()
              // }, 3002);
            }
          } catch (e) { }
        });

      });
    }
    else {
      this.message = 'App Password Deleted Successfully'
      this.deleteModal.show()
    }

  }
  updateUserdata() {
    
    this.userDetail = JSON.parse(sessionStorage.userDetails);
    if (this.userDetail.app_password == 1 && this.userDetail.is_verified_yn == 1) {
      this.isAppPasswordSet = true
      this.linkEmailPassword = '********'
      this.isApppasswordBeforeSet = true
      this.isVerified = true

    }
    else if (this.userDetail.app_password == 1 && this.userDetail.is_verified_yn != 1) {
      this.isAppPasswordSet = true
      this.linkEmailPassword = '*******'
      this.isApppasswordBeforeSet = true
      this.isVerified = false

    }
    else if (this.userDetail.app_password != 1 && this.userDetail.is_verified_yn != 1) {
      this.isAppPasswordSet = false
      this.linkEmailPassword = ''
      this.isApppasswordBeforeSet = false
      this.isVerified = false

    }
    this.linkedPassword.focusIn()
  }
  sendTestEmail() {
    this.errorMasForUpdate=''
    // createSpinner({
    //   // Specify the target for the spinner to show
    //   target: document.getElementById('container') as any
    // });
    // this.isAppPasswordSet = false
    // this.isVerified = false
    // showSpinner((document as any).getElementById('container'));
    this.isLinkedEmailError = false
    this.message = 'Email has been added to the queue, Please wait for sometime to see it in your mailbox.'
    const postParams = {
      batch_id: uuid(),
      email_subject: 'Test Email to check Email Account Authentication',
      email_content: `Test Email By ${this.userEmail} from Canvas`,
      sender_email: this.userCode,
      receiver_email: this.userEmail
    };
    this.adminService.sendTeatEmail(postParams).subscribe(res => {
      if (res && res.data) {
        if (res.data == "Test email sent") {
          this.isAppPasswordSet = true
          this.isVerified = true
          this.isLinkedEmailError = false
          // this.success = true;
          // this.error = false;
          const x = document.getElementById('showMessage');
          x.className = 'show';
          setTimeout(() => {
            x.className = x.className.replace('show', '');
          }, 3000);


          // hideSpinner((document as any).getElementById('container'));
          // setTimeout(() => {
          //   this.closeSidebar();
          //   const btn = document.getElementById('kt_quick_panel_close');
          //   btn.click();
          // }, 3002);
        }
      }
    }, err => {

      // hideSpinner((document as any).getElementById('container'));
      document.getElementById("mailerror").innerHTML = err.error.error
      this.linkedPassword.focusIn()
      this.isLinkedEmailError = true
      this.errorMasForUpdate=''
      this.isAppPasswordSet = true
      this.isVerified = false
      this.linkEmailPassword = '*******'
      this.isApppasswordBeforeSet = true
      this.linkedPassword.focusOut()
      this.adminService.getUserDetails(this.userCode).subscribe(res => {
        try {
          if (res && res.data) {
            sessionStorage.setItem("userDetails", JSON.stringify(res.data));
            this.userDetails = res.data;
            this.userData = res.data
            sessionStorage.setItem("userDetails", JSON.stringify(this.userData));
            this.isAppPasswordSet = true
            this.linkEmailPassword = '*******'
            this.isApppasswordBeforeSet = true
            this.isVerified = false
          }
        } catch (e) { }
      });

    });




  }

  changePassword() {

    if (this.changePasswordForm.value.new_passwords.new_password == null || this.changePasswordForm.value.current_password == null) {
      this.isfilled = false;
    } else {
      this.isfilled = true;
    }
    this.store.dispatch(new AuthSuccessNull());
    if (this.changePasswordForm.valid) {
      this.isfilled = true;
      //   this.HasValues=true
      //   this.isNewRequest=false
      //   this.clicked=false
      this.store.dispatch(new ChangePassword({
        user_name: this.userEmail, current_password: this.changePasswordForm.controls.current_password.value,
        new_password: this.changePasswordForm.get('new_passwords.new_password').value
      }));
    }

  }
  changeUsername() {
    this.HasNotAllowedCharecters = false;
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const isFnameHasSpecialCharecter = format.test(this.changeUserNameForm.controls.firstname.value);
    const isLnameHasSpecialCharecter = format.test(this.changeUserNameForm.controls.lastname.value);
    const isFnameHasNumber = /\d/.test(this.changeUserNameForm.controls.firstname.value);
    const isLnameHasNumber = /\d/.test(this.changeUserNameForm.controls.lastname.value);
    if (isFnameHasSpecialCharecter || isLnameHasSpecialCharecter || isLnameHasNumber || isFnameHasNumber) {
      this.HasNotAllowedCharecters = true;
    }
    if (!isFnameHasSpecialCharecter && !isLnameHasSpecialCharecter && !isLnameHasNumber && !isFnameHasNumber) {
      this.HasNotAllowedCharecters = false;
    }
    this.isSubmitted = true;
    if (this.changeUserNameForm.valid && !this.HasNotAllowedCharecters) {
      this.store.dispatch(new ChangeProfile({
        first_name: this.changeUserNameForm.controls.firstname.value,
        last_name: this.changeUserNameForm.controls.lastname.value,
        email: this.userEmail
      }));
    }
    // this.HasValues=true
  }

  clearForm() {
   
  }
  closeSidebar() {

    this.HasNotAllowedCharecters = false;
    this.store.dispatch(new AuthSuccessNull());
    this.isfilled = true;
    // this.isNewRequest=true
    
    if (this.success === true) {
      const param = {
        isSuccess: 'true'
      };
      this.router.navigate([], { queryParams: param });
    } 
    else{
      this.router.navigate([]);
    }
  }
  valueChange() {
    
    this.isAppPasswordSet = false
    
  }
  hideDeleteModal() {
    this.deleteModal.hide()
  }
  deleteAppPassword() {
    
    this.deleteModal.hide()
    this.adminService.DeleteAppPassword(this.userCode).subscribe(res => {
      if (res && res.data) {
        this.isAppPasswordSet = false
        this.linkEmailPassword = ''
        this.isApppasswordBeforeSet = false
        this.isVerified = false
        this.isLinkedEmailError = false
        this.linkedPassword.focusIn()
        const x = document.getElementById('showMessage');
        x.className = 'show';
        setTimeout(() => {
          x.className = x.className.replace('show', '');
        }, 3000);
      }
      this.linkedPassword.focusOut()
    }, err => {
      window.alert(err.error.error)
    })

    this.adminService.getUserDetails(this.userCode).subscribe(res => {
      try {
        if (res && res.data) {
          sessionStorage.setItem("userDetails", JSON.stringify(res.data));
          this.userDetails = res.data;
          this.userData = res.data
          sessionStorage.setItem("userDetails", JSON.stringify(this.userData));

        }
      } catch (e) { }
    });

  }

  clearAll(){
   
    this.clearModal.hide()
    this.changePasswordForm.reset()
    
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
