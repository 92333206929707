<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2>Order Line Item Filter</h2>
    <form id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.order_item_code.value == ''">
        <ejs-dropdowntree formControlName="order_item_code" id='order_item_code' [showSelectAll]=true
                         [fields]='lineItemFields' placeholder='Line Item' cssClass="e-filled"
                         sortOrder="Ascending" (blur)="filterForm.controls.order_item_code.markAsTouched()"
                         floatLabelType="Auto"[showCheckBox]=true [treeSettings]='treeSettings'></ejs-dropdowntree>
      </div>


      <!--      <div class="form-group sf-outline-input mb-10" *ngIf="!isCompanyLink()">-->
      <!--        <ejs-multiselect id='company' formControlName="company_code" #checkboxCompany [dataSource]='companyList'-->
      <!--                         placeholder='Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"-->
      <!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'-->
      <!--                         (filtering)="onFiltering($event, 'company')"-->
      <!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
      <!--      </div>-->
      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 w-100 mr-2">
          <ejs-textbox id="amount_start" placeholder="Amount From" cssClass="e-filled"
                       formControlName="amount_start" floatLabelType="Auto" appOnlynumber
                       (blur)="filterForm.controls.amount_start.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="filterForm.controls.amount_start.errors">
            <div *ngIf="filterForm.controls.amount_start.errors.invalidNumber && filterForm.controls.amount_start.touched "
                 class="e-error">
              Please enter a valid amount
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2">
          <ejs-textbox id="amount_end" placeholder="Amount Till" cssClass="e-filled"
                       formControlName="amount_end" floatLabelType="Auto" appOnlynumber
                       (blur)="filterForm.controls.amount_end.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="filterForm.controls.amount_end.errors">
            <div *ngIf="filterForm.controls.amount_end.errors.invalidNumber && filterForm.controls.amount_end.touched "
                 class="e-error">
              Please enter a valid amount
            </div>
          </div>
        </div>

      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 w-100 mr-2">
          <ejs-textbox id="quantity_start" placeholder="Quantity From" cssClass="e-filled"
                       formControlName="quantity_start" floatLabelType="Auto" appOnlynumber
                       (blur)="filterForm.controls.quantity_start.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="filterForm.controls.quantity_start.errors">
            <div *ngIf="filterForm.controls.quantity_start.errors.invalidNumber && filterForm.controls.quantity_start.touched "
                 class="e-error">
              Please enter a valid quantity
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2">
          <ejs-textbox id="quantity_end" placeholder="Quantity Till" cssClass="e-filled"
                       formControlName="quantity_end" floatLabelType="Auto" appOnlynumber
                       (blur)="filterForm.controls.quantity_end.markAsTouched()"
          ></ejs-textbox>
          <div *ngIf="filterForm.controls.quantity_end.errors">
            <div *ngIf="filterForm.controls.quantity_end.errors.invalidNumber && filterForm.controls.quantity_end.touched "
                 class="e-error">
              Please enter a valid quantity
            </div>
          </div>
        </div>

      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="updated_at" name="updated_at" [allowEdit]="allowEdit"
                               placeholder='Updated Between' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='updated_by' formControlName="updated_by" #checkbox [dataSource]='users'
                           placeholder='Updated By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>
                           
                          </ng-template></ejs-multiselect>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="created_at" name="created_at" [allowEdit]="allowEdit"
                               placeholder='Created Between' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>



        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='created_by' formControlName="created_by" #checkbox [dataSource]='users'
                           placeholder='Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>
                           
                          </ng-template></ejs-multiselect>
        </div>
      </div>


      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clear()"
                data-ripple="true">Clear All
        </button>
        <button id="filter"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
                type="button"
                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
        </button>
      </div>

    </form>

  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
