import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  CompanyActionTypes,
  CompanyLoadSuccess,
  CompanyLoadFailure,
  CompanyAdd,
  CompanyAddSuccess,
  CompanyAddFailure,
  CompanyDelete,
  CompanyDeleteSuccess,
  CompanyDeleteFailure,
  CompanyUpdate,
  CompanyUpdateSuccess,
  CompanyUpdateFailure,
  CompanyGetContacts,
  CompanyGetContactsSuccess,
  CompanyGetContactsFailure,
  CompanyFilter,
  CompanyFilterSuccess,
  CompanyFilterFailure,
  GetCompanyActivityFailure,
  GetCompanyActivitySuccess,
  GetCompanyActivity,
  GetCompanyHistorySuccess,
  GetCompanyHistory,
  GetCompanyHistoryFailure,
  AddCompanyActivity,
  AddCompanyActivitySuccess,
  AddCompanyActivityFailure,
  DeleteCompanyActivity,
  DeleteCompanyActivitySuccess,
  DeleteCompanyActivityFailure,
  UpdateCompanyActivity,
  UpdateCompanyActivitySuccess,
  UpdateCompanyActivityFailure,
  CompanyActivityFilter,
  CompanyActivityFilterSuccess,
  CompanyActivityFilterFailure,
  CompanyLoad,
  CompanyGetProjectsSuccess,
  CompanyGetProjects,
  CompanyGetProjectsFailure,
  CompanyProjectFilter,
  CompanyProjectFilterSuccess,
  CompanyProjectFilterFailure,
  CompanySearch,
  CompanySearchFailure,
  CompanySearchSuccess,
  CompanyOrder,
  CompanyOrderSuccess,
  CompanyOrderFailure,
  CompanyDeleteOrderFailure,
  CompanyDeleteOrderSuccess,
  CompanyDeleteOrder,
  CompanyAddOrder,
  CompanyAddOrderSuccess,
  CompanyAddOrderFailure,
  CompanyUpdateOrder,
  CompanyUpdateOrderSuccess,
  CompanyUpdateOrderFailure,
  GetCompanyActivityHistorySuccess,
  GetCompanyActivityHistoryFailure,
  GetAllCompanyOrders,
  GetAllCompanyOrdersFailure,
  GetAllCompanyOrdersSuccess,
  GetSearchedCompany,
  GetSearchedCompanyFailure,
  GetSearchedCompanySuccess,
  CompanyGetSourceProjectsSuccess,
  CompanyGetSourceProjects,
  CompanyGetSourceProjectsFailure,
  GetSingleCompanyOrder,
  GetSingleCompanyOrderSuccess,
  GetSingleCompanyOrderFailure,
  GetCompanyOrderLineItem,
  GetCompanyOrderLineItemSuccess,
  GetCompanyOrderLineItemFailure,
  AddCompanyOrderLineItem,
  AddCompanyOrderLineItemSuccess,
  AddCompanyOrderLineItemFailure,
  UpdateCompanyOrderLineItem,
  UpdateCompanyOrderLineItemSuccess,
  UpdateCompanyOrderLineItemFailure,
  DeleteCompanyOrderLineItem,
  DeleteCompanyOrderLineItemSuccess,
  DeleteCompanyOrderLineItemFailure,
  GetCompanyOrderDeliverable,
  GetCompanyOrderDeliverableSuccess,
  GetCompanyOrderDeliverableFailure,
  AddCompanyOrderDeliverable,
  AddCompanyOrderDeliverableSuccess,
  AddCompanyOrderDeliverableFailure,
  UpdateCompanyOrderDeliverable,
  UpdateCompanyOrderDeliverableSuccess,
  UpdateCompanyOrderDeliverableFailure,
  DeleteCompanyOrderDeliverable,
  DeleteCompanyOrderDeliverableFailure,
  DeleteCompanyOrderDeliverableSuccess,
  GetDeliverableNotes,
  GetDeliverableNotesSuccess,
  GetDeliverableNotesFailure,
  AddDeliverableNotes,
  AddDeliverableNotesSuccess,
  AddDeliverableNotesFailure,
  UpdateDeliverableNotes,
  UpdateDeliverableNotesSuccess,
  UpdateDeliverableNotesFailure,
  DeleteDeliverableNotes,
  DeleteDeliverableNotesFailure,
  DeleteDeliverableNotesSuccess,
  GetCompanyOrderHistory,
  GetCompanyOrderHistorySuccess,
  GetCompanyOrderHistoryFailure,
  CompanyUpdateSingleOrderSuccess,
  CompanyOrderFilter,
  CompanyOrderFilterFailure,
  CompanyOrderFilterSuccess,
  CompanyOrderDeliverablesFilter,
  CompanyOrderDeliverablesFilterSuccess,
  CompanyOrderDeliverablesFilterFailure,
  CompanyOrderLineItemsFilter,
  CompanyOrderLineItemsFilterFailure,
  CompanyOrderLineItemsFilterSuccess,
  CompanyProjectBulkEdit,
  CompanyProjectBulkEditSuccess,
  CompanyProjectBulkEditFailure,
  CompanyUpdateGeneralSuccess,
  CompanyUpdateGeneral,
  CompanyUpdateGeneralFailure,
  CompanyLoadGeneralSuccess,
  CompanyLoadGeneral,
  CompanyLoadGeneralFailure,
  CompanyLoadLTDSettings,
  CompanyLoadLTDSettingsSuccess,
  CompanyLoadLTDSettingsFailure,
  CompanyUpdateLTDSettings,
  CompanyUpdateLTDSettingsSuccess,
  CompanyUpdateLTDSettingsFailure,
  CompanyGetContactsWithoutRefresh,
  UpdateCompanyOrderColumnSelection,
  UpdateCompanyOrderColumnSelectionSuccess, UpdateCompanyOrderColumnSelectionFailure,
} from '../actions/company.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { CompanyService } from '../services/company.service';
import { of } from 'rxjs';
import { ActivityService } from '../services/activity.service';
import {
  GetProjectActivityHistoryFailure,
  GetProjectActivityHistorySuccess,
  GetProjectHistory,
  ProjectActionTypes, ProjectBulkEdit, ProjectBulkEditFailure, ProjectBulkEditSuccess,
  ProjectFilter,
  ProjectFilterFailure,
  ProjectFilterSuccess
} from '../actions/project.actions';
import { ProjectService } from '../services/project.service';
import {
  ContactActionTypes, ContactLoadGeneral, ContactLoadGeneralFailure, ContactLoadGeneralSuccess,
  ContactUpdateGeneral,
  ContactUpdateGeneralFailure,
  ContactUpdateGeneralSuccess
} from '../actions/contact.actions';

@Injectable()
export class CompanyEffects {

  isRunning = false;
  @Effect()
  loadCompanies = this.actions$.pipe(ofType(CompanyActionTypes.LOAD),
    mergeMap((data: CompanyLoad) => {
      // const errorFlag: Error = new Error();
      const error: any = { error: { message: 'Multiple load company requests found. Ignoring last request ' } };
      if (this.isRunning) {
        return of(new CompanyLoadFailure(error));
      }
      this.isRunning = true;
      return this.companyService.getCompanies(data.payload).pipe(map((response: any) => {
        this.isRunning = false;
        return new CompanyLoadSuccess({ ...response.data });
      }),
        // tslint:disable-next-line:no-shadowed-variable
        catchError((error) => {
          this.isRunning = false;
          return of(new CompanyLoadFailure(error.error));
        }
        )
      );
    }
    ));
  //
  // loadCompanies = this.actions$.pipe(ofType(CompanyActionTypes.LOAD),
  //   switchMap((data: CompanyLoad) => this.companyService.getCompanies(data.payload).pipe(map((response: any) => {
  //       return new CompanyLoadSuccess({...response.data});
  //     }),
  //     catchError((error) =>
  //       of(new CompanyLoadFailure(error.error))
  //     )
  //     )
  //   ));

  @Effect()
  addCompany = this.actions$.pipe(ofType(CompanyActionTypes.ADD),
    switchMap((companyData: CompanyAdd) =>
      this.companyService.addCompany(companyData.payload.company_name, companyData.payload.address, companyData.payload.city_name,
        companyData.payload.country, companyData.payload.website, companyData.payload.tel_no, companyData.payload.industry_type,
        companyData.payload.accountOwner,companyData.payload.account_manager, companyData.payload.company_tag, companyData.payload.excerpt, companyData.payload.display_name,
        companyData.payload.email, companyData.payload.slug, companyData.payload.member_since, companyData.payload.p_o_box, companyData.payload.company_logo_reference_id,
        companyData.payload.primary_community_type_code,companyData.payload.account_owner_activity_note,companyData.payload.account_manager_activity_note)
        .pipe(map((response: any) => {
          response = response.data;
          // const company = new Company(response.company_code, response.company_name, response.address, response.city_name,
          //   response.country, response.website, response.telNo, response.company_type, null, resp);
          return new CompanyAddSuccess({ company: response });
        }),
          catchError(error =>
            of(new CompanyAddFailure(error.error)))
        )
    )
  );

  @Effect()
  deleteCompany = this.actions$.pipe(
    ofType(CompanyActionTypes.DELETE),
    mergeMap(
      (data: CompanyDelete) => this.companyService.deleteCompany(data.payload.companyCode)
        .pipe(
          map(() => new CompanyDeleteSuccess(data.payload)),
          catchError(error => of(new CompanyDeleteFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompany = this.actions$.pipe(
    ofType(CompanyActionTypes.UPDATE),
    mergeMap(
      (data: CompanyUpdate) => this.companyService.updateCompany(data.payload.index, data.payload.company)
        .pipe(map((responseData: any) => {
          return new CompanyUpdateSuccess({ index: responseData.data.company_code, company: responseData.data });
        }),
          catchError(error => of(new CompanyUpdateFailure(error.error)))
        )
    )
  );

  @Effect()
  getCompanyContacts = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_CONTACTS, CompanyActionTypes.GET_CONTACTS_WITHOUT_REFRESH),
    mergeMap(
      (data: CompanyGetContacts | CompanyGetContactsWithoutRefresh) => this.companyService.getCompany(data.payload.companyCode)
        .pipe(
          map((responseData: any) => new CompanyGetContactsSuccess({ company: responseData.data })),
          catchError(error => of(new CompanyGetContactsFailure(error.error)))
        )
    )
  );

  @Effect()
  getFilteredResults = this.actions$.pipe(
    ofType(CompanyActionTypes.FILTER),
    switchMap((data: CompanyFilter) => this.companyService.getFilteredResults(data.payload).pipe(
      map((response: any) => new CompanyFilterSuccess(response.data)),
      catchError(error => of(new CompanyFilterFailure(error.error)))
    ))
  );

  @Effect()
  getCompanyActivity = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_ACTIVITY),
    switchMap((data: GetCompanyActivity) => this.activityService.getActivity(data.payload.companyCode, data.payload.entity).pipe(
      map((response: any) => new GetCompanyActivitySuccess({ companyCode: data.payload.companyCode, activity: response.data })),
      catchError(error => of(new GetCompanyActivityFailure(error.error)))
    ))
  );

  @Effect()
  addCompanyActivity = this.actions$.pipe(ofType(CompanyActionTypes.ADD_COMPANY_ACTIVITY),
    switchMap((activityData: AddCompanyActivity) =>
      this.activityService.addActivity(activityData.payload.company_code, activityData.payload.activity_type,
        activityData.payload.activityTitle, activityData.payload.activityNote, activityData.payload.meeting_date,
        activityData.payload.meeting_start_time, activityData.payload.meeting_end_time, activityData.payload.userParticipants,
        activityData.payload.contactParticipants, activityData.payload.activityTags, activityData.payload.entity)
        .pipe(map((response: any) => {
          response = response.data;
          return new AddCompanyActivitySuccess({ activity: response });
        }),
          catchError(error =>
            of(new AddCompanyActivityFailure(error.error)))
        )
    )
  );

  @Effect()
  deleteCompanyActivity = this.actions$.pipe(
    ofType(CompanyActionTypes.DELETE_COMPANY_ACTIVITY),
    mergeMap(
      (data: DeleteCompanyActivity) => this.activityService.deleteActivity(data.payload.activity_code, data.payload.entity)
        .pipe(
          map(() => new DeleteCompanyActivitySuccess({ activity_code: data.payload.activity_code })),
          catchError(error => of(new DeleteCompanyActivityFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompanyActivity = this.actions$.pipe(
    ofType(CompanyActionTypes.UPDATE_COMPANY_ACTIVITY),
    mergeMap(
      (data: UpdateCompanyActivity) => this.activityService.updateActivity(data.payload.activity_code, data.payload.companyActivity, data.payload.entity)
        .pipe(map((responseData: any) => {
          return new UpdateCompanyActivitySuccess({
            activity_code: responseData.data.activity[0].activity_code,
            activity: responseData.data
          });
        }),
          catchError(error => of(new UpdateCompanyActivityFailure(error.error)))
        )
    )
  );

  @Effect()
  getActivityFilteredResults = this.actions$.pipe(
    ofType(CompanyActionTypes.ACTIVITY_FILTER),
    switchMap((data: CompanyActivityFilter) => this.activityService.getActivityFilteredResults(data.payload.entity, data.payload.filterValues, data.payload.code).pipe(
      map((response: any) => {
        return new CompanyActivityFilterSuccess(response.data);
      }),
      catchError(error => of(new CompanyActivityFilterFailure(error.error)))
    ))
  );

  @Effect()
  getCompanyHistory = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_HISTORY),
    switchMap((data: GetCompanyHistory) => this.companyService.getCompanyHistory(data.payload.companyCode).pipe(
      map((response: any) => new GetCompanyHistorySuccess({ companyCode: data.payload.companyCode, history: response.data })),
      catchError(error => of(new GetCompanyHistoryFailure(error.error)))
    ))
  );

  @Effect()
  getCompanyActivityHistory = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_HISTORY_SUCCESS),
    switchMap((data: GetCompanyHistory) => this.companyService.getCompanyActivityHistory(data.payload.companyCode).pipe(
      map((response: any) => new GetCompanyActivityHistorySuccess({ companyCode: data.payload.companyCode, history: response.data })),
      catchError(error => of(new GetCompanyActivityHistoryFailure(error.error)))
    ))
  );

  @Effect()
  getCompanyProjects = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_PROJECTS),
    switchMap((data: CompanyGetProjects) => this.companyService.getProjects(data.payload).pipe(
      map((response: any) => new CompanyGetProjectsSuccess(response.data)),
      catchError(error => of(new CompanyGetProjectsFailure(error.error)))
    ))
  );

  @Effect()
  getCompanySourceProjects = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_SOURCE_PROJECT),
    switchMap((data: CompanyGetSourceProjects) => this.companyService.getProjects(data.payload).pipe(
      map((response: any) => new CompanyGetSourceProjectsSuccess(response.data)),
      catchError(error => of(new CompanyGetSourceProjectsFailure(error.error)))
    ))
  );

  @Effect()
  getFilteredProjects = this.actions$.pipe(
    ofType(CompanyActionTypes.PROJECT_FILTER),
    switchMap((data: CompanyProjectFilter) => this.companyService.getProjects(data.payload).pipe(
      map((response: any) => {
        return new CompanyProjectFilterSuccess(response.data);
      }),
      catchError(error => of(new CompanyProjectFilterFailure(error.error)))
    ))
  );

  @Effect()
  getSearchResults = this.actions$.pipe(
    ofType(CompanyActionTypes.SEARCH),
    switchMap((data: CompanySearch) => this.companyService.getSearchResults(data.payload).pipe(
      map((response: any) => new CompanySearchSuccess(response.data)),
      catchError(error => of(new CompanySearchFailure(error.error)))
    ))
  );

  @Effect()
  getSearchedCompany = this.actions$.pipe(
    ofType(CompanyActionTypes.SEARCH_COMPANY),
    switchMap((data: GetSearchedCompany) => this.companyService.getSearchedCompany(data.payload).pipe(
      map((response: any) => new GetSearchedCompanySuccess(response.data)),
      catchError(error => of(new GetSearchedCompanyFailure(error.error)))
    ))
  );

  @Effect()
  getAllCompanyOrders = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_ALL_COMPANY_ORDERS),
    switchMap((data: GetAllCompanyOrders) => this.companyService.getAllOrders(data.payload.page_no, data.payload.page_size,data.payload.global).pipe(
      map((response: any) => new GetAllCompanyOrdersSuccess(response.data)),
      catchError(error => of(new GetAllCompanyOrdersFailure(error.error)))
    ))
  );

  @Effect()
  getCompanyOrders = this.actions$.pipe(
    ofType(CompanyActionTypes.COMPANY_ORDER),
    switchMap((data: CompanyOrder) => this.companyService.getOrders(data.payload.company_code, data.payload.page_no, data.payload.page_size).pipe(
      map((response: any) => new CompanyOrderSuccess(response.data)),
      catchError(error => of(new CompanyOrderFailure(error.error)))
    ))
  );

  @Effect()
  deleteCompanyOrder = this.actions$.pipe(
    ofType(CompanyActionTypes.COMPANY_DELETE_ORDER),
    mergeMap(
      (data: CompanyDeleteOrder) => this.companyService.deleteOrder(data.payload.company_code, data.payload.company_order_code)
        .pipe(
          map(() => new CompanyDeleteOrderSuccess({ company_order_code: data.payload.company_order_code })),
          catchError(error => of(new CompanyDeleteOrderFailure(error.error)))
        )
    )
  );

  @Effect()
  addCompanyOrder = this.actions$.pipe(ofType(CompanyActionTypes.COMPANY_ADD_ORDER),
    switchMap((orderData: CompanyAddOrder) =>
      this.companyService.addOrder(null, orderData.payload.company_code, orderData.payload.order_name,
        orderData.payload.notes, orderData.payload.amount, orderData.payload.start_date, orderData.payload.end_date,
        orderData.payload.start_free_period_date, orderData.payload.end_free_period_date, orderData.payload.currency_code,
        orderData.payload.order_owner_code, orderData.payload.order_status_code,orderData.payload.deal_type)
        .pipe(map((response: any) => {
          response = response.data;
          return new CompanyAddOrderSuccess(response);
        }),
          catchError(error =>
            of(new CompanyAddOrderFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompanyOrder = this.actions$.pipe(ofType(CompanyActionTypes.COMPANY_UPDATE_ORDER),
    switchMap((orderData: CompanyUpdateOrder) =>
      this.companyService.updateOrder(orderData.payload.company_order_code, orderData.payload.company_code, orderData.payload.order_name,
        orderData.payload.notes, orderData.payload.amount, orderData.payload.start_date,
        orderData.payload.end_date, orderData.payload.start_free_period_date, orderData.payload.end_free_period_date, orderData.payload.contact_code,
        orderData.payload.order_role_code, orderData.payload.drive_link, orderData.payload.company_order_drive_link_code, orderData.payload.company_order_contact_role_code, false,
        orderData.payload.currency_code, orderData.payload.order_owner_code, orderData.payload.order_status_code,orderData.payload.deal_type)
        .pipe(map((response: any) => {
          response = response.data;
          if (orderData.payload.page === 'company') {
            return new CompanyUpdateOrderSuccess({ order: response, page: orderData.payload.page });
          } else {
            return new CompanyUpdateSingleOrderSuccess({ order: response });
          }
        }),
          catchError(error =>
            of(new CompanyUpdateOrderFailure(error.error)))
        )
    )
  );

  @Effect()
  getSingleCompanyOrder = this.actions$.pipe(ofType(CompanyActionTypes.GET_SINGLE_COMPANY_ORDER),
    switchMap((orderData: GetSingleCompanyOrder) =>
      this.companyService.getSingleCompanyOrder(orderData.payload.company_order_code, orderData.payload.company_code)
        .pipe(map((response: any) => {
          response = response.data;
          return new GetSingleCompanyOrderSuccess({ company_order: response });
        }),
          catchError(error =>
            of(new GetSingleCompanyOrderFailure(error.error)))
        )
    )
  );

  @Effect()
  getCompanyOrderLineItem = this.actions$.pipe(ofType(CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM),
    switchMap((orderData: GetCompanyOrderLineItem) =>
      this.companyService.getCompanyOrderLineItem(orderData.payload.company_order_code, orderData.payload.page_size, orderData.payload.page_no)
        .pipe(map((response: any) => {
          response = response.data;
          return new GetCompanyOrderLineItemSuccess({ company_order: response });
        }),
          catchError(error =>
            of(new GetCompanyOrderLineItemFailure(error.error)))
        )
    )
  );

  @Effect()
  addCompanyOrderLineItem = this.actions$.pipe(ofType(CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM),
    switchMap((orderData: AddCompanyOrderLineItem) =>
      this.companyService.addCompanyOrderLineItem(orderData.payload.company_order_code, orderData.payload.order_item_code,
        orderData.payload.description, orderData.payload.amount, orderData.payload.quantity)
        .pipe(map((response: any) => {
          response = response.data;
          return new AddCompanyOrderLineItemSuccess({ company_order: response });
        }),
          catchError(error =>
            of(new AddCompanyOrderLineItemFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompanyOrderLineItem = this.actions$.pipe(ofType(CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM),
    switchMap((orderData: UpdateCompanyOrderLineItem) =>
      this.companyService.updateCompanyOrderLineItem(orderData.payload.order_line_item_code, orderData.payload.order_item_code,
        orderData.payload.description, orderData.payload.amount, orderData.payload.quantity)
        .pipe(map((response: any) => {
          response = response.data;
          return new UpdateCompanyOrderLineItemSuccess({ company_order: response, order_line_item_code: orderData.payload.order_line_item_code });
        }),
          catchError(error =>
            of(new UpdateCompanyOrderLineItemFailure(error.error)))
        )
    )
  );

  @Effect()
  deleteCompanyOrderLineItem = this.actions$.pipe(ofType(CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM),
    switchMap((orderData: DeleteCompanyOrderLineItem) =>
      this.companyService.deleteCompanyOrderLineItem(orderData.payload.order_line_item_code)
        .pipe(map((response: any) => {
          return new DeleteCompanyOrderLineItemSuccess({ order_line_item_code: orderData.payload.order_line_item_code });
        }),
          catchError(error =>
            of(new DeleteCompanyOrderLineItemFailure(error.error)))
        )
    )
  );

  @Effect()
  getCompanyOrderDeliverable = this.actions$.pipe(ofType(CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE),
    switchMap((orderData: GetCompanyOrderDeliverable) =>
      this.companyService.getCompanyOrderDeliverable(orderData.payload.company_order_code, orderData.payload.page_size, orderData.payload.page_no,orderData.payload.global)
        .pipe(map((response: any) => {
          response = response.data;
          return new GetCompanyOrderDeliverableSuccess({ company_order: response });
        }),
          catchError(error =>
            of(new GetCompanyOrderDeliverableFailure(error.error)))
        )
    )
  );

  @Effect()
  addCompanyOrderDeliverable = this.actions$.pipe(ofType(CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE),
    switchMap((orderData: AddCompanyOrderDeliverable) =>
      this.companyService.addCompanyOrderDeliverable(orderData.payload.company_order_code, orderData.payload.deliverable_item_code,
        orderData.payload.cost_center_code, orderData.payload.deliverable_status_code, orderData.payload.description,
        orderData.payload.start_date, orderData.payload.end_date, orderData.payload.deliverable_owner_code,
        orderData.payload.order_line_item_code, orderData.payload.is_external_provider_yn, orderData.payload.dates,
        orderData.payload.expiry_date, orderData.payload.article_code)
        .pipe(map((response: any) => {
          response = response.data;
          return new AddCompanyOrderDeliverableSuccess({ company_order: response });
        }),
          catchError(error =>
            of(new AddCompanyOrderDeliverableFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompanyDeliverable = this.actions$.pipe(ofType(CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE),
    switchMap((orderData: UpdateCompanyOrderDeliverable) =>
      this.companyService.updateCompanyOrderDeliverable(orderData.payload.deliverable_code, orderData.payload.deliverable_item_code,
        orderData.payload.cost_center_code, orderData.payload.deliverable_status_code, orderData.payload.description,
        orderData.payload.start_date, orderData.payload.end_date, orderData.payload.deliverable_owner_code,
        orderData.payload.order_line_item_code, orderData.payload.is_external_provider_yn, orderData.payload.dates,
        orderData.payload.expiry_date, orderData.payload.article_code, orderData.payload.deliverable_note)
        .pipe(map((response: any) => {
          response = response.data;
          return new UpdateCompanyOrderDeliverableSuccess({
            company_order: response,
            deliverable_code: orderData.payload.deliverable_code, page: orderData.payload.page
          });
        }),
          catchError(error =>
            of(new UpdateCompanyOrderDeliverableFailure(error.error)))
        )
    )
  );

  @Effect()
  updateUserSettings = this.actions$.pipe(ofType(CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION),
    switchMap((orderData: UpdateCompanyOrderColumnSelection) =>
      this.companyService.updateUserSettings(orderData.payload.setting_value, orderData.payload.setting_key_code)
        .pipe(map((response: any) => {
            // response = response.data;
            return new UpdateCompanyOrderColumnSelectionSuccess({
              setting_value: response.data.setting_value,
              setting_key_code: orderData.payload.setting_key_code
            });
          }),
          catchError(error =>
            of(new UpdateCompanyOrderColumnSelectionFailure(error.error)))
        )
    )
  );

  @Effect()
  deleteCompanyOrderDeliverable = this.actions$.pipe(ofType(CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE),
    switchMap((orderData: DeleteCompanyOrderDeliverable) =>
      this.companyService.deleteCompanyOrderDeliverable(orderData.payload.deliverable_code)
        .pipe(map((response: any) => {
          return new DeleteCompanyOrderDeliverableSuccess({ deliverable_code: orderData.payload.deliverable_code });
        }),
          catchError(error =>
            of(new DeleteCompanyOrderDeliverableFailure(error.error)))
        )
    )
  );

  @Effect()
  getCompanyOrderDeliverableNotes = this.actions$.pipe(ofType(CompanyActionTypes.GET_DELIVERABLE_NOTES),
    switchMap((orderData: GetDeliverableNotes) =>
      this.companyService.getDeliverableNotes(orderData.payload.deliverable_code, orderData.payload.page_size, orderData.payload.page_no)
        .pipe(map((response: any) => {
          response = response.data;
          return new GetDeliverableNotesSuccess({
            notes: response, deliverable_code: orderData.payload.deliverable_code,
            page: orderData.payload.page
          });
        }),
          catchError(error =>
            of(new GetDeliverableNotesFailure(error.error)))
        )
    )
  );

  @Effect()
  addCompanyOrderDeliverableNotes = this.actions$.pipe(ofType(CompanyActionTypes.ADD_DELIVERABLE_NOTES),
    switchMap((orderData: AddDeliverableNotes) =>
      this.companyService.addDeliverableNotes(orderData.payload.deliverable_code, orderData.payload.note)
        .pipe(map((response: any) => {
          response = response.data;
          return new AddDeliverableNotesSuccess({
            notes: response, deliverable_code: orderData.payload.deliverable_code,
            page: orderData.payload.page
          });
        }),
          catchError(error =>
            of(new AddDeliverableNotesFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompanyOrderDeliverableNotes = this.actions$.pipe(ofType(CompanyActionTypes.UPDATE_DELIVERABLE_NOTES),
    switchMap((orderData: UpdateDeliverableNotes) =>
      this.companyService.updateDeliverableNotes(orderData.payload.deliverable_note_code, orderData.payload.note)
        .pipe(map((response: any) => {
          response = response.data;
          return new UpdateDeliverableNotesSuccess({
            note: response,
            deliverable_note_code: orderData.payload.deliverable_note_code, deliverable_code: orderData.payload.deliverable_code
          });
        }),
          catchError(error =>
            of(new UpdateDeliverableNotesFailure(error.error)))
        )
    )
  );

  @Effect()
  deleteCompanyOrderDeliverableNotes = this.actions$.pipe(ofType(CompanyActionTypes.DELETE_DELIVERABLE_NOTES),
    switchMap((orderData: DeleteDeliverableNotes) =>
      this.companyService.deleteDeliverableNotes(orderData.payload.deliverable_note_code)
        .pipe(map((response: any) => {
          response = response.data;
          return new DeleteDeliverableNotesSuccess({
            deliverable_note_code: orderData.payload.deliverable_note_code,
            deliverable_code: orderData.payload.deliverable_code
          });
        }),
          catchError(error =>
            of(new DeleteDeliverableNotesFailure(error.error)))
        )
    )
  );

  @Effect()
  getCompanyOrderHistory = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_ORDER_HISTORY),
    switchMap((data: GetCompanyOrderHistory) => this.companyService.getCompanyOrderHistory(data.payload.company_order_code).pipe(
      map((response: any) => new GetCompanyOrderHistorySuccess({ company_order_code: data.payload.company_order_code, history: response.data })),
      catchError(error => of(new GetCompanyOrderHistoryFailure(error.error)))
    ))
  );

  @Effect()
  getFilteredCompanyOrders = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS),
    switchMap((data: CompanyOrderFilter) => this.companyService.getAllFilteredOrders(data.payload).pipe(
      map((response: any) => {
        response.data = { ...response.data, page: data.payload.page };
        // response = [...response.data, page: data.payload.page];
        return new CompanyOrderFilterSuccess(response.data);
      }),
      catchError(error => of(new CompanyOrderFilterFailure(error.error)))
    ))
  );

  @Effect()
  getFilteredCompanyOrderDeliverable = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS),
    switchMap((data: CompanyOrderDeliverablesFilter) => this.companyService.getAllFilteredOrderDeliverables(data.payload).pipe(
      map((response: any) => {
        // response.data = {...response.data, page: data.payload.page};
        // response = [...response.data, page: data.payload.page];
        return new CompanyOrderDeliverablesFilterSuccess(response.data);
      }),
      catchError(error => of(new CompanyOrderDeliverablesFilterFailure(error.error)))
    ))
  );

  @Effect()
  getFilteredCompanyOrderLineItems = this.actions$.pipe(
    ofType(CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS),
    switchMap((data: CompanyOrderLineItemsFilter) => this.companyService.getAllFilteredOrderLineItems(data.payload).pipe(
      map((response: any) => {
        return new CompanyOrderLineItemsFilterSuccess(response.data);
      }),
      catchError(error => of(new CompanyOrderLineItemsFilterFailure(error.error)))
    ))
  );

  @Effect()
  updateCompanyGeneral = this.actions$.pipe(
    ofType(CompanyActionTypes.UPDATE_COMPANY_GENERAL),
    mergeMap(
      (data: CompanyUpdateGeneral) => this.companyService.updateCompanyGeneral(data.payload.company_code, data.payload.description,
        data.payload.socialMedia, data.payload.external_tag, data.payload.internal_tag)
        .pipe(
          map((response: any) => {
            return new CompanyUpdateGeneralSuccess({
              company: response.data
            });
          }),
          catchError(error => of(new CompanyUpdateGeneralFailure(error.error)))
        )
    )
  );

  @Effect()
  loadCompanyGeneral = this.actions$.pipe(
    ofType(CompanyActionTypes.LOAD_COMPANY_GENERAL),
    mergeMap(
      (data: CompanyLoadGeneral) => this.companyService.loadCompanyGeneral(data.payload.company_code)
        .pipe(
          map((response: any) => {
            return new CompanyLoadGeneralSuccess({

              company: response.data
            });
          }),
          catchError(error => of(new CompanyLoadGeneralFailure(error.error)))
        )
    )
  );

  @Effect()
  loadCompanyLTDSettings = this.actions$.pipe(
    ofType(CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS),
    mergeMap(
      (data: CompanyLoadLTDSettings) => this.companyService.loadCompanyLTDSettings(data.payload.company_code)
        .pipe(
          map((response: any) => {
            return new CompanyLoadLTDSettingsSuccess({
              company: response.data
            });
          }),
          catchError(error => of(new CompanyLoadLTDSettingsFailure(error.error)))
        )
    )
  );

  @Effect()
  updateCompanyLTDSettings = this.actions$.pipe(
    ofType(CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS),
    mergeMap(
      (data: CompanyUpdateLTDSettings) => this.companyService.updateCompanyLTDSettings(data.payload, data.payload.company_code)
        .pipe(
          map((response: any) => {
            return new CompanyUpdateLTDSettingsSuccess({

              company: response.data
            });
          }),
          catchError(error => of(new CompanyUpdateLTDSettingsFailure(error.error)))
        )
    )
  );

  constructor(private actions$: Actions, private companyService: CompanyService, private activityService: ActivityService) {
  }
}
