import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/core';
import {AddArticleCollection, UpdateArticleCollection} from 'src/app/core/store/actions/article.actions';
import {Query} from '@syncfusion/ej2-data';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {CollectionService} from 'src/app/core/store/services/collection.service';
import * as _ from 'lodash';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-article-collection-form',
  templateUrl: './article-collection.component.html',
  styleUrls: ['./article-collection.component.scss']
})
export class ArticleCollectionComponent implements OnInit, OnChanges {

  @Input() collectionList: any = [];
  @Input() articleCode: string;
  @Input() selectedCollectionList = [];
  @Output() valueChange = new EventEmitter();
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<any>;

  public collectionFields: object = {value: 'collection_code', text: 'collection_name'};


  articleCollectionForm: FormGroup;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private collectionService: CollectionService) {
  }

  ngOnInit(): void {
    this.generateArticleCollectionForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.valueChange.emit('success');
        this.closeSidebar();
      }
    });

  }

  generateArticleCollectionForm() {
    this.articleCollectionForm = this.formBuilder.group({
      collection_code: new FormControl('')
    });
  }

  onAddArticleCollectionLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleCollection({article_code: this.articleCode, collection_code: form.value.collection_code}));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.articleCollectionForm.reset();
    this.sidebarInstance.hide();
  }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    const collectionList = [];

    // if ((this.projectCompanyRoleForm && this.projectCompanyRoleForm.controls.company_code.value !== null &&
    //   this.projectCompanyRoleForm.controls.company_code.value !== '') || this.projectSourceForm) {
    query = (e.text !== '') ? query.where('collection_name', 'contains', e.text, true) : query;
    const contactfilterParams = {search: e.text, page_size: 40, page_no: 1};
    this.collectionService.getSearchResults(contactfilterParams).subscribe((data) => {
      if (data && data.data) {
        if (data.data.result) {
          for (const collection of data.data.result) {
            const checkPub = collection.publish_status !== 'publish' ? ' [NP]' : ' [P]';
            collectionList.push({...collection, collection_name: collection.collection_name + checkPub});
          }
          // this.collectionList = _.clone(collectionList);
          e.updateData(_.clone(collectionList), query);
        }
      }
    });

    // }

  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCollectionList) {
      this.searchAndSetCompany(this.selectedCollectionList);

    }
  }

  searchAndSetCompany(collections) {
    let selectedCollectionList = [];
    let collectionList = [];

    if (collections && collections.length > 0) {
      for (const collection of collections) {
        let selectedCollectionName = collection.collection_name;
        if (selectedCollectionName) {
          let isCollectionExistsInList = this.collectionList.filter(el => el.collection_name === selectedCollectionName).length > 0;
          if (!isCollectionExistsInList) {
            const checkPub = collection.publish_status !== 'publish' ? ' [NP]' : ' [P]';

            collectionList.push({...collection, collection_name: collection.collection_name + checkPub});


          }

          selectedCollectionList.push(collection.collection_code);
        }

      }

      this.collectionList.forEach(el => collectionList.push(el));
      // this.dropdownObj.dataSource = collectionList;
      this.collectionList = [...this.collectionList, ...collectionList];
    }

    if (this.articleCollectionForm) {
      this.articleCollectionForm.controls.collection_code.setValue(selectedCollectionList);
    }

  }
  clearAll(){
    this.clearModal.hide()
  this.articleCollectionForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
