import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {SourceType} from '../../../../../core/models/source-types.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {SourceTypeAdd, SourceTypeUpdate} from '../../../../../core/store/actions/source-type.actions';
import {DeliverableItem} from '../../../../../core/models/deliverable-item.model';
import {AddDeliverableItems, MetaDataResetError, UpdateDeliverableItems} from '../../../../../core/store/actions/common.actions';
import {trimValidator} from '../../../../../app-validators';
import {Title} from '@angular/platform-browser';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-deliverable-item-form',
  templateUrl: './deliverable-item.component.html',
  styleUrls: ['./deliverable-item.component.scss']
})
export class DeliverableItemComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;

  @Input() parentDeliverableItems: any;
  @Input() deliverableDateList: any;
  @Output() valueChange = new EventEmitter();
  deliverableItemForm: FormGroup;
  public deliverableItemFields: object;


  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public deliverableDateFields = {value: 'deliverable_item_date_code', text: 'deliverable_item_date_name'};
  public typeFields = {value: 'type_code', text: 'type_name'};

  type: any = [
    {
      type_code: 'article',
      type_name: 'Article'
    },
  ];

  public _deliverableInputInput$: BehaviorSubject<DeliverableItem | null> = new BehaviorSubject<DeliverableItem | null>(null);

  @Input() set deliverableItemInput(mode: DeliverableItem | null) {
    this._deliverableInputInput$.next(mode);
  }

  get deliverableItemInput(): DeliverableItem | null {
    return this._deliverableInputInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Deliverable Item - Canvas');

    if (!this.deliverableItemForm) {
      this.generateDeliverableItemForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateDeliverableItemForm() {
    this.deliverableItemForm = this.formBuilder.group({
      deliverable_item_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      description: new FormControl('', [ Validators.maxLength(200)]),
      deliverable_item_parent_code: new FormControl(''),
      deliverable_item_date: new FormControl(''),
      type: new FormControl('')
    });
  }

  onAddUpdateDeliverableInput(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddDeliverableItems({deliverable_name: form.value.deliverable_item_name,
          deliverable_item_parent_code: form.value.deliverable_item_parent_code ? form.value.deliverable_item_parent_code[0] : null,
          description: form.value.description, deliverable_item_date_code: form.value.deliverable_item_date, type: form.value.type}));
      } else {
        this.store.dispatch(new UpdateDeliverableItems({deliverable_name: form.value.deliverable_item_name,
          deliverable_code: this._deliverableInputInput$.getValue().deliverable_item_code,
          deliverable_item_parent_code: form.value.deliverable_item_parent_code ? form.value.deliverable_item_parent_code[0] : null,
          description: form.value.description, deliverable_item_date_code: form.value.deliverable_item_date, type: form.value.type}));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.deliverableItemForm.reset();
    this.sidebarInstance.hide();
    this.deliverableItemForm.controls.deliverable_item_parent_code.setValue('');

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentDeliverableItems) {
      this.deliverableItemFields = {
        dataSource: this.parentDeliverableItems,
        value: 'deliverable_item_code',
        text: 'deliverable_item_name',
        child: 'children'
      };
    }

    if (!this.deliverableItemForm) {
      this.generateDeliverableItemForm();
    }

    if (this._deliverableInputInput$.getValue()) {

      const deliverable_item_date_array = [];
      if (this._deliverableInputInput$.getValue().deliverable_item_date && this._deliverableInputInput$.getValue().deliverable_item_date.length > 0) {
        for (const deliverable_item_date of this._deliverableInputInput$.getValue().deliverable_item_date) {
          deliverable_item_date_array.push(deliverable_item_date.deliverable_item_date_code);
        }
      }


      this.deliverableItemForm.setValue({
        deliverable_item_name: this._deliverableInputInput$.getValue().deliverable_item_name,
        description: this._deliverableInputInput$.getValue().description ?  this._deliverableInputInput$.getValue().description : null,
        deliverable_item_parent_code: this._deliverableInputInput$.getValue().parent ? [this._deliverableInputInput$.getValue().parent] : '',
        deliverable_item_date: deliverable_item_date_array ? deliverable_item_date_array : null,
        type: this._deliverableInputInput$.getValue().type ? this._deliverableInputInput$.getValue().type : '',

      });
    }
  }

  setParentValue(args, form: FormGroup) {
    if (args && args.length === 0) {
      form.controls.deliverable_item_parent_code.setValue(args);
    }
  }
  clearAll(){
    this.clearModal.hide()
  this.deliverableItemForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
