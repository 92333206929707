import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import * as moment from 'moment';
import {ProjectActivityFilter, ProjectGetActivity, ProjectSetStatusNull} from '../../../../../core/store/actions/project.actions';
import {LoadTags} from '../../../../../core/store/actions/common.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {
  ContactActivityFilter,
  ContactFilter,
  ContactSetStatusNull,
  GetContactActivity
} from '../../../../../core/store/actions/contact.actions';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Router} from '@angular/router';
import {CompanyActivityFilter, CompanySetStatusNull, GetCompanyActivity} from '../../../../../core/store/actions/company.actions';
import {EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import * as _ from 'lodash';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-activity-filter',
  templateUrl: './activity-filter.component.html',
  styleUrls: ['./activity-filter.component.scss']
})
export class ActivityFilterComponent implements OnInit {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('tags') activityTag: MultiSelectComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @Input() selectedCode: string;
  @Input() typeActivity: object;
  @Input() users: any;
  @Input() participantList: Array<any> = [];
  @Input() allTags: Observable<Array<Tags>>;
  @Input() activityEntity: string;

  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  filterForm: FormGroup;
  formSent: boolean;
  isReset = false;
  allowEdit = false;

  nullAction: any;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public tagModelData = [];
  modelData = [];
  selectedTag = [];
  tagSelected = '';

  public participantsFields = {value: 'contact_code', text: 'name'};
  public userFields = {value: 'user_code', text: 'name'};
  activityTypeFields: object = {value: 'activity_type_code', text: 'activity_type_name'};
  tagFields: object = {value: 'tag', text: 'tag'};

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.generateFilterForm();

    this.allTags.subscribe(data =>{
      this.tagModelData = data;
    });

    switch (this.activityEntity) {
      case 'project':
        this.success$ = this.store.pipe(select(store => store.projects.success));
        this.error$ = this.store.pipe(select(store => store.projects.error));
        this.nullAction = new ProjectSetStatusNull();
        break;
      case 'contact':
        this.success$ = this.store.pipe(select(store => store.contacts.success.isLoaded));
        this.error$ = this.store.pipe(select(store => store.contacts.error));
        this.nullAction = new ContactSetStatusNull();
        break;

      case 'company':
        this.success$ = this.store.pipe(select(store => store.companies.success.isLoaded));
        this.error$ = this.store.pipe(select(store => store.companies.error));
        this.nullAction = new CompanySetStatusNull();
        break;
    }

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isReset) {
          this.closeSidebar();
        }
        this.isReset = false;
        this.store.dispatch(this.nullAction);
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.errorMsg = data.error;
        this.formSent = false;
      }
    });
  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      activity_title: new FormControl(null),
      activity_type_code: new FormControl(null),
      meeting_date: new FormControl(''),
      activity_participant_code: new FormControl(''),
      user_code: new FormControl(''),
      activity_tag: new FormControl(''),
      created_at: new FormControl(null),
      updated_at: new FormControl(null),
    });
  }

  getFilteredData() {
    let filterValues = {...this.filterForm.value};
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);
    if (filterValues.meeting_date) {
      filterValues.meeting_date_end = moment(filterValues.meeting_date[1]).format('YYYY-MM-DD');
      filterValues.meeting_date_start = moment(filterValues.meeting_date[0]).format('YYYY-MM-DD');
    }
    delete filterValues.meeting_date;

    if (filterValues.created_at) {
      filterValues.created_at_end = moment(filterValues.created_at[1]).format('YYYY-MM-DD');
      filterValues.created_at_start = moment(filterValues.created_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.created_at;

    if (filterValues.updated_at) {
      filterValues.updated_at_end = moment(filterValues.updated_at[1]).format('YYYY-MM-DD');
      filterValues.updated_at_start = moment(filterValues.updated_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }

    this.router.navigate([], {queryParams: filterValues});
    switch (this.activityEntity) {
      case 'contact':
        filterValues = {filterValues, entity: 'contact', code: this.selectedCode};
        this.store.dispatch(new ContactActivityFilter(filterValues));
        break;
      case 'company':
        filterValues = {filterValues, entity: 'company', code: this.selectedCode};
        this.store.dispatch(new CompanyActivityFilter(filterValues));
        break;
      case 'project':
        filterValues = {filterValues, entity: 'project', code: this.selectedCode};
        this.store.dispatch(new ProjectActivityFilter(filterValues));
        break;
    }
    // this.store.dispatch(new ProjectActivityFilter(filterValues));
    this.formSent = true;
  }

  clearForm() {
    this.isReset = true;
    this.filterForm.reset();
    switch (this.activityEntity) {
      case 'contact':
        this.store.dispatch(new GetContactActivity({contactCode: this.selectedCode, entity: this.activityEntity}));
        break;
      case 'company':
        this.store.dispatch(new GetCompanyActivity({companyCode: this.selectedCode, entity: this.activityEntity}));
        break;
      case 'project':
        this.store.dispatch(new ProjectGetActivity({project_code: this.selectedCode, entity: this.activityEntity}));
        break;
    }
    // this.store.dispatch(new ProjectGetActivity({entity: 'list-project'}));
    this.formSent = true;
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  public onFiltering: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'contact') {
      query = (e.text !== '') ? query.where('name', 'startswith', e.text, true) : query;
      const filterParams = {contact_name: e.text, page_size: 40, page_no: 1};
      this.store.dispatch(new ContactFilter(filterParams));
      this.store.select(store => store.contacts.contacts).subscribe((data) => {
        if (data) {
          const companyContactList = [];
          for (const contact of data.result) {

            let company = '';
            if (contact.company) {
              company = ' - ' + contact.company.company_name;
            } else {
              company = '';
            }
            let result = {
              contact_code: contact.contact_code,
              name: (contact.first_name + ' ' + contact.last_name + company),
            };

            if (contact.company) {
              result = {...result, ...{company_code: contact.company.company_code, company_name: contact.company.company_name}};
            }
            companyContactList.push(result);
          }
          e.updateData(companyContactList, query);
        }
      });
    }
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if(!this.modelData.includes(this.tagSelected)) {
      this.activityTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.activity_tag.setValue( _.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);
    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  }

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }
  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if ( tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }
  open(args){
    
  }

  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
