import { projectSocialType } from "../../models/admin/projectSocialType.model";
import { projectSocialTypeActionTypes,projectSocialsTypeActions } from "../actions/projectSocialType.action";
import * as _ from 'lodash';
export interface projectSocialTypeState {
    
   
    projectSocialType?:{
        result:projectSocialType[] ;}
    loading: boolean;
    loaded: boolean;
    error?: Error;
    success?: boolean;
    
}
export const initialState: projectSocialTypeState = {
    loading: false,
    loaded: false,
  };
  export const ReturnLoading = (State: projectSocialTypeState) => {
    return {
      ...State,
      loading: true,
      loaded: false,
      error: undefined,
      success: undefined,
    };
  };
  export function projectSocialTypeReducer(state: projectSocialTypeState = initialState, action: projectSocialsTypeActions): projectSocialTypeState {
    switch (action.type) {

        case projectSocialTypeActionTypes.LOAD_PROJECT_SOCIAL_TYPE: {
            return ReturnLoading(state);
          }
      
          case projectSocialTypeActionTypes.LOAD_PROJECT_SOCIAL_TYPE_SUCCESS: {
            return {
              ...state,
              projectSocialType: {
                result: action.payload.result
              },
              loading: false,
              error: undefined,
              loaded: true
            };
          }

        case projectSocialTypeActionTypes.LOAD_PROJECT_SOCIAL_TYPE_FAILURE: {
            return { ...state, loading: false, error: action.payload, loaded: false };
          }
      
          case projectSocialTypeActionTypes.ADD_PROJECT_SOCIAL_TYPE: {
            return ReturnLoading(state);
          }
      
          case projectSocialTypeActionTypes.ADD_PROJECT_SOCIAL_TYPE_SUCCESS: {
            let array = [action.payload.projectSocialsTypes, ...state.projectSocialType.result];
            array = _.orderBy(array, 'rank_order', 'asc');
            return {
              ...state,
              projectSocialType: { result: array },
              loading: false,
              error: undefined,
              loaded: true,
              success: true
            };
          }
      
          case projectSocialTypeActionTypes.ADD_PROJECT_SOCIAL_TYPE_FAILURE: {
            return { ...state, loading: false, error: action.payload, loaded: false };
          }
      
          case projectSocialTypeActionTypes.UPDATE_PROJECT_SOCIAL_TYPE: {
            return ReturnLoading(state);
          }
      
          case projectSocialTypeActionTypes.UPDATE_PROJECT_SOCIAL_TYPE_SUCCESS: {
            const projectSocialDateIndex = state.projectSocialType.result.findIndex(x => x.social_date_code === action.payload.projectSocialType. social_date_code);
            const projectSocialDate = state.projectSocialType[projectSocialDateIndex];
            const updatedProjectSocialDate = { ...projectSocialDate, ...action.payload.projectSocialType };
            const updatedProjectSocialDates = [...state.projectSocialType.result];
      
            const index = updatedProjectSocialDates.findIndex(x => x. social_date_code === action.payload.projectSocialType.social_date_code);
            updatedProjectSocialDates[index] = updatedProjectSocialDate;
      
      
            const deliverableItemDateResult = { ...state.projectSocialType };
            deliverableItemDateResult.result = _.orderBy(updatedProjectSocialDates, 'rank_order', 'asc');
            return {
              ...state, projectSocialType: deliverableItemDateResult, error: undefined, loading: false, loaded: true,
              success: true
            };
          }
      
          case projectSocialTypeActionTypes.UPDATE_PROJECT_SOCIAL_TYPE_FAILURE:
            return { ...state, error: action.payload, loading: false, loaded: false };
            default:
                return state;
    }}