<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!articleDateInput">Add Article Date</h2>
    <h2 *ngIf="articleDateInput">Update Article Date</h2>

    <form id="formId" [formGroup]="articleDateForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="article_date_name" placeholder="Article Date Name *" cssClass="e-filled"
                     formControlName="article_date_name" floatLabelType="Auto"
                     (blur)="articleDateForm.controls.article_date_name.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="articleDateForm.controls.article_date_name.errors">
          <div
            *ngIf="articleDateForm.controls.article_date_name.errors.required && articleDateForm.controls.article_date_name.touched"
            class="e-error">
            Article Date Name is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <label>Filter Color</label>
        <input ejs-colorpicker id="inline-color-picker" type="color" [modeSwitcher]=false  [showButtons]=false
               formControlName="filter_color" placeholder="Filter Color" cssClass="e-filled"  />
        <div *ngIf="articleDateForm.controls.filter_color.errors">
          <div
            *ngIf="articleDateForm.controls.filter_color.errors.required && articleDateForm.controls.filter_color.touched"
            class="e-error">
            Filter color is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <label>Filter Text Color</label>
        <input ejs-colorpicker id="inline-color-picker-text" type="color" [modeSwitcher]=false  [showButtons]=false
               formControlName="filter_text_color" placeholder="Filter Color" cssClass="e-filled"  />
        <div *ngIf="articleDateForm.controls.filter_text_color.errors">
          <div
            *ngIf="articleDateForm.controls.filter_text_color.errors.required && articleDateForm.controls.filter_text_color.touched"
            class="e-error">
            Filter text color is required.
          </div>
        </div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="short_code" placeholder="Short Code *" cssClass="e-filled"
                     formControlName="short_code" floatLabelType="Auto"
                     (blur)="articleDateForm.controls.short_code.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="articleDateForm.controls.short_code.errors">
          <div
            *ngIf="articleDateForm.controls.short_code.errors.required && articleDateForm.controls.short_code.touched"
            class="e-error">
            Short Code is required.
          </div>
          <div *ngIf="articleDateForm.controls.short_code.errors.maxlength && articleDateForm.controls.short_code.touched"
               class="e-error">
            Short Code cannot be more than 6 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">

        <ejs-numerictextbox id="rank_order" #rankOrder
                            formControlName="rank_order" floatLabelType="Auto" strictMode="true" validateDecimalOnType="true"
                            cssClass="e-filled" placeholder="Rank" format='n0' min="1" [max]="maxValue"
        ></ejs-numerictextbox>
        <div *ngIf="articleDateForm.controls.rank_order.errors">
          <div
            *ngIf="articleDateForm.controls.rank_order.errors.required && articleDateForm.controls.rank_order.touched"
            class="e-error">
            Rank is required.
          </div>
        </div>
      </div>


      <div *ngIf="articleDateForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button"
                data-ripple="true" (click)="clear()">Clear
        </button>
        <button *ngIf="!articleDateInput" id="addSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateArticleDate(articleDateForm); articleDateForm.markAllAsTouched()" [disabled]="formSent" data-ripple="true"> Add
          Article Date
        </button>
        <button *ngIf="articleDateInput" id="updateSubmit"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddUpdateArticleDate(articleDateForm, true)" [disabled]="formSent" data-ripple="true">
          Update Article Date
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
