import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { CommunityProductUploadComponent } from '../community-product-upload/community-product-upload.component';

@Component({
  selector: 'app-community-product-edit',
  templateUrl: './community-product-edit.component.html',
  styleUrls: ['./community-product-edit.component.scss']
})
export class CommunityProductEditComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  
  }
  
 
}
