import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/core';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {ContactService} from 'src/app/core/store/services/contact.service';
import * as _ from 'lodash';
import {AddArticleContact, UpdateArticleContact} from 'src/app/core/store/actions/article.actions';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';


@Component({
  selector: 'app-article-contact-form',
  templateUrl: './article-contact.component.html',
  styleUrls: ['./article-contact.component.scss']
})
export class ArticleContactComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @Input() contactList: any = [];
  @Input() articleCode: string;
  @Input() selectedContactList = [];
  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];

  public formSent: boolean;

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  public contactFields: object = {value: 'value', text: 'name'};


  articleContactForm: FormGroup;
  success$: Observable<any>;


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.generateArticleContactForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
      }
    });


    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.valueChange.emit('success');
        this.closeSidebar();
      }
    });

  }

  generateArticleContactForm() {
    this.articleContactForm = this.formBuilder.group({
      company_contact_code: new FormControl('')
    });
  }

  onAddArticleContactLink(form: FormGroup) {
    if (!form.invalid) {
      this.store.dispatch(new UpdateArticleContact({
        article_code: this.articleCode,
        company_contact_code: form.value.company_contact_code
      }));
      this.formSent = true;
    }

  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();

    // if ((this.projectCompanyRoleForm && this.projectCompanyRoleForm.controls.company_code.value !== null &&
    //   this.projectCompanyRoleForm.controls.company_code.value !== '') || this.projectSourceForm) {
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
    this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
      if (data && data.data) {
        const companyContactList = [];
        if (data.data.result) {
          for (const contact of data.data.result) {
            const checkPub = contact.is_published_yn === 0 ? ' [NP]' : ' [P]';

            if (contact.associated_companies) {
              for (const associatedCompany of contact.associated_companies) {
                let company = '';
                let company_code = '';
                const company_contact_code = _.clone(associatedCompany.company_contact_code);

                if (associatedCompany.company) {
                  company = ' - ' + associatedCompany.company.company_name;
                  company_code = associatedCompany.company.company_code;
                } else {
                  company = ' (' + associatedCompany.email + ')';
                  company_code = '';
                }

                const result = {
                  value: company_contact_code,
                  name: (contact.first_name + ' ' + contact.last_name + company + checkPub),
                  company_code, contact_code: contact.contact_code
                };
                companyContactList.push(result);
              }
            }
          }
          // this.contactList = companyContactList;
          e.updateData(companyContactList, query);
        }
      }
    });

    // }

  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedContactList) {
      this.searchAndSetContact(this.selectedContactList);

    }
  }

  searchAndSetContact(contacts) {
    let selectedContactList = [];
    const contactList: any = [];
    if (contacts && contacts.length > 0) {
      for (const contact of contacts) {
        const checkPub = contact.contact.is_published_yn === 0 ? ' [NP]' : ' [P]';

        let selectedName = contact.contact.first_name ? contact.contact.first_name : null;
        if (selectedName) {
          const isContactExistsInList = this.contactList.filter((el: any) => el.name === selectedName).length > 0;
          if (!isContactExistsInList) {
            let company;
            company = '';

            if (contact.company && contact.company.company_name) {
              company = ' - ' + contact.company.company_name;
            }

            const result = {
              value: contact.company_contact_code,
              name: (contact.contact.first_name + ' ' + contact.contact.last_name + company + checkPub),
            };
            contactList.push(result);

            // this.contactDropdown.dataSource = contactList;
          }
          selectedContactList.push(contact.company_contact_code);
        }
      }
      this.contactList.forEach(el => contactList.push(el));
      this.contactList = [...this.contactList, ...contactList];
    }

    if (this.articleContactForm) {
      this.articleContactForm.controls.company_contact_code.setValue(selectedContactList);
    }


  }
  clearAll(){
    this.clearModal.hide()
    this.articleContactForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
