<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
  style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
      (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <div class="p-15">
    <h2 *ngIf="!articleInput">Add Article </h2>
    <h2 *ngIf="articleInput">Update Article </h2>

    <ul class="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x mb-15" role="tablist">
      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'article'" (click)="setTab('article')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'article'">
          <span class="nav-text">Article</span>

        </a>
      </li>

      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'dates'" (click)="setTab('dates')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'dates'">
          <span class="nav-text">Dates</span>
        </a>
      </li>
    </ul>

    <form id="formId" [formGroup]="articleForm" #formDir="ngForm" class="form-horizontal" novalidate=""
      [ngClass]="selectedTab === 'article' ? 'show' : 'hide'">


      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist id='industryTypePrimary' formControlName="article_type_code" [dataSource]='articleTypes'
          floatLabelType="Auto" [fields]='articleTypeFields' cssClass="e-filled" sortOrder="Ascending"
          placeholder='Article Type *' [allowFiltering]='true'
          (blur)="articleForm.controls.article_type_code.markAsTouched()">
        </ejs-dropdownlist>
        <div *ngIf="articleForm.controls.article_type_code.errors">
          <div
            *ngIf="articleForm.controls.article_type_code.errors.required && articleForm.controls.article_type_code.touched"
            class="e-error">
            Please select an article type
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="article_name" placeholder="Article Name *" cssClass="e-filled" formControlName="article_name"
          floatLabelType="Auto"
          (blur)="articleForm.controls.article_name.markAsTouched(); getSlug(articleForm.value.article_name, articleForm)">
        </ejs-textbox>
        <div *ngIf="articleForm.controls.article_name.invalid">
          <div *ngIf="articleForm.controls.article_name.errors.required && articleForm.controls.article_name.touched "
            class="e-error">
            Please enter an article name
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="slug" placeholder="Slug *" cssClass="e-filled" formControlName="slug" floatLabelType="Auto"
          (blur)="articleForm.controls.slug.markAsTouched()"></ejs-textbox>
        <div *ngIf="articleForm.controls.slug.invalid">
          <div *ngIf="articleForm.controls.slug.errors.required && articleForm.controls.slug.touched " class="e-error">
            Please enter a slug
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10"
        [class.hide-label]="articleForm.controls.article_category_code.value == ''">
        <ejs-dropdowntree id='article_category_code' formControlName="article_category_code" #industryType
          [dataSource]='articleCategory' placeholder='Article Category *' [fields]='articleCategoryFields'
          floatLabelType="Auto" cssClass="e-filled" [showDropDownIcon]='true' [showSelectAll]='false'
          sortOrder="Ascending" [showCheckBox]="true" (change)="setCompanyType($event, articleForm)"
          [allowFiltering]=false (blur)="articleForm.controls.article_category_code.markAsTouched()"
          [showClearButton]=true filterBarPlaceholder='Search'></ejs-dropdowntree>
        <div *ngIf="articleForm.controls.article_category_code.invalid">
          <div
            *ngIf="articleForm.controls.article_category_code.errors.required && articleForm.controls.article_category_code.touched "
            class="e-error">
            Please select an article category
          </div>
        </div>
      </div>



      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist id='primary_article_category_code' formControlName="primary_article_category_code"
          [dataSource]='selectedArticleCategoriesObs|async' floatLabelType="Auto"
          [fields]='primaryArticleCategoryFields' cssClass="e-filled" sortOrder="Ascending"
          placeholder='Primary Article Category *' [allowFiltering]='true' (change)="setPrimaryCategory($event)"
          (blur)="articleForm.controls.primary_article_category_code.markAsTouched()">
        </ejs-dropdownlist>
        <div *ngIf="articleForm.controls.primary_article_category_code.errors">
          <div
            *ngIf="articleForm.controls.primary_article_category_code.errors.required && articleForm.controls.primary_article_category_code.touched"
            class="e-error">
            Please select a primary article category
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-dropdownlist id='article_stage' formControlName="article_stage_code" [dataSource]='articleStage'
          floatLabelType="Auto" [fields]='articleStageFields' cssClass="e-filled" sortOrder="Ascending"
          placeholder='Article Stage *' [allowFiltering]='true'
          (blur)="articleForm.controls.article_stage_code.markAsTouched()">
        </ejs-dropdownlist>
        <div *ngIf="articleForm.controls.article_stage_code.errors">
          <div
            *ngIf="articleForm.controls.article_stage_code.errors.required && articleForm.controls.article_stage_code.touched"
            class="e-error">
            Please select an article stage
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="excerpt" [multiline]="true" placeholder="Excerpt *" cssClass="e-filled"
          formControlName="excerpt" floatLabelType="Auto" (blur)="articleForm.controls.excerpt.markAsTouched()">
        </ejs-textbox>
        <div *ngIf="articleForm.controls.excerpt.errors">
          <div *ngIf="articleForm.controls.excerpt.errors.required && articleForm.controls.excerpt.touched"
            class="e-error">
            Excerpt is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10" >
        <ejs-dropdownlist id='assigned_to_user' formControlName="assigned_to_user" [dataSource]='users' #user
          floatLabelType="Auto" [fields]='userFields' cssClass="e-filled" placeholder='Assigned To *'
          [allowFiltering]='true' (blur)="articleForm.controls.assigned_to_user.markAsTouched()"
          (open)="Complete($event)" (beforeOpen)=" beforeOpen()">
          <ng-template #itemTemplate="" let-data style="display: inline-block">
            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
              <span [ngStyle]="{'color':'black'}">{{data.first_name}}</span>
            </div>
            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1">
              <span [ngStyle]="{'color':'#d3d3d3'}" id="grey">{{data.first_name}}</span>
            </div>
          </ng-template>
        </ejs-dropdownlist>

        <div *ngIf="articleForm.controls.assigned_to_user.errors">
          <div
            *ngIf="articleForm.controls.assigned_to_user.errors.required && articleForm.controls.assigned_to_user.touched"
            class="e-error">
            Please select a user
          </div>
        </div>

      </div>

      <div *ngIf="articleForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>




    </form>

    <form [formGroup]="articleDateForm" #formDir="ngForm" [ngClass]="selectedTab === 'dates' ? 'show' : 'hide'"
      class="form-horizontal" novalidate="">



      <div class="roww mxx-2">
        <!--        <div class="px-0 col-4">-->

        <div *ngFor="let deliverableItemDate of deliverableItemDates">
          <div class="d-flex" *ngIf="findDeliverableDateFromArray(deliverableItemDate.deliverable_item_date_code)">


            <div class="form-group sf-outline-input mx-3 mr-4">
              <ejs-datetimepicker [formControlName]="strReplace(deliverableItemDate.deliverable_item_date_code)"
                [allowEdit]=false
                (change)="onDateChange($event, strReplace(deliverableItemDate.deliverable_item_date_code), articleDateForm)"
                (blur)="articleDateForm.controls[strReplace(deliverableItemDate.deliverable_item_date_code)].markAsTouched()"
                [placeholder]='deliverableItemDate.deliverable_item_date_name' floatLabelType='Auto'
                cssClass="e-filled">
              </ejs-datetimepicker>
              <div *ngIf="articleDateForm.controls[strReplace(deliverableItemDate.deliverable_item_date_code)].errors">
                <div
                  *ngIf="articleDateForm.controls[strReplace(deliverableItemDate.deliverable_item_date_code)].errors.required && articleDateForm.controls[strReplace(deliverableItemDate.deliverable_item_date_code)].touched "
                  class="e-error">
                  {{deliverableItemDate.deliverable_item_date_name}} is required
                </div>
              </div>
            </div>

            <div class="form-group sf-outline-input mb-10 mr-2 ml-2">
              <ejs-dropdownlist
                [formControlName]="'date_owner_code_'+ strReplace(deliverableItemDate.deliverable_item_date_code)"
                [dataSource]='users' floatLabelType="Auto" [fields]='userFields' cssClass="e-filled"
                placeholder='Date Owner' [allowFiltering]='true' [showClearButton]=true
                (blur)="articleDateForm.controls['date_owner_code_'+ strReplace(deliverableItemDate.deliverable_item_date_code)].markAsTouched()">
                <ng-template #itemTemplate="" let-data style="display: inline-block">
                  <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                    <span>{{data.first_name}}</span>
                  </div>
                  <div style="display: inline-block" *ngIf="data.is_disable_yn ==1">
                    <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                  </div>

                </ng-template>
              </ejs-dropdownlist>
            </div>

            <div class="form-group sf-outline-input mb-10 mr-2 ml-2">
              <ejs-dropdownlist
                [formControlName]="'status_code_'+ strReplace(deliverableItemDate.deliverable_item_date_code)"
                [dataSource]='status' floatLabelType="Auto" [fields]='statusFields' cssClass="e-filled"
                placeholder='Status' [allowFiltering]='true' [showClearButton]=true
                (blur)="articleDateForm.controls['status_code_'+ strReplace(deliverableItemDate.deliverable_item_date_code)].markAsTouched()">
              </ejs-dropdownlist>
            </div>

            <div class="form-group sf-outline-input mb-10 align-self-center">
              <div class="d-flex">
                <img width="25px" class="mr-3" src="assets\media\can-tentative.png">
                <div class="mt-1">
                  <ejs-checkbox
                    [formControlName]="'is_tentative_' + strReplace(deliverableItemDate.deliverable_item_date_code)"
                    #wcheckbox label="" labelPosition="Before"></ejs-checkbox>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div *ngFor="let articleDate of articleDates">
          <div class="d-flex" *ngIf="findDateFromArray(articleDate.article_date_code)">


            <div class="form-group sf-outline-input mx-3 mr-4">
              <!-- <ejs-datepicker [formControlName]="strReplace(articleDate.article_date_code)"
                              openOnFocus="true" [allowEdit]=false
                              (change)="onDateChange($event, strReplace(articleDate.article_date_code), deliverableNoteForm, articleDateForm)"
                              (blur)="articleDateForm.controls[strReplace(articleDate.article_date_code)].markAsTouched()"
                              [placeholder]='articleDate.deliverable_item_date_name' floatLabelType='Auto'
                              cssClass="e-filled">
              </ejs-datepicker> -->
              <ejs-datetimepicker [formControlName]="strReplace(articleDate.article_date_code)" [allowEdit]=false
                (change)="onDateChange($event, strReplace(articleDate.article_date_code), articleDateForm)"
                [firstDayOfWeek]="startWeek"
                (blur)="articleDateForm.controls[strReplace(articleDate.article_date_code)].markAsTouched()"
                [placeholder]='articleDate.article_date_name' floatLabelType='Auto' cssClass="e-filled">
              </ejs-datetimepicker>
              <div *ngIf="articleDateForm.controls[strReplace(articleDate.article_date_code)].errors">
                <div
                  *ngIf="articleDateForm.controls[strReplace(articleDate.article_date_code)].errors.required && articleDateForm.controls[strReplace(articleDate.article_date_code)].touched "
                  class="e-error">
                  {{articleDate.article_date_name}} is required
                </div>
              </div>
            </div>

            <div class="form-group sf-outline-input mb-10 mr-2 ml-2">
              <ejs-dropdownlist [formControlName]="'date_owner_code_'+ strReplace(articleDate.article_date_code)"
                [dataSource]='users' floatLabelType="Auto" [fields]='userFields' cssClass="e-filled"
                placeholder='Date Owner' [allowFiltering]='true' showClearButton="true"
                (blur)="articleDateForm.controls['date_owner_code_'+ strReplace(articleDate.article_date_code)].markAsTouched()">
                <ng-template #itemTemplate="" let-data style="display: inline-block">
                  <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                    <span>{{data.first_name}}</span>
                  </div>
                  <div style="display: inline-block" *ngIf="data.is_disable_yn ==1">
                    <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                  </div>

                </ng-template>
              </ejs-dropdownlist>
            </div>

            <div class="form-group sf-outline-input mb-10 mr-2 ml-2">
              <ejs-dropdownlist [formControlName]="'status_code_'+ strReplace(articleDate.article_date_code)"
                [dataSource]='status' floatLabelType="Auto" [fields]='statusFields' cssClass="e-filled"
                placeholder='Status' [allowFiltering]='true' showClearButton="true"
                (blur)="articleDateForm.controls['status_code_'+ strReplace(articleDate.article_date_code)].markAsTouched()">
              </ejs-dropdownlist>
            </div>

            <div class="form-group sf-outline-input mb-10 align-self-center">
              <div class="d-flex">
                <img width="25px" class="mr-3" src="assets\media\can-tentative.png">
                <div class="mt-1">
                  <ejs-checkbox [formControlName]="'is_tentative_' + strReplace(articleDate.article_date_code)"
                    #wcheckbox label="" labelPosition="Before"></ejs-checkbox>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>


    </form>

    <div class="my-10 pb-20">
      <button id="resetbtn"
        class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
        type="button" (click)="clear()" data-ripple="true">Clear All
      </button>
      <button id="add" *ngIf="!articleInput"
        class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="button"
        (click)="onAddArticle(articleForm)" [disabled]="formSent" data-ripple="true">Add Article
      </button>
      <button id="update" *ngIf="articleInput"
        class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="button"
        (click)="onUpdateArticle(articleForm)" [disabled]="formSent" data-ripple="true">Update Article
      </button>
    </div>

  </div>
</ejs-sidebar>
<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>