<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">

    <h2>Company - General</h2>

    <form id="formId" [formGroup]="companyGeneralForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">
      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='tags' #tags [dataSource]="allTags" floatLabelType="Auto" cssClass="e-filled"
                         formControlName='internal_tags' [fields]="tagFields"
                         [allowCustomValue]=true mode='Box' placeholder='Internal Tags'
                         name="tags"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='public_tags' #tags [dataSource]="allTags" floatLabelType="Auto" cssClass="e-filled"
                         formControlName='external_tags' [fields]="tagFields"
                         [allowCustomValue]=true mode='Box' placeholder='External Tags'
                         name="tags"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto"
                     (blur)="companyGeneralForm.controls.description.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <div class="d-flex mb-4">
          <h4>Social Media Links</h4>
          <div class="float-right mr-0 ml-auto">
            <button (click)="addSMLink()"
                    class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3 ml-3 add-btn"
            >+
            </button>
            <button (click)="removeSMLink()"
                    class="btn btn-light btn-text-primary btn-hover-text-primary text-uppercase font-weight-bold mr-2 btn-sm p-3 ml-3 add-btn"
            >-
            </button>
          </div>


        </div>

        <div formArrayName="social_media">
          <div *ngFor="let socialMediaC of social_media.controls; let i=index" >
            <div [formGroupName]="i" class="d-flex mt-5">
              <ejs-dropdownlist class="mr-1 w-75" #statusDropdown id='status_code' [fields]='socialMediaFields'
                                cssClass="e-filled" placeholder="Platform" formControlName="social_media_platform_code"
                                (change)="setSocialMediaPlatforms(i, 'platform', $event)" style="font-size: small"
                                floatLabelType="Auto" [dataSource]="fetchSocialMediaPlatformDataset(social_media.controls[i].get('social_media_platform_code').value)"></ejs-dropdownlist>

              <ejs-textbox class="ml-1 w-100" id="socialMedia" [placeholder]="getPlaceholder(i)" cssClass="e-filled"
                           formControlName="social_media_link" floatLabelType="Auto" [multiline]="true" rows="1"
                           (change)="setSocialMediaPlatforms(i, 'link')"
              ></ejs-textbox>

            </div>
            <div *ngIf="social_media.controls[i].get('social_media_platform_code').errors" >
              <div
                *ngIf="social_media.controls[i].get('social_media_platform_code').errors.required && social_media.controls[i].get('social_media_platform_code').touched"
                class="e-error">
                Please select a social media platform
              </div>
            </div>

            <div *ngIf="social_media.controls[i].get('social_media_link').errors" >
              <div
                *ngIf="social_media.controls[i].get('social_media_link').errors.required && social_media.controls[i].get('social_media_link').touched"
                class="e-error">
                Please enter a social media link
              </div>
              <div
                *ngIf="social_media.controls[i].get('social_media_link').errors.invalidurl && social_media.controls[i].get('social_media_link').touched"
                class="e-error">
                Please enter a valid social media link
              </div>
            </div>



          </div>

        </div>


      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button"
                (click)="clear();"
                data-ripple="true">Clear
        </button>

        <button
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUpdateCompanyGeneral(companyGeneralForm)" [disabled]="formSent" data-ripple="true">Update General
        </button>
      </div>
    </form>

  </div>

</ejs-sidebar>
<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
