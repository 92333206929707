// Actions
import {AuthActions, AuthActionTypes} from './auth.actions';
import {User} from '../../models/user.model';
import {environment} from '../../../../environments/environment.prod';
import cloneDeep from 'lodash.clonedeep';
// Models

export interface AuthState {
  loggedIn: boolean;
  authToken?: string;
  user?: User;
  isUserLoaded: boolean;
  error?: Error;
  success?: any;
  isLimitedUser?: boolean;
}

export const initialAuthState: AuthState = {
  loggedIn: false,
  authToken: undefined,
  user: undefined,
  isUserLoaded: false,
  isLimitedUser: undefined
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return { loggedIn: true, authToken: undefined, user: undefined, isUserLoaded: false };
    }

    case AuthActionTypes.REGISTER: {
      const token: string = action.payload.authToken;
      return {
        loggedIn: true,
        authToken: token,
        user: undefined,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.LOGOUT:
      state = undefined;
      return {authToken: '', isUserLoaded: false, loggedIn: false, user: undefined, ...state};

    case AuthActionTypes.USER_LOADED: {
      const user: User = action.payload.user;
      let stateUser: any = state.user;
      stateUser = {...stateUser, ...user};
      let isLimitedUser;
      isLimitedUser = environment.limitedUsers.includes(user.user_code);
      return {
        ...state,
        user: stateUser,
        isUserLoaded: true,
        loggedIn: true,
        isLimitedUser
      };
    }

    case AuthActionTypes.LOGIN_BY_TOKEN: {
      return {
        loggedIn: false,
        authToken: undefined,
        user: undefined,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.LOGIN_BY_TOKEN_SUCCESS: {
      return { ...state, loggedIn: true, authToken: undefined, user: undefined, isUserLoaded: true };
    }

    case AuthActionTypes.LOGIN_BY_TOKEN_FAILURE: {
      return {...state, loggedIn: false, authToken: undefined, user: undefined, isUserLoaded: false, error: action.payload };
    }

    case AuthActionTypes.CHANGE_PASSWORD: {
     
      return {
        ...state,
        loggedIn: false,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
   
      
    
      return { ...state, loggedIn: true, isUserLoaded: true, success: action.payload };
    }

    case AuthActionTypes.CHANGE_PASSWORD_FAILURE: {
      
      
      return { ...state, loggedIn: false, isUserLoaded: false, error: action.payload };
    }

    case AuthActionTypes.CHANGE_PROFILE: {
     
      return {
        ...state,
        loggedIn: false,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.CHANGE_PROFILE_SUCCESS: {  
    var ChangedUser={...state}
    var changedUser=cloneDeep(ChangedUser)
  changedUser.user.last_name=action.payload.last_name
  changedUser.user.first_name=action.payload.first_name
      return { ...state,user:changedUser.user,loggedIn: true, isUserLoaded: true,success:true};
    }

    case AuthActionTypes.CHANGE_PROFILE_FAILURE: {
      
      
      return { ...state, loggedIn: false, isUserLoaded: false, error: action.payload };
    }





    case AuthActionTypes.SET_SUCCESS_NULL: {
      return { ...state, loggedIn: false, authToken: undefined, user: undefined, isUserLoaded: false, error: undefined, success: undefined };
    }

    case AuthActionTypes.SET_STATUS_NULL: {
      return { ...state, loggedIn: true, isUserLoaded: true, error: undefined, success: undefined };
    }

    case AuthActionTypes.USER_REQUESTED: {
      return { ...state };
    }

    default:
      return state;
  }
}
