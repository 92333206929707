import { Action } from '@ngrx/store';

export enum ProductActionTypes {

  LOAD = '[PRODUCT] Load Action',
  LOAD_SUCCESS = '[PRODUCT] Load Success Action',
  LOAD_FAILURE = '[PRODUCT] Load Failure Action',

  SEARCH_PRODUCT = '[PRODUCT] Search Product',
  SEARCH_PRODUCT_SUCCESS = '[PRODUCT] Search Product Success',
  SEARCH_PRODUCT_FAILURE = '[PRODUCT] Search Product Failure',

  SET_SUCCESS_NULL = '[PRODUCT] Set Success Null',

}

export class ProductLoad implements Action {
  readonly type = ProductActionTypes.LOAD;
  constructor(public payload?: {page_size?: number, page_no?: number }) { }
}

export class ProductLoadSuccess implements Action {
  readonly type = ProductActionTypes.LOAD_SUCCESS;
  constructor(public payload: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) { }
}

export class ProductLoadFailure implements Action {
  readonly type = ProductActionTypes.LOAD_FAILURE;
  constructor(public payload: Error) { }
}

export class ProductSearch implements Action {
  readonly type = ProductActionTypes.SEARCH_PRODUCT;

  constructor(public payload: { search?: string, page_no?: number, page_size?: number }) {
  }
}

export class ProductSearchSuccess implements Action {
  readonly type = ProductActionTypes.SEARCH_PRODUCT_SUCCESS;

  constructor(public payload: { result: [], total_count: number, page_no: number, page_size: number }) {
  }
}

export class ProductSearchFailure implements Action {
  readonly type = ProductActionTypes.SEARCH_PRODUCT_FAILURE;

  constructor(public payload: Error) {
  }
}


export type ProductActions = ProductLoad | ProductLoadSuccess | ProductLoadFailure |
ProductSearch | ProductSearchSuccess | ProductSearchFailure;
