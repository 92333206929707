import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../core";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";
import { Router } from "@angular/router";
import { Event } from "../../../../core/models/event.model";
import { fromToDate, trimValidator } from "../../../../app-validators";
import * as _ from "lodash";
import { EventsService } from "../../../../core/store/services/events.service";
import { EmitType } from "@syncfusion/ej2-base";
import {
  DropDownListComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-angular-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import {
  EventAdd,
  EventsSetStatusNull,
  EventUpdate,
} from "../../../../core/store/actions/events.actions";
import * as moment from "moment/moment";
import { ActionsService } from "../../../../core/services/actions.service";
import { errorMessage } from "../../../common/app-error-messages";

@Component({
  selector: "app-events-form",
  templateUrl: "./events-form.component.html",
  styleUrls: ["./events-form.component.scss"],
})
export class EventsFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("sidebarInstance") public sidebarInstance: SidebarComponent;
  @ViewChild("clearModal") public clearModal: DialogComponent;
  @ViewChild("countryDropdown") countryDropdown: DropDownListComponent;
  // Input Props
  @Input() countries: { [key: string]: object }[];
  // Event Emitters
  @Output() valueChange = new EventEmitter();
  @Output() editChange = new EventEmitter();

  // Sidebar Props
  public width = "auto";
  allowEdit = false;
  isEdit = false;
  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];

  // Form Props
  public formSent: boolean;
  eventsForm: FormGroup;
  errorMsg: string[] = [];
  error$: Observable<any>;
  success$: Observable<string>;
  duplicateMsg$ = new BehaviorSubject("");
  public eventsInput$: BehaviorSubject<Event | null> =
    new BehaviorSubject<Event | null>(null);
  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(false);
  public countryFields: object = {
    value: "country_code",
    text: "country_name",
  };
  editEventData: Event | null = null;
  isUpdateForm = false;
  timeoutIDs: any = [];

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private eventsService: EventsService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.eventsForm) {
      this.generateCompanyForm();
    }
  }

  ngOnInit(): void {
    if (!this.eventsForm) {
      this.generateCompanyForm();
    }
    this.error$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.error) {
          return;
        }
        return store.events.error;
      }),
    );
    this.error$.subscribe((data: any) => {
      console.log("error", data);
      if (data) {
        try {
          const em = JSON.parse(data.error);
          const msg = [];
          const keys = Object.keys(em);
          if (keys.length) {
            keys.forEach((key) => {
              msg.push(`${key}: ${em[key]}`);
            });
          }
          this.errorMsg = _.cloneDeep(msg);
          console.log("error --", this.errorMsg);
          this.cd.detectChanges();
        } catch (e) {
          console.log("error", e);
        }
        this.formSent = false;
        this.isEdit = false;
      }
    });
    this.success$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.success) {
          return;
        }
        return store.events.success.isForm;
      }),
    );
    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new EventsSetStatusNull());
      }
    });
  }

  generateCompanyForm() {
    this.eventsForm = this.formBuilder.group(
      {
        event_name: new FormControl("", [
          Validators.required,
          Validators.compose([trimValidator]),
        ]),
        slug: new FormControl("", [
          Validators.maxLength(200),
          Validators.required,
          Validators.compose([Validators.pattern("^[A-Za-z0-9-]+$")]),
        ]),
        city_name: new FormControl("", [
          Validators.required,
          Validators.maxLength(100),
          Validators.compose([trimValidator]),
        ]),
        country: new FormControl("", [Validators.required]),
        address: new FormControl("", [Validators.maxLength(200)]),
        start_date: new FormControl(""),
        end_date: new FormControl(""),
        description: new FormControl("", [Validators.maxLength(1000)]),
      },
      {
        validators: [fromToDate("start_date", "end_date")],
      },
    );
  }
  setFormValues() {
    this.isEdit = true;
    if (!this.eventsForm) {
      return;
    }
    this.eventsForm.patchValue({
      event_name: this.editEventData.event_name,
      slug: this.editEventData.slug,
      city_name: this.editEventData.city_name,
      country: this.editEventData.country,
      address: this.editEventData.address,
      start_date: this.editEventData.start_date
        ? new Date(this.editEventData.start_date)
        : null,
      end_date: this.editEventData.end_date
        ? new Date(this.editEventData.end_date)
        : null,
      description: this.editEventData.description,
    });
    this.timeoutIDs.push(
      setTimeout(() => {
        try {
          this.eventsForm.controls.country.setValue(this.editEventData.country);
          this.countryDropdown.value = this.editEventData.country.country_code;
          this.countryDropdown.refresh();
        } catch (e) {}
      }, 1000),
    );
    console.log(
      "edit form data",
      this.editEventData,
      this.eventsForm.getRawValue(),
    );
  }

  initiateEdit(data: Event) {
    console.log("edit data", data);
    this.eventsInput$.next(data);
    this.editEventData = data;
    this.setFormValues();
  }

  get eventsInput(): Event | null {
    return this.eventsInput$.getValue();
  }

  openSidebar(data): void {
    this.sidebarInstance.show();
    this.isEdit = false;
    this.setCleared$.next(false);
    console.log("dddd", data);
    if (data) {
      this.initiateEdit(data);
    }
  }

  closeSidebar(): void {
    console.log("close called");
    this.timeoutIDs.push(
      setTimeout(() => {
        this.eventsForm.reset();
      }, 1000),
    );
    this.errorReset();
    this.editEventData = null;
    this.isEdit = false;
    this.valueChange.emit("success");
    this.eventsInput$.next(null);
    if (this.sidebarInstance && this.sidebarInstance.isOpen) {
      this.sidebarInstance.hide();
    }
    this.setCleared$.next(true);
  }

  errorReset() {
    this.store.dispatch(new EventsSetStatusNull());
  }
  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }

  clearAll() {
    this.clearModal.hide();
    if (this.eventsForm) {
      this.eventsForm.reset();
    }
  }

  // Validator APIs
  checkDuplicates(eventName: string) {
    if (
      (this.eventsInput$.getValue() &&
        this.eventsInput$.getValue().event_name !== eventName.trim() &&
        eventName.trim() !== "") ||
      (!this.eventsInput$.getValue() && eventName.trim() !== "")
    ) {
      this.eventsService.checkDuplicates({ event_name: eventName }).subscribe(
        (data) => {
          if (_.size(data.data)) {
            this.duplicateMsg$.next(
              `Warning: We found ${data.data.length} more events with the same name!`,
            );
          } else {
            this.duplicateMsg$.next("");
          }
        },
        (err) => this.duplicateMsg$.next(""),
      );
    } else {
      this.duplicateMsg$.next("");
    }
  }
  // Transformers
  getEventsSlug(eventName: string, form: FormGroup) {
    if (
      (this.eventsInput$.getValue() &&
        this.eventsInput$.getValue().event_name !== eventName.trim() &&
        eventName.trim() !== "") ||
      (!this.eventsInput$.getValue() && eventName.trim() !== "")
    ) {
      this.eventsService.getEventSlug({ event_name: eventName }).subscribe(
        (data) => {
          if (data && data.data) {
            form.controls.slug.setValue(data.data);
          }
        },
        (err) => {
          form.controls.slug.setValue(
            eventName.toLowerCase().trim().replace(/ /g, "-"),
          );
        },
      );
    }
  }
  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    query =
      e.text !== ""
        ? query.where("country_name", "startswith", e.text, true)
        : query;
    e.updateData(this.countries, query);
  };

  // CRUD APIs
  addEvent(form: FormGroup) {
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(
        new EventAdd({
          event_name: this.eventsForm.value.event_name.trim(),
          slug: this.eventsForm.value.slug,
          city_name: this.eventsForm.value.city_name,
          country_code: this.eventsForm.value.country,
          address: this.eventsForm.value.address,
          start_date:
            this.eventsForm.value.start_date !== null
              ? moment(this.eventsForm.value.start_date).format(
                  "YYYY-MM-DD HH:mm:ss",
                )
              : null,
          end_date:
            this.eventsForm.value.end_date !== null
              ? moment(this.eventsForm.value.end_date).format(
                  "YYYY-MM-DD HH:mm:ss",
                )
              : null,
          description: this.eventsForm.value.description,
        }),
      );
    }
  }

  updateEvent(form: FormGroup) {
    if (!form.invalid) {
      this.formSent = true;
      const postData: any = {
        event_code: this.editEventData.event_code,
        event_name: this.eventsForm.value.event_name.trim(),
        city_name: this.eventsForm.value.city_name,
        country_code: this.eventsForm.value.country,
        address: this.eventsForm.value.address,
        start_date:
          this.eventsForm.value.start_date !== null
            ? moment(this.eventsForm.value.start_date).format(
                "YYYY-MM-DD HH:mm:ss",
              )
            : null,
        end_date:
          this.eventsForm.value.end_date !== null
            ? moment(this.eventsForm.value.end_date).format(
                "YYYY-MM-DD HH:mm:ss",
              )
            : null,
        description: this.eventsForm.value.description,
      };
      if (this.editEventData.slug !== this.eventsForm.value.slug) {
        postData.slug = this.eventsForm.value.slug;
      }
      this.store.dispatch(new EventUpdate(postData));
    }
  }

  ngOnDestroy() {
    this.duplicateMsg$.unsubscribe();
    if (this.timeoutIDs && this.timeoutIDs.length > 0) {
      this.timeoutIDs.forEach((el) => clearTimeout(el));
    }
  }
}
