import {Injectable} from '@angular/core';
import {ResponseData} from '../../ResponseData.interface';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {v4 as uuid} from 'uuid';
import {environment} from '../../../../environments/environment.prod';

const BRAND_URL = environment.API_URL + 'brand';

@Injectable()
export class BrandService {
  id: string;

  constructor(private http: HttpClient) {
  }

  getBrands(page_size?: number, page_no?: number) {
    const request: any = {page_size, page_no};
    return this.http.get<ResponseData>(BRAND_URL, {params: request});
  }

getSearchedBrand(parameters) {
    return this.http.get<ResponseData>(BRAND_URL + '/search-side-bar', {params: parameters});
  }

  getSearchResults(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(BRAND_URL + '/search', {params: parameters});
  }

}
