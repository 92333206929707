import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import * as _ from 'lodash';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppState} from 'src/app/core';
import {Article} from 'src/app/core/models/article.model';
import {AddArticle, ArticleSetSuccessNull, ArticleUpdate} from 'src/app/core/store/actions/article.actions';
import {ArticleService} from 'src/app/core/store/services/article.service';
import {AdminService} from '../../../../../core/store/services/admin.service';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Query} from '@syncfusion/ej2-data'
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-article-form',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('user') public userDropdowm !: DropDownListComponent;
  @Input() articleCategory: any;
  @Input() articleTypes: any;
  @Input() articleStage: any;
  @Input() articleDates: any;
  @Input() users: any;
  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  articleForm: FormGroup;
  articleDateForm: FormGroup;

  formSent: boolean;
  selectedArticleCategories: any[];

  selectedArticleCategoriesObs = new BehaviorSubject([]);
  articleFirstEdit = true;

  error$: Observable<any>;
  success$: Observable<boolean>;
  errorMsg = '';

  selectedTab = 'article';
  isUpdate = true;

  startWeek = 1;


  deliverableItemDates: any[];
  articleCategoryFields: object = {value: 'article_category_code', text: 'article_category_name'};
  primaryArticleCategoryFields: object = {value: 'article_category_code', text: 'article_category_name'};
  articleStageFields: object = {value: 'article_stage_code', text: 'article_stage_name'};
  public statusFields: object = {value: 'status_code', text: 'status_name'};
  public userFields: object = {value: 'user_code', text: 'first_name'};


  articleTypeFields: object = {value: 'article_type_code', text: 'article_type_name'};

  status: any = [
    {
      status_code: 'pending',
      status_name: 'Pending'
    },
    {
      status_code: 'completed',
      status_name: 'Completed'
    }
  ];

  public _articleInput$: BehaviorSubject<Article | null> = new BehaviorSubject<Article | null>(null);

  @Input() set articleInput(mode: Article | null) {
    this._articleInput$.next(mode);
  }

  get articleInput(): Article | null {
    return this._articleInput$.getValue();
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private articleService: ArticleService, private adminService: AdminService) {
  }


  ngOnInit(): void {
    this.generateForm();
    this.generateArticleDatesForm();

    this.error$ = this.store.pipe(select(store => store.articles.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;

      }
    });

    this.success$ = this.store.pipe(select(store => store.articles.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ArticleSetSuccessNull());
      }
    });
  }

  generateForm() {
    this.articleForm = this.formBuilder.group({
      article_type_code: new FormControl(null, Validators.required),
      article_name: new FormControl(null, Validators.required),
      slug: new FormControl(null, Validators.required),
      article_category_code: new FormControl('', Validators.required),
      primary_article_category_code: new FormControl('', Validators.required),
      excerpt: new FormControl('', Validators.required),
      article_stage_code: new FormControl(null, Validators.required),
      assigned_to_user: new FormControl('', Validators.required)
    });
  }

  clearForm() {
    this.articleForm.reset();
    this.articleDateForm.reset();

    this.articleForm.controls.article_category_code.setValue('');
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.articleFirstEdit = true;
    this.selectedTab = 'article';
    this.valueChange.emit('d');

    this.clearForm();

    this.sidebarInstance.hide();
  }

  onAddArticle(form: FormGroup) {

    const articleDates = this.formatArticleDatesAPI(this.articleDateForm);

    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new AddArticle({
        article: {
          article_name: this.articleForm.value.article_name.trim(),
          slug: this.articleForm.value.slug,
          article_category_code: _.cloneDeep(this.articleForm.value.article_category_code),
          primary_article_category_code: this.articleForm.value.primary_article_category_code,
          excerpt: this.articleForm.value.excerpt,
          article_stage_code: this.articleForm.value.article_stage_code,
          article_type_code: this.articleForm.value.article_type_code,
          assigned_to_user: this.articleForm.value.assigned_to_user,
          dates: articleDates
        }
      }));
    }
  }

  formatArticleDatesAPI(form: FormGroup) {
    const articleDatesValues = _.clone(form.getRawValue());
    const articleDates = [];
    for (const articleDatesValuesKey in articleDatesValues) {
      const dateData = {
        reference_type_date_code: null,
        date: null,
        is_tentative: null,
        status_code: null,
        date_owner_code: null,
        reference_code: null,
        reference_type: null
      };


      if (articleDatesValues[articleDatesValuesKey] !== null && articleDatesValues[articleDatesValuesKey] !== '' && !articleDatesValuesKey.includes('is_tentative')
        && !articleDatesValuesKey.includes('status_')) {
        const newarticleDatesValuesKey = articleDatesValuesKey.replace(/_/g, '-');
        if (this.articleDates.find(item => item.article_date_code === newarticleDatesValuesKey)) {
          dateData.reference_type_date_code = newarticleDatesValuesKey;
          dateData.date = moment(articleDatesValues[articleDatesValuesKey]).format('YYYY-MM-DD HH:mm:ss');

          const tentativeKeyword = 'is_tentative_' + articleDatesValuesKey.toString();
          dateData.is_tentative = articleDatesValues[tentativeKeyword] === true ? 1 : 0;
          dateData.date_owner_code = articleDatesValues['date_owner_code_' + articleDatesValuesKey.toString()];

          dateData.status_code = articleDatesValues['status_code_' + articleDatesValuesKey.toString()];

          if (this.articleDates.find(item => item.article_date_code === newarticleDatesValuesKey)) {
            dateData.reference_type = 'Article';
          } else if (this.deliverableItemDates.find(item => item.deliverable_item_date_code === newarticleDatesValuesKey)) {
            dateData.reference_type = 'Deliverable';
            dateData.reference_code = this._articleInput$.getValue().deliverables[0].deliverable_code;
          }

          articleDates.push(dateData);
        }
      }
    }

    return articleDates;
  }

  setCompanyType(args, articleForm: FormGroup) {

    this.selectedArticleCategories = [];
    this.selectedArticleCategoriesObs.next([]);

    const articleCategories = args.value;
    this.articleForm.controls.article_category_code.setValue(args.value);

    if (articleCategories) {
      articleCategories.forEach(element => {
        const foundIndex = this.articleCategory.findIndex((item) => item.article_category_code === element);
        if (foundIndex !== -1) {
          this.selectedArticleCategories.push(this.articleCategory[foundIndex]);
        }
      });
    }


    this.articleForm.controls.primary_article_category_code.setValue(this.articleForm.controls.primary_article_category_code.value);

    if (this.articleForm.controls.primary_article_category_code.value !== '' && !this.selectedArticleCategories.includes(this.articleForm.controls.primary_article_category_code.value)) {
      this.articleForm.controls.primary_article_category_code.setValue(null);
    }

    this.selectedArticleCategoriesObs.next(this.selectedArticleCategories);


    if (this._articleInput$.getValue() && this.articleFirstEdit && this._articleInput$.getValue().primary_article_category) {
      this.articleForm.controls.primary_article_category_code.setValue(this._articleInput$.getValue().primary_article_category[0].article_category_code);
      this.articleFirstEdit = false;
    }

  }

  fetchDeliverableItemDates(value, type) {
    this.adminService.getDeliverableDates(value, type).subscribe((data) => {
      if (data && data.data && data.data.deliverable_item_date) {
        this.deliverableItemDates = _.orderBy(data.data.deliverable_item_date, 'rank_order', 'asc');
       
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.articleCategory) {
      this.articleCategoryFields = {dataSource: this.articleCategory, value: 'article_category_code', text: 'article_category_name'};
    }

    if (this._articleInput$.getValue()) {

      // get linked deliverable and get the relative dates
      if (this._articleInput$.getValue().deliverables && this._articleInput$.getValue().deliverables.length > 0) {
        const deliverable = this._articleInput$.getValue().deliverables[0];

        if (deliverable && deliverable.deliverable_item.type === 'article') {
          this.fetchDeliverableItemDates(deliverable.deliverable_item.deliverable_item_code, 'article');
          if (deliverable.deliverable_dates) {
            for (const deliverable_date of deliverable.deliverable_dates) {
              const key = deliverable_date.reference_type_date.deliverable_item_date_code.replace(/-/g, '_');

              this.articleDateForm.addControl(key, new FormControl(''));
              this.articleDateForm.addControl('is_tentative_' + key, new FormControl(false));
              this.articleDateForm.addControl('status_code_' + key, new FormControl(null));
              this.articleDateForm.addControl('date_owner_code_' + key, new FormControl(null));


              if (deliverable_date.status) {
                this.articleDateForm.controls['status_code_' + key].setValue(deliverable_date.status);
              }


              this.articleDateForm.controls[key].setValue(moment(deliverable_date.date, 'YYYY-MM-DD HH:mm:ss').toDate());

              const tentativeKey = 'is_tentative_' + key.toString();
              this.articleDateForm.controls[tentativeKey].setValue(deliverable_date.is_tentative_yn === 1);

              if (deliverable_date.date_owner) {
                this.articleDateForm.controls['date_owner_code_' + key].setValue(deliverable_date.date_owner.user_code);
              }

            }

            // this.isUpdate = false;
          }
        }

      }

      const article_category_array = [];
      if (this._articleInput$.getValue().article_category) {
        for (const articleCategory of this._articleInput$.getValue().article_category) {
          article_category_array.push(articleCategory.article_category_code);
        }
      }

      const primary_community_type_array = [];
      if (this._articleInput$.getValue().primary_article_category) {
        for (const articleCategory of this._articleInput$.getValue().primary_article_category) {
          primary_community_type_array.push(articleCategory.article_category_code);
        }
      }

      this.articleForm.patchValue({
        article_name: this._articleInput$.getValue().article_name ? this._articleInput$.getValue().article_name : null,
        slug: this._articleInput$.getValue().slug ? this._articleInput$.getValue().slug : null,
        article_category_code: article_category_array ? article_category_array : null,
        excerpt: this._articleInput$.getValue().excerpt ? this._articleInput$.getValue().excerpt : null,
        article_stage_code: this._articleInput$.getValue().article_stage ? this._articleInput$.getValue().article_stage.article_stage_code : null,
        article_type_code: this._articleInput$.getValue().article_type ? this._articleInput$.getValue().article_type.article_type_code : null,
        assigned_to_user: this._articleInput$.getValue().assigned_to ? this._articleInput$.getValue().assigned_to.user_code : null,

      });

      if (this._articleInput$.getValue().article_dates) {
        for (const article_date of this._articleInput$.getValue().article_dates) {
          const key = article_date.reference_type_date.article_date_code.replace(/-/g, '_');

          this.articleDateForm.addControl(key, new FormControl(''));
          this.articleDateForm.addControl('is_tentative_' + key, new FormControl(false));
          this.articleDateForm.addControl('status_code_' + key, new FormControl(null));
          this.articleDateForm.addControl('date_owner_code_' + key, new FormControl(null));


          if (article_date.status) {
            this.articleDateForm.controls['status_code_' + key].setValue(article_date.status);
          }


          this.articleDateForm.controls[key].setValue(moment(article_date.date, 'YYYY-MM-DD HH:mm:ss').toDate());

          const tentativeKey = 'is_tentative_' + key.toString();
          this.articleDateForm.controls[tentativeKey].setValue(article_date.is_tentative_yn === 1);

          if (article_date.date_owner) {
            this.articleDateForm.controls['date_owner_code_' + key].setValue(article_date.date_owner.user_code);
          }

        }

        // this.isUpdate = false;
      }
    }

  }

  onUpdateArticle(articleForm) {
    if (!articleForm.invalid) {

      // const articleDates = this.formatArticleDatesAPI(this.articleDateForm);

      const articleDatesValues = _.cloneDeep(this.articleDateForm.getRawValue());

      const articleDates = [];
      for (const articleDatesValuesKey in articleDatesValues) {
        const dateData = {
          reference_type_date_code: null,
          date: null,
          is_tentative: null,
          status_code: null,
          date_owner_code: null,
          reference_type: null,
          reference_code: null
        };

        if (articleDatesValues[articleDatesValuesKey] !== null && articleDatesValues[articleDatesValuesKey] !== '' && !articleDatesValuesKey.includes('is_tentative')) {
          const newarticleDatesValuesKey = articleDatesValuesKey.replace(/_/g, '-');
          if (this.articleDates.find(item => item.article_date_code === newarticleDatesValuesKey) || (this.deliverableItemDates && this.deliverableItemDates.length > 0 && this.deliverableItemDates.find(item => item.deliverable_item_date_code === newarticleDatesValuesKey))) {
            dateData.reference_type_date_code = newarticleDatesValuesKey;
            dateData.date = moment(articleDatesValues[articleDatesValuesKey]).format('YYYY-MM-DD HH:mm:ss');

            const tentativeKeyword = 'is_tentative_' + articleDatesValuesKey.toString();
            dateData.is_tentative = articleDatesValues[tentativeKeyword] === true ? 1 : 0;
            dateData.date_owner_code = articleDatesValues['date_owner_code_' + articleDatesValuesKey.toString()];

            dateData.status_code = articleDatesValues['status_code_' + articleDatesValuesKey.toString()];

            if (this.articleDates.find(item => item.article_date_code === newarticleDatesValuesKey)) {
              dateData.reference_type = 'Article';
              dateData.reference_code = this._articleInput$.getValue().article_code;
            } else if (this.deliverableItemDates.find(item => item.deliverable_item_date_code === newarticleDatesValuesKey)) {
              dateData.reference_type = 'Deliverable';
              dateData.reference_code = this._articleInput$.getValue().deliverables && this._articleInput$.getValue().deliverables.length > 0 ? this._articleInput$.getValue().deliverables[0].deliverable_code : null;
            }

            articleDates.push(dateData);
          }

        }

        if (articleDates.length === 0) {
          const dateDataArticle = {
            date: null,
            reference_type: null,
            reference_code: null
          };

          const dateDataDelv = {
            date: null,
            reference_type: null,
            reference_code: null
          };

          dateDataArticle.reference_type = 'Article';
          dateDataArticle.reference_code = this._articleInput$.getValue().article_code;

          articleDates.push(dateDataArticle);

          if (this._articleInput$.getValue().deliverables && this._articleInput$.getValue().deliverables.length > 0 ) {
            dateDataDelv.reference_type = 'Deliverable';
            dateDataDelv.reference_code = this._articleInput$.getValue().deliverables && this._articleInput$.getValue().deliverables.length > 0 ? this._articleInput$.getValue().deliverables[0].deliverable_code : null;

            articleDates.push(dateDataDelv);
          }
        }

      }

const Category=this.articleForm.value.article_category_code

      const article = {
        article_code: this._articleInput$.getValue().article_code,
        article_name: articleForm.value.article_name,
        excerpt: articleForm.value.excerpt,
        slug: this.articleForm.value.slug,
        article_category_code: _.cloneDeep(this.articleForm.value.article_category_code),
        primary_article_category_code: this.articleForm.value.primary_article_category_code,
        article_stage_code: this.articleForm.value.article_stage_code,
        article_type_code: this.articleForm.value.article_type_code,
        is_published_yn: this._articleInput$.getValue().is_published_yn,
        assigned_to_user: this.articleForm.value.assigned_to_user,
        dates: articleDates
      };

      this.formSent = true;
      this.store.dispatch(new ArticleUpdate({article_code: this._articleInput$.getValue().article_code, article}));
    }
  }

  setPrimaryCategory(args) {
    if (args && args.itemData) {
      this.articleForm.controls.primary_article_category_code.setValue(args.itemData.article_category_code);
    }
  }

  getSlug(articleName: string, form: FormGroup) {
    if ((this._articleInput$.getValue() && this._articleInput$.getValue().article_name !== articleName.trim() && articleName.trim() !== '') || (!this._articleInput$.getValue() && articleName.trim() !== '')) {
      this.articleService.getSlug({article_name: articleName}).subscribe(data => {
        if (data && data.data) {
          form.controls.slug.setValue(data.data);
        }
      }, err => {
        
      });
    }
  }

  setTab(args) {
    this.selectedTab = args;
  }

  strReplace(text) {
    if (text !== null && text !== undefined) {
      return text.toString().replace(/-/g, '_');
    }
  }

  findDateFromArray(date) {
    const deliverableFound = this.articleDates.find(item => item.article_date_code === date);
    if (deliverableFound) {
      this.articleDateForm.addControl(deliverableFound.article_date_code.toString().replace(/-/g, '_'), new FormControl(''));
      this.articleDateForm.addControl('is_tentative_' + deliverableFound.article_date_code.toString().replace(/-/g, '_'), new FormControl(false));
      this.articleDateForm.addControl('status_code_' + deliverableFound.article_date_code.toString().replace(/-/g, '_'), new FormControl(null));
      this.articleDateForm.addControl('date_owner_code_' + deliverableFound.article_date_code.toString().replace(/-/g, '_'), new FormControl(null));


      this.isUpdate = false;
      return true;
    } else {
      return false;
    }
  }

  findDeliverableDateFromArray(date) {
    const deliverableFound = this.deliverableItemDates.find(item => item.deliverable_item_date_code === date);
    if (deliverableFound) {
      this.articleDateForm.addControl(deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(''));
      this.articleDateForm.addControl('is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(false));
      this.articleDateForm.addControl('status_code_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(null));
      this.articleDateForm.addControl('date_owner_code_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(null));


      this.isUpdate = false;
      return true;
    } else {
      return false;
    }
  }

  onDateChange(args, formControl: string, form: FormGroup) {
    // console.log(this.articleDateForm.value);
    if(args.value.getHours()==0){
    
      args.value.setHours(10)
      args.value.setMinutes(0)
      args.value.setSeconds(0)}
   
  }

  generateArticleDatesForm() {
    this.articleDateForm = this.formBuilder.group({});
  }
  beforeOpen(){
   
    this.userDropdowm.refresh()  
  }
  Complete(args){
  
    if(document.getElementById('grey')==null){
      // this.userDropdowm.addItem(uselist,0)
      // this.userDropdowm.getItems()[0].remove()
      // this.userDropdowm.refresh()
    }
    
   
  }
  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}

