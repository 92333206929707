import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Feature } from './feature';
import { AppService } from '../../../services/app.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../index';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { UserRequested } from '../../../store/auth/auth.actions';
import { environment } from 'src/environments/environment';
import { BaseComponent } from 'src/app/views/common/base.component';

@Injectable({
  providedIn: 'root'
})
export class LayoutGuardService implements CanActivate {
  authLoaded$ = this.store.pipe(select(store => store.auth.isUserLoaded));
  authAccount$ = this.store.pipe(select(store => store.auth.user));
  _environment = environment
  constructor(public router: Router, private app: AppService, private store: Store<AppState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('token');
    if (!token) {
      // localStorage.clear();
      localStorage.removeItem('token')
      localStorage.removeItem('userCode')
      localStorage.removeItem('layoutConfig')
      localStorage.removeItem('LAYOUT_CONFIG_LOCAL_STORAGE_KEY')
      localStorage.removeItem('metadata')
      localStorage.removeItem('selectDetails')
      localStorage.removeItem('filter')
      this.router.navigate(['/auth/login']);
      return false;
    }


    if (!route.data.feature && route.url.length > 0 && route.url[0].path !== 'log') {
      return true;
    }

    return this.authLoaded$.pipe(tap(loaded => {
      if (!loaded) {
        this.store.dispatch(new UserRequested());
      }
    }), filter(loaded => !!loaded), mergeMap(() => this.authAccount$), map(userAccount => {
      const meAccess = this.app.isAuthorized(route.data.feature, Feature.VIEW_ACCESS);



      if (route.url.length > 0 && route.url[0].path === 'log') {

        if (this._environment.usersAllowToSync.includes(userAccount.user_code)) {
          // this.router.navigate(['../']);

          return true;
        }
      }




      if (!meAccess) {
        if (!token) {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate(['../']);
        }
      } else {
        return meAccess;
      }


    }), first());
    //
    // const meAccess = this.app.isAuthorized(route.data.feature, Feature.VIEW_ACCESS);
    // if (!meAccess) {
    //   if (!token) {
    //     this.router.navigate(['/auth/login']);
    //   } else {
    //     this.router.navigate(['../']);
    //   }
    // } else {
    //   return meAccess;
    // }
  }
}
