import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tags} from '../../../../../../core/models/tags.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Company} from '../../../../../../core/models/company.model';
import {telephoneValidator, trimValidator, websiteValidator} from '../../../../../../app-validators';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core';
import {AddCompanyOrderLineItem, CompanyAdd, UpdateCompanyOrderLineItem} from '../../../../../../core/store/actions/company.actions';
import {ContactSetStatusNull} from '../../../../../../core/store/actions/contact.actions';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-order-line-item-form',
  templateUrl: './order-line-item.component.html',
  styleUrls: ['./order-line-item.component.scss']
})
export class OrderLineItemComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;

  @Input() orderItems: any;
  @Input() companyOrderCode: string;
  @Output() valueChange = new EventEmitter();

  orderLineItemForm: FormGroup;
  formSent: boolean;

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];

  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  successOrderLineItem$: Observable<boolean>;

  public orderItemFields: object;

  public _orderLineItemInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  @Input() set orderLineItemInput(mode: any | null) {
    this._orderLineItemInput$.next(mode);
  }

  get orderLineItemInput(): any | null {
    return this._orderLineItemInput$.getValue();
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.generateOrderLineItemForm();

    this.successOrderLineItem$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.successOrderLineItem$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });

    this.error$ = this.store.pipe(select(store => store.companies.error));
    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  generateOrderLineItemForm() {
    this.orderLineItemForm = this.formBuilder.group({
      order_item_code: new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.maxLength(10)]),
      amount: new FormControl('', [Validators.maxLength(15)]),
      description: new FormControl('', [Validators.maxLength(1000), Validators.required]),
    });
  }

  ngOnDestroy() {
    this._orderLineItemInput$.unsubscribe();
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.orderLineItemForm.reset();
    this.valueChange.emit('success');
    if (this.sidebarInstance) {
      this.sidebarInstance.hide();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItems) {
      this.orderItemFields = { value: 'order_item_code', text: 'order_item_name', dataSource: this.orderItems, child: 'children'};
    }

    if (this._orderLineItemInput$.getValue()) {
      this.orderLineItemForm.setValue({
        order_item_code: this._orderLineItemInput$.getValue().order_item ? [this._orderLineItemInput$.getValue().order_item.order_item_code] : null,
        quantity: this._orderLineItemInput$.getValue().quantity !== '' || this._orderLineItemInput$.getValue().quantity !== null  ? this._orderLineItemInput$.getValue().quantity : null,
        amount: this._orderLineItemInput$.getValue().amount ? this._orderLineItemInput$.getValue().amount : null,
        description: this._orderLineItemInput$.getValue().description ? this._orderLineItemInput$.getValue().description : null,
      });
    }
  }

  onAddOrderLineItem(form: FormGroup) {
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new AddCompanyOrderLineItem({
        order_item_code: this.orderLineItemForm.value.order_item_code[0],
        amount: this.orderLineItemForm.value.amount,
        quantity: this.orderLineItemForm.value.quantity,
        company_order_code: this.companyOrderCode,
        description: this.orderLineItemForm.value.description
      }));
    }
  }

  onUpdateOrderLineItem(form: FormGroup) {
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new UpdateCompanyOrderLineItem({
        order_line_item_code: this._orderLineItemInput$.getValue().order_line_item_code,
        order_item_code: this.orderLineItemForm.value.order_item_code[0],
        amount: this.orderLineItemForm.value.amount,
        quantity: this.orderLineItemForm.value.quantity,
        description: this.orderLineItemForm.value.description
      }));
    }
  }
  clearAll(){
    this.clearModal.hide()
  this.orderLineItemForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
