<ejs-sidebar
  #sidebarInstance
  id="sidebar-menu"
  style="visibility: hidden"
  type="Over"
  [width]="width"
  [position]="position"
  [enableGestures]="false"
  [showBackdrop]="true"
>
  <div
    class="offcanvas-close mt-n1 pr-5"
    id="event-attendies-add"
    style="float: right; padding: 48px"
  >
    <a
      href="javascript:;"
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      id="kt_quick_panel_close"
      (click)="closeSidebar()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">
    <h2>{{ isAddOrUpdate }} Attendees for Event</h2>
    <form
      id="formId"
      [formGroup]="eventAttendeeForm"
      #formDir="ngForm"
      class="form-horizontal"
      novalidate=""
      style="margin-top: 16px"
    >
      <div
        class="form-group sf-outline-input mb-10"
        [class.hide-label]="eventAttendeeForm.controls.contact_code.value == ''"
      >
        <ejs-multiselect
          #contact
          id="contact_code"
          formControlName="contact_code"
          mode="CheckBox"
          showClearButton="true"
          placeholder="Contact"
          cssClass="e-filled"
          floatLabelType="Auto"
          [dataSource]="contactList"
          [fields]="contactFields"
          [showClearButton]="true"
          [allowFiltering]="true"
          (filtering)="onFiltering($event, 'contact')"
          (blur)="eventAttendeeForm.controls.contact_code.markAsTouched()"
        ></ejs-multiselect>
        <div *ngIf="eventAttendeeForm.controls.contact_code.errors">
          <div
            *ngIf="
              eventAttendeeForm.controls.contact_code.errors.required &&
              eventAttendeeForm.controls.contact_code.touched
            "
            class="e-error"
          >
            Contact is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox
          id="eventComments"
          cssClass="e-filled"
          placeholder="Comments"
          formControlName="comment"
          floatLabelType="Auto"
          [multiline]="true"
          (blur)="eventAttendeeForm.controls.comment.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="eventAttendeeForm.controls.comment.errors">
          <div
            *ngIf="
              eventAttendeeForm.controls.address.errors.maxlength &&
              eventAttendeeForm.controls.address.touched
            "
            class="e-error"
          >
            Comment cannot be more than 1000 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox
          #checkbox
          formControlName="has_attended"
          label="Attended Event"
          labelPosition="After"
        ></ejs-checkbox>
      </div>

      <div *ngIf="eventAttendeeForm.invalid" class="e-error">
        Please fill all fields
      </div>
      <div *ngIf="error$ | async as error" class="e-error">
        {{ error.error }}
      </div>

      <div class="my-10 pb-20">
        <button
          id="resetbtn"
          class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
          type="button"
          data-ripple="true"
          (click)="clear()"
        >
          Clear
        </button>
        <button
          id="addSubmit"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
          type="submit"
          data-ripple="true"
          (click)="updateEventAttendees(eventAttendeeForm)"
          [disabled]="formSent || eventAttendeeForm.invalid"
        >
          {{ isAddOrUpdate }} Attendees for Event
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<ejs-dialog
  #clearModal
  [buttons]="dltButtons"
  header="Are you sure?"
  animationSettings="none"
  showCloseIcon="true"
  width="25%"
  [visible]="false"
>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
