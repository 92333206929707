import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-community-product-image-upload',
  templateUrl: './community-product-image-upload.component.html',
  styleUrls: ['./community-product-image-upload.component.scss']
})
export class CommunityProductImageUploadComponent implements OnInit {
 
  
  constructor() { }

  ngOnInit(): void {
  }
 
}
