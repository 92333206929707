import { Action } from '@ngrx/store';
import { Company } from '../../models/company.model';
import { TransactionHistory } from '../../models/transaction-history.model';
import { Activity } from '../../models/activity.model';
import { ContactActionTypes } from './contact.actions';
import { Project } from '../../models/project.model';
import { ProjectActionTypes } from './project.actions';
import { Order } from '../../models/order.model';
import { Contact } from '../../models/contact.model';

export enum CompanyActionTypes {
  LOAD = '[COMPANY] Load Action',
  LOAD_SUCCESS = '[COMPANY] Load Success Action',
  LOAD_FAILURE = '[COMPANY] Load Failure Action',

  ADD = '[COMPANY] Add Action',
  ADD_SUCCESS = '[COMPANY] Add Success Action',
  ADD_FAILURE = '[COMPANY] Add Failure Action',

  UPDATE = '[COMPANY] Update Action',
  UPDATE_SUCCESS = '[COMPANY] Update Success Action',
  UPDATE_FAILURE = '[COMPANY] Update Failure Action',

  DELETE = '[COMPANY] Delete Action',
  DELETE_SUCCESS = '[COMPANY] Delete Success Action',
  DELETE_FAILURE = '[COMPANY] Delete Failure Action',

  GET_CONTACTS = '[COMPANY] Get Contacts Action',
  GET_CONTACTS_SUCCESS = '[COMPANY] Get Contacts Success Action',
  GET_CONTACTS_FAILURE = '[COMPANY] Get Contacts Failure Action',

  FILTER = '[COMPANY] Filter Action',
  FILTER_SUCCESS = '[COMPANY] Filter Success Action',
  FILTER_FAILURE = '[COMPANY] Filter Failure Action',

  GET_COMPANY_ACTIVITY = '[COMPANY] Get Company Activity Action',
  GET_COMPANY_ACTIVITY_SUCCESS = '[COMPANY] Get Company Activity Success Action',
  GET_COMPANY_ACTIVITY_FAILURE = '[COMPANY] Get Company Activity Failure Action',

  ADD_COMPANY_ACTIVITY = '[COMPANY] Add Company Activity Action',
  ADD_COMPANY_ACTIVITY_SUCCESS = '[COMPANY] Add Company Activity Success Action',
  ADD_COMPANY_ACTIVITY_FAILURE = '[COMPANY] Add Company Activity Failure Action',

  UPDATE_COMPANY_ACTIVITY = '[COMPANY] Update Company Activity Action',
  UPDATE_COMPANY_ACTIVITY_SUCCESS = '[COMPANY] Update Company Activity Success Action',
  UPDATE_COMPANY_ACTIVITY_FAILURE = '[COMPANY] Update Company Activity Failure Action',

  DELETE_COMPANY_ACTIVITY = '[COMPANY] Delete Company Activity Action',
  DELETE_COMPANY_ACTIVITY_SUCCESS = '[COMPANY] Delete Company Activity Success Action',
  DELETE_COMPANY_ACTIVITY_FAILURE = '[COMPANY] Delete Company Activity Failure Action',

  ACTIVITY_FILTER = '[COMPANY] Activity Filter Action',
  ACTIVITY_FILTER_SUCCESS = '[COMPANY] Activity Filter Success Action',
  ACTIVITY_FILTER_FAILURE = '[COMPANY] Activity Filter Failure Action',

  GET_COMPANY_HISTORY = '[COMPANY] Get Company Transaction History Action',
  GET_COMPANY_HISTORY_SUCCESS = '[COMPANY] Get Company Transaction History Success Action',
  GET_COMPANY_HISTORY_FAILURE = '[COMPANY] Get Company Transaction History Failure Action',

  GET_PROJECTS = '[COMPANY] Get Projects for Company Action',
  GET_PROJECTS_SUCCESS = '[COMPANY] Get Projects for Company Success Action',
  GET_PROJECTS_FAILURE = '[COMPANY] Get Projects for Company Failure Action',

  PROJECT_FILTER = '[COMPANY] Project Filter for Company Action',
  PROJECT_FILTER_SUCCESS = '[COMPANY] Project Filter for Company Success Action',
  PROJECT_FILTER_FAILURE = '[COMPANY] Project Filter for Company Failure Action',

  COMPANY_ORDER = '[COMPANY] Company Order Action',
  COMPANY_ORDER_SUCCESS = '[COMPANY] Company Order Success Action',
  COMPANY_ORDER_FAILURE = '[COMPANY] Company Order Failure Action',

  COMPANY_DELETE_ORDER = '[COMPANY] Company Delete Order Action',
  COMPANY_DELETE_ORDER_SUCCESS = '[COMPANY] Company Delete Order Success Action',
  COMPANY_DELETE_ORDER_FAILURE = '[COMPANY] Company Delete Order Failure Action',

  COMPANY_ADD_ORDER = '[COMPANY] Company Add Order Action',
  COMPANY_ADD_ORDER_SUCCESS = '[COMPANY] Company Add Order Success Action',
  COMPANY_ADD_ORDER_FAILURE = '[COMPANY] Company Add Order Failure Action',

  COMPANY_UPDATE_ORDER = '[COMPANY] Company Update Order Action',
  COMPANY_UPDATE_ORDER_SUCCESS = '[COMPANY] Company Update Order Success Action',
  COMPANY_UPDATE_ORDER_FAILURE = '[COMPANY] Company Update Order Failure Action',

  SEARCH = '[COMPANY] Search Action',
  SEARCH_SUCCESS = '[COMPANY] Search Success Action',
  SEARCH_FAILURE = '[COMPANY] Search Failure Action',

  FILTER_RESET = '[COMPANY] FILTER RESET',
  SET_SUCCESS_NULL = '[COMPANY] Set status Null',
  SET_ERROR_NULL = '[COMPANY] Set Error Null',
  SET_COMPANIES_NULL = '[COMPANY] Set Companies Null',

  GET_COMPANY_ACTIVITY_HISTORY = '[COMPANY] Get Company Activity History Action',
  GET_COMPANY_ACTIVITY_HISTORY_SUCCESS = '[COMPANY] Get Company Activity History Success Action',
  GET_COMPANY_ACTIVITY_HISTORY_FAILURE = '[COMPANY] Get Company Activity History Failure Action',

  GET_ALL_COMPANY_ORDERS = '[COMPANY] Get All Company Orders Action',
  GET_ALL_COMPANY_ORDERS_SUCCESS = '[COMPANY] Get All Company Orders Success Action',
  GET_ALL_COMPANY_ORDERS_FAILURE = '[COMPANY] Get All Company Orders Failure Action',

  SEARCH_COMPANY = '[COMPANY] Search Company Action',
  SEARCH_COMPANY_SUCCESS = '[COMPANY] Search Company Success Action',
  SEARCH_COMPANY_FAILURE = '[COMPANY] Search Company Failure Action',

  GET_SOURCE_PROJECT = '[COMPANY] Get Source Project Action',
  GET_SOURCE_PROJECT_SUCCESS = '[COMPANY] Source Project Success Action',
  GET_SOURCE_PROJECT_FAILURE = '[COMPANY] Source Project Failure Action',

  GET_SINGLE_COMPANY_ORDER = '[COMPANY] Get Single Company Order Action',
  GET_SINGLE_COMPANY_ORDER_SUCCESS = '[COMPANY] Get Single Company Order Success Action',
  GET_SINGLE_COMPANY_ORDER_FAILURE = '[COMPANY] Get Single Company Order Failure Action',

  GET_COMPANY_ORDER_LINE_ITEM = '[COMPANY] Get Company Order Line Item Action',
  GET_COMPANY_ORDER_LINE_ITEM_SUCCESS = '[COMPANY] Get Company Order Line Item Success Action',
  GET_COMPANY_ORDER_LINE_ITEM_FAILURE = '[COMPANY] Get Company Order Line Item Failure Action',

  ADD_COMPANY_ORDER_LINE_ITEM = '[COMPANY] Add Company Order Line Item Action',
  ADD_COMPANY_ORDER_LINE_ITEM_SUCCESS = '[COMPANY] Add Company Order Line Item Success Action',
  ADD_COMPANY_ORDER_LINE_ITEM_FAILURE = '[COMPANY] Add Company Order Line Item Failure Action',

  UPDATE_COMPANY_ORDER_LINE_ITEM = '[COMPANY] Update Company Order Line Item Action',
  UPDATE_COMPANY_ORDER_LINE_ITEM_SUCCESS = '[COMPANY] Update Company Order Line Item Success Action',
  UPDATE_COMPANY_ORDER_LINE_ITEM_FAILURE = '[COMPANY] Update Company Order Line Item Failure Action',

  DELETE_COMPANY_ORDER_LINE_ITEM = '[COMPANY] Delete Company Order Line Item Action',
  DELETE_COMPANY_ORDER_LINE_ITEM_SUCCESS = '[COMPANY] Delete Company Order Line Item Success Action',
  DELETE_COMPANY_ORDER_LINE_ITEM_FAILURE = '[COMPANY] Delete Company Order Line Item Failure Action',

  //  Deliverables
  GET_COMPANY_ORDER_DELIVERABLE = '[COMPANY] Get Company Order Deliverable Action',
  GET_COMPANY_ORDER_DELIVERABLE_SUCCESS = '[COMPANY] Get Company Order Deliverable Success Action',
  GET_COMPANY_ORDER_DELIVERABLE_FAILURE = '[COMPANY] Get Company Order Deliverable Failure Action',

  ADD_COMPANY_ORDER_DELIVERABLE = '[COMPANY] Add Company Order Deliverable Action',
  ADD_COMPANY_ORDER_DELIVERABLE_SUCCESS = '[COMPANY] Add Company Order Deliverable Success Action',
  ADD_COMPANY_ORDER_DELIVERABLE_FAILURE = '[COMPANY] Add Company Order Deliverable Failure Action',

  UPDATE_COMPANY_ORDER_DELIVERABLE = '[COMPANY] Update Company Order Deliverable Action',
  UPDATE_COMPANY_ORDER_DELIVERABLE_SUCCESS = '[COMPANY] Update Company Order Deliverable Success Action',
  UPDATE_COMPANY_ORDER_DELIVERABLE_FAILURE = '[COMPANY] Update Company Order Deliverable Failure Action',

  UPDATE_TABLE_COLUMNS_SELECTION = '[COMPANY] Update Table Columns Selection Action',
  UPDATE_TABLE_COLUMNS_SELECTION_SUCCESS = '[COMPANY] Update Table Columns Selection Success Action',
  UPDATE_TABLE_COLUMNS_SELECTION_FAILURE = '[COMPANY] Update Table Columns Selection Failure Action',

  DELETE_COMPANY_ORDER_DELIVERABLE = '[COMPANY] Delete Company Order Deliverable Action',
  DELETE_COMPANY_ORDER_DELIVERABLE_SUCCESS = '[COMPANY] Delete Company Order Deliverable Success Action',
  DELETE_COMPANY_ORDER_DELIVERABLE_FAILURE = '[COMPANY] Delete Company Order Deliverable Failure Action',

  GET_DELIVERABLE_NOTES = '[COMPANY] Get Deliverable Notes Action',
  GET_DELIVERABLE_NOTES_SUCCESS = '[COMPANY] Get Deliverable Notes Success Action',
  GET_DELIVERABLE_NOTES_FAILURE = '[COMPANY] Get Deliverable Notes Failure Action',

  ADD_DELIVERABLE_NOTES = '[COMPANY] Add Deliverable Notes Action',
  ADD_DELIVERABLE_NOTES_SUCCESS = '[COMPANY] Add Deliverable Notes Success Action',
  ADD_DELIVERABLE_NOTES_FAILURE = '[COMPANY] Add Deliverable Notes Failure Action',

  UPDATE_DELIVERABLE_NOTES = '[COMPANY] Update Deliverable Notes Action',
  UPDATE_DELIVERABLE_NOTES_SUCCESS = '[COMPANY] Update Deliverable Notes Success Action',
  UPDATE_DELIVERABLE_NOTES_FAILURE = '[COMPANY] Update Deliverable Notes Failure Action',

  DELETE_DELIVERABLE_NOTES = '[COMPANY] Delete Deliverable Notes Action',
  DELETE_DELIVERABLE_NOTES_SUCCESS = '[COMPANY] Delete Deliverable Notes Success Action',
  DELETE_DELIVERABLE_NOTES_FAILURE = '[COMPANY] Delete Deliverable Notes Failure Action',

  GET_COMPANY_ORDER_HISTORY = '[COMPANY] Get Order Transaction History Action',
  GET_COMPANY_ORDER_HISTORY_SUCCESS = '[COMPANY] Get Order Transaction History Success Action',
  GET_COMPANY_ORDER_HISTORY_FAILURE = '[COMPANY] Get Order Transaction History Failure Action',

  UPDATE_SINGLE_COMPANY_ORDER_SUCCESS = '[COMPANY] Update Single Company Order Success Action',

  GET_COMPANY_ORDER_FILTER_RESULTS = '[COMPANY] Get Order Filter Results Action',
  GET_COMPANY_ORDER_FILTER_RESULTS_SUCCESS = '[COMPANY] Get Order Filter Results Success Action',
  GET_COMPANY_ORDER_FILTER_RESULTS_FAILURE = '[COMPANY] Get Order Filter Results Failure Action',

  GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS = '[COMPANY] Get Deliverables Filter Results Action',
  GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS_SUCCESS = '[COMPANY] Get Deliverables Filter Results Success Action',
  GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS_FAILURE = '[COMPANY] Get Deliverables Filter Results Failure Action',

  /*UPDATE_COMPANY_ORDER_DELIVERABLES_FILTER_USER_SETTINGS_RESULTS = '[COMPANY] Update Deliverables Filter Results Action',
  UPDATE_COMPANY_ORDER_DELIVERABLES_FILTER_USER_SETTINGS_RESULTS_SUCCESS = '[COMPANY] Update Deliverables Filter Results Success Action',
  UPDATE_COMPANY_ORDER_DELIVERABLES_FILTER_USER_SETTINGS_RESULTS_FAILURE = '[COMPANY] Update Deliverables Filter Results Failure Action',*/

  GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS = '[COMPANY] Get Line Items Filter Results Action',
  GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS_SUCCESS = '[COMPANY] Get Line Items Filter Results Success Action',
  GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS_FAILURE = '[COMPANY] Get Line Items Filter Results Failure Action',

  PROJECT_BULK_EDIT = '[COMPANY] Project Bulk Edit Action',
  PROJECT_BULK_EDIT_SUCCESS = '[COMPANY] Project Bulk Edit Success Action',
  PROJECT_BULK_EDIT_FAILURE = '[COMPANY] Project Bulk Edit Failure Action',

  LOAD_COMPANY_GENERAL = '[COMPANY] Load Company General Action',
  LOAD_COMPANY_GENERAL_SUCCESS = '[COMPANY] Load Company General Success Action',
  LOAD_COMPANY_GENERAL_FAILURE = '[COMPANY] Load Company General Failure Action',

  UPDATE_COMPANY_GENERAL = '[COMPANY] Update Company General Action',
  UPDATE_COMPANY_GENERAL_SUCCESS = '[COMPANY] Update Company General Success Action',
  UPDATE_COMPANY_GENERAL_FAILURE = '[COMPANY] Update Company General Failure Action',

  LOAD_COMPANY_LTD_SETTINGS = '[COMPANY] Load Company LTD Settings Action',
  LOAD_COMPANY_LTD_SETTINGS_SUCCESS = '[COMPANY] Load Company LTD Settings Success Action',
  LOAD_COMPANY_LTD_SETTINGS_FAILURE = '[COMPANY] Load Company LTD Settings Failure Action',

  UPDATE_COMPANY_LTD_SETTINGS = '[COMPANY] Update Company LTD Settings Action',
  UPDATE_COMPANY_LTD_SETTINGS_SUCCESS = '[COMPANY] Update Company LTD Settings Success Action',
  UPDATE_COMPANY_LTD_SETTINGS_FAILURE = '[COMPANY] Update Company LTD Settings Failure Action',

  GET_CONTACTS_WITHOUT_REFRESH = '[COMPANY] Get Contacts Without Refresh Action',
  UPDATE_SYNC_STATUS = '[COMPANY] UPDATE SYNC STATUS',

}

export class CompanyLoad implements Action {
  readonly type = CompanyActionTypes.LOAD;

  constructor(public payload?: { page_size?: number, page_no?: number, sort_order?: string, sort_by?: string }) {
  }
}

export class CompanyLoadSuccess implements Action {
  readonly type = CompanyActionTypes.LOAD_SUCCESS;

  constructor(public payload: { data?: [], columns: [], total_count: number, page_size: number, page_no: number }) {
  }
}

export class CompanyLoadFailure implements Action {
  readonly type = CompanyActionTypes.LOAD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyAdd implements Action {
  readonly type = CompanyActionTypes.ADD;

  constructor(public payload: {
    company_name: string, display_name: string, address: string, city_name: string, country: string, website: string,
    tel_no: string, industry_type: [], accountOwner: string,account_manager:string, company_tag: string, excerpt: string, email: string, slug: string,
    member_since: string, p_o_box: string, company_logo_reference_id: string,
    primary_community_type_code: string, account_owner_activity_note?:string ,
    account_manager_activity_note?:string
  }) {
  }
}

export class CompanyAddSuccess implements Action {
  readonly type = CompanyActionTypes.ADD_SUCCESS;

  constructor(public payload: { company: Company }) {
  }
}

export class CompanyAddFailure implements Action {
  readonly type = CompanyActionTypes.ADD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyUpdate implements Action {
  readonly type = CompanyActionTypes.UPDATE;

  constructor(public payload: { index: string, company: {} }) {
  }
}

export class CompanyUpdateSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { index: string, company: Company }) {
  }
}

export class CompanyUpdateFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyDelete implements Action {
  readonly type = CompanyActionTypes.DELETE;

  constructor(public payload: { companyCode: string }) {
  }
}

export class CompanyDeleteSuccess implements Action {
  readonly type = CompanyActionTypes.DELETE_SUCCESS;

  constructor(public payload: { companyCode: string }) {
  }
}

export class CompanyDeleteFailure implements Action {
  readonly type = CompanyActionTypes.DELETE_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyGetContacts implements Action {
  readonly type = CompanyActionTypes.GET_CONTACTS;

  constructor(public payload: { companyCode: string, contacts: [] }) {
  }
}

export class CompanyGetContactsSuccess implements Action {
  readonly type = CompanyActionTypes.GET_CONTACTS_SUCCESS;

  constructor(public payload: { company: Company }) {
  }
}

export class CompanyGetContactsFailure implements Action {
  readonly type = CompanyActionTypes.GET_CONTACTS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyGetContactsWithoutRefresh implements Action {
  readonly type = CompanyActionTypes.GET_CONTACTS_WITHOUT_REFRESH;

  constructor(public payload: { companyCode: string, contacts: [] }) {
  }
}

export class CompanyUpdateSyncStatus implements Action {
  readonly type = CompanyActionTypes.UPDATE_SYNC_STATUS;
  constructor(public payload: { company_last_sync: any }) {
  }
}

export class CompanyFilter implements Action {
  readonly type = CompanyActionTypes.FILTER;

  constructor(public payload: {
    companyName?: string, industry_type?: [], country?: [], assigned_to?: string, tags?: string[],
    page_no?: number, page_size?: number
  }) {
  }
}

export class CompanyFilterSuccess implements Action {
  readonly type = CompanyActionTypes.FILTER_SUCCESS;
  constructor(public payload: { data?: [], columns: [], total_count: number, page_size: number, page_no: number }) {
  }
}

export class CompanyFilterFailure implements Action {
  readonly type = CompanyActionTypes.FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetCompanyActivity implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ACTIVITY;

  constructor(public payload: { companyCode: string, entity: string }) {
  }
}

export class GetCompanyActivitySuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ACTIVITY_SUCCESS;

  constructor(public payload: { companyCode: string, activity: [] }) {
  }
}

export class GetCompanyActivityFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class AddCompanyActivity implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ACTIVITY;

  constructor(public payload: {
    company_code: string, activity_type: string, activityTitle: string, activityNote: string,
    userParticipants: string[], contactParticipants: string[], meeting_start_time: string, meeting_end_time: string,
    meeting_date: string, activityTags: string[], entity: string
  }) {
  }
}

export class AddCompanyActivitySuccess implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ACTIVITY_SUCCESS;

  constructor(public payload: { activity: Activity }) {
  }
}

export class AddCompanyActivityFailure implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteCompanyActivity implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ACTIVITY;

  constructor(public payload: { activity_code: string, entity: string }) {
  }
}

export class DeleteCompanyActivitySuccess implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ACTIVITY_SUCCESS;

  constructor(public payload: { activity_code: string }) {
  }
}

export class DeleteCompanyActivityFailure implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateCompanyActivity implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ACTIVITY;

  constructor(public payload: { activity_code: string, companyActivity: {}, entity: string }) {
  }
}

export class UpdateCompanyActivitySuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ACTIVITY_SUCCESS;

  constructor(public payload: { activity_code: string, activity: Activity }) {
  }
}

export class UpdateCompanyActivityFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ACTIVITY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyActivityFilter implements Action {
  readonly type = CompanyActionTypes.ACTIVITY_FILTER;

  constructor(public payload: {
    filterValues: {
      activity_title: string, activity_type: [], participants: [], tags: string[],
      meeting_date: string
    }, entity: string, code: string
  }) {
  }
}

export class CompanyActivityFilterSuccess implements Action {
  readonly type = CompanyActionTypes.ACTIVITY_FILTER_SUCCESS;

  constructor(public payload: Array<Activity>) {
  }
}

export class CompanyActivityFilterFailure implements Action {
  readonly type = CompanyActionTypes.ACTIVITY_FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetCompanyHistory implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_HISTORY;

  constructor(public payload: { companyCode: string }) {
  }
}

export class GetCompanyHistorySuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_HISTORY_SUCCESS;

  constructor(public payload: { companyCode: string, history: TransactionHistory }) {
  }
}

export class GetCompanyHistoryFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_HISTORY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanySetStatusNull implements Action {
  readonly type = CompanyActionTypes.SET_SUCCESS_NULL;

  constructor() {
  }
}

export class CompanySetErrorNull implements Action {
  readonly type = CompanyActionTypes.SET_ERROR_NULL;

  constructor() {
  }
}

export class CompanySetCompaniesNull implements Action {
  readonly type = CompanyActionTypes.SET_COMPANIES_NULL;
}

export class CompanyGetProjects implements Action {
  readonly type = CompanyActionTypes.GET_PROJECTS;

  constructor(public payload: { project_company_code: string }) {
  }
}

export class CompanyGetProjectsSuccess implements Action {
  readonly type = CompanyActionTypes.GET_PROJECTS_SUCCESS;

  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class CompanyGetProjectsFailure implements Action {
  readonly type = CompanyActionTypes.GET_PROJECTS_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyGetSourceProjects implements Action {
  readonly type = CompanyActionTypes.GET_SOURCE_PROJECT;

  constructor(public payload: { project_company_code_source: string }) {
  }
}

export class CompanyGetSourceProjectsSuccess implements Action {
  readonly type = CompanyActionTypes.GET_SOURCE_PROJECT_SUCCESS;

  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class CompanyGetSourceProjectsFailure implements Action {
  readonly type = CompanyActionTypes.GET_SOURCE_PROJECT_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyProjectFilter implements Action {
  readonly type = CompanyActionTypes.PROJECT_FILTER;

  constructor(public payload: {
    company_code: string, project_name?: string, reference_id?: string, project_type?: [], country?: [],
    tags?: string[], assigned_to?: string, proposed_post_date?: string, status?: string[], page_no?: number, page_size?: number,
    sort_by?: string, sort_order?: string
  }) {
  }
}

export class CompanyProjectFilterSuccess implements Action {
  readonly type = CompanyActionTypes.PROJECT_FILTER_SUCCESS;

  constructor(public payload: {
    data?: [],
    columns: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  }) {
  }
}

export class CompanyProjectFilterFailure implements Action {
  readonly type = CompanyActionTypes.PROJECT_FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanySearch implements Action {
  readonly type = CompanyActionTypes.SEARCH;

  constructor(public payload: { search?: string, page_no?: number, page_size?: number }) {
  }
}

export class CompanySearchSuccess implements Action {
  readonly type = CompanyActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { data?: [], columns: [], total_count: number, page_size: number, page_no: number }) {
  }
}

export class CompanySearchFailure implements Action {
  readonly type = CompanyActionTypes.SEARCH_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyFilterReset implements Action {
  readonly type = CompanyActionTypes.FILTER_RESET;
}

export class CompanyOrder implements Action {
  readonly type = CompanyActionTypes.COMPANY_ORDER;

  constructor(public payload: { company_code: string, page_no?: number, page_size?: number }) {
  }
}

export class CompanyOrderSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_ORDER_SUCCESS;

  constructor(public payload: Array<Order>) {
  }
}

export class CompanyOrderFailure implements Action {
  readonly type = CompanyActionTypes.COMPANY_ORDER_FAILURE;
  constructor(public payload: Error) {
  }
}

export class GetAllCompanyOrders implements Action {
  readonly type = CompanyActionTypes.GET_ALL_COMPANY_ORDERS;
  constructor(public payload: { page_no?: number, page_size?: number ,global?:any}) {
  }
}

export class GetAllCompanyOrdersSuccess implements Action {
  readonly type = CompanyActionTypes.GET_ALL_COMPANY_ORDERS_SUCCESS;
  constructor(public payload: { total_count: number, result: Array<Order>, page_no?: number, page_size?: number  }) {
  }
}

export class GetAllCompanyOrdersFailure implements Action {
  readonly type = CompanyActionTypes.GET_ALL_COMPANY_ORDERS_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyAddOrder implements Action {
  readonly type = CompanyActionTypes.COMPANY_ADD_ORDER;

  constructor(public payload: {
    company_code: string, order_name: string, amount: string, start_date: string, end_date: string,
    notes: string, start_free_period_date: string, end_free_period_date: string, currency_code: string, order_owner_code: string,
    order_status_code: string,deal_type:string,
  }) {
  }
}

export class CompanyAddOrderSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_ADD_ORDER_SUCCESS;

  constructor(public payload: Order) {
  }
}

export class CompanyAddOrderFailure implements Action {
  readonly type = CompanyActionTypes.COMPANY_ADD_ORDER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyUpdateOrder implements Action {
  readonly type = CompanyActionTypes.COMPANY_UPDATE_ORDER;

  constructor(public payload: {
    company_order_code?: string, company_code?: string, order_name?: string, amount?: string, start_date?: string, end_date?: string,
    notes?: string, start_free_period_date?: string, end_free_period_date?: string, drive_link?: string, contact_code?: string,
    order_role_code?: string, company_order_drive_link_code?: string, company_order_contact_role_code?: string, page?: string,
    currency_code?: string, order_owner_code?: string, order_status_code?: string,deal_type?:string
  }) {
  }
}

export class CompanyUpdateOrderSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_UPDATE_ORDER_SUCCESS;
  constructor(public payload: { order: Order, page: string }) {
  }
}

export class CompanyUpdateOrderFailure implements Action {
  readonly type = CompanyActionTypes.COMPANY_UPDATE_ORDER_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyUpdateSingleOrderSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_SINGLE_COMPANY_ORDER_SUCCESS;
  constructor(public payload: { order: Order }) {
  }
}

export class CompanyDeleteOrder implements Action {
  readonly type = CompanyActionTypes.COMPANY_DELETE_ORDER;

  constructor(public payload: { company_code: string, company_order_code: string }) {
  }
}

export class CompanyDeleteOrderSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_DELETE_ORDER_SUCCESS;

  constructor(public payload: { company_order_code: string }) {
  }
}

export class CompanyDeleteOrderFailure implements Action {
  readonly type = CompanyActionTypes.COMPANY_DELETE_ORDER_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetCompanyActivityHistory implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY;
  constructor(public payload: { companyCode: string, history: TransactionHistory }) { }
}

export class GetCompanyActivityHistorySuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY_SUCCESS;
  constructor(public payload: { companyCode: string, history: TransactionHistory }) { }
}

export class GetCompanyActivityHistoryFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY_FAILURE;
  constructor(public payload: Error) { }
}

export class GetSearchedCompany implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY;
  constructor(public payload: { company_name?: string }) { }
}

export class GetSearchedCompanySuccess implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY_SUCCESS;
  constructor(public payload: { total_count: number, result: Array<Company>, page_no?: number, page_size?: number }) { }
}

export class GetSearchedCompanyFailure implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY_FAILURE;
  constructor(public payload: Error) { }
}

export class GetSingleCompanyOrder implements Action {
  readonly type = CompanyActionTypes.GET_SINGLE_COMPANY_ORDER;
  constructor(public payload: { company_order_code: string, company_code: string }) { }
}

export class GetSingleCompanyOrderSuccess implements Action {
  readonly type = CompanyActionTypes.GET_SINGLE_COMPANY_ORDER_SUCCESS;
  constructor(public payload: { company_order: Order }) { }
}

export class GetSingleCompanyOrderFailure implements Action {
  readonly type = CompanyActionTypes.GET_SINGLE_COMPANY_ORDER_FAILURE;
  constructor(public payload: Error) { }
}

export class GetCompanyOrderLineItem implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM;
  constructor(public payload: { company_order_code: string, page_size?: number, page_no?: number }) { }
}

export class GetCompanyOrderLineItemSuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM_SUCCESS;
  constructor(public payload: { company_order: Order }) { }
}

export class GetCompanyOrderLineItemFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM_FAILURE;
  constructor(public payload: Error) { }
}

export class AddCompanyOrderLineItem implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM;
  constructor(public payload: { company_order_code: string, order_item_code: string, amount: string, quantity: string, description: string }) { }
}

export class AddCompanyOrderLineItemSuccess implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM_SUCCESS;
  constructor(public payload: { company_order: Order }) { }
}

export class AddCompanyOrderLineItemFailure implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM_FAILURE;
  constructor(public payload: Error) { }
}

export class UpdateCompanyOrderLineItem implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM;
  constructor(public payload: { order_line_item_code: string, order_item_code: string, amount: string, quantity: string, description: string }) { }
}

export class UpdateCompanyOrderLineItemSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM_SUCCESS;

  constructor(public payload: { company_order: any, order_line_item_code: string }) { }
}

export class UpdateCompanyOrderLineItemFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM_FAILURE;
  constructor(public payload: Error) { }
}

export class DeleteCompanyOrderLineItem implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM;
  constructor(public payload: { order_line_item_code: string }) { }
}

export class DeleteCompanyOrderLineItemSuccess implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM_SUCCESS;
  constructor(public payload: { order_line_item_code: string }) { }
}

export class DeleteCompanyOrderLineItemFailure implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM_FAILURE;
  constructor(public payload: Error) { }
}

// Deliverables
export class GetCompanyOrderDeliverable implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE;
  constructor(public payload: { company_order_code?: string, page_size?: number, page_no?: number,global?:any }) { }
}

export class GetCompanyOrderDeliverableSuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE_SUCCESS;
  constructor(public payload: { company_order: Order }) { }
}

export class GetCompanyOrderDeliverableFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE_FAILURE;
  constructor(public payload: Error) { }
}

export class AddCompanyOrderDeliverable implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE;

  constructor(public payload: {
    end_date: string; deliverable_owner_code: any; company_order_code: string; description: any;
    deliverable_status_code: any; order_line_item_code: any; is_external_provider_yn: number; deliverable_item_code: any;
    cost_center_code: any; start_date: string, dates?: any, expiry_date: string, article_code: any[]
  }) { }
}

export class AddCompanyOrderDeliverableSuccess implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE_SUCCESS;
  constructor(public payload: { company_order: any }) { }
}

export class AddCompanyOrderDeliverableFailure implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE_FAILURE;
  constructor(public payload: Error) { }
}

export class UpdateCompanyOrderDeliverable implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE;

  constructor(public payload: {
    end_date: string; deliverable_owner_code: any; description: any; deliverable_status_code: any;
    order_line_item_code: any; is_external_provider_yn: number; deliverable_code: any; deliverable_item_code: any;
    cost_center_code: any; start_date: string, page?: string, deliverable_note?: any, dates?: any, expiry_date: string, article_code: any[]
  }) { }
}

export class UpdateCompanyOrderDeliverableSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE_SUCCESS;

  constructor(public payload: { company_order: any, deliverable_code: string, page?: string }) { }
}

export class UpdateCompanyOrderDeliverableFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE_FAILURE;
  constructor(public payload: Error) { }
}

export class UpdateCompanyOrderColumnSelection implements Action {
  readonly type = CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION;

  constructor(public payload: {
    setting_value: string; batchId: string, setting_key_code: string
  }) { }
}

export class UpdateCompanyOrderColumnSelectionSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION_SUCCESS;

  constructor(public payload: {
    setting_value: string
    setting_key_code: string
  }) { }
}

export class UpdateCompanyOrderColumnSelectionFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION_FAILURE;

  constructor(public payload: Error) {
  }
}

export class DeleteCompanyOrderDeliverable implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE;
  constructor(public payload: { deliverable_code: string }) { }
}

export class DeleteCompanyOrderDeliverableSuccess implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE_SUCCESS;
  constructor(public payload: { deliverable_code: string }) { }
}

export class DeleteCompanyOrderDeliverableFailure implements Action {
  readonly type = CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE_FAILURE;
  constructor(public payload: Error) { }
}

export class GetDeliverableNotes implements Action {
  readonly type = CompanyActionTypes.GET_DELIVERABLE_NOTES;
  constructor(public payload: { deliverable_code: string, page_size: number, page_no: number, page: string }) { }
}

export class GetDeliverableNotesSuccess implements Action {
  readonly type = CompanyActionTypes.GET_DELIVERABLE_NOTES_SUCCESS;
  constructor(public payload: { deliverable_code: string, notes: any, page: string }) { }
}

export class GetDeliverableNotesFailure implements Action {
  readonly type = CompanyActionTypes.GET_DELIVERABLE_NOTES_FAILURE;
  constructor(public payload: Error) { }
}

export class AddDeliverableNotes implements Action {
  readonly type = CompanyActionTypes.ADD_DELIVERABLE_NOTES;
  constructor(public payload: { deliverable_code: string, note: string, page: string }) { }
}

export class AddDeliverableNotesSuccess implements Action {
  readonly type = CompanyActionTypes.ADD_DELIVERABLE_NOTES_SUCCESS;
  constructor(public payload: { deliverable_code: string, notes: any, page: string }) { }
}

export class AddDeliverableNotesFailure implements Action {
  readonly type = CompanyActionTypes.ADD_DELIVERABLE_NOTES_FAILURE;
  constructor(public payload: Error) { }
}

export class UpdateDeliverableNotes implements Action {
  readonly type = CompanyActionTypes.UPDATE_DELIVERABLE_NOTES;
  constructor(public payload: { deliverable_code: string, deliverable_note_code: string, note: string }) { }
}

export class UpdateDeliverableNotesSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_DELIVERABLE_NOTES_SUCCESS;
  constructor(public payload: { deliverable_code: string, deliverable_note_code: string, note: string }) { }
}

export class UpdateDeliverableNotesFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_DELIVERABLE_NOTES_FAILURE;
  constructor(public payload: Error) { }
}

export class DeleteDeliverableNotes implements Action {
  readonly type = CompanyActionTypes.DELETE_DELIVERABLE_NOTES;
  constructor(public payload: { deliverable_code: string, deliverable_note_code: string }) { }
}

export class DeleteDeliverableNotesSuccess implements Action {
  readonly type = CompanyActionTypes.DELETE_DELIVERABLE_NOTES_SUCCESS;
  constructor(public payload: { deliverable_code: string, deliverable_note_code: string }) { }
}

export class DeleteDeliverableNotesFailure implements Action {
  readonly type = CompanyActionTypes.DELETE_DELIVERABLE_NOTES_FAILURE;
  constructor(public payload: Error) { }
}


export class GetCompanyOrderHistory implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_HISTORY;

  constructor(public payload: { company_order_code: string }) {
  }
}

export class GetCompanyOrderHistorySuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_HISTORY_SUCCESS;

  constructor(public payload: { company_order_code: string, history: TransactionHistory }) {
  }
}

export class GetCompanyOrderHistoryFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_HISTORY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyOrderFilter implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS;
  constructor(public payload: {
    order_name?: string, company_code?: string, created_at_start?: string, created_at_end?: string,
    updated_at_start?: string, updated_at_end?: string, created_by?: string, updated_by?: string, amount_start?: string, amount_end?: string,
    start_date_start?: string, start_date_end?: string, end_date_start?: string, end_date_end?: string, free_period_start?: string,
    free_period_end?: string, subscription_stage: string, page?: string
  }) { }
}

export class CompanyOrderFilterSuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS_SUCCESS;
  constructor(public payload: {
    page: string, total_count: number, result: Array<Order>, page_no?: number, page_size?: number
  }) {
  }
}

export class CompanyOrderFilterFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyOrderDeliverablesFilter implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS;
  constructor(public payload: {
    deliverable_status_code?: string, deliverable_item_code?: string, cost_center_code?: string, company_code?: string,
    created_at_start?: string, created_at_end?: string,
    updated_at_start?: string, updated_at_end?: string, created_by?: string, updated_by?: string,
    start_date?: string, end_date?: string
  }) { }
}

export class CompanyOrderDeliverablesFilterSuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS_SUCCESS;
  constructor(public payload: {
    page: string, total_count: number, result: Array<Order>, page_no?: number, page_size?: number, user_settings?: Array<any>,
    column_list?: Array<any>
  }) {
  }
}

export class CompanyOrderDeliverablesFilterFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyOrderLineItemsFilter implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS;
  constructor(public payload: {
    order_item_code: string, company_code?: string,
    created_at_start?: string, created_at_end?: string,
    updated_at_start?: string, updated_at_end?: string, created_by?: string, updated_by?: string
  }) { }
}

export class CompanyOrderLineItemsFilterSuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS_SUCCESS;
  constructor(public payload: {
    page: string, total_count: number, result: Array<Order>, page_no?: number, page_size?: number
  }) {
  }
}

export class CompanyOrderLineItemsFilterFailure implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS_FAILURE;
  constructor(public payload: Error) {
  }
}

export class CompanyProjectBulkEdit implements Action {
  readonly type = CompanyActionTypes.PROJECT_BULK_EDIT;
  constructor(public payload: { request: any }) { }
}

export class CompanyProjectBulkEditSuccess implements Action {
  readonly type = CompanyActionTypes.PROJECT_BULK_EDIT_SUCCESS;
  constructor(public payload: Array<Project>) { }
}

export class CompanyProjectBulkEditFailure implements Action {
  readonly type = CompanyActionTypes.PROJECT_BULK_EDIT_FAILURE;
  constructor(public payload: Error) { }
}

export class CompanyLoadGeneral implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_GENERAL;

  constructor(public payload?: { company_code?: string }) {
  }
}

export class CompanyLoadGeneralSuccess implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_GENERAL_SUCCESS;

  constructor(public payload: { company: any }) {
  }
}

export class CompanyLoadGeneralFailure implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_GENERAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyUpdateGeneral implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_GENERAL;

  constructor(public payload?: { company_code: string, description?: string, socialMedia: any[], external_tag: any[], internal_tag: any[] }) {
  }
}

export class CompanyUpdateGeneralSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_GENERAL_SUCCESS;

  constructor(public payload: { company: any }) {
  }
}

export class CompanyUpdateGeneralFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_GENERAL_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyLoadLTDSettings implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS;

  constructor(public payload?: { company_code?: string }) {
  }
}

export class CompanyLoadLTDSettingsSuccess implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS_SUCCESS;

  constructor(public payload: { company: any }) {
  }
}

export class CompanyLoadLTDSettingsFailure implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CompanyUpdateLTDSettings implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS;

  constructor(public payload?: {
    company_code: string, address?: string, gps_latitude: string, gps_longitude: string, is_gps_active_yn: number,
    request_form_email_address: string, brands: any[], default_tab: string, is_premium_company_yn: number, is_hide_socials_yn: number,
    featured_image_reference_id: string, visibility: string, password_protected_password: string
  }) {
  }
}

export class CompanyUpdateLTDSettingsSuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS_SUCCESS;

  constructor(public payload: { company: any }) {
  }
}

export class CompanyUpdateLTDSettingsFailure implements Action {
  readonly type = CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS_FAILURE;

  constructor(public payload: Error) {
  }
}


export type CompanyActions = CompanyAdd | CompanyAddSuccess | CompanyAddFailure | CompanyLoad | CompanyLoadSuccess | CompanyLoadFailure |
  CompanyUpdate | CompanyUpdateSuccess | CompanyUpdateFailure | CompanyDelete | CompanyDeleteSuccess | CompanyDeleteFailure |
  CompanyGetContacts | CompanyGetContactsSuccess | CompanyGetContactsFailure | CompanyFilter | CompanyFilterSuccess | CompanyFilterFailure |
  GetCompanyActivity | GetCompanyActivitySuccess | GetCompanyActivityFailure | AddCompanyActivity | AddCompanyActivitySuccess |
  AddCompanyActivityFailure | DeleteCompanyActivity | DeleteCompanyActivitySuccess | DeleteCompanyActivityFailure | UpdateCompanyActivity |
  UpdateCompanyActivitySuccess | UpdateCompanyActivityFailure | CompanyActivityFilter | CompanyActivityFilterSuccess |
  CompanyActivityFilterFailure | GetCompanyHistory | GetCompanyHistorySuccess | GetCompanyHistoryFailure | CompanySetStatusNull |
  CompanySetCompaniesNull | CompanyGetProjects | CompanyGetProjectsSuccess | CompanyGetProjectsFailure | CompanyProjectFilter |
  CompanyProjectFilterSuccess | CompanyProjectFilterFailure | CompanySearch | CompanySearchSuccess | CompanySearchFailure |
  CompanyFilterReset | CompanySetErrorNull | CompanyOrder | CompanyOrderSuccess | CompanyOrderFailure | CompanyDeleteOrder |
  CompanyDeleteOrderSuccess | CompanyDeleteOrderFailure | CompanyAddOrder | CompanyAddOrderSuccess | CompanyAddOrderFailure |
  CompanyUpdateOrder | CompanyUpdateOrderSuccess | CompanyUpdateOrderFailure | GetCompanyActivityHistory | GetCompanyActivityHistorySuccess |
  GetCompanyActivityHistoryFailure | GetAllCompanyOrders | GetAllCompanyOrdersSuccess | GetAllCompanyOrdersFailure | GetSearchedCompany |
  GetSearchedCompanySuccess | GetSearchedCompanyFailure | CompanyGetSourceProjects | CompanyGetSourceProjectsSuccess |
  CompanyGetSourceProjectsFailure | GetSingleCompanyOrder | GetSingleCompanyOrderSuccess | GetSingleCompanyOrderFailure |
  GetCompanyOrderLineItem | GetCompanyOrderLineItemSuccess | GetCompanyOrderLineItemFailure | AddCompanyOrderLineItem |
  AddCompanyOrderLineItemSuccess | AddCompanyOrderLineItemFailure | UpdateCompanyOrderLineItem | UpdateCompanyOrderLineItemSuccess |
  UpdateCompanyOrderLineItemFailure | DeleteCompanyOrderLineItem | DeleteCompanyOrderLineItemSuccess | DeleteCompanyOrderLineItemFailure |
  GetCompanyOrderDeliverable | GetCompanyOrderDeliverableSuccess | GetCompanyOrderDeliverableFailure | AddCompanyOrderDeliverable |
  AddCompanyOrderDeliverableSuccess | AddCompanyOrderDeliverableFailure | UpdateCompanyOrderDeliverable |
  UpdateCompanyOrderDeliverableSuccess | UpdateCompanyOrderDeliverableFailure | DeleteCompanyOrderDeliverable |
  DeleteCompanyOrderDeliverableSuccess | DeleteCompanyOrderDeliverableFailure | GetDeliverableNotes | GetDeliverableNotesSuccess |
  GetDeliverableNotesFailure | AddDeliverableNotes | AddDeliverableNotesSuccess | AddDeliverableNotesFailure | UpdateDeliverableNotes |
  UpdateDeliverableNotesSuccess | UpdateDeliverableNotesFailure | DeleteDeliverableNotes | DeleteDeliverableNotesSuccess |
  DeleteDeliverableNotesFailure | GetCompanyOrderHistory | GetCompanyOrderHistorySuccess | GetCompanyOrderHistoryFailure |
  CompanyUpdateSingleOrderSuccess | CompanyOrderFilter | CompanyOrderFilterSuccess | CompanyOrderFilterFailure |
  CompanyOrderDeliverablesFilter | CompanyOrderDeliverablesFilterSuccess | CompanyOrderDeliverablesFilterFailure |
  CompanyOrderLineItemsFilter | CompanyOrderLineItemsFilterSuccess | CompanyOrderLineItemsFilterFailure | CompanyProjectBulkEdit |
  CompanyProjectBulkEditSuccess | CompanyProjectBulkEditFailure | CompanyLoadGeneral | CompanyLoadGeneralSuccess | CompanyLoadGeneralFailure |
  CompanyUpdateGeneral | CompanyUpdateGeneralSuccess | CompanyUpdateGeneralFailure | CompanyLoadLTDSettings | CompanyLoadLTDSettingsSuccess |
  CompanyLoadLTDSettingsFailure | CompanyUpdateLTDSettings | CompanyUpdateLTDSettingsSuccess | CompanyUpdateLTDSettingsFailure |
  CompanyGetContactsWithoutRefresh | CompanyUpdateSyncStatus | UpdateCompanyOrderColumnSelection | UpdateCompanyOrderColumnSelectionSuccess |
  UpdateCompanyOrderColumnSelectionFailure ;

