<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>


  <div class="p-15">
    <h2>Contact Filter</h2>

    <ul class="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x mb-15" role="tablist">

      <li class="nav-item mr-3" [class.menu-item-active]="selectedTab === 'contact'" (click)="setTab('contact')">
        <a class="nav-link" data-toggle="tab"[class.active]="selectedTab === 'contact'">
																<span class="nav-icon mr-2">
																	<span class="svg-icon mr-3">
                                      <span class="svg-icon svg-icon-lg"
                                            [inlineSVG]="'./assets/media/svg/icons/Home/Timer.svg'"></span>
																	</span>
																</span>
          <span class="nav-text">Contact</span>
        </a>
      </li>
      <li class="nav-item mr-3" [class.menu-item-active]="selectedTab === 'article'" (click)="setTab('article')">
        <a class="nav-link" data-toggle="tab" [class.active]="selectedTab === 'article'">
																<span class="nav-icon mr-2">
																	<span class="svg-icon mr-3">
                                      <span class="svg-icon svg-icon-lg"
                                            [inlineSVG]="'./assets/media/svg/icons/Tools/Hummer2.svg'"></span>
																	</span>
																</span>
          <span class="nav-text">Article</span>
        </a>
      </li>
    </ul>




    <form *ngIf="selectedTab === 'contact'" id="formId" [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="contact_name" placeholder="Contact Name" cssClass="e-filled"
                     formControlName="contact_name" floatLabelType="Auto"
                     (blur)="filterForm.controls.contact_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="reference_id" floatLabelType="Auto"
                     (blur)="filterForm.controls.reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="company_name" placeholder="Company Name" cssClass="e-filled"
                     formControlName="company_name" floatLabelType="Auto"
                     (blur)="filterForm.controls.company_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email" placeholder="Email" cssClass="e-filled"
                     formControlName="email" floatLabelType="Auto"
                     (blur)="filterForm.controls.email.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10" [class.hide-label]="filterForm.controls.company_type_code.value == ''">
        <ejs-dropdowntree formControlName="company_type_code" id='company_type_code' allowMultiSelection='true'
                          [fields]='companyTypeField' placeholder='Company Type' cssClass="e-filled" sortOrder="Ascending"
                          (blur)="filterForm.controls.company_type_code.markAsTouched()" floatLabelType="Auto"
                          (change)="setCompanyType($event, filterForm)"
                          [treeSettings]='treeSettings' [showCheckBox]='true'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='multiselect-checkbox' formControlName="country_code"  [dataSource]='countries'
                         placeholder='Country' [fields]='countryField' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='published_by' formControlName="published_by" #checkbox [dataSource]='users'
                         placeholder='Published by' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='contact_tag' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"
                         [fields]="tagFields" formControlName='contact_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event)"
                         (change)="onChange($event)"
                         (blur)="onBlur($event, filterForm)"
                         name="tags"
                         (removed)="selectedTag = []"
                         ([ngModel])="modelData"
                         (select)="select($event)"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="updated_at" name="updated_at" [allowEdit]="allowEdit"
                             placeholder='Select a Updated At Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="created_at" name="created_at" [allowEdit]="allowEdit"
                             placeholder='Select a Created At Date Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>


      <div *ngIf="error$ | async as error">{{error.error}}</div>

<!--      <div class="my-10 pb-20">-->
<!--        <button id="resetbtn"-->
<!--                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"-->
<!--                type="button" (click)="clearForm()"-->
<!--                data-ripple="true">Clear All-->
<!--        </button>-->
<!--        <button id="filter"-->
<!--                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"-->
<!--                type="submit"-->
<!--                (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter-->
<!--        </button>-->
<!--      </div>-->

    </form>

    <form *ngIf="selectedTab === 'article'" id="formdd" [formGroup]="articleFilterForm" #formDir="ngForm" class="form-horizontal" novalidate="">


      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="articleFilterForm.controls.article_category_code.value == ''">
        <ejs-multiselect id='article_category_code' formControlName="article_category_code" #checkbox
                         [dataSource]='articleCategory' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Article Type' [fields]='articleCategoryFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

<!--      <div *ngIf="error$ | async as error">{{error.error}}</div>-->



    </form>

    <div class="my-10 pb-20">
      <button id="resetbtnn"
              class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
              type="button" (click)="clearForm()"
              data-ripple="true">Clear All
      </button>
      <button id="filterr"
              class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
              type="submit"
              (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
      </button>
    </div>

  </div>
</ejs-sidebar>
