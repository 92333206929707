import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { FilteringEventArgs, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Observable } from 'rxjs';
import { Tags } from '../../../../../../core/models/tags.model';
import { debounce, EmitType } from '@syncfusion/ej2-base';
import { ProjectFilter, ProjectFilterReset, ProjectSetStatusNull } from '../../../../../../core/store/actions/project.actions';
import * as moment from 'moment';
import {
  CompanyFilter,
  CompanyGetProjects, CompanyOrder,
  CompanyOrderFilter,
  CompanyProjectFilter, GetAllCompanyOrders
} from '../../../../../../core/store/actions/company.actions';
import { ContactFilter, ContactGetProjects, ContactProjectFilter } from '../../../../../../core/store/actions/contact.actions';
import { Query } from '@syncfusion/ej2-data';
import { ProjectSourceSearchAllSources } from '../../../../../../core/store/actions/project-source.actions';
import * as _ from 'lodash';
import { CompanyService } from 'src/app/core/store/services/company.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss']
})
export class OrderFilterComponent implements OnInit, OnChanges {

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private route: ActivatedRoute, private companyService: CompanyService) {
  }
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @Input() users: object;
  @Input() subscriptionStage: object;
  @Input() companyList: any;
  @Input() selectedCompanyCode: string;
  @Input() orderStatus: object;
  @Input() dealType: any;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  error$: Observable<any>;
  success$: Observable<boolean>;

  formSent: boolean;
  isFilterReset = false;
  public companyFields: object = { value: 'company_code', text: 'company_name' };
  userFields: object = { value: 'user_code', text: 'first_name' };
  orderStatusField = { value: 'order_status_code', text: 'order_status_name' };
  dealTypeFields: object = { text: 'common_name', value: 'common_code' };

  subscriptionStageField: object;
  allowEdit = false;


  filterForm: FormGroup;

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    this.generateFilterForm();

    this.success$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.error$ = this.store.pipe(select(store => store.companies.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        // this.store.dispatch(new ProjectSetStatusNull());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subscriptionStage) {
      this.subscriptionStageField = { dataSource: this.subscriptionStage, value: 'subscription_stage_code', text: 'subscription_stage_name' };
    }
  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      order_name: new FormControl(null),
      created_at: new FormControl(null),
      updated_at: new FormControl(null),
      subscription_stage_code: new FormControl(null),
      company_code: new FormControl(null),
      deal_type: new FormControl(null),
      updated_by: new FormControl(null),
      created_by: new FormControl(null),
      free_period_date: new FormControl(null),
      validity_period: new FormControl(null),
      amount_start: new FormControl(null),
      amount_end: new FormControl(null),
      order_owner_code: new FormControl(null),
      order_status_code: new FormControl(null),

    });

    this.route.queryParams.subscribe(res => {
      // console.log(res);
      // let filterVal = {...this.filterForm.value};
      // filterVal = {...filterVal, ...res};
      // // console.log(filterVal);
      // for (const fKey in filterVal) {
      //   if (this.filterFormArray.includes(fKey)) {
      //     // console.log(filterVal[fKey]);
      //     if (filterVal[fKey]) {
      //       filterVal[fKey] = filterVal[fKey].split(',');
      //     }
      //   }
      // }

      // this.filterForm.setValue({...filterVal});
      // this.filterForm.controls.project_type_code.setValue(res.project_type_code.split(','));
      // console.log(this.filterForm.value);
      // console.log(filterVal);
      // const queryCopy = {...res};
      // delete queryCopy.project_type_code;
      // this.filterForm.patchValue(queryCopy);
      // console.log(this.filterForm.value);
      // console.log(queryCopy);
      // const resCopy = {...res};
      // const types = resCopy.project_type_code.toString().split(',');
      // console.log(types);
      // this.filterForm.setValue(res);
      // this.filterForm.setValue({company_type_code: types});
      // this.filterForm.controls.project_type_code.value = types;
      // this.filterForm.patchValue({project_type_code: types});
      // console.log(this.filterForm.value);
    });
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = { ...this.filterForm.value };
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '')) && delete filterValues[key]);

    if (filterValues.validity_period) {
      filterValues.end_date = moment(filterValues.validity_period[1]).format('YYYY-MM-DD');
      filterValues.start_date = moment(filterValues.validity_period[0]).format('YYYY-MM-DD');
    }
    delete filterValues.validity_period;

    if (filterValues.free_period_date) {
      filterValues.end_free_period_date = moment(filterValues.free_period_date[1]).format('YYYY-MM-DD');
      filterValues.start_free_period_date = moment(filterValues.free_period_date[0]).format('YYYY-MM-DD');
    }
    delete filterValues.free_period_date;

    if (filterValues.created_at) {
      filterValues.created_at_end = moment(filterValues.created_at[1]).format('YYYY-MM-DD');
      filterValues.created_at_start = moment(filterValues.created_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.created_at;

    if (filterValues.updated_at) {
      filterValues.updated_at_end = moment(filterValues.updated_at[1]).format('YYYY-MM-DD');
      filterValues.updated_at_start = moment(filterValues.updated_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }

    this.router.navigate([], { queryParams: filterValues });
    filterValues = { ...filterValues, page_size: 100, page_no: 1 };
    if (this.isCompanyLink()) {
      filterValues = { ...filterValues, page: 'company', company_code: this.selectedCompanyCode };
    }
    else {
      filterValues = { ...filterValues, page: 'order' };
    }
    console.log(filterValues)
    this.store.dispatch(new CompanyOrderFilter(filterValues));
  }

  clearForm() {
    this.isFilterReset = true;
    this.router.navigate([], { queryParams: {} });
    this.filterForm.reset();
    this.filterForm.controls.subscription_stage_code.setValue('');

    if (this.isCompanyLink()) {
      this.store.dispatch(new CompanyOrder({
        company_code: this.selectedCompanyCode,
        page_size: 100,
        page_no: 1
      }));
    } else {
      this.store.dispatch(new GetAllCompanyOrders({ page_size: 100, page_no: 1 }));
    }
  }

  openClick(): void {
    this.sidebarInstance.show();
    this.route.queryParams.subscribe(res => {
      if (Object.keys(res).length === 0) {
        
          this.filterForm.reset()
        }
      if(Object.keys(res).length === 1) {
      if(res.hasOwnProperty('search')){
        this.filterForm.reset() 
      }
      }

      
    })
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  isCompanyLink(): boolean {

    return (this.router.url.indexOf('/company/') > -1);
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      const filterParams = { company_name: e.text, page_size: 100, page_no: 1 };
      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data.data) {
          const companyList = [];
          for (const company of data.data.result) {
            companyList.push({ company_name: company.company_name + ' (' + company.reference_id + ')', company_code: company.company_code })
            e.updateData(companyList, query);
          }
          // this.store.dispatch(new CompanyFilter(filterParams));
          // this.store.select(store => store.companies.companies).subscribe((data) => {
          //   if (data) {
          //     this.companyList = data.result;
          //     const companyList = [];
          //     for (let company of data.result) 
          //     {
          //       const companyName = company.company_name;
          //       company = { ...company, company_name: companyName + ' (' + company.reference_id + ')' };
          //       companyList.push(company);
          //     }
          //     this.companyList = companyList;
          //     e.updateData(this.companyList, query);
        }
      });
    }

  }

  clearAll() {
    this.clearModal.hide()
    this.clearForm()

  }
  hideClearAllModal() {
    this.clearModal.hide()
  }
  clear() {
    this.clearModal.show()
  }
}
