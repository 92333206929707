import {EventsActions, EventsActionTypes} from '../actions/events.actions';
import {Event} from '../../models/event.model';
import * as _ from 'lodash';

export interface EventsState {
  events: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number,
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  is_deleted?: boolean;
  success?: {
    isLoaded?: boolean,
    isForm?: string;
  };
  db?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number,
  };
  details?: Event;
}

export const initialState: EventsState = {
  events: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0,
  },
  loading: false,
  loaded: false,
  success: {
    isLoaded: false,
  },
};

export const ReturnLoading = (State: EventsState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: {
      isLoaded: false,
    },
  };
};
export function eventsReducer(state: EventsState = initialState, action: EventsActions): EventsState {
  switch (action.type) {
    case EventsActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.FILTER_SUCCESS: {
      const result: any = { ...action.payload };
      state = {
        ...state,
        events: {
          result: result.data.result,
          page_no: result.data.page_no,
          page_size: result.data.page_size,
          total_count: result.data.total_count,
        },
        loading: false,
        error: undefined,
        success: { isLoaded: true },
        loaded: true,
        db: {
          result: result.data.result,
          total_count: result.data.total_count,
          page_no: result.data.page_no,
          page_size: result.data.page_size,
        }
      };
      return state;
    }

    case EventsActionTypes.FILTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case EventsActionTypes.ADD: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.ADD_SUCCESS: {
      let events = {...action.payload.event};
      Object.keys(events).some((key) => {
        if (action.payload.event[key] && typeof events[key] === 'object') {
          events = {...events, ...action.payload.event[key]};
        }
      });
      console.log('set', state, events);
      return {
        ...state, loading: false, events: {result: [events, ...state.events.result]}, error: undefined, loaded: true,
        success: { isForm: 'ADD_EVENT', isLoaded: true }
      };
    }

    case EventsActionTypes.ADD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case EventsActionTypes.SET_SUCCESS_NULL: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.SET_ERROR_NULL: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.GET_DETAILS: {
      return {
        ...state,
        details: null,
        loading: true,
        loaded: false,
        error: undefined,
        success: {
          isLoaded: false,
          isForm: ''
        },
      };
    }

    case EventsActionTypes.GET_DETAILS_SUCCESS: {
      return {...state, details: action.payload.event, error: undefined, loading: false, loaded: true};
    }

    case EventsActionTypes.GET_DETAILS_FAILURE: {
      return {...state, error: action.payload, loading: false, loaded: false};
    }

    case EventsActionTypes.EVENT_UPDATE: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.EVENT_UPDATE_SUCCESS: {

      const eventSourceIndex = state.events.result.findIndex(x => x.event_code === action.payload.event.event_code);
      const eventSource = state.events[eventSourceIndex];
      const updatedEventSource = {...eventSource, ...action.payload.event};
      const updatedEventSources = [...state.events.result];

      const index = updatedEventSources.findIndex(x => x.event_code === action.payload.event.event_code);
      updatedEventSources[index] = updatedEventSource;

      return {
        ...state,
        events: {...state.events, result: updatedEventSources},
        details: Object.assign({}, {...state.details}, action.payload.event),
        error: undefined,
        loading: false,
        loaded: true,
        success: { isForm: 'UPDATE_EVENT', isLoaded: true },
      };
    }

    case EventsActionTypes.EVENT_UPDATE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case EventsActionTypes.UPDATE_ATTENDEE: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.UPDATE_ATTENDEE_SUCCESS: {
      return {
        ...state, details: action.payload.event,
        error: undefined,
        loading: false,
        loaded: true,
        success: { isForm: 'UPDATE_EVENT_ATTENDEE', isLoaded: true }
      };
    }

    case EventsActionTypes.UPDATE_ATTENDEE_FAILURE: {
      return {...state, error: action.payload, loading: false, loaded: false};
    }

    case EventsActionTypes.DELETE: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.DELETE_SUCCESS: {
      const events = state.events.result.filter(x => x.event_code !== action.payload.event_code);
      console.log('events', events, state.events.result, action.payload);
      return {
        ...state, events: {result: events},
        details: null,
        error: undefined,
        loading: false,
        loaded: true,
        is_deleted: true,
        success: { isForm: 'Delete Success', isLoaded: true }
      };
    }

    case EventsActionTypes.DELETE_FAILURE: {
      return {...state, error: action.payload, loading: false, loaded: false};
    }

    case EventsActionTypes.DELETE_ATTENDEE: {
      return ReturnLoading(state);
    }

    case EventsActionTypes.DELETE_ATTENDEE_SUCCESS: {
      const eventAttendees = _.cloneDeep(state.details.contact);
      const deletedContact = action.payload.payload.company_contact_code;
      console.log(deletedContact, eventAttendees);
      if (deletedContact && deletedContact.length > 0) {
        deletedContact.forEach((contact) => {
          const deleteIndex = eventAttendees.findIndex(x => x.company_contact_code === contact);
          if (deleteIndex > -1) {
            eventAttendees.splice(deleteIndex, 1);
          }
        });
      }
      return {
        ...state, details: {...state.details, contact: eventAttendees},
        error: undefined,
        loading: false,
        loaded: true,
        success: { isForm: 'DELETE_ATTENDEE', isLoaded: true }
      };
    }

    case EventsActionTypes.DELETE_ATTENDEE_FAILURE: {
      return {...state, error: action.payload, loading: false, loaded: false};
    }

  }
}
