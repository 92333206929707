import { environment } from "src/environments/environment";
export const DynamicHeaderMenuConfig = {
  items: [
    {
      title: "Research",
      bullet: "dot",
      page: "/project",
      alignment: "left",
      permission: ["project-list"],
      submenu: [
        {
          title: "Projects",
          bullet: "dot",
          page: "/project",
          alignment: "left",
          toggle: "click",
          permission: "project-list",
        },
        {
          title: "Sources",
          bullet: "dot",
          page: "/sources",
          alignment: "left",
          toggle: "click",
          permission: "project-source-list",
        },
        {
          title: "Brands",
          bullet: "dot",
          page: "/brand",
          alignment: "left",
          toggle: "click",
          permission: "brand-list",
        },
        {
          title: "Collections",
          bullet: "dot",
          page: "/collection",
          alignment: "left",
          toggle: "click",
          permission: "collection-list",
        },
        {
          title: "Products",
          bullet: "dot",
          page: "/product",
          alignment: "left",
          toggle: "click",
          permission: "product-list",
        },
      ],
    },
    {
      title: "Address Book",
      page: "/company",
      alignment: "left",
      permission: ["company-list"],
      bullet: "dot",
      submenu: [
        {
          title: "Companies",
          bullet: "dot",
          page: "/company",
          icon: "flaticon-user",
          permission: "company-list",
        },
        {
          title: "Contacts",
          bullet: "dot",
          page: "/contact",
          icon: "flaticon-user",
          permission: "contact-list",
        },
      ],
    },
    {
      title: "Admin",
      bullet: "dot",
      alignment: "left",
      page: "/admin",
      permission: ["admin-list"],
      submenu: [
        {
          title: "LTD Emails",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "deliverable-items-master",
          submenu: [
            {
              title: "Email Templates",
              bullet: "dot",
              page: "/email-templates",
              icon: "flaticon-user",
              permission: "deliverable-items-master",
            },
            {
              title: "Email History",
              bullet: "dot",
              page: "/email-history",
              icon: "flaticon-user",
              permission: "deliverable-items-master",
            },
            {
              title: "Email Run",
              bullet: "dot",
              page: "/email-run",
              icon: "flaticon-user",
              permission: "deliverable-items-master",
            },
          ],
        },
        {
          title: "Community Service",
          page: "/admin/community-service",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "cost-center-master",
        },
        {
          title: "Product Type",
          page: "/admin/product-type",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "cost-center-master",
        },
        {
          title: "Community Type",
          page: "/admin/community-type",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "cost-center-master",
        },
        {
          title: "Article",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "cost-center-master",
          submenu: [
            {
              title: "Article Category",
              page: "/admin/article-category",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "cost-center-master",
            },
            {
              title: "Article Stage",
              page: "/admin/article-stage",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "cost-center-master",
            },
            {
              title: "Article Type",
              page: "/admin/article-type",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "article-type-master",
            },
            {
              title: "Article Dates",
              page: "/admin/article-date",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "article-type-master",
            },
          ],
        },
        {
          title: "Deliverables",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "deliverable-items-master",
          submenu: [
            {
              title: "Deliverable Items",
              page: "/admin/deliverable-items",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "deliverable-items-master",
            },
            {
              title: "Deliverable Item Dates",
              page: "/admin/deliverable-item-date",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "deliverable-items-master",
            },
            {
              title: "Deliverable Status",
              page: "/admin/deliverable-status",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "deliverable-status-master",
            },
          ],
        },
        {
          title: "General",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "edition-master",
          submenu: [
            {
              title: "Email Template",
              page: "/admin/email-template",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "email-template-master",
            },
            {
              title: "Edition",
              page: "/admin/edition",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "edition-master",
            },
          ],
        },
        {
          title: "Order",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "order-line-items-master",
          submenu: [
            {
              title: "Order Items",
              page: "/admin/order-items",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "order-line-items-master",
            },
            {
              title: "Cost Center",
              page: "/admin/cost-center",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "cost-center-master",
            },
          ],
        },
        {
          title: "Project",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "cost-center-master",
          submenu: [
            {
              title: "Company Types",
              page: "/admin/company-types",
              bullet: "dot",
              icon: "flaticon-business",
              permission: "company-types-master",
            },
            {
              title: "Company Role",
              page: "/admin/company-roles",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "company-roles-master",
            },
            {
              title: "Project Types",
              page: "/admin/project-types",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "project-types-master",
            },
            {
              title: "Project Status",
              page: "/admin/project-status",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "project-status-master",
            },
            {
              title: "Source Types",
              page: "/admin/source-types",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "source-type-master",
            },
            {
              title: "Social Channels",
              page: "/admin/social-types",
              bullet: "dot",
              icon: "flaticon-user",
              permission: "cost-center-master",
            },
          ],
        },
        {
          title: "Users",
          page: "/admin/users",
          bullet: "dot",
          icon: "flaticon-user",
          permission: "user-master",
        },
      ],
    },
    {
      title: "Customer Success",
      root: true,
      bullet: "dot",
      page: "/orders",
      alignment: "left",
      toggle: "click",
      permission: ["order-list"],
      submenu: [
        {
          title: "Orders",
          root: true,
          bullet: "dot",
          page: "/orders",
          alignment: "left",
          toggle: "click",
          permission: "order-list",
        },
        {
          title: "Deliverables",
          root: true,
          bullet: "dot",
          page: "/deliverable",
          alignment: "left",
          toggle: "click",
          permission: "deliverable-list",
        },
      ],
    },
    {
      title: "Emails",
      root: true,
      bullet: "dot",
      page: "/email-log",
      alignment: "left",
      toggle: "click",
      permission: ["email-list", "email-view"],
    },
    {
      title: "Community",
      root: true,
      bullet: "dot",
      page: environment.newDomainUrl + "/community",
      alignment: "left",
      toggle: "click",
      permission: ["community-registration", "community-registration"],
    },
    {
      title: "Content",
      bullet: "dot",
      page: "/article_news",
      alignment: "left",
      permission: ["article-list"],
      submenu: [
        {
          title: "Articles & News",
          bullet: "dot",
          page: "/article_news",
          alignment: "left",
          toggle: "click",
          permission: "article",
        },
        {
          title: "Events",
          bullet: "dot",
          page: "/events",
          alignment: "left",
          toggle: "click",
          permission: "event-list",
        },
      ],
    },
    // {
    //   title: 'LTD Emails',
    //   page: '/email-templates',
    //   alignment: 'left',
    //   permission: ['company-list'],
    //   bullet: 'dot',
    //   submenu: [
    //     {
    //       title: 'Email Templates',
    //       bullet: 'dot',
    //       page: '/email-templates',
    //       icon: 'flaticon-user',
    //       permission: 'company-list'
    //     },
    //     {
    //       title: 'Email History',
    //       bullet: 'dot',
    //       page: '/email-history',
    //       icon: 'flaticon-user',
    //       permission: 'contact-list'
    //     },
    //   ]
    // },
  ],
};
