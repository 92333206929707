import {Injectable} from '@angular/core';
import {ResponseData} from '../../ResponseData.interface';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {v4 as uuid} from 'uuid';
import {environment} from '../../../../environments/environment.prod';

const ARTICLE_URL = environment.API_URL + 'article';
const ARTICLE_CONTACT_URL = environment.API_URL + 'article-contact';
const ARTICLE_COMPANY_URL = environment.API_URL + 'article-company';
const ARTICLE_PROJECT_URL = environment.API_URL + 'article-project';
const ARTICLE_BRAND_URL = environment.API_URL + 'article-brand';
const ARTICLE_PRODUCT_URL = environment.API_URL + 'article-product';
const ARTICLE_COLLECTION_URL = environment.API_URL + 'article-collection';
const ARTICLE_EDITION_URL = environment.API_URL + 'article-edition';
const ARTICLE_GENERAL_URL = environment.API_URL + 'article-general';
const ARTICLE_LTD_SETTINGS_URL = environment.API_URL + 'article-ltd-settings';
const ARTICLE_LINKS_URL = environment.API_URL + 'article-links';




@Injectable()
export class ArticleService {
  id: string;

  constructor(private http: HttpClient) {
  }

  getArticles(page_size?: number, page_no?: number) {
    const request: any = {page_size, page_no};
    return this.http.get<ResponseData>(ARTICLE_URL, {params: request});
  }

  getArticle(articleCode: string) {
    return this.http.get<ResponseData>(ARTICLE_URL + '/' + articleCode);
  }

  getFilteredArticles(parameters) {
    return this.http.get<ResponseData>(ARTICLE_URL + '/filter', {params: parameters});
  }

  addArticle(article: {}) {
    this.id = uuid();
    const request = {...article, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_URL, request);
  }

  updateArticle(code: string, article: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...article, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_URL + '/' + code, request);
  }

  deleteArticle(articleCode: string) {
    return this.http.delete<ResponseData>(ARTICLE_URL + '/' + articleCode);
  }

  getArticleContact(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_CONTACT_URL, {params: request});
  }

  addArticleContact(article_code: string, company_contact_code: string) {
    this.id = uuid();
    const request = {company_contact_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_CONTACT_URL, request);
  }

  updateArticleContact(code: string, company_contact_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {company_contact_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_CONTACT_URL + '/' + code, request);
  }

  deleteArticleContact(article_contact_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_CONTACT_URL + '/' + article_contact_link_code);
  }

  getArticleCompany(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_COMPANY_URL, {params: request});
  }

  addArticleCompany(article_code: string, company_code: string) {
    this.id = uuid();
    const request = {company_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_COMPANY_URL, request);
  }

  updateArticleCompany(code: string, company_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {company_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_COMPANY_URL + '/' + code, request);
  }

  deleteArticleCompany(article_company_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_COMPANY_URL + '/' + article_company_link_code);
  }

  // Article Projects 
  getArticleProject(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_PROJECT_URL, {params: request});
  }

  addArticleProject(article_code: string, project_code: string) {
    this.id = uuid();
    const request = {project_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_PROJECT_URL, request);
  }

  updateArticleProject(code: string, project_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {project_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_PROJECT_URL + '/' + code, request);
  }

  deleteArticleProject(article_project_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_PROJECT_URL + '/' + article_project_link_code);
  }

  // Article Brands 
  getArticleBrand(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_BRAND_URL, {params: request});
  }

  addArticleBrand(article_code: string, brand_code: string) {
    this.id = uuid();
    const request = {brand_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_BRAND_URL, request);
  }

  updateArticleBrand(code: string, brand_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {brand_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_BRAND_URL + '/' + code, request);
  }

  deleteArticleBrand(article_brand_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_BRAND_URL + '/' + article_brand_link_code);
  }

  // Article Products 
  getArticleProduct(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_PRODUCT_URL, {params: request});
  }

  addArticleProduct(article_code: string, product_code: string) {
    this.id = uuid();
    const request = {product_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_PRODUCT_URL, request);
  }

  updateArticleProduct(code: string, product_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {product_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_PRODUCT_URL + '/' + code, request);
  }

  deleteArticleProduct(article_product_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_PRODUCT_URL + '/' + article_product_link_code);
  }

  // Article Collections 
  getArticleCollection(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_COLLECTION_URL, {params: request});
  }

  addArticleCollection(article_code: string, collection_code: string) {
    this.id = uuid();
    const request = {collection_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_COLLECTION_URL, request);
  }

  updateArticleCollection(code: string, collection_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {collection_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_COLLECTION_URL + '/' + code, request);
  }

  deleteArticleCollection(article_collection_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_COLLECTION_URL + '/' + article_collection_link_code);
  }

  // Article Edition 
  getArticleEdition(articleCode: string) {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_EDITION_URL, {params: request});
  }

  addArticleEdition(article_code: string, edition_code: string) {
    this.id = uuid();
    const request = {edition_code, article_code, batch_id: this.id};

    return this.http.post<ResponseData>(ARTICLE_EDITION_URL, request);
  }

  updateArticleEdition(code: string, edition_code: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {edition_code, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_EDITION_URL + '/' + code, request);
  }

  deleteArticleEdition(article_edition_link_code: string) {
    return this.http.delete<ResponseData>(ARTICLE_EDITION_URL + '/' + article_edition_link_code);
  }

  getArticleHistory(articleCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(ARTICLE_URL + '/' + articleCode + '/transaction-history');
  }

  getArticleGeneral(articleCode: string): Observable<ResponseData> {
    const request: any = {article_code: articleCode};

    return this.http.get<ResponseData>(ARTICLE_GENERAL_URL, {params: request});
  }

  updateArticleGeneral(code: string, article_general: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...article_general, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_GENERAL_URL + '/' + code, request);
  }

  getArticleLTDSettings(articleCode: string): Observable<ResponseData> {
    const request: any = {article_code: articleCode};
    return this.http.get<ResponseData>(ARTICLE_LTD_SETTINGS_URL , {params: request});
  }

  updateArticleLTDSettings(code: string, article_ltd_settings: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...article_ltd_settings, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_LTD_SETTINGS_URL + '/' + code, request);
  }

  getArticleLinks(articleCode: string): Observable<ResponseData> {
    const request: any = {article_code: articleCode};
    return this.http.get<ResponseData>(ARTICLE_LINKS_URL , {params: request});
  }

  updateArticleLink(code: string, article_link: {}): Observable<ResponseData> {
    this.id = uuid();
    const request = {...article_link, batch_id: this.id};
    return this.http.put<ResponseData>(ARTICLE_LINKS_URL + '/' + code, request);
  }

  getSearchResults(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(ARTICLE_URL + '/search', {params: parameters});
  }

  getSlug(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(ARTICLE_URL + '/generate-slug', {params: parameters});
  }
}
