/* tslint:disable */
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import {
  ProjectActivityUpdate,
  ProjectAddActivity,
  ProjectGetActivity,
  ProjectSetStatusNull,
  ProjectStatusChangedCleared,
  ProjectUpdate,
} from "../../../../core/store/actions/project.actions";
import {
  ChangeEventArgs,
  TimePickerComponent,
} from "@syncfusion/ej2-angular-calendars";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../core";
import { Activity } from "../../../../core/models/activity.model";
import { BehaviorSubject, Observable } from "rxjs";
import { LoadTags } from "../../../../core/store/actions/common.actions";
import { Tags } from "../../../../core/models/tags.model";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import {
  AddContactActivity,
  ContactFilter,
  ContactSetStatusNull,
  ContactUpdate,
  GetContactActivity,
  UpdateContactActivity,
} from "../../../../core/store/actions/contact.actions";
import {
  AddCompanyActivity,
  CompanyFilter,
  CompanySetStatusNull,
  UpdateCompanyActivity,
} from "../../../../core/store/actions/company.actions";
import { trimValidator } from "../../../../app-validators";
import { EmitType } from "@syncfusion/ej2-base";
import {
  FilteringEventArgs,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import * as _ from "lodash";
import { v4 as uuid } from "uuid";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";

@Component({
  selector: "app-activity-form",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
})
export class ActivityComponent implements OnInit, OnChanges {
  @ViewChild("sidebarInstance")
  public sidebarInstance: SidebarComponent;
  @ViewChild("tags") activityTag: MultiSelectComponent;

  @Input() formType: string;
  @Input() activityEntity: string;
  @Input() users: any;
  @Input() participantsList: any;
  @Input() selectedCode: string;
  @Input() projectStatus: any;
  @Input() allTags: Observable<Array<Tags>>;
  @Output() valueChange = new EventEmitter();
  @ViewChild("clearModal") public clearModal: DialogComponent;
  @ViewChild("startTime")
  public startObject: TimePickerComponent;
  @ViewChild("endTime")
  public endObject: TimePickerComponent;
  public isStartTimeChange = true;

  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];
  public tagModelData = [];
  modelData = [];
  selectedTag = [];
  tagSelected = "";

  public width = "auto";
  public position = "Right";

  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  public formSent: boolean;
  isStatusChanged = false;
  nullAction: any;

  companyContactStateData: any = [
    { name: "Published", value: "1" },
    { name: "Not Published", value: "0" },
  ];

  statusChangedForm: FormGroup;
  activityForm: FormGroup;
  allowEdit = false;
  setEnabled = true;
  selectedStatus: any;
  public projectStatusFields: object;
  public tagFields: object = { value: "tag", text: "tag" };
  public userFields = { value: "user_code", text: "name" };
  selectedProject: any;
  public participantsFields = { value: "contact_code", text: "name" };
  public companyContactStateDataField = {
    dataSource: this.companyContactStateData,
    text: "name",
    value: "value",
  };

  public _activityInput$: BehaviorSubject<Activity | null> =
    new BehaviorSubject<Activity | null>(null);

  @Input() set activityInput(mode: Activity | null) {
    this._activityInput$.next(mode);
  }

  get activityInput(): Activity | null {
    return this._activityInput$.getValue();
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    if (!this.activityForm) {
      this.generateActivityForm();
    }

    // this.allTags.subscribe(data => {
    //   this.tagModelData = data;
    // });

    switch (this.activityEntity) {
      // CHECK FOR TYPE OF ACTIVITY
      case "project":
        this.success$ = this.store.pipe(
          select((store) => store.projects.success),
        );
        this.error$ = this.store.pipe(select((store) => store.projects.error));
        this.nullAction = new ProjectSetStatusNull();
        break;

      case "contact":
        this.success$ = this.store.pipe(
          select((store) => store.contacts.success.isLoaded),
        );
        this.error$ = this.store.pipe(select((store) => store.contacts.error));
        this.nullAction = new ContactSetStatusNull();
        break;

      case "company":
        this.success$ = this.store.pipe(
          select((store) => store.companies.success.isLoaded),
        );
        this.error$ = this.store.pipe(select((store) => store.companies.error));
        this.nullAction = new CompanySetStatusNull();
        break;
    }

    this.success$.subscribe((data: any) => {
      if (data && this.formSent) {
        if (this.isStatusChanged) {
          this.isStatusChanged = false;
          this.store.dispatch(
            new ProjectGetActivity({
              entity: "project",
              project_code: this.selectedCode,
            }),
          );
          this.onAddStatusChangedActivity();
        } else {
          this.formSent = false;
          this.closeSidebar();
          this.store.dispatch(this.nullAction);
        }
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.errorMsg = data.error;
        this.formSent = false;
      }
    });

    if (history.state.data === "status_changed") {
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allTags) {
      this.allTags.subscribe((data) => {
        this.tagModelData = data;
      });
    }

    if (
      (changes.formType && this.formType === "status-changed") ||
      this.formType === "assigned-to-changed" ||
      this.formType === "post-date-changed" ||
      this.formType === "featured-date-changed"
    ) {
      if (this.activityEntity === "project") {
        this.store
          .select((store) => store.projects.statusChanged)
          .subscribe((data) => {
            if (data) {
              this.selectedProject = data;
              this.generateStatusChangedForm();
              this.statusChangedForm.controls.assigned_to.setValue(
                data.assigned_to,
              );
              this.statusChangedForm.controls.status_code.setValue(data.status);
              data.proposed_post_date
                ? this.statusChangedForm.controls.proposed_post_date.setValue(
                    data.proposed_post_date,
                  )
                : null;
              data.featured_date
                ? this.statusChangedForm.controls.featured_date.setValue(
                    data.featured_date,
                  )
                : null;

              if (data.status_name === data.previous_status_name) {
                this.statusChangedForm.controls.note.clearValidators();
                this.statusChangedForm.controls.note.updateValueAndValidity();
              }
            }
          });
      } else if (this.activityEntity === "contact") {
        this.store
          .select((store) => store.contacts.statusChanged)
          .subscribe((data) => {
            if (data) {
              this.selectedProject = data;
              this.generateStatusChangedForm();
              this.statusChangedForm.controls.status_code.setValue(
                data.is_published_yn.toString(),
              );
            }
          });
      }
    }

    if (!this.activityForm) {
      this.generateActivityForm();
    }

    if (changes.projectStatus) {
      this.projectStatusFields = {
        dataSource: this.projectStatus,
        value: "status_code",
        text: "status_name",
        groupBy: "parent",
        child: "children",
      };
    }

    if (
      this._activityInput$.getValue() &&
      this._activityInput$.getValue().activity[0].activity_type
        .activity_type_code !== "status-changed"
    ) {
      const tagsArray = [];
      if (this._activityInput$.getValue().activity_tag) {
        for (const index of this._activityInput$.getValue().activity_tag) {
          tagsArray.push(index.tag);
        }
      }

      let participantsArray = [];
      let userParticipantsArray = [];

      if (this._activityInput$.getValue().activity_participant) {
        for (const participant of this._activityInput$.getValue()
          .activity_participant) {
          if (
            !this.participantsList.find(
              (item) => item.contact_code === participant[0].contact_code,
            )
          ) {
            this.participantsList.push({
              contact_code: participant[0].contact_code,
              name: participant[0].first_name + " " + participant[0].last_name,
            });
          }
          participantsArray.push(participant[0].contact_code);
        }
      }

      if (this._activityInput$.getValue().user) {
        for (const participant of this._activityInput$.getValue().user) {
          userParticipantsArray.push(participant[0].user_code);
        }
      }

      this.modelData = _.cloneDeep(tagsArray);

      this.activityForm.setValue({
        activity_title:
          this._activityInput$.getValue().activity[0].activity_title,
        note: this._activityInput$.getValue().activity[0].activity_note,
        attachments: null,
        meeting_date: this._activityInput$.getValue().activity[0].meeting_date
          ? moment(
              this._activityInput$.getValue().activity[0].meeting_date,
              "YYYY-MM-DD",
            ).toDate()
          : null,
        meeting_start_time: this._activityInput$.getValue().activity[0]
          .meeting_start_time
          ? moment(
              this._activityInput$.getValue().activity[0].meeting_start_time,
              "HH:mm:ss",
            ).toDate()
          : null,
        meeting_end_time: this._activityInput$.getValue().activity[0]
          .meeting_end_time
          ? moment(
              this._activityInput$.getValue().activity[0].meeting_end_time,
              "HH:mm:ss",
            ).toDate()
          : null,
        participants: participantsArray.length > 0 ? participantsArray : null,
        userParticipants:
          userParticipantsArray.length > 0 ? userParticipantsArray : null,
        tags: tagsArray ? tagsArray : null,
      });
    }

    if (
      this._activityInput$.getValue() &&
      this._activityInput$.getValue().activity[0].activity_type
        .activity_type_code === "status-changed"
    ) {
      this.setEnabled = false;
      this.store
        .select((store) => store.projects.details)
        .subscribe((data) => {
          if (data) {
            this.statusChangedForm.setValue({
              activity_title:
                this._activityInput$.getValue().activity[0].activity_title,
              note: this._activityInput$.getValue().activity[0].activity_note,
              status_code: data.status.status_code,
              assigned_to: data.assigned_to ? data.assigned_to.user_code : null,
            });
          }
        });
    }
  }

  generateActivityForm(): void {
    this.activityForm = this.formBuilder.group({
      activity_title: new FormControl("", [
        Validators.required,
        Validators.compose([trimValidator]),
      ]),
      note: new FormControl("", [
        Validators.required,
        Validators.compose([trimValidator]),
      ]),
      attachments: new FormControl(""),
      meeting_date: new FormControl(""),
      meeting_start_time: new FormControl(""),
      meeting_end_time: new FormControl(""),
      participants: new FormControl(""),
      userParticipants: new FormControl(""),
      tags: new FormControl(""),
    });
  }

  generateStatusChangedForm(): void {
    this.statusChangedForm = this.formBuilder.group({
      note: new FormControl("", [
        Validators.required,
        Validators.compose([trimValidator]),
      ]),
      assigned_to: new FormControl(""),
      status_code: new FormControl(""),
      assigned_to_note: new FormControl(""),
      featured_date: new FormControl(""),
      proposed_post_date: new FormControl(""),
    });
  }

  onAddUpdateActivity(form: FormGroup, type: string) {
    if (!form.invalid) {
      let meeting_date = null;
      let meeting_start_time = null;
      let meeting_end_time = null;

      if (this.formType === "meeting") {
        meeting_date = form.value.meeting_date
          ? moment(form.value.meeting_date).format("YYYY-MM-DD")
          : null;
        meeting_start_time = form.value.meeting_start_time
          ? moment(form.value.meeting_start_time).format("HH:mm:ss")
          : null;
        meeting_end_time = form.value.meeting_end_time
          ? moment(form.value.meeting_end_time).format("HH:mm:ss")
          : null;
      }

      this.formSent = true;
      if (type === "add") {
        switch (this.activityEntity) {
          case "project":
            this.store.dispatch(
              new ProjectAddActivity({
                project_code: this.selectedCode,
                activity_type: this.formType,
                activityTitle: form.value.activity_title,
                activityNote: form.value.note,
                userParticipants: form.value.userParticipants,
                contactParticipants: form.value.participants,
                meeting_start_time,
                meeting_end_time,
                meeting_date,
                activityTags: _.cloneDeep(form.value.tags),
                entity: "project",
              }),
            );
            break;
          case "contact":
            this.store.dispatch(
              new AddContactActivity({
                contact_code: this.selectedCode,
                activity_type: this.formType,
                activityTitle: form.value.activity_title,
                activityNote: form.value.note,
                userParticipants: form.value.userParticipants,
                contactParticipants: form.value.participants,
                meeting_start_time,
                meeting_end_time,
                meeting_date,
                activityTags: _.cloneDeep(form.value.tags),
                entity: "contact",
              }),
            );
            break;
          case "company":
            this.store.dispatch(
              new AddCompanyActivity({
                company_code: this.selectedCode,
                activity_type: this.formType,
                activityTitle: form.value.activity_title,
                activityNote: form.value.note,
                userParticipants: form.value.userParticipants,
                contactParticipants: form.value.participants,
                meeting_start_time,
                meeting_end_time,
                meeting_date,
                activityTags: _.cloneDeep(form.value.tags),
                entity: "company",
              }),
            );
            break;
        }
      } else {
        const activity = {
          activity_type: this.formType,
          activityTitle: form.value.activity_title,
          activityNote: form.value.note,
          userParticipants: form.value.userParticipants,
          contactParticipants: form.value.participants,
          meeting_start_time,
          meeting_end_time,
          meeting_date,
          activityTags: _.cloneDeep(form.value.tags),
        };

        switch (this.activityEntity) {
          case "project":
            this.store.dispatch(
              new ProjectActivityUpdate({
                activity_code:
                  this._activityInput$.getValue().activity[0].activity_code,
                projectActivity: activity,
                entity: "project",
              }),
            );
            break;

          case "contact":
            this.store.dispatch(
              new UpdateContactActivity({
                activity_code:
                  this._activityInput$.getValue().activity[0].activity_code,
                contactActivity: activity,
                entity: "contact",
              }),
            );
            break;

          case "company":
            this.store.dispatch(
              new UpdateCompanyActivity({
                activity_code:
                  this._activityInput$.getValue().activity[0].activity_code,
                companyActivity: activity,
                entity: "company",
              }),
            );
            break;
        }
      }
    }
  }

  public onEnableEndTime(args: ChangeEventArgs): void {
    /*Enables end time if start time is selected*/
    let value: Date;
    if (this.isStartTimeChange) {
      this.endObject.enabled = true;
      this.endObject.value = null;
      value = new Date(args.value);
      value.setMinutes(value.getMinutes() + this.endObject.step);
      this.endObject.min = value;
    } else {
      this.isStartTimeChange = true;
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
    if (this.activityForm) {
      this.activityForm.reset();
    }
    if (this.statusChangedForm) {
      this.statusChangedForm.reset();
    }
    this.setEnabled = true;
    this.valueChange.emit("success");
  }

  public onFiltering: EmitType<any> = (
    e: FilteringEventArgs,
    entity: string,
  ) => {
    let query = new Query();
    if (entity === "contact") {
      query =
        e.text !== "" ? query.where("name", "startswith", e.text, true) : query;
      const filterParams = { contact_name: e.text, page_size: 40, page_no: 1 };
      this.store.dispatch(new ContactFilter(filterParams));
      this.store
        .select((store) => store.contacts.contacts)
        .subscribe((data) => {
          if (data) {
            const companyContactList = [];
            for (const contact of data.result) {
              let company = "";
              if (contact.company) {
                company = " - " + contact.company.company_name;
              } else {
                company = "";
              }
              let result = {
                contact_code: contact.contact_code,
                name: contact.first_name + " " + contact.last_name + company,
              };

              if (contact.company) {
                result = {
                  ...result,
                  ...{
                    company_code: contact.company.company_code,
                    company_name: contact.company.company_name,
                  },
                };
              }
              companyContactList.push(result);
            }
            e.updateData(companyContactList, query);
          }
        });
    }
  };

  onBlur(args, form) {
    if (this.selectedTag.length != 0) {
      this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

      if (this.modelData && !this.modelData.includes(this.tagSelected)) {
        this.activityTag.addItem({
          tag: this.tagSelected,
          tag_code: this.tagSelected,
        });
        form.controls.tags.setValue(
          _.uniq(_.compact([...this.modelData, this.tagSelected])),
        );
      }

      this.modelData = _.compact([...this.modelData, this.tagSelected]);
      // this.modelData.push(this.tagSelected);
      this.selectedTag = [];
    }
  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (
      _.get(args, "result[0]") &&
      this.tagModelData.length != _.get(args, "result", []).length
    ) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if (tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  onUpdateStatus(form) {
    if (!form.invalid) {
      this.isStatusChanged = true;
      let title = "";
      let note = "";

      // const projectTags = this.selectedProject.project_tag ? this.selectedProject.project_tag.map(item => item.tag) : null;

      if (this.activityEntity === "project") {
        const projectActivities = [];
        const activity = {
          project_code: this.selectedCode,
          activity_note: note,
          user_code: null,
          activity_participant_code: null,
          meeting_start_time: null,
          meeting_end_time: null,
          meeting_date: null,
          activity_tag: null,
          entity: "project",
          batch_id: uuid(),
        };

        //Check if status changed or assigned to
        if (
          this.formType !== "post-date-changed" &&
          this.formType !== "featured-date-changed"
        ) {
          this.selectedStatus = this.projectStatus.find(
            (item) => item.status_code === form.value.status_code,
          );

          if (
            this.selectedProject.previous_status_name &&
            this.selectedProject.previous_status_name !==
              this.selectedStatus.status_name
          ) {
            title =
              "Status Changed from " +
              this.selectedProject.previous_status_name +
              " to " +
              this.selectedStatus.status_name;
            note = this.statusChangedForm.value.note;
            this.formType = "status-changed";

            projectActivities.push({
              ...activity,
              activity_title: title,
              activity_note: note,
              activity_type_code: this.formType,
            });
          }
        }

        if (this.selectedProject.assigned_to !== form.value.assigned_to) {
          this.formType = "assigned-to-changed";

          let previousUser: any;
          if (this.selectedProject.assigned_to === "system") {
            previousUser = "System";
          } else {
            try {
              previousUser = this.users.find(
                (item) => item.user_code === this.selectedProject.assigned_to,
              ).name;
            } catch (e) {}
          }
          const newUser = this.users.find(
            (item) => item.user_code === form.value.assigned_to,
          );

          title =
            "Assigned User Changed from " +
            previousUser +
            " to " +
            newUser.name;
          note = form.value.assigned_to_note;

          projectActivities.push({
            ...activity,
            activity_title: title,
            activity_note: note,
            activity_type_code: this.formType,
          });
        }

        if (
          this.formType === "post-date-changed" ||
          this.formType === "featured-date-changed"
        ) {
          title =
            this.formType === "post-date-changed"
              ? "Post Date Changed from " +
                this.selectedProject.previous_post_date +
                " to " +
                moment(
                  this.statusChangedForm.controls.proposed_post_date.value,
                ).format("YYYY-MM-DD HH:mm:ss")
              : "Featured Date Changed from " +
                this.selectedProject.previous_featured_date +
                " to " +
                moment(
                  this.statusChangedForm.controls.featured_date.value,
                ).format("YYYY-MM-DD");
          note = this.statusChangedForm.value.note;
          // this.formType = 'post-date-changed';

          projectActivities.push({
            ...activity,
            activity_title: title,
            activity_note: note,
            activity_type_code: this.formType,
          });
        }

        // const id = uuid();
        if (projectActivities.length > 0) {
          this.store.dispatch(
            new ProjectUpdate({
              project_code: this.selectedCode,
              project: {
                status_code: form.value.status_code,
                featured_date: form.value.featured_date
                  ? moment(form.value.featured_date).format("YYYY-MM-DD")
                  : this.selectedProject.featured_date,
                proposed_post_date: form.value.proposed_post_date
                  ? moment(form.value.proposed_post_date).format(
                      "YYYY-MM-DD HH:mm:ss",
                    )
                  : null,
                project_tag: this.selectedProject.project_tag,
                display_name: this.selectedProject.display_name,
                user_code: form.value.assigned_to,
                project_activity: projectActivities,
                drive_link: this.selectedProject.drive_link,
                ltd_project_link: this.selectedProject.ltd_project_link,
                project_description: this.selectedProject.project_description,
                project_assigned_notes: form.value.assigned_to_note,
              },
            }),
          );

          this.formSent = true;
        }
      } else if (this.activityEntity === "contact") {
        this.selectedStatus = {
          status_name: this.companyContactStateData.find(
            (item) => item.value === form.value.status_code,
          ).name,
        };
        title = "Status Changed to " + this.selectedStatus.status_name;

        const contact = {
          is_published_yn: parseInt(form.value.status_code),
          company_code: this.selectedProject.company_code,
          tel_no: this.selectedProject.tel_no,
          email: this.selectedProject.email,
          designation: this.selectedProject.designation,
          contact_tag: this.selectedProject.contact_tag,
          start_date: this.selectedProject.start_date
            ? moment(this.selectedProject.start_date).format("YYYY-MM-DD")
            : null,
          end_date: this.selectedProject.end_date
            ? moment(this.selectedProject.end_date).format("YYYY-MM-DD")
            : null,
          lead_source: this.selectedProject.lead_source
            ? this.selectedProject.lead_source
            : null,
          website: this.selectedProject.website
            ? this.selectedProject.website
            : null,
          slug: this.selectedProject.slug ? this.selectedProject.slug : null,
          excerpt: this.selectedProject.excerpt
            ? this.selectedProject.excerpt
            : null,
          published_at: this.selectedProject.published_at
            ? this.selectedProject.published_at
            : null,
          password_protected_password: this.selectedProject
            .password_protected_password
            ? this.selectedProject.password_protected_password
            : null,
          description: this.selectedProject.description
            ? this.selectedProject.description
            : null,
          contact_activity: {
            contact_code: this.selectedCode,
            activity_type_code: this.formType,
            activity_title: title,
            activity_note: this.statusChangedForm.value.note,
            user_code: null,
            activity_participant_code: null,
            meeting_start_time: null,
            meeting_end_time: null,
            meeting_date: null,
            activityTags: null,
            entity: "contact",
            batch_id: uuid(),
          },
        };

        this.store.dispatch(
          new ContactUpdate({
            contact_code: this.selectedCode,
            contact: contact,
            currentScreen: "contact",
          }),
        );

        this.formSent = true;
      }
    }
  }

  onAddStatusChangedActivity() {
    // const title = 'Status Changed to ' + this.selectedStatus.status_name;
    switch (this.activityEntity) {
      case "project":
        this.store.dispatch(
          new ProjectGetActivity({
            entity: "project",
            project_code: this.selectedCode,
          }),
        );

        // this.store.dispatch(new ProjectAddActivity({
        //   project_code: this.selectedCode, activity_type: this.formType,
        //   activityTitle: title, activityNote: this.statusChangedForm.value.note, userParticipants: null,
        //   contactParticipants: null, meeting_start_time: null, meeting_end_time: null,
        //   meeting_date: null, activityTags: null, entity: 'project'
        // }));
        break;

      case "contact":
        this.store.dispatch(
          new GetContactActivity({
            entity: "contact",
            contactCode: this.selectedCode,
          }),
        );
        // this.store.dispatch(new AddContactActivity({
        //   contact_code: this.selectedCode, activity_type: this.formType,
        //   activityTitle: title, activityNote: this.statusChangedForm.value.note, userParticipants: null,
        //   contactParticipants: null, meeting_start_time: null, meeting_end_time: null,
        //   meeting_date: null, activityTags: null, entity: 'contact'
        // }));
        break;
    }
  }

  onUpdateStatusNote(form: FormGroup) {
    const activity = {
      activity_type: this.formType,
      activityTitle: form.value.activity_title,
      activityNote: form.value.note,
      userParticipants: null,
      contactParticipants: null,
      meeting_start_time: null,
      meeting_end_time: null,
      meeting_date: null,
      activityTags: null,
    };

    this.store.dispatch(
      new ProjectActivityUpdate({
        activity_code:
          this._activityInput$.getValue().activity[0].activity_code,
        projectActivity: activity,
        entity: "project",
      }),
    );

    this.formSent = true;
  }

  findCode(array, id, searchVal) {
    let result;
    array.some(
      (o) =>
        (result =
          o[searchVal] === id
            ? o
            : this.findCode(o.children || [], id, searchVal)),
    );
    return result;
  }

  setStatus(args, form) {
    if (args.itemData.status_code !== this.selectedProject.status) {
      form.controls.note.setValidators([Validators.required]);
      form.controls.note.markAsTouched();
      form.controls.note.updateValueAndValidity();
    } else {
      form.controls.note.clearValidators();
      form.controls.note.updateValueAndValidity();
    }
  }

  setAssignedTo(args, form) {
    if (args.itemData.user_code !== this.selectedProject.assigned_to) {
      form.controls.assigned_to_note.setValidators([Validators.required]);
      form.controls.assigned_to_note.markAsTouched();
      form.controls.assigned_to_note.updateValueAndValidity();
    } else {
      form.controls.assigned_to_note.clearValidators();
      form.controls.assigned_to_note.updateValueAndValidity();
    }
  }
  Complete(args) {}

  clearAll() {
    this.clearModal.hide();
    if (this.activityForm) {
      this.activityForm.reset();
    }
    if (this.statusChangedForm) {
      this.statusChangedForm.reset();
    }
  }
  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }
}
