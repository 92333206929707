import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../../core";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";
import { ActivatedRoute, Router } from "@angular/router";
import { Event } from "../../../../../core/models/event.model";
import { fromToDate } from "../../../../../app-validators";
import * as _ from "lodash";
import { debounce, EmitType } from "@syncfusion/ej2-base";
import {
  DropDownListComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-angular-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { EventsSetStatusNull } from "../../../../../core/store/actions/events.actions";
import * as moment from "moment/moment";

@Component({
  selector: "app-events-filter",
  templateUrl: "./events-filter.component.html",
  styleUrls: ["./events-filter.component.scss"],
})
export class EventsFilterComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("sidebarInstance") public sidebarInstance: SidebarComponent;
  @ViewChild("clearModal") public clearModal: DialogComponent;
  @ViewChild("countryDropdown") countryDropdown: DropDownListComponent;
  @ViewChild("hasAttended") hasAttended: DropDownListComponent;
  // Input Props
  @Input() users: any;
  @Input() countries: { [key: string]: object }[];
  // Event Emitters
  @Output() valueChange = new EventEmitter();
  @Output() editChange = new EventEmitter();

  // Sidebar Props
  public width = "auto";
  allowEdit = false;
  isEdit = false;
  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];
  // Form Props
  has_attended_options: { [key: string]: Object }[] = [
    { name: "Attended", value: "1" },
    { name: "Not Attended", value: "0" },
  ];
  public has_attended_fields: object = { value: "value", text: "name" };
  public contactFields: object = { value: "value", text: "name" };
  userFields: object = { value: "user_code", text: "first_name" };
  public formSent: boolean;
  eventsForm: FormGroup;
  errorMsg: string[] = [];
  error$: Observable<any>;
  success$: Observable<string>;
  duplicateMsg$ = new BehaviorSubject("");
  public eventsInput$: BehaviorSubject<Event | null> =
    new BehaviorSubject<Event | null>(null);
  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(false);
  public countryFields: object = {
    value: "country_code",
    text: "country_name",
  };
  editEventData: Event | null = null;
  isFilterReset = false;
  isFiltering = false;
  queryParams: any;
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.eventsForm) {
      this.generateCompanyForm();
    }
    if (changes.country && changes.country.currentValue) {
      this.populateSidebar();
    }
  }

  ngOnInit(): void {
    if (!this.eventsForm) {
      this.generateCompanyForm();
    }
    this.error$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.error) {
          return;
        }
        return store.events.error;
      }),
    );
    this.error$.subscribe((data: any) => {
      if (data) {
        try {
          const em = JSON.parse(data.error);
          const msg = [];
          const keys = Object.keys(em);
          if (keys.length) {
            keys.forEach((key) => {
              msg.push(`${key}: ${em[key]}`);
            });
          }
          this.errorMsg = _.cloneDeep(msg);
          console.log("error --", this.errorMsg);
          this.cd.detectChanges();
        } catch (e) {
          console.log("error", e);
        }
        this.formSent = false;
        this.isEdit = false;
      }
    });
    this.success$ = this.store.pipe(
      select((store) => {
        if (!store || !store.events || !store.events.success) {
          return;
        }
        return store.events.success.isForm;
      }),
    );
    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new EventsSetStatusNull());
      }
    });
    this.route.queryParams.subscribe((res) => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
      }
      this.populateSidebar();
    });
  }

  generateCompanyForm() {
    this.eventsForm = this.formBuilder.group(
      {
        event_name: new FormControl(""),
        city_name: new FormControl("", [Validators.maxLength(100)]),
        updated_by: new FormControl(null),
        created_by: new FormControl(null),
        country: new FormControl(null),
        address: new FormControl("", [Validators.maxLength(200)]),
        start_date: new FormControl(""),
        end_date: new FormControl(""),
        company_name: new FormControl(""),
        contact_name: new FormControl(""),
        has_attended_yn: new FormControl(null),
      },
      {
        validators: [fromToDate("start_date", "end_date")],
      },
    );
  }
  setFormValues() {
    this.isEdit = true;
    console.log("editEventData", this.editEventData);
    this.eventsForm.patchValue({
      event_name: this.editEventData.event_name,
      city_name: this.editEventData.city_name,
      address: this.editEventData.address,
      start_date: this.editEventData.start_date
        ? new Date(this.editEventData.start_date)
        : null,
      end_date: this.editEventData.end_date
        ? new Date(this.editEventData.end_date)
        : null,
      country: this.editEventData.country,
      updated_by: this.editEventData.updated_by,
      created_by: this.editEventData.created_by,
      company_name: this.editEventData.company_name,
      contact_name: this.editEventData.contact_name,
      has_attended_yn: this.editEventData.has_attended_yn,
    });
  }
  populateSidebar() {
    if (this.eventsForm) {
      for (const key in this.queryParams) {
        if (this.queryParams[key] && this.eventsForm.controls[key]) {
          if (key === "start_date" || key === "end_date") {
            this.eventsForm.controls[key].setValue(
              new Date(this.queryParams[key]),
            );
          }
          if (key !== "start_date" && key !== "end_date") {
            this.eventsForm.controls[key].setValue(
              _.cloneDeep(this.queryParams[key].split(",")),
            );
          }
          if (key === "has_attended_yn") {
            this.eventsForm.controls[key].setValue(this.queryParams[key]);
          } else {
            this.eventsForm.controls.has_attended_yn.setValue(null);
          }
        }
      }
      this.cd.detectChanges();
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
    this.isEdit = false;
    this.setCleared$.next(false);
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }

  clearAll() {
    this.clearModal.hide();
    this.queryParams = {};
    this.isFilterReset = true;
    this.router.navigate([], { queryParams: {} });
    this.eventsForm.reset();
    this.eventsForm.markAsPristine();
    this.isFiltering = false;
    this.formSent = false;
    this.cd.detectChanges();
  }

  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    query =
      e.text !== ""
        ? query.where("country_name", "startswith", e.text, true)
        : query;
    e.updateData(this.countries, query);
  };

  // CRUD APIs
  filterEvents(form: FormGroup) {
    this.isFilterReset = false;
    let filterValues = { ...form.value };
    console.log("filterValues ", filterValues);
    Object.keys(filterValues).forEach(
      (key) =>
        (filterValues[key] == null || filterValues[key] === "") &&
        delete filterValues[key],
    );

    if (filterValues.end_date) {
      filterValues.end_date = moment(filterValues.end_date).format(
        "YYYY-MM-DD",
      );
    }
    if (filterValues.start_date) {
      filterValues.start_date = moment(filterValues.start_date).format(
        "YYYY-MM-DD",
      );
    }
    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(",");
      }
    }
    try {
      if (
        filterValues.has_attended_yn === null ||
        filterValues.has_attended_yn === undefined
      ) {
        delete filterValues.has_attended_yn;
      }
    } catch (e) {
      console.log("error", e);
    }

    this.router.navigate([], { queryParams: filterValues });
    filterValues = { ...filterValues, page_size: 100, page_no: 1 };
    console.log("filterValues", filterValues, this.eventsForm.getRawValue());
    // this.store.dispatch(new CompanyOrderDeliverablesFilter(filterValues));
    this.sidebarInstance.hide();
  }
  clearHasAttended() {
    this.eventsForm.controls.has_attended_yn.setValue(null);
  }
  public onChangeHasAttended(e: any): void {
    console.log(e);
    try {
      const selectedId = e.value;
      if (selectedId !== null && selectedId !== undefined) {
        this.eventsForm.controls.has_attended_yn.setValue(selectedId);
        this.cd.detectChanges();
      } else {
        this.eventsForm.controls.has_attended_yn.setValue(null);
      }
      console.log("selectedId", selectedId);
    } catch (e) {
      this.eventsForm.controls.has_attended_yn.setValue(null);
      console.log("error", e);
    }
  }

  ngOnDestroy() {
    this.duplicateMsg$.unsubscribe();
  }
}
