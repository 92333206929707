import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NumericTextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from 'src/app/core';
import { ArticleType } from 'src/app/core/models/admin/article-type.model';
import { MetaDataResetError, UpdateArticleType } from 'src/app/core/store/actions/common.actions';

@Component({
  selector: 'app-article-types-form',
  templateUrl: './article-types.component.html',
  styleUrls: ['./article-types.component.scss']
})
export class ArticleTypesComponent implements OnInit {

  
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent; 
  @ViewChild('rankOrder') rankOrder: NumericTextBoxComponent;

  @Input() maxValue: number;
  @Output() valueChange = new EventEmitter();

  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  article_type_name: string = '';

  articleTypeForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  public minValue: number = 1;
  articleStagesFields: any;
  
  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _articleTypesInput$: BehaviorSubject<ArticleType | null> = new BehaviorSubject<ArticleType | null>(null);

  @Input() set articleTypeInput(mode: ArticleType | null) {
    this._articleTypesInput$.next(mode);
  }

  get articleTypeInput(): ArticleType | null {
    return this._articleTypesInput$.getValue();
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.articleTypeForm) {
      this.generateArticleTypeForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateArticleTypeForm() {
    this.articleTypeForm = this.formBuilder.group({
      filter_color: new FormControl('', [Validators.required]),
      short_code: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      filter_text_color: new FormControl('', [Validators.required]),
      rank_order: new FormControl('', [Validators.required]),
      description: new FormControl('', Validators.maxLength(200))
    });
  }

  onAddUpdateArticleType(form: FormGroup, isUpdate = false) {
     if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        // this.store.dispatch(new AddArticleStage({article_stage_name: form.value.article_stage_name, article_stage_parent_code: form.value.article_stage_parent_code, description: form.value.description,
        // filter_color: form.value.filter_color, short_code: form.value.short_code, filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      } else {
        this.store.dispatch(new UpdateArticleType({description: form.value.description,
          filter_color: form.value.filter_color, short_code: form.value.short_code,
          article_type_code: this._articleTypesInput$.getValue().article_type_code,
          filter_text_color: form.value.filter_text_color, rank_order: this.rankOrder.value}));
      }
    }
  }

  openSideBar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.articleTypeForm.reset();
    this.sidebarInstance.hide();

    this.article_type_name = '';

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.articleTypeForm) {
      this.generateArticleTypeForm();
    }

    if (this._articleTypesInput$.getValue()) {
      this.article_type_name = this._articleTypesInput$.getValue().article_type_name;
      this.articleTypeForm.setValue({
        filter_color: this._articleTypesInput$.getValue().filter_color ? this._articleTypesInput$.getValue().filter_color : null,
        short_code: this._articleTypesInput$.getValue().short_code ? this._articleTypesInput$.getValue().short_code : null,
        filter_text_color: this._articleTypesInput$.getValue().filter_text_color ? this._articleTypesInput$.getValue().filter_text_color : null,
        rank_order: this._articleTypesInput$.getValue().rank_order ? this._articleTypesInput$.getValue().rank_order : null,
        description: this._articleTypesInput$.getValue().description ? this._articleTypesInput$.getValue().description : null,
      });
    }
  }
  clearAll(){
    this.clearModal.hide()
  this.articleTypeForm.reset()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  } 
}
