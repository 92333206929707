import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {trimValidator} from '../../../../../app-validators';
import {CompanyRoleAdd, CompanyRoleResetError, CompanyRoleUpdate} from '../../../../../core/store/actions/company-role.actions';
import {CompanyRole} from '../../../../../core/models/company-role.model';
import {CompanyTypeResetError} from '../../../../../core/store/actions/company-type.actions';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-company-roles-form',
  templateUrl: './company-roles.component.html',
  styleUrls: ['./company-roles.component.scss']
})
export class CompanyRolesComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;
  @Input() parentCompanyRoles: any;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  @Output() valueChange = new EventEmitter();
  companyRoleForm: FormGroup;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  public companyRoleFields: object = {value: 'company_role_code', text: 'company_role_name'};

  public _companyRoleInput$: BehaviorSubject<CompanyRole | null> = new BehaviorSubject<CompanyRole | null>(null);

  @Input() set companyRoleInput(mode: CompanyRole | null) {
    this._companyRoleInput$.next(mode);
  }

  get companyRoleInput(): CompanyRole | null {
    return this._companyRoleInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.companyRoleForm) {
      this.generateCompanyRoleForm();
    }

    this.error$ = this.store.pipe(select(store => store.companyRole.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.companyRole.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateCompanyRoleForm() {
    this.companyRoleForm = this.formBuilder.group({
      company_role_parent_code: new FormControl(''),
      company_role_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.clearForm();
    this.sidebarInstance.hide();
  }

  clearForm() {
    this.companyRoleForm.reset();
    this.companyRoleForm.controls.company_role_parent_code.setValue('');
    this.store.dispatch(new CompanyRoleResetError());
  }

  onAddUpdateCompanyType(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!this._companyRoleInput$.getValue()) {
        this.store.dispatch(new CompanyRoleAdd({
          company_role_name: form.value.company_role_name,
          parent_type_code: form.value.company_role_parent_code[0]
        }));
      } else {
        this.store.dispatch(new CompanyRoleUpdate({
          companyRoleName: form.value.company_role_name,
          company_role_code: this._companyRoleInput$.getValue().company_role_code,
          parent_type_code: form.value.company_role_parent_code ? form.value.company_role_parent_code[0] : null
        }));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._companyRoleInput$.getValue()) {
      this.companyRoleForm.setValue({
        company_role_name: this._companyRoleInput$.getValue().company_role_name,
        company_role_parent_code: this._companyRoleInput$.getValue().parent ? [this._companyRoleInput$.getValue().parent] : null
      });
    }

    if (changes.parentCompanyRoles) {
      this.companyRoleFields = {
        dataSource: this.parentCompanyRoles,
        value: 'company_role_code',
        text: 'company_role_name',
        child: 'children'
      };
    }
  }

  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
