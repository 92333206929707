import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ResponseData} from '../ResponseData.interface';
import {environment} from '../../../environments/environment.prod';
import { v4 as uuid } from 'uuid';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
const API_URL = environment.API_URL;
const LTD_URL = environment.LTD_API_URL + 'email-templates';
const LTD_EMAIL_URL = environment.API_URL + 'email-templates';

@Injectable()
export class CommonService {
  id: string;
  data = {};
  private previewData = new BehaviorSubject('default data');
  priviewDetails$ = this.previewData.asObservable();
  private details$ = new Subject<any>();
  private histdata$ = new Subject<any>();
  historyData$ = this.histdata$.asObservable();
  selectedDetails$ = this.details$.asObservable();
  constructor(private http: HttpClient) {}

  // Get Company Types
  getCompanyTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'company-type');
  }

  getUserSettings(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'user-settings');
  }

  // Get Countries
  getCountries(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'country');
  }

  // Get Project Types
  getProjectTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-type');
  }

  // Get Project Status
  getProjectStatus(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-status');
  }

  // Get users
  getUsers(): Observable<ResponseData> {

    return this.http.get<ResponseData>(API_URL + 'user');
  }

  // Get Company Roles (Project)
  getCompanyRoles(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'company-role');
  }

  // Get Activity Types
  getActivityTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'activity-type');
  }

  // Get All Tags
  getTags(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'tag');
  }

  // Get user avatar
  getUserAvatar(userCode: string): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'user/generate-avatar/' + userCode);
  }

  // Get project count for status - Dashboard
  getProjectCount(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-status/list-project-count');
  }

  getProjectCountByYear(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-status/list-project-count-by-year');
  }

  // Get Order Roles
  getOrderRoles(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'order-role');
  }

  // Get Order Roles
  getSourceTypes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'project-source-type');
  }

  // Get Release Notes
  getReleaseNotes(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'release-notes');
  }

  // Get Categories
  getCategories(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'application-category');
  }

  // Get Email Categories
  getEmailCategories(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'email-category');
  }

  // Format Email
  formatEmail(company_contact_code: string, sender_email: string, email_template_code: string, company_code: string, project_code, type): Observable<ResponseData> {
    const request: any = {
      company_contact_code, sender_email, email_template_code, company_code, project_code
    };

    if (type === 'company') {
      delete request.project_code;
    }
    return this.http.post<ResponseData>(API_URL + 'send-email/format', request);
  }

  // Send Email
  sendEmail(receiver_email: string, sender_email: string, email_subject: string, email_content: string,
            email_template_code: string, company_code: string, project_code, activity: any, type): Observable<ResponseData> {
    let request: any = {company_contact_code: receiver_email, sender_email, email_subject, email_content, email_template_code,
      company_code,  batch_id: uuid()};

    if (type === 'company') {
      request = {...request, company_activity: activity};
    } else {
      request = {...request, project_activity: activity, project_code};

    }

    return this.http.post<ResponseData>(API_URL + 'send-email', request);
  }

  // Get email keywords
  getEmailKeywords(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'email-keyword');
  }

  // Get email keywords
  getDeliverableItemDate(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'deliverable-item-date');
  }

  // Get social media platform
  getSocialMediaPlatform(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'social-media-platform');
  }

  getGoogleMapsCoordinates(address) {
    return this.http.get<ResponseData>(API_URL + 'country/get-map-coordinates', { params: {address}});
  }

  getGoogleMapsAddress(latitude, longitude) {
    const latlng = latitude + ',' + longitude;
    return this.http.get<ResponseData>(API_URL + 'country/get-map-address', { params: {latlng}});
  }

  getDefaultTabs(master_type?: string) {
    return this.http.get<ResponseData>(API_URL + 'common-master', { params: {master_type}});
  }

  // Get Regions
  getRegions(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'region');
  }

  // Sync logs
  getSyncJobs(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'sync-jobs');
  }

  // Sync logs
  getFilteredSyncJobs(params): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'sync-jobs/filter', {params});
  }

  // Sync Blocks
  getSyncBlocks(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'sync-block');
  }
  // Get social media platform
  getOrderStatus(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'order-status');
  }

  // Sync logs
  getLTDDataSyncLogs(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'get-ltd-sync-data');
  }

  // Get community service
  getCommunityService(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'community-service');
  }

  // Get Product Type
  getProductType(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'product-type');
  }

  // Get community service
  getCommunityType(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'community-type');
  }

  // Get article stage
  getArticleStage(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'article-stage');
  }

   // Get article type
   getArticleType(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'article-type');
  }

  // Get article type
  updateArticleType(articleTypeCode, filter_color, short_code, filter_text_color, rank_order, description): Observable<ResponseData> {
    const params = {filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order, description};

    return this.http.put<ResponseData>(API_URL + 'article-type/' + articleTypeCode, params);
  }

  getEdition(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'edition');
  }

  retryOrIgnoreSync(params): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'retry-canvas-sync', {params});
  }

  // Get article dates
  getArticleDates(): Observable<ResponseData> {
    return this.http.get<ResponseData>(API_URL + 'article-date');
  }

  // Get dates
  getDates(params): Observable<ResponseData> {
    if (params) {
      return this.http.get<ResponseData>(API_URL + 'date', {params});
    } else {
      return this.http.get<ResponseData>(API_URL + 'date');
    }
  }

  // social types
  getProjectSocialType(): Observable<ResponseData> {

    return this.http.get<ResponseData>(API_URL + 'social-dates');

  }


  addProjectSocialType(social_date_name: string, filter_color: string, short_code: string, filter_text_color: string, rank_order: number, description: string) {
    const params = { social_date_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order, description };
    return this.http.post<ResponseData>(API_URL + 'social-dates', params);
  }

  UpdateProjectSocialType(social_data_code:string,social_date_name: string, filter_color: string, short_code: string,
    filter_text_color: string, rank_order: number, description: string) {
    const params = { social_date_name, filter_color, short_code, batch_id: uuid(), filter_text_color, rank_order, description };
    return this.http.put<ResponseData>(API_URL + 'social-dates/'+social_data_code, params);

  }

getProjectSocialDateCalenderData(params): Observable<ResponseData> {
  if (params) {

    return this.http.get<ResponseData>(API_URL + 'project-social-dates', {params});
  } else {

    return this.http.get<ResponseData>(API_URL + 'project-social-dates');
  }
}
// for ltd-emails history details page
setData(details:any){


  this.details$.next(details);
}

getData():Observable<any> {
return this.selectedDetails$
}
// for ltd-emails show preview data setting
setPreviewData(data:any){

  this.previewData.next(data)
}

// getPreviewData():Observable<any>{
//   console.log(this.priview$)
//   return this.priview$
// }

// LTD EMAILS
getAllLtdEmails(): Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL);

}
getEmailHistory(parameters): Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/history',{params: parameters });

}

getEmailRun(parameters): Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/run-log',{params: parameters });

}

getEmailTransactionHistory(code): Observable<ResponseData> {

 return this.http.get<ResponseData>(LTD_EMAIL_URL+'/'+code+'/transaction-history',);

}

getSingleTemplate(code) :Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/'+code);

}

GetemailPreview(code):Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/'+code+'/preview');

}

putEmailPreview(param){
  return this.http.post(LTD_EMAIL_URL+'/preview',param,{responseType: 'text'});
}

GetemailSettings(code):Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/'+code+'/settings');

}
putemailSettings(param):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/settings',param);

}

GetemailSchedule(code):Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/'+code+'/schedule');

}
putemailSchedule(param):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/schedule',param);

}
DisableTemplate(code):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/disable',code);

}
enableTemplate(code):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/enable',code);

}
deleteTemplate(code) {

  return this.http.delete<ResponseData>(LTD_EMAIL_URL+'/'+code);

}
sendTestMail(data):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/test-email',data);

}
reset(data):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/reset-preview',data);

}
getAllProjectTypes():Observable<ResponseData> {

  return this.http.get<ResponseData>(LTD_EMAIL_URL+'/ltd-project-types');

}

dispatch(data):Observable<ResponseData> {

  return this.http.post<ResponseData>(LTD_EMAIL_URL+'/dispatch',data);

}
calculateOrderAmount(){
  return this.http.get<ResponseData> (API_URL +'report/order-deliverables')
}
}
