<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>


  <div class="p-15">
    <h2 *ngIf="currentPage === 'source'">Source Filter</h2>
    <h2 *ngIf="currentPage === 'contact'">Contact Filter</h2>
    <h2 *ngIf="currentPage === 'article' && selectedTab !== 'article-date'">Article Filter</h2>
    <h2 *ngIf="currentPage === 'project'">Project Filter</h2>
    <h2 *ngIf="currentPage === 'company'">Company Filter</h2>
    <h2 *ngIf="currentPage === 'article' && selectedTab === 'article-date'">Article Dates Filter</h2>

    <ul class="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x mb-15" role="tablist">
      <li *ngIf="currentPage !== 'article'" class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'source'"
          (click)="setTab('source')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'source'">
          <span class="nav-text" *ngIf="currentPage !== 'company'">Source</span>
          <span class="nav-text" *ngIf="currentPage === 'company'"> Project Source</span>

        </a>
      </li>

      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'contact'" (click)="setTab('contact')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'contact'">
          <span class="nav-text">Contact</span>
        </a>
      </li>
      <li *ngIf="currentPage !== 'project'" class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'article'"
          (click)="setTab('article')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'article'">
          <span class="nav-text">Article</span>
        </a>
      </li>
      <li *ngIf="currentPage === 'article'" class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'article-date'"
          (click)="setTab('article-date')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'article-date'">
          <span class="nav-text">Article Dates</span>
        </a>
      </li>
      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'company'" (click)="setTab('company')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'company'">
          <span class="nav-text">Company</span>
        </a>
      </li>
      <li *ngIf="currentPage !== 'article'" class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'project'"
          (click)="setTab('project')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'project'">
          <span class="nav-text" *ngIf="currentPage !== 'company'">Project</span>
          <span class="nav-text" *ngIf="currentPage === 'company'">Project Role</span>
        </a>
      </li>
    </ul>

    <form *ngIf="currentPage !== 'article'" [ngClass]="selectedTab === 'source' ? 'show' : 'hide'" id="sourcesformId"
          [formGroup]="filterForm" #formDir="ngForm" class="form-horizontal">
      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="sources_reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="project_source_reference_id" floatLabelType="Auto"
                     (blur)="filterForm.controls.project_source_reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="filterForm.controls.project_source_type_code.value == ''">
        <ejs-dropdowntree formControlName="project_source_type_code" id='project_source_type_code_filter'
                          [showCheckBox]='true'
                          [treeSettings]='treeSettings'
                          floatLabelType="Auto" (blur)="filterForm.controls.project_source_type_code.markAsTouched()"
                          cssClass="e-filled" [fields]='sourceTypeFields' placeholder='Source Type'></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker id="source_updated_at" formControlName="project_source_updated_at" [allowEdit]="allowEdit"
                             placeholder='Updated between' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker id="source_created_at" formControlName="project_source_created_at" [allowEdit]="allowEdit"
                             placeholder='Created between' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='multiselect-checkbox-status' formControlName="project_source_status_code"
                         [dataSource]='projectSourceStatus'
                         placeholder='Source Status' [fields]='projectSourceStatusField' floatLabelType="Auto"
                         cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div *ngIf="currentPage === 'company'">

        <div class="separator separator-dashed mt-8 mb-5"></div>
        <h3>Source Company</h3>

        <div class="form-group sf-outline-input mb-10 mt-10">
          <ejs-textbox id="project_source_company_name" placeholder="Source Company Name" cssClass="e-filled"
                       formControlName="project_source_company_name" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_company_name.markAsTouched()"
          ></ejs-textbox>

        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_source_company_reference_id" placeholder="Source Company Reference ID"
                       cssClass="e-filled"
                       formControlName="project_source_company_reference_id" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_company_reference_id.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="filterForm.controls.project_source_company_community_type_code.value == ''">
          <!-- <ejs-dropdowntree formControlName="project_source_company_company_type_code"
                            id='project_source_company_company_type_code' allowMultiSelection='true'
                            [treeSettings]='treeSettings'
                            [fields]='companyTypeField' placeholder='Source Company Type' floatLabelType="Auto"
                            sortOrder="Ascending"
                            cssClass="e-filled"
                            (blur)="filterForm.controls.project_source_company_company_type_code.markAsTouched()"
                            [showCheckBox]='true'></ejs-dropdowntree> -->
          <ejs-multiselect id='project_source_company_company_type_code-checkbox' formControlName="project_source_company_community_type_code"
                            [dataSource]='communityTypes'
                            placeholder='Source Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
                            mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                            (change)="setCompanyType($event, projectFilterForm)"
                            filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_account_owner-checkbox'
                           formControlName="project_source_company_account_owner" #checkbox [dataSource]='users'
                           placeholder='Source Company Account Owner ' [fields]='userFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_account_owner-checkbox'
                           formControlName="project_source_company_account_manager" #checkbox [dataSource]='users'
                           placeholder='Source Company Sales' [fields]='userFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>


        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_published_by' formControlName="project_source_company_published_by"
                           #checkbox [dataSource]='users'
                           placeholder='Source Company Published by' [fields]='userFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template>
                          </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_country_code-checkbox'
                           formControlName="project_source_company_country_code"
                           #checkbox [dataSource]='countries'
                           placeholder='Source Company Country' [fields]='countryField' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_is_inactive_yn' formControlName="project_source_company_is_inactive_yn"
                           #checkbox [dataSource]='status'
                           placeholder='Source Company Active Status' [fields]='statusFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>


        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="filterForm.controls.project_source_company_company_role_code.value == ''">
          <ejs-dropdowntree formControlName="project_source_company_company_role_code"
                            id='project_source_company_company_role_code' allowMultiSelection='true'
                            [showCheckBox]="true"
                            [fields]='companyRoleField' placeholder='Company Role' floatLabelType="Auto"
                            sortOrder="Ascending"
                            cssClass="e-filled"
                            (blur)="filterForm.controls.project_source_company_company_role_code.markAsTouched()"
                            [showSelectAll]=true></ejs-dropdowntree>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_source_company_updated_at" [allowEdit]="allowEdit"
                               placeholder='Select a Updated At Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_source_company_created_at" [allowEdit]="allowEdit"
                               placeholder='Select a Created At Date Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>


        <div class="separator separator-dashed mt-8 mb-5"></div>
        <h3>Source Contact</h3>

        <div class="form-group sf-outline-input mb-10 mt-10">
          <ejs-textbox id="project_source_contact_name" placeholder="Contact Name" cssClass="e-filled"
                       formControlName="project_source_contact_name" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_contact_name.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_source_reference_id" placeholder="Reference ID" cssClass="e-filled"
                       formControlName="project_source_contact_reference_id" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_contact_reference_id.markAsTouched()"
          ></ejs-textbox>
        </div>


        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="company_name" placeholder="Company Name" cssClass="e-filled"
                       formControlName="project_source_contact_company_name" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_contact_company_name.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_source_email" placeholder="Source Contact Email" cssClass="e-filled"
                       formControlName="project_source_contact_email" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_contact_email.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="filterForm.controls.project_source_contact_community_type_code.value == ''">
          <!-- <ejs-dropdowntree formControlName="project_source_contact_company_type_code"
                            id='project_source_contact_company_type_code'
                            allowMultiSelection='true'
                            [fields]='companyTypeField' placeholder='Company Type' cssClass="e-filled"
                            sortOrder="Ascending"
                            (blur)="filterForm.controls.project_source_contact_company_type_code.markAsTouched()"
                            floatLabelType="Auto"
                            (change)="setCompanyType($event, contactFilterForm)"
                            [treeSettings]='treeSettings' [showCheckBox]='true'></ejs-dropdowntree> -->

          <ejs-multiselect id='project_source_contact_company_type_code-checkbox' formControlName="project_source_contact_community_type_code"
                            [dataSource]='communityTypes'
                            placeholder='Source Contact Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
                            mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                            (change)="setCompanyType($event, contactFilterForm)"
                            filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_multiselect-checkbox'
                           formControlName="project_source_contact_country_code" [dataSource]='countries'
                           placeholder='Contact Country' [fields]='countryField' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <!--      <div class="form-group sf-outline-input mb-10">-->
        <!--        <ejs-multiselect id='published_by' formControlName="contact_published_by" #checkbox [dataSource]='users'-->
        <!--                         placeholder='Published by' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"-->
        <!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"-->
        <!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
        <!--      </div>-->


        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_source_contact_updated_at" name="updated_at"
                               [allowEdit]="allowEdit"
                               placeholder='Select a Updated At Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_source_contact_created_at" name="created_at"
                               [allowEdit]="allowEdit"
                               placeholder='Select a Created At Date Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="separator separator-dashed mt-8 mb-5"></div>
        <h3>Source Project</h3>

        <div class="form-group sf-outline-input mb-10 mt-10">
          <ejs-textbox id="project_source_project_name" placeholder="Project Name" cssClass="e-filled"
                       formControlName="project_source_project_name" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_project_name.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_source_project_reference_id" placeholder="Source Project Reference ID"
                       cssClass="e-filled"
                       formControlName="project_source_project_reference_id" floatLabelType="Auto"
                       (blur)="filterForm.controls.project_source_project_reference_id.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="filterForm.controls.project_source_project_type_code.value == ''">
          <ejs-dropdowntree formControlName="project_source_project_type_code" id='project_source_project_type_code'
                            allowMultiSelection='true'
                            [fields]='projectTypeFields' placeholder='Source Project Type' cssClass="e-filled"
                            [treeSettings]='treeSettings'
                            sortOrder="Ascending"
                            (blur)="filterForm.controls.project_source_project_type_code.markAsTouched()"
                            floatLabelType="Auto" [showCheckBox]='true'
                            (change)="setDropdownValue($event, filterForm, 'project_source_project_type_code')"></ejs-dropdowntree>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="filterForm.controls.project_source_project_status_code.value == ''">
          <ejs-dropdowntree formControlName="project_source_project_status_code"
                            id='project_source_project_status_codeee' [showCheckBox]='true'
                            [treeSettings]='treeSettings'
                            floatLabelType="Auto"
                            (blur)="filterForm.controls.project_source_project_status_code.markAsTouched()"
                            cssClass="e-filled" [fields]='projectStatusFields'
                            placeholder='Source Project Status'></ejs-dropdowntree>
        </div>


        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_assigned_to_user'
                           formControlName="project_source_project_assigned_to_user" #checkbox [dataSource]='users'
                           placeholder='Source Project Assigned To' [fields]='userFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_source_country_code' formControlName="project_source_project_country_code"
                           #checkbox [dataSource]='countries'
                           placeholder='Source Project Country' [fields]='countryField' cssClass="e-filled"
                           floatLabelType="Auto"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="filterForm.controls.project_source_project_region_code.value == ''">
          <ejs-dropdowntree formControlName="project_source_project_region_code" id='project_source_project_region_code' allowMultiSelection='true'
                            [fields]='regionFields' placeholder='Project Region' cssClass="e-filled"
                            [treeSettings]='treeSettings'
                            sortOrder="Ascending"
                            floatLabelType="Auto" [showCheckBox]='true'
                            (change)="setDropdownValue($event, filterForm, 'project_source_project_region_code')"></ejs-dropdowntree>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="project_source_project_featured_date" name="featured_date"
                               placeholder='Featured Between' openOnFocus="true" [allowEdit]="allowEdit"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-daterangepicker formControlName="project_source_project_proposed_post_date"
                                 placeholder='Published Between' openOnFocus="true" [allowEdit]="allowEdit"
                                 floatLabelType='Auto' cssClass="e-filled">
            </ejs-daterangepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 ml-2 w-100">
            <ejs-multiselect id='project_source_project_published_by'
                             formControlName="project_source_project_published_by" #checkbox
                             [dataSource]='users'
                             placeholder='Published By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                             mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                             filterBarPlaceholder='Search'>
                             <ng-template  #itemTemplate="" let-data style="display: inline-block">
                              <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                                <span>{{data.first_name}}</span>
                              </div>
                              <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                                <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                              </div>

                            </ng-template> </ejs-multiselect>
          </div>

        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-daterangepicker formControlName="project_source_project_updated_at" [allowEdit]="allowEdit"
                                 placeholder='Updated Between' openOnFocus="true"
                                 floatLabelType='Auto' cssClass="e-filled">
            </ejs-daterangepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 ml-2 w-100">
            <ejs-multiselect id='project_source_updated_by' formControlName="project_source_project_updated_by"
                             #checkbox [dataSource]='users'
                             placeholder='Updated By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                             mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                             filterBarPlaceholder='Search'>
                             <ng-template  #itemTemplate="" let-data style="display: inline-block">
                              <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                                <span>{{data.first_name}}</span>
                              </div>
                              <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                                <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                              </div>

                            </ng-template> </ejs-multiselect>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 mr-2 w-100">
            <ejs-daterangepicker formControlName="project_source_project_created_at" [allowEdit]="allowEdit"
                                 placeholder='Created Between' openOnFocus="true"
                                 floatLabelType='Auto' cssClass="e-filled">
            </ejs-daterangepicker>
          </div>

          <div class="form-group sf-outline-input mb-10 ml-2 w-100">
            <ejs-multiselect id='project_source_created_by' formControlName="project_source_project_created_by"
                             #checkbox [dataSource]='users'
                             placeholder='Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                             mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                             filterBarPlaceholder='Search'>
                             <ng-template  #itemTemplate="" let-data style="display: inline-block">
                              <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                                <span>{{data.first_name}}</span>
                              </div>
                              <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                                <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                              </div>

                            </ng-template> </ejs-multiselect>
          </div>


        </div>


        <!--      <div class="form-group sf-outline-input mb-10">-->
        <!--        <ejs-multiselect id='company' formControlName="project_company_code" #checkboxCompany [dataSource]='companyList'-->
        <!--                         placeholder='Project Company' [fields]='companyFields' cssClass="e-filled"-->
        <!--                         floatLabelType="Auto"-->
        <!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'-->
        <!--                         (filtering)="onFiltering($event, 'company')"-->
        <!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
        <!--      </div>-->

        <!--      <div class="form-group sf-outline-input mb-10">-->
        <!--        <ejs-multiselect id='contact' formControlName="project_contact_code" #checkboxContact [dataSource]='contactList'-->
        <!--                         placeholder='Project Contact' [fields]='contactFields' cssClass="e-filled"-->
        <!--                         floatLabelType="Auto"-->
        <!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'-->
        <!--                         (filtering)="onFiltering($event, 'contact')"-->
        <!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
        <!--      </div>-->

        <!--        <div class="form-group sf-outline-input mb-10">-->
        <!--        <ejs-dropdowntree id='project_source_type_code_filter' formControlName="project_source_type_code"-->
        <!--                          [treeSettings]='treeSettings' [allowMultiSelection]=true-->
        <!--                         placeholder='Source Type' [fields]='sourceTypeFields' floatLabelType="Auto" cssClass="e-filled"-->
        <!--                         showCheckBox="true" [showDropDownIcon]='true' -->
        <!--                         filterBarPlaceholder='Search'></ejs-dropdowntree>-->

        <!--        <ejs-dropdowntree formControlName="project_source_type_code" id='project_source_type_code_filter' [showCheckBox]='true'-->
        <!--                          [treeSettings]='treeSettings'-->
        <!--                          floatLabelType="Auto" (blur)="filterForm.controls.project_source_type_code.markAsTouched()"-->
        <!--                          cssClass="e-filled" [fields]='sourceTypeFields' placeholder='Source Type'></ejs-dropdowntree>-->
        <!--        </div>-->


        <div class="form-group sf-outline-input mb-10">
          <ejs-checkbox formControlName="project_source_project_is_deleted_yn" #wcheckbox
                        label="Include Deleted Projects: "
                        labelPosition="Before"></ejs-checkbox>
        </div>

      </div>

    </form>

    <form [ngClass]="selectedTab === 'contact' ? 'show' : 'hide'" id="formId" [formGroup]="contactFilterForm"
          #formDir="ngForm" class="form-horizontal">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="contact_name" placeholder="Contact Name" cssClass="e-filled"
                     formControlName="contact_name" floatLabelType="Auto"
                     (blur)="contactFilterForm.controls.contact_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="contact_reference_id" floatLabelType="Auto"
                     (blur)="contactFilterForm.controls.contact_reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="company_name" placeholder="Company Name" cssClass="e-filled"
                     formControlName="contact_company_name" floatLabelType="Auto"
                     (blur)="contactFilterForm.controls.contact_company_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email" placeholder="Contact Email" cssClass="e-filled"
                     formControlName="contact_email" floatLabelType="Auto"
                     (blur)="contactFilterForm.controls.contact_email.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="contactFilterForm.controls.contact_community_type_code.value == ''">
        <!-- <ejs-dropdowntree formControlName="contact_company_type_code" id='contact_company_type_code'
                          allowMultiSelection='true'
                          [fields]='companyTypeField' placeholder='Company Type' cssClass="e-filled"
                          sortOrder="Ascending"
                          (blur)="contactFilterForm.controls.contact_company_type_code.markAsTouched()"
                          floatLabelType="Auto"
                          (change)="setCompanyType($event, contactFilterForm)"
                          [treeSettings]='treeSettings' [showCheckBox]='true'></ejs-dropdowntree> -->
        <ejs-multiselect id='contact_community_type_code-checkbox' formControlName="contact_community_type_code"
                          [dataSource]='communityTypes'
                          placeholder='Contact Company Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
                          mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                          filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='multiselect-checkbox' formControlName="contact_country_code" [dataSource]='countries'
                         placeholder='Contact Country' [fields]='countryField' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <!--      <div class="form-group sf-outline-input mb-10">-->
      <!--        <ejs-multiselect id='published_by' formControlName="contact_published_by" #checkbox [dataSource]='users'-->
      <!--                         placeholder='Published by' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"-->
      <!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"-->
      <!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
      <!--      </div>-->


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='contact_tag' #contact_tags [dataSource]="tagModelData" floatLabelType="Auto"
                         cssClass="e-filled"
                         [fields]="tagFields" formControlName='contact_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Contact Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event, 'contact')"
                         (change)="onChange($event, 'contact')"
                         (blur)="onBlur($event, contactFilterForm, 'contact_tag')"
                         (removed)="selectedTag = []"
                         [(ngModel)]="modelDataContactTag"
                         (select)="select($event)"
                         ></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="contact_updated_at" name="updated_at" [allowEdit]="allowEdit"
                             placeholder='Select a Updated At Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="contact_created_at" name="created_at" [allowEdit]="allowEdit"
                             placeholder='Select a Created At Date Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>
      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='is_published_yn' formControlName="contact_is_published_yn" #checkbox [dataSource]='PulishedStatus'
                         placeholder='Is Published'[fields]='PulishedFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <!-- <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="contact_is_published_yn" #wcheckbox label="Show Only Published Contacts: "
                      labelPosition="Before"></ejs-checkbox>
      </div> -->


      <div *ngIf="error$ | async as error">{{error.error}}</div>

    </form>

    <form *ngIf="currentPage !== 'project'" [ngClass]="selectedTab === 'article'? 'show' : 'hide'" id="formdd"
          [formGroup]="articleFilterForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="article_name" placeholder="Article Name" cssClass="e-filled"
                     formControlName="article_name" floatLabelType="Auto"
                     (blur)="articleFilterForm.controls.article_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="article_reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="article_reference_id" floatLabelType="Auto"
                     (blur)="articleFilterForm.controls.article_reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="articleFilterForm.controls.article_category_code.value == ''">
        <ejs-multiselect id='aarticle_category_code' formControlName="article_category_code" #checkbox
                         [dataSource]='articleCategory' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Article Category' [fields]='articleCategoryFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="currentPage === 'article'"
           [class.hide-label]="articleFilterForm.controls.article_type_code.value == ''">
        <ejs-multiselect id='article_type_code' formControlName="article_type_code" #checkbox
                         [dataSource]='articleType' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Article Type' [fields]='articleTypeFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="currentPage === 'article'"
           [class.hide-label]="articleFilterForm.controls.article_country_code.value == ''">
        <ejs-multiselect id='article_country_code' formControlName="article_country_code" #checkbox
                         [dataSource]='countries' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Article Country' [fields]='countryField'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="currentPage === 'article'"
           [class.hide-label]="articleFilterForm.controls.article_edition_code.value == ''">
        <ejs-multiselect id='article_edition_code' formControlName="article_edition_code" #checkbox
                         [dataSource]='editions' cssClass="e-filled" floatLabelType="Auto"
                         placeholder='Edition' [fields]='editionFields'
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="articleFilterForm.controls.article_community_type_code.value == ''">
        <!-- <ejs-dropdowntree formControlName="article_company_type_code" id='article_company_type_code'
                          allowMultiSelection='true' [treeSettings]='treeSettings'
                          [fields]='companyTypeField' placeholder='Company Type' floatLabelType="Auto"
                          sortOrder="Ascending"
                          cssClass="e-filled"
                          (blur)="articleFilterForm.controls.article_company_type_code.markAsTouched()"
                          [showCheckBox]='true'></ejs-dropdowntree> -->
        <ejs-multiselect id='article_community_type_code-checkbox' formControlName="article_community_type_code"
                            [dataSource]='communityTypes'
                            placeholder='Article Company Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
                            mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                            filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
<!--        <ejs-multiselect #articleCompanyCode id='article_company_code' formControlName="article_company_code" #checkboxCompany-->
<!--                         [dataSource]='companyList' [allowFiltering]=true (focus)="contactFocus()"-->
<!--                         placeholder='Article Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"-->
<!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'-->
<!--                         (filtering)="onFiltering($event, 'company')"-->
<!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
        <ejs-multiselect #articleCompanyCode id='article_company_code' formControlName="article_company_code" #checkboxContact
                         [dataSource]='companyList' [allowFiltering]=true
                         placeholder='Article Company' [fields]='companyFields' cssClass="e-filled"
                         floatLabelType="Auto" (focus)="contactFocus()"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'company')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect #articleContactCode id='article_contact_code' formControlName="article_contact_code" #checkboxContact
                         [dataSource]='contactList' [allowFiltering]=true
                         placeholder='Article Contact' [fields]='contactFields' cssClass="e-filled"
                         floatLabelType="Auto" (focus)="contactFocus()"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'contact')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10 w-100">
        <ejs-multiselect id='article_assigned_to' formControlName="article_assigned_to"
                         #checkbox [dataSource]='users'
                         placeholder='Assigned To' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'>
                         <ng-template  #itemTemplate="" let-data style="display: inline-block">
                          <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                            <span>{{data.first_name}}</span>
                          </div>
                          <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                            <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                          </div>

                        </ng-template> </ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10 w-100">
        <ejs-multiselect id='article_published_by' formControlName="article_published_by"
                         #checkbox [dataSource]='users'
                         placeholder='Published By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'>
                         <ng-template  #itemTemplate="" let-data style="display: inline-block">
                          <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                            <span>{{data.first_name}}</span>
                          </div>
                          <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                            <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                          </div>

                        </ng-template> </ejs-multiselect>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="article_published_from"
                          placeholder='Published From' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="article_published_till"
                          placeholder='Published Till' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="articleFilterForm.value.published_from">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="article_created_from"
                          placeholder='Created From' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="article_created_till"
                          placeholder='Created Till' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="articleFilterForm.value.created_from">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="article_updated_from"
                          placeholder='Updated From' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-datepicker formControlName="article_updated_till"
                          placeholder='Updated Till' openOnFocus="true" [allowEdit]="allowEdit"
                          floatLabelType='Auto' cssClass="e-filled" [min]="articleFilterForm.value.updated_from">
          </ejs-datepicker>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 w-100">
          <ejs-multiselect id='article_updated_by' formControlName="article_updated_by"
                           #checkbox [dataSource]='users'
                           placeholder='Article Updated By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='article_created_by' formControlName="article_created_by"
                           #checkbox [dataSource]='users'
                           placeholder='Article Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="article_is_published_yn" #wcheckbox label="Show Only Published Articles: "
                      labelPosition="Before"></ejs-checkbox>
      </div>
    </form>

    <form [ngClass]="selectedTab === 'company' ? 'show' : 'hide'" id="formIdCompany" [formGroup]="companyFilterForm"
          #formDir="ngForm" class="form-horizontal" novalidate="">
      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="role_company_name" placeholder="Company Name" cssClass="e-filled"
                     formControlName="company_name" floatLabelType="Auto"
                     (blur)="companyFilterForm.controls.company_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="source_company_reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="company_reference_id" floatLabelType="Auto"
                     (blur)="companyFilterForm.controls.reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="companyFilterForm.controls.company_community_type_code.value == ''">
        <!-- <ejs-dropdowntree formControlName="company_company_type_code" id='source_company_company_type_code'
                          allowMultiSelection='true' [treeSettings]='treeSettings'
                          [fields]='companyTypeField' placeholder='Company Type' floatLabelType="Auto"
                          sortOrder="Ascending"
                          cssClass="e-filled"
                          (blur)="companyFilterForm.controls.company_company_type_code.markAsTouched()"
                          [showCheckBox]='true'></ejs-dropdowntree> -->
        <ejs-multiselect id='source_company_company_type_code-checkbox' formControlName="company_community_type_code" #checkbox
                        [dataSource]='communityTypes'
                        placeholder='Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
                        mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                        filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='account_owner-checkbox' formControlName="company_account_owner" #checkbox
                         [dataSource]='users'
                         placeholder='Account Owner' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'>
                         <ng-template  #itemTemplate="" let-data style="display: inline-block">
                          <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                            <span>{{data.first_name}}</span>
                          </div>
                          <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                            <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                          </div>

                        </ng-template> </ejs-multiselect>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='account_Manager-checkbox' formControlName="company_account_manager" #checkbox
                         [dataSource]='users'
                         placeholder='Sales' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'>
                         <ng-template  #itemTemplate="" let-data style="display: inline-block">
                          <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                            <span>{{data.first_name}}</span>
                          </div>
                          <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                            <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                          </div>

                        </ng-template> </ejs-multiselect>
      </div>




      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='published_by' formControlName="company_published_by" #checkbox [dataSource]='users'
                         placeholder='Published by' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'>
                         <ng-template  #itemTemplate="" let-data style="display: inline-block">
                          <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                            <span>{{data.first_name}}</span>
                          </div>
                          <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                            <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                          </div>

                        </ng-template> </ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='country_code-checkbox' formControlName="company_country_code" #checkbox
                         [dataSource]='countries'
                         placeholder='Company Country' [fields]='countryField' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='is_inactive_yn' formControlName="company_is_inactive_yn" #checkbox [dataSource]='status'
                         placeholder='Active Status' [fields]='statusFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='is_premium_yn' formControlName="company_is_premium_company_yn" #checkbox [dataSource]='premium'
                         placeholder='Company Premium Status' [fields]='premiumFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='company_tag' #company_tags [dataSource]="tagModelData" floatLabelType="Auto"
                         cssClass="e-filled"
                         [fields]="tagFields" formControlName='company_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Company Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event, 'company')"
                         (change)="onChange($event, 'company')"
                         (blur)="onBlur($event, companyFilterForm, 'company_tag')"
                         name="tags"
                         (removed)="selectedTag = []"
                         (select)="select($event)"
                         [(ngModel)]="modelDataCompanyTag"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="companyFilterForm.controls.company_company_role_code.value == ''">
        <ejs-dropdowntree formControlName="company_company_role_code" id='company_company_role_code'
                          allowMultiSelection='true' [showCheckBox]="true"
                          [fields]='companyRoleField' placeholder='Company Role' floatLabelType="Auto"
                          sortOrder="Ascending"
                          cssClass="e-filled"
                          (blur)="companyFilterForm.controls.company_company_role_code.markAsTouched()"
                          [showSelectAll]=true></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="company_updated_at" [allowEdit]="allowEdit"
                             placeholder='Select a Updated At Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-daterangepicker formControlName="company_created_at" [allowEdit]="allowEdit"
                             placeholder='Select a Created At Date Range' openOnFocus="true"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <!-- <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="company_is_published_yn" #wcheckbox label="Show Only Published Companies: "
                      labelPosition="Before"></ejs-checkbox>
      </div> -->

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='is_premium_yn' formControlName="company_is_published_yn" #checkbox [dataSource]='PulishedStatus'
                         placeholder='Is Published'[fields]='PulishedFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

    </form>

    <form *ngIf="currentPage !== 'article'" [ngClass]="selectedTab === 'project' ? 'show' : 'hide'" id="projectformId"
          [formGroup]="projectFilterForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="project_name" placeholder="Project Name" cssClass="e-filled"
                     formControlName="project_name" floatLabelType="Auto"
                     (blur)="projectFilterForm.controls.project_name.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="project_reference_id" placeholder="Reference ID" cssClass="e-filled"
                     formControlName="project_reference_id" floatLabelType="Auto"
                     (blur)="projectFilterForm.controls.project_reference_id.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="projectFilterForm.controls.project_type_code.value == ''">
        <ejs-dropdowntree formControlName="project_type_code" id='project_type_code' allowMultiSelection='true'
                          [fields]='projectTypeFields' placeholder='Project Type' cssClass="e-filled"
                          [treeSettings]='treeSettings'
                          sortOrder="Ascending" (blur)="projectFilterForm.controls.project_type_code.markAsTouched()"
                          floatLabelType="Auto" [showCheckBox]='true'
                          (change)="setDropdownValue($event, projectFilterForm, 'project_type_code')"></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="projectFilterForm.controls.project_status_code.value == ''">
        <ejs-dropdowntree formControlName="project_status_code" id='project_status_codeee' [showCheckBox]='true'
                          [treeSettings]='treeSettings'
                          floatLabelType="Auto" (blur)="projectFilterForm.controls.project_status_code.markAsTouched()"
                          cssClass="e-filled" [fields]='projectStatusFields'
                          placeholder='Project Status'></ejs-dropdowntree>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='assigned_to_user' formControlName="project_assigned_to_user" #checkbox [dataSource]='users'
                         placeholder='Assigned To' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         filterBarPlaceholder='Search'>
                         <ng-template  #itemTemplate="" let-data style="display: inline-block">
                          <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                            <span>{{data.first_name}}</span>
                          </div>
                          <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                            <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                          </div>

                        </ng-template> </ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='country_code' formControlName="project_country_code" #checkbox [dataSource]='countries'
                         placeholder='Project Country' [fields]='countryField' cssClass="e-filled" floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10"
           [class.hide-label]="projectFilterForm.controls.project_project_region_code.value == ''">
        <ejs-dropdowntree formControlName="project_project_region_code" id='project_project_region_code' allowMultiSelection='true'
                          [fields]='regionFields' placeholder='Project Region' cssClass="e-filled"
                          [treeSettings]='treeSettings'
                          sortOrder="Ascending" (blur)="projectFilterForm.controls.project_project_region_code.markAsTouched()"
                          floatLabelType="Auto" [showCheckBox]='true'
                          (change)="setDropdownValue($event, projectFilterForm, 'region_code')"></ejs-dropdowntree>
      </div>

      <div class="form-group sf-outline-input mb-10 mr-2 w-100">
        <ejs-daterangepicker formControlName="project_featured_date" name="featured_date"
                             placeholder='Featured Between' openOnFocus="true" [allowEdit]="allowEdit"
                             floatLabelType='Auto' cssClass="e-filled">
        </ejs-daterangepicker>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="project_proposed_post_date" name="proposed_post_date"
                               placeholder='Published Between' openOnFocus="true" [allowEdit]="allowEdit"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='project_published_by' formControlName="project_published_by" #checkbox
                           [dataSource]='users'
                           placeholder='Published By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'> <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>

      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="project_updated_at" [allowEdit]="allowEdit"
                               placeholder='Updated Between' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='updated_by' formControlName="project_updated_by" #checkbox [dataSource]='users'
                           placeholder='Updated By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'> <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 mr-2 w-100">
          <ejs-daterangepicker formControlName="project_created_at" [allowEdit]="allowEdit"
                               placeholder='Created Between' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10 ml-2 w-100">
          <ejs-multiselect id='created_by' formControlName="project_created_by" #checkbox [dataSource]='users'
                           placeholder='Created By' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>


      </div>
      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='tags' #project_tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"
                         [fields]="tagFields" formControlName='project_tag'
                         [allowCustomValue]=true mode='Box' placeholder='Project Tags'
                         (actionComplete)="actionComplete($event)"
                         (ngModelChange)="syncModel($event, 'project')"
                         (change)="onChange($event, 'project')"
                         (blur)="onBlur($event, projectFilterForm, 'project_tag')"
                         name="tags"
                         (removed)="selectedTag = []"
                         [(ngModel)]="modelDataProjectTag"
                         (select)="select($event)"
                         ngModel></ejs-multiselect>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='company' formControlName="project_company_code" #checkboxCompany [dataSource]='companyList'
                         placeholder='Project Company' [fields]='companyFields' cssClass="e-filled"
                         floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'company')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='contact' formControlName="project_contact_code" #checkboxContact [dataSource]='contactList'
                         placeholder='Project Contact' [fields]='contactFields' cssClass="e-filled"
                         floatLabelType="Auto"
                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                         (filtering)="onFiltering($event, 'contact')"
                         filterBarPlaceholder='Search'></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <!--        <ejs-dropdowntree id='project_source_type_code_filter' formControlName="project_source_type_code"-->
        <!--                          [treeSettings]='treeSettings' [allowMultiSelection]=true-->
        <!--                         placeholder='Source Type' [fields]='sourceTypeFields' floatLabelType="Auto" cssClass="e-filled"-->
        <!--                         showCheckBox="true" [showDropDownIcon]='true' -->
        <!--                         filterBarPlaceholder='Search'></ejs-dropdowntree>-->

        <!--        <ejs-dropdowntree formControlName="project_source_type_code" id='project_source_type_code_filter' [showCheckBox]='true'-->
        <!--                          [treeSettings]='treeSettings'-->
        <!--                          floatLabelType="Auto" (blur)="filterForm.controls.project_source_type_code.markAsTouched()"-->
        <!--                          cssClass="e-filled" [fields]='sourceTypeFields' placeholder='Source Type'></ejs-dropdowntree>-->
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="project_is_deleted_yn" #wcheckbox label="Include Deleted Projects: "
                      labelPosition="Before"></ejs-checkbox>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="currentPage === 'project'">
        <ejs-checkbox formControlName="project_is_source_exist" #wwcheckbox
                      label="Only display projects with no source: " labelPosition="Before"></ejs-checkbox>
      </div>

      <div *ngIf="currentPage === 'company'">

        <div class="separator separator-dashed mt-8 mb-5"></div>
        <h3>Project Role Company</h3>

        <div class="form-group sf-outline-input mb-10 mt-10">
          <ejs-textbox id="project_role_company_name" placeholder="Project Company Name" cssClass="e-filled"
                       formControlName="project_role_company_name" floatLabelType="Auto"
                       (blur)="projectFilterForm.controls.project_role_company_name.markAsTouched()"
          ></ejs-textbox>

        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_role_company_reference_id" placeholder="Project Company Reference ID"
                       cssClass="e-filled"
                       formControlName="project_role_company_reference_id" floatLabelType="Auto"
                       (blur)="projectFilterForm.controls.project_role_company_reference_id.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="projectFilterForm.controls.project_role_company_community_type_code.value == ''">
          <!-- <ejs-dropdowntree formControlName="project_role_company_company_type_code"
                            id='project_role_company_company_type_code' allowMultiSelection='true'
                            [treeSettings]='treeSettings'
                            [fields]='companyTypeField' placeholder='Project Company Type' floatLabelType="Auto"
                            sortOrder="Ascending"
                            cssClass="e-filled"
                            (blur)="projectFilterForm.controls.project_role_company_company_type_code.markAsTouched()"
                            [showCheckBox]='true'></ejs-dropdowntree> -->

                            <ejs-multiselect id='project_role_company_community_type_code-checkbox' formControlName="project_role_company_community_type_code"
                            [dataSource]='communityTypes'
                            placeholder='Project Company Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
                            mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                            filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_role_account_owner-checkbox'
                           formControlName="project_role_company_account_owner" #checkbox [dataSource]='users'
                           placeholder='Project Company Assigned To' [fields]='userFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_role_published_by' formControlName="project_role_company_published_by"
                           #checkbox [dataSource]='users'
                           placeholder='Project Company Published by' [fields]='userFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'>
                           <ng-template  #itemTemplate="" let-data style="display: inline-block">
                            <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                              <span>{{data.first_name}}</span>
                            </div>
                            <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                              <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                            </div>

                          </ng-template> </ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_role_country_code-checkbox' formControlName="project_role_company_country_code"
                           #checkbox [dataSource]='countries'
                           placeholder='Project Company Country' [fields]='countryField' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_role_is_inactive_yn' formControlName="project_role_company_is_inactive_yn"
                           #checkbox [dataSource]='status'
                           placeholder='Project Company Active Status' [fields]='statusFields' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>


        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="projectFilterForm.controls.project_role_company_company_role_code.value == ''">
          <ejs-dropdowntree formControlName="project_role_company_company_role_code"
                            id='project_role_company_company_role_code' allowMultiSelection='true' [showCheckBox]="true"
                            [fields]='companyRoleField' placeholder='Project Company Role' floatLabelType="Auto"
                            sortOrder="Ascending"
                            cssClass="e-filled"
                            (blur)="projectFilterForm.controls.project_role_company_company_role_code.markAsTouched()"
                            [showSelectAll]=true></ejs-dropdowntree>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_role_company_updated_at" [allowEdit]="allowEdit"
                               placeholder='Select a Updated At Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_role_company_created_at" [allowEdit]="allowEdit"
                               placeholder='Select a Created At Date Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>


        <div class="separator separator-dashed mt-8 mb-5"></div>
        <h3>Project Role Contact</h3>

        <div class="form-group sf-outline-input mb-10 mt-10">
          <ejs-textbox id="project_role_contact_name" placeholder="Project Contact Name" cssClass="e-filled"
                       formControlName="project_role_contact_name" floatLabelType="Auto"
                       (blur)="projectFilterForm.controls.project_role_contact_name.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_role_reference_id" placeholder="Project Contact Reference ID" cssClass="e-filled"
                       formControlName="project_role_contact_reference_id" floatLabelType="Auto"
                       (blur)="projectFilterForm.controls.project_role_contact_reference_id.markAsTouched()"
          ></ejs-textbox>
        </div>


        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="role_company_namee" placeholder="Project Company Name" cssClass="e-filled"
                       formControlName="project_role_contact_company_name" floatLabelType="Auto"
                       (blur)="projectFilterForm.controls.project_role_contact_company_name.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox id="project_role_email" placeholder="Project Contact Email" cssClass="e-filled"
                       formControlName="project_role_contact_email" floatLabelType="Auto"
                       (blur)="projectFilterForm.controls.project_role_contact_email.markAsTouched()"
          ></ejs-textbox>
        </div>

        <div class="form-group sf-outline-input mb-10"
             [class.hide-label]="projectFilterForm.controls.project_role_contact_community_type_code.value == ''">
             <ejs-multiselect id='project_role_contact_community_type_code-checkbox' formControlName="project_role_contact_community_type_code"
             [dataSource]='communityTypes'
             placeholder='Project Contact Community Type' [fields]='communityTypeField' floatLabelType="Auto" cssClass="e-filled"
             mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"
             (change)="setCompanyType($event, projectFilterForm)"
             filterBarPlaceholder='Search'></ejs-multiselect>
          <!-- <ejs-dropdowntree formControlName="project_role_contact_company_type_code"
                            id='project_role_contact_company_type_code'
                            allowMultiSelection='true'
                            [fields]='companyTypeField' placeholder='Project Contact Company Type' cssClass="e-filled"
                            sortOrder="Ascending"
                            (blur)="projectFilterForm.controls.project_role_contact_company_type_code.markAsTouched()"
                            floatLabelType="Auto"
                            (change)="setCompanyType($event, projectFilterForm)"
                            [treeSettings]='treeSettings' [showCheckBox]='true'></ejs-dropdowntree> -->
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-multiselect id='project_role_multiselect-checkbox' formControlName="project_role_contact_country_code"
                           [dataSource]='countries'
                           placeholder='Project Contact Country' [fields]='countryField' floatLabelType="Auto"
                           cssClass="e-filled"
                           mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true'
                           filterBarPlaceholder='Search'></ejs-multiselect>
        </div>

        <!--      <div class="form-group sf-outline-input mb-10">-->
        <!--        <ejs-multiselect id='published_by' formControlName="contact_published_by" #checkbox [dataSource]='users'-->
        <!--                         placeholder='Published by' [fields]='userFields' floatLabelType="Auto" cssClass="e-filled"-->
        <!--                         mode='CheckBox' [showDropDownIcon]='true' showSelectAll='true' sortOrder="Ascending"-->
        <!--                         filterBarPlaceholder='Search'></ejs-multiselect>-->
        <!--      </div>-->


        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_role_contact_updated_at" [allowEdit]="allowEdit"
                               placeholder='Select a Updated At Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-daterangepicker formControlName="project_role_contact_created_at" [allowEdit]="allowEdit"
                               placeholder='Select a Created At Date Range' openOnFocus="true"
                               floatLabelType='Auto' cssClass="e-filled">
          </ejs-daterangepicker>
        </div>
      </div>


    </form>

    <form *ngIf="currentPage === 'article'" [ngClass]="selectedTab === 'article-date' ? 'show' : 'hide'" [formGroup]="articleDateForm" #formDir="ngForm"
          class="form-horizontal" novalidate="">

      <div class="roww mxx-2">

        <div *ngFor="let articleDate of articleDates">
          <div class="d-flex" *ngIf="addDateToFilter(articleDate.article_date_code)">
            <div class="form-group sf-outline-input mb-10 mr-2 w-100">
              <ejs-datepicker [formControlName]="strReplace(articleDate.article_date_code) + '_from'" openOnFocus="true" [allowEdit]=false
                              [placeholder]='articleDate.article_date_name + " From"' floatLabelType='Auto'
                              cssClass="e-filled">
              </ejs-datepicker>
            </div>

            <div class="form-group sf-outline-input mb-10 mr-2 w-100">
              <ejs-datepicker [formControlName]="strReplace(articleDate.article_date_code) + '_to'" [allowEdit]="allowEdit"
                              [placeholder]="articleDate.article_date_name+' To'" openOnFocus="true" [firstDayOfWeek]="startWeek"
                              [min]="articleDateForm.value.client_content_receipt_deadline_from"
                              floatLabelType='Auto' cssClass="e-filled">
              </ejs-datepicker>
            </div>

            <div class="form-group sf-outline-input mr-2 w-100">
              <ejs-multiselect  [dataSource]='users' [id]="strReplace(articleDate.article_date_code) + '_date_owner'"
                               placeholder='Article Date Owner' (change)="onSelectDateOwner(strReplace(articleDate.article_date_code) + '_date_owner', $event)"
                               [fields]='userFields' floatLabelType="Auto" cssClass="e-filled" mode='CheckBox'
                               [showDropDownIcon]='true'
                               showSelectAll='true' filterBarPlaceholder='Search' #owner [value]="value" >
                               <!-- [value]="setvalue(strReplace(articleDate.article_date_code) + '_date_owner',value)" -->
                               <ng-template  #itemTemplate="" let-data style="display: inline-block">
                                <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                                  <span>{{data.first_name}}</span>
                                </div>
                                <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                                  <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                                </div>

                              </ng-template> </ejs-multiselect>
            </div>

            <div class="form-group sf-outline-input mb-10 w-75">
              <ejs-dropdownlist [formControlName]="'is_tentative_' + strReplace(articleDate.article_date_code)"
                                [dataSource]='tentativeOptions' [showClearButton]=true
                                floatLabelType="Auto"
                                [fields]='tentativeFields' cssClass="e-filled"
                                placeholder='Is Tentative'>
              </ejs-dropdownlist>
            </div>

            <div class="form-group sf-outline-input mb-10 align-self-center" title="Completed">
              <div class="d-flex">
                <div class="mt-1">
                  <ejs-checkbox
                    [formControlName]="'is_completed_' + strReplace(articleDate.article_date_code)"
                    label="" labelPosition="Before"></ejs-checkbox>
                </div>
              </div>

            </div>


          </div>
        </div>


      </div>


    </form>
    <div class="my-10 pb-20">
      <button id="resetbtnn"
              class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
              type="button" (click)="clear()"
              data-ripple="true">Clear All
      </button>
      <button id="filterr"
              class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
              type="submit"
              (click)="getFilteredData()" [disabled]="formSent" data-ripple="true">Filter
      </button>
    </div>

  </div>
</ejs-sidebar>
<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
