import { Component, OnInit,ViewChild } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
@Component({
  selector: 'app-community-product-status-edit',
  templateUrl: './community-product-status-edit.component.html',
  styleUrls: ['./community-product-status-edit.component.scss']
})
export class CommunityProductStatusEditComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
 
}
