<ejs-sidebar #sidebarInstance id="sidebar-menu" style="visibility: hidden" type='Over' [width]='width' [position]='position'
             [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close" (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2>Update Selected Attendees Details</h2>

    <h4 class="mt-8" *ngIf="selectedContactList && selectedContactList.length > 0"><b>Contacts:</b></h4>
    <ul class="pl-5 mb-5">
      <li *ngFor="let attendee of selectedContactList">
        <b class="pt-2">
          {{ attendee.contact.first_name || ''}} {{ attendee.contact.last_name || ''}}
          <span *ngIf="(attendee.contact && (attendee.contact.first_name || attendee.contact.last_name)) && (attendee.company && attendee.company.company_name)">-</span>
          {{ (attendee.company && attendee.company.company_name) ? (attendee.company.company_name || '') : '' }}
        </b>
      </li>
    </ul>

    <form id="formId" [formGroup]="eventAttendeeForm" #formDir="ngForm" class="form-horizontal mt-12" novalidate="" style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="eventComments" cssClass="e-filled" placeholder="Comments" formControlName="comment" floatLabelType="Auto"
                     [multiline]="true" (blur)="eventAttendeeForm.controls.comment.markAsTouched()"></ejs-textbox>
        <div *ngIf="eventAttendeeForm.controls.comment.errors">
          <div *ngIf="eventAttendeeForm.controls.address.errors.maxlength && eventAttendeeForm.controls.address.touched" class="e-error">
            Comment cannot be more than 1000 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox #checkbox formControlName="has_attended" label="Attended Event" labelPosition="After"></ejs-checkbox>
      </div>

      <div *ngIf="eventAttendeeForm.invalid" class="e-error">Please fill all fields</div>
      <div *ngIf="error$ | async as error" class="e-error">{{error.error}}</div>

      <div class="my-10 pb-20">
        <button id="resetbtn" class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5" type="button" data-ripple="true"
                (click)="clear()">
          Clear
        </button>
        <button  id="addSubmit" class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit" data-ripple="true"
                 (click)="updateEventAttendees(eventAttendeeForm)" [disabled]="formSent || eventAttendeeForm.invalid">
          Update Selected Attendees
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
