import { Tags } from '../../models/tags.model';
import { CommonActions, CommonActionTypes } from '../actions/common.actions';
import { User } from '../../models/user.model';
import { Country } from '../../models/country.model';
import { DeliverableItem } from '../../models/deliverable-item.model';
import { OrderItem } from '../../models/order-item.model';
import { CostCenter } from '../../models/cost-center.model';
import { DeliverableStatus } from '../../models/deliverable-status.model';
import { SubscriptionStage } from '../../models/subscription-stage.model';
import { Currency } from '../../models/currency.model';
import { ProjectSourceStatus } from '../../models/project-source-status.model';
import { find, findAndDelete, findAndReplace } from '../../../views/common/base.component';
import { ArticleCategory } from '../../models/article-category.model';
import { EmailTemplate } from '../../models/email-template.model';
import { EmailLog } from '../../models/email-log.model';
import { DeliverableItemDate } from '../../models/admin/deliverable-item-date.model';
import * as _ from 'lodash';

export interface CommonState {
  tags?: Tags[];
  error?: Error;
  success?: boolean;
  loading: boolean;
  loaded: boolean;
  users?: User[];
  countries?: Country[];
  projectSourceStatus?: ProjectSourceStatus[];
  deliverableItems?: {
    result: DeliverableItem[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  lineItems?: {
    result: OrderItem[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  costCenter?: {
    result: CostCenter[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  deliverableStatus?: {
    result: DeliverableStatus[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  subscriptionStage?: {
    result: SubscriptionStage[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  currency?: {
    result: Currency[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  articleCategory?: {
    result: ArticleCategory[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  emailTemplate?: {
    result: EmailTemplate[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  emailLogs?: {
    result: EmailLog[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  deliverableItemDate?: {
    result: DeliverableItemDate[]
  };
  socialMediaPlatforms?: any[];
  regions?: any[];
  orderStatus?: any[];
  communityService?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  communityType?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  productType?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  articleStage?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  articleType?: {
    result: any[],
  };
  edition?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  articleDates?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  dates?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
}

export const initialState: CommonState = {
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: CommonState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: undefined,
  };
};

export function commonReducer(state: CommonState = initialState, action: CommonActions): CommonState {
  switch (action.type) {

    case CommonActionTypes.LOAD_TAGS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_TAGS_SUCCESS: {
      return { ...state, tags: action.payload, loading: false, error: undefined, loaded: true };
    }

    case CommonActionTypes.LOAD_TAGS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_USER: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_USER_SUCCESS: {

var disabledUserArray=[]
var notDisabletUserArray=[]

for(const elem of action.payload){
  if(elem.is_disable_yn==null){
    notDisabletUserArray.push(elem)
  }
  if(elem.is_disable_yn==1){
    disabledUserArray.push(elem)
  }
}
for(const elem of disabledUserArray){
  notDisabletUserArray.push(elem)
}



      return { ...state, users: notDisabletUserArray, loading: false, error: undefined, loaded: true };
    }

    case CommonActionTypes.LOAD_USER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_COUNTRY: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_COUNTRY_SUCCESS: {
      return { ...state, countries: action.payload, loading: false, error: undefined, loaded: true };
    }

    case CommonActionTypes.LOAD_COUNTRY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_DELIVERABLE_ITEMS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_DELIVERABLE_ITEMS_SUCCESS: {
      return {
        ...state,
        deliverableItems: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_DELIVERABLE_ITEMS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_DELIVERABLE_ITEM: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_DELIVERABLE_ITEMS_SUCCESS: {

      let newDeliverableItem;
      if (action.payload.deliverable_item_parent_code) {
        const parentDeliverableItem = { ...find([...state.deliverableItems.result], action.payload.deliverable_item_parent_code, 'deliverable_item_code') };

        const childrenArray = [...parentDeliverableItem.children];
        childrenArray.push(action.payload.deliverableItem);
        parentDeliverableItem.children = childrenArray;
        const findParent = findAndReplace([...state.deliverableItems.result], action.payload.deliverable_item_parent_code, parentDeliverableItem, 'deliverable_item_code');
        newDeliverableItem = findParent;
      } else {
        newDeliverableItem = [action.payload.deliverableItem, ...state.deliverableItems.result];
      }

      return {
        ...state,
        deliverableItems: { result: newDeliverableItem },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_DELIVERABLE_ITEMS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_ITEMS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_ITEMS_SUCCESS: {
      const deliverableItemsList = findAndDelete([...state.deliverableItems.result], action.payload.deliverableItem.deliverable_item_code, 'deliverable_item_code');
      let newDeliverableItem;
      if (action.payload.deliverable_item_parent_code) {
        const parentDeliverableItem = { ...find(deliverableItemsList, action.payload.deliverable_item_parent_code, 'deliverable_item_code') };
        const childrenArray = [...parentDeliverableItem.children];
        childrenArray.push(action.payload.deliverableItem);
        parentDeliverableItem.children = childrenArray;
        const findParent = findAndReplace(deliverableItemsList, action.payload.deliverable_item_parent_code, parentDeliverableItem, 'deliverable_item_code');
        newDeliverableItem = findParent;
      } else {
        newDeliverableItem = [action.payload.deliverableItem, ...deliverableItemsList];
      }


      return {
        ...state, deliverableItems: {
          result: newDeliverableItem, page_no: state.deliverableItems.page_no, page_size: state.deliverableItems.page_size,
          total_count: state.deliverableItems.total_count
        }, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_ITEMS_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.DELETE_DELIVERABLE_ITEM:
      return ReturnLoading(state);

    case CommonActionTypes.DELETE_DELIVERABLE_ITEMS_SUCCESS: {
      return {
        ...state,
        deliverableItems: { result: state.deliverableItems.result.filter(item => item.deliverable_item_code !== action.payload.deliverable_item_code) },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.DELETE_DELIVERABLE_ITEMS_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case CommonActionTypes.LOAD_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_LINE_ITEM_SUCCESS: {
      return {
        ...state,
        lineItems: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_LINE_ITEM_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_LINE_ITEM_SUCCESS: {
      let newOrderItem;
      if (action.payload.order_item_parent_code) {
        const parentOrderItem = { ...find([...state.lineItems.result], action.payload.order_item_parent_code, 'order_item_code') };
        const childrenArray = [...parentOrderItem.children];
        childrenArray.push(action.payload.lineItem);
        parentOrderItem.children = childrenArray;
        const findParent = findAndReplace([...state.lineItems.result], action.payload.order_item_parent_code, parentOrderItem, 'order_item_code');
        newOrderItem = findParent;
      } else {
        newOrderItem = [action.payload.lineItem, ...state.lineItems.result];
      }
      return {
        ...state,
        lineItems: { result: newOrderItem },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_LINE_ITEM_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_LINE_ITEM_SUCCESS: {
      const lineItemIndex = state.lineItems.result.findIndex(x => x.order_item_code === action.payload.lineItem.order_item_code);
      const lineItem = state.lineItems[lineItemIndex];
      const updatedLineItem = { ...lineItem, ...action.payload.lineItem };
      const updatedLineItems = [...state.lineItems.result];

      const index = updatedLineItems.findIndex(x => x.order_item_code === action.payload.lineItem.order_item_code);
      updatedLineItems[index] = updatedLineItem;

      const lineItemsResult = { ...state.lineItems };
      lineItemsResult.result = updatedLineItems;




      const lineItemsList = findAndDelete([...state.lineItems.result], action.payload.lineItem.order_item_code, 'order_item_code');
      let newLineItems;
      if (action.payload.order_item_parent_code) {
        const parentLineItems = { ...find(lineItemsList, action.payload.order_item_parent_code, 'order_item_code') };
        const childrenArray = [...parentLineItems.children];
        childrenArray.push(action.payload.lineItem);
        parentLineItems.children = childrenArray;
        const findParent = findAndReplace(lineItemsList, action.payload.order_item_parent_code, parentLineItems, 'order_item_code');
        newLineItems = findParent;
      } else {
        newLineItems = [action.payload.lineItem, ...lineItemsList];
      }
      return {
        ...state,
        lineItems: {
          result: newLineItems,
          page_no: state.lineItems.page_no,
          page_size: state.lineItems.page_size,
          total_count: state.lineItems.total_count
        },
        error: undefined,
        loading: false,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_LINE_ITEM_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.DELETE_LINE_ITEM:
      return ReturnLoading(state);

    case CommonActionTypes.DELETE_LINE_ITEM_SUCCESS: {
      const orderItemsList = findAndDelete([...state.lineItems.result], action.payload.line_item_code, 'order_item_code');

      return {
        ...state,
        lineItems: { result: orderItemsList },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.DELETE_LINE_ITEM_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.LOAD_DELIVERABLE_STATUS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_DELIVERABLE_STATUS_SUCCESS: {
      return {
        ...state,
        deliverableStatus: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_DELIVERABLE_STATUS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_DELIVERABLE_STATUS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_DELIVERABLE_STATUS_SUCCESS: {
      let newDeliverableStatus;
      if (action.payload.deliverable_status_parent_code) {
        const parentDeliverableStatus = { ...find([...state.deliverableStatus.result], action.payload.deliverable_status_parent_code, 'deliverable_status_code') };
        const childrenArray = [...parentDeliverableStatus.children];
        childrenArray.push(action.payload.deliverableStatus);
        parentDeliverableStatus.children = childrenArray;
        const findParent = findAndReplace([...state.deliverableStatus.result], action.payload.deliverable_status_parent_code, parentDeliverableStatus, 'deliverable_status_code');
        newDeliverableStatus = findParent;
      } else {
        newDeliverableStatus = [action.payload.deliverableStatus, ...state.deliverableStatus.result];
      }
      return {
        ...state,
        deliverableStatus: { result: newDeliverableStatus },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_DELIVERABLE_STATUS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_STATUS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_STATUS_SUCCESS: {
      const deliverableStatusList = findAndDelete([...state.deliverableStatus.result], action.payload.deliverableStatus.deliverable_status_code, 'deliverable_status_code');
      let newDeliverableStatus;
      if (action.payload.deliverable_status_parent_code) {
        const parentDeliverableStatus = { ...find(deliverableStatusList, action.payload.deliverable_status_parent_code, 'deliverable_status_code') };
        const childrenArray = [...parentDeliverableStatus.children];
        childrenArray.push(action.payload.deliverableStatus);
        parentDeliverableStatus.children = childrenArray;
        const findParent = findAndReplace(deliverableStatusList, action.payload.deliverable_status_parent_code, parentDeliverableStatus, 'deliverable_status_code');
        newDeliverableStatus = findParent;
      } else {
        newDeliverableStatus = [action.payload.deliverableStatus, ...deliverableStatusList];
      }
      return {
        ...state,
        deliverableStatus: {
          result: newDeliverableStatus,
          page_no: state.deliverableStatus.page_no,
          page_size: state.deliverableStatus.page_size,
          total_count: state.deliverableStatus.total_count
        },
        error: undefined,
        loading: false,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_STATUS_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.DELETE_DELIVERABLE_STATUS:
      return ReturnLoading(state);

    case CommonActionTypes.DELETE_DELIVERABLE_STATUS_SUCCESS: {
      return {
        ...state,
        deliverableStatus: { result: state.deliverableStatus.result.filter(item => item.deliverable_status_code !== action.payload.deliverable_status_code) },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.DELETE_DELIVERABLE_STATUS_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.LOAD_COST_CENTER: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_COST_CENTER_SUCCESS: {
      return {
        ...state,
        costCenter: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_COST_CENTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_COST_CENTER: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_COST_CENTER_SUCCESS: {
      return {
        ...state,
        costCenter: { result: [action.payload.costCenter, ...state.costCenter.result] },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_COST_CENTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_COST_CENTER: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_COST_CENTER_SUCCESS: {
      const costCenterIndex = state.costCenter.result.findIndex(x => x.cost_center_code === action.payload.costCenter.cost_center_code);
      const costCenter = state.costCenter[costCenterIndex];
      const updatedCostCenter = { ...costCenter, ...action.payload.costCenter };
      const updatedCostCenters = [...state.costCenter.result];

      const index = updatedCostCenters.findIndex(x => x.cost_center_code === action.payload.costCenter.cost_center_code);
      updatedCostCenters[index] = updatedCostCenter;

      const costCenterResult = { ...state.costCenter };
      costCenterResult.result = updatedCostCenters;
      return {
        ...state, costCenter: costCenterResult, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_COST_CENTER_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.DELETE_COST_CENTER:
      return ReturnLoading(state);

    case CommonActionTypes.DELETE_COST_CENTER_SUCCESS: {
      return {
        ...state,
        costCenter: { result: state.costCenter.result.filter(item => item.cost_center_code !== action.payload.cost_center_code) },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.DELETE_COST_CENTER_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.LOAD_SUBSCRIPTION_STAGE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_SUBSCRIPTION_STAGE_SUCCESS: {
      return {
        ...state,
        subscriptionStage: {
          result: action.payload.data,
          total_count: action.payload.data ? action.payload.data.length : 0
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_SUBSCRIPTION_STAGE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_CURRENCY: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_CURRENCY_SUCCESS: {
      return {
        ...state,
        currency: {
          result: action.payload.data,
          total_count: action.payload.data ? action.payload.data.length : 0
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_CURRENCY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS_SUCCESS: {
      return { ...state, projectSourceStatus: action.payload, loading: false, error: undefined, loaded: true };
    }

    case CommonActionTypes.LOAD_PROJECT_SOURCE_STATUS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_ARTICLE_CATEGORY: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_ARTICLE_CATEGORY_SUCCESS: {
      return {
        ...state,
        articleCategory: {
          result: action.payload.data.result,
          total_count: action.payload.data.total_count,
          page_no: action.payload.data.page_no,
          page_size: action.payload.data.page_size
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_ARTICLE_CATEGORY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_EMAIL_TEMPLATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        emailTemplate: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true,
      };
    }

    case CommonActionTypes.LOAD_EMAIL_TEMPLATE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_EMAIL_TEMPLATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_EMAIL_TEMPLATE_SUCCESS: {
      let newEmailTemplate;
      if (action.payload.email_template_parent_code) {
        const parentEmailTemplate = { ...find([...state.emailTemplate.result], action.payload.email_template_parent_code, 'email_template_code') };
        const childrenArray = [...parentEmailTemplate.children];
        childrenArray.push(action.payload.emailTemplate);
        parentEmailTemplate.children = childrenArray;
        const findParent = findAndReplace([...state.emailTemplate.result], action.payload.email_template_parent_code, parentEmailTemplate, 'email_template_code');
        newEmailTemplate = findParent;
      } else {
        newEmailTemplate = [action.payload.emailTemplate, ...state.emailTemplate.result];
      }
      return {
        ...state,
        emailTemplate: { result: newEmailTemplate },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_EMAIL_TEMPLATE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_EMAIL_TEMPLATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      const emailTemplateList = findAndDelete([...state.emailTemplate.result], action.payload.emailTemplate.email_template_code, 'email_template_code');
      let newEmailTemplate;
      if (action.payload.parent_email_template_code) {
        const parentEmailTemplate = { ...find(emailTemplateList, action.payload.parent_email_template_code, 'email_template_code') };
        const childrenArray = [...parentEmailTemplate.children];
        childrenArray.push(action.payload.emailTemplate);
        parentEmailTemplate.children = childrenArray;
        const findParent = findAndReplace(emailTemplateList, action.payload.parent_email_template_code, parentEmailTemplate, 'email_template_code');
        newEmailTemplate = findParent;
      } else {
        newEmailTemplate = [action.payload.emailTemplate, ...emailTemplateList];
      }
      return {
        ...state,
        emailTemplate: {
          result: newEmailTemplate,
          page_no: state.emailTemplate.page_no,
          page_size: state.emailTemplate.page_size,
          total_count: state.emailTemplate.total_count
        },
        error: undefined,
        loading: false,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_EMAIL_TEMPLATE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.DELETE_EMAIL_TEMPLATE:
      return ReturnLoading(state);

    case CommonActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS: {
      const emailTemplateList = findAndDelete([...state.emailTemplate.result], action.payload.email_template_code, 'email_template_code');

      return {
        ...state,
        emailTemplate: { result: emailTemplateList },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.DELETE_EMAIL_TEMPLATE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case CommonActionTypes.LOAD_EMAIL_LOG: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_EMAIL_LOG_SUCCESS: {
      return {
        ...state,
        emailLogs: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.LOAD_EMAIL_LOG_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.FILTER_EMAIL_LOG: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.FILTER_EMAIL_LOG_SUCCESS: {
      return {
        ...state,
        emailLogs: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.FILTER_EMAIL_LOG_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.RESET_ERROR:
      return ReturnLoading(state);

    case CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE_SUCCESS: {
      return {
        ...state,
        deliverableItemDate: {
          result: action.payload.result
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_DELIVERABLE_ITEM_DATE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE_SUCCESS: {
      let array = [action.payload.deliverableItemDate, ...state.deliverableItemDate.result];
      array = _.orderBy(array, 'rank_order', 'asc');
      return {
        ...state,
        deliverableItemDate: { result: array },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_DELIVERABLE_ITEM_DATE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE_SUCCESS: {
      const deliverableItemDateIndex = state.deliverableItemDate.result.findIndex(x => x.deliverable_item_date_code === action.payload.deliverableItemDate.deliverable_item_date_code);
      const deliverableItemDate = state.deliverableItemDate[deliverableItemDateIndex];
      const updatedDeliverableItemDate = { ...deliverableItemDate, ...action.payload.deliverableItemDate };
      const updatedDeliverableItemDates = [...state.deliverableItemDate.result];

      const index = updatedDeliverableItemDates.findIndex(x => x.deliverable_item_date_code === action.payload.deliverableItemDate.deliverable_item_date_code);
      updatedDeliverableItemDates[index] = updatedDeliverableItemDate;


      const deliverableItemDateResult = { ...state.deliverableItemDate };
      deliverableItemDateResult.result = _.orderBy(updatedDeliverableItemDates, 'rank_order', 'asc');
      return {
        ...state, deliverableItemDate: deliverableItemDateResult, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_DELIVERABLE_ITEM_DATE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM_SUCCESS: {
      return {
        ...state,
        socialMediaPlatforms: action.payload.result,
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_SOCIAL_MEDIA_PLATFORM_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_REGION: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_REGION_SUCCESS: {
      return { ...state, regions: action.payload, loading: false, error: undefined, loaded: true };
    }

    case CommonActionTypes.LOAD_REGION_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_ORDER_STATUS: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_ORDER_STATUS_SUCCESS: {
      return { ...state, orderStatus: action.payload, loading: false, error: undefined, loaded: true };
    }

    case CommonActionTypes.LOAD_ORDER_STATUS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_COMMUNITY_SERVICE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_COMMUNITY_SERVICE_SUCCESS: {
      return {
        ...state,
        communityService: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_COMMUNITY_SERVICE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_PRODUCT_TYPE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        productType: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_PRODUCT_TYPE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_COMMUNITY_TYPE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_COMMUNITY_TYPE_SUCCESS: {
      return {
        ...state,
        communityType: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_COMMUNITY_TYPE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_ARTICLE_STAGE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_ARTICLE_STAGE_SUCCESS: {
      return {
        ...state,
        articleStage: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_ARTICLE_STAGE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_ARTICLE_STAGE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_ARTICLE_STAGE_SUCCESS: {
      let newArticleStage;
      if (action.payload.article_stage_parent_code) {
        const parentArticleStage = { ...find([...state.articleStage.result], action.payload.article_stage_parent_code, 'article_stage_code') };
        const childrenArray = [...parentArticleStage.children];
        childrenArray.push(action.payload.articleStage);
        parentArticleStage.children = childrenArray;
        const findParent = findAndReplace([...state.articleStage.result], action.payload.article_stage_parent_code, parentArticleStage, 'article_stage_code');
        newArticleStage = findParent;
      } else {
        newArticleStage = [action.payload.articleStage, ...state.articleStage.result];
      }
      let array = [action.payload.articleStage, ...state.articleStage.result];
      array = _.orderBy(array, 'rank_order', 'asc');
      return {
        ...state,
        articleStage: { result: newArticleStage },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_ARTICLE_STAGE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_ARTICLE_STAGE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_ARTICLE_STAGE_SUCCESS: {
      const articleStageList = findAndDelete([...state.articleStage.result], action.payload.articleStage.article_stage_code, 'article_stage_code');
      let newArticleStage;
      if (action.payload.article_stage_parent_code) {

        const parentArticleStage = { ...find(articleStageList, action.payload.article_stage_parent_code, 'article_stage_code') };
        const childrenArray = [...parentArticleStage.children];
        childrenArray.push(action.payload.articleStage);
        parentArticleStage.children = childrenArray;
        const findParent = findAndReplace(articleStageList, action.payload.article_stage_parent_code, parentArticleStage, 'article_stage_code');
        newArticleStage = findParent;
      } else {
        newArticleStage = [action.payload.articleStage, ...articleStageList];
      }
      return {
        ...state, articleStage: { result: newArticleStage }, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_ARTICLE_STAGE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };


    case CommonActionTypes.LOAD_ARTICLE_TYPE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_ARTICLE_TYPE_SUCCESS: {
      return {
        ...state,
        articleType: {
          result: action.payload.result
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_ARTICLE_TYPE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_ARTICLE_TYPE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_ARTICLE_TYPE_SUCCESS: {
      const articleTypeIndex = state.articleType.result.findIndex(x => x.article_type_code === action.payload.articleType.article_type_code);
      const articleType = state.articleType[articleTypeIndex];
      const updatedArticleType = { ...articleType, ...action.payload.articleType };
      const updatedArticleTypes = [...state.articleType.result];

      const index = updatedArticleTypes.findIndex(x => x.article_type_code === action.payload.articleType.article_type_code);
      updatedArticleTypes[index] = updatedArticleType;


      const articleTypeResult = { ...state.articleType };
      articleTypeResult.result = _.orderBy(updatedArticleTypes, 'rank_order', 'asc');
      return {
        ...state, articleType: articleTypeResult, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_ARTICLE_TYPE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case CommonActionTypes.LOAD_EDITION: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_EDITION_SUCCESS: {
      return {
        ...state,
        edition: {
          result: action.payload.result,
          total_count: action.payload.total_count,
          page_size: action.payload.page_size,
          page_no: action.payload.page_no
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_EDITION_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.LOAD_ARTICLE_DATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_ARTICLE_DATE_SUCCESS: {
      return {
        ...state,
        articleDates: {
          result: action.payload.result
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_ARTICLE_DATE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.ADD_ARTICLE_DATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.ADD_ARTICLE_DATE_SUCCESS: {
      let array = [action.payload.articleDate, ...state.articleDates.result];
      array = _.orderBy(array, 'rank_order', 'asc');
      return {
        ...state,
        articleDates: { result: array },
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case CommonActionTypes.ADD_ARTICLE_DATE_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case CommonActionTypes.UPDATE_ARTICLE_DATE: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.UPDATE_ARTICLE_DATE_SUCCESS: {
      const articleDateIndex = state.articleDates.result.findIndex(x => x.article_date_code === action.payload.articleDate.article_date_code);
      const articleDate = state.articleDates[articleDateIndex];
      const updatedArticleDate = { ...articleDate, ...action.payload.articleDate };
      const updatedArticleDates = [...state.articleDates.result];

      const index = updatedArticleDates.findIndex(x => x.article_date_code === action.payload.articleDate.article_date_code);
      updatedArticleDates[index] = updatedArticleDate;


      const articleDateResult = { ...state.articleDates };
      articleDateResult.result = _.orderBy(updatedArticleDates, 'rank_order', 'asc');
      return {
        ...state, articleDates: articleDateResult, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case CommonActionTypes.UPDATE_ARTICLE_DATE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case CommonActionTypes.LOAD_DATES: {
      return ReturnLoading(state);
    }

    case CommonActionTypes.LOAD_DATES_SUCCESS: {
      return {
        ...state,
        dates: {
          result: action.payload.result
        },
        loading: false,
        error: undefined,
        loaded: true
      };
    }

    case CommonActionTypes.LOAD_DATES_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }


    default:
      return state;
  }
}


