<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
  style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true
  [ngClass]="{'long-sidebar': type === 'ltd-settings'}">

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
      (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">

    <h2 *ngIf="type === 'ltd-settings'">Article - LoveThatDesign</h2>
    <h2 *ngIf="type === 'images'">Article - Images</h2>


    <form id="formId" [formGroup]="articleLTDForm" #formDir="ngForm" class="form-horizontal" novalidate=""
      style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <div class="form-group sf-outline-input mb-10">
          <ejs-dropdownlist formControlName="subArticle" floatLabelType="Auto" cssClass="e-filled"
            [value]="selectedItem" [dataSource]='articleList' [fields]='articleFields' placeholder='Parent Article'
            [showClearButton]=true allowFiltering="true" (filtering)="onFiltering($event)">
          </ejs-dropdownlist>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-dropdownlist id='visibilityDropdown' #visibility formControlName="visibility" floatLabelType="Auto"
            cssClass="e-filled" [value]="selectedItem" [dataSource]='visibilityList' [fields]='visibilityFields'
            placeholder='Visibility *' (created)="setDefaultValue()"
            (change)="changePasswordValidation(articleLTDForm, $event)">
          </ejs-dropdownlist>
          <div *ngIf="articleLTDForm.controls.visibility.errors">
            <div
              *ngIf="articleLTDForm.controls.visibility.errors.required && articleLTDForm.controls.visibility.touched"
              class="e-error">
              Visibility is required.
            </div>
          </div>
        </div>

        <div *ngIf="displayPasswordInput" class="mt-4 mb-10">
          <ejs-textbox placeholder="Password *" cssClass="e-filled" formControlName="password_protected_password"
            floatLabelType="Auto" (blur)="articleLTDForm.controls.password_protected_password.markAsTouched()">
          </ejs-textbox>
          <div *ngIf="articleLTDForm.controls.password_protected_password.errors">
            <div
              *ngIf="articleLTDForm.controls.password_protected_password.errors.required && articleLTDForm.controls.password_protected_password.touched"
              class="e-error">
              Password is required.
            </div>
            <div
              *ngIf="articleLTDForm.controls.password_protected_password.errors.maxlength && articleLTDForm.controls.password_protected_password.touched"
              class="e-error">
              Password cannot be more than 255 characters
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mt-5 mb-10" *ngIf="!isArticleType">
          <ejs-dropdownlist formControlName="country" floatLabelType="Auto" [allowFiltering]=true
                            cssClass="e-filled"  [dataSource]='countryList' [fields]='countryFields'
                            placeholder='Country'
                            >
          </ejs-dropdownlist>
        </div>

        <div class="form-group sf-outline-input mb-10">
          <ejs-textbox placeholder="Youtube/Vimeo Link" cssClass="e-filled" formControlName="youtube_vimeo_link"
                       floatLabelType="Auto" (blur)="articleLTDForm.controls.youtube_vimeo_link.markAsTouched()">
          </ejs-textbox>
          <div *ngIf="articleLTDForm.controls.youtube_vimeo_link.errors">
            <div *ngIf="articleLTDForm.controls.youtube_vimeo_link.errors.invalidurl && articleLTDForm.controls.youtube_vimeo_link.touched "
                 class="e-error">
              Please enter a valid link.
            </div>
          </div>
        </div>



        </div>

      <div *ngIf="showUploader$|async as showUploader">
        <span class="font-weight-bold">Featured Image</span>
        <app-uploader *ngIf="showUploader" id="company-featured-uploader" (valueChange)="updateReferenceID($event)"
          [preLoadedFiles]="preLoadedFiles" [setCleared]="setCleared$|async"></app-uploader>
      </div>


      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <ejs-checkbox formControlName="is_featured_homepage" #wwcheckbox label="Feature on homepage: "
          labelPosition="Before"></ejs-checkbox>
      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
          class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
          type="button" (click)="clear()" data-ripple="true">Clear
        </button>

        <button *ngIf="type === 'ltd-settings'"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUpdateArticleLTD(articleLTDForm)" [disabled]="formSent" data-ripple="true">Update Article
        </button>
        <button *ngIf="type === 'brand'"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUpdateArticleLTD(articleLTDForm)" [disabled]="formSent" data-ripple="true">Update Brands
        </button>
        <button *ngIf="type=== 'images' && !showUploadingBtn"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUpdateArticleLTD(articleLTDForm)" [disabled]="formSent" data-ripple="true">Update Images
        </button>
        <button *ngIf="type=== 'images' && showUploadingBtn"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          [disabled]=true data-ripple="true">Uploading Images...
        </button>
      </div>
    </form>

    <!--    <form [formGroup]="brandForm" *ngIf="type === 'brand'">-->
    <!--      <div class="form-group sf-outline-input mb-10">-->
    <!--        <ejs-multiselect floatLabelType="Auto" cssClass="e-filled" mode='CheckBox' showClearButton="true"-->
    <!--                         formControlName='brands' [fields]="brandFields" [dataSource]="brandList"-->
    <!--                         placeholder='Select brands'></ejs-multiselect>-->
    <!--      </div>-->

    <!--      <div class="my-10 pb-20">-->
    <!--        <button id="resetbtn"-->
    <!--                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"-->
    <!--                type="button" (click)="articleLTDForm.reset();"-->
    <!--                data-ripple="true">Clear-->
    <!--        </button>-->

    <!--        <button-->
    <!--          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"-->
    <!--          (click)="onUpdateCompanyLTD(articleLTDForm)" [disabled]="formSent" data-ripple="true">Update Company-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </form>-->


  </div>

</ejs-sidebar>
<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>