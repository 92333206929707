import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment.prod";
import { Observable } from "rxjs";
import { ResponseData } from "../../ResponseData.interface";
import { v4 as uuid } from "uuid";

const API_URL = environment.API_URL;
const EVENT_URL: string = API_URL + "event";
const HTTP_DOWNLOAD_OPTIONS: {
  observe: "response";
  responseType: "blob"; // TODO: response type blob or json
} = {
  observe: "response",
  responseType: "blob",
};

@Injectable()
export class EventsService {
  id: string;
  constructor(private http: HttpClient) {}
  getEventsList(parameters): Observable<ResponseData> {
    if (parameters.routerParams) {
      parameters = Object.assign({}, parameters.routerParams, parameters);
      delete parameters.routerParams;
    }
    if (parameters.search) {
      delete parameters.search;
    }
    return this.http.get<ResponseData>(API_URL + "event", {
      params: parameters,
    });
  }
  updateEvent(params): Observable<ResponseData> {
    const postData = Object.assign({}, params, { batch_id: uuid() });
    delete postData.event_code;
    return this.http.put<ResponseData>(
      EVENT_URL + "/" + params.event_code,
      postData,
    );
  }
  deleteEvent(params): Observable<ResponseData> {
    return this.http.delete<ResponseData>(EVENT_URL + "/" + params.event_code);
  }
  checkDuplicates(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(EVENT_URL + "/check-duplicates", {
      params: parameters,
    });
  }
  getEventSlug(parameters): Observable<ResponseData> {
    return this.http.get<ResponseData>(EVENT_URL + "/generate-events-slug", {
      params: parameters,
    });
  }

  addEvent(params = {}): Observable<ResponseData> {
    const postData = Object.assign({}, params, { batch_id: uuid() });
    return this.http.post<ResponseData>(EVENT_URL, postData);
  }

  getEventDetails(params): Observable<ResponseData> {
    return this.http.get<ResponseData>(
      API_URL + "event-attendee/" + params.event_code,
    );
  }
  updateEventAttendees(params): Observable<ResponseData> {
    const postData = Object.assign({}, params, { batch_id: uuid() });
    delete postData.event_code;
    return this.http.put<ResponseData>(
      API_URL + "event-attendee/" + params.event_code,
      postData,
    );
  }

  deleteEventAttendee(params): Observable<ResponseData> {
    console.log("params", params);
    return this.http.post<ResponseData>(
      API_URL + "event-attendee/delete/" + params.event_code,
      {
        company_contact_code: params.company_contact_code,
      },
    );
  }
  exportEventAttendees(event_code: string, params = {}) {
    // const postData = Object.assign({}, params, { batch_id: uuid() });
    const postData = Object.assign({}, params);
    return this.http.post(
      `${API_URL}event-attendee/${event_code}/export`,
      postData,
      HTTP_DOWNLOAD_OPTIONS,
    );
  }
  getUserSettings(): Observable<ResponseData> {
    return this.http.get<ResponseData>(`${API_URL}user-settings`);
  }

  updateUserSettings(
    setting_value: string,
    settingKeyCode: string,
  ): Observable<ResponseData> {
    this.id = uuid();

    let request: any = {
      setting_value,
      batch_id: this.id,
    };

    return this.http.put<ResponseData>(
      `${API_URL}user-settings/${settingKeyCode}`,
      request,
    );
  }
}
