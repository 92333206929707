<ejs-sidebar
  id="sidebar-menu"
  #sidebarInstance
  type="Over"
  [width]="width"
  [position]="position"
  style="visibility: hidden"
  [enableGestures]="false"
  [showBackdrop]="true"
>
  <div class="offcanvas-close mt-n1 pr-5" style="float: right; padding: 48px">
    <a
      href="javascript:;"
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      id="kt_quick_panel_close"
      (click)="closeSidebar()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">
    <h2 *ngIf="formType === 'note' && !activityInput">Create Note</h2>
    <h2 *ngIf="formType === 'note' && activityInput">Update Note</h2>

    <h2 *ngIf="formType === 'meeting' && !activityInput">Create Meeting</h2>
    <h2 *ngIf="formType === 'meeting' && activityInput">Update Meeting</h2>

    <h2
      *ngIf="
        (formType === 'status-changed' || formType === 'assigned-to-changed') &&
        !activityInput &&
        activityEntity === 'project'
      "
    >
      Status and/or Assigned User Changed
    </h2>
    <h2
      *ngIf="
        (formType === 'status-changed' || formType === 'assigned-to-changed') &&
        !activityInput &&
        activityEntity !== 'project'
      "
    >
      Status Changed
    </h2>
    <h2 *ngIf="formType === 'status-changed' && activityInput">
      Update Status Changed
    </h2>

    <h2
      *ngIf="
        formType === 'post-date-changed' &&
        !activityInput &&
        activityEntity === 'project'
      "
    >
      Post Date Changed
    </h2>
    <h2
      *ngIf="
        formType === 'featured-date-changed' &&
        !activityInput &&
        activityEntity === 'project'
      "
    >
      Featured Date Changed
    </h2>

    <form
      *ngIf="formType === 'note' || formType === 'meeting'"
      id="formId"
      [formGroup]="activityForm"
      #formDir="ngForm"
      class="form-horizontal"
      novalidate=""
      style="margin-top: 16px"
    >
      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox
          id="activity_title"
          placeholder="Activity Title *"
          cssClass="e-filled"
          formControlName="activity_title"
          floatLabelType="Auto"
          (blur)="activityForm.controls.activity_title.markAsTouched()"
          required
        ></ejs-textbox>
        <div *ngIf="activityForm.controls.activity_title.errors">
          <div
            *ngIf="
              activityForm.controls.activity_title.errors.required &&
              activityForm.controls.activity_title.touched
            "
            class="e-error"
          >
            Activity Title is required.
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox
          id="note"
          [multiline]="true"
          placeholder="Notes *"
          cssClass="e-filled"
          formControlName="note"
          floatLabelType="Auto"
          (blur)="activityForm.controls.note.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="activityForm.controls.note.errors">
          <div
            *ngIf="
              activityForm.controls.note.errors.required &&
              activityForm.controls.note.touched
            "
            class="e-error"
          >
            Activity Note is required.
          </div>
        </div>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="formType === 'meeting'"
      >
        <ejs-datepicker
          formControlName="meeting_date"
          openOnFocus="true"
          [allowEdit]="allowEdit"
          placeholder="Meeting Date"
          floatLabelType="Auto"
          cssClass="e-filled"
        >
        </ejs-datepicker>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="formType === 'meeting'"
      >
        <ejs-timepicker
          formControlName="meeting_start_time"
          cssClass="e-filled"
          openOnFocus="true"
          [allowEdit]="allowEdit"
          placeholder="Select a start time"
          floatLabelType="Auto"
          #startTime
          id="startPicker"
          (change)="onEnableEndTime($event)"
        ></ejs-timepicker>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="formType === 'meeting'"
      >
        <ejs-timepicker
          formControlName="meeting_end_time"
          name="meeting_end_time"
          placeholder="Select an end time"
          cssClass="e-filled"
          openOnFocus="true"
          [allowEdit]="allowEdit"
          floatLabelType="Auto"
          #endTime
          id="endPicker"
          [enabled]="false"
        ></ejs-timepicker>
      </div>

      <div class="form-group sf-outline-input" *ngIf="formType === 'meeting'">
        <div class="e-float-input">
          <ejs-multiselect
            id="userParticipants"
            formControlName="userParticipants"
            cssClass="e-filled"
            floatLabelType="Auto"
            [dataSource]="users"
            (filtering)="onFiltering($event)"
            [fields]="userFields"
            [allowFiltering]="true"
            mode="Box"
            placeholder="Internal Participants"
          >
          </ejs-multiselect>
        </div>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="formType === 'meeting'"
      >
        <div class="e-float-input">
          <ejs-multiselect
            id="multi-template"
            formControlName="participants"
            cssClass="e-filled"
            floatLabelType="Auto"
            [dataSource]="participantsList"
            (filtering)="onFiltering($event, 'contact')"
            [fields]="participantsFields"
            [allowFiltering]="true"
            mode="Box"
            placeholder="External Participants"
          >
          </ejs-multiselect>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect
          id="project_activity_tag"
          #tags
          [dataSource]="tagModelData"
          floatLabelType="Auto"
          cssClass="e-filled"
          [fields]="tagFields"
          formControlName="tags"
          [allowCustomValue]="true"
          mode="Box"
          placeholder="Tags"
          (actionComplete)="actionComplete($event)"
          (ngModelChange)="syncModel($event)"
          (change)="onChange($event)"
          (blur)="onBlur($event, activityForm)"
          (removed)="selectedTag = []"
          [(ngModel)]="modelData"
          (select)="select($event)"
          ngModel
        ></ejs-multiselect>
      </div>

      <div *ngIf="activityForm.invalid" class="e-error">
        Please fill all fields
      </div>
      <div *ngIf="error$ | async as error">{{ error.error.message }}</div>

      <div class="my-10 pb-20">
        <button
          id="resetbtnactivity"
          class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
          type="button"
          data-ripple="true"
          (click)="clear()"
        >
          Clear
        </button>
        <button
          id="add"
          *ngIf="!activityInput"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
          type="submit"
          (click)="onAddUpdateActivity(activityForm, 'add')"
          [disabled]="formSent"
          data-ripple="true"
        >
          Add Activity
        </button>
        <button
          *ngIf="activityInput"
          id="update"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
          type="submit"
          (click)="onAddUpdateActivity(activityForm, 'update')"
          [disabled]="formSent"
          data-ripple="true"
        >
          Update Activity
        </button>
      </div>
    </form>

    <form
      *ngIf="
        formType === 'status-changed' ||
        formType === 'assigned-to-changed' ||
        formType === 'post-date-changed' ||
        formType === 'featured-date-changed'
      "
      id="status_changed_formId"
      [formGroup]="statusChangedForm"
      #formDir="ngForm"
      class="form-horizontal"
      novalidate=""
      style="margin-top: 16px"
    >
      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="
          activityEntity === 'project' &&
          formType !== 'post-date-changed' &&
          formType !== 'featured-date-changed'
        "
      >
        <ejs-dropdownlist
          #status
          formControlName="status_code"
          id="status_code"
          [fields]="projectStatusFields"
          placeholder="Status *"
          (blur)="statusChangedForm.controls.status_code.markAsTouched()"
          cssClass="e-filled"
          [enabled]="setEnabled"
          (change)="setStatus($event, statusChangedForm)"
          floatLabelType="Auto"
          [dataSource]="projectStatus"
        ></ejs-dropdownlist>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="activityEntity === 'project' && formType === 'post-date-changed'"
      >
        <ejs-datetimepicker
          formControlName="proposed_post_date"
          name="proposed_post_date"
          [allowEdit]="allowEdit"
          placeholder="Published Date"
          cssClass="e-filled"
          floatLabelType="Auto"
        >
        </ejs-datetimepicker>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="
          activityEntity === 'project' && formType === 'featured-date-changed'
        "
      >
        <ejs-datepicker
          formControlName="featured_date"
          name="featured_date"
          [allowEdit]="allowEdit"
          placeholder="Featured Date"
          cssClass="e-filled"
          floatLabelType="Auto"
        >
        </ejs-datepicker>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="activityEntity === 'contact'"
      >
        <ejs-dropdownlist
          #status
          formControlName="status_code"
          [fields]="companyContactStateDataField"
          placeholder="Status *"
          (blur)="statusChangedForm.controls.status_code.markAsTouched()"
          cssClass="e-filled"
          [enabled]="setEnabled"
          floatLabelType="Auto"
          [dataSource]="companyContactStateData"
        ></ejs-dropdownlist>
      </div>

      <!--      <div class="form-group sf-outline-input mb-10">-->
      <!--        <ejs-textbox id="activity_title" placeholder="Activity Title *" cssClass="e-filled"-->
      <!--                     formControlName="activity_title" floatLabelType="Auto"-->
      <!--                     (blur)="statusChangedForm.controls.activity_title.markAsTouched()" required-->
      <!--        ></ejs-textbox>-->
      <!--        <div *ngIf="statusChangedForm.controls.activity_title.errors">-->
      <!--          <div-->
      <!--            *ngIf="statusChangedForm.controls.activity_title.errors.required && activityForm.controls.activity_title.touched"-->
      <!--            class="e-error">-->
      <!--            Activity Title is required.-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox
          id="note"
          [multiline]="true"
          placeholder="Notes *"
          cssClass="e-filled"
          formControlName="note"
          floatLabelType="Auto"
          (blur)="statusChangedForm.controls.note.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="statusChangedForm.controls.note.errors">
          <div
            *ngIf="
              statusChangedForm.controls.note.errors.required &&
              statusChangedForm.controls.note.touched
            "
            class="e-error"
          >
            Activity Note is required.
          </div>
        </div>
      </div>

      <div
        class="form-group sf-outline-input"
        *ngIf="
          activityEntity === 'project' &&
          formType !== 'post-date-changed' &&
          formType !== 'featured-date-changed'
        "
      >
        <div class="e-float-input">
          <ejs-dropdownlist
            id="assigned_to"
            formControlName="assigned_to"
            cssClass="e-filled"
            floatLabelType="Auto"
            [dataSource]="users"
            (filtering)="onFiltering($event)"
            [fields]="userFields"
            [allowFiltering]="true"
            [enabled]="setEnabled"
            placeholder="Assigned To"
            (change)="setAssignedTo($event, statusChangedForm)"
            (open)="Complete($event)"
          >
            <ng-template
              #itemTemplate=""
              let-data
              style="display: inline-block"
            >
              <div
                style="display: inline-block"
                *ngIf="data.is_disable_yn != 1"
              >
                <span>{{ data.name }}</span>
              </div>
              <div
                style="display: inline-block"
                *ngIf="data.is_disable_yn == 1"
              >
                <span [ngStyle]="{ color: '#d3d3d3' }">{{ data.name }}</span>
              </div>
            </ng-template>
          </ejs-dropdownlist>
        </div>
      </div>

      <div
        class="form-group sf-outline-input mb-10"
        *ngIf="
          activityEntity === 'project' &&
          formType !== 'post-date-changed' &&
          formType !== 'featured-date-changed'
        "
      >
        <ejs-textbox
          id="assigned_to_note"
          [multiline]="true"
          placeholder="Assigned To Notes"
          cssClass="e-filled"
          formControlName="assigned_to_note"
          floatLabelType="Auto"
          (blur)="statusChangedForm.controls.assigned_to_note.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="statusChangedForm.controls.assigned_to_note.errors">
          <div
            *ngIf="
              statusChangedForm.controls.assigned_to_note.errors.required &&
              statusChangedForm.controls.assigned_to_note.touched
            "
            class="e-error"
          >
            Assigned To Note is required.
          </div>
        </div>
      </div>

      <div *ngIf="statusChangedForm.invalid" class="e-error">
        Please fill all fields
      </div>
      <div *ngIf="error$ | async as error">{{ error.error.message }}</div>

      <div class="my-10 pb-20">
        <button
          *ngIf="!activityInput"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
          type="submit"
          (click)="onUpdateStatus(statusChangedForm)"
          [disabled]="formSent"
          data-ripple="true"
        >
          Add Activity
        </button>
        <button
          *ngIf="activityInput"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block"
          type="submit"
          (click)="onUpdateStatusNote(statusChangedForm)"
          [disabled]="formSent"
          data-ripple="true"
        >
          Update Activity
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>
<ejs-dialog
  #clearModal
  [buttons]="dltButtons"
  header="Are you sure?"
  animationSettings="none"
  showCloseIcon="true"
  width="25%"
  [visible]="false"
>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>

