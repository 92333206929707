import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tags } from '../../../../../core/models/tags.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { websiteValidator } from '../../../../../app-validators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core';
import { ContactSetStatusNull, ContactUpdateGeneral } from '../../../../../core/store/actions/contact.actions';
import { Company } from '../../../../../core/models/company.model';
import { CompanySetStatusNull } from '../../../../../core/store/actions/company.actions';
import * as _ from 'lodash';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-contact-general-form',
  templateUrl: './contact-general.component.html',
  styleUrls: ['./contact-general.component.scss']
})
export class ContactGeneralComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @Input() allTags: Observable<Array<Tags>>;
  @Input() contactCode: string;
  @Input() socialMediaPlatform: any[];
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  public formSent: boolean;
  serror = false
  svalue = []
  s = ''
  contactGeneralForm: FormGroup;
  socialMediaCounter = [1];
  chosenSocialMediaPlatforms = [];
  
 

  successContact$: Observable<boolean>;
  @Output() valueChange = new EventEmitter();

  socialMediaFields = { text: 'social_media_platform_name', value: 'social_media_platform_code' };
  tagFields = { text: 'tag', value: 'tag' };
  public tagModelData = [];

  public _contactGeneralInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set contactGeneralInput(mode: any | null) {
    this._contactGeneralInput$.next(mode);
  }

  get contactGeneral(): any | null {
    return this._contactGeneralInput$.getValue();
  }


  constructor(private fb: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.generateForm();

    this.successContact$ = this.store.pipe(select(store => store.contacts.success.isLoaded));
    this.successContact$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ContactSetStatusNull());
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
    this.valueChange.emit('success');
    this.clearForm();
  }

  clearForm() {
    this.chosenSocialMediaPlatforms = [];
    this.contactGeneralForm.reset();
    this.social_media.clear();
    this.contactGeneralForm.clearValidators();
    this.contactGeneralForm.updateValueAndValidity();
  }

  generateForm() {
    this.contactGeneralForm = new FormGroup({
      internal_tags: new FormControl(''),
      external_tags: new FormControl(''),
      description: new FormControl(''),
      social_media: new FormArray([])
    });

    if (!this._contactGeneralInput$.getValue()) {
      // this.addSMLink();
    }
  }

  addSMLink() {
    this.socialMediaCounter.push(1);
    this.social_media.push(this.newLink());
  }

  newLink(): FormGroup {
    return this.fb.group({
      social_media_link: new FormControl(''),
      social_media_platform_code: '',
    });
  }

  get social_media(): FormArray {
    return this.contactGeneralForm.get('social_media') as FormArray;
  }

  removeSMLink() {
    this.social_media.removeAt(this.social_media.length - 1);
    this.chosenSocialMediaPlatforms.pop();

  }

  onUpdateContactGeneral(form: FormGroup) {
    this.serror = false
    if (!form.invalid) {
      const formValues = form.getRawValue();
      if (formValues.social_media.social_media_platform_code != '' || formValues.social_media.social_media_platform_code != null) {
        
              for (var i = 0; i <= formValues.social_media.length - 1; i++) {
                var j = 0
                if (formValues.social_media[i].social_media_link == '' || formValues.social_media[i].social_media_link == null) {
                   this.serror = true
        
                 }
              
                 
               }
             }
        if(this.serror==false){
      this.store.dispatch(new ContactUpdateGeneral({
        contact_code: this.contactCode,
        description: formValues.description,
         socialMedia: formValues.social_media.filter((item) => item.social_media_platform_code !== ''),
        internal_tag: formValues.internal_tags,
        external_tag: formValues.external_tags
      }));
    }}
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allTags) {
    }

    if (this._contactGeneralInput$.getValue()) {

      const publicTags = [];
      const privateTags = [];

      if (this._contactGeneralInput$.getValue().public_tag) {
        for (const tag of this._contactGeneralInput$.getValue().public_tag) {
          publicTags.push(tag.tag);
        }
      }

      if (this._contactGeneralInput$.getValue().private_tag) {
        for (const tag of this._contactGeneralInput$.getValue().private_tag) {
          privateTags.push(tag.tag);
        }
      }

      this.contactGeneralForm.patchValue({
        internal_tags: privateTags ? privateTags : null,
        external_tags: publicTags ? publicTags : null,
        description: this._contactGeneralInput$.getValue().description ? this._contactGeneralInput$.getValue().description : null
      });

      if (this._contactGeneralInput$.getValue().social_media && this._contactGeneralInput$.getValue().social_media.length > 0) {
        for (const [index, socialMediaLink] of this._contactGeneralInput$.getValue().social_media.entries()) {
          // if (index > 0) {
          this.addSMLink();
          // }

          this.social_media.at(index).setValue({
            social_media_link: socialMediaLink.link,
            social_media_platform_code: socialMediaLink.social_media_platform.social_media_platform_code,
          });

        }


      }
    }
  }

  setSocialMediaPlatforms(index, type, args?) {
    if(this.serror==true){
           if(args==undefined){
             this.serror=false
            }
          }
    if (type === 'link') {

      if (this.social_media.controls[index].get('social_media_platform_code').value === null
        || this.social_media.controls[index].get('social_media_platform_code').value === '') {
        
        this.social_media.controls[index].get('social_media_platform_code').setValidators([Validators.required]);
        this.social_media.controls[index].get('social_media_platform_code').markAsTouched();
      } else {
        
        this.social_media.controls[index].get('social_media_platform_code').clearValidators();
      }
    } else {
      if (this.social_media.controls[index].get('social_media_link').value === null
        || this.social_media.controls[index].get('social_media_link').value === '') {
        // this.socialMediaPlatform = this.socialMediaPlatform.filter((item) => item.social_media_platform_code !== this.social_media.controls[index].get('social_media_platform_code').value);
        if (args.value !== null) {
          this.social_media.controls[index].get('social_media_platform_code').setValue(args.value);
          this.chosenSocialMediaPlatforms[index] = this.social_media.controls[index].get('social_media_platform_code').value;
        }
        if (this.social_media.controls[index].get('social_media_platform_code').value.indexOf('social-id') > -1) {
          this.social_media.controls[index].get('social_media_link').clearValidators();
          
          this.social_media.controls[index].get('social_media_link').setValidators([Validators.required]);
          this.serror=false
          // this.social_media.controls[index].get('social_media_id').markAsTouched();

        } else {

          this.social_media.controls[index].get('social_media_link').clearValidators();
          
          this.social_media.controls[index].get('social_media_link').setValidators([Validators.required, websiteValidator]);
          this.serror=false
        }
        this.social_media.controls[index].get('social_media_link').markAsTouched();
      } else {
        this.social_media.controls[index].get('social_media_link').clearValidators();
        this.social_media.controls[index].get('social_media_link').updateValueAndValidity();

        // this.social_media.controls[index].get('social_media_link').setValidators([Validators.compose([websiteValidator])]);

      }
    }

    this.social_media.controls[index].get('social_media_platform_code').updateValueAndValidity();
    this.social_media.controls[index].get('social_media_link').updateValueAndValidity();
  }

  fetchSocialMediaPlatformDataset(args) {
    return _.cloneDeep(this.socialMediaPlatform.filter((item) => !this.chosenSocialMediaPlatforms.includes(item.social_media_platform_code) || item.social_media_platform_code === args));
  }

  getPlaceholder(index) {
    if (this.social_media.controls[index].get('social_media_platform_code').value.indexOf('social-id') > -1) {
      return 'ID';
    } else {
      return 'Link';
    }
  }
  clearAll(){
    this.clearModal.hide()
  this.clearForm()
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
