<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">

    <h2>Article - General</h2>

    <form id="formId" [formGroup]="articleGeneralForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">
      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='tags' #tags [dataSource]="allTags" floatLabelType="Auto" cssClass="e-filled"
                         formControlName='internal_tags' [fields]="tagFields"
                         [allowCustomValue]=true mode='Box' placeholder='Internal Tags'
                         name="tags"
                         ngModel></ejs-multiselect>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-multiselect id='public_tags' #tags [dataSource]="allTags" floatLabelType="Auto" cssClass="e-filled"
                         formControlName='external_tags' [fields]="tagFields"
                         [allowCustomValue]=true mode='Box' placeholder='External Tags'
                         name="tags"
                         ngModel></ejs-multiselect>
      </div>


      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button"
                (click)="clear();"
                data-ripple="true">Clear
        </button>

        <button
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="onUpdateArticleGeneral(articleGeneralForm)" [disabled]="formSent" data-ripple="true">Update General
        </button>
      </div>
    </form>

  </div>

</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
