import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CompanyFilter, CompanyLoad, CompanySetStatusNull} from '../../../../../core/store/actions/company.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import * as moment from 'moment';
import * as _ from 'lodash';
import {MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-company-filter',
  templateUrl: './company-filter.component.html',
  styleUrls: ['./company-filter.component.scss']
})
export class CompanyFilterComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance')
  public sidebarInstance: SidebarComponent;
  @ViewChild('tags') companyTag: MultiSelectComponent;

  @Input() users: object=[];
  @Input() countries: object;
  @Input() companyTypes: any;
  @Input() companyRoles: any;
  @Input() allTags: Observable<Array<Tags>>;

  public width = 'auto';
  public position = 'Right';

  formSent: boolean;
  isFilterReset = false;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;
  allowEdit = false;

  selectedTag = [];
  modelData = [];
  public tagModelData = [];
  tagSelected = '';
  public treeSettings: object = { autoCheck: true };

  status = [
    {
      status_value: '0',
      status: 'Active'
    },
    {
      status_value: '1',
      status: 'Inactive'
    }
  ];

  public companyTypeField: object;
  public companyRoleField: object;
  public tagFields: object = {value: 'tag', text: 'tag'};
  public userFields: object = {dataSource: this.users, value: 'user_code', text: ('first_name')};
  public statusFields: object = {dataSource: this.status, value: 'status_value', text: 'status'};
  public countryField: object = {value: 'country_code', text: 'country_name'};



  filterForm: FormGroup;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.generateFilterForm();
    this.allTags.subscribe(data => {
      this.tagModelData = data;
    });

    this.success$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.error$ = this.store.pipe(select(store => store.companies.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset) {
          this.closeSidebar();
        }
        this.store.dispatch(new CompanySetStatusNull());
      }
    });

    this.error$.subscribe((data: any) => {
      this.errorMsg = data ? data.error : null;
      if (data) {
        this.formSent = false;
      }
    });

  }

  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      company_name: new FormControl(null),
      company_type_code: new FormControl(''),
      account_owner: new FormControl(''),
      country_code: new FormControl(null),
      company_tag: new FormControl(''),
      updated_at: new FormControl(null),
      created_at: new FormControl(null),
      published_by: new FormControl(null),
      reference_id: new FormControl(null),
      company_role_code: new FormControl(''),
      is_inactive_yn: new FormControl(''),
    });
    this.activatedRoute.queryParams.subscribe(res => {
      // console.log(res);
      // let queryCopy = {...res};
      // const queryCopy = {...this.filterForm.value, ...res};
      // delete queryCopy.company_type_code;
      // this.filterForm.patchValue(queryCopy);
      // console.log(this.filterForm.value);
      // console.log(queryCopy);
      // const resCopy = {...res};
      // const types = resCopy.company_type_code.split(',');
      // this.filterForm.setValue(queryCopy);
      // this.filterForm.setValue({company_type_code: types});
      // this.filterForm.patchValue({company_type_code: types});
      // console.log(this.filterForm.value);
    });
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {...this.filterForm.value};
    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] == '')) && delete filterValues[key]);

    if (filterValues.created_at) {
      filterValues.created_at_end = moment(filterValues.created_at[1]).format('YYYY-MM-DD');
      filterValues.created_at_start = moment(filterValues.created_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.created_at;

    if (filterValues.updated_at) {
      filterValues.updated_at_end = moment(filterValues.updated_at[1]).format('YYYY-MM-DD');
      filterValues.updated_at_start = moment(filterValues.updated_at[0]).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at;

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }
    }

    this.router.navigate([], {queryParams: filterValues});
    
    filterValues = {...filterValues, page_size: 100, page_no: 1};
    this.store.dispatch(new CompanyFilter(filterValues));
  }

  clearForm() {
    this.filterForm.reset();
    this.isFilterReset = true;
    this.filterForm.controls.company_type_code.setValue('');
    this.router.navigate([], { queryParams: {}});
    this.store.dispatch(new CompanyLoad());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companyTypes) {
      this.companyTypeField = {dataSource: this.companyTypes, value: 'company_type_code', text: 'company_type_name', child: 'children'};
    }

    if (changes.companyRoles) {
      this.companyRoleField = {dataSource: this.companyRoles, value: 'company_role_code', text: 'company_role_name', child: 'children'};
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.sidebarInstance.hide();
  }


  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

    if (!this.modelData.includes(this.tagSelected)) {
      this.companyTag.addItem({tag: this.tagSelected, tag_code: this.tagSelected});
      form.controls.company_tag.setValue( _.uniq(_.compact([...this.modelData, this.tagSelected])));
    }

    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);

    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  }

  actionComplete(args) {
    if (_.get(args, 'result[0]')) {
      this.selectedTag.push(args.result[0].tag);
    }
  }
  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if ( tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

}
