export class User {
  user_code: string;
  email?: string;
  token?: string;
  first_name: string;
  last_name: string;
  security_permissions: object;
  is_disable_yn:any;
  is_blocked_yn:any;

  clear(): void {
    this.user_code = '';
    this.email = '';
    this.token = '';
  }

  constructor() {}
}
