// Actions
import {ProjectActions, ProjectActionTypes} from '../actions/project.actions';

// Models
import {Project} from '../../models/project.model';
import {Activity} from '../../models/activity.model';
import {ProjectSource} from '../../models/project-source.model';
import {formatDataSet} from '../../../views/common/base.component';
import {TransactionHistory} from '../../models/transaction-history.model';
import * as moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import * as _ from 'lodash';
export interface ProjectState {
  projects: {
    result: Project[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: any;
  projectActivity?: Activity[];
  projectSources?: ProjectSource[];
  db?: {
    result: Project[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  details?: Project;
  statusChanged?: any;
}

export const initialState: ProjectState = {
  projects: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
  projectActivity: [],
};

export const ReturnLoading = (State: ProjectState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: false,
  };
};

export function projectReducer(state: ProjectState = initialState, action: ProjectActions): ProjectState {
  switch (action.type) {

    case ProjectActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.LOAD_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = {data: []};
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
      }

      return {
        ...state, projects: {
          result: result.data,
          total_count: result.data.length,
        }, loading: false, error: undefined, loaded: true, db: {
          result: result.data,
          total_count: result.data.length,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };
    }

    case ProjectActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.ADD: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.ADD_SUCCESS: {
      let project = {...action.payload.project};
      Object.keys(project).some((key) => {
        if (action.payload.project[key] && typeof project[key] === 'object') {
          project = {...project, ...action.payload.project[key]};
        }
      });
      return {
        ...state, loading: false, projects: {result: [project, ...state.projects.result]}, error: undefined, loaded: true,
        success: true
      };
    }

    case ProjectActionTypes.ADD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.UPDATE:
      return ReturnLoading(state);

    case ProjectActionTypes.UPDATE_SUCCESS: {
      const updatedProject = {...action.payload.project};
      const stateDetails = {...state.details};
      updatedProject.Project_Roles = stateDetails.Project_Roles;
      updatedProject.activity = stateDetails.activity;
      updatedProject.transaction_history = stateDetails.transaction_history;

      const projectList = {...state.projects};
      const updatedProjects = [...state.projects.result];

      if (projectList.result && projectList.result.length > 0) {
        const projectIndex = projectList.result.findIndex(x => x.project_code === action.payload.project_code);
        const project = state.projects[projectIndex];

        const updatedProjectListItem = {
          ...project, ...{
            project_code: action.payload.project_code,
            project_name: action.payload.project.project_name,
            status_code: action.payload.project.status.status_code,
            status_name: action.payload.project.status.status_name,
            reference_id: action.payload.project.reference_id,
            proposed_post_date: action.payload.project.proposed_post_date,
            updated_at: action.payload.project.last_updated,
            country_code: action.payload.project.country.country_code,
            country_name: action.payload.project.country.country_name,
            featured_date: action.payload.project.featured_date,
            ltd_project_link: action.payload.project.ltd_project_link,
            drive_link: action.payload.project.drive_link,
            is_deleted_yn: action.payload.project.is_deleted_yn

          }
        };
        const index = updatedProjects.findIndex(x => x.project_code === action.payload.project_code);
        updatedProjects[index] = updatedProjectListItem;
      }

      // const projectIndex = state.projects.findIndex(x => x.project_code === action.payload.project_code);
      // const project = state.projects[projectIndex];
      // const updatedProject = {...project, ...action.payload.project};
      // const updatedProjects = [...state.projects];
      //
      // const index = updatedProjects.findIndex(x => x.project_code === action.payload.project_code);
      // updatedProjects[index] = updatedProject;
      return {
        ...state, details: updatedProject, projects: {result: updatedProjects, total_count: updatedProjects.length}, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case ProjectActionTypes.UPDATE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.DELETE:
      return ReturnLoading(state);

    case ProjectActionTypes.DELETE_SUCCESS: {
      return {
        ...state, projects: {result: state.projects.result.filter(item => item.project_code !== action.payload.project_code)},
        loading: false, error: undefined, loaded: true, success: action.payload.project_code
      };
    }

    case ProjectActionTypes.DELETE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.LOAD_SINGLE_PROJECT:
      return ReturnLoading(state);

    case ProjectActionTypes.LOAD_SINGLE_PROJECT_SUCCESS: {
      // const selectedProjectIndex = state.projects.findIndex(x => x.project_code === action.payload.project.project_code);
      // const project = state.projects[selectedProjectIndex];
      // const updatedProject = {...project, ...action.payload.project};
      //
      // let roles: { [key: string]: { company_role_name: string, companyRoles: Array<any> } } = {};
      // // let roles: object;
      // updatedProject.Project_Roles.forEach(element => {
      //   if (!roles[element.company_role[0].company_role_code]) {
      //     roles[element.company_role[0].company_role_code] = {
      //       company_role_name: element.company_role[0].company_role_name, companyRoles: []
      //     };
      //   }
      //
      //   let companyContact = {...element};
      //   delete companyContact.company_role;
      //   roles[element.company_role[0].company_role_code].companyRoles.push(companyContact);
      // });
      // // delete updatedProject.Project_Roles;
      // updatedProject.roles = roles;
      // const updatedProjects = [...state.projects];
      // console.log(updatedProject);
      //
      // const index = updatedProjects.findIndex(x => x.project_code === action.payload.project.project_code);
      // updatedProjects[index] = updatedProject;
      

      return {...state, details: action.payload.project, error: undefined, loading: false, loaded: true, success: true};
    }

    case ProjectActionTypes.LOAD_SINGLE_PROJECT_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.GET_ACTIVITY:
      return ReturnLoading(state);

    case ProjectActionTypes.GET_ACTIVITY_SUCCESS: {
      const projectDetails = {...state.details};
      projectDetails.activity = [...action.payload];
      return {...state, details: projectDetails, error: undefined, loading: false, loaded: true, success: true};
    }

    case ProjectActionTypes.GET_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.ADD_ACTIVITY:
      return ReturnLoading(state);

    case ProjectActionTypes.ADD_ACTIVITY_SUCCESS: {
      const projectDetails = {
        updated_at: undefined,
        ...state.details
      };
      if (!projectDetails.activity) {
        const array = [];
        array.push(action.payload.activity);
        projectDetails.activity = array;
      } else {
        projectDetails.activity = [action.payload.activity, ...projectDetails.activity];
      }
      projectDetails.updated_at = action.payload.activity.updated_at;
      return {
        ...state, details: projectDetails, loading: false,
        loaded: true, success: true, error: undefined
      };
    }

    case ProjectActionTypes.ADD_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.SET_SUCCESS_NULL:
      return ReturnLoading(state);

    case ProjectActionTypes.DELETE_ACTIVITY:
      return ReturnLoading(state);

    case ProjectActionTypes.DELETE_ACTIVITY_SUCCESS: {
      const projectDetails = {
        updated_at: undefined,
        ...state.details
      };
      projectDetails.activity = projectDetails.activity.filter(item => item.activity[0].activity_code !==
        action.payload.activity_code);

      projectDetails.updated_at = moment();
      return {
        ...state, details: projectDetails,
        loading: false, error: undefined, loaded: true
      };
    }

    case ProjectActionTypes.DELETE_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.UPDATE_ACTIVITY:
      return ReturnLoading(state);

    case ProjectActionTypes.UPDATE_ACTIVITY_SUCCESS: {
      const projectActivityIndex = state.details.activity.findIndex(x => x.activity[0].activity_code === action.payload.activity_code);
      const projectActivity = state.details.activity[projectActivityIndex];
      const updatedProjectActivity = {...action.payload.projectActivity};
      const updatedProjectDetails = {
        updated_at: undefined,
        ...state.details
      };

      const index = updatedProjectDetails.activity.findIndex(x => x.activity[0].activity_code === action.payload.activity_code);
      const projectActivityCopy = [...updatedProjectDetails.activity];
      projectActivityCopy[index] = updatedProjectActivity;
      updatedProjectDetails.activity = projectActivityCopy;
      updatedProjectDetails.updated_at = action.payload.projectActivity.updated_at;
      return {
        ...state, details: updatedProjectDetails, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case ProjectActionTypes.UPDATE_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.GET_PROJECT_SOURCES:
      return ReturnLoading(state);

    case ProjectActionTypes.GET_PROJECT_SOURCES_SUCCESS:
      return {
        ...state,
        projects: {result: [...state.projects.result]},
        projectSources: action.payload,
        error: undefined,
        loading: false,
        loaded: true
      };


    case ProjectActionTypes.GET_PROJECT_SOURCES_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.FILTER_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = {data: []};
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
      }
      return {
        ...state,
        projects: {result: result.data, total_count: result.data.length},
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case ProjectActionTypes.FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.FILTER_RESET: {
      return {
        ...state,
        projects: {result: [...state.db.result], total_count: state.db.total_count},
        loading: false,
        error: undefined,
        loaded: true,
        success: true
      };
    }

    case ProjectActionTypes.ACTIVITY_FILTER: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.ACTIVITY_FILTER_SUCCESS: {
      const projectDetails = {...state.details};
      projectDetails.activity = [...action.payload];

      // for (const activity in resultActivity) {
      //   const updated_by = {...resultActivity[activity].updated_by};
      //   if (resultActivity[activity].contact_activity) {
      //     resultActivity[activity] = resultActivity[activity].contact_activity;
      //
      //     delete resultActivity[activity].contact_activity;
      //   } else if (resultActivity[activity].company_activity) {
      //     resultActivity[activity] = resultActivity[activity].company_activity;
      //     delete resultActivity[activity].company_activity;
      //   } else if (resultActivity[activity].project_activity) {
      //     resultActivity[activity] = resultActivity[activity].project_activity;
      //     delete resultActivity[activity].project_activity;
      //   }
      //   const act = {...resultActivity[activity]};
      //   act.updated_by = updated_by;
      //   resultActivity[activity] = act;
      // }

      // projectDetails.activity = resultActivity;
      
      return {...state, details: projectDetails, loading: false, error: undefined, loaded: true, success: true};
    }

    case ProjectActionTypes.ACTIVITY_FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};

    }

    case ProjectActionTypes.LOAD_PROJECT_SOCIALS: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.LOAD_PROJECT_SOCIALS_SUCCESS: {
      const project = {...state.details};
      project.project_socials = [...action.payload];
      
      return {...state, details: project, loading: false, error: undefined, loaded: true};
    }

    case ProjectActionTypes.LOAD_PROJECT_SOCIALS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.LOAD_COMPANY_ROLES: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.LOAD_COMPANY_ROLES_SUCCESS: {
      const project = {...state.details};
      project.Project_Roles = [...action.payload];
      
      return {...state, details: project, loading: false, error: undefined, loaded: true};
    }

    case ProjectActionTypes.LOAD_COMPANY_ROLES_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.ADD_PROJECT_SOCIALS: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.ADD_PROJECT_SOCIALS_SUCCESS: {
      const project = {updated_at: undefined, ...state.details};
      if (!project.project_socials) {
        const array = [];
        array.push(action.payload);
        project.project_socials = array;
      } else {
        project.project_socials = [...project.project_socials, action.payload];
      }
      
      return {...state, details: project, loading: false, error: undefined, loaded: true, success: true};
    }

    case ProjectActionTypes.ADD_PROJECT_SOCIALS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.UPDATE_PROJECT_SOCIALS: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.UPDATE_PROJECT_SOCIALS_SUCCESS: {
      const projectSocialIndex = state.details.project_socials.findIndex(x => x.project_social_code === action.payload.project_social_code);
      const projectSocials = state.details.project_socials[projectSocialIndex];
      const updatedProjectSocial = {...action.payload};
      const updatedProjectSocials = { ...state.details};

      const index = updatedProjectSocials.project_socials.findIndex(x => x.project_social_code=== action.payload.project_social_code);
      const socials = [...updatedProjectSocials.project_socials];
      socials[index] = updatedProjectSocial;
      updatedProjectSocials.project_socials = socials;
      


      return {...state, details: updatedProjectSocials, loading: false, error: undefined, loaded: true, success: true};
    }

    case ProjectActionTypes.UPDATE_PROJECT_SOCIALS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.DELETE_PROJECT_SOCIALS:
      return ReturnLoading(state);

    case ProjectActionTypes.DELETE_PROJECT_SOCIALS_SUCCESS: {
      const project = {
        
        ...state.details
      };
    
      project.project_socials = project.project_socials.filter(item => item.project_social_code !== action.payload.social_date_code);
      

      return {
        ...state, details: project, loading: false, error: undefined, loaded: true
      };
    }

    case ProjectActionTypes.DELETE_PROJECT_SOCIALS_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.GET_PROJECT_HISTORY: {
      return ReturnLoading(state);
    }




    case ProjectActionTypes.ADD_COMPANY_ROLE: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.ADD_COMPANY_ROLE_SUCCESS: {
      const project = {updated_at: undefined, ...state.details};
      if (!project.Project_Roles) {
        const array = [];
        array.push(action.payload);
        project.Project_Roles = array;
      } else {
        project.Project_Roles = [...project.Project_Roles, action.payload];
      }
      project.updated_at = action.payload.updated_at;
      return {...state, details: project, loading: false, error: undefined, loaded: true, success: true};
    }

    case ProjectActionTypes.ADD_COMPANY_ROLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.UPDATE_COMPANY_ROLE: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.UPDATE_COMPANY_ROLE_SUCCESS: {
      const projectRoleIndex = state.details.Project_Roles.findIndex(x => x.project_company_role_code === action.payload.project_company_role_code);
      const projectRoles = state.details.Project_Roles[projectRoleIndex];
      const updatedProjectRole = {...action.payload};
      const updatedProjectRoles = {updated_at: undefined, ...state.details};

      const index = updatedProjectRoles.Project_Roles.findIndex(x => x.project_company_role_code === action.payload.project_company_role_code);
      const roles = [...updatedProjectRoles.Project_Roles];
      roles[index] = updatedProjectRole;
      updatedProjectRoles.Project_Roles = roles;
      updatedProjectRoles.updated_at = action.payload.updated_at;


      return {...state, details: updatedProjectRoles, loading: false, error: undefined, loaded: true, success: true};
    }

    case ProjectActionTypes.UPDATE_COMPANY_ROLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.DELETE_COMPANY_ROLE:
      return ReturnLoading(state);

    case ProjectActionTypes.DELETE_COMPANY_ROLE_SUCCESS: {
      const project = {
        updated_at: undefined,
        ...state.details
      };
      project.Project_Roles = project.Project_Roles.filter(item => item.project_company_role_code !== action.payload.project_role_code);
      project.updated_at = moment();

      return {
        ...state, details: project, loading: false, error: undefined, loaded: true,success:true
      };
    }

    case ProjectActionTypes.DELETE_COMPANY_ROLE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ProjectActionTypes.GET_PROJECT_HISTORY: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.GET_PROJECT_HISTORY_SUCCESS: {
      const detailProject = state.details;
      const updatedDetailProject = {...detailProject};
      updatedDetailProject.transaction_history = {...action.payload.history};

      return {
        ...state, details: updatedDetailProject, error: undefined, loading: false,
        success: true, loaded: true
      };
    }

    case ProjectActionTypes.GET_PROJECT_HISTORY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    // case ProjectActionTypes.GET_PROJECT_ACTIVITY_HISTORY: {
    //   return ReturnLoading(state);
    // }

    case ProjectActionTypes.GET_PROJECT_ACTIVITY_HISTORY_SUCCESS: {
      const detailProject = state.details;
      const updatedDetailProject = {...detailProject};

      const history = {...updatedDetailProject.transaction_history};
      history.history = [...action.payload.history.history, ...history.history];
      var hist=cloneDeep(history)
    
      var his=hist.history 
      const histo =  _.orderBy(his, [(obj) => new Date(obj.updated_on)], ['desc'])
    
      for (const elem of histo){
      
        var date
        date =new Date(elem.updated_on)
        var offset = new Date(date).getTimezoneOffset();
        date.setMinutes( date.getMinutes() - offset);
      
        elem.updated_on= date
        if(elem.detailed)
        { for (const elemn of elem.detailed){
          var dates
          dates =new Date(elemn.updated_on)
          var offset = new Date(dates).getTimezoneOffset();
          dates.setMinutes( dates.getMinutes() - offset);
          elemn.updated_on= dates
        }}
        

      }
      history.history=histo
      updatedDetailProject.transaction_history = {...history};

      return {
        ...state, details: updatedDetailProject, error: undefined, loading: false,
        success: true, loaded: true
      };
    }

    case ProjectActionTypes.GET_PROJECT_ACTIVITY_HISTORY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.SET_PROJECTS_NULL: {
      return {
        ...state, projects: {result: [], page_no: 0, page_size: 0, total_count: 0}, loading: false, error: undefined,
        success: true
      };
    }

    case ProjectActionTypes.SEARCH: {
      return ReturnLoading(state);
    }

    case ProjectActionTypes.SEARCH_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = {data: []};
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
      }
      return {
        ...state, projects: {
          result: result.data, total_count: result.data.length,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ProjectActionTypes.SEARCH_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.BULK_EDIT:
      return ReturnLoading(state);

    case ProjectActionTypes.BULK_EDIT_SUCCESS: {
      const updatedProjectList = action.payload;
      const originalProjectList = [...state.projects.result];
      for (const updatedProject of updatedProjectList) {
        const uProject = {status_name: undefined, status_code: undefined, ...updatedProject};
        uProject.country_name = uProject.country.country_name;
        uProject.country_code = uProject.country.country_code;
        uProject.status_name = uProject.status.status_name;
        uProject.status_code = uProject.status.status_code;
        const projectIndex = state.projects.result.findIndex(x => x.project_code === uProject.project_code);
        const project = state.projects.result[projectIndex];
        const newUpdatedProject = {...project, ...uProject};
        // const originalProjectList = [...state.projects.result];

        const index = originalProjectList.findIndex(x => x.project_code === uProject.project_code);
        originalProjectList[index] = newUpdatedProject;
      }
      return {
        ...state, projects: {result: originalProjectList}, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ProjectActionTypes.BULK_EDIT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProjectActionTypes.SET_UPDATED_AT_TIME: {
      const projectDetails = {updated_at: undefined, ...state.details};
      projectDetails.updated_at = action.payload;
      return {...state, loading: false, details: projectDetails, loaded: false};
    }

    case ProjectActionTypes.STATUS_CHANGED: {
      return {...state, loading: false, statusChanged: action.payload, loaded: false};
    }

    case ProjectActionTypes.STATUS_CHANGED_CLEARED: {
      return {...state, loading: false, statusChanged: undefined, loaded: false};
    }

    default:
      return state;
  }

  // function formatDataSet(columns, result) {
  //   const data = [];
  //   if (result && result.data && result.data.length > 0) {
  //       if (!Array.isArray(result.data[0])) {
  //         return(data);
  //       } else {
  //         result.data.forEach((values, id) => {
  //           data.push({});
  //           values.forEach((value, idx) => {
  //             if (columns[idx]) {
  //               data[id][columns[idx]] = value;
  //             }
  //           });
  //           if (id === result.data.length - 1) {
  //             result.data = data;
  //             return(data);
  //           }
  //         });
  //         return result;
  //       }
  //     }
  // }
}
