import { Action } from '@ngrx/store';
import { Community } from '../../models/community.model';
import { communityHistory } from '../../models/communityHistory.model';
export enum CommunityActionTypes {

  LOAD = '[COMMUNITY] Load Action',
  LOAD_SUCCESS = '[COMMUNITY] Load Success Action',
  LOAD_FAILURE = '[COMMUNITY] Load Failure Action',

  GET_SINGLE_COMPANY = '[COMMUNITY] Get Single Company Action',
  GET_SINGLE_COMPANY_SUCCESS = '[COMMUNITY] Get Single Company Success Action',
  GET_SINGLE_COMPANY_FAILURE = '[COMMUNITY] Get Single Company Failure Action',

  UPDATE_STATUS = '[COMMUNITY] Update Status Action',
  UPDATE_STATUS_SUCCESS = '[COMMUNITY] Update Status Success Action',
  UPDATE_STATUS_FAILURE = '[COMMUNITY] Update Status Failure Action',

  GET_COMMUNITY_HISTORY = '[COMMUNITY] Get Community History Action',
  GET_COMMUNITY_HISTORY_SUCCESS = '[COMMUNITY] Get Community History Success Action',
  GET_COMMUNITY_HISTORY_FAILURE = '[COMMUNITY] Get Community History Failure Action',


  FILTER = '[COMMUNITY] Filter Action',
  FILTER_SUCCESS = '[COMMUNITY] Filter Success Action',
  FILTER_FAILURE = '[COMMUNITY] Filter Failure Action',

}

// export class CommunityLoad implements Action {
//     readonly type = CommunityActionTypes.LOAD;

//     constructor(public payload?: { page_size?: number, page_no?: number, }) {
//     }
// }


export class CommunityLoad implements Action {
  readonly type = CommunityActionTypes.LOAD;

  constructor(public payload?: { pgsize?: number, pgnum?: number}) {
  }
}




export class CommunityLoadSuccess implements Action {
  readonly type = CommunityActionTypes.LOAD_SUCCESS;

  constructor(public payload: { data?: [], total_count: number, pgsize: number, pgnum: number }) {
  }
}

export class CommunityLoadFailure implements Action {
  readonly type = CommunityActionTypes.LOAD_FAILURE;

  constructor(public payload: Error) {
  }
}

export class CommunityGetSingleCompany implements Action {
  readonly type = CommunityActionTypes.GET_SINGLE_COMPANY;

  constructor(public payload: { contactCode: string }) {
  }
}

export class CommunityGetSingleCompanySuccess implements Action {
  readonly type = CommunityActionTypes.GET_SINGLE_COMPANY_SUCCESS;
  // contactCode: string, IT MAY USE INSIDE BELOW PAYLOAD
  constructor(public payload: {  communitySingle: Community }) {
  }
}

export class CommunityGetSingleCompanyFailure implements Action {
  readonly type = CommunityActionTypes.GET_SINGLE_COMPANY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class GetCommunityHistory implements Action {
  readonly type = CommunityActionTypes.GET_COMMUNITY_HISTORY;

  constructor(public payload: { Code: string }) {
  }
}

export class GetCommunityHistorySuccess implements Action {
  readonly type = CommunityActionTypes.GET_COMMUNITY_HISTORY_SUCCESS;
  // companyCode: string, IT MAY USE INSIDE BELOW PAYLOAD
  constructor(public payload: { communityHistory: communityHistory }) {

  }
}

export class GetCommunityHistoryFailure implements Action {
  readonly type = CommunityActionTypes.GET_COMMUNITY_HISTORY_FAILURE;

  constructor(public payload: Error) {
  }
}

export class UpdateStatus implements Action {
  readonly type = CommunityActionTypes.UPDATE_STATUS;

  constructor(public payload: { code: string, updateStatusOf: {} }) {
  }
}

export class UpdateStatusSuccess implements Action {
  readonly type = CommunityActionTypes.UPDATE_STATUS_SUCCESS;

  constructor(public payload: {
    company_approval_code: any,
    message: string,
    updated_at: any,
    request_status: any,
    verified_by:string
  }) {
  }
}

export class UpdateStatusFailure implements Action {
  readonly type = CommunityActionTypes.UPDATE_STATUS_FAILURE;

  constructor(public payload: Error) {
  }
}
export class CommunityFilter implements Action {
  readonly type = CommunityActionTypes.FILTER;

  constructor(public payload: {
    pgsize?: number, pgnum?: number, status_type?:string,
    company?: string,
    created_at_start?: any,
    created_at_end?: any,
    email?: string,
    request_type?: string,
    verified_by?: string,
  }) {
  }
}

export class CommunityFilterSuccess implements Action {
  readonly type = CommunityActionTypes.FILTER_SUCCESS;
  constructor(public payload: { data?: [], total_count: number, pgsize: number, pgnum: number }) {
  }
}

export class CommunityFilterFailure implements Action {
  readonly type = CommunityActionTypes.FILTER_FAILURE;

  constructor(public payload: Error) {
  }
}
export type CommunityActions = CommunityLoad | CommunityLoadSuccess | CommunityLoadFailure |
  CommunityGetSingleCompany | CommunityGetSingleCompanySuccess | CommunityGetSingleCompanyFailure |
  GetCommunityHistory | GetCommunityHistorySuccess | GetCommunityHistoryFailure | UpdateStatus | UpdateStatusSuccess | UpdateStatusFailure |
  CommunityFilter | CommunityFilterSuccess | CommunityFilterFailure;