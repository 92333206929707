// Actions
import { ContactActions, ContactActionTypes } from '../actions/contact.actions';

// Models
import { Contact } from '../../models/contact.model';
import { formatDataSet } from '../../../views/common/base.component';
import { CompanyActionTypes } from '../actions/company.actions';
import { ProjectActionTypes } from '../actions/project.actions';
import * as moment from 'moment';
import { dataBinding } from '@syncfusion/ej2-angular-schedule';
import { isOnIdentifyEffects } from '@ngrx/effects/src/lifecycle_hooks';
import * as _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import { pull } from "lodash";
import { arraysAreNotAllowedMsg } from '@ngrx/store/src/models';
import { newArray } from '@angular/compiler/src/util';
export interface ContactState {
  contacts: {
    result: Contact[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: {
    isLoaded: boolean,
    isForm: string;
  };
  db?: {
    result: Contact[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  details?: Contact;
  statusChanged?: any;
  general?: any;
  companyContacts?: any;
}

export const initialState: ContactState = {
  contacts: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
  success: {
    isLoaded: false,
    isForm: ''
  },
};

export const ReturnLoading = (State: ContactState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: {
      isLoaded: false,
      isForm: ''
    },
  };
};

export function contactReducer(state: ContactState = initialState, action: ContactActions): ContactState {
  switch (action.type) {

    case ContactActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.LOAD_SUCCESS: {
      const result: any = formatDataSet(action.payload.columns, { ...action.payload });

      state = {
        ...state, contacts: {
          result: result.data, page_no: action.payload.page_no,
          page_size: action.payload.page_size, total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true, db:
        {
          result: result.data,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };

      return state;
    }

    case ContactActionTypes.LOAD_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.ADD: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.ADD_SUCCESS: {

      let stateCopy;
      if (action.payload.currentScreen === 'project') {
        stateCopy = { result: [...state.contacts.result] };
      } else {
        const result = {
          country_name: undefined, article_count: undefined,

          ...action.payload.contact
        };
        if (action.payload.contact.company) {
          result.company_name = result.company.company_name;
          result.country_name = result.company.country.country_name;
          result.article_count = 0;
        }
        stateCopy = { result: [result, ...state.contacts.result], total_count: state.contacts.total_count + 1, page_no: state.contacts.page_no, page_size: state.contacts.page_size };
      }

      return {
        ...state, loading: false, contacts: stateCopy, error: undefined, loaded: true,
        success: { isForm: 'ADD_CONTACT', isLoaded: true }
      };
    }

    case ContactActionTypes.ADD_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.UPDATE: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.UPDATE_SUCCESS: {
      const updatedContact = {
        is_display_yn: undefined,
        rank_order: undefined,
        ...action.payload.contact
      };
      
      const stateDetails = { ...state.details };
      var code = stateDetails.company_contact_code
      var rank = 0
      updatedContact.associatedCompanies= stateDetails.associatedCompanies;
    if (updatedContact.associatedCompanies && updatedContact.associatedCompanies.result && updatedContact.associatedCompanies.result.length > 0){
      const indexOf=updatedContact.associatedCompanies.result.findIndex(item => item.company_contact_code === code);
      rank=updatedContact.associatedCompanies.result[indexOf].rank_order}
      

      const updatedCompany = {
        company_contact_code: updatedContact.company_contact_code ? updatedContact.company_contact_code : null,
        company: updatedContact.company ? updatedContact.company : null,
        designation: updatedContact.designation ? updatedContact.designation : null,
        tel_no: updatedContact.tel_no ? updatedContact.tel_no : null,
        start_date: updatedContact.start_date ? updatedContact.start_date : null,
        end_date: updatedContact.end_date ? updatedContact.end_date : null,
        email: updatedContact.email ? updatedContact.email : null,
        is_primary_yn: action.payload.contact ? action.payload.contact.is_primary_yn : null,
        updated_at: updatedContact.updated_at,
        created_at: updatedContact.created_at,
        is_display_yn: 1,
        rank_order: rank,

      };


      if (updatedContact.associatedCompanies && updatedContact.associatedCompanies.result && updatedContact.associatedCompanies.result.length > 0) {
        const primaryCompanyIndex = updatedContact.associatedCompanies.result.findIndex(item => item.is_display_yn === 1);


        let associatedCompanies: any = { ...updatedContact.associatedCompanies };
        const resultsCopy = [...associatedCompanies.result];
        resultsCopy[primaryCompanyIndex] = updatedCompany;
        associatedCompanies = { ...associatedCompanies, result: resultsCopy };
        updatedContact.associatedCompanies = associatedCompanies;
      } else {
        const associatedCompanies = [];
        associatedCompanies.push(updatedCompany);

        updatedContact.associatedCompanies = { result: associatedCompanies, total_count: 1 };
      }


      updatedContact.activity = stateDetails.activity;
      
      return {
        ...state, details: updatedContact, error: undefined, loading: false, loaded: true,
        success: { isForm: 'UPDATE_CONTACT', isLoaded: true }
      };
    }

    case ContactActionTypes.UPDATE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case ContactActionTypes.DELETE:
      return ReturnLoading(state);

    case ContactActionTypes.DELETE_SUCCESS: {
      return {
        ...state, contacts: { result: state.contacts.result.filter(item => item.contact_code !== action.payload.contactCode) },
        loading: false, error: undefined, loaded: true, success: { isForm: action.payload.contactCode, isLoaded: true }
      };
    }

    case ContactActionTypes.DELETE_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case ContactActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.FILTER_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = { data: [] };
      } else {
        result = formatDataSet(action.payload.columns, { ...action.payload });
      }

      return {
        ...state, contacts: {
          result: result.data, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true,
        success: { isForm: 'contact', isLoaded: true }
      };
    }

    case ContactActionTypes.FILTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.FILTER_RESET: {
      return { ...state, contacts: { ...state.db }, loading: false, error: undefined, loaded: true };
    }

    case ContactActionTypes.GET_SINGLE_CONTACT: {
      return {
        ...state,
        details: null,
        loading: true,
        loaded: false,
        error: undefined,
        success: {
          isLoaded: false,
          isForm: ''
        },
      };
    }

    case ContactActionTypes.GET_SINGLE_CONTACT_SUCCESS: {
      // const contactIndex = state.contacts.findIndex(x => x.contact_code === action.payload.contactCode);
      // const contact = state.contacts[contactIndex];
      // const updatedContact = { ...contact, ...action.payload.contact };
      // const updatedContacts = [...state.contacts];
      //
      // const index = updatedContacts.findIndex(x => x.contact_code === action.payload.contactCode);
      // updatedContacts[index] = updatedContact;

      return {
        ...state, details: action.payload.contact, error: undefined, loading: false,
        loaded: true
      };
    }

    case ContactActionTypes.GET_SINGLE_CONTACT_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.GET_CONTACT_ACTIVITY: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.GET_CONTACT_ACTIVITY_SUCCESS: {
      const contactDetails = { ...state.details };
      contactDetails.activity = [...action.payload.activity];

      return {
        ...state, details: contactDetails, error: undefined, loading: false,
        success: { isForm: 'LOAD_CONTACT', isLoaded: true }, loaded: true
      };
    }

    case ContactActionTypes.GET_CONTACT_ACTIVITY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.GET_CONTACT_ACTIVITY_HISTORY_SUCCESS: {
      const detailsContact = state.details;
      const updatedDetailContact = { ...detailsContact };

      const history = { ...updatedDetailContact.transaction_history };
      history.history = [...action.payload.history.history, ...history.history];
      updatedDetailContact.transaction_history = { ...history };

      return {
        ...state, details: updatedDetailContact, error: undefined, loading: false,
        success: { isLoaded: true, isForm: 'Company' }, loaded: true
      };
    }

    case ContactActionTypes.GET_CONTACT_ACTIVITY_HISTORY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.ADD_CONTACT_ACTIVITY:
      return ReturnLoading(state);

    case ContactActionTypes.ADD_CONTACT_ACTIVITY_SUCCESS: {
      const contactDetails = { ...state.details };
      if (!contactDetails.activity) {
        const array = [];
        array.push(action.payload.activity);
        contactDetails.activity = array;
      } else {
        contactDetails.activity = [action.payload.activity, ...contactDetails.activity];
      }
      return {
        ...state, details: contactDetails, loading: false,
        loaded: true, success: { isLoaded: true, isForm: 'contact' }, error: undefined
      };
    }

    case ContactActionTypes.ADD_CONTACT_ACTIVITY_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case ContactActionTypes.DELETE_CONTACT_ACTIVITY:
      return ReturnLoading(state);

    case ContactActionTypes.DELETE_CONTACT_ACTIVITY_SUCCESS: {
      const contactDetails = { ...state.details };
      contactDetails.activity = contactDetails.activity.filter(item => item.activity[0].activity_code !==
        action.payload.activity_code);
      return {
        ...state, details: contactDetails,
        loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.DELETE_CONTACT_ACTIVITY_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case ContactActionTypes.UPDATE_CONTACT_ACTIVITY:
      return ReturnLoading(state);

    case ContactActionTypes.UPDATE_CONTACT_ACTIVITY_SUCCESS: {
      const contactActivityIndex = state.details.activity.findIndex(x => x.activity[0].activity_code === action.payload.activity_code);
      const contactActivity = state.details.activity[contactActivityIndex];
      const updatedContactActivity = { ...action.payload.activity };
      const updatedContactDetails = { ...state.details };

      const index = updatedContactDetails.activity.findIndex(x => x.activity[0].activity_code === action.payload.activity_code);
      const contactActivityCopy = [...updatedContactDetails.activity];
      contactActivityCopy[index] = updatedContactActivity;
      updatedContactDetails.activity = contactActivityCopy;
      return {
        ...state, details: updatedContactDetails, error: undefined, loading: false, loaded: true,
        success: {
          isLoaded: true, isForm: 'contact'
        }
      };
    }

    case ContactActionTypes.UPDATE_CONTACT_ACTIVITY_FAILURE:
      return { ...state, error: action.payload, loading: false, loaded: false };

    case ContactActionTypes.SET_SUCCESS_NULL:
      return ReturnLoading(state);

    case ContactActionTypes.ACTIVITY_FILTER: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.ACTIVITY_FILTER_SUCCESS: {
      const contactDetails = { ...state.details };
      const resultActivity = [...action.payload];

      for (const activity in resultActivity) {
        const updated_by = { ...resultActivity[activity].updated_by };
        if (resultActivity[activity].contact_activity) {
          resultActivity[activity] = resultActivity[activity].contact_activity;

          delete resultActivity[activity].contact_activity;
        } else if (resultActivity[activity].company_activity) {
          resultActivity[activity] = resultActivity[activity].company_activity;
          delete resultActivity[activity].company_activity;
        } else if (resultActivity[activity].project_activity) {
          resultActivity[activity] = resultActivity[activity].project_activity;
          delete resultActivity[activity].project_activity;
        }
        const act = { ...resultActivity[activity] };
        act.updated_by = updated_by;
        resultActivity[activity] = act;

      }
      contactDetails.activity = resultActivity;

      return {
        ...state, details: contactDetails, loading: false, error: undefined, loaded: true, success: {
          isForm: 'contact', isLoaded: true
        }
      };
    }

    case ContactActionTypes.ACTIVITY_FILTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.GET_CONTACT_HISTORY: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.GET_CONTACT_HISTORY_SUCCESS: {
      const detailContact = state.details;
      const updatedDetailContact = { ...detailContact };
      updatedDetailContact.transaction_history = { ...action.payload.history };

      return {
        ...state, details: updatedDetailContact, error: undefined, loading: false,
        success: { isForm: 'LOAD_COMPANY', isLoaded: true }, loaded: true
      };
    }

    case ContactActionTypes.GET_CONTACT_HISTORY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.SET_CONTACTS_NULL: {
      return {
        ...state, contacts: { result: [], page_no: 0, page_size: 0, total_count: 0 }, loading: false, error: undefined,
        success: { isForm: '', isLoaded: false }
      };
    }

    case ContactActionTypes.GET_PROJECTS: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.GET_PROJECTS_SUCCESS: {
      const updatedDetails = { ...state.details };
      let result;
      if (action.payload.data.length === 0) {
        result = { result: [] };
        updatedDetails.projects = result;
      } else {
        result = formatDataSet(action.payload.columns, { ...action.payload });
        result = { result: result.data };
        updatedDetails.projects = result;
      }
      // updatedDetails.projects.count = action.payload.count;

      return {
        ...state, details: updatedDetails, loading: false, error: undefined,
        success: { isForm: '', isLoaded: false }
      };
    }

    case ContactActionTypes.GET_PROJECTS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.PROJECT_FILTER: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.PROJECT_FILTER_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = { data: [] };
      } else {
        result = formatDataSet(action.payload.columns, { ...action.payload });
      }
      const updatedDetails = { ...state.details };
      updatedDetails.projects = { result: result.data, count: result.data.length };
      return { ...state, details: updatedDetails, loading: false, error: undefined, loaded: true, success: { isForm: '', isLoaded: false } };
    }

    case ContactActionTypes.PROJECT_FILTER_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.SEARCH: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.SEARCH_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = { data: [] };
      } else {
        result = formatDataSet(action.payload.columns, { ...action.payload });
      }

      return {
        ...state, contacts: {
          result: result.data, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true,
        success: { isForm: 'contact', isLoaded: true }
      };
    }

    case ContactActionTypes.SEARCH_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.RESET: {
      return { ...state, contacts: null, loading: false, error: undefined, loaded: false };
    }

    case ContactActionTypes.SEARCH_CONTACT:
      return ReturnLoading(state);


    case ContactActionTypes.SEARCH_CONTACT_SUCCESS: {
      return {
        ...state, contacts: { result: action.payload.result }, error: undefined, loading: false,
        success: { isLoaded: true, isForm: 'Company' }, loaded: true
      };
    }

    case ContactActionTypes.SEARCH_CONTACT_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.GET_ALL_COMPANY_CONTACTS: {
      return {
        ...state, loading: true, error: undefined, contacts: {
          result: [], total_count: 0
        }, success: {
          isLoaded: false,
          isForm: ''
        },
      };
    }

    case ContactActionTypes.GET_ALL_COMPANY_CONTACTS_SUCCESS: {
      let company = { ...action.payload.company };
      delete company.company_contact;

      const contactsArray = [];
      const companyContacts = { ...action.payload.company };
      if (companyContacts.company_contact) {
        for (const companyContact of companyContacts.company_contact) {
          let contact: any = {
            company,
            ...companyContact.contact
          };
          // delete companyContact['contact'];
          contact = { ...companyContact, ...contact };
          delete contact.contact;

          contactsArray.push(contact);
        }
      }

      // let result;
      // if (action.payload.data.length === 0) {
      //   result = {data: []};
      // } else {
      //   result = formatDataSet(action.payload.columns, {...action.payload});
      // }


      return {
        ...state, companyContacts: {
          result: contactsArray, total_count: contactsArray.length
        }, loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.GET_ALL_COMPANY_CONTACTS_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.GET_ALL_ASSOCIATED_COMPANIES:
      return ReturnLoading(state);

    case ContactActionTypes.GET_ALL_ASSOCIATED_COMPANIES_SUCCESS: {
      const contactDetails = {
        associatedCompanies: { result: {} },
        ...state.details
      };

      const companiesList = [];
      for (const company of action.payload.result) {
        if (company.associated_companies) {
          for (const associatedCompany of company.associated_companies) {
            // if (associatedCompany.is_primary_yn === 0) {
            companiesList.push(associatedCompany);
            // }
          }
        }
      }

      const aCompanies = {
        result: companiesList,
        total_count: companiesList.length,
        page_size: action.payload.page_size,
        page_no: action.payload.page_no,
      };
      
      contactDetails.associatedCompanies = aCompanies;

      // contactDetails.associatedCompanies.result = companiesList;
      // contactDetails.associatedCompanies.total_count = companiesList.length;
      // contactDetails.associatedCompanies.page_size = action.payload.page_size;
      // contactDetails.associatedCompanies.page_no = action.payload.page_no;

      return {
        ...state, details: contactDetails, loading: false, error: undefined, loaded: true,
        success: { isForm: 'contact', isLoaded: true }
      };
    }

    case ContactActionTypes.GET_ALL_ASSOCIATED_COMPANIES_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_RANK:
      return ReturnLoading(state);

    case ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_RANK_SUCCESS: {

      let currentRankDetails: any = []
      currentRankDetails = [...state.details.associatedCompanies.result]

      var newState = cloneDeep(state.details)

      let updatedRankOrder: any = [];
      updatedRankOrder = action.payload

      var newArray1 = JSON.parse(JSON.stringify(currentRankDetails))

      for (var i = 0; i <= state.details.associatedCompanies.result.length - 1; i++) {
        for (const element of updatedRankOrder) {
          if (newArray1[i].company_contact_code == element.company_contact_code) {
            newArray1[i].rank_order = element.rank_order
          }
        }
      }
      newArray1 = _.orderBy(newArray1, 'rank_order', 'desc');



      newState.associatedCompanies.result = newArray1
      

      return {
        ...state, details: newState, loading: false, error: undefined, loaded: true,
      };



    }

    case ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_RANK_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.ADD_ASSOCIATED_COMPANY:
      return ReturnLoading(state);

    case ContactActionTypes.ADD_ASSOCIATED_COMPANY_SUCCESS: {
      let contactDetails: any = {
        is_primary_yn: undefined, end_date: undefined, start_date: undefined, company_contact_code: undefined, is_display_yn: undefined,
        ...state.details
      };

      let associatedCompanies;
      // contactDetails = {...action.payload.company};

      if (action.payload.company.is_primary_yn === 0) {

        if (!contactDetails.associatedCompanies) {
         
          const array = [];
          array.push(action.payload.company);
          associatedCompanies = { ...contactDetails.associatedCompanies };
          associatedCompanies.result = array;

          associatedCompanies.total_count = 1;
          

        } else {
          associatedCompanies = { ...contactDetails.associatedCompanies };
          associatedCompanies.result = [action.payload.company, ...contactDetails.associatedCompanies.result];
          associatedCompanies.total_count = associatedCompanies.result.length;
        }
        contactDetails.associatedCompanies = associatedCompanies;
      } else {
        if (!contactDetails.associatedCompanies) {

        
          const array = [];
          array.push({
            company_contact_code: contactDetails.company_contact_code, designation: contactDetails.designation,
            tel_no: contactDetails.tel_no, is_primary_yn: 1, email: contactDetails.email,
            start_date: contactDetails.start_date, end_date: contactDetails.end_date, company: contactDetails.company, rank_order: contactDetails.rank_order
          });
          array.push(action.payload.company);
          associatedCompanies = { ...contactDetails.associatedCompanies };
          associatedCompanies.result = array;
          associatedCompanies.total_count = 1;
        } else {
          
          var copyArray = []
          copyArray = cloneDeep(contactDetails.associatedCompanies.result)
          associatedCompanies = { ...contactDetails.associatedCompanies };
          associatedCompanies.result = associatedCompanies.result.filter(item => item.company_contact_code !== contactDetails.company_contact_code);
          if (!contactDetails.company_contact_code) {
            associatedCompanies.result = [action.payload.company];
          } else {
            associatedCompanies.result = [{
              company_contact_code: contactDetails.company_contact_code, designation: contactDetails.designation,
              tel_no: contactDetails.tel_no, is_primary_yn: 1, email: contactDetails.email, is_display_yn: contactDetails.is_display_yn,
              start_date: contactDetails.start_date, end_date: contactDetails.end_date, company: contactDetails.company, rank_order: contactDetails.rank_order
            }, ...associatedCompanies.result, action.payload.company];
          }

          associatedCompanies.total_count = associatedCompanies.result.length;
        }
        contactDetails.associatedCompanies = associatedCompanies;
        var resultCopy = cloneDeep(action.payload.company)
        copyArray.push(resultCopy)
        var array = []
        array = copyArray
        array = _.orderBy(array, 'rank_order', 'desc')
        contactDetails.associatedCompanies.result = array
      }

      if (action.payload.company.is_display_yn == '1') {

        if (contactDetails.associatedCompanies) {
          
          
          const oldDisplayCompany = { ...contactDetails.associatedCompanies.result.find((associateCompany) => associateCompany.company_contact_code === contactDetails.company_contact_code) };
          oldDisplayCompany.is_display_yn = 0;
          const oldDisplayCompanyIndex = contactDetails.associatedCompanies.result.findIndex((associateCompany) => associateCompany.company_contact_code === contactDetails.company_contact_code);
          contactDetails.associatedCompanies.result[oldDisplayCompanyIndex] = oldDisplayCompany;
          // contactDetails.associatedCompanies = associatedCompanies;
        }
        contactDetails.designation = null;
        contactDetails.company = null;
        contactDetails.tel_no = null;
        contactDetails = { ...contactDetails, ...action.payload.company };
     

      }


      contactDetails.updated_at = moment().format();

     
      
      return {
        ...state, details: contactDetails, loading: false,
        loaded: true, success: { isLoaded: true, isForm: 'contact' }, error: undefined
      };
    }

    case ContactActionTypes.ADD_ASSOCIATED_COMPANY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.UPDATE_ASSOCIATED_COMPANY:
      return ReturnLoading(state);

    case ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_SUCCESS: {
      if (action.payload.page === 'contacts') {
        let associatedCompanies;

        const contactAssociatedCompanyIndex = state.details.associatedCompanies.result.findIndex(x => x.company_contact_code === action.payload.company.company_contact_code);
        const contactAssociatedCompany = state.details.associatedCompanies[contactAssociatedCompanyIndex];
        const updatedContactAssociated = {
          ...contactAssociatedCompany,
          ...action.payload.company
        };
        let updatedContactDetails = {
          is_primary_yn: undefined,
          end_date: undefined,
          start_date: undefined,
          company_contact_code: undefined,
          rank_order: undefined,
          ...state.details
        };


        // if (action.payload.company.is_primary_yn === 0) {
        const index = updatedContactDetails.associatedCompanies.result.findIndex(x => x.company_contact_code === action.payload.oldCompanyContactCode);


        const associatedCompaniesCopy = { ...updatedContactDetails.associatedCompanies };

        if (action.payload.company.is_display_yn === 1) {
          const previousPrimaryCompany = associatedCompaniesCopy.result.findIndex(item => item.is_display_yn === 1);
          const primaryCompany = { ...associatedCompaniesCopy.result[previousPrimaryCompany] };
          primaryCompany.is_display_yn = 0;
          const previousPCompany = [...associatedCompaniesCopy.result];
          previousPCompany[previousPrimaryCompany] = primaryCompany;

          associatedCompaniesCopy.result = previousPCompany;
        }
        const contactAssociateCompanyCopy = [...associatedCompaniesCopy.result];

        // contactAssociateCompanyCopy[index] = updatedContactAssociated;
        contactAssociateCompanyCopy.push(updatedContactAssociated);
        contactAssociateCompanyCopy.splice(index, 1);
        associatedCompaniesCopy.result = contactAssociateCompanyCopy;


        if (action.payload.company.is_display_yn === 1) {
          updatedContactDetails.designation = null;
          updatedContactDetails.company = null;
          updatedContactDetails.tel_no = null;
          updatedContactDetails.email = null;
          updatedContactDetails.start_date = null;
          updatedContactDetails.end_date = null;
          updatedContactDetails.end_date = null;
          updatedContactDetails.rank_order = null


          updatedContactDetails = { ...updatedContactDetails, ...action.payload.company };
        }
        updatedContactDetails.associatedCompanies = associatedCompaniesCopy;





        // } else {
        //   associatedCompanies = {...updatedContactDetails.associatedCompanies};
        //   associatedCompanies.result = [{
        //     company_contact_code: updatedContactDetails.company_contact_code, designation: updatedContactDetails.designation,
        //     tel_no: updatedContactDetails.tel_no, is_primary_yn: 0, email: updatedContactDetails.email,
        //     start_date: updatedContactDetails.start_date, end_date: updatedContactDetails.end_date, company: updatedContactDetails.company
        //   }, ...updatedContactDetails.associatedCompanies.result];
        //   associatedCompanies.result = associatedCompanies.result.filter(item => item.company_contact_code !==
        //     action.payload.company.company_contact_code);
        //   updatedContactDetails.associatedCompanies = associatedCompanies;
        //   updatedContactDetails.company = null;
        //   updatedContactDetails = {...updatedContactDetails, ...action.payload.company};
        // }

        updatedContactDetails.updated_at = moment().format();

        var array = cloneDeep(updatedContactDetails.associatedCompanies.result)

        array = _.orderBy(array, 'rank_order', 'desc');

        updatedContactDetails.associatedCompanies.result = array
        
        return {
          ...state, details: updatedContactDetails, loading: false,
          loaded: true, success: { isLoaded: true, isForm: 'contact' }, error: undefined
        };
      } else if (action.payload.page === 'company') {
        //check if companies match
        //if not, remove contactcode from list
        const contact = state.companyContacts.result ? state.companyContacts.result.find(item => item.contact_code === action.payload.company.contact_code) : null;
        if (contact && action.payload.company.company && contact.company.company_code !== action.payload.company.company.company_code) {
          const updatedState = state.companyContacts.result.filter(item => item.contact_code !==
            action.payload.company.contact_code);
          
          return {
            ...state, companyContacts: { result: updatedState }, loading: false,
            loaded: true, success: { isLoaded: true, isForm: 'contact' }, error: undefined
          };
        } else if (!action.payload.company.company) {
          const updatedState = state.companyContacts.result.filter(item => item.contact_code !==
            action.payload.company.contact_code);
        

          return {
            ...state, companyContacts: { result: updatedState }, loading: false,
            loaded: true, success: { isLoaded: true, isForm: 'contact' }, error: undefined
          };
        } else {
          const companyOrderIndex = state.companyContacts.result.findIndex(x => x.company_contact_code === action.payload.company.company_contact_code);
          const companyOrder = state.companyContacts[companyOrderIndex];
          const updatedCompanyOrder = { ...companyOrder, ...action.payload.company };
          const updatedCompanyDetails = { ...state.companyContacts };

          const index = updatedCompanyDetails.result.findIndex(x => x.company_contact_code === action.payload.company.company_contact_code);
          const projectOrdersResultCopy = [...updatedCompanyDetails.result];
          projectOrdersResultCopy[index] = updatedCompanyOrder;

          const projectOrdersCopy: any = { ...updatedCompanyDetails };

          // projectOrdersCopy.result = projectOrdersResultCopy;
          // updatedCompanyDetails = projectOrdersCopy;
          return {
            ...state, companyContacts: { result: projectOrdersResultCopy }, loading: false,
            loaded: true, success: { isLoaded: true, isForm: 'contact' }, error: undefined
          };
        }
      }
      break;
    }

    case ContactActionTypes.UPDATE_ASSOCIATED_COMPANY_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.DELETE_ASSOCIATED_COMPANY:
      return ReturnLoading(state);

    case ContactActionTypes.DELETE_ASSOCIATED_COMPANY_SUCCESS: {

      const contactDetails = {
        is_display_yn: undefined,
        ...state.details
      };
      const associatedCompanies = { ...contactDetails.associatedCompanies };
      if (associatedCompanies.result) {
        associatedCompanies.result = contactDetails.associatedCompanies.result.filter(item => item.company_contact_code !==
          action.payload.company_contact_code);
        associatedCompanies.total_count = associatedCompanies.result.length;
        

        if (action.payload.company_contact_code !== action.payload.displayCompanyCompanyContactCode) {
          if (associatedCompanies.result.length > 0) {

            //  Set display company
            const displayCompany = { ...associatedCompanies.result.find((item) => item.company_contact_code === action.payload.displayCompanyCompanyContactCode) };
            contactDetails.company_contact_code = displayCompany.company_contact_code;
            contactDetails.designation = displayCompany.designation ?? null;
            contactDetails.company = displayCompany.company ?? null;
            contactDetails.start_date = displayCompany.start_date;
            contactDetails.end_date = displayCompany.end_date ?? null;
            contactDetails.email = displayCompany.email;
            contactDetails.is_primary_yn = displayCompany.is_primary_yn;
            contactDetails.is_display_yn = 1;
            displayCompany.is_display_yn = 1;
            const oldDisplayCompanyIndex = associatedCompanies.result.findIndex((associateCompany) => associateCompany.company_contact_code === contactDetails.company_contact_code);
            associatedCompanies.result[oldDisplayCompanyIndex] = displayCompany;
          }
        } else {
          contactDetails.company_contact_code = null;
          contactDetails.designation = null;
          contactDetails.company = null;
          contactDetails.start_date = null;
          contactDetails.end_date = null;
          contactDetails.email = null;
          contactDetails.is_primary_yn = null;
          contactDetails.is_display_yn = 1;

        }
        var len = associatedCompanies.result.length;
        var count = len
        for (const element of associatedCompanies.result) {
          var elem = element.rank_order
        }
        if (elem != 0) {
          for (var j = 0; j <= parseInt(len) - 1; j++) {
            var rankOrderField = cloneDeep(associatedCompanies.result[j]);
            rankOrderField.rank_order = count;
            associatedCompanies.result[j] = rankOrderField;
            
            count = count - 1;
          }
        }
        contactDetails.associatedCompanies = associatedCompanies;
        


        contactDetails.associatedCompanies = associatedCompanies;
        contactDetails.updated_at = moment().format();
        
      }

      return {
        ...state, details: contactDetails, success: { isForm: action.payload.company_contact_code, isLoaded: true },
        loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.DELETE_ASSOCIATED_COMPANY_FAILURE: {

      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.DELETE_COMPANY_CONTACT_LINK: {
      return ReturnLoading(state);
    }

    case ContactActionTypes.DELETE_COMPANY_CONTACT_LINK_SUCCESS: {
      return {
        ...state,
        companyContacts: { result: state.companyContacts.result.filter(item => item.company_contact_code !== action.payload.company_contact_code) },
        loading: false,
        error: undefined,
        loaded: true,
        success: { isForm: action.payload.company_contact_code, isLoaded: true }
      };
    }

    case ContactActionTypes.DELETE_COMPANY_CONTACT_LINK_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.PROJECT_BULK_EDIT:
      return ReturnLoading(state);
    case ContactActionTypes.PROJECT_BULK_EDIT_SUCCESS: {
      const updatedProjectList = action.payload;
      const originalProjectList = [...state.details.projects.result];
      for (const updatedProject of updatedProjectList) {
        const uProject = { status_name: undefined, status_code: undefined, ...updatedProject };
        uProject.country_name = uProject.country.country_name;
        uProject.country_code = uProject.country.country_code;
        uProject.status_name = uProject.status.status_name;
        uProject.status_code = uProject.status.status_code;

        const projectIndex = state.details.projects.result.findIndex(x => x.project_code === uProject.project_code);
        const project = state.details.projects.result[projectIndex];
        const newUpdatedProject = { ...project, ...uProject };
        // const originalProjectList = [...state.projects.result];

        const index = originalProjectList.findIndex(x => x.project_code === uProject.project_code);
        originalProjectList[index] = newUpdatedProject;
      }

      const companyDetails = { ...state.details };
      
      companyDetails.projects = { result: originalProjectList, count: originalProjectList.length };
      return {
        ...state, details: companyDetails, loading: false, error: undefined, loaded: true, success: { isForm: 'LOAD_COMPANY', isLoaded: true }
      };

    }

    case ContactActionTypes.PROJECT_BULK_EDIT_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.STATUS_CHANGED: {
      return { ...state, loading: false, statusChanged: action.payload, loaded: false };
    }

    case ContactActionTypes.STATUS_CHANGED_CLEARED: {
      return { ...state, loading: false, statusChanged: undefined, loaded: false };
    }

    case ContactActionTypes.LOAD_CONTACT_GENERAL:
      return ReturnLoading(state);

    case ContactActionTypes.LOAD_CONTACT_GENERAL_SUCCESS: {
      const contactDetails = { description: null, ...state.details };
      if (action.payload.contact.description) {
        contactDetails.description = action.payload.contact.description;
      }

      const response = {
        ...state.general, social_media: action.payload.contact.social_media, private_tag: action.payload.contact.private_tag,
        public_tag: action.payload.contact.public_tag, description: action.payload.contact.description
      };

      return {
        ...state, general: response, details: contactDetails, success: { isForm: 'success', isLoaded: true },
        loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.LOAD_CONTACT_GENERAL_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }


    case ContactActionTypes.UPDATE_CONTACT_GENERAL:
      return ReturnLoading(state);

    case ContactActionTypes.UPDATE_CONTACT_GENERAL_SUCCESS: {
      const contactDetails = { description: null, ...state.details };
      if (action.payload.contact.description) {
        contactDetails.description = action.payload.contact.description;
      }

      return {
        ...state, general: action.payload.contact, details: contactDetails, success: { isForm: 'success', isLoaded: true },
        loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.UPDATE_CONTACT_GENERAL_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.UPDATE_CONTACT_IMAGES:
      return ReturnLoading(state);

    case ContactActionTypes.UPDATE_CONTACT_IMAGES_SUCCESS: {
      const contactDetails = { featured_image_reference_id: undefined, ...state.details };
      contactDetails.featured_image_reference_id = action.payload.contact.featured_image_reference_id;

      return {
        ...state, details: contactDetails, success: { isForm: 'success', isLoaded: true },
        loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.UPDATE_CONTACT_IMAGES_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.MERGE_CONTACT:
      return ReturnLoading(state);

    case ContactActionTypes.MERGE_CONTACT_SUCCESS: {
      let contactList = [...state.contacts.result];
      contactList = contactList.filter((contact) => contact.contact_code !== action.payload.destinationContactCode);
      contactList = contactList.filter((contact) => contact.contact_code !== action.payload.sourceContactCode);

      const result = {
        country_name: undefined, article_count: undefined,

        ...action.payload.mergedContact
      };
      if (action.payload.mergedContact.company) {
        result.company_name = result.company.company_name;
        result.country_code = action.payload.mergedContact.company.country.country_code;
        result.country_name = action.payload.mergedContact.company.country.country_name;

        result.article_count = 0;
      }

      // Add to top of list if page number is 1
      if (state.contacts.page_no === 1) {
        contactList = [result, ...contactList];
      }


      return {
        ...state, contacts: { total_count: state.contacts.total_count - 1, page_size: state.contacts.page_size, page_no: state.contacts.page_no, result: contactList }, success: { isForm: 'success', isLoaded: true },
        loading: false, error: undefined, loaded: true
      };
    }

    case ContactActionTypes.MERGE_CONTACT_FAILURE: {
      return { ...state, loading: false, error: action.payload, loaded: false };
    }

    case ContactActionTypes.UPDATE_SYNC_STATUS: {
      const contactDetails = { ...state.details };
      contactDetails.contact_last_synced = action.payload.contact_last_sync;
      return { ...state, details: contactDetails, loading: false, loaded: false };

    }


    default:
      return state;
  }
}
