// Actions
import {CompanyActions, CompanyActionTypes} from '../actions/company.actions';

// Models
import {Company} from '../../models/company.model';
import {formatDataSet} from '../../../views/common/base.component';
import {Order} from '../../models/order.model';
import * as moment from 'moment';
import {ProjectActionTypes} from '../actions/project.actions';
import {ContactActionTypes} from '../actions/contact.actions';
import cloneDeep from 'lodash.clonedeep';
import * as _ from 'lodash';
export interface CompanyState {
  companies: {
    result: Company[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  setting_value?: string,
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: {
    isLoaded?: boolean,
    isForm?: string;
  };
  db?: {
    result: Company[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  details?: Company;
  orders?: {
    result: Order[],
    total_count?: number,
    page_no?: number,
    page_size?: number,
    user_settings?: Array<any>,
    column_list?: Array<any>
  };
  orderDetails?: Order;
  search?: Company[];
  deliverables?: any;
  userSetting?:string;
  general?: any;
  LTDSettings?: any;
}

export const initialState: CompanyState = {
  companies: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
  success: {
    isLoaded: false,
    isForm: ''
  },
};

export const ReturnLoading = (State: CompanyState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: {
      isLoaded: false,
      isForm: ''
    },
  };
};

export function companyReducer(state: CompanyState = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {

    case CompanyActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.LOAD_SUCCESS: {
      const result: any = formatDataSet(action.payload.columns, {...action.payload});

      state = {
        ...state, companies: {
          result: result.data, page_no: action.payload.page_no,
          page_size: action.payload.page_size, total_count: action.payload.total_count
        }, loading: false, error: undefined, loaded: true, db:
          {
            result: result.data,
            total_count: action.payload.total_count,
            page_no: action.payload.page_no,
            page_size: action.payload.page_size,
          }
      };
      return state;
    }

    case CompanyActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.ADD: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.ADD_SUCCESS: {
      let company = {
        country_name: undefined,
        ...action.payload.company
      };
      company.published_count = 0;
      company.featured_count = 0;
      company.country_name = action.payload.company.country.country_name;

      const companiesList = {...state.companies};
      if (!companiesList.result || companiesList.result.length <= 0) {
        const array = [];
        array.push(company);
        companiesList.result = array;
      } else {
        companiesList.result = [company, ...companiesList.result];
      }

      // Object.keys(company).some((key) => {
      //   if (action.payload.company[key] && typeof company[key] === 'object') {
      //     company = {...company, ... action.payload.company[key]};
      //   }
      // });


      return {
        ...state, loading: false, companies: companiesList, error: undefined, loaded: true,
        success: {isForm: 'ADD_COMPANY', isLoaded: true}
      };
    }

    case CompanyActionTypes.ADD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.UPDATE: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.UPDATE_SUCCESS: {

      const updatedCompany = {...action.payload.company};
      const stateDetails = {...state.details};

      updatedCompany.orders = stateDetails.orders;
      updatedCompany.transaction_history = stateDetails.transaction_history;
      updatedCompany.activity = stateDetails.activity;
      updatedCompany.projects = stateDetails.projects;


      return {
        ...state, details: updatedCompany, error: undefined, loading: false, loaded: true,
        success: {isForm: 'UPDATE_COMPANY', isLoaded: true}
      };
    }

    case CompanyActionTypes.UPDATE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyActionTypes.DELETE:
      return ReturnLoading(state);

    case CompanyActionTypes.DELETE_SUCCESS: {
      return {
        ...state, companies: {result: state.companies.result.filter(item => item.company_code !== action.payload.companyCode)},
        loading: false, error: undefined, loaded: true, success: {isForm: action.payload.companyCode, isLoaded: true}
      };
    }

    case CompanyActionTypes.DELETE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyActionTypes.GET_CONTACTS:

      return {
        ...state,
        details: null,
        loading: true,
        loaded: false,
        error: undefined,
        success: {
          isLoaded: false,
          isForm: ''
        },
      };
      // return ReturnLoading(state);

    case CompanyActionTypes.GET_CONTACTS_SUCCESS: {

      // if (state.companies.length < 1) {
      //   let updatedCompanies = [];
      //   updatedCompanies.push(action.payload.company);
      //   return { ...state, companies: updatedCompanies, error: undefined, loading: false, loaded: true };
      // }
      // const selectedCompanyIndex = state.companies.findIndex(x => x.company_code === action.payload.company.company_code);
      // const company = state.companies[selectedCompanyIndex];
      // const updatedCompany = { ...company, ...action.payload.company };
      // const updatedCompanies = [...state.companies];
      // console.log(updatedCompanies);
      //
      // const index = updatedCompanies.findIndex(x => x.company_code === action.payload.company.company_code);
      // updatedCompanies[index] = updatedCompany;
      return {...state, details: action.payload.company, error: undefined, loading: false, loaded: true};
    }

    case CompanyActionTypes.GET_CONTACTS_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyActionTypes.GET_CONTACTS_WITHOUT_REFRESH:

      return ReturnLoading(state);

    case CompanyActionTypes.UPDATE_SYNC_STATUS: {

      const companyDetails = {...state.details};
      companyDetails.company_last_synced = action.payload.company_last_sync;
      return {...state, details: companyDetails, loading: false, loaded: false};

    }


    case CompanyActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.FILTER_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = {data: []};
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
      }

      return {
        ...state, companies: {
          result: result.data, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: {
          isLoaded: true, isForm: 'COMPANY'
        }
      };
    }

    case CompanyActionTypes.FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_ACTIVITY: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ACTIVITY_SUCCESS: {

      const detailCompany = state.details;
      const updatedDetailCompany = {...detailCompany};
      updatedDetailCompany.activity = Object.assign([], action.payload.activity);

      return {
        ...state, details: updatedDetailCompany, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_ACTIVITY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.ADD_COMPANY_ACTIVITY:
      return ReturnLoading(state);

    case CompanyActionTypes.ADD_COMPANY_ACTIVITY_SUCCESS: {
      const companyDetails = {...state.details};
      if (!companyDetails.activity) {
        const array = [];
        array.push(action.payload.activity);
        companyDetails.activity = array;
      } else {
        companyDetails.activity = [action.payload.activity, ...companyDetails.activity];
      }
      companyDetails.updated_at = action.payload.activity.updated_at;

      return {
        ...state, details: companyDetails, loading: false,
        loaded: true, success: {isForm: 'company', isLoaded: true}, error: undefined
      };
    }

    case CompanyActionTypes.ADD_COMPANY_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyActionTypes.DELETE_COMPANY_ACTIVITY:
      return ReturnLoading(state);

    case CompanyActionTypes.DELETE_COMPANY_ACTIVITY_SUCCESS: {
      const companyDetails = {...state.details};
      companyDetails.activity = companyDetails.activity.filter(item => item.activity[0].activity_code !==
        action.payload.activity_code);
      companyDetails.updated_at = moment();

      return {
        ...state, details: companyDetails,
        loading: false, error: undefined, loaded: true
      };
    }

    case CompanyActionTypes.DELETE_COMPANY_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyActionTypes.UPDATE_COMPANY_ACTIVITY:
      return ReturnLoading(state);

    case CompanyActionTypes.UPDATE_COMPANY_ACTIVITY_SUCCESS: {
      const companyActivityIndex = state.details.activity.findIndex(x => x.activity[0].activity_code === action.payload.activity_code);
      const companyActivity = state.details.activity[companyActivityIndex];
      const updatedCompanyActivity = {...action.payload.activity};
      const updatedCompanyDetails = {...state.details};

      const index = updatedCompanyDetails.activity.findIndex(x => x.activity[0].activity_code === action.payload.activity_code);
      const projectActivityCopy = [...updatedCompanyDetails.activity];
      projectActivityCopy[index] = updatedCompanyActivity;
      updatedCompanyDetails.activity = projectActivityCopy;
      updatedCompanyDetails.updated_at = action.payload.activity.updated_at;

      return {
        ...state, details: updatedCompanyDetails, error: undefined, loading: false, loaded: true,
        success: {isLoaded: true, isForm: 'company'}
      };
    }

    case CompanyActionTypes.UPDATE_COMPANY_ACTIVITY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyActionTypes.ACTIVITY_FILTER: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.ACTIVITY_FILTER_SUCCESS: {

      const companyDetails = {...state.details};
      const resultActivity = [...action.payload];

      for (const activity in resultActivity) {
        const updated_by = {...resultActivity[activity].updated_by};
        if (resultActivity[activity].contact_activity) {
          resultActivity[activity] = resultActivity[activity].contact_activity;

          delete resultActivity[activity].contact_activity;
        } else if (resultActivity[activity].company_activity) {
          resultActivity[activity] = resultActivity[activity].company_activity;
          delete resultActivity[activity].company_activity;
        } else if (resultActivity[activity].project_activity) {
          resultActivity[activity] = resultActivity[activity].project_activity;
          delete resultActivity[activity].project_activity;
        }
        const act = {...resultActivity[activity]};
        act.updated_by = updated_by;
        resultActivity[activity] = act;

      }
      companyDetails.activity = resultActivity;

      return {
        ...state, details: companyDetails, loading: false, error: undefined, loaded: true, success: {
          isForm: 'contact', isLoaded: true
        }
      };
    }

    case CompanyActionTypes.ACTIVITY_FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_HISTORY: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_HISTORY_SUCCESS: {
      const detailCompany = state.details;
      const updatedDetailCompany = {...detailCompany};

     const history = {...action.payload.history};
     var hist =cloneDeep(history)
     var his=hist.history
      const histo= _.orderBy(his, [(obj) => new Date(obj.updated_on)], ['desc'])
      // for (const elem of histo){

      //   var date
      //   date =new Date(elem.updated_on.replace(' ', 'T') + "Z")
      //   // var offset = new Date(date).getTimezoneOffset();
      //   // date.setMinutes( date.getMinutes() dt.getHours(); // Will be local time

      //   elem.updated_on= date.toLocaleString()

      //   if(elem.detailed)
      //   { for (const elemn of elem.detailed){
      //     var dates
      //     dates =new Date(elemn.updated_on.replace(' ', 'T') + "Z")
      //     // var offset = new Date(dates).getTimezoneOffset();
      //     // dates.setMinutes( dates.getMinutes() - offset);
      //     elemn.updated_on= dates.toLocaleString()
      //   }}


      // }
      history.history=histo
      updatedDetailCompany.transaction_history = {...history};

      return {
        ...state, details: updatedDetailCompany, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_HISTORY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.SET_SUCCESS_NULL: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.SET_ERROR_NULL: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.SET_COMPANIES_NULL: {
      return {
        ...state, companies: {result: [], page_no: 0, page_size: 0, total_count: 0}, loading: false, error: undefined,
        success: {isForm: '', isLoaded: false}
      };
    }

    case CompanyActionTypes.GET_PROJECTS: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_PROJECTS_SUCCESS: {
      const updatedDetails = {...state.details};
      let result;
      if (action.payload.data.length === 0) {
        result = {result: []};
        updatedDetails.projects = result;
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
        const totalCount = result.data === 0 ? 0 : result.data.length;
        result = {result: result.data, total_count: totalCount};
        updatedDetails.projects = result;
      }

      return {
        ...state, details: updatedDetails, loading: false, error: undefined,
        success: {isForm: '', isLoaded: false}
      };
    }

    case CompanyActionTypes.GET_PROJECTS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_SOURCE_PROJECT: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_SOURCE_PROJECT_SUCCESS: {
      const updatedDetails = {...state.details};
      let result;
      if (action.payload.data.length === 0) {
        result = {result: []};
        updatedDetails.projects = result;
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
        const totalCount = result.data === 0 ? 0 : result.data.length;
        result = {result: result.data, total_count: totalCount};
        updatedDetails.projects = result;
      }

      return {
        ...state, details: updatedDetails, loading: false, error: undefined,
        success: {isForm: '', isLoaded: false}
      };
    }

    case CompanyActionTypes.GET_SOURCE_PROJECT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.PROJECT_FILTER: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.PROJECT_FILTER_SUCCESS: {

      const updatedDetails = {...state.details};
      let result;
      if (action.payload.data.length === 0) {
        result = {result: []};
        updatedDetails.projects = result;
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
        const totalCount = result.data === 0 ? 0 : result.data.length;
        // const totalCount = result.data ? result.data.length : 0;
        result = {result: result.data, total_count: totalCount};
        updatedDetails.projects = result;
      }
      return {...state, details: updatedDetails, loading: false, error: undefined, loaded: true, success: {isForm: '', isLoaded: false}};
    }

    case CompanyActionTypes.PROJECT_FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.SEARCH: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.SEARCH_SUCCESS: {
      let result;
      if (action.payload.data.length === 0) {
        result = {data: []};
      } else {
        result = formatDataSet(action.payload.columns, {...action.payload});
      }

      return {
        ...state, companies: {
          result: result.data, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: {
          isLoaded: true, isForm: 'COMPANY'
        }
      };
    }

    case CompanyActionTypes.SEARCH_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.FILTER_RESET: {
      return {
        ...state,
        companies: {...state.db},
        loading: false,
        error: undefined,
        loaded: true,
        success: {isLoaded: true, isForm: 'company'}
      };
    }

    case CompanyActionTypes.GET_ALL_COMPANY_ORDERS: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_ALL_COMPANY_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: {
          result: action.payload.result,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
          total_count: action.payload.total_count
        },
        error: undefined,
        loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true},
        loaded: true
      };
    }

    case CompanyActionTypes.GET_ALL_COMPANY_ORDERS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.COMPANY_ORDER: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.COMPANY_ORDER_SUCCESS: {
      const detailCompany = state.details;
      const updatedDetailCompany = {...detailCompany};
      updatedDetailCompany.orders = Object.assign([], action.payload);

      return {
        ...state, details: updatedDetailCompany, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.COMPANY_ORDER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.COMPANY_DELETE_ORDER:
      return ReturnLoading(state);

    case CompanyActionTypes.COMPANY_DELETE_ORDER_SUCCESS: {
      const companyDetails = {...state.details};
      const companyOrders = {...companyDetails.orders};
      if (companyOrders && companyOrders.result) {
        // let companyOrdersResult = companyOrders.result;
        companyOrders.result =
          companyOrders.result.filter(item => item.company_order_code !==
            action.payload.company_order_code);

        companyDetails.orders = companyOrders;
      }
      companyDetails.updated_at = moment();

      return {
        ...state, details: companyDetails, success: {isForm: action.payload.company_order_code, isLoaded: true},
        loading: false, error: undefined, loaded: true
      };
    }

    case CompanyActionTypes.COMPANY_DELETE_ORDER_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyActionTypes.COMPANY_ADD_ORDER:
      return ReturnLoading(state);

    case CompanyActionTypes.COMPANY_ADD_ORDER_SUCCESS: {
      const companyDetails = {...state.details};
      if (!companyDetails.orders) {
        const array = [];
        array.push(action.payload);
        companyDetails.orders = {result: array, total_count: 1};
      } else {
        companyDetails.orders = {
          result: [action.payload, ...companyDetails.orders.result],
          total_count: (companyDetails.orders.result.length + 1)
        };
      }
      companyDetails.updated_at = moment();

      return {
        ...state, details: companyDetails, loading: false,
        loaded: true, success: {isForm: 'company', isLoaded: true}, error: undefined
      };
    }

    case CompanyActionTypes.COMPANY_ADD_ORDER_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case CompanyActionTypes.COMPANY_UPDATE_ORDER:
      return ReturnLoading(state);

    case CompanyActionTypes.COMPANY_UPDATE_ORDER_SUCCESS: {
      const companyOrderIndex = state.details.orders.result.findIndex(x => x.company_order_code === action.payload.order.company_order_code);
      const companyOrder = state.details.orders.result[companyOrderIndex];
      const updatedCompanyOrder = {...companyOrder, ...action.payload.order};
      const updatedCompanyDetails = {...state.details};

      const index = updatedCompanyDetails.orders.result.findIndex(x => x.company_order_code === action.payload.order.company_order_code);
      const projectOrdersResultCopy = [...updatedCompanyDetails.orders.result];
      projectOrdersResultCopy[index] = updatedCompanyOrder;

      const projectOrdersCopy: any = {...updatedCompanyDetails.orders};

      projectOrdersCopy.result = projectOrdersResultCopy;
      updatedCompanyDetails.orders = projectOrdersCopy;
      updatedCompanyDetails.updated_at = action.payload.order.updated_at;

      return {
        ...state, details: updatedCompanyDetails, loading: false,
        loaded: true, success: {isForm: 'company', isLoaded: true}, error: undefined
      };
    }

    case CompanyActionTypes.COMPANY_UPDATE_ORDER_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyActionTypes.UPDATE_SINGLE_COMPANY_ORDER_SUCCESS: {
      const updatedDetails = {...state.orderDetails, ...action.payload.order};

      return {
        ...state, orderDetails: updatedDetails, loading: false, loaded: false,
        success: {isForm: 'order', isLoaded: true}
      };
    }


    case CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY_SUCCESS: {

      const detailsCompany = state.details;
      const updatedDetailCompany = {...detailsCompany};

      const history = {...updatedDetailCompany.transaction_history};
      history.history = [...action.payload.history.history, ...history.history];
      var hist=cloneDeep(history)

      var his=hist.history
      const histo =  _.orderBy(his, [(obj) => new Date(obj.updated_on)], ['desc'])

      for (const elem of histo){

        var date
        date =new Date(elem.updated_on.replace(' ', 'T') + "Z")
        // var offset = new Date(date).getTimezoneOffset();
        // date.setMinutes( date.getMinutes() - offset);


        // dates =new Date(elemn.updated_on.replace(' ', 'T') + "Z")
        // var offset = new Date(dates).getTimezoneOffset();
        // dates.setMinutes( dates.getMinutes() - offset);
        elem.updated_on= date.toLocaleString()
        elem.updated_on=moment(elem.updated_on).format('MMM DD, YYYY') +' '+ date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})

        if(elem.detailed)
        { for (const elemn of elem.detailed){
          var dates
          dates =new Date(elemn.updated_on.replace(' ', 'T') + "Z")
          // var offset = new Date(dates).getTimezoneOffset();
          // dates.setMinutes( dates.getMinutes() - offset);
          elemn.updated_on= dates.toLocaleString()
          elemn.updated_on=moment(elemn.updated_on).format('MMM DD, YYYY')+' '+date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
        }}


      }
      history.history=histo

      updatedDetailCompany.transaction_history = {...history};

      return {
        ...state, details: updatedDetailCompany, error: undefined, loading: false,
        success: {isLoaded: true, isForm: 'Company'}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.COMPANY_UPDATE_ORDER_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY_SUCCESS: {

      const detailsCompany = state.details;
      const updatedDetailCompany = {...detailsCompany};

      const history = {...updatedDetailCompany.transaction_history};
      history.history = [...action.payload.history.history, ...history.history];

      updatedDetailCompany.transaction_history = {...history};

      return {
        ...state, details: updatedDetailCompany, error: undefined, loading: false,
        success: {isLoaded: true, isForm: 'Company'}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_ACTIVITY_HISTORY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    //
    case CompanyActionTypes.SEARCH_COMPANY:
      return ReturnLoading(state);


    case CompanyActionTypes.SEARCH_COMPANY_SUCCESS: {
      return {
        ...state, companies: {result: action.payload.result}, error: undefined, loading: false,
        success: {isLoaded: true, isForm: 'Company'}, loaded: true
      };
    }

    case CompanyActionTypes.SEARCH_COMPANY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_SINGLE_COMPANY_ORDER: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_SINGLE_COMPANY_ORDER_SUCCESS: {
      return {
        ...state, orderDetails: action.payload.company_order, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.GET_SINGLE_COMPANY_ORDER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      orderDetailsCopy.lineItem = action.payload.company_order;
      return {
        ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEM_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      let orderDetailsCopyResult = [...orderDetailsCopy.lineItem.result];
      orderDetailsCopyResult = [action.payload.company_order, ...orderDetailsCopyResult];

      orderDetailsCopy.lineItem = {...orderDetailsCopy.lineItem, ...{result: orderDetailsCopyResult}};
      orderDetailsCopy.updated_at = action.payload.company_order.updated_at;
      return {
        ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.ADD_COMPANY_ORDER_LINE_ITEM_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM_SUCCESS: {
      const orderLineItemIndex = state.orderDetails.lineItem.result.findIndex(x => x.order_line_item_code === action.payload.company_order.order_line_item_code);
      const orderLineItem = state.orderDetails.lineItem.result[orderLineItemIndex];
      const updatedCompanyOrder = {...orderLineItem, ...action.payload.company_order};
      const orderDetailsCopy = {
        lineItem: undefined,
        ...state.orderDetails
      };

      const index = orderDetailsCopy.lineItem.result.findIndex(x => x.order_line_item_code === action.payload.company_order.order_line_item_code);
      const listItemsCopy = [...orderDetailsCopy.lineItem.result];
      listItemsCopy[index] = updatedCompanyOrder;

      const lineItemsCopyCopy = {...orderDetailsCopy.lineItem};
      lineItemsCopyCopy.result = listItemsCopy;

      orderDetailsCopy.lineItem = lineItemsCopyCopy;
      orderDetailsCopy.updated_at = action.payload.company_order.updated_at;

      return {
        ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.UPDATE_COMPANY_ORDER_LINE_ITEM_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      let orderDetailsCopyResult = [...orderDetailsCopy.lineItem.result];
      orderDetailsCopyResult = orderDetailsCopyResult.filter(item => item.order_line_item_code !== action.payload.order_line_item_code);

      orderDetailsCopy.lineItem = {...orderDetailsCopy.lineItem, ...{result: orderDetailsCopyResult}};
      orderDetailsCopy.updated_at = moment().format();

      return {
        ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.DELETE_COMPANY_ORDER_LINE_ITEM_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE: {
      const orderDetails = {...state.orderDetails};
      orderDetails.deliverable = {result: []};

      return {
        ...state,
        loading: true,
        loaded: false,
        error: undefined,
        success: {
          isLoaded: false,
          isForm: ''
        },
        deliverables: {result: []},
        orderDetails
      };
      // return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      orderDetailsCopy.deliverable = action.payload.company_order;
      return {
        ...state, orderDetails: orderDetailsCopy, deliverables: {...action.payload.company_order}, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      if (orderDetailsCopy.deliverable) {
        let orderDetailsCopyResult = [...orderDetailsCopy.deliverable.result];
        orderDetailsCopyResult = [action.payload.company_order, ...orderDetailsCopyResult];

        orderDetailsCopy.deliverable = {
          ...orderDetailsCopy.deliverable, ...{
            result: orderDetailsCopyResult,
            total_count: orderDetailsCopyResult.length
          }
        };
      } else {
        orderDetailsCopy.deliverable = {result: [action.payload.company_order]};
      }

      orderDetailsCopy.updated_at = action.payload.company_order.updated_at;

      return {
        ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
        success: {isForm: action.payload.company_order.deliverable_code, isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.ADD_COMPANY_ORDER_DELIVERABLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE_SUCCESS: {
      if (action.payload.page === 'company-order') {
        const orderLineItemIndex = state.orderDetails.deliverable.result.findIndex(x => x.deliverable_code === action.payload.company_order.deliverable_code);
        const orderLineItem = state.orderDetails.deliverable.result[orderLineItemIndex];
        const updatedCompanyOrder = {...action.payload.company_order};
        const orderDetailsCopy = {
          deliverable: undefined,
          ...state.orderDetails
        };

        const index = orderDetailsCopy.deliverable.result.findIndex(x => x.deliverable_code === action.payload.company_order.deliverable_code);
        const listItemsCopy = [...orderDetailsCopy.deliverable.result];
        listItemsCopy[index] = updatedCompanyOrder;

        const lineItemsCopyCopy = {...orderDetailsCopy.deliverable};
        lineItemsCopyCopy.result = listItemsCopy;
        lineItemsCopyCopy.total_count = state.orderDetails.deliverable.total_count;

        orderDetailsCopy.deliverable = lineItemsCopyCopy;
        orderDetailsCopy.updated_at = action.payload.company_order.updated_at;


        return {
          ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
          success: {isForm: "UPDATED_DELIVERABLE", isLoaded: true}, loaded: true
        };
      } else {
        const orderLineItemIndex = state.deliverables.result.findIndex(x => x.deliverable_code === action.payload.company_order.deliverable_code);
        const orderLineItem = state.deliverables.result[orderLineItemIndex];
        const updatedCompanyOrder = {...action.payload.company_order};
        let orderDetailsCopy = {...state.deliverables};

        const index = orderDetailsCopy.result.findIndex(x => x.deliverable_code === action.payload.company_order.deliverable_code);
        const listItemsCopy = [...orderDetailsCopy.result];
        listItemsCopy[index] = updatedCompanyOrder;

        const lineItemsCopyCopy = {...orderDetailsCopy};
        lineItemsCopyCopy.result = listItemsCopy;
        lineItemsCopyCopy.total_count = state.deliverables.total_count;
        lineItemsCopyCopy.page_size = state.deliverables.page_size;
        lineItemsCopyCopy.page_no = state.deliverables.page_no;

        orderDetailsCopy = lineItemsCopyCopy;
        orderDetailsCopy.updated_at = action.payload.company_order.updated_at;

        return {
          ...state, deliverables: orderDetailsCopy, error: undefined, loading: false,
          success: {isForm: "UPDATED_DELIVERABLE", isLoaded: true}, loaded: true
        };
      }
    }

    case CompanyActionTypes.UPDATE_COMPANY_ORDER_DELIVERABLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION_SUCCESS: {
      let userSettingTemp = state.userSetting;
      userSettingTemp = action.payload.setting_value;
      const setting_key_code = action.payload.setting_key_code;
      const deliverablesState = _.cloneDeep(state.deliverables);
      console.log('state, action ------->', state, action);
      try {
        if (setting_key_code && deliverablesState && deliverablesState.user_settings && deliverablesState.user_settings.length > 0) {
          deliverablesState.user_settings.forEach(el => {
            if (el.setting_key_code === setting_key_code) {
              console.log('el.setting_value', el.setting_value);
              el.setting_value = userSettingTemp;
            }
          });
        }
      } catch (e) {}
      const stateResult = Object.assign({}, _.cloneDeep(state), {deliverables: deliverablesState});
      console.log('stateResult --->', stateResult, deliverablesState);
      const result = {
        ...state, userSetting: userSettingTemp,
        error: undefined, loading: false,
        success: {isForm: 'UPDATED_USER_SETTING', isLoaded: true}, loaded: true
      };
      console.log('result-------->', result);
      return result;

      /*return {
        ...state, setting_value: action.payload.setting_value,
        error: undefined, loading: false,
        success: {isForm: 'UPDATED_USER_SETTING', isLoaded: true}, loaded: true
      };*/
    }

    case CompanyActionTypes.UPDATE_TABLE_COLUMNS_SELECTION_FAILURE: {
      return {...state, error: action.payload};
    }

    case CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      let orderDetailsCopyResult = [...orderDetailsCopy.deliverable.result];
      orderDetailsCopyResult = orderDetailsCopyResult.filter(item => item.deliverable_code !== action.payload.deliverable_code);

      orderDetailsCopy.deliverable = {
        ...orderDetailsCopy.deliverable, ...{
          result: orderDetailsCopyResult,
          total_count: orderDetailsCopyResult.length
        }
      };
      orderDetailsCopy.updated_at = moment().format();

      return {
        ...state, orderDetails: orderDetailsCopy, deliverables: orderDetailsCopy.deliverable, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.DELETE_COMPANY_ORDER_DELIVERABLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_DELIVERABLE_NOTES: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_DELIVERABLE_NOTES_SUCCESS: {
      if (action.payload.page === 'order') {
        const orderLineItemIndex = state.orderDetails.deliverable.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
        const orderLineItem = {...state.orderDetails.deliverable.result[orderLineItemIndex]};
        orderLineItem.notes = action.payload.notes;

        const resultsCopy = [...state.orderDetails.deliverable.result];
        resultsCopy[orderLineItemIndex] = orderLineItem;

        const orderDetailsCopy = {...state.orderDetails.deliverable};
        orderDetailsCopy.result = resultsCopy;

        const deliverablesCopy = {...state.orderDetails};
        deliverablesCopy.deliverable = orderDetailsCopy;

        const deliverableIndex = state.deliverables.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
        const deliverableItems = {...state.deliverables.result[deliverableIndex]};
        deliverableItems.notes = action.payload.notes;

        const deliverableresultsCopy = [...state.deliverables.result];
        deliverableresultsCopy[deliverableIndex] = deliverableItems;


        return {
          ...state, orderDetails: deliverablesCopy, error: undefined, loading: false, success: {isForm: 'GETTING_NOTES'},
          loaded: true
        };
      } else {
        const deliverableIndex = state.deliverables.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
        const deliverableItems = {...state.deliverables.result[deliverableIndex]};
        deliverableItems.notes = action.payload.notes;

        const deliverableresultsCopy = [...state.deliverables.result];
        deliverableresultsCopy[deliverableIndex] = deliverableItems;


        return {
          ...state, deliverables: {
            result: deliverableresultsCopy, total_count: state.deliverables.total_count,
            page_no: state.deliverables.page_no, page_size: state.deliverables.page_size
          }, error: undefined, loading: false,
          loaded: true, success: {isForm: 'GETTING_NOTES'}
        };
      }

    }

    case CompanyActionTypes.GET_DELIVERABLE_NOTES_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.ADD_DELIVERABLE_NOTES: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.ADD_DELIVERABLE_NOTES_SUCCESS: {
      if (action.payload.page === 'order') {
        const orderLineItemIndex = state.orderDetails.deliverable.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
        const orderLineItem = {...state.orderDetails.deliverable.result[orderLineItemIndex]};
        let notesResults;
        if (orderLineItem.deliverable_notes) {
          notesResults = [action.payload.notes, ...orderLineItem.deliverable_notes];
        } else {
          const resultArray = [];
          resultArray.push(action.payload.notes);
          notesResults = resultArray;
        }
        orderLineItem.deliverable_notes = [...notesResults];

        const resultsCopy = [...state.orderDetails.deliverable.result];
        resultsCopy[orderLineItemIndex] = orderLineItem;

        const orderDetailsCopy = {...state.orderDetails.deliverable};
        orderDetailsCopy.result = resultsCopy;

        const deliverablesCopy = {...state.orderDetails};
        deliverablesCopy.deliverable = orderDetailsCopy;

        return {
          ...state, orderDetails: deliverablesCopy, error: undefined, loading: false,
          success: {isForm: 'ADDED_NOTES', isLoaded: true}, loaded: true
        };

      } else {
        const orderLineItemIndex = state.deliverables.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
        const orderLineItem = {...state.deliverables.result[orderLineItemIndex]};
        let notesResults;
        if (orderLineItem.deliverable_notes) {
          notesResults = [action.payload.notes, ...orderLineItem.deliverable_notes];
        } else {
          const resultArray = [];
          resultArray.push(action.payload.notes);
          notesResults = resultArray;
        }
        orderLineItem.deliverable_notes = [...orderLineItem.deliverable_notes, ...notesResults];

        const resultsCopy = [...state.deliverables.result];
        resultsCopy[orderLineItemIndex] = orderLineItem;

        const deliverablesCopy = {...state.deliverables};
        deliverablesCopy.result = resultsCopy;

        return {
          ...state, deliverables: deliverablesCopy, error: undefined, loading: false,
          success: {isForm: 'ADDED_NOTES', isLoaded: true}, loaded: true
        };
      }

    }

    case CompanyActionTypes.ADD_DELIVERABLE_NOTES_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.UPDATE_DELIVERABLE_NOTES: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.UPDATE_DELIVERABLE_NOTES_SUCCESS: {
      const orderLineItemIndex = state.orderDetails.deliverable.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
      const orderLineItem = {...state.orderDetails.deliverable.result[orderLineItemIndex]};
      orderLineItem.notes = [...action.payload.note, orderLineItem.notes];

      const resultsCopy = [...state.orderDetails.deliverable.result];
      resultsCopy[orderLineItemIndex] = orderLineItem;

      const orderDetailsCopy = {...state.orderDetails.deliverable};
      orderDetailsCopy.result = resultsCopy;

      const deliverablesCopy = {...state.orderDetails};
      deliverablesCopy.deliverable = orderDetailsCopy;

      return {
        ...state, orderDetails: deliverablesCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.UPDATE_DELIVERABLE_NOTES_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.DELETE_DELIVERABLE_NOTES: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.DELETE_DELIVERABLE_NOTES_SUCCESS: {
      const orderLineItemIndex = state.orderDetails.deliverable.result.findIndex(x => x.deliverable_code === action.payload.deliverable_code);
      const orderLineItem = {...state.orderDetails.deliverable.result[orderLineItemIndex]};
      orderLineItem.notes = {result: orderLineItem.notes.result.filter(item => item.deliverable_note_code !== action.payload.deliverable_note_code)};

      const resultsCopy = [...state.orderDetails.deliverable.result];
      resultsCopy[orderLineItemIndex] = orderLineItem;

      const orderDetailsCopy = {...state.orderDetails.deliverable};
      orderDetailsCopy.result = resultsCopy;

      const deliverablesCopy = {...state.orderDetails};
      deliverablesCopy.deliverable = orderDetailsCopy;

      return {
        ...state, orderDetails: deliverablesCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.DELETE_DELIVERABLE_NOTES_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case CompanyActionTypes.GET_COMPANY_ORDER_HISTORY: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_HISTORY_SUCCESS: {

      const detailCompany = state.orderDetails;
      const updatedOrderDetailCompany = {...detailCompany};
      updatedOrderDetailCompany.transactionHistory = {...action.payload.history};

      return {
        ...state, orderDetails: updatedOrderDetailCompany, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_HISTORY_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS_SUCCESS: {
      if (action.payload.page === 'company') {
        const detailCompany = state.details;
        const updatedDetailCompany = {...detailCompany};
        updatedDetailCompany.orders = Object.assign([], action.payload);

        return {
          ...state, details: updatedDetailCompany, error: undefined, loading: false,
          success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
        };
      } else {
        return {
          ...state,
          orders: {
            result: action.payload.result,
            page_no: action.payload.page_no,
            page_size: action.payload.page_size,
            total_count: action.payload.total_count
          },
          error: undefined,
          loading: false,
          success: {isForm: 'LOAD_COMPANY', isLoaded: true},
          loaded: true
        };
      }

    }

    case CompanyActionTypes.GET_COMPANY_ORDER_FILTER_RESULTS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS_SUCCESS: {

      return {
        ...state, deliverables: action.payload, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };

    }

    case CompanyActionTypes.GET_COMPANY_ORDER_DELIVERABLES_FILTER_RESULTS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS: {
      return ReturnLoading(state);
    }

    case CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS_SUCCESS: {
      const orderDetailsCopy = {...state.orderDetails};
      orderDetailsCopy.lineItem = action.payload;
      return {
        ...state, orderDetails: orderDetailsCopy, error: undefined, loading: false,
        success: {isForm: 'LOAD_COMPANY', isLoaded: true}, loaded: true
      };

    }

    case CompanyActionTypes.GET_COMPANY_ORDER_LINE_ITEMS_FILTER_RESULTS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.PROJECT_BULK_EDIT:
      return ReturnLoading(state);

    case CompanyActionTypes.PROJECT_BULK_EDIT_SUCCESS: {
      const updatedProjectList = action.payload;
      const originalProjectList = [...state.details.projects.result];
      for (const updatedProject of updatedProjectList) {
        const uProject = {status_name: undefined, status_code: undefined, ...updatedProject};
        uProject.country_name = uProject.country.country_name;
        uProject.country_code = uProject.country.country_code;
        uProject.status_name = uProject.status.status_name;
        uProject.status_code = uProject.status.status_code;

        const projectIndex = state.details.projects.result.findIndex(x => x.project_code === uProject.project_code);
        const project = state.details.projects.result[projectIndex];
        const newUpdatedProject = {...project, ...uProject};
        // const originalProjectList = [...state.projects.result];

        const index = originalProjectList.findIndex(x => x.project_code === uProject.project_code);
        originalProjectList[index] = newUpdatedProject;
      }

      const companyDetails = {...state.details};
      companyDetails.projects = {result: originalProjectList, count: originalProjectList.length};
      return {
        ...state, details: companyDetails, loading: false, error: undefined, loaded: true, success: {isForm: 'LOAD_COMPANY', isLoaded: true}
      };
    }

    case CompanyActionTypes.PROJECT_BULK_EDIT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS:

      return {
        ...state, LTDSettings: {}, success: {isForm: '', isLoaded: false},
        loading: true, error: undefined, loaded: false
      };

    case CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS_SUCCESS: {
      // const response = {...state.LTDSettings, address: action.payload.company.address};
      const response = {...state.LTDSettings, brand: action.payload.company.brand,
        company_ltd_settings: action.payload.company.company_ltd_settings, address: action.payload.company.address, visibility: action.payload.company.visibility,
      password_protected_password: action.payload.company.password_protected_password};


      return {
        ...state, LTDSettings: response, success: {isForm: 'success', isLoaded: true},
        loading: false, error: undefined, loaded: true
      };
    }

    case CompanyActionTypes.LOAD_COMPANY_LTD_SETTINGS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS:
      return ReturnLoading(state);

    case CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS_SUCCESS: {
      const response = {...state.LTDSettings, brand: action.payload.company.brand, company_ltd_settings: action.payload.company.company_ltd_settings};

      response.address = action.payload.company.address;
      response.visibility = action.payload.company.visibility;
      response.password_protected_password = action.payload.company.password_protected_password;
      const updatedCompanyDetails = {...state.details};
      updatedCompanyDetails.address = action.payload.company.address;
      updatedCompanyDetails.visibility = action.payload.company.visibility;
      updatedCompanyDetails.password_protected_password = action.payload.company.password_protected_password;

      return {
        ...state, LTDSettings: response, details: updatedCompanyDetails, success: {isForm: 'success', isLoaded: true},
        loading: false, error: undefined, loaded: true
      };
    }

    case CompanyActionTypes.UPDATE_COMPANY_LTD_SETTINGS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case CompanyActionTypes.LOAD_COMPANY_GENERAL:
      return ReturnLoading(state);

    case CompanyActionTypes.LOAD_COMPANY_GENERAL_SUCCESS: {
      const companyDetails = {...state.details};
      if (action.payload.company.description) {
        companyDetails.description = action.payload.company.description;
      }

      const response = {...state.general, social_media: action.payload.company.social_media, private_tag: action.payload.company.private_tag,
        public_tag: action.payload.company.public_tag, description: action.payload.company.description};

      return {
        ...state, general: response, details: companyDetails, success: {isForm: 'success', isLoaded: true},
        loading: false, error: undefined, loaded: true
      };
    }

    case CompanyActionTypes.LOAD_COMPANY_GENERAL_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case CompanyActionTypes.UPDATE_COMPANY_GENERAL:
      return ReturnLoading(state);

    case CompanyActionTypes.UPDATE_COMPANY_GENERAL_SUCCESS: {
      const companyDetails = {...state.details};
      if (action.payload.company.description) {
        companyDetails.description = action.payload.company.description;
      }

      return {
        ...state, general: action.payload.company, details: companyDetails, success: {isForm: 'success', isLoaded: true},
        loading: false, error: undefined, loaded: true
      };
    }

    case CompanyActionTypes.UPDATE_COMPANY_GENERAL_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    default:
      return state;
  }

}

export const getErrors = (state: CompanyState) => state.error;
export const getCompanies = (state: CompanyState) => state.companies;
export const getCompanyLoading = (state: CompanyState) => state.loading;
