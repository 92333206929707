// Actions

// Models
import * as moment from 'moment';
import {CollectionActions, CollectionActionTypes} from '../actions/collection.action';
import { ProductActions, ProductActionTypes } from '../actions/product.actions';

export interface ProductState {
  products: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
  db?: {
    result: [],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
}

export const initialState: ProductState = {
    products: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: ProductState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: false,
  };
};

export function productReducer(state: ProductState = initialState, action: ProductActions): ProductState {
  switch (action.type) {

    case ProductActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case ProductActionTypes.LOAD_SUCCESS: {
      return {
        ...state, products: {
          result: action.payload.result,
          total_count:  action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, db: {
          result:  action.payload.result,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };
    }

    case ProductActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ProductActionTypes.SEARCH_PRODUCT: {
      return ReturnLoading(state);
    }

    case ProductActionTypes.SEARCH_PRODUCT_SUCCESS: {

      return {
        ...state, products: {
          result: action.payload.result, total_count: action.payload.total_count,
          page_no: action.payload.page_no, page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ProductActionTypes.SEARCH_PRODUCT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    default:
      return state;
  }

}
