// Actions
import {ProjectActions, ProjectActionTypes} from '../actions/project.actions';

// Models
import {Project} from '../../models/project.model';
import {Activity} from '../../models/activity.model';
import {ProjectSource} from '../../models/project-source.model';
import {formatDataSet} from '../../../views/common/base.component';
import {TransactionHistory} from '../../models/transaction-history.model';
import * as moment from 'moment';
import {ArticleActions, ArticleActionTypes} from '../actions/article.actions';
import { Article } from '../../models/article.model';
import {CompanyActionTypes} from '../actions/company.actions';
import {ContactActionTypes} from '../actions/contact.actions';
import cloneDeep from 'lodash.clonedeep';

export interface ArticleState {
  articles: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  error?: Error;
  loading: boolean;
  loaded: boolean;
  success?: boolean;
  db?: {
    result: any[],
    total_count?: number,
    page_no?: number,
    page_size?: number
  };
  details?: Article;
  is_deleted?: boolean;
  general?: any;
  ltdSettings?: any;
  links?: any;
}

export const initialState: ArticleState = {
  articles: {
    result: [],
    total_count: 0,
    page_no: 0,
    page_size: 0
  },
  loading: false,
  loaded: false,
};

export const ReturnLoading = (State: ArticleState) => {
  return {
    ...State,
    loading: true,
    loaded: false,
    error: undefined,
    success: false,
    is_deleted: undefined
  };
};

export function articleReducer(state: ArticleState = initialState, action: ArticleActions): ArticleState {
  switch (action.type) {

    case ArticleActionTypes.LOAD: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.LOAD_SUCCESS: {
      
      const article=[]
      var result=cloneDeep(action.payload.result)
      for (const elem of result){
        for (const [key, value] of Object.entries(elem)) {
        if(key=='article_dates'){
          for (const [key, val] of Object.entries(value)) {
            if(val.reference_type_date.article_date_code=="publishing-deadline-date"){
            
           const datename='publishing_deadline_date'
        elem[datename]=<never>val.date
        article.push(elem)}
            }
        }
        }

  
      }
      return {
        ...state, articles: {
          result: result,
          total_count:  action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, db: {
          result:  action.payload.result,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }
      };
    }

    case ArticleActionTypes.LOAD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case ArticleActionTypes.GET_ONE_ARTICLE: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.GET_ONE_ARTICLE_SUCCESS: {
      var countryCode=''
      var articleDetails
      if(action.payload.article.hasOwnProperty('article_ltd_settings')){
        if(action.payload.article.article_ltd_settings.hasOwnProperty('country')){
          if(action.payload.article.article_ltd_settings.country.hasOwnProperty('country_code')){
            countryCode=action.payload.article.article_ltd_settings.country.country_code
          }  
        }
      }
     if(countryCode==''){
       articleDetails = {...action.payload.article}
       
     }
     else{
      articleDetails = {...action.payload.article, country_code: action.payload.article.article_ltd_settings.country ? action.payload.article.article_ltd_settings.country.country_code : null}
      };
      return {
        ...state, details: articleDetails, loading: false, error: undefined, loaded: true
      };
    }

    case ArticleActionTypes.GET_ONE_ARTICLE_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ArticleActionTypes.FILTER: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.FILTER_SUCCESS: {

      const article=[]
      var result=cloneDeep(action.payload.result)
      for (const elem of result){
        for (const [key, value] of Object.entries(elem)) {
        if(key=='article_dates'){
          for (const [key, val] of Object.entries(value)) {
            if(val.reference_type_date.article_date_code=="publishing-deadline-date"){
            
           const datename='publishing_deadline_date'
        elem[datename]=<never>val.date
        article.push(elem)}
            }
        }
        }}
      return {
        ...state, articles: {
          result: result,
          total_count:  action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size
        }, loading: false, error: undefined, loaded: true, db: {
          result:  action.payload.result,
          total_count: action.payload.total_count,
          page_no: action.payload.page_no,
          page_size: action.payload.page_size,
        }, success: true
      };
    }

    case ArticleActionTypes.FILTER_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ArticleActionTypes.GET_ARTICLE_CONTACTS: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.GET_ARTICLE_CONTACTS_SUCCESS: {
      const detailArticle = state.details;
      const updatedDetailArticle = {...detailArticle};
      updatedDetailArticle.contacts = Object.assign([], action.payload.article.contact);

      return {
        ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.GET_ARTICLE_CONTACTS_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case ArticleActionTypes.ADD: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.ADD_SUCCESS: {
      let article = {
        ...action.payload.article
      };

      const articlesList = {...state.articles};
      if (!articlesList.result || articlesList.result.length <= 0) {
        const array = [];
        array.push(article);
        articlesList.result = array;
      } else {
        articlesList.result = [article, ...articlesList.result];
      }

      return {
        ...state, loading: false, articles: articlesList, error: undefined, loaded: true,
        success: true
      };
    }

    case ArticleActionTypes.ADD_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ArticleActionTypes.UPDATE:
      return ReturnLoading(state);

    case ArticleActionTypes.UPDATE_SUCCESS: {
      const updatedArticle = {...action.payload.article};
      const stateDetails = {...state.details};
      updatedArticle.contacts = stateDetails.contacts;
      updatedArticle.company = stateDetails.company;
      updatedArticle.collections = stateDetails.collections;
      updatedArticle.projects = stateDetails.projects;
      updatedArticle.products = stateDetails.products;
      updatedArticle.brands = stateDetails.brands;
      updatedArticle.editions = stateDetails.editions;

      updatedArticle.country_code = state.ltdSettings && state.ltdSettings.country ? state.ltdSettings.country.country_code : null;


      updatedArticle.activity = stateDetails.activity;
      updatedArticle.transaction_history = stateDetails.transaction_history;

      // const projectIndex = state.projects.findIndex(x => x.project_code === action.payload.project_code);
      // const project = state.projects[projectIndex];
      // const updatedProject = {...project, ...action.payload.project};
      // const updatedProjects = [...state.projects];
      //
      // const index = updatedProjects.findIndex(x => x.project_code === action.payload.project_code);
      // updatedProjects[index] = updatedProject;
      return {
        ...state, details: updatedArticle, error: undefined, loading: false, loaded: true,
        success: true
      };
    }

    case ArticleActionTypes.UPDATE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ArticleActionTypes.DELETE:
      return ReturnLoading(state);

    case ArticleActionTypes.DELETE_SUCCESS: {
      return {
        ...state, articles: {result: state.articles.result.filter(item => item.article_code !== action.payload.article_code)}, details: null,
        loading: false, error: undefined, loaded: true, is_deleted: true
      };
    }

    case ArticleActionTypes.DELETE_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    case ArticleActionTypes.ADD_ARTICLE_CONTACT:
      return ReturnLoading(state);

    case ArticleActionTypes.ADD_ARTICLE_CONTACT_SUCCESS: {
      const articleDetails = {
        updated_at: undefined,
        ...state.details
      };
      if (!articleDetails.contacts) {
        const array = [];
        array.push(action.payload.contact.contact[0]);
        articleDetails.contacts = array;
      } else {
        articleDetails.contacts = [action.payload.contact.contact[0], ...articleDetails.contacts];
      }
      articleDetails.updated_at = action.payload.contact.updated_at;
      return {
        ...state, details: articleDetails, loading: false,
        loaded: true, success: true, error: undefined
      };
    }

  case ArticleActionTypes.ADD_ARTICLE_CONTACT_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};

    case ArticleActionTypes.UPDATE_ARTICLE_CONTACT: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.UPDATE_ARTICLE_CONTACT_SUCCESS: {
      const detailArticle = state.details;
      const updatedDetailArticle = {...detailArticle};
      updatedDetailArticle.contacts = Object.assign([], action.payload.contact.contact);

      return {
        ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.UPDATE_ARTICLE_CONTACT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

  case ArticleActionTypes.DELETE_ARTICLE_CONTACT:
  return ReturnLoading(state);

  case ArticleActionTypes.DELETE_ARTICLE_CONTACT_SUCCESS: {
    const articleDetails = {
      updated_at: undefined,
      ...state.details
    };
    articleDetails.contacts = articleDetails.contacts.filter(item => item.article_contact_link_code !==
      action.payload.article_contact_link_code);

    articleDetails.updated_at = moment();
    return {
      ...state, details: articleDetails,
      loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.DELETE_ARTICLE_CONTACT_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};

  case ArticleActionTypes.GET_ARTICLE_COMPANY: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.GET_ARTICLE_COMPANY_SUCCESS: {
    const detailArticle = state.details;
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.company = Object.assign([], action.payload.article.company);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.GET_ARTICLE_COMPANY_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }



    case ArticleActionTypes.ADD_ARTICLE_COMPANY:
      return ReturnLoading(state);

    case ArticleActionTypes.ADD_ARTICLE_COMPANY_SUCCESS: {
      const articleDetails = {
        updated_at: undefined,
        ...state.details
      };
      if (!articleDetails.company) {
        const array = [];
        array.push(action.payload.article.company[0]);
        articleDetails.company = array;
      } else {
        articleDetails.company = [action.payload.article.company[0], ...articleDetails.company];
      }
      articleDetails.updated_at = action.payload.article.company[0].updated_at;
      return {
        ...state, details: articleDetails, loading: false,
        loaded: true, success: true, error: undefined
      };
    }

    case ArticleActionTypes.ADD_ARTICLE_COMPANY_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

  case ArticleActionTypes.UPDATE_ARTICLE_COMPANY: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_COMPANY_SUCCESS: {
    const detailArticle = state.details;
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.company = Object.assign([], action.payload.company.company);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_COMPANY_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.DELETE_ARTICLE_COMPANY:
  return ReturnLoading(state);

  case ArticleActionTypes.DELETE_ARTICLE_COMPANY_SUCCESS: {
    const articleDetails = {
      updated_at: undefined,
      ...state.details
    };
    articleDetails.company = articleDetails.company.filter(item => item.article_company_link_code !==
      action.payload.article_company_link_code);

    articleDetails.updated_at = moment();
    return {
      ...state, details: articleDetails,
      loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.DELETE_ARTICLE_CONTACT_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};



  case ArticleActionTypes.GET_ARTICLE_PROJECT: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.GET_ARTICLE_PROJECT_SUCCESS: {
    const detailArticle = {...state.details};
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.projects = Object.assign([], action.payload.article.project);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.GET_ARTICLE_PROJECT_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }


  case ArticleActionTypes.ADD_ARTICLE_PROJECT:
    return ReturnLoading(state);

  case ArticleActionTypes.ADD_ARTICLE_PROJECT_SUCCESS: {
    const articleDetails = {
      updated_at: undefined,
      ...state.details
    };
    if (!articleDetails.projects) {
      const array = [];
      array.push(action.payload.article.project[0]);
      articleDetails.projects = array;
    } else {
      articleDetails.projects = [action.payload.article.project[0], ...articleDetails.projects];
    }
    articleDetails.updated_at = action.payload.article.project[0].updated_at;
    return {
      ...state, details: articleDetails, loading: false,
      loaded: true, success: true, error: undefined
    };
  }

  case ArticleActionTypes.ADD_ARTICLE_PROJECT_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};

  case ArticleActionTypes.UPDATE_ARTICLE_PROJECT: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_PROJECT_SUCCESS: {
    const detailArticle = {...state.details};
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.projects = Object.assign([], action.payload.project.project);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_PROJECT_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.DELETE_ARTICLE_PROJECT:
  return ReturnLoading(state);

  case ArticleActionTypes.DELETE_ARTICLE_PROJECT_SUCCESS: {
    const articleDetails = {
      updated_at: undefined,
      ...state.details
    };
    articleDetails.projects = articleDetails.projects.filter(item => item.article_project_link_code !==
      action.payload.article_project_link_code);

    articleDetails.updated_at = moment();
    return {
      ...state, details: articleDetails,
      loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.DELETE_ARTICLE_PROJECT_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};

    case ArticleActionTypes.GET_ARTICLE_BRAND: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.GET_ARTICLE_BRAND_SUCCESS: {
      const detailArticle = state.details;
      const updatedDetailArticle = {...detailArticle};
      updatedDetailArticle.brands = Object.assign([], action.payload.article.brand);

      return {
        ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.GET_ARTICLE_BRAND_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }



    case ArticleActionTypes.ADD_ARTICLE_BRAND:
      return ReturnLoading(state);

    case ArticleActionTypes.ADD_ARTICLE_BRAND_SUCCESS: {
      const articleDetails = {
        updated_at: undefined,
        ...state.details
      };
      if (!articleDetails.brands) {
        const array = [];
        array.push(action.payload.article.brand[0]);
        articleDetails.brands = array;
      } else {
        articleDetails.brands = [action.payload.article.brand[0], ...articleDetails.brands];
      }
      articleDetails.updated_at = action.payload.article.brand[0].updated_at;
      return {
        ...state, details: articleDetails, loading: false,
        loaded: true, success: true, error: undefined
      };
    }

case ArticleActionTypes.ADD_ARTICLE_BRAND_FAILURE:
  return {...state, error: action.payload, loading: false, loaded: false};

case ArticleActionTypes.UPDATE_ARTICLE_BRAND: {
  return ReturnLoading(state);
}

case ArticleActionTypes.UPDATE_ARTICLE_BRAND_SUCCESS: {
  const detailArticle = state.details;
  const updatedDetailArticle = {...detailArticle};
  updatedDetailArticle.brands = Object.assign([], action.payload.brand.brand);

  return {
    ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
  };
}

case ArticleActionTypes.UPDATE_ARTICLE_BRAND_FAILURE: {
  return {...state, loading: false, error: action.payload, loaded: false};
}

  case ArticleActionTypes.DELETE_ARTICLE_BRAND:
  return ReturnLoading(state);

    case ArticleActionTypes.DELETE_ARTICLE_BRAND_SUCCESS: {
      const articleDetails = {
        updated_at: undefined,
        ...state.details
      };
      articleDetails.brands = articleDetails.brands.filter(item => item.article_brand_link_code !==
        action.payload.article_brand_link_code);

      articleDetails.updated_at = moment();
      return {
        ...state, details: articleDetails,
        loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.DELETE_ARTICLE_BRAND_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};


    // Product
    case ArticleActionTypes.GET_ARTICLE_PRODUCT: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.GET_ARTICLE_PRODUCT_SUCCESS: {
      const detailArticle = {...state.details};
      const updatedDetailArticle = {...detailArticle};
      updatedDetailArticle.products = Object.assign([], action.payload.article.product);

      return {
        ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.GET_ARTICLE_PRODUCT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }


    case ArticleActionTypes.ADD_ARTICLE_PRODUCT:
      return ReturnLoading(state);

    case ArticleActionTypes.ADD_ARTICLE_PRODUCT_SUCCESS: {
      const articleDetails = {
        updated_at: undefined,
        ...state.details
      };
      if (!articleDetails.products) {
        const array = [];
        array.push(action.payload.article.product[0]);
        articleDetails.products = array;
      } else {
        articleDetails.products = [action.payload.article.product[0], ...articleDetails.products];
      }
      articleDetails.updated_at = action.payload.article.product[0].updated_at;
      return {
        ...state, details: articleDetails, loading: false,
        loaded: true, success: true, error: undefined
      };
    }

    case ArticleActionTypes.ADD_ARTICLE_PRODUCT_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

    case ArticleActionTypes.UPDATE_ARTICLE_PRODUCT: {
      return ReturnLoading(state);
    }

    case ArticleActionTypes.UPDATE_ARTICLE_PRODUCT_SUCCESS: {
      const detailArticle = {...state.details};
      const updatedDetailArticle = {...detailArticle};
      updatedDetailArticle.products = Object.assign([], action.payload.product.product);

      return {
        ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.UPDATE_ARTICLE_PRODUCT_FAILURE: {
      return {...state, loading: false, error: action.payload, loaded: false};
    }

    case ArticleActionTypes.DELETE_ARTICLE_PRODUCT:
    return ReturnLoading(state);

    case ArticleActionTypes.DELETE_ARTICLE_PRODUCT_SUCCESS: {
      const articleDetails = {
        updated_at: undefined,
        ...state.details
      };
      articleDetails.products = articleDetails.products.filter(item => item.article_product_link_code !==
        action.payload.article_product_link_code);

      articleDetails.updated_at = moment();
      return {
        ...state, details: articleDetails,
        loading: false, error: undefined, loaded: true, success: true
      };
    }

    case ArticleActionTypes.DELETE_ARTICLE_PRODUCT_FAILURE:
      return {...state, error: action.payload, loading: false, loaded: false};

// Collections
case ArticleActionTypes.GET_ARTICLE_COLLECTION: {
  return ReturnLoading(state);
}

case ArticleActionTypes.GET_ARTICLE_COLLECTION_SUCCESS: {
  const detailArticle = {...state.details};
  const updatedDetailArticle = {...detailArticle};
  updatedDetailArticle.collections = Object.assign([], action.payload.article.collection);

  return {
    ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
  };
}

case ArticleActionTypes.GET_ARTICLE_COLLECTION_FAILURE: {
  return {...state, loading: false, error: action.payload, loaded: false};
}


case ArticleActionTypes.ADD_ARTICLE_COLLECTION:
  return ReturnLoading(state);

case ArticleActionTypes.ADD_ARTICLE_COLLECTION_SUCCESS: {
  const articleDetails = {
    updated_at: undefined,
    ...state.details
  };
  if (!articleDetails.collections) {
    const array = [];
    array.push(action.payload.article.collection[0]);
    articleDetails.collections = array;
  } else {
    articleDetails.collections = [action.payload.article.collection[0], ...articleDetails.collections];
  }
  articleDetails.updated_at = action.payload.article.collection[0].updated_at;
  return {
    ...state, details: articleDetails, loading: false,
    loaded: true, success: true, error: undefined
  };
}

case ArticleActionTypes.ADD_ARTICLE_COLLECTION_FAILURE:
  return {...state, error: action.payload, loading: false, loaded: false};

  case ArticleActionTypes.UPDATE_ARTICLE_COLLECTION: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_COLLECTION_SUCCESS: {
    const detailArticle = {...state.details};
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.collections = Object.assign([], action.payload.collection.collection);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_COLLECTION_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

case ArticleActionTypes.DELETE_ARTICLE_COLLECTION:
return ReturnLoading(state);

case ArticleActionTypes.DELETE_ARTICLE_COLLECTION_SUCCESS: {
  const articleDetails = {
    updated_at: undefined,
    ...state.details
  };
  articleDetails.collections = articleDetails.collections.filter(item => item.article_collection_link_code !==
    action.payload.article_collection_link_code);

  articleDetails.updated_at = moment();
  return {
    ...state, details: articleDetails,
    loading: false, error: undefined, loaded: true, success: true
  };
}

case ArticleActionTypes.DELETE_ARTICLE_COLLECTION_FAILURE:
  return {...state, error: action.payload, loading: false, loaded: false};


  // Editions
  case ArticleActionTypes.GET_ARTICLE_EDITION: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.GET_ARTICLE_EDITION_SUCCESS: {
    const detailArticle = {...state.details};
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.editions = Object.assign([], action.payload.article.edition);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.GET_ARTICLE_EDITION_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }


  case ArticleActionTypes.ADD_ARTICLE_EDITION:
    return ReturnLoading(state);

  case ArticleActionTypes.ADD_ARTICLE_EDITION_SUCCESS: {
    const articleDetails = {
      updated_at: undefined,
      ...state.details
    };
    if (!articleDetails.editions) {
      const array = [];
      array.push(action.payload.article.edition[0]);
      articleDetails.editions = array;
    } else {
      articleDetails.editions = [action.payload.article.edition[0], ...articleDetails.editions];
    }
    articleDetails.updated_at = action.payload.article.edition[0].updated_at;
    return {
      ...state, details: articleDetails, loading: false,
      loaded: true, success: true, error: undefined
    };
  }

  case ArticleActionTypes.ADD_ARTICLE_EDITION_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};

  case ArticleActionTypes.UPDATE_ARTICLE_EDITION: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_EDITION_SUCCESS: {
    const detailArticle = {...state.details};
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.editions = Object.assign([], action.payload.edition.edition);

    return {
      ...state, details: updatedDetailArticle, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_EDITION_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.DELETE_ARTICLE_EDITION:
    return ReturnLoading(state);

  case ArticleActionTypes.DELETE_ARTICLE_EDITION_SUCCESS: {
    const articleDetails = {
      updated_at: undefined,
      ...state.details
    };
    articleDetails.editions = articleDetails.editions.filter(item => item.article_edition_link_code !==
      action.payload.article_edition_link_code);

    articleDetails.updated_at = moment();
    return {
      ...state, details: articleDetails,
      loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.DELETE_ARTICLE_EDITION_FAILURE:
    return {...state, error: action.payload, loading: false, loaded: false};


  case ArticleActionTypes.GET_ARTICLE_TRANSACTION_HISTORY: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.GET_ARTICLE_TRANSACTION_HISTORY_SUCCESS: {
    const detailArticle = state.details;
    const updatedDetailArticle = {...detailArticle};
    updatedDetailArticle.transaction_history = {...action.payload.history};

    return {
      ...state, details: updatedDetailArticle, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.GET_ARTICLE_TRANSACTION_HISTORY_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.LOAD_ARTICLE_GENERAL: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.LOAD_ARTICLE_GENERAL_SUCCESS: {

    const response = {...state.general, private_tag: action.payload.articleGeneral.private_tag,
      public_tag: action.payload.articleGeneral.public_tag};

    return {
      ...state, general: response, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.LOAD_ARTICLE_GENERAL_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.UPDATE_ARTICLE_GENERAL: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_GENERAL_SUCCESS: {

    const response = {...state.general, private_tag: action.payload.articleGeneral.private_tag,
      public_tag: action.payload.articleGeneral.public_tag};

    return {
      ...state, general: response, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_GENERAL_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  // LTD SEttings

  case ArticleActionTypes.LOAD_ARTICLE_LTD_SETTINGS: {
    return {
      ...state, ltdSettings: null, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.LOAD_ARTICLE_LTD_SETTINGS_SUCCESS: {
    let articleLTDData = {};
    let articleState = {...state.details};
    articleState = {...articleState, featured_image_reference_id: action.payload.articleLTDSettings.featured_image_reference_id, country_code: action.payload.articleLTDSettings.article_ltd_settings.country ? action.payload.articleLTDSettings.article_ltd_settings.country.country_code : null};


    if (action.payload.articleLTDSettings && action.payload.articleLTDSettings.article_ltd_settings) {
      articleLTDData = action.payload.articleLTDSettings.article_ltd_settings;
    }

    return {
      ...state, ltdSettings: articleLTDData, details: articleState, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.LOAD_ARTICLE_LTD_SETTINGS_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.UPDATE_ARTICLE_LTD_SETTINGS: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_LTD_SETTINGS_SUCCESS: {
    let articleLTDData = null;
    let articleState = {...state.details};
    articleState = {...articleState, featured_image_reference_id: action.payload.articleLTDSettings.featured_image_reference_id, is_published_yn: action.payload.articleLTDSettings.is_published_yn,
      country_code: action.payload.articleLTDSettings.article_ltd_settings.country ? action.payload.articleLTDSettings.article_ltd_settings.country.country_code : null};
    if (action.payload.articleLTDSettings && action.payload.articleLTDSettings.article_ltd_settings) {
      articleLTDData = action.payload.articleLTDSettings.article_ltd_settings;
    }

    return {
      ...state, ltdSettings: articleLTDData, details: articleState, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_LTD_SETTINGS_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  // Links

  case ArticleActionTypes.LOAD_ARTICLE_LINKS: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.LOAD_ARTICLE_LINKS_SUCCESS: {
    let articleLTDData = null;
    if (action.payload.articleLinks && action.payload.articleLinks.article_links) {
      articleLTDData = action.payload.articleLinks.article_links;
    }


    return {
      ...state, links: articleLTDData, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.LOAD_ARTICLE_LINKS_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.UPDATE_ARTICLE_LINKS: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.UPDATE_ARTICLE_LINKS_SUCCESS: {
    let articleLTDData = null;
    if (action.payload.articleLinks && action.payload.articleLinks.article_links) {
      articleLTDData = action.payload.articleLinks.article_links;
    }

    return {
      ...state, links: articleLTDData, error: undefined, loading: false,
      success: true, loaded: true
    };
  }

  case ArticleActionTypes.UPDATE_ARTICLE_LINKS_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.SEARCH_ARTICLE: {
    return ReturnLoading(state);
  }

  case ArticleActionTypes.SEARCH_ARTICLE_SUCCESS: {

    return {
      ...state, articles: {
        result: action.payload.result, total_count: action.payload.total_count,
        page_no: action.payload.page_no, page_size: action.payload.page_size
      }, loading: false, error: undefined, loaded: true, success: true
    };
  }

  case ArticleActionTypes.SEARCH_ARTICLE_FAILURE: {
    return {...state, loading: false, error: action.payload, loaded: false};
  }

  case ArticleActionTypes.UPDATE_SYNC_STATUS: {
    const articleDetails = {
      article_last_synced: null,
      ...state.details};
    articleDetails.article_last_synced = action.payload.article_last_sync;
    return {...state, details: articleDetails, loading: false, loaded: false};

  }

    case ArticleActionTypes.FILTER_RESET: {
      return {...state, articles: {...state.db}, loading: false, error: undefined, loaded: true};
    }

    case ArticleActionTypes.SET_SUCCESS_NULL:
      return ReturnLoading(state);

    default:
      return state;
  }

}
