import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Observable } from 'rxjs';
import { debounce, EmitType } from '@syncfusion/ej2-base';
import { BehaviorSubject, of, zip } from 'rxjs';
import * as moment from 'moment';
import {
  CompanyFilter,
  CompanyOrder, CompanyOrderDeliverablesFilter,
  CompanyOrderFilter,
  GetAllCompanyOrders, GetCompanyOrderDeliverable
} from '../../../../../../../core/store/actions/company.actions';
import { DropDownTree, DropDownTreeComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { LoadDeliverableItemDate } from 'src/app/core/store/actions/common.actions';
import * as _ from 'lodash';
import { ArticleService } from '../../../../../../../core/store/services/article.service';
import { AdminService } from 'src/app/core/store/services/admin.service';
import { cloneDeep } from 'lodash'
import { element } from 'protractor';
import { CompanyService } from 'src/app/core/store/services/company.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
@Component({
  selector: 'app-deliverable-filter',
  templateUrl: './deliverable-filter.component.html',
  styleUrls: ['./deliverable-filter.component.scss']
})
export class DeliverableFilterComponent implements OnInit, OnChanges {

  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('statusDeliverCode') statusDeliverCode: DropDownTreeComponent

  @Input() users: object;
  @Input() deliverableStatus: object;
  @Input() costCenter: object;
  @Input() companyList: any;
  @Input() deliverableItem: [];
  @Input() lineItem: [];
  @Input() articleList: [];
  @Input() isPopulateSidebar: any;


  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  queryParams: any;

  error$: Observable<any>;
  success$: Observable<boolean>;

  selectedTab = 'deliverable';

  formSent: boolean;
  isFilterReset = true;
  articleFields: object = { value: 'article_code', text: 'article_name' };

  companyFields: object = { value: 'company_code', text: 'company_name' };
  userFields: object = { value: 'user_code', text: 'first_name' };
  costCenterFields = { value: 'cost_center_code', text: 'cost_center_name' };
  deliverableItemFields: any = { value: 'deliverable_item_code', text: 'deliverable_item_name' };
  lineItemFields: object;
  deliverableStatusFields: any;
  tentativeFields = { value: 'tentative_value', text: 'tentative' };
  deliverableStatusmulti
  deliverableStatusmultiData
  deliverableStatusmultiDataField = {
    value: 'deliverable_status_code',
    text: 'deliverable_status_name'
  }
  public treeSettings: object = { autoCheck: true };
  public value: string[] = ["open"];
  allowEdit = false;
  isFiltering = false;
  durationTo: any;

  startWeek = 1;


  deliverableItemDateForm: FormGroup;

  filterForm: FormGroup;
  defaultTentativeValue = 'all';
  deliverableItemDates = [];

  tentativeOptions = [
    {
      tentative_value: 'tentative',
      tentative: 'Tentative'
    },
    {
      tentative_value: 'not-tentative',
      tentative: 'Not Tentative'
    }
  ];

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }


  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private route: ActivatedRoute,
    private articleService: ArticleService, private admin: AdminService, private companyService: CompanyService) {
  }

  ngOnInit(): void {


    this.generateFilterForm();

    if (history.state.navigationId != 1) {
      this.route.queryParams.subscribe(res => {
        if (Object.keys(res).length !== 0) {
          this.queryParams = res;
        }
      });
      this.populateSidebar(this.queryParams);
    }
    this.getDeliverableDates();
    this.store.select(store => store.metadata.deliverableStatus).subscribe((data) => {
      if (data) {

        this.deliverableStatusmulti = of(data.result);
        this.deliverableStatusmultiData = cloneDeep(data.result);

      }

    });

    this.success$ = this.store.pipe(select(store => store.companies.success.isLoaded));
    this.error$ = this.store.pipe(select(store => store.companies.error));

    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        if (!this.isFilterReset && !this.isFiltering) {
          this.closeSidebar();
        }
        this.isFiltering = false;
        // this.store.dispatch(new ProjectSetStatusNull());
      }
    });

    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });



  }


  generateFilterForm() {
    this.filterForm = this.formBuilder.group({
      created_at_from: new FormControl(null),
      created_at_to: new FormControl(null),
      updated_at_from: new FormControl(null),
      updated_at_to: new FormControl(null),
      duration_from: new FormControl(null),
      duration_to: new FormControl(null),
      deliverable_item_code: new FormControl([]),
      deliverable_status_code: new FormControl([null]),
      cost_center_code: new FormControl([]),
      company_code: new FormControl([]),
      company_account_manager: new FormControl(null),
      updated_by: new FormControl(null),
      created_by: new FormControl(null),
      order_item_code: new FormControl([]),
      deliverable_owner_code: new FormControl(null),
      date_owner_code: new FormControl(null),
      expiry_from: new FormControl(null),
      expiry_to: new FormControl(null),
      article_code: new FormControl(null)
    });


    this.deliverableItemDateForm = this.formBuilder.group({

    });



    this.route.queryParams.subscribe(res => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
      }

    });

  }

  getFilteredData() {


    this.isFilterReset = false;


    const deliverableDates = { ...this.deliverableItemDateForm.value };
    for (const deliverableDate in deliverableDates) {
      if (!deliverableDate.includes('is_tentative') && deliverableDates[deliverableDate] !== null && deliverableDates[deliverableDate] !== '') {
        deliverableDates[deliverableDate] = moment(deliverableDates[deliverableDate]).format('YYYY-MM-DD');
      }
    }

    let filterValues = { ...this.filterForm.value, ...deliverableDates };




    Object.keys(filterValues).forEach((key) => ((filterValues[key] == null) || (filterValues[key] === '') || (filterValues[key] === 'Invalid date') || (filterValues[key] === false)) && delete filterValues[key]);

    if (filterValues.duration_from) {
      filterValues.start_date = moment(filterValues.duration_from).format('YYYY-MM-DD');
    }
    delete filterValues.duration_from;

    if (filterValues.duration_to) {
      filterValues.end_date = moment(filterValues.duration_to).format('YYYY-MM-DD');
    }
    delete filterValues.duration_to;

    if (filterValues.expiry_from) {
      filterValues.expiry_from = moment(filterValues.expiry_from).format('YYYY-MM-DD');
    }

    if (filterValues.expiry_to) {
      filterValues.expiry_to = moment(filterValues.expiry_to).format('YYYY-MM-DD');
    }

    if (filterValues.created_at_from) {
      filterValues.created_at_start = moment(filterValues.created_at_from).format('YYYY-MM-DD');
    }
    delete filterValues.created_at_from;

    if (filterValues.created_at_to) {
      filterValues.created_at_end = moment(filterValues.created_at_to).format('YYYY-MM-DD');
    }
    delete filterValues.created_at_to;

    if (filterValues.updated_at_from) {
      filterValues.updated_at_start = moment(filterValues.updated_at_from).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at_from;

    if (filterValues.updated_at_to) {
      filterValues.updated_at_end = moment(filterValues.updated_at_to).format('YYYY-MM-DD');
    }
    delete filterValues.updated_at_to;

    for (const filterValuesKey in filterValues) {

      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(',');
      }

    }


    this.router.navigate([], { queryParams: filterValues });
    filterValues = { ...filterValues, page_size: 100, page_no: 1, column_list: true };
    this.store.dispatch(new CompanyOrderDeliverablesFilter(filterValues));
    this.sidebarInstance.hide();
  }

  clearForm() {
    this.isFilterReset = true;
    let filterValues = { ...this.filterForm.value };
    this.router.navigate([], { queryParams: {} });
    this.filterForm.reset();
    this.deliverableItemDateForm.reset();
    this.filterForm.controls.order_item_code.setValue([]);
    this.filterForm.controls.deliverable_item_code.setValue([]);
    this.filterForm.controls.deliverable_status_code.setValue([]);
    // this.filterForm.controls.company_code.setValue([]);
    this.filterForm.controls.cost_center_code.setValue([]);


    this.isFiltering = false;

    this.store.dispatch(new GetCompanyOrderDeliverable({ page_size: 100, page_no: 1 }));
  }

  openClick(): void {
    this.sidebarInstance.show();
    this.route.queryParams.subscribe(res => {
      if (Object.keys(res).length !== 0) {
        if (Object.keys(res).length == 1) {
          if (res.hasOwnProperty('search')) {
            this.filterForm.reset();
          }
          else {
            this.queryParams = res;
            this.populateSidebar(this.queryParams);
          }
        }
        else{
        this.queryParams = res;
        this.populateSidebar(this.queryParams);}
      }

    });

  }

  public closeSidebar(): void {
    this.sidebarInstance.hide();
  }

  isCompanyLink(): boolean {
    return (this.router.url.indexOf('/company/') > -1);
  }

  setTab(args) {
    this.selectedTab = args;
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {


      query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      const filterParams = { company_name: e.text, page_size: 100, page_no: 1 };
      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data.data) {
          const companyList = [];
          for (const company of data.data.result) {
            companyList.push({ company_name: company.company_name + ' (' + company.reference_id + ')', company_code: company.company_code })
            e.updateData(companyList, query);
          }
        }
      });


      // query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
      // const filterParams = { company_name: e.text, page_size: 100, page_no: 1 };
      // this.store.dispatch(new CompanyFilter(filterParams));
      // this.store.select(store => store.companies.companies).subscribe((data) => {
      //   if (data && data.result) {
      //     this.companyList = data.result;

      //     e.updateData(this.companyList, query);
      //   }
      // });

      this.isFiltering = true;
    } else if (entity === 'article') {
      query = (e.text !== '') ? query.where('article_name', 'contains', e.text, true) : query;
      const filterParams = { search: e.text, page_size: 100, page_no: 1 };
      this.articleService.getSearchResults(filterParams).subscribe((data) => {
        if (data && data.data) {
          e.updateData(data.data.result, query);

        }
      });
    }
  };

  setDuration(args) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliverableItem) {
      this.deliverableItemFields = {
        value: 'deliverable_item_code',
        text: 'deliverable_item_name',
        dataSource: this.deliverableItem,
        child: 'children'
      };
    }

    // if (changes.deliverableStatus) {

    //   this.deliverableStatusFields = {
    //     value: 'deliverable_status_code',
    //     text: 'deliverable_status_name',
    //     dataSource: this.deliverableStatus,
    //     child: 'children'
    //   };

    // }




    if (changes.lineItem) {
      this.lineItemFields = {
        value: 'order_item_code',
        text: 'order_item_name',
        dataSource: this.lineItem,
        child: 'children'
      };
    }

    if (changes.isPopulateSidebar) {
      this.populateSidebar(this.queryParams);
    }
  }

  getDeliverableDates() {
    this.store.dispatch(new LoadDeliverableItemDate());

    this.store.select(store => store.metadata.deliverableItemDate).subscribe((data) => {
      if (data && data.result) {
        this.deliverableItemDates = data.result;
        // this.deliverableItemDatesCopy = _.cloneDeep(this.deliverableItemDates);
      }
    });
  }

  strReplace(text) {
    if (text !== null) {
      return text.toString().replace(/-/g, '_');
    }
  }

  addDateToFilter(date) {
    const deliverableFound = this.deliverableItemDates.find(item => item.deliverable_item_date_code === date);
    if (deliverableFound) {
      this.deliverableItemDateForm.addControl('is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_'), new FormControl(false));
      this.deliverableItemDateForm.addControl(deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_') + '_to', new FormControl(false));
      this.deliverableItemDateForm.addControl(deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_') + '_from', new FormControl(true));

      // if (this.deliverableItemDateForm.controls['is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_')].value === false) {
      //   this.deliverableItemDateForm.controls['is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_')].setValue('all');
      // }
      return true;
    } else {
      return false;
    }
  }

  populateSidebar(filters) {

    // if(filters==undefined&&!this.isFilterReset){
    //   console.log("reached")
    //   this.filterForm.controls.deliverable_status_code.setValue(val)
    //   console.log(this.filterForm.value)
    // }

    for (const key in filters) {


      if (filters[key]) {

        this.filterForm.controls[key].setValue(_.cloneDeep(filters[key].split(',')));

      }

    }
  }
  created() {
    // if(this.router.url === '/deliverable?deliverable_status_code=open'){
    //  this. statusDeliverCode.value = ['open'];
    //  this. statusDeliverCode.text = 'Open'; }

  }
  beforeOpen() {
    //   if(this.router.url === '/deliverable?deliverable_status_code=open'){
    //    this. statusDeliverCode.value = ["open"];
    //    this. statusDeliverCode.text = 'Open';
    //   console.log("before open")}
    // }
  }
  action(args) {
    if (this.router.url == '/deliverable?deliverable_status_code=open') {
      this.filterForm.controls.deliverable_status_code.setValue(['open'])
    }
  }

  clearAll() {
    this.clearModal.hide()
    this.clearForm()

  }
  hideClearAllModal() {
    this.clearModal.hide()
  }
  clear() {
    this.clearModal.show()
  }
}
