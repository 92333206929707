import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeliverableItem} from '../../../../../core/models/deliverable-item.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {
  AddDeliverableItems, AddLineItem,
  MetaDataResetError,
  UpdateDeliverableItems,
  UpdateLineItem
} from '../../../../../core/store/actions/common.actions';
import {OrderItem} from '../../../../../core/models/order-item.model';
import {trimValidator} from '../../../../../app-validators';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-line-item-form',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.scss']
})
export class LineItemComponent implements OnInit, OnChanges {
  @ViewChild('sidebarInstance') sidebarInstance: SidebarComponent;

  @Input() order_items: any;
  @Output() valueChange = new EventEmitter();
  orderItemForm: FormGroup;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';

  orderItemsField: any;

  formSent: boolean;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<boolean>;

  public _orderItemInput$: BehaviorSubject<OrderItem | null> = new BehaviorSubject<OrderItem | null>(null);

  @Input() set orderItemInput(mode: OrderItem | null) {
    this._orderItemInput$.next(mode);
  }

  get orderItemInput(): OrderItem | null {
    return this._orderItemInput$.getValue();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {
  }

  ngOnInit(): void {
    if (!this.orderItemForm) {
      this.generateOrderLineItemForm();
    }

    this.error$ = this.store.pipe(select(store => store.metadata.error));
    this.error$.subscribe((data: any) => {

      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
      }
    });

    this.success$ = this.store.pipe(select(store => store.metadata.success));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.closeSidebar();
      }
    });
  }

  generateOrderLineItemForm() {
    this.orderItemForm = this.formBuilder.group({
      order_item_name: new FormControl('', [Validators.required, Validators.compose([trimValidator])]),
      description: new FormControl('', [Validators.maxLength(200)]),
      order_item_parent_code: new FormControl(''),
    });
  }

  onAddUpdateDeliverableInput(form: FormGroup, isUpdate = false) {
    if (!form.invalid) {
      this.formSent = true;
      if (!isUpdate) {
        this.store.dispatch(new AddLineItem({
          order_item_name: form.value.order_item_name,
          order_item_parent_code: form.value.order_item_parent_code ? form.value.order_item_parent_code[0] : null,
          description: form.value.description
        }));
      } else {
        this.store.dispatch(new UpdateLineItem({
          order_item_name: form.value.order_item_name,
          order_item_code: this._orderItemInput$.getValue().order_item_code,
          order_item_parent_code: form.value.order_item_parent_code ? form.value.order_item_parent_code[0] : null,
          description: form.value.description
        }));
      }
    }
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.valueChange.emit('success');
    this.orderItemForm.reset();
    this.sidebarInstance.hide();
    this.orderItemForm.controls.order_item_parent_code.setValue('');

    this.store.dispatch(new MetaDataResetError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order_items) {
      this.orderItemsField = {
        dataSource: this.order_items,
        value: 'order_item_code',
        text: 'order_item_name',
        child: 'children'
      };
    }
    if (!this.orderItemForm) {
      this.generateOrderLineItemForm();
    }

    if (this._orderItemInput$.getValue()) {
      this.orderItemForm.setValue({
        order_item_name: this._orderItemInput$.getValue().order_item_name,
        description: this._orderItemInput$.getValue().description ? this._orderItemInput$.getValue().description : null,
        order_item_parent_code: this._orderItemInput$.getValue().parent ? [this._orderItemInput$.getValue().parent] : '',
      });
    }
  }
  clearAll(){
    this.clearModal.hide()
    this.orderItemForm.reset()
    this.orderItemForm.controls.order_item_parent_code.setValue('');
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }

}
